import { createSlice } from "@reduxjs/toolkit";

const quoteSliceRsa = createSlice({
    name:'quote',
    initialState:{
        isInputsRead:false,
        loader:true,
        firstLoader:true,
        apiData:{},
       
        loadApiData:{}
    },
    reducers:{
        setQuoteLoading(state,action){
            state.loader=action.payload;
   
        },
        addFirstQuoteLoad(state, action) {
          state.firstLoader = action.payload
     
        },
        setApiDataValues(state, action) {
            state.apiData = action.payload
            // state.apiData = action.apiData
        },
       
        setInputsRead (state,action) {
            state.isInputsRead = true;
        },
        setLoadApiDataValues(state,action) {

        },
        
    }
})

export default quoteSliceRsa.reducer;
export const quoteActions = quoteSliceRsa.actions