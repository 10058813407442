import React, { useState } from "react";
import NavHeader from "../components/NavHeader";
import LandingBackground from "../assets/Svg/LandingPageBck.svg";
import AbtSvg1 from "../assets/Svg/Aboutus1.svg";
import AbtSvg2 from "../assets/Svg/Aboutus2.svg";
import SectionBck from "../UI/SectionBck";
import Footer from "../components/Footer";
import BlogCard from "../UI/BlogCard";
import { blogsData } from "../data/BlogsData";
import BlogContactUs from "../components/blogs/BlogContactUs";

const AboutUs = () => {
  const [active, setActive] = useState(0);
  const [closeNav, setCloseNav] = useState(false)
  return (
    <>
      <NavHeader   dropClose={ [closeNav, setCloseNav] }
 />
      <main
        className="lg:w-full  bg-no-repeat bg-cover lg:pb-20  "
        style={{ backgroundImage: `url(${LandingBackground})` }}  onClick={()=>{
          setCloseNav(false)
        }}
      >
        <div className="hidden lg:block">
          <SectionBck title="About Us" />
        </div>
        <h1 className="lg:hidden font-bold text-2xl  text-center py-14 ">
          About Us
        </h1>
        <div className=" lg:py-32 lg:px-44 ">
          <div className="flex flex-col-reverse lg:flex-row items-center lg:space-x-20">
            <div className="">
              <h1 className="text-hero text-center lg:text-start font-bold text-2xl lg:text-3xl pb-4">
                We provide the best <br className="lg:hidden" /> secure{" "}
                <br className="hidden lg:block" /> insurance{" "}
                <br className="lg:hidden" /> plans for you
              </h1>
              <h2 className="text-[#777777] text-xs px-6 leading-6 lg:px-0 text-left  lg:text-start lg:text-lg">
              PolicyMart is a retail aggregation platform from SmartTech Insurance Brokers LLP. Our platform consolidates quotes from multiple insurers so that customers can get the best rates. The team is comprised of insurance industry leaders and experts with over three decades of insurance experience who advise clients on business and retail risks.
              </h2>
            </div>
            <div className="lg:flex flex-1"></div>
            <img src={AbtSvg1} alt="AbtSvg1" className="px-10 lg:px-0 pb-10 lg:pb-0" />
          </div>
          <div className="flex flex-col lg:flex-row items-center lg:space-x-20 py-14 lg:pb-0 lg:pt-36">
            <img src={AbtSvg2} alt="AbtSvg2" className="px-10 lg:px-0 pb-10 lg:pb-0" />
            <div className="flex flex-1"></div>
            <div className="px-6 lg:px-0">
              <h1 className="text-hero font-bold text-2xl lg:text-3xl pb-4">
                Our Mission
              </h1>
              <h2 className="text-[#777777] text-xs leading-6 lg:text-lg">
              To be India's trusted adviser on insurance risk by providing innovative and relevant risk and human capital solutions, building sustainable partnerships and contributing meaningfully to the communities we serve.
              </h2>
            </div>
          </div>

          {/* Meet OUr Team section */}
          <div className="hidden">
            <h2 className="font-bold text-hero text-3xl pb-10">
              Meet our Team
            </h2>
            <div className=" m-auto justify-center ">
              <TeamCard
                teamData={[
                  {
                    id: "1",
                    img: "",
                    name: "Ravi Krishnan",
                    post: "Chairman"
                  },
                  {
                    id: "2",
                    img: "",
                    name: "Priyanka",
                    post: "CEO"
                  },
                  {
                    id: "3",
                    img: "",
                    name: "Aamir Khan",
                    post: "Head of Finance"
                  },
                  {
                    id: "4",
                    img: "",
                    name: "Prakash Raj",
                    post: "Head of Design"
                  },

                  {
                    id: "6",
                    img: "",
                    name: "Vinoth Kumar",
                    post: "Head of Marketing"
                  },
                  {
                    id: "7",
                    img: "",
                    name: "Satish Sundar",
                    post: "Head of Human Resources"
                  }
                ]}
              />
            </div>
          </div>
        </div>
      </main>
      {/* Global Footer */}
      <Footer />
    </>
  );
};

export default AboutUs;

export const TeamCard = ({ teamData }) => {
  return (
    <div className="grid grid-cols-3 ">
      {teamData.map((item) => (
        <div className="w-80 h-80 bg-[#D9D9D9] relative rounded-2xl mb-10">
          <div className="absolute bottom-0 pb-4 px-6">
            <h2 className="font-bold text-lg ">{item.name}</h2>
            <h2 className="">{item.post}</h2>
          </div>
        </div>
      ))}
    </div>
  );
};
