import React,{useState,useEffect} from 'react'
import NavHeader from '../NavHeader'
import CarInfoStep1Manual from './step1forms/manual/CarInfoStep1'
import CarInfoStep1MobileVerify from './step1forms/mobileverify/CarInfoStep1'

const CarStep1Manage = () => {
  const [closeNav, setCloseNav] = useState(false)
  const [tileType, setTileType] = useState()

  const tileSelected = localStorage.getItem('tile_Type_price')

  useEffect(()=>{
    setTileType(tileSelected)
  },[tileSelected])

  return (
    <>
        <NavHeader dropClose={ [closeNav, setCloseNav] }/>
    <div  onClick={()=>{
          setCloseNav(false)
        }} >

        {tileType=='RSA'?<CarInfoStep1MobileVerify/> : 
        <CarInfoStep1Manual/>}
    </div>
    </>
  )
}

export default CarStep1Manage