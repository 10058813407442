import React, { useState, useEffect } from "react";
import LandingBackground from "../../../assets/Svg/LandingPageBck.svg";
import MaleSvg from '../../../assets/Vector/Health/male.svg'
import FemaleSvg from '../../../assets/Vector/Health/female.svg'
import { Link, useNavigate } from "react-router-dom";
import NavHeader from "../../NavHeader";
import FormBar from "../../FormBar";
import InputPP from "../../customs/InputPP";
import axios from "axios";
import RadioPP from "../../customs/RadioPP";


const HealthIntro6 = () => {
  const navigate = useNavigate();

  const [closeNav, setCloseNav] = useState(false)
  const [selectedGender, setSelectedGender] = useState()
  const [showError, setShowError] = useState(false)
const [jwtToken, setJwtToken] = useState(localStorage.getItem('jwt_token'))
const [preDis, setPreDis] = useState()
const [inputs, setInputs] = useState({})
const [validPincode, setValidPincode] = useState(false)
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT


  const incomeBracket = localStorage.getItem('health_incomeBrac')
  const handleChange = (e) => {
    const name = e.target.name;

    const value = e.target.id;

    setInputs((values) => ({
      ...values,
      [name]: value
    }));
  };


  const handleSubmit = (e) => {
    e.preventDefault();


        setShowError(false)
         localStorage.setItem('health_incomeBrac',JSON.stringify(inputs))
        navigate("/healthinsurance/health-profile/prestep-7" );
    


  };

  const handleAutodirect = (gender)=>{
    setSelectedGender(gender)
    localStorage.setItem('health_incomeBrac',JSON.stringify(inputs))
    setTimeout(()=>{

      navigate("/healthinsurance/health-profile/prestep-2" );
    },500)

  }



useEffect(()=>{
    incomeBracket&& setInputs(JSON.parse(incomeBracket))
},[incomeBracket])




  return (
    <>
      <NavHeader dropClose={ [closeNav, setCloseNav] } />
      <main
        className="w-full pb-10 lg:pt-8 bg-no-repeat bg-cover   "
        style={{ backgroundImage: `url(${LandingBackground})` }}  onClick={()=>{
          setCloseNav(false)
        }}
      >
        
        <form onChange={handleChange} >
          <h1 className="text-xl lg:text-3xl text-[#293651] font-semibold  py-10   lg:pt-4 text-center">
            Health Insurance Quote
          </h1>
          {/* Steps Bar */}
          <div className="lg:w-[55%] rounded-t-md bg-[#aab8aa27] m-auto ">

<div className={`lg:w-[85.1%] rounded-t-md  bg-hero py-[3px]`}></div>
</div>

{/* ------------------------------------------ */}

          <div className=" justify-center bg-white mx-5 lg:m-auto shadow-[0px_0px_25px_rgba(0,0,0,0.1)] lg:drop-shadow-lg lg:w-[55%] h-[500px   px-4 py-5">
          
            <h1 className="mr-auto font-medium text-solid lg:text-xl py-4 pl-4">
            What is your Income bracket ?            </h1>
            <hr className="text-[#DBDBDB] pb-4" />
            <p className="px-5 text-[#7e8488] text-[15px]">
            We will utilize this data to determine your potential tax benefits within the provisions of section 80D.              </p>
          <div className="py-4 grid grid-rows-1 h-full">
          <RadioPP
                  title=""
                  ClassName="flex flex-col gap-y-2 pl-5 w-max"
                //   errorState={[diseaseError, setDiseaseError]}
                  value={inputs.income}
                  required={false}
                  objValues={[
                    {
                      id: "<2.5",
                      name: "income",
                      value: "Less than Rs 2.5 lakhs"
                    },
                    {
                      id: "2.5-5",
                      name: "income",
                      value: "Rs 2.5 to 5 lakhs"
                    },
                    {
                      id: "5-10",
                      name: "income",
                      value: "Rs 5 to 10 lakhs"
                    },
                    {
                      id: ">10",
                      name: "income",
                      value: "More than Rs 10 lakhs"
                    }
                  ]}
                />
          </div>
          <h1 className={`${showError?'':'hidden'} text-center pt-2 text-sm text-[#eb5648] `}>Please Enter Valid Pincode</h1>

          </div>
          <div className="flex justify-center space-x-5 pt-8 ">
            <Link to={preDis&&preDis.isPreDis=='yes'? "/healthinsurance/health-profile/prestep-4":"/healthinsurance/health-profile/prestep-3" }
>
            <button className="bg-[#293651]  w-24 py-2 rounded-full text-white">
              Back
            </button>
            </Link>
            <input
              type="submit"
              className="bg-hero w-24 py-2 rounded-full text-white cursor-pointer "
              onClick={handleSubmit}
              value="Skip"
            />
          </div>
        </form>
      </main>
    </>
  );
};

export default HealthIntro6;
