import React,{useState} from 'react'
import CircleLoader from "react-spinners/SquareLoader";

const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "orange"
};
const LoadingEffect = () => {
  const [loading, setLoading] = useState(true);

  return (
    <div><CircleLoader
    cssOverride={override}
    size={10}
    color={'hsl(153deg 3% 58% / 46%)'}
    loading={loading}
    speedMultiplier={1.5}
  /></div>
  )
}

export default LoadingEffect