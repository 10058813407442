import React from "react";
import ClaimSvg1 from "../assets/Vector/ClaimsVec/ClaimSvg2.svg";
import ClaimSvg2 from "../assets/Vector/ClaimsVec/ClaimSvg3.svg";
import ClaimSvg3 from "../assets/Vector/ClaimsVec/ClaimSvg4.svg";
import ClaimSvg4 from "../assets/Vector/ClaimsVec/ClaimSvg5.svg";

const claimAssistData = [
  {
    id: "1",
    imgUrl: ClaimSvg1,
    step: "STEP 1",
    title: "Select a type of Insurance and enter the details"
  },
  {
    id: "2",
    imgUrl: ClaimSvg2,
    step: "STEP 2",
    title: "Our claim assistance experts will contact you"
  },

  {
    id: "3",
    imgUrl: ClaimSvg3,
    step: "STEP 3",
    title: "Submit the necessary documents "
  },
  {
    id: "4",
    imgUrl: ClaimSvg4,
    step: "STEP 4",
    title: "Congratulations! Your claim has been filed."
  }
];
const ClaimAssistantCard = () => {
  return (
    <div className="lg:flex  lg:justify-center px-5   lg:gap-7">
      {claimAssistData.map((item, key) => (
        <div className=" flex flex-col justify-center mb-5 items-center shadow-[-5px_5px_50px_10px_rgba(0,167,142,0.1)] border border-[#E6E6E6]  lg:w-80 rounded-lg p-5 space-y-3">
          <img src={item.imgUrl} alt={item.imgUrl} />
          <h1 className="font-semibold text-lg">{item.step}</h1>
          <h2 className="text-center text-sm px-3">{item.title}</h2>
        </div>
      ))}
    </div>
  );
};

export default ClaimAssistantCard;
