import React, { useState, useEffect } from "react";
import DropDownPP from "../customs/DropDownPP";
import {useNavigate} from 'react-router'
import InputPP from "../customs/InputPP";
import axios from "axios";
import RadioPP from "../customs/RadioPP";
import InsuranceHeader from "../../UI/insurance/InsuranceHeader";
import BikeInsuranceHeader from "../../UI/insurance/BikeInsuranceHeader";

const GodigitKyc = () => {
  const [inputs, setInputs] = useState([]);
  const [dobValueError, setDobValueError] = useState( );
  const [ckycNoError, setckycNoError] = useState();
  const [jwtToken, setJwtToken] = useState(localStorage.getItem("jwt_token"));
  const [changeButton, setChangeButton] = useState(false)
const [isKycVerifiedError, setIsKycVerifiedError] = useState()
const [actionType, setActionType] = useState()
const [isPopWindowOpen, setIsPopWindowOpen] = useState(false)


  const [kycResponse, setKycResponse] = useState(null)
const [customErrorMessage, setCustomErrorMessage] = useState('')

const actionValue = sessionStorage.getItem("action_type")
const inputData = localStorage.getItem("godigitKycInput");

  const navigate = useNavigate();


  useEffect(()=>{
    inputData&&setInputs(JSON.parse(inputData));

    setActionType(actionValue)
  },[actionValue, inputData])

  const handleChange = (e) => {
    const name = e.target.name;

    const value = e.target.value;
    setInputs((values) => ({
      ...values,
      [name]: name === "registered" ? e.target.id : value
    }));
  };

  useEffect(() => {
    const onStorage = () => {
      setJwtToken(localStorage.getItem("jwt_token"));
    };

    window.addEventListener("storage", onStorage);

    return () => {
      window.removeEventListener("storage", onStorage);
    };
  }, []);
useEffect (()=>{
  
},[inputs])
// console.log('res',kycResponse.KYC_Verified
// =='false')


  const handleSubmit = (e) => {
    e.preventDefault();
   

    if (inputs.dob == "" || inputs.dob == undefined) {
      setDobValueError(true);
    } else {
        setDobValueError(false);

    }
    if (inputs.registered == "" || inputs.registered == undefined) {
      setIsKycVerifiedError(true);
    } else {
        setIsKycVerifiedError(false);

    }
    if (inputs.ckycNo == "" || inputs.ckycNo == undefined) {
      setckycNoError(true);
    }   else {
        setckycNoError(false);

    }
    if (inputs.pancardNo == "" || inputs.pancardNo == undefined) {
      setckycNoError(true);
    }   else {
        setckycNoError(false);

    }

Object.keys(inputs).length>=3&&!isKycVerifiedError&&!dobValueError&&!ckycNoError&&localStorage.setItem('godigitKycInput',JSON.stringify(inputs))

Object.keys(inputs).length>=3&&!isKycVerifiedError&&!dobValueError&&!ckycNoError&&navigate((actionType=='Car'&&'/carinsurance/car-profile/step-1') || (actionType=='Bike'&&'/bikeinsurance/bike-profile/step-1'))


//       customErrorMessage=='OK'&&navigate('/carinsurance/car-profile/step-1')
//  kycResponse&&kycResponse.KYC_Verified=='false'&&window.location.href(kycResponse.Endpoint_2_URL)
      

  };
useEffect(()=>{

//  if (kycResponse && kycResponse.KYC_Verified
//   =='false'
//   ) {


//   setChangeButton(true)
//  }
//  if (kycResponse && kycResponse.message
//   =='Invalid Pan Number'
//   ) {


//     setckycNoError(true)
//  }

},[kycResponse])


  useEffect(()=>{
    if (Object.keys(inputs).includes('registered')&&(inputs.registered ==  undefined|| inputs.registered == "")) {
      setIsKycVerifiedError(true);
    } else if(Object.keys(inputs).includes('registered')&&(inputs.registered ==  undefined|| inputs.registered == "")) {
      setIsKycVerifiedError(false);
    }
    if (Object.keys(inputs).includes('dob')&&(inputs.dob == "" || inputs.dob == undefined)) {
      setDobValueError(true);
    } else if((Object.keys(inputs).includes('dob')&&(inputs.dob !== "" || inputs.dob !== undefined))) {
      setDobValueError(false);
    }
    if (Object.keys(inputs).includes('ckycNo')&&(inputs.ckycNo == "" || inputs.ckycNo == undefined)) {
      setckycNoError(true);
    } else if((Object.keys(inputs).includes('ckycNo')&&(inputs.ckycNo == "" || inputs.ckycNo == undefined))) {
      setckycNoError(false);
    }
    if (Object.keys(inputs).includes('pancardNo')&&(inputs.pancardNo == "" || inputs.pancardNo == undefined)) {
      setckycNoError(true);
    } else if((Object.keys(inputs).includes('pancardNo')&&(inputs.pancardNo == "" || inputs.pancardNo == undefined))) {
      setckycNoError(false);
    }

   
  },[ inputs])

  useEffect(()=>{
    
    if (inputs.dob == "" || inputs.dob == undefined) {
      Object.keys(inputs).includes('dob')&&    setDobValueError(true);
    } else {
        setDobValueError(false);

    }
    if (inputs.registered == "" || inputs.registered == undefined) {
      Object.keys(inputs).includes('registered')&& setIsKycVerifiedError(true);
    } else {
        setIsKycVerifiedError(false);

    }
    if (inputs.ckycNo == "" || inputs.ckycNo == undefined) {
      Object.keys(inputs).includes('ckycNo')&& setckycNoError(true);
    }   else {
        setckycNoError(false);

    }
    if (inputs.pancardNo == "" || inputs.pancardNo == undefined) {
      Object.keys(inputs).includes('pancardNo')&& setckycNoError(true);
    }   else {
        setckycNoError(false);

    }


  },[inputs])

  return (
    <>

<div className="lg:p">

{actionType=='Car'?<InsuranceHeader popwindow={[isPopWindowOpen, setIsPopWindowOpen] }/> : <BikeInsuranceHeader popwindow={[isPopWindowOpen, setIsPopWindowOpen] } />}

</div>
      <form onChange={handleChange}>
        <div className=" flex flex-col justify-center items-centeer">
          <div className="  bg-white mx-5 lg:m-auto shadow-[0px_0px_25px_rgba(0,0,0,0.1)] lg:drop-shadow-lg lg:w-[55%] h-[500px   px-4 py-5">
            <h1 className="mr-auto font-medium capitalize text-solid text-xl py-4 lg:pl-4">
              know your customer(KYC)
            </h1>
            <hr className="text-[#DBDBDB] pb-4" />
            <div className="space-y-5">
              <h2 className="text-center py-1 rounded-md  bg-[#7fc07f]">
                From jan 1st 2023 onwards, policyholders will have to provide
                Know Your Customer (KYC) documents for buying all insurance
                policies as per the Insurance Regulator and Development
                Authority of India (IRDAI)
              </h2>

              <div className="    ">

              <RadioPP
                title="Is Kyc verified ?"
                errorState={[isKycVerifiedError, setIsKycVerifiedError]}
                value={inputs.registered}
                
                objValues={[
                  {
                      id: "yes",
                      name: "registered",
                      value: "Yes"
                    },
                    {
                        id: "no",
                        name: "registered",
                        value: "No"
                    }
                ]}/>

                </div>

                <div className="h-20">

                <div className="absolute">
              <InputPP
                className="w-full lg:w-80 px-3 py-2 my-2 s rounded-md"
                labelClassName="text-[#959595] font-medium pl-1 "
                id="dob"
                label="Date Of Birth"
                type="date"
                placeholder=""
                value={inputs.dob}
                errorState={[dobValueError, setDobValueError]}
              />

</div>
</div>
              <InputPP
                className="w-full lg:w-80    px-3 py-2 m rounded-md"
                labelClassName="text-[#959595] font-medium pl-1 "
                id={inputs&&inputs.registered=='yes'?"ckycNo":'pancardNo'}
                label={inputs&&inputs.registered=='yes'?"Ckyc Number":'Pancard Number'}
                type="text"
                placeholder={`Enter ${inputs&&inputs.registered=='yes'?'Ckyc':'Pancard'} Number`}
                value={inputs&&inputs.registered=='yes'?inputs.ckycNo:inputs.pancardNo}
                errorState={[ckycNoError, setckycNoError]}
              />



            <h2 className={` ${customErrorMessage=='OK'?'text-hero font-medium':'text-[#e25757]'} `}>{  customErrorMessage=='OK'? 'KYC Verification Completed ✅ Please Proceed to enter the details in proposal form' : customErrorMessage}</h2>

              <input
                type="submit"
                className="bg-hero w-32   py-2 rounded-full text-white "
                onClick={handleSubmit}
                value='Continue'
                // value="View Quote"
              />
            </div>
          </div>
          <div className="  justify-center hidden space-x-5 pt-8 ">
            {/* <Link to="/carinsurance/car-profile/prestep-2">
        <button className="bg-[#293651]  w-24 py-2 rounded-full text-white">
          Back
        </button>
      </Link> */}
          </div>
        </div>
      </form>
    </>
  );
};

export default GodigitKyc;
