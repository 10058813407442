import React, { useState, useEffect } from "react";
import DigitLogo from "../assets/Svg/BrandPartner/BrandPolicy-digit.svg";
import SundaramLogo from "../assets/Svg/BrandPartner/BrandPolicy-sundaram.svg";
import RelianceLogo from "../assets/Svg/BrandPartner/BrandPolicy-reliance.png";
import { AiOutlineClose } from "react-icons/ai";
import { FeaturesContainer } from "./quotation/FeaturesContainer";

const QuoteDetailPopBike = ({ detailsPop, detailsData,selectedNcb='' }) => {
  const [isDetailsPopUp, setisDetailsPopUp] = detailsPop;
  const [tileType, setTileType] = useState();
  const [activeCard, setActiveCard] = useState(0);
  const [popData, setPopData] = useState();
  const [oldPolicyType, setOldPolicyType] = useState();
const [showSection1, setShowSection1] = useState(false)
const [showSection2, setShowSection2] = useState(false)
const [showSection3, setShowSection3] = useState(false)
  const [policyType, setPolicyType] = useState()
  const [actionType, setActionType] = useState('')
  const [tppdChange, setTppdChange] = useState(true)

  const [registeredModelValues, setRegisteredModelValues] = useState({})
  const [prevClaimValue, setPrevClaimValue] = useState()
const [lstPricing, setLstPricing] = useState()
  const actiontype = sessionStorage.getItem("action_type")
  let registeredModelValue= localStorage.getItem('registered_model')
  let registeredModelValue2= localStorage.getItem('bike_registered_model')

  const apidata = localStorage.getItem('apiData')
  const policy = sessionStorage.getItem('inputs_policyType')
  const bikepolicy = sessionStorage.getItem('bike_inputs_policyType')
  const radioValue = localStorage.getItem("car_Info");
  const radioValue2 = localStorage.getItem("bike_Info");
  let registeredModelClaimValue= localStorage.getItem('registered_model_claim')
  let registeredModelClaimValue2= localStorage.getItem('bike_registered_model_claim')
  const tppdVal = sessionStorage.getItem('tppdVal')
  

  const formatter = new Intl.NumberFormat('en-IN');
  
  useEffect(() => {
    setActionType(actiontype)
    setTppdChange(tppdVal)

// setRegisteredModelValues(JSON.parse(registeredModelValue))   
setPrevClaimValue(registeredModelClaimValue)

  }, [registeredModelClaimValue, registeredModelValue, actiontype, tppdVal]);
  
  useEffect(()=>{
    if(actionType=='Car'){
      radioValue&&setOldPolicyType(JSON.parse(radioValue).policy);

      setPolicyType(policy);
      setRegisteredModelValues(JSON.parse(registeredModelValue)) 
      setPrevClaimValue(registeredModelClaimValue)
    }else {
      setPolicyType(bikepolicy)
      setRegisteredModelValues(JSON.parse(registeredModelValue2)) 
      setPrevClaimValue(registeredModelClaimValue2)
      radioValue2&&setOldPolicyType(JSON.parse(radioValue2).policy);

    }


  },[actionType, bikepolicy, policy, radioValue, radioValue2, registeredModelClaimValue, registeredModelClaimValue2, registeredModelValue, registeredModelValue2])
   

  useEffect(() => {
    setPopData(JSON.parse(apidata));
    const tileValue = localStorage.getItem("tile_Type_Detail");
    setTileType(tileValue);
  }, [apidata]);
  

  useEffect(()=>{

    setLstPricing((tileType == "Reliance" &&popData.data.relianceQuoteResponse.MotorPolicy.lstPricingResponse))
  },[popData, tileType])

  // useEffect(()=>{
  //   setShowSection1(((tileType == "Reliance" &&lstPricing&&lstPricing.some(obj => obj.CoverID==='21'))||(tileType == "RSA" &&![0, '0.0', null].includes(popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.BASIC_PREMIUM_AND_NON_ELECTRICAL_ACCESSORIES)))&&())
  // },[])


  // console.log('shit',registeredModelValues,  (registeredModelValues==(null || undefined)||registeredModelValues.prevNCB==(null || undefined)||prevClaimValue=='Yes'))

  const featuresContainer = [
    {
      name: "Cashless Garages",
      value: "Available"
    },
  
    {
      name: "Discounts",
      value: ""
    },
    {
      name: "Add Ons:",
      value: ""
    }
  ];

  // console.log(popData.data.relianceQuoteResponse.MotorPolicy.DerivedVehicleIDV);
  const featureButtons = [
    {
      id: "1",
      name: "Premium Breakup"
    },
  
  ];
  const headerValues = [
    {
      id: "1",
      CoverIDV:
        (tileType == "Reliance" &&
        popData&&  popData.data.relianceQuoteResponse.MotorPolicy.IDV) ||
        (tileType == "GoDigit" &&
        popData&& popData.data.goDigitQuoteResponse.vehicle.vehicleIDV.idv) ||
        (tileType == "RSA" &&
        popData&&  popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.IDV)
    },
    {
      id: "2",
      FinalPrice:
        (tileType == "Reliance" &&
        popData&&   popData.data.relianceQuoteResponse.MotorPolicy.FinalPremium) ||
        (tileType == "GoDigit" &&
        popData&&  popData.data.goDigitQuoteResponse.grossPremium.slice(3)) ||
        (tileType == "RSA" &&
        popData&& popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.GROSS_PREMIUM)
    },
    {
      id: "3",
      imgSrc:
        (tileType == "Reliance" && RelianceLogo) ||
        (tileType == "GoDigit" && DigitLogo) ||
        (tileType == "RSA" && SundaramLogo)
    }
  ];

// console.log('selection',popData&& popData.data.goDigitQuoteResponse.discounts.otherDiscounts[0].discountAmount
// )
  const premiumBreakUpData =
  //    (policyType == (null || undefined)
  // ? oldPolicyType !== "thirdParty"
  // : policyType !== "thirdParty") ?  

    [{
      id: "1",
      title: "BASIC COVERS",

      list: [ 
        {
          id: "1",
          name: "Basic OD",
          isShow: (tileType == "Reliance" &&lstPricing&&lstPricing.some(obj => obj.CoverID==='21'))||(tileType == "RSA" &&![0, '0.0', null].includes(popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.BASIC_PREMIUM_AND_NON_ELECTRICAL_ACCESSORIES))||(tileType == "GoDigit" && !(popData.data.goDigitQuoteResponse.contract.coverages.ownDamage.selection)),
          price:
            (tileType == "Reliance" &&
            lstPricing&&lstPricing.some(obj => obj.CoverID==='21')&&lstPricing[lstPricing.findIndex(obj => obj.CoverID==='21')].Premium) ||
            (tileType == "GoDigit" &&
            popData.data.goDigitQuoteResponse.contract.coverages.ownDamage.netPremium&&popData.data.goDigitQuoteResponse.contract.coverages.ownDamage.netPremium.match(/\d+(\.\d+)?/)[0]   )||
            (tileType == "RSA" &&
              popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM
                .BASIC_PREMIUM_AND_NON_ELECTRICAL_ACCESSORIES)
        },
        {
          id: "2",
          name: "Basic TP",
          isShow: (tileType == "Reliance" &&lstPricing&&lstPricing.some(obj => obj.CoverID==='22'))||(tileType == "RSA" &&![0, '0.0', null].includes(popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.LIABILITY.BASIC_PREMIUM_INCLUDING_PREMIUM_FOR_TPPD))||(tileType == "GoDigit" && !(popData.data.goDigitQuoteResponse.contract.coverages.thirdPartyLiability.selection)),
          price:
            (tileType == "Reliance" &&
            lstPricing&&lstPricing.some(obj => obj.CoverID==='22')&&lstPricing[lstPricing.findIndex(obj => obj.CoverID==='22')].Premium) ||
            (tileType == "GoDigit" &&
            popData.data.goDigitQuoteResponse.contract.coverages.thirdPartyLiability.netPremium&&popData.data.goDigitQuoteResponse.contract.coverages.thirdPartyLiability.netPremium.match(/\d+(\.\d+)?/)[0]) ||
            (tileType == "RSA" &&
              popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.LIABILITY.BASIC_PREMIUM_INCLUDING_PREMIUM_FOR_TPPD)
        },
        {
          id: "3",
          name: "Electrical accessories",
          isShow: (tileType == "Reliance" &&lstPricing&&lstPricing.some(obj => obj.CoverID==='1'))||(tileType == "RSA" && ![0, '0.0', null].includes(popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.ELECTRICAL_ACCESSORIES))||(tileType == "GoDigit" && false),
        
          price:
            (tileType == "Reliance" &&
            (lstPricing&&lstPricing.some(obj => obj.CoverID==='1')&& lstPricing[lstPricing.findIndex(obj => obj.CoverID==='1')].Premium)) ||
            // (tileType == "GoDigit" &&
            // popData.data.goDigitQuoteResponse.contract.coverages.accessories.electrical.insuredAmount )||
            (tileType == "RSA" &&
              popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.ELECTRICAL_ACCESSORIES)
        },
        {
          id: "4",
          name: "Non-electrical accessories",
          isShow: (tileType == "Reliance" &&lstPricing&&lstPricing.some(obj => obj.CoverID==='2'))||(tileType == "RSA" && ![0, '0.0', null].includes(popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.NON_ELECTRICAL_ACCESSORIES))||(tileType == "GoDigit" && false),
          price:
            (tileType == "Reliance" &&
            lstPricing&&lstPricing.some(obj => obj.CoverID==='2')&& lstPricing[lstPricing.findIndex(obj => obj.CoverID==='2')].Premium) ||
            // (tileType == "GoDigit" &&
            // popData.data.goDigitQuoteResponse.contract.coverages.accessories.nonElectrical.insuredAmount ) ||
            (tileType == "RSA" &&
              popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.NON_ELECTRICAL_ACCESSORIES)
        },
        {
          id: "5",
          name: "Bifuel kit",
          isShow: (tileType == "Reliance" &&lstPricing&&lstPricing.some(obj => obj.CoverID==='4'))||(tileType == "RSA" && ![0, '0.0', null].includes(popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.BI_FUEL_KIT)),
          price:
            (tileType == "Reliance" &&
            lstPricing&& lstPricing.some(obj => obj.CoverID==='4')&&lstPricing[lstPricing.findIndex(obj => obj.CoverID==='4')].Premium) ||
            (tileType == "GoDigit" &&
            false) ||
            (tileType == "RSA" &&
              popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.BI_FUEL_KIT)
        },
        {
          id: "6",
          name: "Bifuel kit TP",
          isShow: (tileType == "Reliance" &&lstPricing&&lstPricing.some(obj => obj.CoverID==='18'))||(tileType == "RSA" && ![0, '0.0', null].includes(popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.LIABILITY.BI_FUEL_KIT_CNG)),
          price:
            (tileType == "Reliance" &&
            (lstPricing&&lstPricing.some(obj => obj.CoverID==='18')&&lstPricing[lstPricing.findIndex(obj => obj.CoverID==='18')].Premium)) ||
            (tileType == "GoDigit" &&
            false) ||
            (tileType == "RSA" &&
              popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.LIABILITY.BI_FUEL_KIT_CNG)
        },
       
        // {
        //   id: "2",
        //   name: "Third Party Cover Premium",
        //   price:
        //     (tileType == "Reliance" &&
        //       popData.data.relianceQuoteResponse.MotorPolicy
        //         .TotalLiabilityPremium) ||
        //     (tileType == "GoDigit" &&
        //       popData.data.goDigitQuoteResponse.contract.coverages
        //         .thirdPartyLiability.netPremium.slice(3)) ||
        //     (tileType == "RSA" &&
        //       popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.LIABILITY
        //         .BASIC_PREMIUM_INCLUDING_PREMIUM_FOR_TPPD)
        // },
        // {
        //   id: "3",
        //   name: "PA-Owner Driver",
        //   price: "0"
        // }
      ]
    },
    {
      id: "2",
      title: "DISCOUNTS",
      list: [

        {
          id: "1",
          name: `No Claim Bonus (${selectedNcb})%`,
          isShow:prevClaimValue=='Yes'?false:true&& ((tileType == "Reliance" &&lstPricing&&lstPricing.some(obj => obj.CoverID==='23'))||(tileType == "RSA" && ![0, '0.0', null].includes(popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.NO_CLAIM_BONUS))||(tileType == "GoDigit" && popData&&popData.data.goDigitQuoteResponse.discounts.otherDiscounts.length>0)) ,
          price:
            (tileType == "Reliance" &&
            (lstPricing&&lstPricing.some(obj => obj.CoverID==='23')&&lstPricing[lstPricing.findIndex(obj => obj.CoverID==='23')].Premium)) ||
            (tileType == "GoDigit" &&
            popData.data.goDigitQuoteResponse.discounts.otherDiscounts.find(item => item.discountType === "NCB_DISCOUNT")&&+popData.data.goDigitQuoteResponse.discounts.otherDiscounts.find(item => item.discountType === "NCB_DISCOUNT").discountAmount.slice(3)) ||
            (tileType == "RSA" &&
              popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.NO_CLAIM_BONUS)
        },
       

        
        // {
        //   id: "1",
        //   name: "No Claim Bonus",
        //   isShow: (policyType == (null || undefined)
        //   ? oldPolicyType == "thirdParty"
        //   : policyType == "thirdParty") ?false:true,
        //   price:
        //     (tileType == "Reliance" &&
        //     (registeredModelValues==(null || undefined)|| registeredModelValues.prevNCB==(null || undefined)||prevClaimValue=='Yes')?'₹ 0':((registeredModelValues.prevNCB=='0'&&'20%') || (registeredModelValues.prevNCB=='1'&&'25%') || (registeredModelValues.prevNCB=='2'&&'35%')|| (registeredModelValues.prevNCB=='3'&&'45%')|| (registeredModelValues.prevNCB=='4' &&'50%') || ((registeredModelValues.prevNCB)=='5'||'50%') || (registeredModelValues.prevNCB=='6'&&'55%')|| (registeredModelValues.prevNCB=='7'&&'65%')   )) ||
        //     (tileType == "GoDigit" &&
        //       popData.data.goDigitQuoteResponse.contract.coverages.ownDamage
        //         .discount.discounts[0].discountAmount.slice(3)) ||
        //     (tileType == "RSA" &&
        //       popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM
        //         .NO_CLAIM_BONUS)
        // },
         {
          id: "2",
          name: "Voluntary deductible",
          isShow: (tileType == "Reliance" &&lstPricing&&lstPricing.some(obj => obj.CoverID==='3'))||(tileType == "RSA" && ![0, '0.0', null].includes(popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.VOLUNTARY_DEDUCTABLE)),
          price:
            (tileType == "Reliance" &&
            (lstPricing&&lstPricing.some(obj => obj.CoverID==='3')&&lstPricing[lstPricing.findIndex(obj => obj.CoverID==='3')].Premium)) ||
            (tileType == "GoDigit" &&
            false&&  popData.data.goDigitQuoteResponse.contract.coverages.ownDamage
                .netPremium.slice(3)) ||
            (tileType == "RSA" &&
              popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.VOLUNTARY_DEDUCTABLE)
        },
         {
          id: "3",
          name: "TPPD discount (Added)",
          isShow: (tileType == "Reliance" &&lstPricing&&lstPricing.some(obj => obj.CoverID==='20'))||(tileType == "RSA" &&(policyType == (null || undefined)
            ? oldPolicyType == "ownDamage"
            : policyType == "ownDamage") ?false:tppdChange=='true'? true:false),
          price:
            (tileType == "Reliance" &&
            (lstPricing&&lstPricing.some(obj => obj.CoverID==='20')&&lstPricing[lstPricing.findIndex(obj => obj.CoverID==='20')].Premium)) ||
            (tileType == "GoDigit" &&
            false&& popData.data.goDigitQuoteResponse.contract.coverages.ownDamage
                .netPremium.slice(3)) ||
            (tileType == "RSA" && false
              )
        },
       
        // {
        //   id: "2",
        //   name: "Other Discounts",
        //   price:
        //     (tileType == "Reliance" &&
        //      '0') ||
        //     (tileType == "GoDigit" &&
        //       popData.data.goDigitQuoteResponse.contract.coverages.ownDamage
        //         .discount.defaultSpecialDiscountPercent) ||
        //     (tileType == "RSA" &&
        //       popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA
        //         .TECHNICAL_DISCOUNT)
        // }
      ]
    },
 
    {
      id: "3",
      title: "ADDONS",
      type:'addons',
      list:[
        {
            id: "1",
            name: "Zero Depreciation",
            isShow: (tileType == "Reliance" &&lstPricing&&lstPricing.some(obj => obj.CoverID==='10'))||(tileType == "RSA" && ![0, '0.0', null].includes(popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.DEPRECIATION_WAIVER))||(tileType == "GoDigit" && (popData.data.goDigitQuoteResponse.contract.coverages.addons.partsDepreciation.selection)),
            price:
              (tileType == "Reliance" &&
              (lstPricing&&lstPricing.some(obj => obj.CoverID==='10')&&lstPricing[lstPricing.findIndex(obj => obj.CoverID==='10')].Premium)) ||
              (tileType == "GoDigit" &&
              popData.data.goDigitQuoteResponse.contract.coverages.addons.partsDepreciation.netPremium&& popData.data.goDigitQuoteResponse.contract.coverages.addons.partsDepreciation.netPremium.match(/\d+(\.\d+)?/)[0]
                 ) ||
                 
              (tileType == "RSA" &&
                popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.DEPRECIATION_WAIVER)
          },
        {
            id: "2",
            name: "Road Side Assistance",
            isShow: (tileType == "Reliance" &&false)||(tileType == "RSA" && ![0, '0.0', null].includes(popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.ROADSIDE_ASSISTANCE_COVER))||(tileType == "GoDigit" && (popData.data.goDigitQuoteResponse.contract.coverages.addons.roadSideAssistance.selection)),
            price:
              (tileType == "Reliance" &&
              (lstPricing&&lstPricing.some(obj => obj.CoverID==='10')&&lstPricing[lstPricing.findIndex(obj => obj.CoverID==='10')].Premium)) ||
              (tileType == "GoDigit" &&
              popData.data.goDigitQuoteResponse.contract.coverages.addons.roadSideAssistance.netPremium&&  popData.data.goDigitQuoteResponse.contract.coverages.addons.roadSideAssistance.netPremium.match(/\d+(\.\d+)?/)[0]) ||

              (tileType == "RSA" &&
                popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.ROADSIDE_ASSISTANCE_COVER)
          },
        {
            id: "12",
            name: "Consumables",
            isShow: (tileType == "Reliance" &&false)||(tileType == "RSA" &&![0, '0.0', null].includes(popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.CONSUMABLE_COVER
              ))||(tileType == "GoDigit" && (popData.data.goDigitQuoteResponse.contract.coverages.addons.consumables.selection)),
            price:
              (tileType == "Reliance" &&
              (lstPricing&&lstPricing.some(obj => obj.CoverID==='10')&&lstPricing[lstPricing.findIndex(obj => obj.CoverID==='10')].Premium)) ||
              (tileType == "GoDigit" &&
              popData.data.goDigitQuoteResponse.contract.coverages.addons.consumables.netPremium&&     popData.data.goDigitQuoteResponse.contract.coverages.addons.consumables.netPremium.match(/\d+(\.\d+)?/)[0]) ||
              (tileType == "RSA" &&
                popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.CONSUMABLE_COVER
                )
          },
        {
            id: "3",
            name: "Key loss",
            isShow: (tileType == "Reliance" &&false)||(tileType == "RSA" && ![0, '0.0', null].includes(popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.KEY_REPLACEMENT))||(tileType == "GoDigit" && (popData.data.goDigitQuoteResponse.contract.coverages.addons.keyAndLockProtect.selection)),
            price:
              (tileType == "Reliance" &&
              (lstPricing&&lstPricing.some(obj => obj.CoverID==='10')&&lstPricing[lstPricing.findIndex(obj => obj.CoverID==='10')].Premium)) ||
              (tileType == "GoDigit" &&
              false&& popData.data.goDigitQuoteResponse.contract.coverages.addons.keyAndLockProtect.netPremium.match(/\d+(\.\d+)?/)[0]) ||
              (tileType == "RSA" &&
                popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.KEY_REPLACEMENT)
          },
 

        {
            id: "4",
            name: "Return to Invoice",
            isShow: (tileType == "Reliance" &&false)||(tileType == "RSA" && ![0, '0.0', null].includes(popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.VEHICLE_REPLACEMENT_COVER
              ))||(tileType == "GoDigit" && (popData.data.goDigitQuoteResponse.contract.coverages.addons.returnToInvoice.selection)),
            price:
              (tileType == "Reliance" &&
              (lstPricing&&lstPricing.some(obj => obj.CoverID==='10')&&lstPricing[lstPricing.findIndex(obj => obj.CoverID==='10')].Premium)) ||
              (tileType == "GoDigit" &&
              popData.data.goDigitQuoteResponse.contract.coverages.addons.returnToInvoice.netPremium&& popData.data.goDigitQuoteResponse.contract.coverages.addons.returnToInvoice.netPremium.match(/\d+(\.\d+)?/)[0]) ||
              (tileType == "RSA" &&
                popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.VEHICLE_REPLACEMENT_COVER
                )
          },
        {
            id: "5",
            name: "Engine Protect",
            isShow: (tileType == "Reliance" &&false)||(tileType == "RSA" && ![0, '0.0', null].includes(popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.ENGINE_PROTECTOR))||(tileType == "GoDigit" && (popData.data.goDigitQuoteResponse.contract.coverages.addons.engineProtection.selection)),
            price:
              (tileType == "Reliance" &&
              (lstPricing&&lstPricing.some(obj => obj.CoverID==='10')&&lstPricing[lstPricing.findIndex(obj => obj.CoverID==='10')].Premium)) ||
              (tileType == "GoDigit" &&
              popData.data.goDigitQuoteResponse.contract.coverages.addons.engineProtection.netPremium&& popData.data.goDigitQuoteResponse.contract.coverages.addons.engineProtection.netPremium.match(/\d+(\.\d+)?/)[0]) ||
              (tileType == "RSA" &&
                popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.ENGINE_PROTECTOR)
          },
        {
            id: "6",
            name: "NCB protection",
            isShow: (tileType == "Reliance" &&false)||(tileType == "RSA" && ![0, '0.0', null].includes(popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.NCB_PROTECTOR)),
            price:
              (tileType == "Reliance" &&
              (lstPricing&&lstPricing.some(obj => obj.CoverID==='10')&&lstPricing[lstPricing.findIndex(obj => obj.CoverID==='10')].Premium)) ||
              (tileType == "GoDigit" &&
              false&& popData.data.goDigitQuoteResponse.contract.coverages.ownDamage
                  .netPremium.slice(3)) ||
              (tileType == "RSA" &&
                popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.NCB_PROTECTOR)
          },
        {
            id: "7",
            name: "Tyre Protection",
            isShow: (tileType == "Reliance" &&false)||(tileType == "RSA" && ![0, '0.0', null].includes(popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.TYRE_COVER))||(tileType == "GoDigit" && (popData.data.goDigitQuoteResponse.contract.coverages.addons.tyreProtection.selection)),
            price:
              (tileType == "Reliance" &&
              (lstPricing&&lstPricing.some(obj => obj.CoverID==='10')&&lstPricing[lstPricing.findIndex(obj => obj.CoverID==='10')].Premium)) ||
              (tileType == "GoDigit" &&
              popData.data.goDigitQuoteResponse.contract.coverages.addons.tyreProtection.netPremium&&   popData.data.goDigitQuoteResponse.contract.coverages.addons.tyreProtection.netPremium.match(/\d+(\.\d+)?/)[0]) ||
              (tileType == "RSA" &&
                popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.TYRE_COVER)
          },
        {
            id: "8",
            name: "Loss of personal belongings",
            isShow: (tileType == "Reliance" &&false)||(tileType == "RSA" && ![0, '0.0', null].includes(popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.LOSS_OF_BAGGAGE))||(tileType == "GoDigit" && (popData.data.goDigitQuoteResponse.contract.coverages.addons.personalBelonging.selection)),
            price:
              (tileType == "Reliance" &&
              (lstPricing&&lstPricing.some(obj => obj.CoverID==='10')&&lstPricing[lstPricing.findIndex(obj => obj.CoverID==='10')].Premium)) ||
              (tileType == "GoDigit" &&
              popData.data.goDigitQuoteResponse.contract.coverages.addons.personalBelonging.netPremium&&     popData.data.goDigitQuoteResponse.contract.coverages.addons.personalBelonging.netPremium.match(/\d+(\.\d+)?/)[0]) ||
              (tileType == "RSA" &&
                popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.LOSS_OF_BAGGAGE)
          },
          {
            id: "12",
            name: "Secure plus",
            isShow: tileType == "Reliance" &&lstPricing&&lstPricing.some(obj => obj.CoverID==='374'),
            price:
              (tileType == "Reliance" &&
              (lstPricing&&lstPricing.some(obj => obj.CoverID==='374')&&lstPricing[lstPricing.findIndex(obj => obj.CoverID==='374')].Premium)) ||
              (tileType == "GoDigit" &&
              false&&   popData.data.goDigitQuoteResponse.contract.coverages.ownDamage
                  .netPremium.slice(3)) ||
              (tileType == "RSA" &&
                popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM
                  .BASIC_PREMIUM_AND_NON_ELECTRICAL_ACCESSORIES)
          },
        {
            id: "13",
            name: "Secure premium ",
            isShow: tileType == "Reliance" &&lstPricing&&lstPricing.some(obj => obj.CoverID==='375'),
            price:
              (tileType == "Reliance" &&
              (lstPricing&&lstPricing.some(obj => obj.CoverID==='375')&&lstPricing[lstPricing.findIndex(obj => obj.CoverID==='375')].Premium)) ||
              (tileType == "GoDigit" &&
              false&&    popData.data.goDigitQuoteResponse.contract.coverages.ownDamage
                  .netPremium.slice(3)) ||
              (tileType == "RSA" &&
                popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM
                  .BASIC_PREMIUM_AND_NON_ELECTRICAL_ACCESSORIES)
          },
        {
            id: "9",
            name: "Paid Driver cover",
            isShow: (tileType == "Reliance" &&lstPricing&&lstPricing.some(obj => obj.CoverID==='25'))||(tileType == "RSA" && ![0, '0.0', null].includes(popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.LIABILITY.PA_COVER_TO_PAID_DRIVER
              ))||(tileType == "GoDigit" && (popData.data.goDigitQuoteResponse.contract.coverages.unnamedPA.unnamedPaidDriver.selection)),
            price:
              (tileType == "Reliance" &&
              (lstPricing&&lstPricing.some(obj => obj.CoverID==='25')&&lstPricing[lstPricing.findIndex(obj => obj.CoverID==='25')].Premium)) ||
              (tileType == "GoDigit" &&
              popData.data.goDigitQuoteResponse.contract.coverages.unnamedPA.unnamedPaidDriver.netPremium&&   popData.data.goDigitQuoteResponse.contract.coverages.unnamedPA.unnamedPaidDriver.netPremium.match(/\d+(\.\d+)?/)[0]) ||
              (tileType == "RSA" &&
                popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.LIABILITY.PA_COVER_TO_PAID_DRIVER)
          },
        {
            id: "10",
            name: "Owner Driver PA cover ",
            isShow: (tileType == "Reliance" &&lstPricing&&lstPricing.some(obj => obj.CoverID==='24'))||(tileType == "RSA" && ![0, '0.0', null].includes(popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.LIABILITY.UNDER_SECTION_III_OWNER_DRIVER
              ))||(tileType == "GoDigit" && (popData.data.goDigitQuoteResponse.contract.coverages.personalAccident.selection)),
            price:
              (tileType == "Reliance" &&
              (lstPricing&&lstPricing.some(obj => obj.CoverID==='24')&&lstPricing[lstPricing.findIndex(obj => obj.CoverID==='24')].Premium)) ||
              (tileType == "GoDigit" &&
              popData.data.goDigitQuoteResponse.contract.coverages.personalAccident.netPremium&&    popData.data.goDigitQuoteResponse.contract.coverages.personalAccident.netPremium.match(/\d+(\.\d+)?/)[0]) ||
              (tileType == "RSA" &&
                popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.LIABILITY.UNDER_SECTION_III_OWNER_DRIVER
                )
          },
        {
            id: "11",
            name: "Unnamed passenger PA cover",
            isShow: (tileType == "Reliance" &&lstPricing&&lstPricing.some(obj => obj.CoverID==='16'))||(tileType == "RSA" && ![0, '0.0', null].includes(popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.LIABILITY.UNNAMED_PASSENGRS
              ))||(tileType == "GoDigit" && (popData.data.goDigitQuoteResponse.contract.coverages.unnamedPA.unnamedPax.selection)),
            price:
              (tileType == "Reliance" &&
              (lstPricing&&lstPricing.some(obj => obj.CoverID==='16')&&lstPricing[lstPricing.findIndex(obj => obj.CoverID==='16')].Premium)) ||
              (tileType == "GoDigit" &&
              popData.data.goDigitQuoteResponse.contract.coverages.unnamedPA.unnamedPax.netPremium&&     popData.data.goDigitQuoteResponse.contract.coverages.unnamedPA.unnamedPax.netPremium.match(/\d+(\.\d+)?/)[0]) ||
              (tileType == "RSA" &&
                popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.LIABILITY.UNNAMED_PASSENGRS
                )
          },
       
      ]
      
    },
    
    {
      id: "4",
      title: "TAXES",
      list: [
        {
          id: "1",
          name: "Goods and Services Tax",
          isShow:true,
          price:
            (tileType == "Reliance" &&(window.location.hostname=='www.policymart.co.in'?false&&popData&&popData.data.relianceQuoteResponse.MotorPolicy
            .LstTaxComponentDetails.TaxComponent.length>0&&+popData.data.relianceQuoteResponse.MotorPolicy
            .LstTaxComponentDetails.TaxComponent[0].Amount:
            popData&&+popData.data.relianceQuoteResponse.MotorPolicy
            .LstTaxComponentDetails.TaxComponent[0].Amount +
            +popData.data.relianceQuoteResponse.MotorPolicy
              .LstTaxComponentDetails.TaxComponent[1].Amount) )||
            (tileType == "GoDigit" &&
              popData.data.goDigitQuoteResponse.serviceTax.totalTax.slice(3)) ||
            (tileType == "RSA" &&
              parseFloat(
                +popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.IGST +
                  +popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.CGST +
                  +popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.SGST +
                  +popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.UTGST
              ))
        }
      ]
    },
    {
      id: "5",
      title: "FINAL PREMIUM",
      totalFare:
        (tileType == "Reliance" &&
          popData.data.relianceQuoteResponse.MotorPolicy.FinalPremium) ||
        (tileType == "GoDigit" &&
          popData.data.goDigitQuoteResponse.grossPremium.slice(3)) ||
        (tileType == "RSA" &&
          popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.GROSS_PREMIUM),
      list: []
    }
  ] 
//   :
//   [{
//     id: "1",
//     title: "BASIC COVERS",

//     list: [ 
     
//       {
//         id: "1",
//         name: "Third Party Cover Premium",
//         price:
//           (tileType == "Reliance" &&
//             popData.data.relianceQuoteResponse.MotorPolicy
//               .TotalLiabilityPremium) ||
//           (tileType == "GoDigit" &&
//             popData.data.goDigitQuoteResponse.contract.coverages
//               .thirdPartyLiability.netPremium.slice(3)) ||
//           (tileType == "RSA" &&
//             popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.LIABILITY
//               .BASIC_PREMIUM_INCLUDING_PREMIUM_FOR_TPPD)
//       }
       
//     ]
  
// },
// {
//   id: "3",
//   title: "ADDONS",
//   type:'addons',
//   list:[]
  
// },
// {
//   id: "4",
//   title: "TAXES",
//   list: [
//     {
//       id: "1",
//       name: "Goods and Services Tax",
//       isShow:true,
//       price:
//         (tileType == "Reliance" &&
//           +popData.data.relianceQuoteResponse.MotorPolicy
//             .LstTaxComponentDetails.TaxComponent[0].Amount +
//             +popData.data.relianceQuoteResponse.MotorPolicy
//               .LstTaxComponentDetails.TaxComponent[1].Amount) ||
//         (tileType == "GoDigit" &&
//           popData.data.goDigitQuoteResponse.serviceTax.totalTax.slice(3)) ||
//         (tileType == "RSA" &&
//           parseFloat(
//             +popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.IGST +
//               +popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.CGST +
//               +popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.SGST +
//               +popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.UTGST
//           ))
//     }
//   ]
// },
// {
//   id: "5",
//   title: "FINAL PREMIUM",
//   totalFare:
//     (tileType == "Reliance" &&
//       popData.data.relianceQuoteResponse.MotorPolicy.FinalPremium) ||
//     (tileType == "GoDigit" &&
//       popData.data.goDigitQuoteResponse.grossPremium.slice(3)) ||
//     (tileType == "RSA" &&
//       popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.GROSS_PREMIUM),
//   list: []
// }

// ]
  
 
  return (
    <div className="bg-white modal2   w-full lg:w-[610px]  lg:h-[460px]  lg:overflow-y-scroll px-8 mt-10 lg:mt-0 lg:px-8 pt-2 pb-14 lg:pb-0 rounded-2xl relative z-20 ">
      <AiOutlineClose
        className="ml-auto -mr-5 text-bold mb-2 cursor-pointer w-6 h-6 p-1 rounded-full bg-[#45454542]"
        onClick={() => {
          setisDetailsPopUp(false);
          sessionStorage.setItem ("detail_open", false);

        }}
      />
      <div className="flex items-center gap-10 lg:justify-between">
        <img
          src={headerValues[2].imgSrc}
          alt={headerValues[2].imgSrc}
          className="w-[70px] h-[30px] lg:w-[100px] lg:h-[40px]"
        />
        <div className={` ${ (policyType == (null || undefined)
            ? oldPolicyType !== "thirdParty"
            : policyType !== "thirdParty")?'':'hidden'} `}>
          <p className="text-[#989898] text-[10px] lg:text-xs">
            Cover Amount (IDV)
          </p>
          <p className=" text-sm lg:text-base font-bold text-center">
            {" "}
         {headerValues[0].CoverIDV.toLocaleString()}
          </p>
        </div>
        {/* Button For Desktop View  */}
        <button className="bg-hero text-white font-semibold text-lg w-max px-5 py-2  hidden lg:block rounded-full">
          {" "}
          ₹ {formatter.format(headerValues[1].FinalPrice)}
        </button>
      </div>
      <div className="hidden fle x  gap-5 lg:gap-10  lg:m-auto justify-center pt-7 lg:pt-8">
        {featureButtons.map((item, key) => (
          <button
            className={`${
              key == activeCard ? "bg-hero text-white" : "bg-[#E0E0E0]"
            }  w-28  lg:w-44 py-2 rounded-full text-[10px] lg:text-sm`}
            onClick={(e) => {
              setActiveCard(key);
            }}
          >
            {item.name}
          </button>
        ))}
      </div>
      <div className="pt-2 lg:pt-5  ">
         { premiumBreakUpData.map((item, key) => (
          <>
            <div className={` flex items-center pb-2 pt-3`}>
              <h1 className="text-xs lg:text-sm font-medium">{item.title}</h1>
              <div className="flex flex-1"></div>
              <p className={`text-sm font-medium ${item.title=='FINAL PREMIUM'?'':'hidden'}`}> {item.title=='FINAL PREMIUM'?'₹':''} {formatter.format(item.totalFare)}</p>
            </div>
            { item.list.every(item => item.isShow === false)&&item.title!=='FINAL PREMIUM'? <p className="text-xs lg:text-sm text-[#575a5c] " > None</p> :item.list.map((item, key) => (
              <div className={`${item.isShow?'':'hidden'} flex space-y-2 items-center `}>
                <p className="text-xs lg:text-sm text-[#575a5c] ">
                  {item.name} 
                </p>
                <div className="flex flex-1"></div>
                <p className={` ${item.name=='TPPD discount (Added)'?'hidden  ':''}  text-sm  text-[#263238] font-light`}>
                  {  item.name==`No Claim Bonus (${selectedNcb})%`?` ${tileType == "Reliance"?'₹':'₹ -'}`: ' ₹'} {formatter.format(item.price)}
                </p>
              </div>
            ))}
            {/* {item.type=='addons'&& <FeaturesContainer values={featuresContainer} isAddonShow={true} isshow={false}/>} */}
            <button className={`  bg-hero text-white font-semibold text-lg w-full lg:w-28 py-2 ${key<4?'hidden':''} lg:hidden rounded-full`}>
        {" "}
        ₹ {formatter.format(item.totalFare)}
      </button>
          </>
        ))}
      </div>
     
    </div>
  );
};

export default QuoteDetailPopBike;