import React,{useState} from "react";
import { Link } from "react-router-dom";
import NavHeader from "../components/NavHeader";
import LandingBackground from "../assets/Svg/LandingPageBck.svg";
import BikeInsuSvg from "../assets/Svg/BikeInsuSvg.svg";
import BikeInsuSvg2 from "../assets/Svg/BikeInsuSvg2.svg";
import QuoteVector from "../assets/Svg/QuoteVector2.svg";
import Testimonial from "../components/slider/Testimonial";
import Article from "../components/Article";
import Partners from "../components/slider/Partners";
import Faq from "../components/Faq";
import Footer from "../components/Footer";
const BikeInsuranceLanding = () => {
  const [closeNav, setCloseNav] = useState(false)
  return (
    <>
      <NavHeader   dropClose={ [closeNav, setCloseNav] }
 />
      <main
        className="w-full  bg-no-repeat bg-cover  "
        style={{ backgroundImage: `url(${LandingBackground})` }}  onClick={()=>{
          setCloseNav(false)
        }}
      >
        {/* Section - 1 */}
        <section className="flex flex-col-reverse lg:flex-row  items-center lg:space-x-32 justify-around px- py-7 lg:px-32 lg:py-24">
          <div className="space-y-5">
            <h1 className="text-2xl lg:text-5xl text-[#293651] text-center lg:text-start lg:leading-snug ">
              Compare and get the <br /> best{" "}
              <span className="text-hero font-bold">Bike Insurance</span>
            </h1>
            <h2 className=" text-[#777777] text-base leading-5 lg:leading-loose text-center lg:text-start  ">
              A two wheeler insurance policy <br className="lg:hidden" />{" "}
              protects your bike or scooter <br className="hidden lg:block" />
              against <br className="lg:hidden" /> any damages caused due to
              road <br className="lg:hidden" /> accidents, natural{" "}
              <br className="hidden lg:block" />
              disaster, and theft <br className="lg:hidden" /> or loss.
            </h2>
            <Link to="/bikeinsurance/bike-profile/prestep-1">
              <img src={QuoteVector} alt="QuoteVector" className="pt-6 px-10" />
            </Link>
          </div>
          <div className=" ">
            <img src={BikeInsuSvg} alt="BikeInsuSvg" className="pt-3 pb-8 lg:py-0" />
          </div>
        </section>

        {/* Section -2  what is Bike Insurance */}

        <section className="flex flex-col lg:flex-row items-center lg:space-x-20  lg:px-40 lg:py-20">
          <img
            src={BikeInsuSvg2}
            alt="BikeInsuSvg2"
            className="pt-3  px-10 lg:px-0  pb-8 lg:py-0"
          />
          <div className="">
            <h1 className="text-hero font-bold text-lg lg:text-3xl pb-3 lg:pb-5">
              What is Bike insurance?
            </h1>
            <h2 className="text-sm lg:w-[434px] text-[#777777] lg:leading-8">
              Two Wheeler Insurance or Bike Insurance is a{" "}
              <br className="lg:hidden" /> plan that protects{" "}
              <br className="hidden lg:block" />
              the owner of a bike <br className="lg:hidden" /> from any possible
              damages in case of an
              <br />
              accident, theft or natural disaster. <br className="lg:hidden" />{" "}
              Currently, a third-party 2
              <br className="hidden lg:block" />
              wheeler insurance <br className="lg:hidden" /> policy is mandatory
              under the Motor <br className="lg:hidden" /> Vehicle{" "}
              <br className="hidden lg:block" /> Act 1988.
            </h2>
          </div>
        </section>

        {/* Section -3 Testimonial */}
        <section className="py-8   ">
          <Testimonial />
        </section>

        {/* section -4 Articles */}
        <section className="py-10 lg:py-20  flex justify-center">
          <Article />
        </section>

        {/* Section - 5 Partners */}
        <section className="  px-6 lg:px-32  m-auto">
          <Partners />
        </section>
        {/* Section -6 Faqs */}
        <section className="lg:py-16 lg:px-40">
          <Faq />
        </section>
        {/* Global  Footer */}
        <Footer />
      </main>
    </>
  );
};

export default BikeInsuranceLanding;
