import React, { useState, useEffect } from "react";

const Search = ({
  placeholder,
  data,
  name,
  id,
  values = "",
  changeValue=false,
  errorState = "",
  changeFunction
}) => {
  const [filteredData, setFilteredData] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [hide, setHide] = useState(false);
  const [error, setError] = errorState;
  const [inputValue, setInputValue] = changeFunction;

  useEffect(() => {
    if (selectedValue.length > 1) {
      setSelectedValue(selectedValue);
    }
    setInputValue(selectedValue);
    // changeFunction();
  }, [selectedValue]);
  
  const handleFilter = (e) => {
    const searchWord = e.target.value;
    const newFilter = data.filter((value) => {
      return value.toLowerCase().includes(searchWord.toLowerCase());
    });
    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }
    setSelectedValue(e.target.value);
    setHide(false);
  };
  function myFunction() {
    const value = document.getElementById("demo");
  }
  return (
    <div>
      <div className="z-10">
        <input
          id={name}
          type="text"
          autoSave="off"
          autoComplete="off"
          className={` w-full lg:w-80 border ${error?' border-[#d81111] transition-transform duration-300 animate-shake ':''} rounded-md border-[#d7d5d5] focus:outline-none px-3 py-2 my-2`}
          placeholder={placeholder}
          onChange={handleFilter}
          value={changeValue ?  values :values.length > 0 ? values : selectedValue}
          name={name}
        />
      </div>
      {filteredData.length != 0 && (
        <div
          className={` ${
            hide ? "hidden" : ""
          } z-10 absolute w-full lg:w-80 h-60 overflow-x-hidden overflow-y-auto`}
        >
          {filteredData.map((item, key) => {
            return (
              <div className=" bg-white drop-shadow-xl  cursor-pointer text-center">
                <option
                  className="  hover:bg-[#0000000e]"
                  name={item}
                  id="demo"
                  onClick={(e) => {
                    setSelectedValue(e.target.value);
                    setHide(!hide);
                  }}
                >
                  {item}
                </option>
                <hr className="text-[#00000002]" />
              </div>
            );
          })}
        </div>
      )}
      {error && (
        <h1 className="text-[#b54a39]  text-sm">Please Choose {id}</h1>
      )}
    </div>
  );
};

export default Search;
