import React,{useState} from "react";
import Img1 from "../../assets/Vector/Blogs/BlogImg1.svg";
import Carousel, { consts } from "react-elastic-carousel";
import { FaArrowLeft, FaArrowRight, FaRegCircle } from "react-icons/fa";
import { VscCircleLargeFilled } from "react-icons/vsc";
import { TrendingArticleData } from "../../data/TrendingArticles";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 1 },
  { width: 768, itemsToShow: 1 },
  { width: 1200, itemsToShow: 3 }
];

const TrendingArticle = ({trendingArticleData}) => {

  const [trendData, setTrendData] = useState([trendingArticleData])
  return (
    <div>
      

      {/* For Desktop View */}
      <div className="  lg:block">
        {trendingArticleData.map((item, key) => (
          <>
            <div className="flex space-x-4 items-center py-2">
              <img
                src={item.imgUrl}
                alt={item.imgUrl}
                className="w-[116px] h-[85px] rounded-xl"
              />
              <div className="">
                <h1 className="text-[#293651] text-xs lg:text-[13px] font-medium leading-5">
                  {item.title}
                </h1>
                <h2 className="text-xs hidden opacity-[0.3] pt-2">{item.date}</h2>
              </div>
            </div>
          </>
        ))}
      </div>

      {/* For Mobile View */}
      <div className="hidden pb-10">
        <Carousel
          breakPoints={breakPoints}
          className={` lg:p-5`}
          // renderArrow={myArrow}
          // pagination={false}
          showArrows={false}
          renderPagination={({ pages, activePage, onClick }) => {
            return (
              <div className="flex space-x-1">
                {pages.map((page) => {
                  const isActivePage = activePage === page;
                  return (
                    <VscCircleLargeFilled
                      key={page}
                      onClick={() => onClick(page)}
                      active={isActivePage}
                      className={` ${
                        isActivePage
                          ? "text-hero"
                          : "text-[rgba(0,167,142,0.18)]"
                      } `}
                    />
                  );
                })}
              </div>
            );
          }}
        >
          {TrendingArticleData.map((item, key) => (
            <>
              <div className="  py-2">
                <img
                  src={item.img}
                  alt={item.img}
                  className=" w-[320px] h-[162px] rounded-xl"
                />
                <div className="">
                  <h1 className="text-[#293651] text-[13px] py-2 font-medium leading-5">
                    {item.title}
                  </h1>
                  <h3 className="text-xs opacity-[0.3]  ">{item.date}</h3>
                </div>
              </div>
            </>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default TrendingArticle;
