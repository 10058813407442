import React, { useState } from "react";

const DropDownPP = ({
  label = "",
  className = "",
  labelClassName = "",
  parentClassName='',
  id,
  brClass,
  value = "",
  differentDrop=false,
  required = true,
  errorState = "",
  options,
  special=false
}) => {
  const [error, setError] = errorState;

  return (
    <div className={`${parentClassName}`}>
      <label htmlFor={id} className={`${labelClassName} `}>
        {label}{" "}
        <span className={required ? `text-[#f45138]` : "hidden"}>*</span>
      </label>{" "}
      <br className={brClass} />
      <select
        name={id}
        id={id}
        defaultValue={value}
        className={`${className} ${
          error ? "border-[#d81111] transition-transform duration-300 animate-shake " : "border-[#d7d5d5]"
        }rounded-md focus:outline-none`}
      >
        {options.map((item) => (
          <>
            <option value="none" selected hidden>
              {value.length > 0 ? value : `Select ${label}`}
            </option>
            <option id={special?item: item.id} value={differentDrop?item.id:item}>{differentDrop?item.value:item}</option>
          </>
        ))}
      </select>
      {error && (
        <h1 className="text-[#b54a39]  text-sm">Please Fill the {label}</h1>
      )}
    </div>
  );
};

export default DropDownPP;
