import React, { useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

import NavHeader from "../components/NavHeader";
import LandingBackground from "../assets/Svg/LandingPageBck.svg";
import Footer from "../components/Footer";
import BlogContactUs from "../components/blogs/BlogContactUs";
import { BlogEntryDetails } from "../data/BlogEntryDetails";
import { BsFillPersonFill } from "react-icons/bs";
import { FaCalendarAlt } from "react-icons/fa";
import TrendingArticle from "../components/blogs/TrendingArticle";
import { blogsData } from "../data/BlogsData";
const BlogsEntry = ({}) => {
  let { slug } = useParams();
  const [closeNav, setCloseNav] = useState(false);

  const convertToSlug = (text) => {
    return text
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  };
  const params = blogsData.find((item) => convertToSlug(item.title) === slug);
  return (
    <>
      <NavHeader dropClose={[closeNav, setCloseNav]} />
      <main
        className="w-full  bg-no-repeat bg-cover  "
        style={{ backgroundImage: `url(${LandingBackground})` }}
        onClick={() => {
          setCloseNav(false);
        }}
      >
        <div className="flex flex-col lg:flex-row lg:px-44 px-5 pt-8 lg:py-20 lg:space-x-14">
          <button className="bg-[#E6F6F4] capitalize lg:hidden w-max px-4 py-1 text-xs mb-5 text-hero font-semibold rounded-2xl">
            {params.category}
          </button>
          <div className="">
            {BlogEntryDetails.map((item) => (
              <>
                <h1 className="text-hero font-semibold  lg:w-full text- lg:text-3xl leading-snug pb-2 ">
                  {params.title} 
                </h1>
                <div className="flex lg:justify-end  items-center text-[10px] lg:text-base  space-x-7">
                  <div className="hidden items-center space-x-2">
                    <BsFillPersonFill className="text-hero  text-lg lg:text-xl" />
                    <h2 className="text-[#777777]">{item.author}</h2>
                  </div>
                  <div className="hidden items-center space-x-4">
                    <FaCalendarAlt className="text-hero text-lg lg:text-xl" />
                    <h2 className="text-[#777777]">{params.date}</h2>
                  </div>
                </div>
                <hr className="opacity-[0.2] my-3" />
                <img
                  src={params.imgUrl}
                  alt={params.imgUrl}
                  className=" w-[302px] h-[202px] rounded-lg lg:h-max lg:w-full py-2"
                />
                {/* <h2 className=" py-10">{item.data}</h2> */}
                <div className=" text-[13.5px] pt-4 text-[#383839c4] lg:text-[17px] lg:pt-5 lg:leading-loose">
                  {parse(params.description)}
                </div>
              </>
            ))}
          </div>
          <div className="flex flex-col-reverse lg:flex-col">
            <BlogContactUs />
            <div className="pt-14 pb-10 pl-2">
              <h1 className="text-hero font-bold text-base">
                Trending Articles
              </h1>
              <hr className="opacity-[0.2] my-3" />
              {blogsData
                .filter((blog) => {
                  return blog.category == params.category;
                })
                .map((blogs, key) => {
                  let slug = [convertToSlug(blogs.title)];
                  return (
                    <>
                      <div
                        className={`${key > 0 ? "hi dden" : " "}`}
                        key={blogs.id}
                      >
                        <Link to={`/article/${slug}`}>
                          {/* {blogs.title} */}
                          <TrendingArticle trendingArticleData={[blogs]} />
                        </Link>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </main>
      {/* Global Footer */}
      <Footer />
    </>
  );
};

export default BlogsEntry;
