import React,{useState,useEffect} from 'react'
import InputPP from '../customs/InputPP'

const Login = () => {

    const [inputs, setInputs] = useState({})
    const [userNameError, setUserNameError] = useState()
    const [passwordError, setPasswordError] = useState()

    const handleChange = (e) => {
        const name = e.target.name;
    
        const value = e.target.value;
    
        setInputs((values) => ({
          ...values,
          [name]: name === "registered" ? e.target.id : value
        }));
      };


  return (
    <div className='flex flex-col  justify-center items-center h-screen'>
        <h1 className="font-semibold lg:text-lg pb-4">DEMO LOGIN</h1>
    <form onChange={handleChange} className="space-y-4">
        <InputPP 
         className="w-full lg:w-80 border-[#EEF0F2]  px-3 py-2 my-2 rounded-md"
         labelClassName=" font-medium pl-1 "
         id="userName"
         label="Username"
         type="text"
         placeholder="Your username"
         // state={[ownerName, setOwnerName]}
         value={inputs.userName}
         changeFunction={handleChange}
         errorState={[userNameError, setUserNameError]}
        />
        <InputPP 
         className="w-full lg:w-80 border-[#EEF0F2]  px-3 py-2 my-2 rounded-md"
         labelClassName=" font-medium pl-1 "
         id="password"
         label="Password"
         type="password"
         placeholder="Your username"
         // state={[ownerName, setOwnerName]}
         value={inputs.password}
         changeFunction={handleChange}
         errorState={[passwordError, setPasswordError]}
        />
         <input
              type="submit"
              className="bg-[#0D6EFD] w-full px-5 py-3 rounded-[15px] text-white focus:outline-none cursor-pointer "
            //   onClick={handleSubmit}
              value="Login"
            />
          
            <h2 className="text-[#8E92BC] text-right text-xs ">Forgot Password?</h2>
            <h2 className="text-[#8E92BC] text-center text-sm">Go to Customer Portal</h2>
            <h2 className="text-[#8E92BC] text-sm text-center">Not Demo? <span className="text-[#0D6EFD]"> Click here</span></h2>
    </form>
    </div>
  )
}

export default Login