import React, {useState, useEffect } from "react";
import LandingBackground from "../../assets/Svg/LandingPageBck.svg";
import DetailsReviewCard from "../../UI/DetailsReviewCard";
import InsuranceHeader from "../../UI/insurance/InsuranceHeader";
import PlanSummary from "../../UI/PlanSummary";
import axios from "axios";


import NavHeader from "../NavHeader";
import { Link } from "react-router-dom";




const ReviewDetails = ({proposalData,dataLoaded}) => {
  const [apiData, setApiData] = useState({})
  const [jwtToken, setJwtToken] = useState('')
  const [tokenLoaded, setTokenLoaded] = useState(false)
  const [step1Values, setStep1Values] = useState({})
  const [carRegistrationNumber, setCarRegistrationNumber] = useState('')
  const [vehicleNumber, setVehicleNumber] = useState('')
  const [rtoRegionCode, setRtoRegionCode] = useState('')
  const [manufactureYear, setManufactureYear] = useState()
const [normalCarRegNo, setNormalCarRegNo] = useState()
const [closeNav, setCloseNav] = useState(false)
  const [step2Values, setStep2Values] = useState({})
  const [step3Values, setStep3Values] = useState({})
  const [step4Values, setStep4Values] = useState({})
const [registeredModelValues, setRegisteredModelValues] = useState({})
const [proposalDataApi, setProposalDataApi] = proposalData
const [isDataLoaded, setIsDataLoaded] = dataLoaded
  const [popUpsFilterData, setPopUpsFilterData] = useState({})
  const [prevClaimValue, setPrevClaimValue] = useState()
  const [isPopWindowOpen, setIsPopWindowOpen] = useState(false)
const [pincodeDetails, setPincodeDetails] = useState({})
  const [dateOfBirth, setDateOfBirth] = useState()
  const [isCarRegistered, setIsCarRegistered] = useState()
  const carRegistered = localStorage.getItem("isCarRegistered")
  const filterData = localStorage.getItem("popupsData");
  let registeredModelValue= localStorage.getItem('registered_model')

  const carOwnerDetails = localStorage.getItem("step-1_Values")
  const personalDetails = localStorage.getItem("step-2_Values")
  const addressDetails = localStorage.getItem("step-3_Values")
  const vechileDetails = localStorage.getItem("step-4_Values")
  const radioValue = localStorage.getItem('car_Info')
const pincodedata = localStorage.getItem('pincode_Details')
  let registeredModelClaimValue= localStorage.getItem('registered_model_claim')
  const carRegNO = localStorage.getItem('car_Intro')

useEffect(()=>{
  setPopUpsFilterData(JSON.parse(filterData))
  setRegisteredModelValues(JSON.parse(registeredModelValue))
  setPrevClaimValue(registeredModelClaimValue)
  carRegNO&&setCarRegistrationNumber(JSON.parse(carRegNO).registrationNumber)
  setManufactureYear(JSON.parse(radioValue).manufactureYear)
  carRegNO&&setNormalCarRegNo(JSON.parse(carRegNO).registrationNumber)
  setStep1Values(JSON.parse(carOwnerDetails))
  setStep2Values(JSON.parse(personalDetails))
  setStep3Values(JSON.parse(addressDetails))
  setStep4Values(JSON.parse(vechileDetails))
  setIsCarRegistered(carRegistered)
  setPincodeDetails(JSON.parse(pincodedata))
},[carOwnerDetails,pincodedata,carRegistered,radioValue,carRegNO,registeredModelClaimValue,vechileDetails,filterData,registeredModelValue,addressDetails,personalDetails])



  useEffect(() => {

    axios
      .post(
        "/token",
        {
          
            username: "oneadmin",
            credentials: "$%#@gh817655$$#1jk"
    
        },
        {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",

          }
        }
        )
        .then((res) => {
          setTokenLoaded(true)
        setJwtToken(res.data);
      })
      
      .catch((error) => console.log(error));
        
      }, [])

      useEffect(()=>{

        const vehicleNumberHandle = ()=>{
          let vehNo = ['']
          let spliting = carRegistrationNumber.split('')
          vehNo.unshift(spliting)
          setVehicleNumber(vehNo[0][0]+vehNo[0][1]+'-'+vehNo[0][2]+vehNo[0][3]+'-'+vehNo[0][4]+'-'+vehNo[0][5]+vehNo[0][6]+vehNo[0][7]+vehNo[0][8])
          setRtoRegionCode(vehNo[0][0]+vehNo[0][1]+vehNo[0][2]+vehNo[0][3])
          // console.log(vehNo[0][0]+vehNo[0][1]+'-'+vehNo[0][2]+vehNo[0][3]+'-'+vehNo[0][4]+'-'+vehNo[0][5]+vehNo[0][6]+vehNo[0][7]+vehNo[0][8])
        }
        vehicleNumberHandle()
      },[carRegistrationNumber])

      useEffect(()=>{

        const vehicleNumberHandle = ()=>{
          let dob = ['']
          let spliting = step2Values.dateOfBirth.split('-')
          dob.unshift(spliting)
          setDateOfBirth(dob[0][2]+ '/' + dob[0][1]+'/'+dob[0][0])
        }
        step2Values.dateOfBirth&& vehicleNumberHandle()
      },[ step2Values.dateOfBirth])
  // useEffect(() => {
    
  //   const interval = setInterval(() => {
  //      axios
  //     .post(
  //       "/token",
  //       {
          
  //           username: "oneadmin",
  //           credentials: "$%#@gh817655$$#1jk"
    
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           accept: "application/json"
  //         }
  //       }
  //       )
  //       .then((res) => {
  //       setJwtToken(res.data);
  //     })
      
  //     .catch((error) => console.log(error));
    
  
  //   },600000);
  
  //   return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  // }, [])

  let currentDate = new Date();
  let cDay = currentDate.getDate();
  let cMonth = currentDate.getMonth() + 1;
  let cYear = currentDate.getFullYear();


  const userEnterDate = new Date(registeredModelValues&&registeredModelValues.policyExp)
  let prevDay = userEnterDate.getDate() 
  let prevMonth = userEnterDate.getMonth() + 1;
  let prevYear = userEnterDate.getFullYear();
  let curDay = userEnterDate.getDate() 
  let curMonth = userEnterDate.getMonth() + 1;
  let curYear = userEnterDate.getFullYear()-1;



  const details1 = [
    // {
    //   id: "1",
    //   name: "Car Registered in Company Name?",
    //   ans: step1Values.registered
    // },
    {
      id: "2",
      name: "Salutation",
      ans: step1Values.salutation
    },
    {
      id: "3",
      name: "Car Owner First  Name?",
      ans: step1Values.firstName
    },
    {
      id: "4",
      name: "Car Owner Last  Name?",
      ans: step1Values.lastName
    },
    {
      id: "5",
      name: "Email Address",
      ans: step1Values.email
    },
    {
      id: "6",
      name: "Mobile Number",
      ans: step1Values.mobile
    }
  ];
  const details2 = [
    {
      id: "1",
      name: "Gender",
      ans: step2Values.gender
    },
    {
      id: "2",
      name: "Married?",
      ans: step2Values.married
    },
    {
      id: "3",
      name: "Date of Birth",
      ans: step2Values.dateOfBirth
    },
    {
      id: "4",
      name: "Nominee Details",
      ans: step2Values.nomineeName
    },
    {
      id: "5",
      name: "Age",
      ans: step2Values.age
    },
    {
      id: "6",
      name: "Relation",
      ans: step2Values.relation
    },
    {
      id: "4",
      name: "Nominee DOB",
      ans: step2Values.nomineedateOfBirth
    },
  ];
  const details3 = [
    {
      id: "1",
      name: "Address",
      ans: step3Values.address+  ','+step3Values.address2
    },
    {
      id: "2",
      name: "Pincode",
      ans: step3Values.pincode
    },
    {
      id: "3",
      name: "City",
      ans: pincodeDetails.cityOrVillageName
    },
    {
      id: "4",
      name: "District",
      ans: pincodeDetails.districtName
    },
    {
      id: "5",
      name: "State",
      ans: pincodeDetails.stateName
    }
  ];
  const details4 = [
    {
      id: "1",
      name: "Car Registration Number",
      ans: step4Values.carRegisterNo
    },
    {
      id: "2",
      name: "Car Registration Date",
      ans: step4Values.carRegistrationDate
    },
    {
      id: "3",
      name: "Engine Number",
      ans: step4Values.engineNo
    },
    {
      id: "4",
      name: "Chassis Number",
      ans: step4Values.chasisNo
    },
    {
      id: "5",
      name: "Previous Policy Insurer",
      ans: step4Values.prevPolicyInsurer
    },
    {
      id: "6",
      name: "Previous Policy Number",
      ans: step4Values.prevPolicyNo
    },
    {
      id: "7",
      name: "Existing TP Expiry Date",
      ans: step4Values.existingTPExpDate
    },
     {
      id: "8",
      name: "Does this car have an external CNG/LPG Kit?",
      ans: step4Values.externalKit=='externalKitno'?"No":"Yes"
    },
    {
      id: "9",
      name: "Is your car currently on loan?",
      ans: step4Values.loan
    },
    step4Values.loan=='yes'&&{
      id: "10",
      name: "Financier Name ",
      ans: step4Values.financierName
    },
    step4Values.loan=='yes'&&{
      id: "11",
      name: "Financier City",
      ans: step4Values.financiercity
    },
    step4Values.loan=='yes'&& {
      id: "12",
      name: "Financier Address",
      ans: step4Values.financierAddress
    },

   
  ];



  return (
    <>
      <NavHeader   dropClose={ [closeNav, setCloseNav] }
/>
      <main
        className="lg:w-full  lg:pt-5 bg-no-repeat bg-cover   "
        style={{ backgroundImage: `url(${LandingBackground})` }}  onClick={()=>{
          setCloseNav(false)
        }}
      > 
        <InsuranceHeader popwindow={[isPopWindowOpen, setIsPopWindowOpen]}  />

        <div className={` lg:gap-40 lg:justify-center lg:m-auto py-5 px-4 lg:py-10 lg:px-36 ${isPopWindowOpen?'hidden':'lg:flex'} `}>
          <div className="">
            <div className="w-full lg:flex items-center">
              <h1 className="text-lg lg:text-2xl font-bold">Review and Pay</h1>
              <div className="flex flex-1"></div>
             <Link to='/carinsurance/car-profile/step-4'>
              <button className="bg-[#293651] hidden lg:block text-white w-24 font-semibold rounded-full py-2">
                Back
              </button>
             </Link>
            </div>
            <div className="space-y-7 pt-5">
              <DetailsReviewCard
                cardData={details1}
                isOpen={false}
                step="1"
                title="Car Owner Details"
                link="/carinsurance/car-profile/step-1"
              />
              <DetailsReviewCard
                cardData={details2}
                title="Personal Details"
                step="2"
                link="/carinsurance/car-profile/step-2"
              />
              <DetailsReviewCard
                cardData={details3}
                title="Communication Address"
                step="3"
                link="/carinsurance/car-profile/step-3"
              />
              <DetailsReviewCard
                cardData={details4}
                title="Vehicle Details"
                step="4"
                link="/carinsurance/car-profile/step-4"
              />
            </div>
          </div>
          {/* <div className="flex flex-1"></div> */}
          <div className="pt-7">
            <PlanSummary proposalDatas={[proposalDataApi, setProposalDataApi]} dataLoaded={[isDataLoaded, setIsDataLoaded]} />
          </div>
        </div>
      </main>
    </>
  );
};

export default ReviewDetails;
