import React, { useState, useEffect } from "react";
import axios from "axios";
import NavHeader from "./NavHeader";

const Dummy = () => {
  const [datas, setDatas] = useState([]);
  const [userData, setUserData] = useState();
  const [userData1, setUserData1] = useState();
  const [userData2, setUserData2] = useState();
  const data = { userData, userData1, userData2 };
  const handlePageClick = () => {
    console.log("Button is Clicked");
  };

  let bodyData = {
    id: 14,
    title: "string",
    category: "string",
    image: "string",
    subTitle: "string",
    author: "string",
    date: "2022-09-12T06:49:14.720Z",
    content: "string"
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    axios({
      method: "post",
      url: "http://www.staging-policymart.co.in/api/v1/contacts",
      data: ["preetham", "fdj", "kjlk", "jlj", "kj"]
      // headers: {
      //   // "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      //   "content-type": "application/x-www-form-urlencoded"
      //   // accept: "*/*",
      //   // "Content-type": "application/json",
      //   // "content-length": 0,
      //   // "Content-Type": "application/json",
      //   // "Content-Type": "application/json;charset=UTF-8",
      //   // "Access-Control-Allow-Origin": "*"
      // }
    })
      .then((response) => console.log(response))
      .catch((error) => {
        console.log(error.response);
      });
  };

  useEffect(() => {
    fetch("/blogs/2")
      .then((response) => response.json())
      .then((data) => {
        setDatas(data);
      });
  }, []);

  const changeHandler = (e) => {
    e.preventDefault();
    setUserData(e.target.value);
  };
  const submithandler = (e) => {
    e.preventDefault();
    // axios
    //   .post(
    //     "/blogs",
    //     {
    //       title: "What is Bike Insurance",
    //       category: "Bike Insurance",
    //       image: "string",
    //       subTitle: "You must and Should Insure Your Bike ",
    //       author: "Preetham Nayak",
    //       date: "2022-09-13T04:57:29.336Z",
    //       content:
    //         "No Content is available currently will be available soon We need some time"
    //     },
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Accept: "application/json"
    //       }
    //     }
    //   )
    //   .then((res) => console.log(res))
    //   .catch((err) => console.log(err));
  };

  const [questions, setQuestions] = useState([
    { id: 1, question: 'Vehicle ownership change in past 12 months?', answer: false },
    { id: 2, question: 'Any claims?', answer: false, showNextQuestion: false },
    { id: 3, question: 'Previous NCB *:', answer: null, options: [0, 10, 20, 30, 40, 50], showNextQuestion: false },
    { id: 4, question: 'Policy expiry date *:', answer: null, showNextQuestion: false },
  ]);

  const toggleAnswer = (id) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((q) =>
        q.id === id ? { ...q, answer: !q.answer } : q
      )
    );
  };

  const handleDropdownChange = (id, selectedValue) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((q) =>
        q.id === id ? { ...q, answer: selectedValue } : q
      )
    );
  };

  return (
    <>
      {/* <NavHeader /> */}
      <form
        action=""
        className="flex flex-col h-full mt-52  items-center m-auto justify-center "
        onSubmit={submithandler}
      >
        <input
          type="text"
          name="name"
          value={userData}
          className="border"
          onChange={changeHandler}
        />
        <input
          type="text"
          name="name"
          className="border"
          value={userData1}
          onChange={changeHandler}
        />
        <input
          type="text"
          name="name"
          className="border"
          value={userData2}
          onChange={changeHandler}
        />
        <button
          className="bg-hero p-5 text-white flex flex-col h-ful l    items-center m-auto justify-center "
          // onClick={handleSubmit}

          type="submit"
        >
          {" "}
          Submit
        </button>
<div className="mt-20">
<label class="toggle-container">
    <input type="checkbox" class="toggle-input"/>
    <span class="toggle-slider"></span>
  </label>

  <div class="custom-checkbox">
    <input type="checkbox" id="myCheckbox"/>
    <label for="myCheckbox"></label>
  </div>
</div>

<div className="popup">
      <div className="popup-content">
        <h2>Questions</h2>
        {questions.map((q) => (
          <div key={q.id} className="question-container">
            <p>{q.question}</p>
            {q.answer !== null && !q.showNextQuestion && (
              <div
                className={`toggle-button ${q.answer ? 'active' : ''}`}
                onClick={() => toggleAnswer(q.id)}
              >
                <div className="slider"></div>
              </div>
            )}
            {q.answer !== null && q.showNextQuestion && (
              <div className="dropdown-container">
                <select
                  value={q.answer}
                  onChange={(e) => handleDropdownChange(q.id, e.target.value)}
                >
                  <option value="" disabled>Select an option</option>
                  {q.options.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {q.answer !== null && q.showNextQuestion && q.id === 4 && (
              <div className="date-input-container">
                <input type="date" value={q.answer} onChange={(e) => handleDropdownChange(q.id, e.target.value)} />
              </div>
            )}
            {q.showNextQuestion && (
              <p>Next question...</p>
            )}
          </div>
        ))}
      </div>
    </div>


      </form>
    </>
  );
};

export default Dummy;

  //   "clientType": step1Values.registered=='yes'?1:0   , // registered in company : yes ?1:0
  //   "lastName": step1Values.lastName,
  //   "foreName": step1Values.firstName,
  //   "dob": dateOfBirth, //date of birth from step2
  //   "gender": step2Values.gender=='male'?1:2, // gender from step2 if male? 1:2
  //   "mobileNo":step1Values.mobile,
  //   "communicationAddress": {
  //     "addressType": 0,
  //     "address1": step3Values.address, //address from step3
  //     "address2": "dfff",
  //     "address3": "gfde",
  //     "cityID": "238881",  // from api values 
  //     "districtID": "321",
  //     "stateID": "21",
  //     "pinCode": step3Values.pincode, // pincode from step3
  //     "country": "India"
  //   },
  //   "permanentAddress": { // add box
  //     "addressType": 0,
  //     "address1": step3Values.address,  //same as before
  //     "address2": "aaaa",
  //     "address3": "strifffffng",
  //     "cityID": "89305",
  //     "districtID": "130",
  //     "stateID": "12",
  //     "pinCode": step3Values.pincode,
  //     "country": "India"
  //   },
  //   "registrationAddress": {
  //     "addressType": 0,      //same as before
  //     "address1": step3Values.address,
  //     "address2": "dfff",
  //     "address3": "gfde",
  //     "cityID": "238881",
  //     "districtID": "321",
  //     "stateID": "21",
  //     "pinCode": step3Values.pincode,
  //     "country": "India"
  //   },
  //   "emailID":  step1Values.email,
  //   "salutation": step1Values.salutation,  // from step 1 salutation
  //   "maritalStatus":step2Values.married=='yes'?"1951":'1952' , //from step 2 married ? yes ? 1951 : 1952
  //   "registeredUnderGST": "1", // step 1 yes ? 1 :0
  //   "gstIn": "27AASCS2460H1Z0", // step1 gst Number
  //   "policy": {
    // "tpPolicyNumber": "PB9867786234554321",
    // "tpPolicyStartDate": "2019-08-22", // 3yrs before end date
    // "tpPolicyEndDate": "2024-08-21", // tp expiry date
    // "tpPolicyInsurer": 2, // tp id 
  //     "businessType":  isCarRegistered=='true'?"5":'1', // new car ? 1 : 5
  //     "cover_From": isCarRegistered?prevYear + "-" + prevMonth + "-" + prevDay:cYear + "-" + cMonth + "-" + cDay,//same to quote
  //     "cover_To": isCarRegistered ? prevYear+1 + "-" + prevMonth + "-" + prevDay:cYear+1 + "-" + cMonth + "-" + cDay,  //same to quote cover date
  //     "branchCode": "9202",
  //     "agentName": "Direct",
  //     // "productCode": "2311",  //same as quote productcode
  //     "productCode":isCarRegistered?  (popUpsFilterData.policyData=="comprehensive" && 'comprehensive') || (popUpsFilterData.policyData=="thirdParty"&&'LiabilityOnly') || (popUpsFilterData.policyData=="ownDamage"&&'standalone ')    :popUpsFilterData.policyData=='bundled'?'Bundled':'LiabilityOnly'  , //based on vehicle type
  //       //same as quote productcode
  //     "otherSystemName": "1",
  //     "isMotorQuote": "false" 
  //   },
  //   "risk": {
  //     "chassis":step4Values.chasisNo, // chasis from step 4
  //     "financeType": step4Values.loan=='yes'?3:2,  // step 4 car loan ? yes : 3 : 2
  //     "stateOfRegistrationID": 21, // pincode appi 
  //     "vehicleVariant": "LXI CNG",
  //     "zone": "A", // kolkata, chennai,delhi , mumbai  == matches ? A : B 
  //     "financierAddress": "Mumbai",  // finan address step 4
  //     "rto_RegionCode": rtoRegionCode, // from quote mh01
  //     "cubicCapacity": 998,
  //     "manufactureYear": manufactureYear,  // manufacture year 
  //     "vehicleMakeID": 192,
  //     "financierName": step4Values.financierName, // step 4
  //     "isPermanentAddressSameasCommAddress": true, // st
  //     "isVehicleHypothicated": false, // if it is on loan ? true : false
  //     "isRegAddressSameasPermanentAddress": true,
  //     "vehicleModelID": 44507, // from api 
  //     "isRegAddressSameasCommAddress": true,
  //     "manufactureMonth": "07", // step 4 date of purchase month
  //     "dateOfPurchase":`${manufactureYear}-07-01`, // manfuacture year
  //     "engineNo": step4Values.engineNo, // engine NUm from step4
  //     "rtolocationID": 571,
  //     "idv": 333301  // details window cover value  need to fixed // from tile selected
  //   },
  //   "vehicle": {
  //     "seatingCapacity": 4,
  //     "registration_Number":vehicleNumber, // from quote
  //     "registration_date": step4Values.carRegistrationDate, /// from step 4 car reg
  //     "typeOfFuel": 5 // from step 1 need to fixed
  //   },
  //   "cover": {
  //     "electricalItemsTotalSI": "10000.00",
  //     "nonElectricalItemsTotalSI": "1900",
  //     "sumInsured": "7500",
  //     "antiTheftDeviceDiscount": {
  //       "noOfItems": "0",
  //       "checked": true
  //     },
  //     "paCoverToOwner": {
  //       "nomineeName": "ABC",
  //       "nomineeDOB": "17/09/1990",
  //       "nomineeRelationship": "Sister",
  //       "cpacovertenure": "1"
  //     },
  //     "paToNamedPassenger": {
  //       "noOfItems": "3",
  //       "sumInsured": "100000"
  //     },
  //     "paToUnNamedPassenger": {
  //       "noOfItems": "4",
  //       "sumInsured": "100000"
  //     },
  //     "liabilityToPaidDriver": {
  //       "noOfItems": "5"
  //     },

  
  //     "tppdCover": {
  //       "sumInsured": "100000",
  //       "checked": true
  //     },
  //     "nilDepreciationCoverage": {
  //       "applicableRate": "0.55"
  //     },
  //     "securePremium": {
  //       "applicableRate": "0.55"
  //     },
  //     "securePlus": {
  //       "applicableRate": "0.55"
  //     },
  //     "bifuelKit": {
  //       "sumInsured": 20000,
  //       "islpgCng": true,
  //       "policyCoverDetailsID": {},
  //       "isChecked": true,
  //       "fueltype": "CNG",
  //       "isMandatory": true
  //     },
  //     "antiTheftDeviceFitted": true,
  //     "electricItemFitted": true,
  //     "voluntaryDeductableOpted": true,
  //     "patoOwnerDriverCoverd": true,
  //     "patoUnnamedPassengerCovered": true,
  //     "liabilityToPaidDriverCovered": true,
  //     "patoDriverCovered": true,
  //     "nonElectricalItemFitted": true,
  //     "biFuelKit": true,
  //     "basicLiability": true,
  //     "patoNamedPassenger": true,
  //     "tppcover": true,
  //     "basicOdCoverage": true
  //   },
  //   "previousInsuranceDetails": {
  //     "prevYearInsurer": step4Values.prevPolicyInsurer,  // from step 4 dropdown
  //     "isPreviousPolicyDetailsAvailable": "YES",
  //     "prevYearPolicyNo": step4Values.prevPolicyNo, // prev policy num
  //     "prevYearPolicyStartDate": curDay  + "/" + curMonth + "/" + +curYear, // -1 year
  //     "prevYearPolicyEndDate": prevDay  + "/" + prevMonth + "/" + prevYear  //from modal window
  //   },
  //   "ncbEligibility": {
  //     "currentNCB": `${+registeredModelValues.prevNCB+1}`, //  modal windiw +1 prev ncb
  //     "ncbeligibilityCriteria":  prevClaimValue=='Yes'?'1':'2',
  //     "previousNCB": registeredModelValues.prevNCB 
  //   },
  //   "userId": "100002",
  //   "sourceSystemID": "100002",
  //   "authToken": "Pass@123",
  //   "existingRGICustomer": true
  // },
















  // .custom-checkbox {
  //   position: relative;
  //   display: inline-block;
  //   top:3px;
  // }
  
  // .custom-checkbox input {
  //   position: absolute;
  //   opacity: 0;
  //   cursor: pointer;
  // }
  
  // .custom-checkbox label {
  //   /* position: relative; */
  //   display: inline-block;
  //   width: 20px;
  //   height: 20px;
  //   /* background-color: rgba(44, 180, 160, 0.732); */
  //   border: 2px solid #cbc1c1;
  //   border-radius: 4px; /* Added rounded corners */
  //   transition: background-color 0.3s ease-in-out, transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  // }
  
  // .custom-checkbox label:after {
  //   content: "";
  //   position: absolute;
  //   display: none;
  // }
  
  // .custom-checkbox input:checked + label {
  //   /* background-color: #00A78E; */
  //   transform: scale(1.1);
  //   opacity: 0.8;
    
  // }
  
  // .custom-checkbox input:checked + label:after {
  //   display: block;
  //   content: "";
  //   position: absolute;
  //   left: 7px;
  //   top: 3px;
  //   width: 5px;
  //   height: 10px;
  //   border: solid #00A78E; /* Set the color of the tick mark */
  //   border-width: 0 2px 2px 0;
  //   animation: popUp 0.3s ease-in-out forwards;
  // }
  
  // @keyframes popUp {
  //   from {
  //     transform: rotate(45deg) scale(0);
  //   }
  //   to {
  //     transform: rotate(45deg) scale(1);
  //   }
  // }
  