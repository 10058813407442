import React,{useState,useEffect} from "react";
import NavHeader from "../NavHeader";
import {
  AiOutlineCheck,
  AiOutlineMail,
  AiOutlinePrinter,
  AiOutlineWhatsApp
} from "react-icons/ai";
import axios from "axios";
import BlogCard from "../../UI/BlogCard";
import { useStore } from "react-redux";
import { useNavigate } from 'react-router-dom';
const PaymentSuccess = ({rsaPaymentData=''}) => {



  const [pdfPath, setPdfPath] = useState()
  const [jwtToken, setJwtToken] = useState(localStorage.getItem('jwt_token'))
  const [isJWTCall, setIsJWTCall] = useState(false)
  const [tokenLoaded, setTokenLoaded] = useState(false)
  const [proposalType, setProposalType] = useState()
  const [pdfURL, setPdfURL] = useState()
const [userEmail, setUserEmail] = useState()
const [paymentValues, setPaymentValues] = useState(null)
const [isVehicleRegistered, setIsVehicleRegistered] = useState()
const [initialPolicyType, setInitialPolicyType] = useState({})
const [popUpsFilterData, setPopUpsFilterData] = useState({})
const [isDownload, setIsDownload] = useState(false)
const [isDownloadComplete, setIsDownloadComplete] = useState(false)
const [productCodeValue, setProductCodeValue] = useState(false);
const [closeNav, setCloseNav] = useState(false)
const [insurerName, setInsurerName] = useState()
const [actionType, setActionType] = useState('')
const [clearValues, setClearValues] = useState(false)
const [apiQuoteData, setApiQuoteData] = useState()
const [rsaPaymentValue, setRsaPaymentValue] =useState()
const [viewPdf, setViewPdf] = useState()


/* -------------------  API ENDPOINT -------------- */
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
/* ---------------------------------- -------------- */


const actiontype = sessionStorage.getItem("action_type")
const tileType = localStorage.getItem('tile_Type_price')
const paymentIdValues = localStorage.getItem("payment_Data")
const paymentIdValues2 = localStorage.getItem("bike_payment_Data")
const carRegistered = localStorage.getItem("isCarRegistered");
const bikeRegistered = localStorage.getItem("isBikeRegistered");
const prevPolicyType = localStorage.getItem('car_Info')
const filterData =  sessionStorage.getItem("inputs_policyType");
const filterData2 = localStorage.getItem("bike_inputs_policyType");
const insurername = localStorage.getItem('step-1_Values')
const insurername2 = localStorage.getItem('bike_step-1_Values')
const apiData= localStorage.getItem("apiData")


// const carOwnerDetails = localStorage.getItem("step-1_Values")

  // useEffect(()=>{
  //   setUserEmail(JSON.parse(carOwnerDetails))
  // },[carOwnerDetails])

  useEffect(() => {
    setActionType(actiontype)
  }, [actiontype]);

useEffect(()=>{
  setProposalType(tileType)
  setRsaPaymentValue(rsaPaymentData)
  setInitialPolicyType(JSON.parse(prevPolicyType))
},[tileType, paymentIdValues, insurername, filterData, prevPolicyType, apiData, rsaPaymentData])

useEffect(()=>{
  if(actionType=='Car'){
    setPopUpsFilterData( filterData)

setInsurerName(JSON.parse(insurername))
setIsVehicleRegistered (JSON.parse(carRegistered))
    
    setPaymentValues(JSON.parse(paymentIdValues))
  return ;

  }
  if(actionType==='Bike') {
setInsurerName(JSON.parse(insurername2))
setIsVehicleRegistered (JSON.parse(bikeRegistered))

    setPaymentValues(JSON.parse(paymentIdValues2))
  setPopUpsFilterData( filterData2)

  }
     



},[actionType, bikeRegistered, carRegistered, filterData, filterData2, insurername, insurername2, paymentIdValues, paymentIdValues2])


const navigate = useNavigate();

// Function to handle custom back button behavior
const handleCustomBack = () => {
  // You can add custom logic here, such as redirecting to the home page
  navigate('/'); // Replace with the URL you want to redirect to
};

// Set up an event listener for the browser's back button
window.onpopstate = () => {
  // Call the custom back button handler
  handleCustomBack();
};


useEffect(() => {
  const onStorage = () => {
    setJwtToken(localStorage.getItem('jwt_token'));
  };

  window.addEventListener('storage', onStorage);

  return () => {
      window.removeEventListener('storage', onStorage);
  };
}, []);
//   
const urlparam =window.location.search


//  console.log(userEmail.email)

 
/* ------------- Api For Purchase Data ---------------- */
useEffect(()=>{

  paymentValues&&urlparam&&insurerName&&axios(
      
    {
    
    url:`${API_ENDPOINT}/quotes/purchase`,
    method:'POST',
   
    data:{
      "proposalNo":paymentValues&&paymentValues.ProposalNo,  // proposal api 
    "policyNo":urlparam&&`${urlparam.split('|')[1]}`, /// url 
    "transactionStatus":"success", //
    "statusId":"1", // null of others
    "insurerName":insurerName&&`${insurerName.firstName+insurerName.lastName}` // compnay
          },
       
        headers: {
          'authorization':jwtToken,
          "Content-Type": "application/json",
        
      }
    
     } ).then((response)=>{
  
  })
  .catch((err)=>{
    console.log(err)
  })

},[ insurerName, paymentValues, urlparam])
 
 
  // console.log('test web',)

  // const urlParams = new URLSearchParams("http://localhost:3000/payment/complete?Output=1");
  //     console.log('test web',urlParams.get('Output'));




  const download = (e) => {
    e.preventDefault()
    setIsDownload(true)


    /* --------------------------- PDF Download For Reliance ----------------------------------- */
    // tileType == "Reliance" &&  axios(
      
    //   {
      
    //   url:`${API_ENDPOINT}/schedule`,
    //   method:'POST',
    //   responseType:'blob',
      
  
    
      
    //   data:{
    //     // "policyNumber": `920222323110003800`,
    //     "policyNumber": `${urlparam.split('|')[1]}`,
    //     // "productCode":'2311'
    //     "productCode": (isVehicleRegistered=='true'||isVehicleRegistered==true)
    //     ? ((popUpsFilterData==undefined||null)
    //         ?initialPolicyType.policy  == "comprehensive" && 2311
    //         : popUpsFilterData == "comprehensive" && 2311) ||
    //       ((popUpsFilterData
    //         ?initialPolicyType.policy   == "thirdParty" && 2347
    //         :popUpsFilterData  == "thirdParty") &&
    //         2347) ||
    //       (popUpsFilterData
    //         ?initialPolicyType.policy   == "ownDamage" && 2309
    //         : popUpsFilterData == "ownDamage" && 2309)
    //     : (popUpsFilterData
    //         ?initialPolicyType.policy   == "bundled" && 2374
    //         : popUpsFilterData == "bundled" && 2374) ||
    //       (popUpsFilterData
    //         ?initialPolicyType.policy  == "thirdParty" && 2347
    //         : popUpsFilterData == "thirdParty" && 2347),  
    //         },
         
    //       headers: {
    //         'authorization':jwtToken,
    //         // "Content-Type": "application/json",
    //         // accept: "application/pdf",
    //         "x-insurance-provider":1 // 1 : reliance , 2: godigit , 3 : rsa
          
    //     }
      
    //    } ).then((response)=>{
    //   const url = window.URL.createObjectURL(new Blob([response.data]))

    //   const link = document.createElement('a')
    //   link.href=url
    //   link.setAttribute('download',`${urlparam.split('|')[1]}.pdf`)
    //   document.body.appendChild(link)
    //   setPdfURL(url)
    //   link.click()
    //   setIsDownloadComplete(true)
    //   setIsDownload(false)
    //   setClearValues(urlparam&&true)
    // })
    // .catch((err)=>{
    //   console.log(err)
    // })


  tileType == "Reliance" &&  axios(
      
{
      
  // url:`${API_ENDPOINT}/schedule`,
  url:`${API_ENDPOINT}/schedule/reliance`,
  method:'POST',
  // responseType:'blob',
  


  
  data:{
    // "policyNumber": `920222323110003800`,
    "policyNumber":urlparam&&`${urlparam.split('|')[1]}`
 
        },
     
      headers: {
        'authorization':jwtToken,
        "Content-Type": "application/json",
        accept: "application/pdf",
        "x-insurance-provider":1 // 1 : reliance , 2: godigit , 3 : rsa
      
    }
  
   } ).then((response)=>{

    // console.log('rel',response)
    window.location.href=response.data.DownloadLink
  // const url = window.URL.createObjectURL(new Blob([response.data]))

  // const link = document.createElement('a')
  // link.href=url
  // link.setAttribute('download',`${urlparam.split('|')[1]}.pdf`)
  // document.body.appendChild(link)
  // setPdfURL(url)
  // link.click()
  setIsDownloadComplete(true)
  setIsDownload(false)
  // setClearValues(urlparam&&true)
})
.catch((err)=>{
  console.log(err)
})

    /* --------------------------- PDF Download For Reliance End Here ----------------------------------- */


    /* --------------------------- PDF Download For Godigit ----------------------------------- */
  tileType == "GoDigit" &&  axios(
      
      {
      
      url:`${API_ENDPOINT}/schedule`,
      method:'POST',
      responseType:'blob',
      
  
    
      
      data:{
        "policyNumber":'V22DB06E32119AB967CA95BC1FDD847112290F9B23F8D8C26A10D6A5EF1A2F8E36F488F441D8F9B923B021D40D5D153EBC',
  "productCode":  20102, 
            },
         
          headers: {
            'authorization':jwtToken,
            // "Content-Type": "application/json",
            // accept: "application/pdf",
            "x-insurance-provider":2// 1 : reliance , 2: godigit , 3 : rsa
          
        }
      
       } ).then((response)=>{
      const url = window.URL.createObjectURL(new Blob([response.data]))

      const link = document.createElement('a')
      link.href=url
      link.setAttribute('download',`godigitPayment.pdf`)
      document.body.appendChild(link)
      setPdfURL(url)
      link.click()
      setIsDownloadComplete(true)
      setIsDownload(false)

    })
    .catch((err)=>{
      console.log(err)
    })
  

  
 /* --------------------------- PDF Download For RSA Start Here ----------------------------------- */
 tileType == "RSA" &&  axios(
      
  {
  
  url:`${API_ENDPOINT}/schedule/rsa`,
  method:'POST',
  // responseType:'blob',
  


  
  data:{
      "businessType": "NB",
      "expiryDate": rsaPaymentValue&&rsaPaymentValue.expiryDate,
      "force": true,
      "lineOfBusiness": "Motor",
      "pdfType": "PurchasedPdf",
      "policyNumber": rsaPaymentValue&&rsaPaymentValue.policyNumber,
      "tinyURL": "no"
      
        },
     
      headers: {
        'authorization':jwtToken,
        // "Content-Type": "application/json",
        // accept: "application/pdf",
        "x-insurance-provider":3// 1 : reliance , 2: godigit , 3 : rsa
      
    }
  
  } ).then((response)=>{
    //  window.location.href=response.data.data.tinyUrlMotor.shorturl
     
     setIsDownloadComplete(true)
     setIsDownload(false)

    //  setViewPdf(response.data.tinyUrlMotor.shorturl)
     const byteCharacters = atob(response.data.data.base64File);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = rsaPaymentValue&&rsaPaymentValue.policyNumber;
    link.click();
   
    setClearValues(urlparam&&true)
  
})
.catch((err)=>{
  console.log(err)
})

}

/* --------------------------- PDF Download For RSA End Here  ----------------------------------- */





   
 /* -------------------- Clear the Localstorage Values and session storage Values --------------------- */
 useEffect(()=>{
  const keysToKeep = ['isCarRegistered',  'isBikeRegistered','jwt_token','car_Info','tile_Type_price' ];
for (let i = localStorage.length - 1; i >= 0; i--) {
  const key = localStorage.key(i);
  if (!keysToKeep.includes(key)) {
  //  clearValues&& localStorage.removeItem(key);
  }
}
  
  
 },[clearValues])


  // const handleMail = ()=>{
  //   let body = document.getElementById('body')
  //   alert(body)
  // }

  
  return (
    <div id="body">
      <>
        <NavHeader   dropClose={ [closeNav, setCloseNav] }
 />
        <main
          className="lg:w-full py-20 lg:py-24 bg-no-repeat bg-cover overflow-x-hidden   "  onClick={()=>{
            setCloseNav(false)
          }}
          //   style={{ backgroundImage: `url(${LandingBackground})` }}
        >
          <div>
        {/* <iframe title="pdf" width="100%" height="600px" srcDoc={pdfPath}></iframe> */}
      </div>
          <div className="slider-in  justify-center bg-white lg:m-auto drop-shadow-xl lg:w-[40%]   mx-4   rounded-xl lg:px-4 py-10 ">
            {isDownload?
            <>
               <h1 className=" text-sm lg:text-3xl font-semibold text-center pb-10">
                Please wait while  we are downloading your Policy
            </h1>
             <div class="dot-container">
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </div> 
            </>
  
  :
  <>
  <AiOutlineCheck className="bg-hero text-white m-auto w-16 h-16 rounded-full p-2 font-bold" />
            <h1 className="text-hero text-2xl lg:text-3xl font-semibold text-center pt-9  pb-4 lg:pb-14">
            {isDownloadComplete? '': 'Payment Successful' }
            </h1>
            <h1 className=" text-sm lg:text-3xl font-semibold text-center">
              {isDownloadComplete? 'Your Policy has been successfully downloaded':' Your Policy Is Ready To Download'}
            </h1>
                    

                    {/* <a download  href={pdfPath}> */}
            <button className="w-52 font-semibold flex justify-center m-auto bg-hero text-white lg:text-xl rounded-full p-3 my-8" onClick={(e)=>download(e)} >
          {isDownload?'Loading...': tileType==='RSA'?'View PDF':'Download PDF'} 
            </button>
            {/* </a> */}
            <button className="w-52 font-semibold flex justify-center m-auto text-hero border border-hero   rounded-full p-2 my-8 lg:hidden">
              Share
            </button>
           <div className="hidden">

            <h1 className="opacity-[0.4] text-center text-3xl hidden lg:block py-6">
              OR
            </h1>
            <div className="hidden lg:flex justify-center space-x-4 text-3xl text-hero">
             <a href={`mailto:bpmnayak143@gmail.com`}>
             <AiOutlineMail /> </a> 
              <AiOutlinePrinter />
              <AiOutlineWhatsApp />
           </div>
            </div> 
  </>
            }
        
          </div>
        </main>
      </>
    </div>
  );
};

export default PaymentSuccess;
