import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import LandingBackground from "../../../../assets/Svg/LandingPageBck.svg";
import InsuranceHeader from "../../../../UI/insurance/InsuranceHeader";
import InsuranceTracker from "../../../../UI/insurance/InsuranceTracker";
import DropDownPP from "../../../customs/DropDownPP";
import InputPP from "../../../customs/InputPP";
import RadioPP from "../../../customs/RadioPP";
import NavHeader from "../../../NavHeader";

const CarInfoStep1Manual = () => {
  // const [ownerName, setOwnerName] = useState("");
  const [kycData1, setKycData1] = useState(null)

  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    salutation:'',
    firstName:'',
    lastName:'',
    email:'',
    mobile:''

  });
  const [proceed, setProceed] = useState(false);

  const [ownerFirstNameError, setOwnerFirstNameError] = useState(false);
  const [ownerLastNameError, setOwnerLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [gstinError, setGstinError] = useState(false)
  const [salutationError, setSalutationError] = useState(false)
  const [mobileError, setMobileError] = useState(false);
  const [isPopWindowOpen, setIsPopWindowOpen] = useState(false)
const [updateAPiValues, setUpdateAPiValues] = useState()
  const [registeredError, setRegisteredError] = useState(false);
  const [verifyNumberError, setVerifyNumberError] = useState(false);
  const [isVerifyTrue, setIsVerifyTrue] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [otpSentOnce, setOtpSentOnce] = useState(false);
  const [isVerifyOtp, setIsVerifyOtp] = useState(false);
const [typeOfPolicy, setTypeOfPolicy] = useState()
  const [testingInput, setTestingInput] = useState(false);

  const reliaceKyc = localStorage.getItem("relianceKyc_apidata")
  const rsaKyc = localStorage.getItem('rsaKyc_apidata')
  const tileSelected = localStorage.getItem('tile_Type_price')

  const inputValues = localStorage.getItem("step-1_Values");
// console.log('reliance',kycData1.kyc_data.CKYC.result.PERSONAL_DETAILS.FNAME)


  useEffect(()=>{


    setUpdateAPiValues(inputValues)
    setTypeOfPolicy(tileSelected)
  },[reliaceKyc,tileSelected,inputValues])
  
  useEffect(()=>{
    if(tileSelected=='Reliance'){
      
      setKycData1(JSON.parse(reliaceKyc))
    }
    
  },[reliaceKyc, tileSelected])

  /* ------------------------- Prefilling Input Values  Based on Kyc data Start Here ----------------------- */
  useEffect(()=>{
    
    updateAPiValues==null&& typeOfPolicy=='Reliance'&& setInputs({
      ...inputs,
      firstName: kycData1&&kycData1.kyc_data.CKYC.result.PERSONAL_DETAILS.FNAME,
      lastName:kycData1&&kycData1.kyc_data.CKYC.result.PERSONAL_DETAILS.LNAME,
      email:kycData1&&kycData1.kyc_data.CKYC.result.PERSONAL_DETAILS.EMAIL,
      mobile:kycData1&&kycData1.kyc_data.CKYC.result.PERSONAL_DETAILS.MOB_NUM,
    });
    updateAPiValues==null&& typeOfPolicy=='RSA'&& setInputs({
      ...inputs,
      firstName: kycData1&&kycData1.firstName,
      lastName:kycData1&&kycData1.lastName,
      // email:kycData1&&kycData1.kyc_data.CKYC.result.PERSONAL_DETAILS.EMAIL,
      // mobile:kycData1&&kycData1.kyc_data.CKYC.result.PERSONAL_DETAILS.MOB_NUM,
    });
  },[updateAPiValues, kycData1, typeOfPolicy])
  
  /* ------------------------- Prefilling Input Values  Based on Kyc data End Here ----------------------- */
  // console.log('input',
  // updateAPiValues&&(Object.keys(JSON.parse(updateAPiValues)).length==null),updateAPiValues!==null)


 

  var validRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const mobileNumberRegex = /^[6789]\d{9}$/;

  const handleChange = (e) => {
    const name = e.target.name;

    const value = e.target.value;

    setInputs((values) => ({
      ...values,
      [name]: name === "registered" ? e.target.id : value
    }));
  };
 
  const handleSubmit = (e) => {
    e.preventDefault();

 
    if (/^[a-zA-Z ]*$/.test(inputs.firstName) == false) {
      setOwnerFirstNameError(true);
      setTestingInput(true);
    } else {
      // setOwnerFirstNameError(false);
    }
    if (/^[a-zA-Z ]*$/.test(inputs.lastName) == false) {
     setOwnerLastNameError(true);
     setTestingInput(true);
    } else {
    //  setOwnerLastNameError(false);
    }
    // if (inputs.gstin == undefined || inputs.gstin == "") {
    //   setGstinError(true);
    // } else {
    //   setGstinError(false);
    // }
    if (inputs.salutation == undefined || inputs.gstin == "") {
      setSalutationError(true);
    }
    if (inputs.email == undefined || inputs.email == "") {
      setEmailError(true);
    } 
    if (inputs.mobile == undefined || inputs.mobile == "") {
      setMobileError(true);
    } 
    if (inputs.verifyNumber == undefined || inputs.verifyNumber == "") {
      setVerifyNumberError(true);
    } 



    if (
      Object.values(inputs).length >=5&&
      inputs.firstName!==null&&  inputs.firstName.length > 0&&
      inputs.lastName!==null&& inputs.lastName.length > 0 &&
      inputs.salutation.length > 1 &&
      inputs.mobile!==null&& inputs.mobile.length > 1 &&
      inputs.email!==null&&inputs.email.length > 1 
      // (typeOfPolicy==='RSA'&&inputs.mobile.length > 1) &&
      // (typeOfPolicy==='RSA'&&inputs.verifyNumberError.length > 1)
    ) {
      localStorage.setItem("step", 1);
      localStorage.setItem("step-1_Values", JSON.stringify(inputs));
    }
    // if (Object.values(inputs).length >= 5) {
    //   localStorage.setItem("step", 1);
    //   localStorage.setItem("step-1_Values", JSON.stringify(inputs));
    // }

   

   !salutationError &&!ownerFirstNameError && !ownerLastNameError &&!mobileError && !emailError&& navigate(proceed ? "/carinsurance/car-profile/step-2" : "");
  };

  useEffect(() => {
    inputValues && setInputs(JSON.parse(inputValues));
  }, [inputValues]);

  useEffect(() => {
    if (/^[a-zA-Z ]*$/.test(inputs.firstName) == false||(/^[a-zA-Z ]*$/.test(inputs.lastName) == false)) {
      setOwnerFirstNameError(true);
      setProceed(false);
    }
    if (/^[a-zA-Z ]*$/.test(inputs.lastName) == false) {
      setProceed(false);
      setOwnerLastNameError(true);

    }
  }, [inputs, proceed]);

 
  useEffect(() => {

    if (
      Object.values(inputs).length >=5&&
      inputs.firstName!==null&&  inputs.firstName.length > 0 &&
      inputs.lastName!==null&& inputs.lastName.length > 0 &&
       
      inputs.salutation.length > 1 &&
      inputs.mobile!==null&& inputs.mobile.length > 1 &&
      inputs.email!==null&& inputs.email.length > 1 
     
    ) {
      setProceed(true);
    }else{
      setProceed(false)
    }
    
    
  }, [inputs,ownerFirstNameError,ownerLastNameError,emailError,gstinError]);

  useEffect(() => {
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);

    if (inputs.verifyNumber == undefined || inputs.verifyNumber == "") {
      setIsVerifyOtp(false);
    } else {
      setIsVerifyOtp(true);
    }
    if (inputs.firstName == undefined || inputs.firstName == "") {
      setOwnerFirstNameError(true);
      // localStorage.removeItem("step-1_Values");
    } 
    else {
      setOwnerFirstNameError(false);
    }
    if (inputs.lastName == undefined || inputs.lastName == "") {
        setOwnerLastNameError(true);
      setProceed(false);

      // localStorage.removeItem("step-1_Values");
    } 
    else {
       setOwnerLastNameError(false);
    }
    if(proceed) {
       setOwnerLastNameError(false);
      setOwnerFirstNameError(false);

    }
  }, [isVerifyTrue,proceed,inputs, isVerifyOtp]);


useEffect(()=>{
  /* --------------- Validating Mobile Number ------------------ */
if (
 Object.keys(inputs).includes('mobile')&&
 !mobileNumberRegex.test(inputs.mobile)
) {
 setMobileError(true)
}else if (Object.keys(inputs).includes('mobile')&&
inputs.mobile.length ==10){
 setMobileError(false)
}

/* ----------------- Validating Email Id ---------------------- */

if (
 Object.keys(inputs).includes('email')&&
 inputs.email.toLowerCase().match(validRegex)
) {
 // setProceed(true);
 setEmailError(false)
}else if(Object.keys(inputs).includes('email')) {
 // setProceed(false);
 setEmailError(true)
}
},[inputs, validRegex])



  

  return (
    <>
      {/* <NavHeader /> */}
      <main
          className={`lg:w-full   overflow-x-hidden  lg:pt- bg-no-repeat bg-cover  ${isPopWindowOpen?'h-screen ':''} `}
        style={{ backgroundImage: `url(${LandingBackground})` }}
      >
        <InsuranceHeader popwindow={[isPopWindowOpen, setIsPopWindowOpen] } />
        <div className="pt-5 lg:pt- pb-16 lg:pb-">
          <InsuranceTracker />
        </div>

        <form onChange={handleChange}  className={`${isPopWindowOpen?'hidden ':''}`}>
          <div className="slider-in justify-center bg-white mx-5 lg:m-auto drop-shadow-lg lg:w-[55%] overflow-x-hidden    rounded-xl p-4 ">
            <div className="flex items-center space-x-2 pb-4">
              <div className="w-6 h-6 lg:w-8 lg:h-8 bg-hero text-white rounded-full items-center flex justify-center">
                1
              </div>
              <h1 className="m  text-solid lg:text-xl  font-semibold">
                Car Owner Details
              </h1>
            </div>
            <hr className="text-[#DBDBDB] pb-4" />
            <div className="">
              <div className="lg:mb-3">

              {/* <RadioPP
                title="Car Registered In Company Name?"
                errorState={[registeredError, setRegisteredError]}
                value={inputs.registered}
                objValues={[
                  {
                    id: "yes",
                    name: "registered",
                    value: "Yes"
                  },
                  {
                    id: "no",
                    name: "registered",
                    value: "No"
                  }
                ]}
                /> */}
                </div>
              
                  {/* <InputPP
                  className="w-full lg:w-64   px-3 py-2 my-2 rounded-md"
                  labelClassName="text-[#959595] font-medium pl-1 "
                  id="gstin"
                      label="Enter the GST in for the company"
                  type="text"
                  parenetClassName={`${inputs.registered=='yes'?'':'hidden'}`}
                  placeholder="GST'in Number"
                  // state={[ownerName, setOwnerName]}
                  value={inputs.gstin}
                  changeFunction={handleChange}
                  errorState={ [gstinError, setGstinError]}
                  
                /> */}

              <div className="my-2 lg:my-4 lg:flex lg:gap-5">
              <DropDownPP
                      className="w-full lg:w-44 border border-[#d7d5d5] text-sm lg:text-base rounded-md focus:outline-none px-2 lg:px-3 py-1.5 lg:py-2.5 my-2"
                      labelClassName="text-[#959595]   text-sm lg:text-base  font-medium pl-1"
                      // parentClassName={`${typeOfPolicy=='Reliance'?'':'hidden'}`}
errorState={[salutationError, setSalutationError]}
                      label="Salutation"
                      id="salutation"
                      required={true}
                      value={inputs.salutation}
                      options={[
                        "Mr",
                        "Mrs",
                        "Miss",
                        "Master",
                        
                      ]}
                      />
                <InputPP
                  className="w-full lg:w-64  text-sm lg:text-base px-2 lg:px-3 py-1.5 lg:py-2.5 my-2 rounded-md"
                  labelClassName="text-[#959595] w-full   text-sm lg:text-base font-medium pl-1 "
                  id="firstName"
                  label= ' Car Owner FirstName (As Per RC Copy )'
                  type="text"
                  placeholder="Car Owner FirstName"
                  // state={[ownerName, setOwnerName]}
                  value={inputs.firstName}
                  changeFunction={handleChange}
                  errorState={ [ownerFirstNameError, setOwnerFirstNameError]}
                  // disableTrue={kycData1&&kycData1.kyc_data.CKYC.result.PERSONAL_DETAILS.FNAME&&true}
                />

</div>
                <InputPP
                  className={`  w-full lg:w-64   text-sm lg:text-base px-2 lg:px-3 py-1.5 lg:py-2.5 mb-2 rounded-md`}
                  labelClassName="text-[#959595]   text-sm lg:text-base  font-medium pl-1 "
                  // parenetClassName={`${typeOfPolicy=='RSA'?'':'hidden'}`}
                  id="lastName"
                  label="Car Owner LastName "
                  type="text"
                  placeholder="Car Owner LastName"
                  // state={[ownerName, setOwnerName]}
                  value={inputs.lastName}
                  changeFunction={handleChange}
                  errorState={ [ownerLastNameError, setOwnerLastNameError]}
                  // disableTrue={kycData1&&kycData1.kyc_data.CKYC.result.PERSONAL_DETAILS.LNAME&&true}
                />
               
             
              <InputPP
                className="w-full    text-sm lg:text-base px-2 lg:px-3 py-1.5 lg:py-2.5 my-2 rounded-md"
                labelClassName="text-[#959595]   text-sm lg:text-base font-medium pl-1 "

                id="email"
                label="Email address"
                type="email"
                placeholder="Enter Email"
                value={inputs.email }
                changeFunction={handleChange}
                errorState={[emailError, setEmailError]}
              />
               <InputPP
                  className="w-full lg:w-80   text-sm lg:text-base px-2 lg:px-3 py-1.5 lg:py-2.5 my-2 rounded-md"
                  parenetClassName={``}

                  labelClassName="text-[#959595]   text-sm lg:text-base font-medium pl-1 "
                  id="mobile"
                  label="Mobile number"
                  type="number"
                  placeholder="Enter Mobile Number"
                  value={inputs.mobile}
                  changeFunction={handleChange}
                  errorState={[mobileError, setMobileError]}
                />
            
            </div>
            {/* <h1>Please Fill All Inputs</h1> */}
          </div>
          <div className="flex justify-center space-x-5 py-8 ">
            {/* <Link to={proceed ? `/carinsurance/car-profile/step-2` : ""}>
              <input type="submit" /> */}
            <input
              type="submit"
              className="bg-hero w-max px-5 py-2 rounded-full text-white focus:outline-none cursor-pointer "

              onClick={handleSubmit}
              value=" Continue to Personal Details"
            />
            {/* </Link> */}
          </div>
        </form>
      </main>
    </>
  );
};

export default CarInfoStep1Manual;
