import React, { useState, useEffect } from "react";
import LoadingSpinner from "../paymentStatus/LoadingSpinner";
import PaymentFailure from "../paymentStatus/PaymentFailure";
import PaymentSuccess from "../paymentStatus/PaymentSuccess";

const BikeInfoStep6 = () => {
  const [stateValue, setstateValue] = useState();
  // function parse(input) {
  //   function parseSingle(input) {
  //     var parts = input.split("|"),
  //       part,
  //       record = {};

  //     for (var i = 0; i < parts.length; i++) {
  //       part = parts[i].split("=");
  //       record[part[0]] = part[1];
  //     }

  //     return record;
  //   }

  //   var parts = input.split("++"),
  //     records = [];

  //   for (var i = 0; i < parts.length; i++) {
  //     records.push(parseSingle(parts[i]));
  //   }

  //   return records;
  // }
  // function parse2(input) {
  //   function parseSingle(input) {
  //     var parts = input.split("||"),
  //       part,
  //       record = {};

  //     for (var i = 0; i < parts.length; i++) {
  //       part = parts[i].split("=");
  //       record[part[0]] = part[1];
  //     }

  //     return record;
  //   }

  //   var parts = input.split("++"),
  //     records = [];

  //   for (var i = 0; i < parts.length; i++) {
  //     records.push(parseSingle(parts[i]));
  //   }

  //   return records;
  // }

  // // var string = window.location.href;
  // var string =
  //   "https://staging-policymart.co.in/payments/complete?Output=0|920222223700001328|C505092200167|0|CCAvenue|R05092200160|Failure|";

  // var parsed1 = parse(string);
  // var parsed2 = parse2(string);
  // console.log(
  //   parsed1[0][
  //     "https://staging-policymart.co.in/payments/complete?Output"
  //   ].includes("Success")
  // );

  // useEffect(() => {
  //   if (
  //     parsed1[0]["https://staging-policymart.co.in/payments/complete?Output"] ==
  //       1 &&
  //     parsed2[0][
  //       "https://staging-policymart.co.in/payments/complete?Output"
  //     ].includes("Success")
  //   ) {
  //     setstateValue(1);
  //   } else {
  //     setstateValue(0);
  //   }
  // }, [stateValue]);

  // console.log(stateValue);

  // console.log(
  //   parsed1[0][
  //     "https://staging-policymart.co.in/payments/complete?Output"
  //   ].includes("Failure")
  // );

  const urlValue = window.location.search
  // const urlValue =
  //   "https://staging-policymart.co.in/payments/complete?Output=0|920222223700001328|C505092200167|0|CCAvenue|R05092200160|Success|";
  useEffect(() => {
    if (urlValue.includes("Success")) {
      setstateValue(1);
    } else {
      setstateValue(0);
    }
  }, []);
  return (
    <div>
      {stateValue == 0 ? <PaymentFailure /> : <PaymentSuccess />}
      {/* <PaymentSuccess /> */}
      {/* <PaymentFailure /> */}
      {/* <LoadingSpinner /> */}
    </div>
  );
};

export default BikeInfoStep6;
