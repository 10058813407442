import React, { useState, useEffect } from "react";
import DropDownPP from "../customs/DropDownPP";
import { useNavigate } from "react-router";
import InputPP from "../customs/InputPP";
import axios from "axios";
import BikeInsuranceHeader from "../../UI/insurance/BikeInsuranceHeader";
import InsuranceHeader from "../../UI/insurance/InsuranceHeader";
import { AiOutlineCheck } from "react-icons/ai";
import { useSelector } from "react-redux";

const RelianceKyc = () => {
  const [inputs, setInputs] = useState([]);
  const [dobValueError, setDobValueError] = useState();
  const [panNoError, setPanNoError] = useState();
  const [panNoCustErr, setPanNoCustErr] = useState()
  const [isInputChanged, setIsInputChanged] = useState(false)
  const [jwtToken, setJwtToken] = useState(localStorage.getItem("jwt_token"));
  const [changeButton, setChangeButton] = useState(false);
  const [redirectRsaForVerify, setRedirectRsaForVerify] = useState()
  const [kycResponse, setKycResponse] = useState(null);
  const [requestLoading, setRequestLoading] = useState(false);
  const [customErrorMessage, setCustomErrorMessage] = useState(null);
  const [successStatus, setSuccessStatus] = useState(false)
  const [isRsaVer, setIsRsaVer] = useState(null)
  const [relianceReqId, setRelianceReqId] = useState()
  const [isPopWindowOpen, setIsPopWindowOpen] = useState(false);
  const [typeOfAction, setTypeOfAction] = useState("");
  const [quoteApiData, setQuoteApiData] = useState();
  const [showRedirectionMessage, setShowRedirectionMessage] = useState(false);
const [tileType, setTileType] = useState()
  const apiData = localStorage.getItem("apiData");
  const typeofTile = localStorage.getItem('tile_Type_Detail')
  const actiontype = sessionStorage.getItem("action_type");
  const inputData = localStorage.getItem("relianceKyc");
  const changeKey = sessionStorage.getItem("changeKycPage");
  const isRelianceVerified = localStorage.getItem('relianceKyc_apidata');
  const isRsaVerified  = localStorage.getItem('rsaKyc_apidata')
  const reliancequoteReqId = sessionStorage.getItem("relianceReqId")
  const navigate = useNavigate();


  /* ------------ API END POINT ----------------------- */

  
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
  /* ------------ API END POINT END HERE ----------------------- */


  /* ------------------ Redux Values ----------------- */
  let rsaApiData = useSelector(state=>state.quoteRsa.apiData)
  let relianceApiData = useSelector(state=>state.quoteReliance.apiData)



  /* ---------------------------------------------- */

  useEffect(() => {
    setQuoteApiData(JSON.parse(apiData));
    inputData&&setInputs(JSON.parse(inputData));
    setTypeOfAction(actiontype);
    setTileType(typeofTile)
    setRelianceReqId(reliancequoteReqId)
    setIsRsaVer(JSON.parse(isRsaVerified))
    // changeKey&&setShowRedirectionMessage(changeKey);
  }, [actiontype,typeofTile, reliancequoteReqId,apiData,isRsaVerified, changeKey, inputData]);
  const handleChange = (e) => {
    setIsInputChanged(true);
    const name = e.target.name;

    const value = e.target.value;
    setInputs((values) => ({
      ...values,
      [name]: value,
    }));
  };


  useEffect(() => {
    const onStorage = () => {
      setJwtToken(localStorage.getItem("jwt_token"));
    };

    window.addEventListener("storage", onStorage);

    return () => {
      window.removeEventListener("storage", onStorage);
    };
  }, []);
  useEffect(() => {}, [inputs]);
  // console.log('res',kycResponse.KYC_Verified
  // =='false')

  // console.log("kali", inputs);


//validate Pan number 
function validatePAN(pan) {
  // Check if PAN follows the correct format
  const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
  if (!panRegex.test(pan)) {
      return false; // PAN format is incorrect
  }
  return true;

  // // Calculate checksum
  // const weights = [16, 8, 4, 2, 1, 6, 5, 4, 3, 2];
  // let total = 0;
  // for (let i = 0; i < 9; i++) {
  //     total += weights[i] * parseInt(pan.charAt(i), 36);
  // }
  // const checksum = (total % 11) % 10;

  // // Check if checksum matches
  // return pan.charAt(9) === String(checksum);
}



// useEffect(()=>{
//   if(isRsaVer&&isRsaVer.ckycNo) {
//     setCustomErrorMessage('OK')
//   }
// },[isRsaVer])


useEffect(()=>{
;
  var regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
  
  if(regpan.test(inputs.panNo)){
    setPanNoCustErr(false)
     // valid pan card number
  } else {
    setPanNoCustErr(true)

     // invalid pan card number
  }
},[inputs])

console.log('pan',relianceApiData)

  useEffect(() => {

    /* ----------- KYC API request for Reliance -------------------- */
   tileType=='Reliance'&&  showRedirectionMessage &&
      Object.keys(inputs).length >= 2 &&
      !dobValueError &&
      !panNoError &&
      axios
        .post(
          `${API_ENDPOINT}/kyc/checkRelianceKycStatus`,
          {
            PAN: inputs.panNo.toUpperCase(),
            DOB: inputs.dob.split("-").reverse().join("-"),
            // "DOB":'14-06-2000',
            CKYC: "",
            MOBILE: "",
            PINCODE: "",
            BIRTHYEAR: "",
            ReturnURL: window.location.hostname==='www.policymart.co.in'?'www.policymart.co.in/kyc': "https://staging-policymart.co.in/kyc",
            UNIQUEID: relianceReqId&&relianceReqId,
            CIN: "",
            VOTERID: "",
            "DL_No ": "",
            PASSPORT: "",
            AADHAAR_NO: "",
            FULLNAME: "",
            GENDER: "",
          },
          {
            headers: {
              authorization: jwtToken,

              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setRequestLoading(false);

          localStorage.setItem(
            "relianceKyc_apidata",
            JSON.stringify(res.data.reliance_KYCResponse)
          );

          const updateStep1Val = {
            "salutation": "",
            "firstName": res.data.reliance_KYCResponse.kyc_data.CKYC.result.PERSONAL_DETAILS.FNAME,
            "lastName":  res.data.reliance_KYCResponse.kyc_data.CKYC.result.PERSONAL_DETAILS.LNAME,
            "email": res.data.reliance_KYCResponse.kyc_data.CKYC.result.PERSONAL_DETAILS.EMAIL,
            "mobile":res.data.reliance_KYCResponse.kyc_data.CKYC.result.PERSONAL_DETAILS.MOB_NUM,
        }


        const updateStep3Val = {
          "address": res.data.reliance_KYCResponse.kyc_data.CKYC.result.PERSONAL_DETAILS.CORRES_LINE1,
          "address2":res.data.reliance_KYCResponse.kyc_data.CKYC.result.PERSONAL_DETAILS.CORRES_LINE2,
          "pincode": res.data.reliance_KYCResponse.kyc_data.CKYC.result.PERSONAL_DETAILS.PERM_PIN
      }
          const bike_updateStep1Val = {
            "salutation": "",
            "firstName": res.data.reliance_KYCResponse.kyc_data.CKYC.result.PERSONAL_DETAILS.FNAME,
            "lastName":  res.data.reliance_KYCResponse.kyc_data.CKYC.result.PERSONAL_DETAILS.LNAME,
            "email": res.data.reliance_KYCResponse.kyc_data.CKYC.result.PERSONAL_DETAILS.EMAIL,
            "mobile":res.data.reliance_KYCResponse.kyc_data.CKYC.result.PERSONAL_DETAILS.MOB_NUM,
        }


        const bike_updateStep3Val = {
          "address": res.data.reliance_KYCResponse.kyc_data.CKYC.result.PERSONAL_DETAILS.CORRES_LINE1,
          "address2":res.data.reliance_KYCResponse.kyc_data.CKYC.result.PERSONAL_DETAILS.CORRES_LINE2,
          "pincode": res.data.reliance_KYCResponse.kyc_data.CKYC.result.PERSONAL_DETAILS.PERM_PIN
      }
      isInputChanged&& localStorage.setItem('step-1_Values',JSON.stringify(updateStep1Val))
      isInputChanged&& localStorage.setItem('step-3_Values',JSON.stringify(updateStep3Val))
      isInputChanged&& localStorage.setItem('bike_step-1_Values',JSON.stringify(bike_updateStep1Val))
      isInputChanged&& localStorage.setItem('bike_step-3_Values',JSON.stringify(bike_updateStep3Val))
      isInputChanged&& setKycResponse(res.data.reliance_KYCResponse);
      isInputChanged&& setCustomErrorMessage(res.data.reliance_KYCResponse.message);
      isInputChanged&&   setSuccessStatus(res.data.reliance_KYCResponse.success)
        })
        .catch((error) => console.log(error));

        /* --------------------- KYC API Request For Reliance End Here -------------------- */

    /* ----------- KYC API request for RSA Start Here -------------------- */
     tileType=='RSA'&& showRedirectionMessage &&
      Object.keys(inputs).length >= 2 &&
      !dobValueError &&
      !panNoError &&
      axios
        .post(
          `${API_ENDPOINT}/kyc/checkRSAKycStatus`,

          {
            "token": "0jVZOOt67upGqSofGa34cJSJ8dXsmH5lDRuqUe0q9lXuCF/O1HtRe4l/XDU9Sae8",
            "policyNo ": "",
            "quoteNo": rsaApiData.PREMIUMDETAILS.DATA.QUOTE_ID,
            "inwardNo": "",
            "appName": "D2C",
            "proposalNo": "",
            "product": "Privatecar",
            "Lob": "",
            "customerName": "",
            "mobileNo": "",
            "emailId": "",
            "address1": "",
            "address2": "",
            "address3": "",
            "address4": "",
            "State": "",
            "City": "",
            "pinCode": "",
            "ckycNo": "",
            "panNumber":inputs.panNo.toUpperCase(),
            "aadharNumber": "",
            "dob_doi": inputs.dob.split("-").reverse().join("-"),
            "eiaNumber": "",
            "customerType": "I",
            "gender": "",
            "drivingLincense": "",
            "passportNumber": "",
            "voterId": "",
            "userName": "",
            "uniqueId": rsaApiData.PREMIUMDETAILS.DATA.QUOTE_ID,
            "returnURL": window.location.hostname==='www.policymart.co.in'?'www.policymart.co.in/kyc': "https://staging-policymart.co.in/kyc"
        },
          
          {
            headers: {
              authorization: jwtToken,

              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setRequestLoading(false);
          localStorage.setItem(
            "rsaKyc_apidata",
            JSON.stringify(res.data.kycDetails)
          );

            
          const updateStep1Val = {
            "salutation": "",

            "firstName": res.data.kycDetails.firstName,
            "lastName":  res.data.kycDetails.lastName,
            'email':'',
            'mobile':''
         }


        const updateStep3Val = {
          "address": res.data.kycDetails.address1,
          "address2": res.data.kycDetails.address2? res.data.kycDetails.address2:'',
          "pincode":  res.data.kycDetails.pinCode
      }
          const bike_updateStep1Val = {
            "firstName": res.data.kycDetails.firstName,
            "lastName":  res.data.kycDetails.lastName,
           }


        const bike_updateStep3Val = {
          "address": res.data.kycDetails.address1,
          "address2": res.data.kycDetails.address2? res.data.kycDetails.address2:'',
          "pincode":  res.data.kycDetails.pinCode
      }
      isInputChanged&& localStorage.setItem('step-1_Values',JSON.stringify(updateStep1Val))
      isInputChanged&& localStorage.setItem('step-3_Values',JSON.stringify(updateStep3Val))
      isInputChanged&& localStorage.setItem('bike_step-1_Values',JSON.stringify(bike_updateStep1Val))
      isInputChanged&&  localStorage.setItem('bike_step-3_Values',JSON.stringify(bike_updateStep3Val))
      isInputChanged&& localStorage.removeItem("Otp_verified")
      isInputChanged&&  localStorage.removeItem("bike_Otp_verified")

          setKycResponse(res.data.kycDetails);
          setRedirectRsaForVerify(res.data)
          setCustomErrorMessage(res.data.kycStatus=='true'?'OK':'Please Check Details Entered Are Correct Or Click on Verify to Verify Kyc');
        })
        .catch((error) => console.log(error));

        /* --------------------- KYC API Request For RSA End Here -------------------- */
  }, [API_ENDPOINT,tileType, dobValueError, inputs, panNoError, quoteApiData, showRedirectionMessage]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (inputs.dob == "" || inputs.dob == undefined) {
      setDobValueError(true);
    } 
    if (inputs.panNo == "" || inputs.panNo == undefined) {
      setPanNoError(true);
    }
setPanNoError(validatePAN(inputs.panNo))
    Object.keys(inputs).length >= 2 &&
      !panNoError &&
      !dobValueError &&
      setRequestLoading(true);

    Object.keys(inputs).length >= 2 &&
      !dobValueError &&
      !panNoError &&
      localStorage.setItem("relianceKyc", JSON.stringify(inputs)) &&
      sessionStorage.setItem("changeKycPage", true);
    Object.keys(inputs).length >= 2 &&
      !dobValueError &&
      !panNoError &&
      sessionStorage.setItem("changeKycPage", true);



    /* ----------- KYC API request for Reliance Start Here -------------------- */

   tileType=='Reliance'&& Object.keys(inputs).length >= 2 &&
      !dobValueError &&
      !panNoError &&
      axios
        .post(
          `${API_ENDPOINT}/kyc/checkRelianceKycStatus`,
          {
            PAN: inputs.panNo.toUpperCase(),
            DOB: inputs.dob.split("-").reverse().join("-"),
            // DOB: "15-06-2002",
            CKYC: "",
            MOBILE: "",
            PINCODE: "",
            BIRTHYEAR: "",
            ReturnURL: window.location.hostname==='www.policymart.co.in'?'www.policymart.co.in/kyc': "https://staging-policymart.co.in/kyc",
            UNIQUEID:relianceReqId&&relianceReqId,
            CIN: "",
            VOTERID: "",
            "DL_No ": "",
            PASSPORT: "",
            AADHAAR_NO: "",
            FULLNAME: "",
            GENDER: "",
          },
          {
            headers: {
              authorization: jwtToken,

              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setRequestLoading(false);
          setShowRedirectionMessage(true)
          localStorage.setItem(
            "relianceKyc_apidata",
            JSON.stringify(res.data.reliance_KYCResponse)
          );

            
          const updateStep1Val = {
            "salutation": "",
            "firstName": res.data.reliance_KYCResponse.kyc_data.CKYC.result.PERSONAL_DETAILS.FNAME,
            "lastName":  res.data.reliance_KYCResponse.kyc_data.CKYC.result.PERSONAL_DETAILS.LNAME,
            "email": res.data.reliance_KYCResponse.kyc_data.CKYC.result.PERSONAL_DETAILS.EMAIL,
            "mobile":res.data.reliance_KYCResponse.kyc_data.CKYC.result.PERSONAL_DETAILS.MOB_NUM,
        }


        const updateStep3Val = {
          "address": res.data.reliance_KYCResponse.kyc_data.CKYC.result.PERSONAL_DETAILS.CORRES_LINE1,
          "address2":res.data.reliance_KYCResponse.kyc_data.CKYC.result.PERSONAL_DETAILS.CORRES_LINE2,
          "pincode": res.data.reliance_KYCResponse.kyc_data.CKYC.result.PERSONAL_DETAILS.PERM_PIN
      }
          const bike_updateStep1Val = {
            "salutation": "",
            "firstName": res.data.reliance_KYCResponse.kyc_data.CKYC.result.PERSONAL_DETAILS.FNAME,
            "lastName":  res.data.reliance_KYCResponse.kyc_data.CKYC.result.PERSONAL_DETAILS.LNAME,
            "email": res.data.reliance_KYCResponse.kyc_data.CKYC.result.PERSONAL_DETAILS.EMAIL,
            "mobile":res.data.reliance_KYCResponse.kyc_data.CKYC.result.PERSONAL_DETAILS.MOB_NUM,
        }


        const bike_updateStep3Val = {
          "address": res.data.reliance_KYCResponse.kyc_data.CKYC.result.PERSONAL_DETAILS.CORRES_LINE1,
          "address2":res.data.reliance_KYCResponse.kyc_data.CKYC.result.PERSONAL_DETAILS.CORRES_LINE2,
          "pincode": res.data.reliance_KYCResponse.kyc_data.CKYC.result.PERSONAL_DETAILS.PERM_PIN
      }
      isInputChanged&& localStorage.setItem('step-1_Values',JSON.stringify(updateStep1Val))
      isInputChanged&&  localStorage.setItem('step-3_Values',JSON.stringify(updateStep3Val))
      isInputChanged&&  localStorage.setItem('bike_step-1_Values',JSON.stringify(bike_updateStep1Val))
      isInputChanged&&  localStorage.setItem('bike_step-3_Values',JSON.stringify(bike_updateStep3Val))


          setKycResponse(res.data.reliance_KYCResponse);
          setCustomErrorMessage(res.data.reliance_KYCResponse.message);
          setSuccessStatus(res.data.reliance_KYCResponse.success)
          
        })
        .catch((error) => console.log(error));
    /* ----------- KYC API request for Reliance End Here -------------------- */

console.log('rsaData',rsaApiData)
    /* ----------- KYC API request for RSA Start Here -------------------- */

     tileType=='RSA'&& Object.keys(inputs).length >= 2 &&
      !dobValueError &&
      !panNoError &&
      axios
        .post(
          `${API_ENDPOINT}/kyc/checkRSAKycStatus`,
          {
            "token": "0jVZOOt67upGqSofGa34cJSJ8dXsmH5lDRuqUe0q9lXuCF/O1HtRe4l/XDU9Sae8",
            "policyNo ": "",
            "quoteNo": rsaApiData.PREMIUMDETAILS.DATA.QUOTE_ID,
            "inwardNo": "",
            "appName": "D2C",
            "proposalNo": "",
            "product": "Privatecar",
            "Lob": "",
            "customerName": "",
            "mobileNo": "",
            "emailId": "",
            "address1": "",
            "address2": "",
            "address3": "",
            "address4": "",
            "State": "",
            "City": "",
            "pinCode": "",
            "ckycNo": "",
            "panNumber":inputs.panNo.toUpperCase(),
            "aadharNumber": "",
            "dob_doi": inputs.dob.split("-").reverse().join("-"),
            "eiaNumber": "",
            "customerType": "I",
            "gender": "",
            "drivingLincense": "",
            "passportNumber": "",
            "voterId": "",
            "userName": "",
            "uniqueId": rsaApiData.PREMIUMDETAILS.DATA.QUOTE_ID,
            "returnURL": window.location.hostname==='www.policymart.co.in'?'www.policymart.co.in/kyc': "https://staging-policymart.co.in/kyc"
        },
          {
            headers: {
              authorization: jwtToken,

              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setRequestLoading(false);
          setShowRedirectionMessage(true)
          localStorage.setItem(
            "rsaKyc_apidata",
            JSON.stringify(res.data.kycDetails)
          );
          const updateStep1Val = {
            "salutation": "",

            "firstName": res.data.kycDetails.firstName,
            "lastName":  res.data.kycDetails.lastName,
              'email':'',
            'mobile':''
         }


        const updateStep3Val = {
          "address": res.data.kycDetails.address1,
          "address2": res.data.kycDetails.address2? res.data.kycDetails.address2:'',
          "pincode":  res.data.kycDetails.pinCode
      }
          const bike_updateStep1Val = {
            "firstName": res.data.kycDetails.firstName,
            "lastName":  res.data.kycDetails.lastName,
           }


        const bike_updateStep3Val = {
          "address": res.data.kycDetails.address1,
          "address2":  res.data.kycDetails.address2? res.data.kycDetails.address2:'',
          "pincode":  res.data.kycDetails.pinCode
      }
      isInputChanged&&    localStorage.setItem('step-1_Values',JSON.stringify(updateStep1Val))
      isInputChanged&&  localStorage.setItem('step-3_Values',JSON.stringify(updateStep3Val))
      isInputChanged&&   localStorage.setItem('bike_step-1_Values',JSON.stringify(bike_updateStep1Val))
      isInputChanged&& localStorage.setItem('bike_step-3_Values',JSON.stringify(bike_updateStep3Val))
      isInputChanged&& localStorage.removeItem("Otp_verified")
      isInputChanged&& localStorage.removeItem("bike_Otp_verified")

          setRedirectRsaForVerify(res.data)

          setKycResponse(res.data.kycDetails);
          setCustomErrorMessage(res.data.kycStatus=='true'?'OK':'Please Check Details Entered Are Correct Or Click on Verify to Verify Kyc');

        })
        .catch((error) => console.log(error));
    /* ----------- KYC API request for RSA End Here -------------------- */

  if(tileType=='Reliance'){

   ( customErrorMessage == "OK"||successStatus===true) && navigate(typeOfAction == "Car" ? "/carinsurance/car-profile/step-1":"/bikeinsurance/bike-profile/step-1");
  }
    customErrorMessage == "OK" && navigate(typeOfAction == "Car" ? "/carinsurance/car-profile/step-1":"/bikeinsurance/bike-profile/step-1");

    tileType=='Reliance'&& kycResponse &&
      kycResponse.PAN_Verified == "false" &&
      kycResponse.KYC_Verified == "false" &&
      // (window.location.href = kycResponse.Endpoint_2_URL);
      window.open(kycResponse.Endpoint_2_URL, '_blank');
    tileType=='RSA'&& redirectRsaForVerify &&
     redirectRsaForVerify.kycStatus=="false"&&
     window.open(redirectRsaForVerify.url, '_blank');
      // (window.location.href = redirectRsaForVerify.url);

  };

  // console.log('steno',  )
  useEffect(() => {
    if (kycResponse && kycResponse.KYC_Verified == "false") {
      setChangeButton(true);
    }
    //  if (kycResponse && kycResponse.message
    //   =='Invalid Pan Number'
    //   ) {

    //     setPanNoError(true)
    //  }
  }, [kycResponse]);

  useEffect(() => {
    if (
      inputs &&
      Object.keys(inputs).includes("dob") &&
      (inputs.dob == "" || inputs.dob == undefined)
    ) {
      setDobValueError(true);
    } else {
      setDobValueError(false);
    }
    if (
      inputs &&
      Object.keys(inputs).includes("panNo") &&
      (inputs.panNo == "" || inputs.panNo == undefined)
    ) {
      setPanNoError(true);
    } else {
      setPanNoError(false);
    }
  }, [inputs]);
  return (
    <>
      <div className={`${isPopWindowOpen?'py-16':''}`}>
        {typeOfAction == "Car" ? (
          <InsuranceHeader popwindow={[isPopWindowOpen, setIsPopWindowOpen]} />
        ) : (
          <BikeInsuranceHeader
            popwindow={[isPopWindowOpen, setIsPopWindowOpen]}
          />
        )}
      </div>

      <form onChange={handleChange}>
        <div className=" flex flex-col justify-center items-centeer">
          <div className="  bg-white mx-5 lg:m-auto shadow-[0px_0px_25px_rgba(0,0,0,0.1)] lg:drop-shadow-lg lg:w-[55%] h-[500px   px-4 py-5">
            <h1 className="mr-auto font-medium capitalize text-solid text-xl py-4 lg:pl-4">
              know your customer(KYC)
            </h1>
            <hr className="text-[#DBDBDB] pb-4" />
            <div className="space-y-5 relative">
              <h2 className="text-center py-1 text-sm px-1 lg:px-0  lg:text-base rounded-md  bg-[#7fc07f]">
                From jan 1st 2023 onwards, policyholders will have to provide
                Know Your Customer (KYC) documents for buying all insurance
                policies as per the Insurance Regulator and Development
                Authority of India (IRDAI)
              </h2>
              <div className="absolute mb-20  w-full  ">
                <InputPP
                  className="w-full lg:w-80 px-3 py-2 my-2 rounded-md"
                  labelClassName="text-[#959595] font-medium pl-1 "
                  id="dob"
                  label="Date Of Birth"
                  type="date"
                  placeholder=""
                  value={inputs && inputs.dob}
                  errorState={[dobValueError, setDobValueError]}
                />
              </div>

              <div className="">
                <InputPP
                  className="w-full lg:w-80  uppercase    px-3 py-2 m rounded-md"
                  labelClassName="text-[#959595] mt-40  font-medium pl-1 "
                  id="panNo"
                  label="PanCard Number"
                  parenetClassName="mt-32"
                  type="text"
                  placeholder="Enter Pancard Number"
                  value={inputs && inputs.panNo}
                  errorState={[panNoError, setPanNoError]}
                />
              </div>

              {inputs&&inputs.panNo&&panNoCustErr&& <p className="text-[#e25757]">Please Enter Valid Pan Number</p> }

              <h2
                className={` ${
                  (customErrorMessage == "OK"||successStatus===true)
                    ? "text-hero font-medium"
                    : "text-[#e25757]"
                } text-sm lg:text-base `}
              >
                {(customErrorMessage == "OK"||successStatus===true)
                  ?  <div className="flex lg:items-center gap-2 w-full">
                    <div className="w-max">

                   <AiOutlineCheck
                  className="bg-hero text-white rounded-full p-0.5 w-5 h-5 " 
                  />
                  </div>
                <p className=" text-xs lg:text-base">

              "KYC Verification Completed Please Proceed to enter the details in proposal form"
                </p>
              </div>
                  : customErrorMessage}
              </h2>

              <input
                type="submit"
                className={`bg-hero w-32  ${
                  requestLoading ? "hidden" : ""
                }   py-2 rounded-full text-white `}
                onClick={handleSubmit}
                value={`${(customErrorMessage == "OK"||successStatus===true)? "Continue" : "Verify"}`}
                // value="View Quote"
              />
              <button
                className={`bg-hero w-32  ${
                  requestLoading ? "" : "hidden"
                }   py-2 rounded-full text-white `}
              >
                Verifying
              </button>
            </div>
          </div>
          <div className="  justify-center hidden space-x-5 pt-8 ">
            {/* <Link to="/carinsurance/car-profile/prestep-2">
        <button className="bg-[#293651]  w-24 py-2 rounded-full text-white">
          Back
        </button>
      </Link> */}
          </div>
        </div>
      </form>
    </>
  );
};

export default RelianceKyc;
