// src/components/NotificationPermission.js
import { useEffect } from 'react';

function NotificationPermission() {
  useEffect(() => {
    function requestNotificationPermission() {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          localStorage.setItem('notificationPermission', 'granted');
        }
      });
    }

    if (Notification.permission !== 'granted') {
      requestNotificationPermission();
    }
  }, []);

  return null;
}

export default NotificationPermission;
