import React,{useState} from "react";
import { Link } from "react-router-dom";
import NavHeader from "../components/NavHeader";
import LandingBackground from "../assets/Svg/LandingPageBck.svg";
import TermInsuranceLandingSvg from "../assets/Svg/TeamInsuranceLanding.svg";
import CarLandingSvg2 from "../assets/Svg/CarLandingSvg2.svg";
import QuoteVector from "../assets/Vector/QuoteVector.svg";
import Testimonial from "../components/slider/Testimonial";
import Article from "../components/Article";
import Partners from "../components/slider/Partners";
import Faq from "../components/Faq";
import Footer from "../components/Footer";
import ContactUsCard from "../UI/ContactUsCard";
const TermInsuranceLanding = () => {
  const [closeNav, setCloseNav] = useState(false)
  return (
    <>
      <NavHeader   dropClose={ [closeNav, setCloseNav] }
 />
      <main
        className="w-full  bg-no-repeat bg-cover  "
        style={{ backgroundImage: `url(${LandingBackground})` }}  onClick={()=>{
          setCloseNav(false)
        }}
      >
        {/* Section - 1 */}
        <section className="flex  flex-col lg:flex-row  lg:px-40 lg:py-24">
          <div className="pb-12 lg:pb-0">
            <img
              src={TermInsuranceLandingSvg}
              alt="TermInsuranceLandingSvg"
              className="pt-10 px-14 lg:px-0  pb-10 lg:pb-32"
            />
            <h1 className="text-2xl lg:text-5xl text-[#293651] text-center lg:text-start lg:leading-snug pb-4 lg:pb-0 ">
              <span className="text-hero font-bold"> Term Insurance </span>that{" "}
              <br />
              Truly Fits You
            </h1>
            <h2 className=" text-[#777777]  text-base leading-5 lg:leading-loose   text-center  lg:text-start  ">
              Term insurance is a type of life <br className="lg:hidden" />{" "}
              insurance that provides <br className="hidden lg:block" />{" "}
              coverage for <br className="lg:hidden" /> a specific period of
              time or years, <br className="lg:hidden" /> i.e., a term.{" "}
              <br className="hidden lg:block" /> This type of life insurance{" "}
              <br className="lg:hidden" />
              provides a financial benefit to <br className="hidden lg:block" />{" "}
              the <br className="lg:hidden" /> nominee in case of the
              unfortunate <br className="lg:hidden" /> demise of the{" "}
              <br className="hidden lg:block" /> insured during the{" "}
              <br className="lg:hidden" /> policy term.
            </h2>
          </div>
          <div className="flex flex-1"></div>
          <ContactUsCard />
        </section>

        {/* Section -3 Testimonial */}
        <section className="py-8   ">
          <Testimonial />
        </section>

        {/* section -4 Articles */}
        <section className="py-10 lg:py-20  flex justify-center">
          <Article />
        </section>

        {/* Section - 5 Partners */}
        <section className="  px-6 lg:px-32  m-auto">
          <Partners />
        </section>
        {/* Section -6 Faqs */}
        <section className="lg:py-16 lg:px-40">
          <Faq />
        </section>
        {/* Global  Footer */}
        <Footer />
      </main>
    </>
  );
};

export default TermInsuranceLanding;
