import React, { useState } from "react";
import NavHeader from "../NavHeader";
import CircleLoader from "react-spinners/BarLoader";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "orange"
};

const LoadingSpinner = ({ navTrue = true }) => {
  const [loading, setLoading] = useState(true);
  const [closeNav, setCloseNav] = useState(false)
  function parse(input) {
    function parseSingle(input) {
      var parts = input.split("||"),
        part,
        record = {};

      for (var i = 0; i < parts.length; i++) {
        part = parts[i].split("=");
        record[part[0]] = part[1];
      }

      return record;
    }

    var parts = input.split("++"),
      records = [];

    for (var i = 0; i < parts.length; i++) {
      records.push(parseSingle(parts[i]));
    }

    return records;
  }

  var string =
    "https://staging-policymart.co.in/payments/complete?Output=1|920222223700001328|C505092200167|0|CCAvenue|R05092200160|Failure|";

  var parsed = parse(string);

  // console.log(
  //   parsed[0][
  //     "https://staging-policymart.co.in/payments/complete?Output"
  //   ].includes("Failure")
  // );
  return (
    <div>
      <>
        {navTrue && <NavHeader   dropClose={ [closeNav, setCloseNav] }
 />}
        <main
          className="lg:w-full py-20 lg:py-24 bg-no-repeat bg-cover overflow-x-hidden   "  onClick={()=>{
            setCloseNav(false)
          }}
          //   style={{ backgroundImage: `url(${LandingBackground})` }}
        >
          <div className="slider-in flex flex-col  justify-center bg-white lg:m-auto drop-shadow-xl lg:w-[40%]  lg:h-[500px]  mx-4   rounded-xl lg:px-4 py-10 ">
            <CircleLoader
              cssOverride={override}
              size={150}
              color={"#FFBA00"}
              loading={loading}
              speedMultiplier={1.5}
            />
            <h1 className="text-[#FFBA00] text-2xl lg:text-3xl font-semibold text-center pt-9  pb-4 lg:pb-14">
              Processing Order
            </h1>
            <h1 className=" text-sm lg:text-base font-semibold text-center">
              Thank you for your order. Please wait while we <br /> are
              processing your payment.
            </h1>
          </div>
        </main>
      </>
    </div>
  );
};

export default LoadingSpinner;
