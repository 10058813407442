import { createSlice } from "@reduxjs/toolkit";

const bikeProposalSlice = createSlice({
    name:'carProposal',
    initialState:{
        
       isApiFailed:false,
       proposalApiData:{}
    },
    reducers:{
        setApiFailed(state,action){
            state.isApiFailed=action.payload;
            // state.loader=action.valueLod;
        },
        setProposalApiData(state,action) {
            state.proposalApiData = action.payload
        }
         
        
    }
})

export default bikeProposalSlice.reducer;
export const carProposalAction = bikeProposalSlice.actions