import React, { useState, useEffect } from "react";
import { AiOutlineCheck, AiOutlineClose, AiOutlineRight } from "react-icons/ai";
import { useNavigate, Link } from "react-router-dom";
import { addonsData } from "../data/AddonsData";
import DigitLogo from "../assets/Svg/BrandPartner/BrandPolicy-digit.svg";
import SundaramLogo from "../assets/Svg/BrandPartner/BrandPolicy-sundaram.svg";
import RelianceLogo from "../assets/Svg/BrandPartner/BrandPolicy-reliance.png";
import LoadingSpinner from "../components/paymentStatus/LoadingSpinner";
import axios from "axios";
import NoPlanSvg from "../assets/Vector/NotFound.svg";
import { FeaturesContainer } from "./quotation/FeaturesContainer";

import { useSelector } from "react-redux";

const featuresContainer = [
  {
    name: "Cashless Garages",
    value: "Available"
  },

  {
    name: "Discounts",
    value: ""
  },
  {
    name: "Add Ons:",
    value: ""
  }
];
const PolicyPlanCard = ({
  objPolicy,
  detailsPop,
  redirectUrl,
  id = "",

  quoteData,
  dataLoading
}) => {
  const navigate = useNavigate();
  const [isDetailsPopUp, setisDetailsPopUp] = detailsPop;
  const [apiData, setApiData] = quoteData;
  // const [rsaKycApiData, setRsaKycApiData] = useState([])
  // const [isApiLoaded, setIsApiLoaded] = dataLoading;
  const [isDataLoaded, setIsDataLoaded] = useState();
  const [showapiData, setShowapiData] = useState(false);
  const [isGoditNull, setIsGoditNull] = useState(true);
  const [special, setSpecial] = useState();
  const [isRSANull, setIsRSANull] = useState();
  const [names, setNames] = useState();
  const [isRelianceNull, setIsRelianceNull] = useState();
  const [proposalLoaded2, setProposalLoaded2] = useState(
    localStorage.getItem("missionLoad")
  );
  const [jwtToken, setJwtToken] = useState(localStorage.getItem("jwt_token"));
  const [me, setMe] = useState();
  // console.log(isDataLoaded &&apiData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA == null)
  const universalApiData = localStorage.getItem("apiData");
  const mine = localStorage.getItem("me");
  // const apiLoaded = localStorage.getItem('apiLoaded')
  const myName = localStorage.getItem("missionLoad");

  useEffect(() => {
    const onStorage = () => {
      setJwtToken(localStorage.getItem("jwt_token"));
    };

    window.addEventListener("storage", onStorage);

    return () => {
      window.removeEventListener("storage", onStorage);
    };
  }, []);
  // useEffect(() => {
  //   const onStorage = () => {
  //   };

  //   window.addEventListener('storage', onStorage);

  //   return () => {
  //       window.removeEventListener('storage', onStorage);
  //   };
  // }, []);

/* ---------- Redux State Values ---------------- */

let isApiLoadedRSA = useSelector(state=>state.quoteRsa.loader)
let rsaApiData = useSelector(state=>state.quoteRsa.apiData)

let isApiLoadedGodigit = useSelector(state=>state.quoteGodigit.loader)
let godigitApiData = useSelector(state=>state.quoteGodigit.apiData)

let isApiLoadedReliance = useSelector(state=>state.quoteReliance.loader)
let relianceApiData = useSelector(state=>state.quoteReliance.apiData)


/* ----------------------------------------- */


  useEffect(() => {

    if (isApiLoadedRSA !== false) {
     ;
      setIsRSANull(true);
    }
    if (isApiLoadedGodigit !== false) {
      setIsGoditNull(true);
    }
    if (isApiLoadedReliance !== false) {
      setIsRelianceNull(true);
    }

    if (
      isApiLoadedReliance  == false &&
      relianceApiData == null
    ) {
      setIsRelianceNull(true);
    }
    if (isApiLoadedReliance == false && relianceApiData !== null) {
      setIsRelianceNull(false);
    }

    if (
      isApiLoadedGodigit === false &&
      (godigitApiData == null)
    ) {
      setIsGoditNull(true);
    }
    if (isApiLoadedGodigit === false && godigitApiData !== null) {
      setIsGoditNull(false);
    }



    if (
      isApiLoadedRSA == false &&
      rsaApiData == (undefined||null)
    ) {
      setIsRSANull(true);
    }
    if (isApiLoadedRSA == false &&rsaApiData !== (undefined||null)) {
      setIsRSANull(false);
    }


  
  }, [  isRelianceNull, isApiLoadedRSA, rsaApiData, isApiLoadedGodigit, godigitApiData, isApiLoadedReliance, relianceApiData]);


  // console.log('checking value of reelia idv',apiData.data.relianceQuoteResponse.MotorPolicy.MaxIDV);
  // console.log('is data loadded or not', apiData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA == null);
  // console.log( 'some ', apiData.data.relianceQuoteResponse.MotorPolicy==null||undefined);
  const handleClick = () => {
    // navigate("/carinsurance/car-profile/step-1");
  };
  // console.log(apiData.data.relianceQuoteResponse.MotorPolicy.FinalPremium);
  // console.log('isapiloaded', (isApiLoaded&& isRelianceNull==false)?  apiData.data.relianceQuoteResponse.MotorPolicy
  // .DerivedVehicleIDV:'')

  // console.log('isApiloaded',isApiLoaded)
  // console.log('dataloadedfrom plan', (isApiLoaded&& isRelianceNull==false)?  apiData.data.relianceQuoteResponse.MotorPolicy
  // .DerivedVehicleIDV: apiData.data.relianceQuoteResponse.MotorPolicy
  // .DerivedVehicleIDV)

  return (
    <>
      <>
        <div className="">
          {/* isApiLoaded==false&&isRelianceNull */}
          {/* <div className={`${proposalLoaded2}`}/> */}
          {isApiLoadedRSA == false && isApiLoadedReliance == false && isApiLoadedGodigit == false  && isRelianceNull && isGoditNull&&isRSANull ? (
            <div className="">
              <NoPlans />{" "}
            </div>
          ) : (
            <>
              {isRelianceNull ? (
                <div className=""> </div>
              ) : (
            
                <div className=" ">

                <QuoteTileBox
                  detailsPop={[isDetailsPopUp, setisDetailsPopUp]}
                  logo={RelianceLogo}
                  logoClass="w-[80px] h-[40px] lg:w-[110px] lg:h-[30px]"
                typeTile='Reliance'

                  redirectUrl={redirectUrl}
                  coverAmount={
                    isApiLoadedReliance == false && isRelianceNull == false
                      ? relianceApiData&&relianceApiData.MotorPolicy&&+relianceApiData.MotorPolicy.IDV
                      : "Loading..."
                  }
                  price={
                    isApiLoadedReliance == false && isRelianceNull == false
                      ?relianceApiData&&relianceApiData.MotorPolicy
                          .FinalPremium
                      : "Loading..."
                  }
                  buttonFor="Reliance"
                />
                </div>

              )}

              <div className="">
                {  isGoditNull ? (
                  <div className=""> </div>
                ) : (
                  <QuoteTileBox
                    detailsPop={[isDetailsPopUp, setisDetailsPopUp]}
                    logo={DigitLogo}
                    logoClass="w-[120px] h-[40px]"
                typeTile='Godigit'

                    redirectUrl={redirectUrl}
                    coverAmount={
                      isApiLoadedGodigit == false && isGoditNull == false
                        ? godigitApiData&&godigitApiData.vehicle.vehicleIDV
                            .idv
                        : "Loading..."
                    }
                    price={
                      isApiLoadedGodigit == false && isGoditNull == false
                        ? godigitApiData&& Math.trunc(godigitApiData.grossPremium.slice(
                            3
                          ))
                        : "Loading..."
                    }
                    buttonFor="GoDigit"
                  />
                )}
              </div>
            </>
          )}
        </div>

        <div>
        {isRSANull ? ''
            :(
              <QuoteTileBox
                detailsPop={[isDetailsPopUp, setisDetailsPopUp]}
                logo={SundaramLogo}
                logoClass ='w-[145px] h-[55px]'
                typeTile='RSA'
                redirectUrl={redirectUrl}

                coverAmount={
                  isApiLoadedRSA === false && isRSANull === false
                    ?rsaApiData&& rsaApiData.PREMIUMDETAILS.DATA.IDV
                    : "Loading..."
                }
                price={
                  isApiLoadedRSA === false && isRSANull === false
                    ? rsaApiData&&Math.trunc(rsaApiData.PREMIUMDETAILS.DATA.GROSS_PREMIUM)
                    : "Loading..."
                }
              
               
                buttonFor="RSA"
              />
              )}
        </div>
      </>
    </>
  );
};

export default PolicyPlanCard;

export const NoPlans = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <img src={NoPlanSvg} alt="NoPlanSvg" className="lg:w-80" />
      <h2 className="font-semibold lg:text-xl text-lg py-2 lg:py-3">
        Sorry no quotes found!
      </h2>
      <h2 className="lg:text-base text-center  text-[#3b3f3b]">
        We can't find the quotes as per your enterd details. Please try again.
      </h2>
    </div>
  );
};

const QuoteTileBox = ({
  detailsPop,
  logo,
  typeTile,
  logoClass='',
  coverAmount,
  redirectUrl,
  price,
  buttonFor
}) => {
  const navigate = useNavigate();
  const [isDetailsPopUp, setisDetailsPopUp] = detailsPop;
  const [direct, setDirect] = useState(false);
  const [relianceapiData, setRelianceapiData] = useState({});
  const [policyType, setPolicyType] = useState();
  const [oldPolicyType, setOldPolicyType] = useState();
  const [actionType, setActionType] = useState('')
const actiontype = sessionStorage.getItem("action_type")
  const carRadioValue = localStorage.getItem("car_Info");
  const bikeRadioValue = localStorage.getItem('bike_Info')
  const policy = sessionStorage.getItem("inputs_policyType");
  const bikepolicy = sessionStorage.getItem("bike_inputs_policyType");


  const formatter = new Intl.NumberFormat('en-IN');

  useEffect(() => {
    setActionType(actiontype)
  }, [actiontype]);
  useEffect(()=>{
    if(actionType=='Car'){

      setOldPolicyType(JSON.parse(carRadioValue).policy);
      setPolicyType(policy);
    return ;

    }
    if(actionType==='Bike') {
      setPolicyType(bikepolicy)
      setOldPolicyType(JSON.parse(bikeRadioValue).policy)
    }

  },[actionType,bikeRadioValue,carRadioValue, bikepolicy, policy])
  const handleClick = () => {};
  
  return (
    <div
      className={`bg-white relative md:w-[85%] lg:w-[90%] xl:w-[96%] 2xl:w-[98%] card m-auto border border-[rgba(0,167,142,0.5)] py- shadow-[8px_8px_36px_rgba(0,167,142,0.18)] rounded-lg mb-6 cursor-pointer ${
      (  policyType == (null || undefined)
          ? oldPolicyType !== "thirdParty"
          : policyType !== "thirdParty")
          ? ""
          : "pb-14 lg:pb-0"
      }  `}
      onClick={handleClick}
    >
      {/* <div className="bg-[#CEF9F3]  items-center flex h-8 mb-2">
        <h1 className="text-hero text-sm pl-3 ">
          1 Year OD and 3 Year TP Cover
        </h1>
      </div>  vehicle new and BUndled */}
      <div className="flex px-4  py-2 items-center">
        {/* Logo */}
        <img
          src={logo}
          alt="logo"
          className={` ${
            (   policyType == (null || undefined)
                 ? oldPolicyType !== "thirdParty"
                 : policyType !== "thirdParty")
                 ? ""
                 : "xl:w-[140px] xl:h-[40px]"
             }  ${logoClass}  `}
        />
        <div
          className={` ${
         (   policyType == (null || undefined)
              ? oldPolicyType !== "thirdParty"
              : policyType !== "thirdParty")
              ? "hidden"
              : ""
          } ml-auto lg:m-auto`}
        >
          <h2 className="font-semibold">Third Party Cover</h2>
        </div>

        <div
          className={` ${
            (policyType == (null || undefined)
              ? oldPolicyType !== "thirdParty"
              : policyType !== "thirdParty")
              ? ""
              : "hidden"
          } ml-auto lg:m-auto`}
        >
          <h1 className="text-[#989898] leading-7 text-[10px] xl:text-[14px]">
            Cover Amount (IDV)
          </h1>
          {/* {console.log('vennela',coverAmount,coverAmount.toLocaleString())} */}

          <h2 className=" text-center md:text-sm xl:text-base font-bold"> ₹ {coverAmount.toLocaleString()}</h2>
        </div>
        <div
          className={`absolute ${
           ( policyType == (null || undefined)
              ? oldPolicyType !== "thirdParty"
              : policyType !== "thirdParty")
              ? "bottom-5 "
              : "bottom-4"
          }  lg:bottom-0 lg:relative flex items-center space-x-4 ml-auto  pr-4`}
        >
          <button
            className="text-hero border-hero text-base lg:font-semibold  border rounded-3xl lg:text-base px-5 py-0.5"
            onClick={(e) => {
              setisDetailsPopUp(true);
              localStorage.setItem("tile_Type_Detail", e.target.id);
              sessionStorage.setItem("detail_open", true);
            }}
            id={buttonFor}
          >
            Details
          </button>
          <div className="flex lg:hidden flex-1"></div>
          {/* <Link to={redirectUrl}> */}
          <button
            className="text-white bg-hero flex items-center gap-2 border-hero lg:font-bold text-base  border rounded-3xl lg:text-base px-5 py-0.5"
            onClick={(e) => {
              localStorage.setItem("tile_Type_price", e.target.id);
              localStorage.setItem("tile_Type_Detail", e.target.id);
              localStorage.setItem("tile_price", price);
              setDirect(true);
              navigate( redirectUrl);
            }}
            id={buttonFor}
          >
            ₹ {formatter.format(price)} <div  id={buttonFor} className="text-">
            {"❯"}
            
              </div> 
          </button>
          {/* </Link> */}
        </div>
      </div>
      <div
        className={`${
          (policyType == (null || undefined)
            ? oldPolicyType !== "thirdParty"
            : policyType !== "thirdParty")
            ? ""
            : "hidden"
        } p-4 mb-14 lg:mb-0 `}
      >
        <FeaturesContainer typeTiles={typeTile} values={featuresContainer} />
      </div>
    </div>
  );
};
