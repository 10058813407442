import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { blogsData } from "../../data/BlogsData";
import { FaCalendarAlt } from "react-icons/fa";

const BlogsPagination = () => {
  const [items, setItems] = useState(blogsData);

  const handlePageClick = () => {
  };

  return (
    <div>
      <div className="overflow-hidden">{/* <NavHeader /> */}</div>
      {items.map((item, key) => (
        <>
          <div key={item.id} className={` pb-6 cursor-pointer`}>
            <div className="lg:w-max h2-4 flex  flex-col lg:flex-row lg:space-x-6 items-center  bg-white shadow-[8px_8px_36px_rgba(0,167,142,0.18)] rounded-lg mx-3 lg:mx-0 ">
              <img src={item.imgUrl} alt={item.imgUrl} />
              <div className="w-full ">
                <div className="flex flex-1"></div>
                {/* For Desktop Date  */}
                <div className="hidden lg:flex items-center justify-end  space-x-2 ">
                  <FaCalendarAlt className="text-hero text-lg" />
                  <h2 className="text-xs ">{item.date}</h2>
                </div>
                <h1 className="text-[#293651] font-semibold lg:text-lg pt-3 pb-1">
                  {item.title}
                </h1>
                <h2 className="text-xs lg:text-sm text-solid lg:w-[357px] leading-6 ">
                  {item.subTitle}
                </h2>
              </div>
              <div className="flex w-full pt-4 lg:hidden">
                <button className="bg-[#E6F6F4] text-hero font-semibold h2-1 rounded-3xl px-4">
                  Bike
                </button>
                <div className="flex flex-1"></div>
                <div className="flex items-center justify-end  space-x-2 ">
                  <FaCalendarAlt className="text-hero text-lg" />
                  <h2 className="text-xs ">{item.date}</h2>
                </div>
              </div>
            </div>
          </div>
        </>
      ))}

      <ReactPaginate
        previousLabel="<"
        nextLabel=">"
        breakLabel="..."
        pageCount={items.length}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName="pagination justify-content-center"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLinkClassName="breakPoint"
        activeClassName="activePage"
      />
    </div>
  );
};

export default BlogsPagination;
