import React,{useState,useEffect} from 'react'
import NavHeader from '../NavHeader'
import BikeInfoStep1 from './step1forms/manual/BIkeInfoStep1'
import BikeInfoMobileStep1 from './step1forms/mobileverify/BikeInfoStep1'

const BikeStep1Manage = () => {
  const [closeNav, setCloseNav] = useState(false)
      const [tileType, setTileType] = useState()
      const tileSelected = localStorage.getItem('tile_Type_price')


  useEffect(()=>{
    setTileType(tileSelected)
  },[tileSelected])

  return (
    <>
        <NavHeader dropClose={ [closeNav, setCloseNav] }/>
    <div  onClick={()=>{
          setCloseNav(false)
        }}>
       {tileType=='RSA'?<BikeInfoMobileStep1/> : 
        <BikeInfoStep1/>}
    </div>
    </>
  )
}

export default BikeStep1Manage