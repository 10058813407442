import React, { useState, useEffect } from "react";
import LandingBackground from "../../../assets/Svg/LandingPageBck.svg";
import MaleSvg from '../../../assets/Vector/Health/male.svg'
import FemaleSvg from '../../../assets/Vector/Health/female.svg'
import { Link, useNavigate } from "react-router-dom";
import NavHeader from "../../NavHeader";
import FormBar from "../../FormBar";
import RadioPP from "../../customs/RadioPP";


const HealthIntro3 = () => {
  const navigate = useNavigate();

  const [closeNav, setCloseNav] = useState(false)
  const [selectedPreDis, setselectedPreDis] = useState()
  const [showError, setShowError] = useState(false)
 const [inputs, setInputs] = useState({})
 const [diseaseError, setDiseaseError] = useState(false)

  const preDisValue = localStorage.getItem('health_preDis')
  const handleSubmit = (e) => {
    e.preventDefault();

 
         localStorage.setItem('health_preDis',JSON.stringify(inputs))
        navigate(inputs&&inputs.isPreDis=='yes'? "/healthinsurance/health-profile/prestep-4":"/healthinsurance/health-profile/prestep-5" );
    

  };

  const handleChange = (e) => {
    const name = e.target.name;

    const value = e.target.value;
    setInputs((values) => ({
      ...values,
      [name]:e.target.id 
    }));

  
  };
  const handleAutodirect = (gender)=>{
    // setselectedPreDis(gender)
    // localStorage.setItem('health_gender',gender)
    // localStorage.setItem('health_preDis',JSON.stringify(inputs))

    // setTimeout(()=>{

    //   navigate(inputs&&inputs.isPreDis=='yes'? "/healthinsurance/health-profile/prestep-4":"/healthinsurance/health-profile/prestep-5" );
    // },500)

  }

useEffect(()=>{
  if(preDisValue) {

    setselectedPreDis(JSON.parse(preDisValue))
    setInputs(JSON.parse(preDisValue))
  }


 
},[ preDisValue])

// useEffect(()=>{
//   if( ('yes'||'no').includes(inputs.isPreDis)){
//     setShowError(false)
    
// }else {
//   setShowError(true)
// }
// },[inputs])



  return (
    <>
      <NavHeader dropClose={ [closeNav, setCloseNav] } />
      <main
        className="w-full pb-10 lg:pt-8 bg-no-repeat bg-cover   "
        style={{ backgroundImage: `url(${LandingBackground})` }}  onClick={()=>{
          setCloseNav(false)
        }}
      >
        
        <form onChange={handleChange}>
          <h1 className="text-xl lg:text-3xl text-[#293651] font-semibold  py-10   lg:pt-4 text-center">
            Health Insurance Quote
          </h1>
          {/* Steps Bar */}
          <div className="lg:w-[55%] rounded-t-md bg-[#aab8aa27] m-auto " >

<div className={`lg:w-[36.6%] rounded-t-md  bg-hero py-[3px]`}  style={  {transition: '36.6% 5s ease'}}></div>
</div>

          <div className=" justify-center bg-white mx-5 lg:m-auto shadow-[0px_0px_25px_rgba(0,0,0,0.1)] lg:drop-shadow-lg lg:w-[55%] h-[500px   px-4 py-5">
          
            <h1 className="mr-auto font-medium text-solid lg:text-xl py-4 pl-4">
            Any preexisting dieaseases?
            </h1>
            <hr className="text-[#DBDBDB] pb-4" />
            <div className="">
              <p className="px-5 text-[#7e8488] text-[15px]">
              We can utilize this information to find a Health Insurance Policy that provides the best possible benefits for the specified illness/es.
              </p>
              <div className="pl-5" onClick={handleAutodirect}>

            <RadioPP
                  title=""
                  errorState={[diseaseError, setDiseaseError]}
                  value={inputs.isPreDis}
                  required={false}
                  objValues={[
                    {
                      id: "yes",
                      name: "isPreDis",
                      value: "Yes"
                    },
                    {
                      id: "no",
                      name: "isPreDis",
                      value: "No"
                    }
                  ]}
                />
              </div>

            </div>
         
          <h1 className={`${showError?'':'hidden'} text-center pt-2 text-sm text-[#eb5648] `}>Please Select Gender</h1>

          </div>
          <div className="flex justify-center space-x-5 pt-8 ">
            <Link to="/healthinsurance/health-profile/prestep-2">
            <button className="bg-[#293651]  w-24 py-2 rounded-full text-white">
              Back
            </button>
            </Link>
            <input
              type="submit"
              className="bg-hero w-24 py-2 rounded-full text-white cursor-pointer "
              onClick={handleSubmit}
              value="Next"
            />
          </div>
        </form>
      </main>
    </>
  );
};

export default HealthIntro3;
