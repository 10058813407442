import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import InputPP from "../customs/InputPP";
import NavHeader from "../NavHeader";
import LandingBackground from "../../assets/Svg/LandingPageBck.svg";

import DropDownPP from "../customs/DropDownPP";
import InsuranceHeader from "../../UI/insurance/InsuranceHeader";
import InsuranceTracker from "../../UI/insurance/InsuranceTracker";

const CarInfoStep3 = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    address:'',
    address2:'',
    pincode:''
  });
  const [proceed, setProceed] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [pincodeDetails, setPincodeDetails] = useState({});
  const [cityError, setCityError] = useState(false);
  const [load, setLoad] = useState(false)
  const [districtError, setDistrictError] = useState(false)
  const [stateError, setStateError] = useState(false);
const [kycData1, setKycData1] = useState(null)
const [typeOfPolicy, setTypeOfPolicy] = useState()
const [relianceKycData, setRelianceKycData] = useState()
  const [pincodeError, setPincodeError] = useState();
  const [checkedError, setCheckedError] = useState(false);
  const [pincodeLoaded, setPincodeLoaded] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
const [address2Error, setAddress2Error] = useState(false)
const [hideErrorMsg, setHideErrorMsg] = useState(false)
const [jwtToken, setJwtToken] = useState(localStorage.getItem('jwt_token'))
const [isPopWindowOpen, setIsPopWindowOpen] = useState(false)
const [closeNav, setCloseNav] = useState(false)
const [address1, setAddress1] = useState('')
const [address2, setAddress2] = useState('')
const rsaKyc = localStorage.getItem("rsaKyc_apidata")
const relianceKyc = localStorage.getItem("relianceKyc_apidata")
const tileSelected = localStorage.getItem('tile_Type_price')



// console.log('test pins',pincodeDetails.data[0].stateName)
/* -------------------  API ENDPOINT -------------- */
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
/* ---------------------------------- -------------- */

  const handleChange = (e) => {
    const name = e.target.name;

    const value = e.target.value;
setLoad(false)

    setInputs((values) => ({
      ...values,
      [name]: name === "isRegisteredAddress" ? e.target.checked : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();


    if (inputs.address == undefined || inputs.address == "") {
      setAddressError(true);
    } else {
      setAddressError(false);
    }
    if (inputs.address2 == undefined || inputs.address2 == "") {
      setAddress2Error(true);
    } else {
      setAddress2Error(false);
    }

    if (inputs.pincode == undefined || inputs.pincode == "") {
      setPincodeError(true);
    } 

  


    if (
      Object.values(inputs).length >= 3&&
      inputs.address.length > 0 &&
      inputs.address2.length > 0 &&
      inputs.pincode.length ==6 && !pincodeError && !address2Error&& !addressError
      // inputs.isRegisteredAddress == (false || true)
    ) {
      setProceed(true);
      localStorage.setItem("step", 3);
      localStorage.setItem("step-3_Values", JSON.stringify(inputs));
    }


    axios.get(`${API_ENDPOINT}/reliance/pincode?stateName=${pincodeDetails.data[0].stateName}&districtName=${pincodeDetails.data[0].districtName}&cityOrVillageName=${pincodeDetails.data[0].cityOrVillageName}`, {
      headers: {
        Authorization: `${jwtToken}`,
        'Content-Type': 'application/json',
      },
  },).then((response)=>{
      // setRegionData(response.data)
      localStorage.setItem('region_Pincode',JSON.stringify(response.data[0]))
    })
    .catch((err)=>{
      console.log('pincode',err)
    })
    // if (!inputs.isRegisteredAddress == (false || true)) {
    //   setCheckedError(true);
    // } else {
    //   setCheckedError(false);
    // }

    !addressError&& !address2Error && !pincodeError && navigate(proceed ? "/carinsurance/car-profile/step-4" : "");


  };

  useEffect(()=>{

    // setUpdateAPiValues(JSON.parse(inputValues))
    setKycData1(JSON.parse(rsaKyc))
    setRelianceKycData(JSON.parse(relianceKyc))
    setTypeOfPolicy(tileSelected)
  },[relianceKyc, rsaKyc, tileSelected])

  useEffect(()=>{

    


    const addressParts =kycData1?kycData1.address1&&kycData1.address1.split(' '):"";

    // Initialize address1 and address2
    let updatedAddress1 = '';
    let updatedAddress2 = kycData1&&kycData1.address2;

    // Loop through the address parts and add them to address1 until its length reaches 30 characters
    for (let i = 0; i <addressParts.length; i++) {
      if ((updatedAddress1 + ' ' + addressParts[i]).length <= 30) {
        updatedAddress1 += (updatedAddress1 === '' ? '' : ' ') + addressParts[i];
      } else {
        // If adding the current part exceeds 30 characters, move it to address2
        updatedAddress2 = addressParts.slice(i).join(' ');
        break;
      }
    }





    // Set the updated addresses
    setAddress1(updatedAddress1);
    setAddress2( updatedAddress2 + ' ' + ( kycData1&&kycData1.address2?kycData1.address2:'') );

  
    
    // else {
    //   setAddress1(kycData1&&kycData1.address1)
    //   setAddress2(kycData1&&kycData1.address2)
    // }

  },[ kycData1])


useEffect(()=>{
  if ( address2&& address2.length > 30) {
    // Split the address and move the last part to address2
    const parts = address2.split(' ');
    const lastPart = parts.pop();
    setAddress2(parts.join(' '));
  }
},[address2])


 useEffect(()=>{
  typeOfPolicy=='RSA'&& setInputs({
    ...inputs,
  address:address1?address1:'',
    address2:address2?address2:'',
    pincode:kycData1&&kycData1.pinCode
  
  });
  typeOfPolicy=='Reliance'&& setInputs({
    ...inputs,
    address:(inputs&&inputs.address)?inputs.address: relianceKycData&&relianceKycData.kyc_data.CKYC.result.PERSONAL_DETAILS.CORRES_LINE1,
    address2:(inputs&&inputs.address2)?inputs.address2:  relianceKycData&&relianceKycData.kyc_data.CKYC.result.PERSONAL_DETAILS.CORRES_LINE2,
    pincode:(inputs&&inputs.pincode)?inputs.pincode:  relianceKycData&&relianceKycData.kyc_data.CKYC.result.PERSONAL_DETAILS.PERM_PIN


 
  });
 },[  kycData1,address1,address2,relianceKycData, typeOfPolicy])

    useEffect(() => {
      const onStorage = () => {
        setJwtToken(localStorage.getItem('jwt_token'));
      };
    
      window.addEventListener('storage', onStorage);
    
      return () => {
          window.removeEventListener('storage', onStorage);
      };
    }, []);

  useEffect(() => {
    const inputValues = localStorage.getItem("step-3_Values");
    inputValues && setInputs(JSON.parse(inputValues));
  }, []);
  useEffect(() => {

    // if (
    //   Object.values(inputs).length <1 ) {
    //     setPincodeError(true)

    //   }
    if (
      Object.keys(inputs).includes('pincode') &&
      
      (inputs.pincode.length>5&& inputs.pincode.length<7 )
    ) {
      setProceed(false);
      setPincodeError(false)
     setProceed(true)
    }
    else{
      // setPincodeError(true)
      setProceed(false)
    }
    


   

  }, [inputs.pincode,inputs]);
  // useEffect(() => {
  //   if (
  //     Object.values(inputs).length >= 3&&
  //     inputs.address.length > 0 &&
  //     inputs.address2.length > 0 &&
  //     inputs.pincode.length > 1  
  //     // inputs.isRegisteredAddress == (false || true)
  //   ) {
  //     setProceed(true);
  //     localStorage.setItem("step", 3);
  //     localStorage.setItem("step-3_Values", JSON.stringify(inputs));
  //   }
  // }, [inputs]);
  
// console.log('pincode',pincodeError===true,inputs.pincode.length>5, inputs.pincode.length<7)
// console.log('pincode value test', Object.keys(inputs.pincode).length)
  // Fetching Details Through Pincode

  useEffect(() => {
   pincodeError==false&&  axios.get(`${API_ENDPOINT}/reliance/pincode/master?pincode=${inputs.pincode}`, {
      headers: {
        Authorization: `${jwtToken}`,
        'Content-Type': 'application/json',
      },
  },).then((data)=>{
      setPincodeDetails(data)
      setHideErrorMsg(false)

setLoad(true)
    //  (data.data.message).includes('please enter valid pincode')?setPincodeLoaded(true):setPincodeLoaded(false)
      localStorage.setItem('pincode_Details',JSON.stringify(data.data[0]))  })
    .catch((err)=>{
      setErrorMessage(err.response.data.message)
      setHideErrorMsg(true)
    })
   
  

  }, [inputs, pincodeError, jwtToken, API_ENDPOINT]);

  useEffect(()=>{
   if(  load&& Object.keys(inputs).includes('pincode') && pincodeDetails.data.message!=='please enter valid pincode') {
      setPincodeLoaded(true)
      
    }else {
      setPincodeLoaded(false)

    }
  },[pincodeDetails,inputs,load])
  // console.log('louse',Object.keys(pincodeDetails).length)

  // Fetching  Region Codes 
// /http://staging-policymart.co.in/api/v1/reliance/pincode?stateName=TELANGANA&districtName=ADILABAD&cityOrVillageName=ADILABAD
 


  useEffect(() => {
    window.addEventListener(
      "keydown",
      function (e) {
        if (
         
          e.keyIdentifier == "U+000A" ||
          e.keyIdentifier == "Enter" ||
       
          e.keyCode == 13
        ) {
          if (e.target.nodeName == "INPUT" && e.target.type == "text") {
            e.preventDefault();
            return false;
          }
        }
      },
      true
    );
  }, []);

  
  useEffect(()=>{
    if( Object.keys(inputs).includes('address') && inputs.address.length<1 ) {
      setAddressError(true)
    } else if( Object.keys(inputs).includes('address') && inputs.address.length>0) {
      setAddressError(false)

    }
    if( Object.keys(inputs).includes('address2') && inputs.address2.length<1 ) {
      setAddress2Error(true)
    } else if( Object.keys(inputs).includes('address2') && inputs.address2.length>0) {
      setAddress2Error(false)

    }
    if( Object.keys(inputs).includes('pincode') && inputs.pincode.length!==6 ) {
      setPincodeError(true)
    } else if( Object.keys(inputs).includes('pincode') && inputs.pincode.length==6) {
      setPincodeError(false)

    }
  },[inputs])



  return (
    <div>
      <>
        <NavHeader dropClose={ [closeNav, setCloseNav] } />
        <main
          className={`lg:w-full overflow-x-hidden  lg:pt- bg-no-repeat bg-cover   text-sm lg:text-base   ${isPopWindowOpen?'h-screen ':''}  `}
          style={{ backgroundImage: `url(${LandingBackground})` }}  onClick={()=>{
            setCloseNav(false)
          }}
        >
          <InsuranceHeader popwindow={[isPopWindowOpen, setIsPopWindowOpen] } />
          <div className="pt-5 lg:pt-8 pb-16 lg:pb-">
            <InsuranceTracker />
          </div>
          <form onChange={handleChange}  className={`${isPopWindowOpen?'hidden':''}`}>
            <div className=" slider-in overflow-x-hidden justify-center bg-white mx-5 lg:m-auto drop-shadow-lg lg:w-[55%]    rounded-xl p-4  ">
              <div className="flex items-center space-x-2 pb-4">
                <div className="w-6 h-6 lg:w-8 lg:h-8 bg-hero text-white rounded-full items-center flex justify-center">
                  3
                </div>
                <h1 className="m  text-solid lg:text-xl  font-semibold">
                  Communication Address
                </h1>
              </div>
              <hr className="text-[#DBDBDB] pb-4" />
              <div className="">
                <div className=" ">
                  <InputPP
                    className={`${typeOfPolicy=='R SA'?'opacity-[0.7] bg-[#45464d24]':''} w-full h-20 border border-[#d7d5d5] rounded-md  px-3  my-2`}
                    labelClassName="text-[#959595] font-medium pl-1"
                    id="address"
                    label="Address"
                    type="text"
                    // disableTrue={typeOfPolicy=='RSA'?true:false}

                    errorState={[addressError, setAddressError]}
                    value={inputs.address}
                    // state={[mobileNumber, setMobileNumber]}
                  />
                  <InputPP
                    className={`${typeOfPolicy=='R SA'?'opacity-[0.7] bg-[#45464d24]':''} w-full h-20 border border-[#d7d5d5] rounded-md  px-3  my-2"`}
                    labelClassName="text-[#959595] font-medium pl-1"
                    id="address2"
                    label="Address2"
                    // disableTrue={typeOfPolicy=='RSA'?true:false}

                    type="text"
                    errorState={[address2Error, setAddress2Error]}
                    value={inputs.address2}
                    // state={[mobileNumber, setMobileNumber]}
                  />
                  <div className="flex items-center space-x-2 py-3">
                    <input
                      type="checkbox"
                      className="text-2xl"
                      name="isRegisteredAddress"
                      id="isRegisteredAddress"
                      checked
                      disabled
                      // checked={
                      //   inputs.isRegisteredAddress == true ? true : false
                      // }
                      // onChange={(e) => {
                      //   setInputs((values) => ({
                      //     ...values,
                      //     isRegistered: e.target.checked
                      //   }));
                      // }}
                    />
                    <label
                      htmlFor="isRegisteredAddress"
                      className="text-[#959595] text-[10px] lg:text-base"
                    >
                      Correspondence Address same as Registered Address
                    </label>
                  </div>
                  {checkedError && (
                    <h2 className="text-[#b54a39]  text-sm">
                      Please select Your Option{" "}
                    </h2>
                  )}
                  <InputPP
                    className={`${typeOfPolicy=='R SA'?'opacity-[0.7] bg-[#45464d24]':''} w-full lg:w-96 border border-[#d7d5d5] rounded-md  px-3 py-2 my-2 mb-5 lg:mb-2"`}
                    labelClassName="text-[#959595] font-medium pl-1"
                    id="pincode"
                    label="Pincode"
                    type="text"
                    // disableTrue={typeOfPolicy=='RSA'?true:false}

                   maxValue='6'
                    placeholder="Pincode"
                    value={inputs.pincode}
                    errorState={[pincodeError, setPincodeError]}
                    // state={[emailId, setEmailId]}
                  />
                  {hideErrorMsg&&inputs&&inputs.pincode.length===6 &&<p className="text-[#b54a39] capitalize text-sm">{errorMessage}</p> }
                  <InputPP
                    className="w-full lg:w-96 border border-[#d7d5d5] rounded-md  px-3 py-2 my-2"
                    labelClassName="text-[#959595] font-medium pl-1"
                    id="city"
                    label="City"
                    type="text"
                    disableTrue={true}
                    placeholder="City"
                    errorState={[cityError, setCityError]}
                    // state={[emailId, setEmailId]}
                    value={(load&&pincodeLoaded)?pincodeDetails.data[0].cityOrVillageName:''}
                  />
                  <InputPP
                    className="w-full lg:w-96 border border-[#d7d5d5] rounded-md  px-3 py-2 my-2"
                    labelClassName="text-[#959595] font-medium pl-1"
                    id="district"
                    label="District"
                    type="text"
                    disableTrue={true}

                    placeholder="District"
                    errorState={[districtError, setDistrictError] }
                    // state={[emailId, setEmailId]}
                    value={(load&&pincodeLoaded)?pincodeDetails.data[0].districtName:''}
                  />

                  <InputPP
                    className="w-full lg:w-96 border border-[#d7d5d5] rounded-md  px-3 py-2 my-2"
                    labelClassName="text-[#959595] font-medium pl-1"
                    label="State"
                    type="text"
                    disableTrue={true}

                    placeholder="State"
                    errorState={[stateError, setStateError]}
                    id="state"
                    value={(load&&pincodeLoaded)?pincodeDetails.data[0].stateName:''}
                  />

                  {/* <InputPP
                  className="w-96 border border-[#d7d5d5] rounded-md  px-3 py-2 my-2"
                  labelClassName="text-[#959595] font-medium pl-1"
                  id="state"
                  label="State"
                  type="text"
                  placeholder="Pincode"
                  // state={[emailId, setEmailId]}
                /> */}
                </div>
              </div>
            </div>
            <div className="flex justify-center m-auto space-x-5 py-8 w-[53%] ">
              <Link to="/carinsurance/car-profile/step-2">
                <button className="bg-[#293651] hidden lg:block  w-24 py-2 rounded-full text-white">
                  Back
                </button>
              </Link>
              <div className="flex flex-1"></div>
              {/* <Link to="/carinsurance/car-profile/step-4"> */}
              <input
                type="submit"
                className="bg-hero w-max px-5 py-2 rounded-full text-white focus:outline-none cursor-pointer "
                onClick={handleSubmit}
                value=" Continue to Vehicle Details"
              />
              {/* </Link> */}
            </div>
          </form>
        </main>
      </>
    </div>
  );
};

export default CarInfoStep3;
