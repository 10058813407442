import React, { useState, useEffect } from "react";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import LoadingSpinner from "../../paymentStatus/LoadingSpinner";
import { PolicyFilterCard, PolicyFilterCard1 } from "./FilterTypes/PolicyFilterCard1";
import { PolicyFilterCard2 } from "./FilterTypes/PolicyFilterCard2";
import { PolicyFilterCard3 } from "./FilterTypes/PolicyFilterCard3";
import { PolicyFilterCard4 } from "./FilterTypes/PolicyFilterCard4";
import { useSelector } from "react-redux";

export const FilterType = ({
  filterData,
  closeState = "",
  custQuoteData,
  isQuoteLoad,
  isPopOpened,
  mission,
  loading,
  loadingData
}) => {
  const [policyData, setPolicyData] = useState();
  const [coverData, setCoverData] = useState();
  const [discountData, setDiscountData] = useState();
  const [addonsData, setAddonsData] = useState({});
  const [addonsChecked, setAddonsChecked] = useState('')
  const [filterPopData, setFilterPopData] = useState({})
  const [oldPolicyType, setOldPolicyType] = useState()

  const [policytypePlace, setPolicytypePlace] = useState()
  const [idvPlace, setIdvPlace] = useState()
  const [discountPlace, setDiscountPlace] = useState()
  const [addonsPlace, setAddonsPlace] = useState({})
const [checkedValue, setCheckedValue] = useState('0')
const [isThirdParty, setIsThirdParty] = useState(false)
  const [policy, setPolicy] = useState(false);
  // const [missionQuick, setMissionQuick] = mission
  const [cover, setCover] = useState(false);
  const [covCustIDV, setCovCustIDV] = useState();
  const [discount, setDiscount] = useState(false);
  const [relianceIdvValues, setRelianceIdvValues] = useState([])
  const [addons, setAddons] = useState(false);
  const [minIDVValue, setMinIDVValue] = useState();
  const [maxIDVValue, setMaxIDVValue] = useState();
  const [isNewCar, setIsNewCar] = useState()
 const [isDetailsOpen, setIsDetailsOpen] = useState()
const [actionType, setActionType] = useState('')
const [checkData, setCheckData] = useState(false)
const [relMinIdvVal, setRelMinIdvVal] = useState()
const [relMaxIdvVal, setRelMaxIdvVal] = useState()
 const [missionAwake, setMissionAwake] = mission
const [loader, setLoader] = loading

const [loadApiData, setLoadApiData] = loadingData
  const [closePopUp, setClosePopUp] = closeState;
  const [initialLoader, setInitialLoader] = useState(false)
const [compareData, setCompareData] = useState({})
  const popData = (localStorage.getItem('popupsData'))
  const isNewCarValue = localStorage.getItem('isCarRegistered')
  const actiontype = localStorage.getItem('action_type')
  const isNew = localStorage.getItem('isCarRegistered')
  const addonsCheckData = localStorage.getItem("input_addons_check")
  const checkedaddonsData = localStorage.getItem('checked_addons')
  const popup = sessionStorage.getItem('detail_open')
  const comparedata = localStorage.getItem('apiData')
  const initialLoad = localStorage.getItem('apiLoaded')
  const policyplaceholder = sessionStorage.getItem('inputs_policyType')
  const radioValue = localStorage.getItem('car_Info')
  const relianceidv = sessionStorage.getItem('relianceIdvs')

  const idvplaceholder= sessionStorage.getItem('inputs_IDV')
  const filterpolicyType  = sessionStorage.getItem("inputs_policyType")
  const discountplacehodler = sessionStorage.getItem('inputs_discount')
  // const addonsplaceholder = localStorage.getItem('input_addons_check')
  // console.log( Object.keys(addonsData) )
  
useEffect(()=>{
  setIsThirdParty( (policyplaceholder))
setOldPolicyType(JSON.parse(radioValue).policy)
setActionType(actiontype)

},[policyplaceholder,actiontype,radioValue])


useEffect(()=>{
  setDiscountPlace(discountplacehodler)
  setIdvPlace(idvplaceholder)
  setPolicytypePlace(filterpolicyType?filterpolicyType:oldPolicyType)
},[discountplacehodler, filterpolicyType, idvplaceholder, oldPolicyType])
  useEffect(()=>{
    setInitialLoader(initialLoad)

  },[initialLoad])

    /* ----------- Redux State --------------- */
let rsaApiData = useSelector(state=>state.quoteRsa.apiData)
let godigitData = useSelector(state=>state.quoteGodigit.apiData)
let relinaceApiData = useSelector(state=>state.quoteReliance.apiData)
  
  
  // For Counting Number of Checked values in Addons
  useEffect(()=>{

    // (isThirdParty == (undefined || null)
    // ? oldPolicyType !== "thirdParty"
    // : isThirdParty !== "thirdParty") 
// if((isThirdParty == (undefined || null)
// ? oldPolicyType !== "thirdParty"
// : isThirdParty !== "thirdParty") ) {

//   const selectAddonOption= ['']
//   const trueValues = Object.values(addonsPlace)
//   trueValues.forEach(function (x) { selectAddonOption[x] = (selectAddonOption[x] || 0) + 1; });
//   setCheckedValue(addonsPlace&&selectAddonOption.true==undefined?'0':selectAddonOption.true)


// }
const thirdpartyadd = ['paidDrivercover','ownerDriverPAcover','unnamedpassengerPAcover']
// setCheckedValue(0)
if((isThirdParty == (undefined || null)
? oldPolicyType !== "thirdParty"
: isThirdParty !== "thirdParty") ) {
 const selectAddonOption= ['']

                        const trueValues = Object.values(addonsPlace)
                        trueValues.forEach(function (x) { selectAddonOption[x] = (selectAddonOption[x] || 0) + 1; });
                        // setCheckedValue(selectAddonOption.true==undefined&&selectAddonOption.false==undefined?(isThirdParty==(null||undefined)?oldPolicyType=='ownDamage':isThirdParty=='ownDamage')?0: '1':selectAddonOption.true?selectAddonOption.true:0)
                      }else {
                        const selectAddonOption= ['']
                        const trueValues = Object(addonsPlace)
                        const  truthyCheck=[trueValues.externalBiFuelkit, trueValues.paidDrivercover,trueValues.ownerDriverPAcover,trueValues.unnamedpassengerPAcover]
                        
                        if((isThirdParty == (undefined || null)
                        ? oldPolicyType == "thirdParty"
                        : isThirdParty == "thirdParty")  ) {

                          truthyCheck.forEach(function (x) { selectAddonOption[x] = (selectAddonOption[x] || 0) + 1; });
                          // setCheckedValue(selectAddonOption.true==undefined?((trueValues.ownerDriverPAcover==false))?0:(isThirdParty==(null||undefined)?oldPolicyType=='ownDamage':isThirdParty=='ownDamage')?0: 1:selectAddonOption.true)
                        }
                      }







  },[addonsPlace, isThirdParty, oldPolicyType])
  // console.log('place',policytypePlace,idvPlace==minIDVValue,discountPlace,addonsPlace,checkedValue)
  useEffect(()=>{

    setIsNewCar(isNewCarValue)
    setIsDetailsOpen(popup)
   initialLoader=='true'&& setCompareData(JSON.parse(comparedata))
  },[isNewCarValue,initialLoader,comparedata,popup,isNew])
  useEffect(()=>{

    if(initialLoader=='true'&&compareData&&Object.values(compareData).length>0){
      setCheckData(true)
    }else{
      setCheckData(false)
    }

  },[compareData,initialLoader])
  
  useEffect(()=>{
   
    setFilterPopData(JSON.parse(popData))
    setAddonsData(JSON.parse(addonsCheckData))
    setRelianceIdvValues(JSON.parse(relianceidv))

  },[popData, addonsCheckData, relianceidv])


  useEffect(() => {
    if (policy === false) {
      setClosePopUp(false);
    } else {
      setClosePopUp(true);
    }
  }, [policy]);
  useEffect(() => {
    if (cover === false) {
      setClosePopUp(false);
    } else {
      setClosePopUp(true);
    }
  }, [cover]);
  useEffect(() => {
    if (discount === false) {
      setClosePopUp(false);
    } else {
      setClosePopUp(true);
    }
  }, [discount]);
  useEffect(() => {
    if (addons === false) {
      setClosePopUp(false);
    } else {
      setClosePopUp(true);
    }
  }, [addons]);
  useEffect(() => {
   
    isPopOpened &&
      localStorage.setItem(
        "popupsData",
        JSON.stringify({ policyData, coverData, discountData })
      );
      
    }, [policyData, coverData, discountData, isPopOpened]);
    useEffect(()=>{
  isPopOpened &&
    localStorage.setItem(
      "addonsPop",
      JSON.stringify( addonsData )
    );

},[addonsData,isPopOpened])
// console.log('idvs', 
//  Math.min( compareData.data.rsaQuoteResponse==null?'':+compareData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.MINIMUM_IDV,
//   compareData.data.goDigitQuoteResponse==null?false:  +compareData.data.goDigitQuoteResponse.vehicle.vehicleIDV.minimumIdv,
//   compareData.data.relianceQuoteResponse==null?'': +compareData.data.relianceQuoteResponse.MotorPolicy.MinIDV
// ))
  // console.log(policyData, coverData, discountData, addonsData);
  const a = "40";
  const b = "200";
  const c = "30";
  // console.log('chekcing',Object.keys(filterPopData).length!==0)

  // console.log('discount', discountData)
  // For Min & Max IDV Values for Cover

  console.log('min idv val',relinaceApiData,rsaApiData)
  
 
  useEffect(()=>{
    setRelMinIdvVal(relianceIdvValues&&relianceIdvValues[0])
    setRelMaxIdvVal(relianceIdvValues&&relianceIdvValues[1])
  },[relianceIdvValues])
  useEffect(() => {
    const minValuesToCompare = [ (((rsaApiData&&Object.keys(rsaApiData).length<1)|| rsaApiData==null)?null:+rsaApiData.PREMIUMDETAILS.DATA.MINIMUM_IDV),
        (((godigitData&&Object.keys(godigitData).length<1)||godigitData==null)?null:  +godigitData.vehicle.vehicleIDV.minimumIdv),
        (((relinaceApiData&&Object.keys(relinaceApiData).length<1)||relinaceApiData==null)?null: +relMinIdvVal)
            ]

            const nonNullValues = (rsaApiData||relinaceApiData||godigitData)&& minValuesToCompare.filter(value => value !== null);
            const minIDv = nonNullValues&& Math.min(...nonNullValues);
    const maxIDv = Math.max(
        (((rsaApiData&&Object.keys(rsaApiData).length<1)|| rsaApiData==null)?null:+rsaApiData.PREMIUMDETAILS.DATA.MAXIMUM_IDV),
        (((godigitData&&Object.keys(godigitData).length<1)||godigitData==null)?null:  +godigitData.vehicle.vehicleIDV.maximumIdv),
        (((relinaceApiData&&Object.keys(relinaceApiData).length<1)||relinaceApiData==null)?null: +relMaxIdvVal)
          
        )
    
    setMinIDVValue(minIDv!==Infinity?minIDv:0);
    setMaxIDVValue(maxIDv);
  }, [loadApiData, compareData, checkData, rsaApiData, godigitData, relinaceApiData, relMinIdvVal, relMaxIdvVal]);

  const isMobile = window.innerWidth <= 1025;

  return (
    <>
    <div className={` lg:flex lg:flex-col lg:gap-y-5 ${isDetailsOpen=='true'?'z-10':''} relative   shrink grow w-full justify-evenly i ms-center space-y-5 lg:space-y-0`}>
      {filterData.map((item, key) => (
        <div className=" space-y-2">
          <h1 className={` ${(item.id==2 )&& (isThirdParty == (undefined || null)
                      ? oldPolicyType !== "thirdParty"
                      : isThirdParty !== "thirdParty") ?'lg:text-[#5f5f62]' :(item.id==2)&&'text-[#cdc8c8d7]'} ${(item.id==1||item.id==4)&&'lg:text-[#777777]'} font-bold text-[14px]  -[#777777] `}>
            {item.title}
          </h1>
          <div className="flex flex-col">
            <div className={`${(item.id==2 )&& (isThirdParty == (undefined || null)
                  ? oldPolicyType !== "thirdParty"
                  : isThirdParty !== "thirdParty")?'lg:bg-white rounded-lg':(item.id==2 )&&'lg:bg-[#cdc8c8d7] cursor-not-allowed text-[#665d5deb] '}  relative w-[ %]    lg:border lg:border-hero lg:rounded-lg flex shrink grow items-center `}    onClick={() => {
                 
                 if (item.id == "1") {
                    setPolicy(!policy);
                    setCover(false);
                    setDiscount(false);
                    setAddons(false);
                    setClosePopUp(true);
                  }

                  if (item.id == "2") {
                    (isThirdParty == (undefined || null)
                      ? oldPolicyType !== "thirdParty"
                      : isThirdParty !== "thirdParty")&&setCover(!cover);
                    setPolicy(false);
                    setDiscount(false);
                    setAddons(false);
                    setClosePopUp(true);
                  }
                  if (item.id == "3") {
                    (isThirdParty == (undefined || null)
                    ? oldPolicyType !== "thir dParty"
                    : isThirdParty !== "third Party")&& setDiscount(!discount);
                    setCover(false);
                    setPolicy(false);
                    setAddons(false);
                    setClosePopUp(true);
                  }
                  if (item.id == "4") {
                    setAddons(!addons);
                    setCover(false);
                    setDiscount(false);
                    setPolicy(false);
                    setClosePopUp(true);
                  }
                }}>
              {policy||cover||discount||addons ? (
                 (policy&&item.id=='1') || (cover&&item.id=='2') || (discount&&item.id=='3')  || (addons&&item.id=='4')  ?  <AiOutlineUp className="absolute z-10 right-2   lg:text-[#989898] " />: <AiOutlineDown className={`  absolute z-10 right-2   lg:text-[#989898] `} /> 
              ) : (
             <AiOutlineDown className="absolute z-10 right-2   lg:text-[#989898] " />
              )}
{
  console.log('checksome',(key+1==1? policytypePlace&& policytypePlace.replace(/([A-Z])/g, ' $1').trim():'' )  )

}
             {/* {console.log('ffdf', (filterPopData.coverData!==(maxIDVValue && minIDVValue)&&filterPopData.coverData) || (filterPopData.coverData===minIDVValue?'Best Deal':'Lowest' )) } */}
              <input
                type="text"
                placeholder={item.placeHolder}
                autoComplete={false}
                onKeyDown={((e)=>{e.preventDefault()})}
                className={`focus:outline-none rounded-lg  shrink w-full caret-white cursor-pointer capitalize block lg:w-40  xl:w-64  bg-[rgba(0,167,142,0)]   h-10 px-3 text-[15px]   relative`}
              


                  value={ filterPopData &&((key+1==1? policytypePlace&& policytypePlace.replace(/([A-Z])/g, ' $1').trim():'' )||(key+1==2 ? (isThirdParty == (undefined || null)
                ? oldPolicyType !== "thirdParty"
                : isThirdParty !== "thirdParty")?  (idvPlace):'Not Applicable'    :'' )||(key+1==3 ? discountPlace :'' )||(key+1==4 ? `${checkedValue} Applied` :'' ))}
                // (idvPlace==minIDVValue?'Lowest IDV' :'') ||(idvPlace==maxIDVValue?'Best Value':'')|| (idvPlace)
                // ||(key+1==3 || filterPopData.discountData)||(key+1==4 ||  filterPopData.policyData)
                // onClick={() => {
                //   if (item.id == "1") {
                //     setPolicy(true);
                //     setCover(false);
                //     setDiscount(false);
                //     setAddons(false);
                //     setClosePopUp(true);
                //   }

                //   if (item.id == "2") {
                //     setCover(true);
                //     setPolicy(false);
                //     setDiscount(false);
                //     setAddons(false);
                //     setClosePopUp(true);
                //   }
                //   if (item.id == "3") {
                //     setDiscount(true);
                //     setCover(false);
                //     setPolicy(false);
                //     setAddons(false);
                //     setClosePopUp(true);
                //   }
                //   if (item.id == "4") {
                //     setAddons(true);
                //     setCover(false);
                //     setDiscount(false);
                //     setPolicy(false);
                //     setClosePopUp(true);
                //   }
                // }}
                onChange={(e) => {
                  // alert(e.target.value);
                }}
              />
            </div>
            { item.id == "1" && (
              <div className={`${policy?'':'hidden'}`}>

              <div
                className={`absolute  z-20  py-2`}
                onChange={(e) => {
                  setPolicyData(e.target.id);
                }}
              >
                <PolicyFilterCard1
                  parentClass="w-80 lg:w-72"
                mission={[missionAwake, setMissionAwake]}
                loading ={ [loader, setLoader]}
                loadingData ={[loadApiData, setLoadApiData]}
                placeValue = {[policytypePlace, setPolicytypePlace] }
                  closeState={[policy, setPolicy]}
                  discData={[discountData, setDiscountData]}
                  filterCardData={[
                    {
                      id: "1",
                      title: "Policy Type",
                      type: "radio",
                      radioInputClass: "hidden",
                      buttonName: "Confirm",
                          radioData: isNewCar !=='true' ? [ 
                            // {
                            //       id: "all",
                            //       title: "All",
                            //       subTitle: "",
                            //       name: "policyType"
                            //     },
                                {
                                  key:1,
                                  id: "bundled",
                                  title: "Bundled",
                                  // subTitle:
                                  name: "policyType"
                                },
                                {
                                  key:2,

                                  id: "thirdParty",
                                  title: "Third Party",
                                  selectId:'1',
                                  subTitle:
                                  "This covers accidental damage to any property or any other person but does not cover damage to your car.",

                                  name: "policyType"
                                },
                                
                          ]: [
                            // {
                            //       id: "all",
                            //       title: "All",
                            //       subTitle: "",
                            //       name: "policyType"
                            //     },
                                {
                                  key:1,

                                  id: "comprehensive",
                                  title: "Comprehensive",
                                
                                  name: "policyType"
                                },
                                {
                                  key:2,

                                  id: "thirdParty",
                                  title: "Third Party",
                                  selectId:'1',
                                  subTitle:
                                  "This covers accidental damage to any property or any other person but does not cover damage to your car.",
                                 
                                  name: "policyType"
                                },
                                {
                                  key:3,

                                  id: "ownDamage",
                                  title: "Own Damage",
                                  selectId:'2',
                                  subTitle:
                                  "This covers only accidental damages to your car. ",

                                  name: "policyType"
                                },
                            ],
                      // radioData: [
                      //   {
                      //     id: "all",
                      //     title: "All",
                      //     subTitle: "",
                      //     name: "policyType"
                      //   },
                      //   {
                      //     id: "comprehensive",
                      //     title: "Comprehensive",
                      //     subTitle:
                      //       "This covers your car (around 50%) and any damage to any third party.",
                      //     name: "policyType"
                      //   },
                      //   {
                      //     id: "third Party",
                      //     title: "Third Party",
                      //     subTitle:
                      //       "This covers only accidental damage to any property or any other person but does not cover damage to your car.",
                      //     name: "policyType"
                      //   },
                      //   {
                      //     id: "ownDamage",
                      //     title: "Own Damage",
                      //     name: "policyType"
                      //   },
                      // ]
                    }
                  ]}
                />
              </div>
              </div>
            )}
            {item.id == "2" && (
              <div className={`${cover?'':'hidden'}`}> 
              <div
                className={`absolute  z-20   py-2`}
                onChange={(e) => {
                  const names = e.target.id;
                  setCoverData(e.target.value);
                }}
              >
                <PolicyFilterCard2
                mission={[missionAwake, setMissionAwake]}
                  parentClass="w-80 lg:w-72"
                  closeState={[cover, setCover]}
                loading ={ [loader, setLoader]}
                loadingData ={[loadApiData, setLoadApiData]}
                placeValue={[idvPlace, setIdvPlace]}
                minIdv={minIDVValue}
                maxIdv={maxIDVValue}
                  covData={[coverData, setCoverData]}
                  custCovdata={[covCustIDV, setCovCustIDV]}
                  errorMessages2={covCustIDV < minIDVValue || covCustIDV > maxIDVValue
                    ? false
                    : false}
                  filterCardData={[
                    {
                      id: "2",
                      title: "Cover Amount (IDV)",
                      type: "radio2",
                      inputLabel: `Please enter an IDV between ${minIDVValue} and ${maxIDVValue}`,
                      // inputLabel: `Please enter an IDV between 11111 and 222222`,
                      radioInputClass: "block",
                      name: "customIDV",
                      buttonName: "Apply",
                      errorMessage:
                        covCustIDV < minIDVValue || covCustIDV > maxIDVValue
                          ? true
                          : false,
                      radioData: [
                        {
                          id: "4",
                          title: "Best Deal",
                          subTitle: "",
                          value: maxIDVValue,
                          name: "coverAmount"
                        },
                        {
                          id: "5",
                          title: "Lowest IDV",
                          subTitle: "",
                          value: minIDVValue,
                          name: "coverAmount"
                        },
                        {
                          id: "6",
                          title: "Choose your own IDV",
                          subTitle: "",
                          value: covCustIDV,
                          name: "coverAmount"
                        }
                      ]
                    }
                  ]}
                />
              </div>
              </div>
            )}
            {  item.id == "3" && (
              <div className={`${discount?'':'hidden'}`}>

              <div
                className="absolute z-10 py-2"
                onChange={(e) => {
                  // setDiscountData(e.target.value);
                  // console.log(e.target.value);
                }}
              >
                <PolicyFilterCard3
                  parentClass="w-80 lg:w-72  "
                mission={[missionAwake, setMissionAwake]}
                loading ={ [loader, setLoader]}
                loadingData ={[loadApiData, setLoadApiData]}
// placeValue={[discountPlace, setDiscountPlace] }
                  closeState={[discount, setDiscount]}
                  name="discount"
                  discData={[discountPlace, setDiscountPlace]}
                  filterCardData={[
                    {
                      id: "1",
                      title: "Discount",
                      type: "select",
                      inputLabel: "Please choose a Voluntary Deductible amount",
                      buttonName: "Apply",
                      selectData: ["None", "2500", "5000", "7500", "15000"]
                    }
                  ]}
                />
              </div>
              </div>
            )}
            { item.id == "4" && (
              <div className={`${addons ? '':'hidden'} lg:hidden `}>

              <div
                className={`absolute     z-10   py-2`}
                onChange={(e) => {
                  setAddonsData(...e.target.id,e.target.id);
                }}
              >
                {isMobile?
                <PolicyFilterCard4
                  parentClass="w-80 lg:w-96   h-96 overflow-y-scroll mobile-view   lg:left-[-123px]  "
                mission={[missionAwake, setMissionAwake]}
                loading ={ [loader, setLoader]}
                loadingData ={[loadApiData, setLoadApiData]}
                placeValue={[addonsPlace, setAddonsPlace]}
                checkedAddonCount={[checkedValue, setCheckedValue]}
                  closeState={[addons, setAddons]}
                  addsData={[addonsChecked, setAddonsChecked]}
                 filterCardData={ [


                    {
                      id: "1",
                      title: "Add-Ons Cover",
                      type: "checkbox",
                      buttonName: ["Reset", "Apply"],
                      odAddons: (isThirdParty==(null||undefined)?oldPolicyType=='ownDamage':isThirdParty=='ownDamage')?true:false,
                      checkBoxData: 
                      //  [
                      //   // {
                      //   //   id: "1",
                      //   //   label: "PA Owner Drive   ",
                      //   //   name: "paOwnerDriver"
                      //   // },
                      //   {
                      //     id: "1",
                      //     label: "Zero Depreciation ",
                      //     name: "zeroDepreciation"
                      //   },
                      //   {
                      //     id: "2",
                      //     label: "Road Side Assistance ",
                      //     name: "roadSideAssistance"
                      //   },
                      //   {
                      //     id: "3",
                      //     label: "Consumables",
                      //     name: "consumables"
                      //   },
                      //   {
                      //     id: "4",
                      //     label: "Key loss",
                      //     name: "keyLoss"
                      //   },
                      //   {
                      //     id: "5",
                      //     label: "Return to Invoice ",
                      //     name: "returntoInvoice"
                      //   },
                      //   {
                      //     id: "6",
                      //     label: "Engine Protect",
                      //     name: "engineProtect",
                          
                      //   },
                      //   {
                      //     id: "7",
                      //     label: "NCB protection",
                      //     name: "ncbProtection"
                      //   },
                      //   {
                      //     id: "8",
                      //     label: "Tyre Protection",
                      //     name: "tyreProtection"
                      //   },
                       
                      //   {
                      //     id: "9",
                      //     label: "Loss of personal belongings ",
                      //     name: "lossOfPersonalBelongings"
                      //   },
                      //   {
                      //     id: "10",
                      //     label: "Electrical accessories ",
                      //     name: "electricalaccessories",
                      //     isInput1:true
                      //   },
                      //   {
                      //     id: "11",
                      //     label: "Non-electrical accessories",
                      //     name: "nonElectricalaccessories",
                      //     isInput2:true

                      //   },
                      //   {
                      //     id: "12",
                      //     label: "External Bi-Fuel kit",
                      //     name: "externalBiFuelkit",
                      //     isInput3:true

                      //   },
                      // ] :
                      [
                        // {
                        //   id: "1",
                        //   label: "PA Owner Drive   ",
                        //   name: "paOwnerDriver"
                        // },
                        {
                          id: "1",
                          label: "Zero Depreciation ",
                          name: "zeroDepreciation"
                        },
                        {
                          id: "2",
                          label: "Road Side Assistance ",
                          name: "roadSideAssistance"
                        },
                        {
                          id: "3",
                          label: "Consumables",
                          name: "consumables"
                        },
                        {
                          id: "4",
                          label: "Key loss",
                          name: "keyLoss"
                        },
                        {
                          id: "5",
                          label: "Return to Invoice ",
                          name: "returntoInvoice"
                        },
                        {
                          id: "6",
                          label: "Engine Protect",
                          name: "engineProtect",
                          
                        },
                        {
                          id: "7",
                          label: "NCB protection",
                          name: "ncbProtection"
                        },
                        {
                          id: "8",
                          label: "Tyre Protection",
                          name: "tyreProtection"
                        },
                       
                        {
                          id: "9",
                          label: "Loss of personal belongings ",
                          name: "lossOfPersonalBelongings"
                        },
                        {
                          id: "10",
                          label: "Electrical accessories ",
                          name: "electricalaccessories",
                          isInput1:true
                        },
                        {
                          id: "11",
                          label: "Non-electrical accessories",
                          name: "nonElectricalaccessories",
                          isInput2:true

                        },
                        {
                          id: "12",
                          label: "External Bi-Fuel kit",
                          name: "externalBiFuelkit",
                          isInput3:true

                        },
                        {
                          id: "13",
                          label: "Paid Driver cover",
                          name: "paidDrivercover",
                          checkalt:true,
                          checkText:'Sum insured  : 200000',
                          checkClass:'right-6  -top-12 lg:-top-10'
                        },
                        
                        {
                          id: "14",
                          label: "Owner Driver PA cover",
                          name: "ownerDriverPAcover",
                          check:true,
                          checkText:'Compulsory as per Govt',
                          checkClass : '-right-5 -top-12 lg:-top-10',
                          isInput4:true
                        },
                        {
                          id: "15",
                          label: "Unnamed passenger PA cover",
                          name: "unnamedpassengerPAcover",
                          checkalt:true,
                          checkText:'Sum insured : 100000',
                          checkClass:'-right-4  -top-12 lg:-top-10'

                        },
                      ]
                    }
                  ]  
                
                }
                />:<div></div>}
              </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
    <div className="z-20">

      {/* <LoadingSpinner navTrue={false}/> */}
    </div>
    </>

  );
};
