import React from "react";
const steps = [
  {
    id: "1",
    step: "STEP 1",
    content:
      "Sign up by filling our POSP form given above, and our team will get back to you with more details."
  },
  {
    id: "2",
    step: "STEP 2",
    content:
      "Submit your documents (educational certificates, ID proofs, etc.) so that we can verify them."
  },
  {
    id: "3",
    step: "STEP 3",
    content:
      "Take our 15-hour training. You can do this from the comfort of your home!"
  },
  {
    id: "4",
    step: "STEP 4",
    content: "Complete out prescribed POSP examination."
  },
  {
    id: "5",
    step: "STEP 5",
    content:
      "Sign the agreement with us and that’s it! You’ll be a certified POSP."
  }
];
const BecomePosp = () => {
  return (
    <>
      {steps.map((item, key) => (
        <>
          <div className=" hidden lg:flex flex-col  m-auto justify-center items-center">
            <div className="relative flex  items-center">
              <div
                className={`bg-[rgba(0,167,142,0.1)] absolute w-96 p-3 rounded-lg ${
                  key == 1 || key == 3 ? "right-24" : "left-24"
                }  `}
              >
                <h1 className="w-[] text-start">{item.content}</h1>
              </div>
              <div className="bg-hero w-[80px] h-[80px] rounded-full flex flex-col  m-auto justify-center items-center">
                <h2 className="text-white font-bold ">{item.step}</h2>
              </div>
            </div>
            <div
              className={`${
                key == 4 ? "hidden" : " h-14 w-6 bg-[rgba(0,167,142,0.1)]"
              }`}
            ></div>
          </div>
          <div className="relative flex  items-center lg:hidden pb-10 px-5">
            <div
              className={`bg-hero w-[60px] h-[60px] rounded-full flex flex-col ${
                key == 1 || key == 3 ? "ml-auto" : "mr-auto"
              }   justify-center items-center`}
            >
              <h3 className="text-white text-xs font-bold ">{item.step}</h3>
            </div>
            <div
              className={`bg-[rgba(0,167,142,0.1)] absolute w-60 p-3 rounded-lg ${
                key == 1 || key == 3 ? "right-" : "left-24"
              }  `}
            >
              <h1 className="w-[200px]  text-xs t">{item.content}</h1>
            </div>
          </div>
        </>
      ))}
    </>
  );
};

export default BecomePosp;
