import React,{useState} from 'react'

const KycModal = () => {

// const [isDataLoaded, setIsDataLoaded] = propLoad
    // console.log('load',isDataLoaded)
  return (
    <>
   
    <div className="absolute  bg-[rgba(119,117,117,0.43)] w-screen h-screen z-0"></div>
    <div className='border-2  bg-white rounded-md border-white shadow-xl drop-shadow-xl w-96 h-60 z-50'>
        <div className="flex flex-col justify-center items-center h-full">

        <button className='bg-hero w-max px-5 py-2 rounded-full text-white focus:outline-none cursor-pointer' >Verify Kyc</button>
        <p className="px-5 text-center text-sm py-2 text-[#2e302f]">
          Please Click on Verify Kyc to Verify and Please return to this page Verify Again 
        </p>
        </div>
        
        </div>
    </>
  )
}

export default KycModal