import React,{useState,useEffect} from "react";
import NavHeader from "../NavHeader";
import axios from "axios";
import { AiOutlineClose } from "react-icons/ai";
const PaymentFailure = () => {
  const [closeNav, setCloseNav] = useState(false)
  const [paymentValues, setPaymentValues] = useState(null)
  const [actionType, setActionType] = useState('')
  const [insurerName, setInsurerName] = useState()
  const [jwtToken, setJwtToken] = useState(localStorage.getItem('jwt_token'))

  const paymentIdValues = localStorage.getItem("payment_Data")
  const paymentIdValues2 = localStorage.getItem("bike_payment_Data")
  const actiontype = sessionStorage.getItem("action_type")
  const insurername = localStorage.getItem('step-1_Values')
  const insurername2 = localStorage.getItem('bike_step-1_Values')
/* -------------------  API ENDPOINT -------------- */
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
/* ---------------------------------- -------------- */
  
useEffect(() => {
  const onStorage = () => {
    setJwtToken(localStorage.getItem('jwt_token'));
  };

  window.addEventListener('storage', onStorage);

  return () => {
      window.removeEventListener('storage', onStorage);
  };
}, []);

  useEffect(() => {
    setActionType(actiontype)
  }, [actiontype]);


  useEffect(()=>{
    if(actionType=='Car'){
      setPaymentValues(JSON.parse(paymentIdValues))
  
  setInsurerName(JSON.parse(insurername))
      
    return ;
  
    }
    if(actionType==='Bike') {
  setInsurerName(JSON.parse(insurername2))
  
      setPaymentValues(JSON.parse(paymentIdValues2))
  
    }
       
  
  
  
  },[actionType,  insurername, insurername2, paymentIdValues, paymentIdValues2])

const urlparam =window.location.search


  /* ------------- Api For Purchase Data ---------------- */
useEffect(()=>{

  paymentValues&&urlparam&&insurerName&&axios(
      
    {
    
    url:`${API_ENDPOINT}/quotes/purchase`,
    method:'POST',
   
    data:{
      "proposalNo":paymentValues&&paymentValues.ProposalNo,  // proposal api 
    "policyNo":urlparam&&`${urlparam.split('|')[1]}`, /// url 
    "transactionStatus":"failure", //
    "statusId":"1", // null of others
    "insurerName":insurerName&&`${insurerName.firstName+insurerName.lastName}` // compnay
          },
       
        headers: {
          'authorization':jwtToken,
          "Content-Type": "application/json",
        
      }
    
     } ).then((response)=>{
  
  })
  .catch((err)=>{
    console.log(err)
  })

},[ insurerName, paymentValues, urlparam])


  return (
    <div>
      <>
        <NavHeader   dropClose={ [closeNav, setCloseNav] }
 />
        <main
          className="lg:w-full py-20 lg:py-24 bg-no-repeat bg-cover overflow-x-hidden   "  onClick={()=>{
            setCloseNav(false)
          }}
          //   style={{ backgroundImage: `url(${LandingBackground})` }} 
        >
          <div className="slider-in  justify-center bg-white lg:m-auto drop-shadow-xl lg:w-[40%]   mx-4   rounded-xl lg:px-4 py-10 ">
            <AiOutlineClose className="bg-[#F44336] text-white m-auto w-16 h-16 rounded-full p-3 font-extrabold   " />
            <h1 className="text-[#F44336] text-2xl lg:text-3xl font-semibold text-center pt-9  pb-4 lg:pb-10">
              Payment Failed
            </h1>
            <h1 className=" text-xs lg:text-base font-semibold text-center px-4">
              Unfortunately the payment failed due to a <br /> technical error.
              Please contact our <br /> customer support so that our team can{" "}
              <br />
              help you resolve it.
            </h1>
            <button className="w-52 font-semibold flex justify-center m-auto bg-hero text-white lg:text-xl rounded-full p-3 my-8">
              Call us
            </button>
          </div>
        </main>
      </>
    </div>
  );
};

export default PaymentFailure;
