import React,{useState,useEffect} from 'react'
import GodigitKyc from '../components/kyc/GodigitKyc';
import RelianceKyc from '../components/kyc/RelianceKyc';
import LoadingEffect from '../UI/LoadingEffect';
import QuoteLoading from '../UI/QuoteLoading';

const Kyc = () => {

const [tileType, setTileType] = useState(null);
const [pageLoading, setPageLoading] = useState(true)
const tileValue = localStorage.getItem("tile_Type_Detail");


// console.log('kali',tileType,pageLoading)
useEffect(()=>{
    setTileType(tileValue);


},[tileValue])

useEffect(()=>{
  tileType&&setPageLoading(false)
},[tileType])

  return (

    <>
    <div className={`${pageLoading?'hidden':''}`}>
        {(["Reliance","RSA"].includes(tileType)  && <RelianceKyc/>) ||(tileType == "GoDigit" &&<GodigitKyc/>)}
    </div>
    <div className={`flex justify-center items-center ${pageLoading?'':'hidden'}  h-screen `}>

    {pageLoading&&<QuoteLoading/>}
    </div>

    </>
  )
}

export default Kyc