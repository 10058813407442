import React,{useState} from 'react'

const RSAPayment = ({loadData,paymentData,disabled,isTermSel}) => {
    const [paymentDetails, setPaymentDetails] =paymentData
  return (
    <>
   <html>
  <body>
  {/* <html>
<body>
 <form id="PostForm" name="PostForm" autocomplete="off" action="https://www.royalsundaram.in/web/dtc/paymentgateway" method="post">
 <input type="hidden" name="reqType" id="reqType" value="JSON">
<input type="hidden" name="process"  value="paymentOption">
 <input type="hidden" name="apikey" id="apikey" value="310ZQmv/bYJMYrWQ1iYa7s43084=">
 <input type="hidden" name="agentId" id="agentId" value="BA509901">
 <input type="hidden" name="premium" value="9860.08">
 <input type="hidden" name="quoteId" id="quoteId" value="BA509901VPC0000290">
 <input type="hidden" name="version_no" id="version_no" value="12345">
 <input type="hidden" name="strFirstName" id="strFirstName" value="ANINDITA">
 <input type="hidden" name="strEmail" id="strEmail" value="anindita@ensuredit.com">
 <input type="hidden" name="strMobileNo" id="strMobileNo" value="7503265246">
 <input type="hidden" name="isQuickRenew" id="isQuickRenew" value="No">
 <input type="hidden" name="returnUrl" id="returnUrl" value="https://api-preprod.ensuredit.com/proposal/paymentCallback?companyName=ROYAL_SUNDARAM&secretKey=9680-76347">
 <input type="hidden" value="" name="elc_value" id="elc_value">
 <input type="hidden" value="" name="nonelc_value" id="nonelc_value">
 <input type="hidden" value="Billdesk" name="paymentType"/>
 <input type="submit" class="btn-view-summary" value="Submit" title="Submit">
</form>
</body>
</html> */}




    <form id="PostForm"   name="PostForm" autoComplete="off" action={`${window.location.hostname==='www.policymart.co.in'?'https://www.royalsundaram.in/web/dtc/paymentgateway':'https://www.royalsundaram.net/web/test/paymentgateway'} `} method="post">
      <input type="hidden" name="reqType" id="reqType" value="JSON" />
      <input type="hidden" name="process" value="paymentOption" />
      <input type="hidden" name="apikey" id="apikey" value="310ZQmv/bYJMYrWQ1iYa7s43084=" />
      {/* make id a/c to server prod and staging */}
      <input type="hidden" name="agentId" id="agentId" value="BA509901" />
      {/* make id a/c to server prod and staging */}
      {/* Gross Premium need to be sent from proposal api */}
      <input type="hidden" name="premium" value={paymentDetails.price} />
      {/*  [0].PREMIUMDETAILS.DATA.QUOTE_ID */}
      <input type="hidden" name="quoteId" id="quoteId" value={paymentDetails.quoteId}  />

      <input type="hidden" name="version_no" id="version_no" value="12345" />
      {/* first Name from Step1 */}
      <input type="hidden" name="strFirstName" id="strFirstName" value={paymentDetails.firstName}  />
      {/* email from Step1 */}
      <input type="hidden" name="strEmail" id="strEmail" value={paymentDetails.email} />
      {/* Mobile No from Step1 */}
      <input type="hidden" name="strMobileNo" id="strMobileNo" value={paymentDetails.mobile} />
      {/*  */}
      <input type="hidden" name="isQuickRenew" id="isQuickRenew" value="No" />
      <input type="hidden" name="returnUrl" id="returnUrl" value= {paymentDetails.returnUrl} />
      <input type="hidden" value="privatePassengerCar" name="vehicleSubLine" id="privatePassengerCar" />
      <input type="hidden" value="" name="elc_value" id="elc_value" />
      <input type="hidden" value="" name="nonelc_value" id="nonelc_value" />
      <input type="hidden" value="Billdesk" name="paymentType" />
      <button    type="submit" disabled={loadData||disabled||!isTermSel?true:false} className={` ${isTermSel?'bg-[#FECE4C]':'bg-[#fecf4ca3]'}  w-full py-2 rounded-lg font-semibold`}  >
      {loadData||disabled?'Loading...': ' Make Payment' }
      </button>
      {/* <input type="submit" className="btn-view-summary" value="Submit" title="" /> */}
    </form>
  </body>
</html>


    </>
  )

    
}

export default RSAPayment