// src/utils/NotificationUtils.js
function sendNotification(message) {
    if (localStorage.getItem('notificationPermission') === 'granted') {
      navigator.serviceWorker.ready.then((registration) => {
        registration.showNotification('Your Website Name', {
          body: message,
        });
      });
    }
  }
  
  export default sendNotification;
  