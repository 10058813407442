import React,{useState,useEffect,useRef} from "react";
import Carousel, { consts } from "react-elastic-carousel";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { FaArrowLeft, FaArrowRight, FaRegCircle } from "react-icons/fa";
import { VscCircleLargeFilled } from "react-icons/vsc";
import { blogsData } from "../data/BlogsData";
import ArticleCard from "../UI/ArticleCard";
import ArticleSvg from "../assets/Svg/ArticleSvg.svg";
import ArticleSvg1 from "../assets/Svg/AritcleSvg2.svg";
import ArticleSvg2 from "../assets/Svg/ArticleSvg3.svg";
import BlogCard from "../UI/BlogCard";
import { ArticleBlogsData } from "../data/ArticleBlogData";

const articles = [
  {
    imgUrl: ArticleSvg,
    type: "Business",
    quote: "Challange yourself and win the future."
  },
  {
    imgUrl: ArticleSvg1,
    type: "Insurance",
    quote: "Challange yourself and win the future."
  },
  {
    imgUrl: ArticleSvg2,
    type: "Insurance",
    quote: "Challange yourself and win the future."
  }
];




const convertToSlug = (text) => {
  return text.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'')
}
function myArrow({ type, onClick, isEdge }) {
  const pointer =
    type === consts.PREV ? (
      <FaArrowLeft className="bg-[#00A78E] w-10 text-white h-10 rounded-full p-2" />
    ) : (
      <FaArrowRight className="bg-[#00A78E] text-white w-10 h-10 rounded-full p-2" />
    );

  return (
    <button onClick={onClick} disabled={isEdge}>
      {pointer}
    </button>
  );
}

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 1 },
  { width: 768, itemsToShow: 1 },
  { width: 1200, itemsToShow: 3 }
];

const Article = () => {
  const navigate = useNavigate();


  const aboutRef = useRef(null);
  const [showAbout, setShowAbout] = useState(false);

  useEffect(() => {
    const aboutObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setShowAbout(true);
          aboutObserver.unobserve(entry.target);
        }
      });
    }, {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    });

    if (aboutRef.current) {
      aboutObserver.observe(aboutRef.current);
    }
 
    return () => {
      aboutObserver.disconnect();
    };
  }, []);

  return (
    <section className="">
      <h1 className="text-[#1f243c]  text-xl lg:text-3xl font-bold text-center">
        Articles
      </h1>
      <p className="text-[#5B5B5B] text-center text-sm py-2 px-2 lg:px-0 leading-6">
      Welcome to our articles section! Here, you will find a variety of <br className="hidden lg:block" /> informative and engaging pieces on a wide range of topics.
      </p>

      {/* For Mobile Screen Carousel */}
      <div className="lg:hidden  py-3 lg:py-6">
        <Carousel
          breakPoints={breakPoints}
          className={` lg:p-10`}
          renderArrow={myArrow}
          // pagination={false}
          showArrows={false}
          renderPagination={({ pages, activePage, onClick }) => {
            return (
              <div className="flex space-x-1">
                {pages.map((page) => {
                  const isActivePage = activePage === page;
                  return (
                    <VscCircleLargeFilled
                      key={page}
                      onClick={() => onClick(page)}
                      active={isActivePage}
                      className={` ${
                        isActivePage ? "text-hero" : "text-solid"
                      } `}
                    />
                  );
                })}
              </div>
            );
          }}
        >
          {

ArticleBlogsData.map(
  (blogs,key)=>{
    let slug= [convertToSlug(blogs.title)]
    return (
      <>
  <div className={`${key>0?'hi dden':' '}`} key={blogs.id}>
      <Link to={`/article/${slug}`}>
  <BlogCard blogData={[blogs]} hideTag={' '}   />
  </Link>
  </div>
  </>
)})
    }
        </Carousel>
      </div>

      {/* For Desktop Scren Carousel */}
      <div className="hidden lg:flex gap-16 py-6">
{

ArticleBlogsData.map(
  (blogs,key)=>{
    let slug= [convertToSlug(blogs.title)]
    return (
      <>
  <div ref={aboutRef} className={`${key>0?'hi dden':' '}  `} key={blogs.id}>
      <Link to={`/article/${slug}`}>
  <BlogCard blogData={[blogs]} mainClass={`lg:flex-col ${showAbout ? 'sec2Anim1 ' : ''}card${key+1} text-center drop-shadow-lg  h-[450px] justify-center`} />
  </Link>
  </div>
  </>
)})
    }
      {/* {blogsData.filter((blog)=>{

return  blog.category==selectedBlogType}).map((blogs,key)=>{
  let slug= [convertToSlug(blogs.title)]
  return (
    <>
<div className={`${key>0?'hi dden':' '}`} key={blogs.id}>
    <Link to={`/article/${slug}`}>
<BlogCard blogData={[blogs]} />
</Link>
</div>

</>
)})
}  */}
      </div>
      <button
        className="bg-hero text-white flex justify-center my-4 m-auto w-40 p-3 rounded-full font-semibold "
        onClick={() => {
          navigate("/article");
        }}
      >
        View Articles
      </button>
    </section>
  );
};

export default Article;
