import React from "react";
import ClaimSvg from "../assets/Vector/ClaimsVec/ClaimSvg1.svg";
import {
  AiOutlineWhatsApp,
  AiOutlineRight,
  AiOutlineMail
} from "react-icons/ai";
import { BiPhone } from "react-icons/bi";
const claimData = [
  {
    id: "1",
    imgUrl: ClaimSvg,
    title: true,
    heading1: "Toll-Free Number",
    heading2: "+91 70149 87001"
  },
  {
    id: "2",
    emoji: BiPhone,
    title: false,
    title1: "Call us",
    heading1: "Mon-Sat from 9:30am - 5:30pm",
    heading2: "+91 70149 87001"
  },
  {
    id: "2",
    emoji: AiOutlineWhatsApp,
    title: false,
    title1: "Connect on Whatsapp",
    heading2: "+91 70149 87001"
  }
];
const mobileClaimData = [
  {
    id: "1",
    emoji: BiPhone,
    title: "Toll-Free number ",
    bgColor: "rgba(254,206,76,0.1)",
    textColor: "#FECE4C"
  },
  {
    id: "2",
    emoji: AiOutlineWhatsApp,
    title: "Connect on Whatsapp",
    bgColor: "rgba(0,167,142,0.1)",
    textColor: "#00A78E"
  },
  {
    id: "3",
    emoji: AiOutlineMail,
    title: "Email us",
    bgColor: "rgba(108,99,255,0.1)",
    textColor: "#6C63FF"
  }
];

const ClaimCard = () => {
  return (
    <>
      {claimData.map((item, key) => (
        <div
          className={`bg-white ${
            key > 0 ? "hidden lg:block" : ""
          } lg:w-80 shadow-[8px_8px_20px_rgba(0,167,142,0.1)] p-4 rounded-2xl space-y-5 my-4`}
        >
          <div className="flex items-center">
            {key < 1 && <img src={item.imgUrl} alt={item.imgUrl} />}
            {key > 0 && (
              <item.emoji
                className={`${
                  key < 1
                    ? "hidden"
                    : "bg-[rgba(0,167,142,0.1)] text-hero w-12 h-12 p-2 rounded-[10px] hidden lg:block"
                }`}
              />
            )}

            <div className="flex flex-1"></div>

            <h1
              className={`${
                item.title
                  ? "text-sm lg:text-lg font-bold leading-snug"
                  : "hidden"
              }`}
            >
              Our claim <br /> assistance experts <br /> are available{" "}
              <span className="text-hero"> 24*7</span>
            </h1>
          </div>
          <div className="lg:hidden">
            {mobileClaimData.map((item, key) => (
              <div className="flex gap-4 items-center pb-4 pt-2 " key={item.id}>
                <item.emoji
                  className={`bg-[${item.bgColor}] text-[${item.textColor}] w-10 text-2xl`}
                />
                <h2 className="font-bold "> {item.title} </h2>
                <AiOutlineRight className="ml-auto" />
              </div>
            ))}
          </div>
          <h1 className="hidden lg:block font-bold text-lg">{item.title1}</h1>
          <h2 className="hidden lg:block text-sm">{item.heading1}</h2>
          <h2 className=" hidden lg:block font-medium text-[#000000]">
            {item.heading2}
          </h2>
        </div>
      ))}
    </>
  );
};

export default ClaimCard;
