import React, { useState, useEffect } from "react";
import NavHeader from "../NavHeader";
import LandingBackground from "../../assets/Svg/LandingPageBck.svg";
import CustSvg from "../../assets/Svg/customerSvg.svg";

import Search from "../searchBox/Search";
import carModel from "../../data/CarModel";
import { Link, useNavigate } from "react-router-dom";
import DropDownPP from "../customs/DropDownPP";
import RadioPP from "../customs/RadioPP";
import InputPP from "../customs/InputPP";

const manufactureYear = [
  2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019,
  2020, 2021, 2022
];

const CarIntro = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [proceed, setProceed] = useState(false);

  const [registrationError, setRegistrationError] = useState(false);
  const [isCarRegistered, setIsCarRegistered] = useState("");
  const [isNewCar, setIsNewCar] = useState(false);
  const [closeNav, setCloseNav] = useState(false)
  const handleChange = (e) => {
    const name = e.target.name;

    const value = e.target.value;
    setInputs((values) => ({
      ...values,
      [name]: name === "fuelType" || name == "policy" ? e.target.id : value
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    
    if (
      inputs.registrationNumber == undefined ||
      inputs.registrationNumber == ""
      ) {
        setRegistrationError(true);
      } 
      
    if (
      Object.values(inputs).length >= 1 &&
      (inputs.registrationNumber.length > 8 && inputs.registrationNumber.length <11)
    ) {
      setProceed(true);
      setIsCarRegistered("true");
      localStorage.setItem(`car_Intro`, JSON.stringify(inputs));
      localStorage.setItem("isCarRegistered", "true");
      setRegistrationError(false)
    } else {
      localStorage.setItem("isCarRegistered", "false");
      setRegistrationError(true)
      setProceed(false);

    }
    if (
      Object.values(inputs).length >= 1 &&
      inputs.registrationNumber.length < 5 &&
      isNewCar == true
      ) {
        localStorage.setItem("isCarRegistered", "false");
      } else {
        // localStorage.setItem("isCarRegistered", "false");
      }
      sessionStorage.setItem('action_type','Car')
      navigate(proceed ? "/carinsurance/car-profile/prestep-2" : "");

  };

  useEffect(() => {
    const inputValues = localStorage.getItem("car_Intro");
    inputValues && setInputs(JSON.parse(inputValues));
  }, []);

  useEffect(()=>{
    if (/^[aA-zZ0-9-]+$/g.test(inputs.registrationNumber) == false) {
      setRegistrationError(true);
      setProceed(false);
    }
  },[inputs.registrationNumber])
  useEffect(() => {
    if (
      Object.values(inputs).length >= 1 &&
      (inputs.registrationNumber.length > 8 && inputs.registrationNumber.length <11)
    ) {
      setProceed(true);
      
      setRegistrationError(false)
    } else {
      setRegistrationError(true)
      setProceed(false);

    }
    if (
      Object.values(inputs).length >= 1 &&
      inputs.registrationNumber.length < 5 &&
      isNewCar == true
    ) {
      localStorage.setItem("isCarRegistered", "false");
    } else {
      // localStorage.setItem("isCarRegistered", "false");
    }
  }, [inputs, isNewCar]);

  // useEffect(()=>{

  //   const vehicleNumberHandle = ()=>{
  //     let vehNo = ['']
  //     let spliting = carRegistrationNumber.split('')
  //     vehNo.unshift(spliting)
  //     setVehicleNumber(vehNo[0][0]+vehNo[0][1]+'-'+vehNo[0][2]+vehNo[0][3]+'-'+vehNo[0][4]+'-'+vehNo[0][5]+vehNo[0][6]+vehNo[0][7]+vehNo[0][8])
  //     setRtoRegionCode(vehNo[0][0]+vehNo[0][1]+vehNo[0][2]+vehNo[0][3])
  //     // console.log(vehNo[0][0]+vehNo[0][1]+'-'+vehNo[0][2]+vehNo[0][3]+'-'+vehNo[0][4]+'-'+vehNo[0][5]+vehNo[0][6]+vehNo[0][7]+vehNo[0][8])
  //   }
  //   vehicleNumberHandle()
  // },[carRegistrationNumber])


  return (
    <>
      <NavHeader dropClose={ [closeNav, setCloseNav] } />
      <main
        className="w-full pb-10 lg:pt-8 bg-no-repeat bg-cover   "
        style={{ backgroundImage: `url(${LandingBackground})` }}  onClick={()=>{
          setCloseNav(false)
        }}
      >
        <form onChange={handleChange}>
          <h1 className="text-xl lg:text-3xl text-[#293651] font-semibold  py-10   lg:pt-4 text-center">
            Car Insurance Quote
          </h1>
          <div className=" justify-center bg-white mx-5 lg:m-auto shadow-[0px_0px_25px_rgba(0,0,0,0.1)] lg:drop-shadow-lg lg:w-[55%] h-[500px   px-4 py-5">
            <h1 className="mr-auto font-medium text-solid lg:text-xl py-4 pl-4">
              Car Registration Information
            </h1>
            <hr className="text-[#DBDBDB] pb-4" />
            <div className="lg:flex">
              {/* <h1 className="">Compare & Save upto 85%* on Car Insurance</h1> */}
              <div className="lg:flex lg:flex-col items-center">
                <div className="">
                  <h1 className="lg:pl-20 lg:pt-5 font-semibold text-sm lg:text-xl">
                    Compare & Save upto 85%* on Car Insurance
                  </h1>
                </div>
                <div className="lg:flex lg:flex-col lg:justify-center lg:h-full lg:pl-20">
                  <InputPP
                    className="w-full uppercase lg:w-full   px-3 py-2 my-2 rounded-md"
                    labelClassName="text-[#959595] font-medium pl-1 "
                    id="registrationNumber"
                    label="Registration Number (Format EX: MH01AF0000 )"
                    type="text"
                    placeholder="eg. MH01AF2589"
                    value={proceed ? inputs.registrationNumber : ""}
                    changeFunction={handleChange}
                    errorState={[registrationError, setRegistrationError]}
                  />
                  <Link to="/carinsurance/car-profile/prestep-2">
                    <h3
                      className="text-hero text-center pt-1"
                      onClick={() => {
                        setIsNewCar(isNewCar);
                        localStorage.setItem("isCarRegistered", "false");
                        sessionStorage.setItem('action_type','Car')
                      }}
                    >
                      New Car ? Click Here
                    </h3>
                  </Link>
                </div>
              </div>
              <div className="lg:flex flex-1"></div>
              <img src={CustSvg} alt="CustSvg" className=" hidden md:w-[30%] lg:block" />
            </div>
          </div>
          <div className="flex justify-center space-x-5 pt-8 ">
            <Link to="/carinsurance">
            <button className="bg-[#293651]  w-24 py-2 rounded-full text-white">
              Back
            </button>
            </Link>
            <input
              type="submit"
              className="bg-hero w-24 py-2 rounded-full text-white cursor-pointer "
              onClick={handleSubmit}
              value="Next"
            />
          </div>
        </form>
      </main>
    </>
  );
};

export default CarIntro;
