import React,{useState} from "react";
import { Link } from "react-router-dom";
import NavHeader from "../components/NavHeader";
import LandingBackground from "../assets/Svg/LandingPageBck.svg";
import HealthInsuranceLandingSvg from "../assets/Svg/HealthInsuranceLanding.svg";
import CarLandingSvg2 from "../assets/Svg/CarLandingSvg2.svg";
import QuoteVector from "../assets/Vector/QuoteVector.svg";
import Testimonial from "../components/slider/Testimonial";
import Article from "../components/Article";
import Partners from "../components/slider/Partners";
import Faq from "../components/Faq";
import Footer from "../components/Footer";
import ContactUsCard from "../UI/ContactUsCard";
const HealthInsuranceLanding = () => {
  const [closeNav, setCloseNav] = useState(false)
  return (
    <>
      <NavHeader   dropClose={ [closeNav, setCloseNav] }
 />
      <main
        className="w-full  bg-no-repeat bg-cover  "
        style={{ backgroundImage: `url(${LandingBackground})` }}  onClick={()=>{
          setCloseNav(false)
        }}
      >
        {/* Section - 1 */}
        <section className="flex  flex-col lg:flex-row  lg:px-40 lg:py-24">
          <div className=" pb-12 lg:pb-0">
            <img
              src={HealthInsuranceLandingSvg}
              alt="HealthInsuranceLandingSvg"
              className="pt-10 px-14 lg:px-0  pb-10 lg:pb-32"
            />
            <h1 className="text-2xl lg:text-5xl text-[#293651] text-center lg:text-start lg:leading-snug pb-4 lg:pb-0 ">
              A Right plan for a <br />
              <span className="text-hero font-bold"> Healthier</span> You
            </h1>
            <h2 className=" text-[#777777]  text-base leading-5 lg:leading-loose   text-center  lg:text-start">
              Health insurance provides medical <br className="lg:hidden" />{" "}
              coverage to the <br className="hidden lg:block" /> insured against
              any <br className="lg:hidden" /> emergency and planned medical{" "}
              <br /> expenses. It provides financial{" "}
              <br className="lg:hidden" />
              assistance to cover the cost <br /> of hospitalization, surgeries,
              day care <br className="lg:hidden" /> procedures, etc.
            </h2>
          </div>
          <div className="lg:flex flex-1 "></div>
          <ContactUsCard />
        </section>

        {/* Section -3 Testimonial */}
        <section className="py-8   ">
          <Testimonial />
        </section>

        {/* section -4 Articles */}
        <section className="py-10 lg:py-20  flex justify-center">
          <Article />
        </section>

        {/* Section - 5 Partners */}
        <section className="  px-6 lg:px-32  m-auto">
          <Partners />
        </section>
        {/* Section -6 Faqs */}
        <section className="lg:py-16 lg:px-40">
          <Faq />
        </section>
        {/* Global  Footer */}
        <Footer />
      </main>
    </>
  );
};

export default HealthInsuranceLanding;
