import React, { useState, useEffect } from "react";

import LandingBackground from "../assets/Svg/LandingPageBck.svg";
import NavHeader from "../components/NavHeader";
import PospSvg from "../assets/Svg/PospSvg.svg";
import { PospJoinForm } from "../components/joinPosp/PospJoinForm";
import PospCard from "../UI/PospCard";
import BecomePosp from "../components/joinPosp/BecomePosp";
import Footer from "../components/Footer";
import PospFAQ from "../components/joinPosp/PospFAQ";
const JoinPosp = () => {
  const [closeNav, setCloseNav] = useState(false)
  return (
    <>
      <NavHeader   dropClose={ [closeNav, setCloseNav] }
 />
      <main
        className=" bg-[rgba(0,167,142,0.1)] lg:bg-none   bg-no-repeat bg-cover pt-10  lg:pt-20 lg:px-32"
        //   style="background-image:url('../assets/FooterSvg/irdaLogo.png);"
        style={{ backgroundImage: `url(${LandingBackground})` }}  onClick={()=>{
          setCloseNav(false)
        }}
      >
        <div className="lg:flex lg:gap-60 lg:justify-center lg:m-auto lg:w-full">
          <div className="flex flex-col-reverse lg:flex-col lg:space-y-7 ">
            <div>
              <h1 className="text-2xl lg:text-[45px] lg:leading-snug text-center lg:text-start  py-6 lg:py-0 font-bold">
                Become a <span className="text-hero"> POSP</span> with <br />
                Policy Mart
              </h1>
              <h2 className="text-center lg:text-start text-[#777777] lg:text-lg pb-16 lg:pb-0">
                A POSP (Point of Salesperson) is the name <br /> given to an
                insurance agent who can sell <br /> specific insurance products
              </h2>
            </div>
            <img src={PospSvg} alt="PospSvg" className="px-20 lg:px-0 m-auto" />
          </div>
          {/* <div className="flex flex-1"></div> */}
          <PospJoinForm />
        </div>
        <div className="py-14 lg:py-20">
          <h1 className="text-hero text-lg lg:text-3xl font-bold text-center">
            Why become a POSP?
          </h1>
          <div className="px-4">
            <PospCard />
          </div>
        </div>
        <div className="pb-10 lg:pb-32">
          <h1 className="text-hero font-bold text-lg lg:text-3xl text-center pb-10 lg:pb-16">
            How to become a POSP?
          </h1>
          <BecomePosp />
        </div>
        <div className="text-hero font-bold textlg lg:text-3xl text-center lg:pb-32">
          <h1 className="pb-10">Frequently Asked Questions</h1>
          <PospFAQ />
        </div>
      </main>
      {/* Global Footer */}
      <Footer />
    </>
  );
};

export default JoinPosp;
