import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import InputPP from "../customs/InputPP";
import RadioPP from "../customs/RadioPP";
import NavHeader from "../NavHeader";
import LandingBackground from "../../assets/Svg/LandingPageBck.svg";
import { carProposalAction } from "../../store/slices/car-proposal";
import { RelianceProposal } from "./proposal/RelianceProposal"; 
import { GodigitProposal } from "./proposal/GodigitProposal";
import { RsaProposal } from "./proposal/RsaProposal";

import {useDispatch, useSelector } from "react-redux";

import DropDownPP from "../customs/DropDownPP";
import { insurerName } from "../../data/PreviousPolicyInsurer";
import InsuranceHeader from "../../UI/insurance/InsuranceHeader";
import InsuranceTracker from "../../UI/insurance/InsuranceTracker";
import axios from "axios";

const CarInfoStep4 = ({ proposalData, dataLoaded }) => {
  //Metro cities in India
  const metroCities = ["Kolkata", "Chennai", "Delhi", "Mumbai"];

  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    vehicleOwnership:'yes',
    carRegisterNo:'',
    carRegistrationDate:'',
    puc:'true'
  });
  const [proceed, setProceed] = useState(false);
  const [carRegisterNoError, setCarRegisterNoError] = useState(false);
  const [engineNoError, setEngineNoError] = useState(false);
  const [chasisNoError, setChasisNoError] = useState(false);
  const [carRegisterDateError, setCarRegisterDateError] = useState(false);
  const [prevPolicyInsurerError, setPrevPolicyInsurerError] = useState(false);
  const [prevTpInsurerError, setPrevTpInsurerError] = useState(false)
  const [prevPolicyNoError, setPrevPolicyNoError] = useState(false);
  const [tpPolicyNumberError, setTpPolicyNumberError] = useState(false);
  const [exisTPExpDateError, setExisTPExpDateError] = useState(false);
  const [carLoanError, setCarLoanError] = useState(false);
  const [load, setLoad] = useState(false);

  const [financierNameError, setFinancierNameError] = useState(false);
  const [financedValue, setFinancedValue] = useState(false)
  const [financierCityError, setFinancierCityError] = useState(false);
  const [financierAddress, setFinancierAddress] = useState(false);
  const [vehicleOwnershipError, setvehicleOwnershipError] = useState(false);
  const [jwtToken, setJwtToken] = useState(localStorage.getItem("jwt_token"));
  const [pincodeDetails, setPincodeDetails] = useState({});
  const [step1Values, setStep1Values] = useState({});
  const [carRegistrationNumber, setCarRegistrationNumber] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [rtoRegionCode, setRtoRegionCode] = useState("");
  const [manufactureYear, setManufactureYear] = useState();
  const [normalCarRegNo, setNormalCarRegNo] = useState();
  const [initialPolicyType, setInitialPolicyType] = useState({});
  const [productCodeValue, setProductCodeValue] = useState(false);
  const [step2Values, setStep2Values] = useState({});
  const [step3Values, setStep3Values] = useState({});
  const [step4Values, setStep4Values] = useState({});
  const [registeredModelValues, setRegisteredModelValues] = useState(null);
  const [modelRegionId, setModelRegionId] = useState({});
  const [popUpsFilterData, setPopUpsFilterData] = useState({});
  const [prevClaimValue, setPrevClaimValue] = useState();
  const [idvValue, setIdvValue] = useState();
  const [dateOfBirth, setDateOfBirth] = useState();
  const [isCarRegistered, setIsCarRegistered] = useState();
  const [tileType, setTileType] = useState();
  const [popData, setPopData] = useState();
  const [selectedNCB, setSelectedNCB] = useState(null);

  const [ratesValues, setRatesValues] = useState();
const [rsaKycApiData, setRsaKycApiData] = useState()
const [ownership, setOwnership] = useState()
const [reasonCpaVal, setReasonCpaVal] = useState([])
const [tppdChange, setTppdChange] = useState()
const [prevAddons, setPrevAddons] = useState()
const [carPucError, setCarPucError] = useState(false)
  const [finalAddons, setFinalAddons] = useState({});
  const [tpNewDate, setTpNewDate] = useState();
  const [finalAddonsInput, setFinalAddonsInput] = useState({});
  const [inputDiscount, setInputDiscount] = useState();
  const [manufactureMonth, setManufactureMonth] = useState();
  const [masterID, setMasterID] = useState({});
  const [inputIdvValue, setInputIdvValue] = useState()
  const [previousInsurerId, setPreviousInsurerId] = useState({});
  const [prevTpInsurerId, setPrevTpInsurerId] = useState({})
  const [previousInsurerId2, setPreviousInsurerId2] = useState({});
  const [prevInsurerData, setPrevInsurerData] = useState([]);
  const [prevInsurerData2, setPrevInsurerData2] = useState([]);
  const [loadAgain, setLoadAgain] = useState(false);
  const [isPopWindowOpen, setIsPopWindowOpen] = useState(false);
  const [closeNav, setCloseNav] = useState(false);
  const [tpExpireDate, setTpExpireDate] = useState('');
  const [tpStartDate, setTpStartDate] = useState('')
  const [makeErrorTrue, setMakeErrorTrue] = useState(false);
const [modelName, setModelName] = useState('')
const [policyTerm, setPolicyTerm] = useState(1)
  const [godigitKycDetails, setGodigitKycDetails] = useState([]);
  const carRegistered = localStorage.getItem("isCarRegistered");
  const filterData = sessionStorage.getItem("inputs_policyType");
  const godigitKycData = localStorage.getItem("godigitKycInput");
  let registeredModelValue = localStorage.getItem("registered_model");
const rsaKycApi = localStorage.getItem('rsaKyc_apidata')
  const carOwnerDetails = localStorage.getItem("step-1_Values");
  const personalDetails = localStorage.getItem("step-2_Values");
  const addressDetails = localStorage.getItem("step-3_Values");
  const vechileDetails = localStorage.getItem("step-4_Values");
  const pincodeData = localStorage.getItem("pincode_Details");
  const insurerId = localStorage.getItem("insurance_Id");
  const insurerTpId = localStorage.getItem("insurance-Tp_Id");
  let registeredModelOwnershipValue= localStorage.getItem('registered_model_ownership')
const inputIdvVal = sessionStorage.getItem('inputs_IDV')
  const radioValue = localStorage.getItem("car_Info");
  let registeredModelClaimValue = localStorage.getItem(
    "registered_model_claim"
  );
  const cpaValues = sessionStorage.getItem('cpaValues');
  const tppdVal = sessionStorage.getItem('tppdVal')

  const carRegNO = localStorage.getItem("car_Intro");
  const modelRegion = localStorage.getItem("regionCode");
  const tileValue = localStorage.getItem("tile_Type_Detail");
  const apidata = localStorage.getItem("apiData");
  const finaladdon = sessionStorage.getItem("input_addons_check");
  const finaladdoninput = sessionStorage.getItem("check_inputs");
  const finalDiscount = sessionStorage.getItem("inputs_discount");
  const masteridvalues = localStorage.getItem("master_ID");
  const prevPolicyHolderNames = localStorage.getItem("prev_policy_names");
  const rateValue = localStorage.getItem("ratesValues");
  const prevOptedAddon = sessionStorage.getItem('prevAddons')
  const model = localStorage.getItem("car_Info_make_model");
  const currentNcbVal = sessionStorage.getItem('currentNcb')

  const [proposalApiData, setProposalApiData] = proposalData;
  const [isDataLoaded, seTisDataLoaded] = dataLoaded;
  /* -------------------  API ENDPOINT -------------- */
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  /* ---------------------------------- -------------- */
  const dispatch = useDispatch();

  const { fetchProposalReliance ,updateInputValues} = RelianceProposal();
  const { fetchProposalGodigit ,updateInputValuesGodigit} = GodigitProposal();
  const { fetchProposalRsa ,updateInputValuesRsa} = RsaProposal();


  /* --------------- Redux Values ------------------------ */
  let rsaApiData = useSelector(state=>state.quoteRsa.apiData)

  let godigitApiData = useSelector(state=>state.quoteGodigit.apiData)

  let relianceApiData = useSelector(state=>state.quoteReliance.apiData)

  /* --------------------------------------------------------- */

  // useEffect(()=>{
  //   if(Object.values(masterID).length>0){
  //     setLoad(true)
  //   }
  // },[masterID])

  useEffect(() => {
    setTileType(tileValue);
    setPopData(JSON.parse(apidata));
  }, [tileValue, apidata]);

  useEffect(() => {
    setIdvValue(
      (tileType == "Reliance" &&
      Object.keys(relianceApiData).length>0  &&relianceApiData.MotorPolicy.IDV) ||
        (tileType == "GoDigit" &&
        Object.keys(godigitApiData).length>0 && godigitApiData.vehicle.vehicleIDV.idv) ||
        (tileType == "RSA" &&
        Object.keys(rsaApiData).length>0&&rsaApiData.PREMIUMDETAILS.DATA.MODEL_IDV)
    );
  }, [godigitApiData, relianceApiData, rsaApiData, tileType]);

  console.log('reliance idv',godigitApiData&&godigitApiData.vehicle)
  useEffect(() => {
    setPopUpsFilterData(filterData);
    setRegisteredModelValues(JSON.parse(registeredModelValue));
    setPrevClaimValue(registeredModelClaimValue);
    carRegNO !== null
      ? setCarRegistrationNumber(JSON.parse(carRegNO).registrationNumber)
      : setCarRegistrationNumber(inputs.carRegisterNo);
    setRsaKycApiData(JSON.parse(rsaKycApi))
    setManufactureYear(JSON.parse(radioValue).manufactureYear);
setModelName(model)

    carRegNO && setNormalCarRegNo(JSON.parse(carRegNO).registrationNumber);
    setInitialPolicyType(JSON.parse(radioValue));
    setPincodeDetails(JSON.parse(pincodeData));
    setModelRegionId(JSON.parse(modelRegion));
    setStep1Values(JSON.parse(carOwnerDetails));
    setStep2Values(JSON.parse(personalDetails));
    setStep3Values(JSON.parse(addressDetails));
    setStep4Values(JSON.parse(vechileDetails));
    setFinalAddonsInput(JSON.parse(finaladdoninput));
    setPolicyTerm(finaladdoninput? JSON.parse(finaladdoninput).checkInput4:1);
    setSelectedNCB(currentNcbVal)

    setInputDiscount(finalDiscount);
    setOwnership(registeredModelOwnershipValue)
    setInputIdvValue(inputIdvVal)
    setMasterID(JSON.parse(masteridvalues));
  setReasonCpaVal(cpaValues)
  setTppdChange(tppdVal)
  setPrevAddons(prevOptedAddon)

    setPrevInsurerData2(JSON.parse(prevPolicyHolderNames));
    setGodigitKycDetails(JSON.parse(godigitKycData));
    setFinalAddons(JSON.parse(finaladdon));
    // load&&setPreviousInsurerId2(JSON.parse(insurerId))
    setRatesValues(JSON.parse(rateValue));

    setIsCarRegistered(carRegistered);
  }, [carOwnerDetails, prevOptedAddon, load, cpaValues, registeredModelOwnershipValue, rsaKycApi, rateValue, inputs, inputIdvVal, godigitKycData, prevPolicyHolderNames, finalDiscount, insurerId, masteridvalues, finaladdoninput, carRegistered, finaladdon, modelRegion, pincodeData, radioValue, carRegNO, registeredModelClaimValue, vechileDetails, filterData, registeredModelValue, addressDetails, personalDetails, tppdVal, model]);

 
  useEffect(() => {
    /* ------------ For Getting Prev Insurer Names ---------------- */
    axios
      .get(`${API_ENDPOINT}/previousInsurer/listOfPrevIns?company=${tileValue}`, {
        headers: {
          Authorization: `${jwtToken}`,
          "Content-Type": "application/json"
        }
      })
      .then((response) => {
        const values = [];
        response.data.map((item) =>
          values.push(item)
        );
        setPrevInsurerData(values);
        localStorage.setItem('prev_policy_names', JSON.stringify(values))
        // response.data.standardPreviousInsurerName.map((item)=>(
        setLoadAgain(true);
        //   (item)
        // ))
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  useEffect(() => {
    /* ---------------- For Getting ID's of Previous Insurer -------------- */
   
    Object.keys(inputs).includes("prevPolicyInsurer") &&
      axios
        .get(`${API_ENDPOINT}/insurance/id?name=${inputs.prevPolicyInsurer}&companyName=${tileValue}`, {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json"
          }
        })
        .then((response) => {
          localStorage.setItem(
            "insurance_Id",
            JSON.stringify(response.data[0])
          );
          setPreviousInsurerId(response.data[0]);
          setLoad(true);
        })
        .catch((err) => {
          console.log(err);
        });
   
    Object.keys(inputs).includes("prevTpInsurer") &&
      axios
        .get(`${API_ENDPOINT}/insurance/id?name=${inputs.prevTpInsurer}&companyName=${tileValue}`, {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json"
          }
        })
        .then((response) => {
          localStorage.setItem(
            "insurance-Tp_Id",
            JSON.stringify(response.data[0])
          );
          setPrevTpInsurerId(response.data[0]);
          setLoad(true);
        })
        .catch((err) => {
          console.log(err);
        });

    // https://staging-policymart.co.in/api/v1/insurance/id?name=
  }, [inputs,tileValue]);

 
  // console.log('testing APi value ',Object.keys(previousInsurerId).length) //2347

  useEffect(() => {
    const vehicleNumberHandle = () => {
      let vehNo = [""];
      let spliting = carRegistrationNumber && carRegistrationNumber.split("");
      vehNo.unshift(spliting);
      carRegistrationNumber &&
        setVehicleNumber(
          (carRegistrationNumber && carRegistrationNumber.length) < 10
            ? vehNo[0][0] +
                vehNo[0][1] +
                "-" +
                vehNo[0][2] +
                vehNo[0][3] +
                "-" +
                vehNo[0][4] +
                "-" +
                vehNo[0][5] +
                vehNo[0][6] +
                vehNo[0][7] +
                vehNo[0][8]
            : vehNo[0][0] +
                vehNo[0][1] +
                "-" +
                vehNo[0][2] +
                vehNo[0][3] +
                "-" +
                vehNo[0][4] +
                vehNo[0][5] +
                "-" +
                vehNo[0][6] +
                vehNo[0][7] +
                vehNo[0][8] +
                vehNo[0][9]
        );
      carRegistrationNumber &&
        setRtoRegionCode(vehNo[0][0] + vehNo[0][1] + vehNo[0][2] + vehNo[0][3]);
      // console.log(vehNo[0][0]+vehNo[0][1]+'-'+vehNo[0][2]+vehNo[0][3]+'-'+vehNo[0][4]+'-'+vehNo[0][5]+vehNo[0][6]+vehNo[0][7]+vehNo[0][8])
    };
    vehicleNumberHandle();
  }, [carRegistrationNumber]);
  // console.log('testing number',carRegistrationNumber.length)

  useEffect(() => {
    const vehicleNumberHandle = () => {
      let dob = [""];
      let spliting = step2Values.dateOfBirth.split("-");
      dob.unshift(spliting);
      setDateOfBirth(dob[0][2] + "/" + dob[0][1] + "/" + dob[0][0]);
    };
    step2Values.dateOfBirth && vehicleNumberHandle();
  }, [step2Values.dateOfBirth]);

  useEffect(() => {
    if (popUpsFilterData && popUpsFilterData.length > 0) {
      setProductCodeValue(true);
    } else {
      setProductCodeValue(false);
    }
  }, [popUpsFilterData]);

  useEffect(() => {
    if (
      Object.keys(inputs).includes("carRegisterNo") &&
      inputs.carRegisterNo.length < 1
    ) {
      setCarRegisterNoError(true);
    } else if (
      Object.keys(inputs).includes("carRegisterNo") &&
      inputs.carRegisterNo.length > 0
    ) {
      setCarRegisterNoError(false);
    }
    if (
      Object.keys(inputs).includes("engineNo") &&
      inputs.engineNo.length < 1
    ) {
      setEngineNoError(true);
    } else if (
      Object.keys(inputs).includes("engineNo") &&
      inputs.engineNo.length > 0
    ) {
      setEngineNoError(false);
    }
    if (
      Object.keys(inputs).includes("chasisNo") &&
      inputs.chasisNo.length < 1
    ) {
      setChasisNoError(true);
    } else if (
      Object.keys(inputs).includes("chasisNo") &&
      inputs.chasisNo.length > 0
    ) {
      setChasisNoError(false);
    }
    if (
      Object.keys(inputs).includes("prevPolicyNo") &&
      inputs.prevPolicyNo.length < 1
    ) {
      setPrevPolicyNoError(true);
    } else if (
      Object.keys(inputs).includes("prevPolicyNo") &&
      inputs.prevPolicyNo.length > 0
    ) {
      setPrevPolicyNoError(false);
    }
    if (
      Object.keys(inputs).includes("tpPolicyNumber") &&
      inputs.tpPolicyNumber.length < 1
    ) {
      setTpPolicyNumberError(true);
    } else if (
      Object.keys(inputs).includes("tpPolicyNumber") &&
      inputs.tpPolicyNumber.length > 0
    ) {
      setTpPolicyNumberError(false);
    }
    if (
      Object.keys(inputs).includes("financierName") &&
      inputs.financierName.length < 1
    ) {
      setFinancierNameError(true);
    } else if (
      Object.keys(inputs).includes("financierName") &&
      inputs.financierName.length > 0
    ) {
      setFinancierNameError(false);
    }
    if (
      Object.keys(inputs).includes("financedValue") &&
      inputs.financierName.length < 1
    ) {
      setFinancedValue(true);
    } else if (
      Object.keys(inputs).includes("financedValue") &&
      inputs.financierName.length > 0
    ) {
      setFinancedValue(false);
    }
    if (
      Object.keys(inputs).includes("financierCity") &&
      inputs.financierCity.length < 1
    ) {
      setFinancierCityError(true);
    } else if (
      Object.keys(inputs).includes("financierCity") &&
      inputs.financierCity.length > 0
    ) {
      setFinancierCityError(false);
    }
    if (
      Object.keys(inputs).includes("financierAddress") &&
      inputs.financierAddress.length < 1
    ) {
      setFinancierAddress(true);
    } else if (
      Object.keys(inputs).includes("financierAddress") &&
      inputs.financierAddress.length > 0
    ) {
      setFinancierAddress(false);
    }

   

    // if (
    //   Object.keys(inputs).includes("vehicleOwnership") &&
    //   inputs.vehicleOwnership.length < 1
    // ) {
    //   setvehicleOwnershipError(true);
    // } else if (
    //   Object.keys(inputs).includes("vehicleOwnership") &&
    //   inputs.vehicleOwnership.length > 0
    // ) {
    //   setvehicleOwnershipError(false);
    // }
  }, [inputs]);

  const userEnterDate = new Date(
    registeredModelValues && registeredModelValues.policyExp
  );
  // console.log('dinamma',registeredModelValue,registeredModelValues)
  // let prevDay = userEnterDate.getDate();
  // let prevvDay = userEnterDate.getDate() + 1;

  // let prevMonth = userEnterDate.getMonth() + 1;
  // let prevYear = userEnterDate.getFullYear();
  let prevplusYear = userEnterDate.getFullYear() + 1;
  // let prevActDay = userEnterDate.getDate();
  // let tpPrevYear = userEnterDate.getFullYear() - 1;

  var dateFormatTotime = new Date(userEnterDate);
  var increasedDate = new Date(dateFormatTotime.getTime() + 1 * 86400000);

  // var dateString = "Mon Jun 30 2014 00:00:00";

  var startDate = new Date(
    registeredModelValues && registeredModelValues.policyExp
  );

  // seconds * minutes * hours * milliseconds = 1 day
  var day = 60 * 60 * 24 * 1000;

  var endDate = new Date(startDate.getTime() + day);

  /* ------------------ Manufacture Month ----------------------- */
  const manufacturemonth = new Date(inputs.carRegistrationDate);
  let carMonth = manufacturemonth.getMonth() + 1;
  if (carMonth < 10) {
    carMonth = "0" + carMonth;
  }

  useEffect(() => {
    setIsCarRegistered(carRegistered);
  }, [carRegistered]);

  const handleChange = (e) => {
    const name = e.target.name;

    const value = e.target.value;
    setInputs((values) => ({
      ...values,
      [name]: name === "loan" ||name==='puc' ||name==='vehicleOwnership' || name === "externalKit" ? e.target.id : value
    }));
  };
  // generating Date for tpnewDate
  // useEffect(()=>{

  //   const generateNeedDate =(input)=>{

  //     return setTpNewDate(`${cYear}-${cMonth}-${cDay}`)
  //   }
  // },[step4Values])
  // generateNeedDate(step4Values.existingTPExpDate)

  /* For Generating +1 Day  for Cover from and Cover To Start Here for New vehicle */

  let currentDate ;

  // Add logic to set the date based on a condition
  if (carRegistered==='true' ) {
    currentDate=(new Date(registeredModelValues&&registeredModelValues.policyExp) ); // Add one day
  } else if (carRegistered==='false') {
    currentDate=(new Date() ); // Add two days
  }
  let cDay = currentDate.getDate() + 1;
  let endCDay = currentDate.getDate();

  let cMonth = currentDate.getMonth() + 1;
  let cYear = currentDate.getFullYear();

  if ((cYear + 1) % 4 === 0 && endCDay == 28) {
    endCDay += 1;
  }

  if (cMonth === 11) {
    cMonth = 0;
    cYear += 1;
  } else if (cMonth === 1) {
    let febDays =
      cYear % 4 === 0 && (cYear % 100 !== 0 || cYear % 400 === 0) ? 29 : 28;
    if (cDay === febDays) {
      cDay = 0;
      cMonth += 1;
    }
  } else {
    if (cDay === new Date(cYear, cMonth + 1, 0).getDate()) {
      cDay = 0;
      cMonth += 1;
    }
  }
  let nextDateNew = new Date(cYear, cMonth + 1, cDay + 1);

  let newMonth1New = nextDateNew.getMonth();
  /* For Generating +1 Day  for Cover from and Cover To Start Here for New vehicle */

  /* For Generating +1 Day  for Cover from and Cover To Start Here */

  let newCyear = currentDate.getFullYear() + 1;
  if (cDay < 10) {
    cDay = "0" + cDay;
  }
  if (cMonth < 10) {
    cMonth = "0" + cMonth;
  }
  let day1 = userEnterDate.getDate();
  let month1 = userEnterDate.getMonth();
  let year1 = userEnterDate.getFullYear();

  if (month1 === 11) {
    month1 = 0;
    year1 += 1;
  } else if (month1 === 1) {
    let febDays =
      year1 % 4 === 0 && (year1 % 100 !== 0 || year1 % 400 === 0) ? 29 : 28;
    if (day1 === febDays) {
      day1 = 0;
      month1 += 1;
    }
  } else {
    if (day1 === new Date(year1, month1 + 1, 0).getDate()) {
      day1 = 0;
      month1 += 1;
    }
  }
  let nextDate = new Date(year1, month1 + 1, day1 + 1);


  let newMonth1 = nextDate.getMonth();

/* ---------------- Date Function For Old Vehicle start Here ---------------------  */



 /* ----------------------------------------------------- */
 const startDates = new Date(currentDate);
 startDates.setDate(currentDate.getDate()+1);
 
 // Calculate the end date (coverTo) as the same day next year, adjusting for leap years
 const nextYear = currentDate.getFullYear() + 1;
 
 
 /* ----------------------------------- */
 function getDaysInFebruaryNextYear() {
   const currentYear = new Date().getFullYear();
   const nextYear = currentYear + 1;
 
   // Create a date for March 1st of the next year
   const marchFirstNextYear = new Date(nextYear, 2, 1);
 
   // Subtract one day to get the last day of February in the next year
   const lastDayOfFebruaryNextYear = new Date(marchFirstNextYear - 1);
 
   // Get the day of the month to determine the number of days in February
   return lastDayOfFebruaryNextYear.getDate();
 }
 
 const daysInFebruaryNextYear = getDaysInFebruaryNextYear();
 /* ------------------------------------------------ */
 const userPrevPolicyExp =
 registeredModelValues && registeredModelValues.policyExp;
const startDatenw = new Date(  (isCarRegistered == "true" || isCarRegistered == true )? userPrevPolicyExp : Date.now());
 
 const isNextYearLeap = new Date(nextYear, 1, 29).getDate() === 29;
 const endDates = isNextYearLeap && nextYear%4===0&&daysInFebruaryNextYear===29&&currentDate.getMonth()===1
   ? new Date(nextYear, 1, 29)
   : new Date(nextYear, currentDate.getMonth(), currentDate.getDate() );
 // Format the dates as DD-MM-YYYY
 const formattedStartDate = formatDate(startDates);
 const formattedEndDate = formatDate(endDates);
 const policyEndDatenw = new Date(
  startDatenw.getTime() + 365 * 24 * 60 * 60 * 1000
); 
 const tppolicyEndDatenw = new Date(
  startDatenw.getTime() + 3 * 365 * 24 * 60 * 60 * 1000
);

 // Function to format date as DD-MM-YYYY
 function formatDate(date) {
   const day = String(date.getDate()).padStart(2, '0');
   const month = String(date.getMonth() + 1).padStart(2, '0');
   const year = date.getFullYear();
   return `${day}-${month}-${year}`;
 }
 
 
 /* -------------------------------- */

let dateString =registeredModelValues ? registeredModelValues.policyExp.split("-").reverse().join('-'):'2023-1-5'
// let dateString ='2023-1-5'
let dateParts = dateString.split("-");
let yearAdd1 = parseInt(dateParts[2]);
let monthAdd1 = parseInt(dateParts[1]) - 1;
let dayAdd1 = parseInt(dateParts[0]);

let dateAdd1 = new Date(yearAdd1, monthAdd1, dayAdd1);
dateAdd1.setDate(dateAdd1.getDate() + 1);

let newYear = dateAdd1.getFullYear();
let newMonth = String(dateAdd1.getMonth() + 1).padStart(2, "0");;
let newDay =  String(dateAdd1.getDate()).padStart(2, "0");

let newDateString = `${newDay}-${newMonth}-${newYear}`;
/* +1 year */

let dateCt1 = new Date(`${yearAdd1+1}-${monthAdd1+1}-${dayAdd1}`);
dateCt1.setDate(dateCt1.getDate());

 
let newCtYear = dateCt1.getFullYear();
let newCtMonth = String(dateCt1.getMonth() + 1).padStart(2, "0");;
let newCtDay =  String(dateCt1.getDate()).padStart(2, "0");

let newCtDateString = `${newCtDay}-${newCtMonth}-${newCtYear}`;

/* ------------------- */

/* -1 day */

let dateRem1 = new Date(`${newYear-1}-${newMonth}-${newDay}`);

dateRem1.setDate(dateRem1.getDate() -1);

 
let newRemYear = dateRem1.getFullYear();
let newRemMonth = String(dateRem1.getMonth() + 1).padStart(2, "0");;
let newRemDay =  String(dateRem1.getDate()).padStart(2, "0");

let newRemDateString = `${newRemDay}-${newRemMonth}-${newRemYear}`;


/* ---------------- Date Function For Old Vehicle End Here ---------------------  */

/* ---------------- Date Function For New Vehicle start Here ---------------------  */

 

/* ---------------- Date Function For New Vehicle End Here ---------------------  */

/* --------------------------------------------------------------------- */
  /* For Generating +1 Day  for Cover from and Cover To End Here */

  var date = new Date(inputs.existingTPExpDate);
  let tpDay = date.getDate();
  let tpMonth = date.getMonth() + 1;
  let tpYear = date.getFullYear() - 3;
  if (tpDay < 10) {
    tpDay = "0" + tpDay;
  }
  if (tpMonth < 10) {
    tpMonth = "0" + tpMonth;
  }
  date.setFullYear(date.getFullYear() + 1);


  let prevDay = userEnterDate.getDate();
  let prevvDay = userEnterDate.getDate() + 1;

  let prevMonth = userEnterDate.getMonth() + 1;
  let prevYear = userEnterDate.getFullYear();
  let prevActDay = userEnterDate.getDate();
  let tpPrevYear = userEnterDate.getFullYear() - 1;

  if ((prevYear + 1) % 4 === 0 && prevDay == 28) {
    prevDay += 1;
  }

  var dateFormatTotime = new Date(userEnterDate);
  var increasedDate = new Date(dateFormatTotime.getTime() + 1 * 86400000);

  // var dateString = "Mon Jun 30 2014 00:00:00";

  console.log('policy',isCarRegistered == "true" )

  var startDate = new Date(
    registeredModelValues && registeredModelValues.policyExp
  );

  // seconds * minutes * hours * milliseconds = 1 day
  // var day = 60 * 60 * 24 * 1000;

  var endDate = new Date(startDate.getTime() + day);
  var toCurDate = new Date(currentDate.getTime() + day);
  var fromPrevDay = new Date(userEnterDate.getTime() + day);
  // console.log("dash",
  //  !carRegisterNoError && !engineNoError &&!chasisNoError && !prevPolicyNoError &&!financierNameError && !financierCityError && !financierAddress&& navigate(proceed ? "/carinsurance/car-profile/preview" : ""));

  useEffect(() => {
    if (
      (Object.keys(inputs).includes("carRegisterNo") || makeErrorTrue) &&
      (inputs.carRegisterNo == undefined || inputs.carRegisterNo == "")
    ) {
      setCarRegisterNoError(true);
    } else {
      setCarRegisterNoError(false);
    }
    if (
      (Object.keys(inputs).includes("engineNo") || makeErrorTrue) &&
      (/^[a-zA-Z0-9]*$/.test(inputs.engineNo) == false ||
        inputs.engineNo == undefined ||
        inputs.engineNo == "")
    ) {
      setEngineNoError(true);
    } else {
      setEngineNoError(false);
    }
    if (
      (Object.keys(inputs).includes("chasisNo") || makeErrorTrue) &&
      ((/^[a-zA-Z0-9]*$/.test(inputs.chasisNo) == false || inputs.chasisNo) ==
        undefined ||
        inputs.chasisNo == "")
    ) {
      setChasisNoError(true);
    } else {
      setChasisNoError(false);
    }
    if (
      (Object.keys(inputs).includes("carRegistrationDate") || makeErrorTrue) &&
      (inputs.carRegistrationDate == undefined ||
        inputs.carRegistrationDate == "")
    ) {
      setCarRegisterDateError(true);
    } else {
      setCarRegisterDateError(false);
    }
    if (
      (Object.keys(inputs).includes("prevPolicyInsurer") || makeErrorTrue) &&
      (inputs.prevPolicyInsurer == undefined || inputs.prevPolicyInsurer == "")
    ) {
      setPrevPolicyInsurerError(true);
    } else {
      setPrevPolicyInsurerError(false);
    }
    if (
      (Object.keys(inputs).includes("prevTpInsurer") || makeErrorTrue) &&
      (inputs.prevTpInsurer == undefined || inputs.prevTpInsurer == "")
    ) {
      setPrevTpInsurerError(true);
    } else {
      setPrevTpInsurerError(false);
    }
    if (
      (Object.keys(inputs).includes("prevPolicyNo") || makeErrorTrue) &&
      ((/^[a-zA-Z0-9- /]*$/.test(inputs.prevPolicyNo) == false ||
        inputs.prevPolicyNo) == undefined ||
        inputs.prevPolicyNo == "")
    ) {
      setPrevPolicyNoError(true);
    } else {
      setPrevPolicyNoError(false);
    }
    if (
      (Object.keys(inputs).includes("existingTPExpDate") || makeErrorTrue) &&
      (inputs.existingTPExpDate == undefined || inputs.existingTPExpDate == "")
    ) {
      setExisTPExpDateError(true);
    } else {
      setExisTPExpDateError(false);
    }
    if (
      (Object.keys(inputs).includes("tpPolicyNumber") || makeErrorTrue) &&
      (inputs.tpPolicyNumber == undefined || inputs.tpPolicyNumber == "")
    ) {
      (popUpsFilterData == (undefined || null)
        ? initialPolicyType.policy == "ownDamage"
        : popUpsFilterData == "ownDamage") && setTpPolicyNumberError(true);
    } else {
      setTpPolicyNumberError(false);
    }
    if (
      (Object.keys(inputs).includes("loan") || makeErrorTrue) &&
      (inputs.loan == undefined || inputs.loan == "")
    ) {
      setCarLoanError(true);
    } else {
      setCarLoanError(false);
    }
    if (
      (Object.keys(inputs).includes("puc") || makeErrorTrue) &&
      (inputs.puc == undefined || inputs.puc == "")
    ) {
      setCarPucError(true);
    } else {
      setCarPucError(false);
    }
    if (
      (Object.keys(inputs).includes("financierName") ||
        makeErrorTrue ||
        inputs.loan == "yes") &&
      (inputs.financierName == undefined || inputs.financierName == "")
    ) {
      setFinancierNameError(true);
    } else {
      setFinancierNameError(false);
    }
    if (
      (Object.keys(inputs).includes("financedValue") ||
        makeErrorTrue ||
        inputs.loan == "yes"||tileType == "RSA" )&&
      (inputs.financedValue == undefined || inputs.financedValue == "")
    ) {
      setFinancedValue(true);
    } else {
      setFinancedValue(false);
    }
    if (
      (Object.keys(inputs).includes("financiercity") ||
        makeErrorTrue ||
        inputs.loan == "yes") &&
      (inputs.financiercity == undefined || inputs.financiercity == "")
    ) {
      setFinancierCityError(true);
    } else {
      setFinancierCityError(false);
    }
    if (
      (Object.keys(inputs).includes("financierAddress") ||
        makeErrorTrue ||
        inputs.loan == "yes") &&
      (inputs.financierAddress == undefined || inputs.financierAddress == "")
    ) {
      setFinancierAddress(true);
    } else {
      setFinancierAddress(false);
    }
    // if (
    //   (Object.keys(inputs).includes("vehicleOwnership") || makeErrorTrue) &&
    //   (inputs.vehicleOwnership == undefined || inputs.vehicleOwnership == "")
    // ) {
    //   setvehicleOwnershipError(true);
    // } else {
    //   setvehicleOwnershipError(false);
    // }
  }, [initialPolicyType.policy, inputs, makeErrorTrue, popUpsFilterData]);

  // console.log("vehicle2", dateOfBirth&&dateOfBirth.split('/').reverse().join('/'));
  const handleSubmit = (e) => {
    e.preventDefault();
sessionStorage.setItem('godigitSta','NOT_DONE')
dispatch(carProposalAction.setApiFailed(false));
localStorage.setItem("proposal_loaded", false);
dispatch(carProposalAction.setProposalApiData({}));


    setMakeErrorTrue(true);

    (isCarRegistered == "true"
      ? !carRegisterNoError &&
        !engineNoError &&
        !chasisNoError &&
        !prevPolicyNoError &&
        !carPucError&&
        ((
          popUpsFilterData == (undefined || null)
            ? initialPolicyType.policy == "ownDamage"
            : popUpsFilterData == "ownDamage"
        )
          ? !tpPolicyNumberError
          : true) &&
        (inputs.loan == "yes"
          ? !financierNameError &&(tileType == "RSA"? true:true)&& !financierCityError && !financierAddress
          : true)
      : !carRegisterNoError &&
        !engineNoError &&
        !chasisNoError &&
        (inputs.loan == "yes"
          ? !financierNameError && (tileType == "RSA"?true:true) && !financierCityError && !financierAddress
          : true)) &&
          //  (tileType == "RSA"?!vehicleOwnershipError:true)&&
      navigate(proceed ?tileType=="GoDigit"? "/carinsurance/car-profile/step-5":'/carinsurance/car-profile/preview' : "");

    localStorage.setItem("payment_Data", JSON.stringify({}));
    seTisDataLoaded(false);
  //  proceed&& tileType == "Reliance" &&fetchProposalReliance()
  //  proceed&& tileType == "GoDigit" &&fetchProposalGodigit()
  //  proceed&& tileType == "RSA" &&fetchProposalRsa()
  tileType == "Reliance" &&  axios
        .post(
          `${API_ENDPOINT}/proposal/reliance`,
          //
          {
            productCode:
              isCarRegistered == "true" || isCarRegistered == true
                ? (productCodeValue
                    ? popUpsFilterData == "comprehensive" && 2311
                    : initialPolicyType.policy == "comprehensive" && 2311) ||
                  ((productCodeValue
                    ? popUpsFilterData == "thirdParty" && 2347
                    : initialPolicyType.policy == "thirdParty") &&
                    2347) ||
                  (productCodeValue
                    ? popUpsFilterData == "ownDamage" && 2309
                    : initialPolicyType.policy == "ownDamage" && 2309)
                : (productCodeValue
                    ? popUpsFilterData == "bundled" && 2374
                    : initialPolicyType.policy == "bundled" && 2374) ||
                  (productCodeValue
                    ? popUpsFilterData == "thirdParty" && 2371
                    : initialPolicyType.policy == "thirdParty" && 2371), //based on vehicle type

            clientDetails: {
              // "clientType": 0,
              clientType: step1Values.registered == "yes" ? 0 : 0, // registered in company : yes ?1:0

              // "lastName": "deshmukh",
              lastName: step1Values.lastName,
              // "foreName": "priyanka",
              foreName: step1Values.firstName,
              dob: dateOfBirth,
              // "dob": "01/01/1991",
              // "gender": 2,
              gender: step2Values.gender == "male" ? 1 : 2,
              mobileNo: step1Values.mobile,
              // "mobileNo": step1Values.mobile,
              // "clientAddress":
              // {
              //   "communicationAddress":
              //    {
              //     "addressType": 0,
              //     "address1": 'fsadfdsf',
              //   "address2": "agggaaa",
              //   "address3": "strifffffng",
              //   "cityId":pincodeDetails.cityOrVillageId, // cityid

              //   "districtId":pincodeDetails.districtId,
              //   "stateId": pincodeDetails.stateId, // state id

              //   // "pinCode": '400006',
              //   "pinCode":  step3Values.pincode,

              //   "country": "India"
              // },
              // "permanentAddress": {
              //   "addressType": 0,
              //   "address1": 'step3Values.address',
              //   "address2": "aaaffa",
              //   "address3": "strifffffng",
              //   "cityId":pincodeDetails.cityOrVillageId, // cityid

              //   "districtId":pincodeDetails.districtId,
              //   "stateId": pincodeDetails.stateId, // state id

              //   // "pinCode": '400006',
              //   "pinCode":  step3Values.pincode,

              //   "country": "India"
              // },
              // "registrationAddress": {
              //   "addressType": 0,
              //   // "address1": "Bungalow No. 101",
              //   "address1":'chinthaguda',
              //   "address2": "aaddaa",
              //   "address3": "strifffffng",
              //   "cityId":pincodeDetails.cityOrVillageId, // cityid

              //   "districtId":pincodeDetails.districtId,
              //   "stateId": pincodeDetails.stateId, // state id

              //   // "pinCode": '400006',
              //   "pinCode":  step3Values.pincode,

              //   "country": "India"
              // }
              // },
              clientAddress: {
                communicationAddress: {
                  addressType: 0,
                  address1: step3Values.address,
                  address2: step3Values.address2,
                  address3: step3Values.address2,
                  cityId: pincodeDetails.cityOrVillageId,
                  districtId: pincodeDetails.districtId,
                  stateId: pincodeDetails.stateId,
                  pincode: step3Values.pincode,
                  country: "India"
                },
                permanentAddress: {
                  addressType: 0,
                  address1: step3Values.address,
                  address2: step3Values.address2,
                  address3: step3Values.address2,
                  cityId: pincodeDetails.cityOrVillageId,
                  districtId: pincodeDetails.districtId,
                  stateId: pincodeDetails.stateId,
                  pincode: step3Values.pincode,
                  country: "India"
                },
                registrationAddress: {
                  addressType: 0,
                  address1: step3Values.address,
                  address2: step3Values.address2,
                  address3: step3Values.address2,
                  cityId: pincodeDetails.cityOrVillageId,
                  districtId: pincodeDetails.districtId,
                  stateId: pincodeDetails.stateId,
                  pinCode: step3Values.pincode,
                  country: "India"
                }
              },
              // "emailId": "priyanka.deshmukh@relianceada.com",
              emailId: step1Values.email,
              // "salutation": "Mrs.",
              salutation: `${step1Values.salutation}.`,
              // "maritalStatus": "1951",
              maritalStatus: step2Values.married == "yes" ? "1951" : "1952",
              // registeredUnderGst: step1Values.registered == "yes" ? 1 : 0,
              // "gstin": "27AASCS2460H1Z0"
              gstin: null
            },
            policy: {
              // "tpPolicyNumber": "PB9867786234554321",
              tpPolicyNumber: (
                popUpsFilterData == (undefined || null)
                  ? initialPolicyType.policy == "ownDamage"
                  : popUpsFilterData == "ownDamage"
              )
                ? step4Values.tpPolicyNumber
                : inputs.prevPolicyNo,

              tpPolicyStartDate: initialPolicyType.registrationDate,
              // "tpPolicyEndDate": "2024-08-21",
              tpPolicyEndDate:
                Object.keys(inputs).includes("existingTPExpDate") &&
                inputs.existingTPExpDate.split().reverse().join(),
              tpPolicyInsurer: prevTpInsurerId.relianceId,
              // "businessType": "5",
              businessType:
                isCarRegistered == "true" || isCarRegistered == true
                  ? "5"
                  : "1",
              // "coverFrom": "24/12/2022",
              "coverFrom":    formattedStartDate.split("-").reverse().join('-'), // user inpuut 
     
              "coverTo":  (    !(isCarRegistered == "true" || isCarRegistered == true)&&(popUpsFilterData==(null||undefined)?initialPolicyType.policy == "thirdParty":popUpsFilterData=='thirdParty' ))?tppolicyEndDatenw.toLocaleDateString("en-GB")
          .replace(/\//g, "-")
          .split("-")
          .reverse()
          .join("-") : policyEndDatenw.toLocaleDateString("en-GB")
          .replace(/\//g, "-")
          .split("-")
          .reverse()
          .join("-") , // -1 day from input
              // "coverTo": '2024-02-29' , // -1 day from input
             

              branchCode: "9202",
              agentName: "Direct",
              // "productCode": "2311",
              productCode:
                isCarRegistered == "true" || isCarRegistered == true
                  ? (productCodeValue
                      ? popUpsFilterData == "comprehensive" && 2311
                      : initialPolicyType.policy == "comprehensive" && 2311) ||
                    ((productCodeValue
                      ? popUpsFilterData == "thirdParty" && 2347
                      : initialPolicyType.policy == "thirdParty") &&
                      2347) ||
                    (productCodeValue
                      ? popUpsFilterData == "ownDamage" && 2309
                      : initialPolicyType.policy == "ownDamage" && 2309)
                  : (productCodeValue
                      ? popUpsFilterData == "bundled" && 2374
                      : initialPolicyType.policy == "bundled" && 2374) ||
                    (productCodeValue
                      ? popUpsFilterData == "thirdParty" && 2371
                      : initialPolicyType.policy == "thirdParty" && 2371), //based on vehicle type

              otherSystemName: "1",
              isMotorQuote: "false"
            },
            risk: {
              financierCity: inputs.financiercity,
              exShowroomPrice: "",
              chassis: inputs.chasisNo,
              financeType: inputs.loan == "yes" ? 3 : 2,
              // stateOfRegistrationID: 21, // pincode api
              stateOfRegistrationID: modelRegionId.reliance.stateId, // pincode api
              isHavingValidDrivingLicense: true,
              isOptedStandaloneCPAPolicy:
                finalAddons && finalAddons.ownerDriverPAcover ? false : true,
              // "vehicleVariant": "LXI CNG",
              // "zone":'A',
              zone: modelRegionId.reliance.modelZoneName, // use region rto zone
              financierAddress: inputs.financierAddress,
              rto_RegionCode: modelRegionId&&modelRegionId.reliance.regionCode.replace("-", ""),

              // "cubicCapacity": 998, //not required
              manufactureYear: manufactureYear,
              vehicleMakeID: masterID.reliance[0].makeId, // master id make id
              financierName: inputs.financierName,
              isPermanentAddressSameasCommAddress: true,
              isVehicleHypothicated: inputs.loan == "yes" ? true : false,
              isRegAddressSameasPermanentAddress: true,
              vehicleModelID: masterID.reliance[0].modelId, // master is model id
              isRegAddressSameasCommAddress: true,
              manufactureMonth: carMonth, // car registration date do
              // "dateOfPurchase": "2019-07-01",
              dateOfPurchase: inputs.carRegistrationDate,
              engineNo: inputs.engineNo,
              // "rtolocationID": 571,
              // rtolocationID: 571, // api regioncode modelRegionId
              rtolocationID: modelRegionId.reliance.modelRegionId, // api regioncode modelRegionId
              idv: +idvValue // idv quote
              // idv: 643410 // idv quote
              // idv: 873348.0
            },

            vehicle: {
              seatingCapacity: 5,
              // registrationNumber: 'NEW',
              registrationNumber: isCarRegistered=='true'? vehicleNumber.toUpperCase():`NEW`,
              registrationDate: inputs.carRegistrationDate,
              typeOfFuel: (!["" || null || undefined].includes(finalAddons)&& finalAddons.externalBiFuelkit)?5:
                (initialPolicyType.fuelType == "petrol" && 1) ||
                (initialPolicyType.fuelType == "diesel" && 2) ||
                (['petrol+cng','cng','lpg','petrol+lpg','inbuilt'].includes(initialPolicyType.fuelType) &&5)||
                (initialPolicyType.fuelType == "electric" && 6)
              // typeOfFuel: "1"
            },
            cover: {
              isBasicODCoverage:
                (popUpsFilterData && popUpsFilterData == "thirdParty") ||
                (initialPolicyType && initialPolicyType.policy == "thirdParty")
                  ? false
                  : true,
              lstTaxComponentDetails: "",

              basicLiability:
              (( (popUpsFilterData==undefined||null))?initialPolicyType.policy=="ownDamage":popUpsFilterData=="ownDamage")
                  ? false
                  : true,

              isVoluntaryDeductableOpted:
                inputDiscount == ("" || null || undefined)
                  ? false
                  :(( (popUpsFilterData==undefined||null))?initialPolicyType.policy=="thirdParty":popUpsFilterData=="thirdParty")
                  ? false
                  : inputDiscount == "None"
                  ? false
                  : inputDiscount == "None"
                  ? false
                  : true, // dicut addons
              voluntaryDeductible: {
                voluntaryDeductible: {
                  sumInsured:
                    inputDiscount == ("" || null || undefined)
                      ? "0"
                      :(( (popUpsFilterData==undefined||null))?initialPolicyType.policy=="thirdParty":popUpsFilterData=="thirdParty")
                      ? "0"
                      : inputDiscount == "None"
                      ? "0"
                      : inputDiscount /// discount value
                }
              },

              // "sumInsured": "7500",
              isAntiTheftDeviceFitted: false,
              antiTheftDeviceDiscount: {
                antiTheftDeviceDiscount: {
                  packageName: "",
                  isMandatory: true,
                  noOfItems: "1",
                  isChecked: true
                }
              },
              isPAToOwnerDriverCoverd:
                finalAddons == ("" || null || undefined)
                  ? false
                  : finalAddons.ownerDriverPAcover
                  ? true
                  : false,
              paCoverToOwner: {
                // owner pa driver from addons
                paCoverToOwner: {
                  nomineeName: step2Values.nomineeName, // nominee name from 2nd step
                  nomineeDob: step2Values.nomineedateOfBirth, // nominee dob from 2nd form
                  nomineeRelationship: step2Values.relation, // from 2nd form
                  appointeeName: step2Values.appointeeName,
                  cpaCovertenure: "1",
                  nomineeAddress: ""
                }
              },
              isPAToDriverCovered:
                finalAddons == ("" || null || undefined)
                  ? false
                  : finalAddons.paidDrivercover
                  ? true
                  : false,

              paToPaidDriver: {
                paToPaidDriver: {
                  noOfItems: 1,
                  sumInsured: 200000
                }
              },
              isPAToNamedPassenger: false,
              paToNamedPassenger: {
                paToNamedPassenger: {
                  noOfItems: "3",
                  sumInsured: "100000",
                  isMandatory: false,
                  isChecked: false,
                  passengerName: "1",
                  nomineeName: "2",
                  nomineeDob: "3",
                  nomineeRelationship: "4",
                  nomineeAddress: "5",
                  otherRelation: "6",
                  appointeeName: "7",
                  packageName: "8"
                }
              }, // false
              isPAToUnnamedPassengerCovered:
                finalAddons == ("" || null || undefined)
                  ? false
                  : finalAddons.unnamedpassengerPAcover
                  ? true
                  : false, // addons unnamed
              paToUnNamedPassenger: {
                paToUnNamedPassenger: {
                  noOfItems: "5",
                  sumInsured: "200000"
                }
              },

              isLiabilityToPaidDriverCovered: false,
              liabilityToPaidDriver: {
                liabilityToPaidDriver: {
                  noOfItems: "5"
                } // false
              },
              isTppdCover:
                 (( (popUpsFilterData==undefined||null))?initialPolicyType.policy=="ownDamage":popUpsFilterData=="ownDamage")
                  ? false
                  : true,
              tppdCover: {
                tppdCover: {
                  sumInsured: "750000",
                  isChecked:
                  (( (popUpsFilterData==undefined||null))?initialPolicyType.policy=="ownDamage":popUpsFilterData=="ownDamage")
                      ? false
                      : true
                }
              },

              isNilDepreciation:
                finalAddons == ("" || null || undefined)
                  ? false
                  :(( (popUpsFilterData==undefined||null))?initialPolicyType.policy=="thirdParty":popUpsFilterData=="thirdParty")
                  ? false
                  : finalAddons.zeroDepreciation &&
                    (finalAddons.consumables ||
                      finalAddons.engineProtect ||
                      finalAddons.keyLoss)
                  ? false
                  : finalAddons.zeroDepreciation
                  ? true
                  : false, // from addon
              nilDepreciationCoverage: {
                nilDepreciationCoverage: {
                  applicableRate: ratesValues && ratesValues[0]
                }
              },
              isSecurePlus:
                finalAddons == ("" || null || undefined)
                  ? false
                  :(( (popUpsFilterData==undefined||null))?initialPolicyType.policy=="thirdParty":popUpsFilterData=="thirdParty")
                  ? false
                  : ((finalAddons.zeroDepreciation &&
                      finalAddons.consumables &&
                      finalAddons.engineProtect&&finalAddons.lossOfPersonalBelongings) ||
                      (finalAddons.consumables && finalAddons.engineProtect&&finalAddons.lossOfPersonalBelongings) ||
                      finalAddons.consumables ||
                      finalAddons.engineProtect||finalAddons.lossOfPersonalBelongings) &&
                    finalAddons.tyreProtection !== true
                  ? true
                  : false, // true or false from addons
              securePlus: {
                securePlus: {
                  applicableRate: ratesValues && ratesValues[1]
                }
              },
              isSecurePremium:
                finalAddons == ("" || null || undefined)
                  ? false
                  :(( (popUpsFilterData==undefined||null))?initialPolicyType.policy=="thirdParty":popUpsFilterData=="thirdParty")
                  ? false
                  : finalAddons.tyreProtection
                  ? true
                  : false, // true or false
              securePremium: {
                securePremium: {
                  applicableRate: ratesValues && ratesValues[2]
                }
              },
              // "isBiFuelKit":inputs.externalKit=='externalKityes'? true:false,

              // isBiFuelKit: true,
              isBiFuelKit: ['petrol+cng','petrol+lpg','cng','inbuilt'].includes(initialPolicyType.fuelType) ?  (!["" || null || undefined].includes(finalAddons)&& finalAddons.externalBiFuelkit)
              ? false
              :true  : (!["" || null || undefined].includes(finalAddons)&& finalAddons.externalBiFuelkit)
              ? true
              :false,
              // isBiFuelKit:
              //   finalAddons == null || undefined
              //     ? false
              //     :   finalAddons.externalBiFuelkit
              //     ? false
              //     : false,
              bifuelKit: {
                bifuelKit: {
                  sumInsured:
                    (finalAddons == null || undefined) &&
                    (finalAddonsInput == null || undefined)
                      ? 0
                      : finalAddonsInput && finalAddonsInput.checkInput3, // from addons bifuel input

                  // islpgCng: true,
                  islpgCng:
                  ['petrol+cng','petrol+lpg','inbuilt'].includes(initialPolicyType.fuelType) ? (!["" || null || undefined].includes(finalAddons)&& finalAddons.externalBiFuelkit)
                  ? false
                  :true :(!["" || null || undefined].includes(finalAddons)&& finalAddons.externalBiFuelkit)
                  ? false
                  :true,
                  policyCoverDetailsID: {},
                  // isChecked: true,
                  isChecked:true,
                  fueltype:
                    initialPolicyType.fuelType == ("petrol+cng" || "cng")
                      ? "CNG"
                      : "LPG",
                  isMandatory: false
                }
              },

              // "biFuelKit": initialPolicyType.fuelType == ('petrol+cng'||'petrol+lpg')?true:false,//
              // "newVehicle":  false, // this need to be cleared
              // "basicODCoverage":oldPolicyType=="thirdParty"?false: true,
              // "biFuelIsChecked": initialPolicyType.fuelType == ('petrol+cng'||'petrol+lpg')?true:false,//
              // "biFuelIsMandatory": false,//
              // "biFuelIsLpgCng": initialPolicyType.fuelType == ('petrol+cng'||'petrol+lpg')?true:false, //
              // "biFuelSumInsured": 100.0,
              // "biFuelFuelType": initialPolicyType.fuelType == 'petrol+cng'?"CNG":"LPG",//

              //   "electricItemFitted":false, //
              //   "isPAToOwnerDriverCoverd":false,
              //    "nonElectricalItemFitted":false,
              //  "basicLiability":false

              electricItems: "",
              nonElectricItems: "",

              electricalItemFitted:
                finalAddons == null || undefined
                  ? false
                  : finalAddons.electricalaccessories
                  ? true
                  : false, // based on addon api ele,
              electricalItemsTotalSI:
                finalAddons == null || undefined
                  ? 0
                  : finalAddonsInput && finalAddonsInput.checkInput1, // adddon ele input value

              nonElectricalItemFitted:
                finalAddons == null || undefined
                  ? false
                  : finalAddons.nonElectricalaccessories
                  ? true
                  : false, //based on addons api nonel false ;true,
              nonElectricalItemsTotalSI:
                finalAddons == null || undefined
                  ? 0
                  : finalAddonsInput && finalAddonsInput.checkInput2 // addons none ele inut value
            },

            previousInsuranceDetails: {
              // "prevYearInsurer":'2',
              prevYearInsurer: previousInsurerId.relianceId,
              isPreviousPolicyDetailsAvailable: "true",
              prevYearInsurerAddress: "",
              prevYearPolicyType: "",
              prevYearPolicyNo: inputs.prevPolicyNo,
              // prevYearPolicyStartDate:'01/03/2022',

              prevYearPolicyStartDate:
              `${newDay}/${newMonth}/${newRemYear}`,
              prevYearPolicyEndDate:
              `${newRemDay}/${newRemMonth}/${newYear}`
            },

            ncbEligibility: {
              currentNcb:
              selectedNCB&&
              ((registeredModelValues &&
                registeredModelValues.prevNCB == (null || undefined)) ||
              prevClaimValue == "Yes"
                ? 0
                : selectedNCB),
              ncbeligibilityCriteria: prevClaimValue == "Yes" ? "1" : "2",
              previousNcb:
                (registeredModelValues &&
                  registeredModelValues.prevNCB == (null || undefined)) ||
                prevClaimValue == "Yes"
                  ? 0
                  : registeredModelValues && registeredModelValues.prevNCB
            },
            //  "productcode": "2311",
            userId: "100002",
            sourceSystemId: "100002",
            authToken: "Pass@123",
            existingRGICustomer: false
          },

          {
            headers: {
              authorization: jwtToken,
              "Content-Type": "application/json",
              accept: "application/json",
              "x-rsa-type": 1
            }
          }
        )
        .then((res) => {
          // setApiData(res);
          setProposalApiData(res.data.MotorPolicy);
          seTisDataLoaded(true);
          localStorage.setItem(
            "payment_Data",
            JSON.stringify(res.data.MotorPolicy)
          );
          localStorage.setItem("proposal_loaded", true);
          dispatch(carProposalAction.setApiFailed(false));

        })

        .catch((error) => {
          dispatch(carProposalAction.setApiFailed(true));
          console.log(error)});

    /* ---------------- Proposal For Godigit ------------------ */

    tileType == "GoDigit" &&
      axios
        .post(
          `${API_ENDPOINT}/proposal/godigit`,
          //
          {

       

            enquiryId: "GODIGIT_CQ_PVT_CAR_PACKAGE_01",
            contract: {
              insuranceProductCode:
                isCarRegistered == "true" || isCarRegistered == true
                  ? (productCodeValue
                      ? popUpsFilterData == "comprehensive" && 20101
                      : initialPolicyType.policy == "comprehensive" && 20101) ||
                    (productCodeValue
                      ? popUpsFilterData == "thirdParty" && 20102
                      : initialPolicyType.policy == "thirdParty" && 20102) ||
                    (productCodeValue
                      ? popUpsFilterData == "ownDamage" && 20103
                      : initialPolicyType.policy == "ownDamage" && 20103)
                  : (productCodeValue
                      ? popUpsFilterData == "bundled" && 20101
                      : initialPolicyType.policy == "bundled" && 20101) ||
                    (productCodeValue
                      ? popUpsFilterData == "thirdParty" && 20102
                      : initialPolicyType.policy == "thirdParty" && 20102), // product code
              subInsuranceProductCode:
                isCarRegistered == "true" || isCarRegistered == true
                  ? "PB"
                  :((popUpsFilterData==undefined||null)?initialPolicyType.policy=="bundled":popUpsFilterData=="bundled")
                  ? 31
                  : 30, // pb

                  startDate:  formattedStartDate.split("-").reverse().join('-'), // cover from
                  endDate: (    !(isCarRegistered == "true" || isCarRegistered == true)&&(popUpsFilterData==(null||undefined)?initialPolicyType.policy == "thirdParty":popUpsFilterData=='thirdParty' ))?tppolicyEndDatenw.toLocaleDateString("en-GB")
                  .replace(/\//g, "-")
                  .split("-")
                  .reverse()
                  .join("-"): policyEndDatenw.toLocaleDateString("en-GB")
                  .replace(/\//g, "-")
                  .split("-")
                  .reverse()
                  .join("-"), 
              // "startDate":null,
              // "endDate":null,

              policyHolderType: "INDIVIDUAL",
              externalPolicyNumber: "PARTNER_POL_NO",
              isNCBTransfer: null,
              coverages: {
                voluntaryDeductible:
                  inputDiscount == null || undefined
                    ? "ZERO"
                    : inputDiscount == "None"
                    ? "ZERO"
                    :(( (popUpsFilterData==undefined||null))?initialPolicyType.policy=="thirdParty":popUpsFilterData=="thirdParty")
                    ? "ZERO"
                    : (inputDiscount == "2500" && "TWENTYFIVE_HUNDRED") ||
                      (inputDiscount == "5000" && "FIVE_THOUSAND") ||
                      (inputDiscount == "7500" && "SEVENTYFIVE_HUNDRED") ||
                      (inputDiscount == "15000" && "FIFTEEN_THOUSAND"), // disc addon
                thirdPartyLiability: {
                  isTPPD: true //
                },
                ownDamage: {
                  discount: {
                    userSpecialDiscountPercent: 0,
                    discounts: []
                  },
                  surcharge: {
                    loadings: []
                  }
                },
                personalAccident: {
                  selection:
                    finalAddons == ("" || null || undefined)
                      ? false
                      : finalAddons.ownerDriverPAcover
                      ? true
                      : false, // pa owner driver true
                  coverTerm: 0,
                  coverAvailability: "AVAILABLE",
                  netPremium: "INR 0.00"
                },
                accessories: {
                  cng: {
                    selection:
                      finalAddons == null || undefined
                        ? false
                        : finalAddons.externalBiFuelkit
                        ? true
                        : false, // bifuel true
                    insuredAmount:
                      (finalAddons == null || undefined) &&
                      (finalAddonsInput == null || undefined)
                        ? 0
                        : finalAddonsInput && finalAddonsInput.checkInput3?finalAddonsInput.checkInput3:'0' // bifuel input
                  },
                  electrical: {
                    selection:
                      finalAddons == null || undefined
                        ? false
                        : finalAddons.electricalaccessories
                        ? true
                        : false, // electric
                    insuredAmount:
                      finalAddons == null || undefined
                        ? 0
                        : finalAddonsInput && finalAddonsInput.checkInput1?finalAddonsInput.checkInput1:'0' // electric input
                  },
                  nonElectrical: {
                    selection:
                      finalAddons == null || undefined
                        ? false
                        : finalAddons.nonElectricalaccessories
                        ? true
                        : false, // non electric
                    insuredAmount:
                      finalAddons == null || undefined
                        ? 0
                        : finalAddonsInput && finalAddonsInput.checkInput2?finalAddonsInput.checkInput2:'0' // non electric Input
                  }
                },
                addons: {
                  partsDepreciation: {
                    claimsCovered: 'TWO',
                    selection:
                      finalAddons == null || undefined
                        ? false
                        : finalAddons.zeroDepreciation
                        ? true
                        : false // zero dep true
                  },
                  roadSideAssistance: {
                    selection:
                      finalAddons == null || undefined
                        ? false
                        : finalAddons.roadSideAssistance
                        ? true
                        : false // road
                  },
                  engineProtection: {
                    selection:
                      finalAddons == null || undefined
                        ? false
                        : finalAddons.engineProtect
                        ? true
                        : false /// engine
                  },
                  tyreProtection: {
                    selection:
                      finalAddons == null || undefined
                        ? false
                        : finalAddons.tyreProtection
                        ? true
                        : false // tyre
                  },
                  rimProtection: {
                    selection: null
                  },
                  returnToInvoice: {
                    selection:
                      finalAddons == null || undefined
                        ? false
                        : finalAddons.returntoInvoice
                        ? true
                        : false // return
                  },
                  consumables: {
                    selection:
                      finalAddons == null || undefined
                        ? false
                        : finalAddons.consumables
                        ? true
                        : false // consu
                  }
                },
                legalLiability: {
                  paidDriverLL: {
                    selection: null,
                    insuredCount: null
                  },
                  employeesLL: {
                    selection: null,
                    insuredCount: null
                  },
                  unnamedPaxLL: {
                    selection: null,
                    insuredCount: null
                  },
                  cleanersLL: {
                    selection: null,
                    insuredCount: null
                  },
                  nonFarePaxLL: {
                    selection: null,
                    insuredCount: null
                  },
                  workersCompensationLL: {
                    selection: null,
                    insuredCount: null
                  }
                },
                unnamedPA: {
                  unnamedPax: {
                    selection:
                      finalAddons == null || undefined
                        ? false
                        : finalAddons.unnamedpassengerPAcover
                        ? true
                        : false, // unnamed passweneger cover
                    insuredAmount:null,
                    insuredCount: null
                  },
                  unnamedPaidDriver: {
                    selection:
                      finalAddons == null || undefined
                        ? false
                        : finalAddons.paidDrivercover
                        ? true
                        : false, // paid driver
                    insuredAmount: null,
                    insuredCount: null
                  },
                  unnamedHirer: {
                    selection: null,
                    insuredAmount: null,
                    insuredCount: null
                  },
                  unnamedPillionRider: {
                    selection: null,
                    insuredAmount: null,
                    insuredCount: null
                  },
                  unnamedCleaner: {
                    selection: null,
                    insuredAmount: null,
                    insuredCount: null
                  },
                  unnamedConductor: {
                    selection: null,
                    insuredAmount: null,
                    insuredCount: null
                  }
                }
              }
            },
            vehicle: {

              // "isVehicleNew":   isCarRegistered == "true" || isCarRegistered == true
              //                     ? false
              //                     : true,
              //     "vehicleMaincode": masterID.goDigitVehicleCode[0],
                  // "licensePlateNumber":   isCarRegistered == "true" || isCarRegistered == true
                  //                 ? vehicleNumber.replaceAll("-", "").toUpperCase()
                  //                 : `${modelRegionId.reliance.regionCode.replace("-", "")}NEW`,
                  // "vehicleIdentificationNumber": null,
                  // "engineNumber": "hkhdufhfu2736274",
                  // "manufactureDate": "2021-06-26",
                  // "registrationDate": "2021-06-26",
                  // "vehicleIDV": {
                  //     "idv": 0,
                  //     "defaultIdv": null,
                  //     "minimumIdv": 399768,
                  //     "maximumIdv": 599651
                  // },
               
              isVehicleNew:
                isCarRegistered == "true" || isCarRegistered == true
                  ? false
                  : true, ///  new vehicle false :::: Error if i make True
    //  "vehicleMaincode": "1111810803", // MASTER ID goDigitVehicleCode
           
                  vehicleMaincode: masterID.goDigitVehicleCode[0], // master main code
              licensePlateNumber:
                isCarRegistered == "true" || isCarRegistered == true
                  ? vehicleNumber.replaceAll("-", "").toUpperCase()
                  :`${modelRegionId.reliance.regionCode.replace("-", "")}` ,
                   // num plate
                  // `${modelRegionId.reliance.regionCode.replace("-", "")}NEW`
              vehicleIdentificationNumber:
                isCarRegistered == "true" || isCarRegistered == true
                  ? null
                  : "ABC12345678911111",
              engineNumber: inputs.engineNo, // engine
              "manufactureDate": inputs.carRegistrationDate , // registration date
              // // manufactureDate: null, // registration date
              registrationDate: inputs.carRegistrationDate, // registration date
              "vehicleIDV": {
   
              

              "idv": +inputIdvValue,
              "defaultIdv": null,
              "minimumIdv": godigitApiData&&godigitApiData.vehicle.vehicleIDV.minimumIdv,
              "maximumIdv":  godigitApiData&&godigitApiData.vehicle.vehicleIDV.maximumIdv,
              },
   // if added idv filter
              usageType: null,
              permitType: null,
              motorType: null
            },
            "previousInsurer": {
              // "isPreviousInsurerKnown": true,
              // "previousInsurerCode": 159,
              // "previousPolicyNumber": 8686948888564861,
              // "previousPolicyExpiryDate": "2023-07-10",
              // "isClaimInLastYear": true,
              // "originalPreviousPolicyType": 20101,
              // "previousPolicyType": 20101,
              // "previousNoClaimBonus": 0,
              // "currentThirdPartyPolicy": null,
      

              "isPreviousInsurerKnown":   isCarRegistered == "true" || isCarRegistered == true
              ? true
              : false,
              "previousInsurerCode": previousInsurerId.goDigitCode,
              "previousPolicyNumber": inputs.prevPolicyNo,
              "previousPolicyExpiryDate":isCarRegistered == "true" || isCarRegistered == true
              ? userPrevPolicyExp
              : "",
              "isClaimInLastYear": prevClaimValue == "Yes" ? true : false,
              "originalPreviousPolicyType": isCarRegistered == "true" || isCarRegistered == true
              ? (productCodeValue
                  ? popUpsFilterData == "comprehensive" && '1OD_3TP'
                  : initialPolicyType.policy == "comprehensive" && '1OD_3TP') ||
                (productCodeValue
                  ? popUpsFilterData == "thirdParty" && "1OD_3TP"
                  : initialPolicyType.policy == "thirdParty" && "1OD_3TP") ||
                (productCodeValue
                  ? popUpsFilterData == "ownDamage" && "1OD_3TP"
                  : initialPolicyType.policy == "ownDamage" && "1OD_3TP")
              : (productCodeValue
                  ? popUpsFilterData == "bundled" && "1OD_3TP"
                  : initialPolicyType.policy == "bundled" && "1OD_3TP") ||
                (productCodeValue
                  ? popUpsFilterData == "thirdParty" && "1OD_3TP"
                  : initialPolicyType.policy == "thirdParty" && "1OD_3TP"),
                  
              "previousPolicyType": isCarRegistered == "true" || isCarRegistered == true
              ? (productCodeValue
                  ? popUpsFilterData == "comprehensive" && "1OD_1TP"
                  : initialPolicyType.policy == "comprehensive" && "1OD_1TP") ||
                (productCodeValue
                  ? popUpsFilterData == "thirdParty" && "0OD_1TP"
                  : initialPolicyType.policy == "thirdParty" && "0OD_1TP") ||
                (productCodeValue
                  ? popUpsFilterData == "ownDamage" && "1OD_0TP"
                  : initialPolicyType.policy == "ownDamage" && "1OD_0TP")
              : (productCodeValue
                  ? popUpsFilterData == "bundled" && "1OD_1TP"
                  : initialPolicyType.policy == "bundled" && "1OD_1TP") ||
                (productCodeValue
                  ? popUpsFilterData == "thirdParty" && "0OD_1TP"
                  : initialPolicyType.policy == "thirdParty" && "0OD_1TP"),
                  "previousNoClaimBonus":  isCarRegistered == "true" || isCarRegistered == true?  ((registeredModelValues&&registeredModelValues.prevNCB==(null || undefined))||prevClaimValue=='Yes')?0:`${registeredModelValues&&+registeredModelValues.prevNCB}`:'0',
                  "currentThirdPartyPolicy": {
                    "currentThirdPartyPolicyInsurerCode": null,
                    "currentThirdPartyPolicyNumber": inputs.tpPolicyNumber,
                    "currentThirdPartyPolicyStartDateTime": inputs.carRegistrationDate,
                    "currentThirdPartyPolicyExpiryDateTime": inputs.existingTPExpDate
                }
            },
            // previousInsurer: {
            //   isPreviousInsurerKnown: true,
            //   previousInsurerCode: previousInsurerId.goDigitCode, // prev comp code
            //   previousPolicyNumber: inputs.prevPolicyNo, // prev policy num
            //   previousPolicyExpiryDate:
            //     prevYear + "-" + prevMonth + "-" + prevActDay, // prev expiry date
            //   isClaimInLastYear: prevClaimValue == "Yes" ? false : true, // model popup
            //   originalPreviousPolicyType: null,
            //   previousPolicyType: null,
            //   previousNoClaimBonus:
            //   ((registeredModelValues&&registeredModelValues.prevNCB==(null || undefined))||prevClaimValue=='Yes')?0:`${registeredModelValues&&+registeredModelValues.prevNCB}` , /// pop up ncb
            //   currentThirdPartyPolicy: null
            // },
            persons: [
              {
                personType: "INDIVIDUAL",
                addresses: [
                  {
                    addressType: "PRIMARY_RESIDENCE",
                    flatNumber: null,
                    streetNumber: null,
                    street: "",
                    district: "",
                    state: "27",
                    city: "Pune",
                    country: "IN",
                    pincode: "411001"
                  }
                ],

                communications: [
                  {
                    communicationType: "MOBILE",
                    "communicationId": step1Values.mobile, // mobile num
                    // communicationId: "9381624403", // mobile num
                    isPrefferedCommunication: true
                  },
                  {
                    communicationType: "EMAIL",
                    communicationId: step1Values.email, // email
                    isPrefferedCommunication: true
                  }
                ],
                identificationDocuments: [
                  {
                    identificationDocumentId: "",
                    documentType: "AADHAR_CARD",
                    documentId: "",
                    issuingAuthority: "",
                    issuingPlace: "",
                    issueDate: "2019-11-22",
                    expiryDate: "2019-11-22"
                  }
                ],
                isPolicyHolder: true,
                isVehicleOwner: true,
                firstName: step1Values.firstName, // first name
                middleName: null,
                lastName: step1Values.lastName, // last name
                dateOfBirth: godigitKycDetails.dob, // dob
                // "dateOfBirth": dateOfBirth, // dob **
                gender: step2Values.gender.toUpperCase(), // gender
                isDriver: true,
                isInsuredPerson: true
              }
            ],
            nominee: step2Values.nomineeName? {
              firstName: step2Values.nomineeName, // nominee name
              middleName: "",
              lastName: "",
              dateOfBirth: step2Values.nomineedateOfBirth, // dob
              relation: step2Values.relation, // relation
              personType: "INDIVIDUAL"
            }:null,
            pospInfo: {
              isPOSP: false,
              pospName: "",
              pospUniqueNumber: "",
              pospLocation: "",
              pospPanNumber: "",
              pospAadhaarNumber: "",
              pospContactNumber: ""
            },
            dealer: {
              dealerName: "",
              city: "",
              deliveryDate: null
            },
            motorQuestions: {
              furtherAgreement: "",
              selfInspection: false,
              financer: ""
            },
            kyc: {
              isKYCDone: godigitKycDetails.registered == "yes" ? true : false,

              ckycReferenceDocId:
                godigitKycDetails.registered == "yes" ? "D02" : "D07",

              ckycReferenceNumber:"JQZPK5302K",

              dateOfBirth: godigitKycDetails.dob,

              photo: ""
            }
          },

          {
            headers: {
              authorization: jwtToken,
              "Content-Type": "application/json",
              accept: "application/json",
              "x-rsa-type": 1
            }
          }
        )
        .then((res) => {
          // setApiData(res);
          dispatch(carProposalAction.setApiFailed(false));

          setProposalApiData(res.data);
          seTisDataLoaded(true);

          localStorage.setItem("payment_Data", JSON.stringify(res.data));
          localStorage.setItem("proposal_loaded", true);
        })

        .catch((error) => {

          dispatch(carProposalAction.setApiFailed(true));
          localStorage.setItem("proposal_loaded", true);

          console.log(error)});
          // dispatch(carProposalAction.setApiFailed(true));


    /* ---------------- Proposal For RSA ------------------ */

    proceed&& tileType == "RSA"  &&
    axios
      .post(
        `${API_ENDPOINT}/proposal/rsa/4w?isOTPVerified=yes`,
        //
      
        {
          "isNewUser": "No",
          "isproductcheck": "No",
          "istranscheck": "No",
          "isFinalCalculateCall": "No",
          "isPosOpted": "",
          "posCode": "",
          "quoteId": Object.keys(rsaApiData).length>0&& rsaApiData.PREMIUMDETAILS.DATA.QUOTE_ID, // quote Response RSA Call QUoteID  
          "premium":  Object.keys(rsaApiData).length>0&& rsaApiData.PREMIUMDETAILS.DATA.GROSS_PREMIUM,
          "reqType": "JSON",
          "respType": "JSON",
          "source": "",
          "posDetails": {
              "aadhaar": "",
              "licenceExpiryDate": "",
              "mobile": "",
              "name": "",
              "pan": ""
          },
          "proposerDetails": {
              "strTitle": step1Values.salutation, // salutation 
              "strFirstName": step1Values.firstName, // first Name step1
              "strLastName":step1Values.lastName, // last Name
              "strEmail": step1Values.email, // email 
              "strMobileNo": step1Values.mobile, // mobile No
              "dateOfBirth": dateOfBirth&&dateOfBirth.split('/').reverse().join('/'), // Dob
              "occupation": "",
              "nomineeName":step2Values.nomineeName, // nominee Name 
              "nomineeAge": step2Values.age, // nominee Age
              "relationshipWithNominee": step2Values.relation, // Relation Ship with Nominee TBC
              "guardianName":step2Values.appointeeName
              ,//TBC
              "guardianAge": "22", // TBC
              "relationshipwithGuardian": "Son", // TBC 
              "contactAddress1":step3Values.address, // ADDRESS 1 
              "contactAddress2": step3Values.address2, // ADDRESS 2 
              "contactAddress3": "",  
              "contactAddress4": "",
              "contactCity":rsaKycApiData&&rsaKycApiData.city, /// city
              "contactPincode": step3Values.pincode, /// pincode
              "same_addr_reg": "Yes", 
              "addressOne": "",
              "addressTwo": "",
              "addressThree": "",
              "regCity": pincodeDetails.cityOrVillageName, // city
              "regPinCode": step3Values.pincode, // pincode
              "aadharNumber": "",
              "panNumber": rsaKycApiData&&rsaKycApiData.panNumber,  //pan card Number
              "cKycNumber": rsaKycApiData&&rsaKycApiData.ckycNo,  // ckyc NO
              "validatePan": "Yes",
        "uploadForm": "",
        "form60": "No",
        "form49a": "No",
        "fileFormat": "string",
        "fileName": "string",
              "kycDone": "true",//// kyc verified
              "eKycRefNumber": "",
              "GSTIN": "",
              "clientCode": "",
              "eiaNumber": "",
              "irName": "",
              "isLoginCheck": "",
              "isNewUser": "",
              "strPhoneNo": "",
              "strStdCode": "",
              "updatePanAaadharLater": "",
              "userName": ""
          },
          "vehicleDetails": {
              "yearOfManufacture": manufactureYear, // manufacture year
              "carRegisteredCity": modelRegionId.rsa.cityName, // city from RTO location
              "hdnRoadSideAssistanceCover": finalAddons == null || undefined
              ? false
              : finalAddons.roadSideAssistance
              ? true
              : false, // road Side assistnac
              "roadSideAssistancePlan1": finalAddons == null || undefined
              ? "No"
              : finalAddons.roadSideAssistance
              ? "Yes"
              : "No", // road side yes or no
        "roadSideAssistanceCover": finalAddons == null || undefined
        ? 'Off'
        : finalAddons.roadSideAssistance
        ? "On"
        : "Off",
        "roadSideAssistancePlan2": "no",
              "vehicleregDate": inputs.carRegistrationDate.split('-').reverse().join('/'), // registration Date
              "previousPolicyExpiryDate":  isCarRegistered == "true" ? prevActDay+ "/" + prevMonth + "/" +prevYear :'', // prev policy date
              "vehicleManufacturerName": "",
              "vehicleModelCode": masterID.rsaModelCode[0], 
              "rtoName":modelRegionId.rsa.rtoName, 
              "drivingExperience": "",
              "original_idv":  Object.keys(rsaApiData).length>0&& rsaApiData.PREMIUMDETAILS.DATA.IDV,  // idv same as quote 2nd logic
              "original_idv_2year": "0",
              "original_idv_3year": "0",
              "vehicleRegisteredInTheNameOf": "Individual",
              "companyNameForCar": "",
              // "isBiFuelKit":'No',//TBC
              //   "isBiFuelKitYes":'Inbuilt', // TBC
              "isBiFuelKit":  ['petrol+cng', 'petrol+lpg', 'cng', 'InBuilt'].indexOf(initialPolicyType.fuelType) !== -1   
              ? 'Yes' :(!["" || null || undefined].includes(finalAddons)&& finalAddons.externalBiFuelkit)
                ? 'Yes'
                :'No',
                "isBiFuelKitYes":['petrol+cng', 'petrol+lpg', 'cng', 'InBuilt'].indexOf(initialPolicyType.fuelType) !== -1 
                ? 'Inbuilt' : (!["" || null || undefined].includes(finalAddons)&& finalAddons.externalBiFuelkit)
                ? 'Add On'
                :'Inbuilt',
                "typeOfBiFuelKit":['petrol+cng', 'petrol+lpg', 'cng', 'InBuilt'].indexOf(initialPolicyType.fuelType) !== -1 
                ? 'Inbuilt' : (!["" || null || undefined].includes(finalAddons)&& finalAddons.externalBiFuelkit)
                ? 'Add On'
                :'Inbuilt',
              "addonValue": (finalAddonsInput==null||undefined)?0: finalAddonsInput.checkInput3,
              "vehicleMostlyDrivenOn": "fnejkwnfjen",
              "automobileAssociationMembership": "No",
              "isCarOwnershipChanged":tileType == "RSA"&& ownership=='Yes'?'Yes': "No",
              "isPreviousPolicyHolder": "true",
              "previuosPolicyNumber": inputs.prevPolicyNo, // prev policy number
              "previousInsurerName": inputs.prevPolicyInsurer, // policy prev insurer Name
              "previousinsurersCorrectAddress": "",
              "claimsReported": "0",
              "claimAmountReceived": "0",
              "hdnKeyReplacement": finalAddons == null || undefined
                ? false
                : finalAddons.keyLoss
                ? true
                :  false, //keyLoss True : false
                "keyreplacement": finalAddons == null || undefined
                ?  "Off"
                : finalAddons.keyLoss
                ? "On"
                :  "Off", 
                "hdnDepreciation": finalAddons == null || undefined
                ? false
                : finalAddons.zeroDepreciation
                ? prevAddons=='true'? true :false
                :  false, // zero Depricia True : false
                "depreciationWaiver": finalAddons == null || undefined
                ?  "Off"
                : finalAddons.zeroDepreciation
                ? prevAddons=='true'?"On":'Off'
                :  "Off", 
               
              "hdnWindShield": false,
              "hdnProtector": finalAddons == null || undefined
                ?  false
                : finalAddons.engineProtect
                ? true
                :  false, /// engine Protoctor true:false
                "engineprotector": finalAddons == null || undefined
                ?  "Off"
                : finalAddons.engineProtect
                ? "On"
                :  "Off", 
                "hdnNCBProtector": finalAddons == null || undefined
                ?  false
                : finalAddons.ncbProtection
                ? true
                :  false, // ncb protoctors true:false
                "ncbprotector": finalAddons == null || undefined
                ?  "Off"
                : finalAddons.ncbProtection
                ? "On"
                :  "Off",
            
              "hdnRoadTax": false,
              "hdnSpareCar": false,
              "spareCar": "off",
              "hdnInvoicePrice":  false, // returned invoice true:false
              "invoicePrice":"Off",
              "hdnLossOfBaggage": finalAddons == null || undefined
              ?  false
              : finalAddons.lossOfPersonalBelongings
              ? true
              :  false, // loss of baggage true : false
              "lossOfBaggage": finalAddons == null || undefined
              ?  "Off"
              : finalAddons.lossOfPersonalBelongings
              ? "On"
              :  "Off",
              
              "legalliabilitytopaiddriver": "No",
              "legalliabilitytoemployees": "No",
              "fibreglass": "no",
              "engineNumber": inputs.engineNo, // engine NUmb
              "chassisNumber": inputs.chasisNo, // chasis Num
              "financierName": inputs.financierName, // financier name loan yes 
              "isCarFinanced":  inputs.loan == "yes" ? 'Yes' :"No", // loan true 
              "isCarFinancedValue": "Hypothecation",
              "towingChargesCover": "No",
              "hdnTyreCover":  finalAddons == null || undefined
                ? false
                : finalAddons.tyreProtection
                ? true
                : false , // tyre protoctor true :false
                "tyreCover":  finalAddons == null || undefined
                ?  "Off"
                : finalAddons.tyreProtection
                ? "On"
                :  "Off" ,
              
              "cpaPolicyTerm": isCarRegistered == "true" ?"1":policyTerm, 
              "cpaCoverDetails": {
                "companyName": "test",
                "cpaCoverWithInternalAgent":!finalAddons.ownerDriverPAcover&& reasonCpaVal=="havingInsured"?true:false,
                "expiryDate": "",
                "noEffectiveDrivingLicense": !finalAddons.ownerDriverPAcover&&reasonCpaVal=="havingLicense"?true:false,
                "policyNumber": "",
                "standalonePAPolicy":!finalAddons.ownerDriverPAcover&& reasonCpaVal=="havingPolicy"?true:false
              },
              "cpaSumInsured": 1500000,
              "cpaCoverisRequired": finalAddons == null || undefined
              ?  "Yes"
              : finalAddons.ownerDriverPAcover
              ? "Yes"
              :  "No",//pa owner driver cover
              "ProductName": ['true',true].includes(isCarRegistered)  ?"RolloverCar":"BrandNewCar", /// prdct name
              "typeOfCover":['true',true].includes(isCarRegistered) ? (productCodeValue
                ? popUpsFilterData == "comprehensive" && "Comprehensive"
                : initialPolicyType.policy == "comprehensive" && "Comprehensive") ||
              ((productCodeValue
                ? popUpsFilterData == "thirdParty" && "LiabilityOnly"
                : initialPolicyType.policy == "thirdParty") &&
                "LiabilityOnly") ||
              (productCodeValue
                ? popUpsFilterData == "ownDamage" && "standalone"
                : initialPolicyType.policy == "ownDamage" && "standalone")
            : (productCodeValue
                ? popUpsFilterData == "bundled" && "Bundled"
                : initialPolicyType.policy == "bundled" && "Bundled") ||
              (productCodeValue
                ? popUpsFilterData == "thirdParty" && "LiabilityOnly"
                : initialPolicyType.policy == "thirdParty" && "LiabilityOnly"), // type of cover
              "accidentcoverforpaiddriver": finalAddons == ("" || null || undefined)
              ? '0'
              : finalAddons.paidDrivercover
              ? '200000'
              : '0',
              "isValidDrivingLicenseAvailable": "Yes",
              "averageMonthlyMileageRun": "",
              "claimsMadeInPreviousPolicy":prevClaimValue == "Yes" ? "Yes" : "No",  // claims yes or no
              "cover_dri_othr_car_ass": "No",
              "cover_elec_acc":   finalAddons == null || undefined
              ? "No"
              : finalAddons.electricalaccessories
              ? "Yes"
              : "No", // elect yes No
              "electricalAccessories": {
                  "electronicAccessoriesDetails": [
                      {
                          "makeModel": "",
                          "nameOfElectronicAccessories": "",
                          "value":  finalAddons == null || undefined
                          ? 0
                          : finalAddonsInput && finalAddonsInput.checkInput1
                      }
                  ]
              },
              "valueofelectricalaccessories":  finalAddons == null || undefined
              ? 0
              : finalAddonsInput && finalAddonsInput.checkInput1,
              "engineCapacityAmount": "",
              "fuelType": "", // fuel type
              "towingChargesCover_SI": "1000",
              "hdtDiscount": 10,
              "noClaimBonusPercent": ((registeredModelValues&&registeredModelValues.prevNCB==(null || undefined))||prevClaimValue=='Yes')?0:`${registeredModelValues&&+registeredModelValues.prevNCB}`, // ncb +1
              "cover_non_elec_acc": finalAddons == null || undefined
              ? "No"
              : finalAddons.nonElectricalaccessories
              ? "Yes"
              : "No", // non elect
              "nonElectricalAccesories": {
                  "nonelectronicAccessoriesDetails": [
                      {
                          "makeModel": "",
                          "nameOfElectronicAccessories": "",
                          "value": finalAddons == null || undefined
                          ? 0
                          : finalAddonsInput && finalAddonsInput.checkInput2
                      }
                  ]
              },
              "valueofnonelectricalaccessories": finalAddons == null || undefined
              ? 0
              : finalAddonsInput && finalAddonsInput.checkInput2, 
              "personalaccidentcoverforunnamedpassengers":finalAddons == ("" || null || undefined)
              ? '0'
              : finalAddons.unnamedpassengerPAcover
              ? "200000"
              : "0" , // unnamed addon true 2lak 
              "policySD": formattedStartDate.split("-").join('/'), // same as reliance cover from 
              "policyED":  (    !(isCarRegistered == "true" || isCarRegistered == true)&&(popUpsFilterData==(null||undefined)?initialPolicyType.policy == "thirdParty":popUpsFilterData=='thirdParty' ))?tppolicyEndDatenw.toLocaleDateString("en-GB")
              :policyEndDatenw.toLocaleDateString("en-GB")
              , // cover to
             
          
              "policyTerm":isCarRegistered == "true" ?"1":policyTerm ,
              "region": "",
              "spareCarLimit": "",
              "valueOfLossOfBaggage": "15000",
              "vehicleSubLine": "",
              "voluntarydeductible":  inputDiscount == ("" || null || undefined)
              ? "0"
              :(( (popUpsFilterData==undefined||null))?initialPolicyType.policy=="thirdParty":popUpsFilterData=="thirdParty")
              ? "0"
              : inputDiscount == "None"
              ? "0"
              : inputDiscount, // disco
              "modelName": modelName,
              "modified_idv_2year_value": "0",
              "modified_idv_3year_value": "0",
              "modified_idv_value": idvValue == ("" || null || undefined)
              ? "0"
              :  (( (popUpsFilterData==undefined||null))?initialPolicyType.policy=="thirdParty":popUpsFilterData=="thirdParty")
              ? "0"
              : idvValue,// idv value
              "modify_your_idv": "0", 
              "pucnumber": "",
              "pucvalidUpto": "",
              "technicalDiscount": 0,
              "validPUCAvailable":'Yes',
              "VIRNumber": "",
              "registrationNumber":  isCarRegistered == "true" ? inputs.carRegisterNo: `${modelRegionId.rsa.rto}-NEW`, //reg no 
              "registrationchargesRoadtax": "Off",
            
                  
              "previousPolicyType":['true',true].includes(isCarRegistered)  ? registeredModelValues&&registeredModelValues.prevPolicyType  : (productCodeValue
                ? popUpsFilterData == "bundled" && "Bundled"
                : initialPolicyType.policy == "bundled" && "Bundled") ||
              (productCodeValue
                ? popUpsFilterData == "thirdParty" && "LiabilityOnly"
                : initialPolicyType.policy == "thirdParty" && "LiabilityOnly"),  // policy type
              "planOpted": "",
              "ncbcurrent": "0",
              "ncbprevious":  ((registeredModelValues&&registeredModelValues.prevNCB==(null || undefined))||prevClaimValue=='Yes')?0:`${registeredModelValues&&+registeredModelValues.prevNCB}`,
              "addOnsOptedInPreviousPolicy": finalAddons&& ((finalAddons.zeroDepreciation&&"DepreciationWaiver") ||
                
              (finalAddons.engineProtect&&"AggravationCover") ||
              (finalAddons.consumables&&"consumableCover")),
              "consumableCover":finalAddons&&finalAddons.consumables?"On":"Off",
              "tppdLimit":tppdChange=='true'? 6000:null,
              "campaignDiscount": "",
              "isBreakinInsurance": "No",
              "isBundleDiscountApply": "No",
              "isUsedCar": "No",
              "isVehicleInspected": "No",
              "hdnVehicleReplacementCover": finalAddons == null || undefined
              ?  false
              : finalAddons.returntoInvoice
              ? true
              :  false,
        "vehicleReplacementCover": finalAddons == null || undefined
        ?  "No"
        : finalAddons.returntoInvoice
        ? "Yes"
        :  "No" ,
        "fullInvoicePrice": "Yes",
        "fullInvoicePriceRoadtax": "No",
        "fullInvoicePriceRegCharges": "No",
        "fullInvoicePriceInsuranceCost": "No",
          },
          "authenticationDetails": {
              "apikey": "310ZQmv/bYJMYrWQ1iYa7s43084=",
              "agentId": "BA506423"
          },
          "existingTPPolicyDetails": {
              "tpInsurer": inputs.prevTpInsurer, // tp insurer
              "tpPolicyNumber":(
                popUpsFilterData == (undefined || null)
                  ? initialPolicyType.policy == "ownDamage"
                  : popUpsFilterData == "ownDamage"
              )
                ? step4Values.tpPolicyNumber
                : inputs.prevPolicyNo, // tp policy No
              "tpCity":pincodeDetails.cityOrVillageName,
              "tpPincode":  step3Values.pincode,
              "tpInceptionDate": `${tpDay}/${tpMonth}/${tpYear}`,
              "tpExpiryDate": Object.keys(inputs).includes("existingTPExpDate") &&
              inputs.existingTPExpDate.split('-').reverse().join('/'), // tp expiry Date
              "tpPolicyTerm": 2//TBC
          }
   
       
        },
        {
          headers: {
            authorization: jwtToken,
            "Content-Type": "application/json",
            accept: "application/json",
            
          }
        }
      )
      .then((res) => {
        // setApiData(res);
        seTisDataLoaded(true);
        setProposalApiData(res.data);
        localStorage.setItem(
          "payment_Data",
          JSON.stringify(res.data)
        );
        localStorage.setItem("proposal_loaded", true);
        dispatch(carProposalAction.setApiFailed(false));
        dispatch(carProposalAction.setProposalApiData(res.data));

      })
      .catch((error) => {
        dispatch(carProposalAction.setApiFailed(true));
        localStorage.setItem("proposal_loaded", true);

        console.log(error)});
};
  useEffect(() => {
    const inputValues = localStorage.getItem("step-4_Values");
    inputValues && setInputs(JSON.parse(inputValues));
  }, []);

  useEffect(()=>{
 if(tileType=='Reliance'||tileType==='RSA'||tileType=='GoDigit')
    setInputs({...inputs,
      carRegisterNo:isCarRegistered=='true'?normalCarRegNo:'NEW',
      carRegistrationDate:initialPolicyType.registrationDate
    })
  
    
  },[initialPolicyType.registrationDate, isCarRegistered,normalCarRegNo, tileType])
  useEffect(()=>{
    if(inputs){
    let objVal = {
      prevPolicyNo:inputs.prevPolicyNo,
      existingTPExpDate:inputs.existingTPExpDate,
      financiercity:inputs.financiercity,
      chasisNo:inputs.chasisNo,
      engineNo:inputs.engineNo,
      loan:inputs.loan,
      financierAddress:inputs.financierAddress,
      previousInsurerId:  previousInsurerId.relianceId
    }
      updateInputValues(objVal)
      updateInputValuesGodigit(objVal)
      updateInputValuesRsa(objVal)
    }
  },[inputs, previousInsurerId])

  useEffect(() => {
    const onStorage = () => {
      setJwtToken(localStorage.getItem("jwt_token"));
    };

    window.addEventListener("storage", onStorage);

    return () => {
      window.removeEventListener("storage", onStorage);
    };
  }, []);

  // console.log(
  //   "testing ",
  //   popUpsFilterData == (undefined || null)
  //     ? initialPolicyType.policy == "ownDamage"
  //     : popUpsFilterData == "ownDamage",
  //   initialPolicyType.policy == "ownDamage",
  //   popUpsFilterData
  // );

  useEffect(() => {
    // localStorage.setItem("proposal_loaded", false);
  }, []);

  // console.log(
  //   "test1",
  //   Object.values(inputs).length >= Object.keys(inputs).includes('loan')&&inputs.loan == "yes"?12:10
  //    ,
  //   Object.keys(inputs).includes('loan'),inputs.loan == "yes",
  //   proceed,
  //   Object.values(inputs).length >= inputs.loan == "yes" ? 12 : 9
  // );
  console.log('proced',proceed)
  useEffect(() => {
    // eslint-disable-next-line no-mixed-operators
    if (
      isCarRegistered == ("true" || true)
         ? Object.values(inputs).length >= (Object.keys(inputs).includes('loan')&&inputs.loan == "yes"?   ((isCarRegistered == "true" || isCarRegistered == true)&&(popUpsFilterData == (undefined || null)
        ? initialPolicyType.policy == "ownDamage"
        : popUpsFilterData == "ownDamage")? tileType == "RSA"? 15:14 : tileType == "RSA"?12:11) // for od left ;; for oth right 
        ///if Loan yes above value taken 
        //if loan no below value taken
        :  ((isCarRegistered == "true" || isCarRegistered == true)&&(popUpsFilterData == (undefined || null)
        ? initialPolicyType.policy == "ownDamage"
        : popUpsFilterData == "ownDamage"))?tileType == "RSA"?12: 11:tileType == "RSA"?9: 9) &&
            inputs.carRegisterNo.length > 1 &&
            inputs.engineNo.length > 1 &&
            inputs.chasisNo.length > 1 &&
            // (tileType == "RSA"?inputs.vehicleOwnership.length > 1 :true) &&
            inputs.carRegistrationDate.length > 1 &&
            inputs.prevPolicyNo.length > 1 &&
            inputs.puc.length > 1 &&
            inputs.prevPolicyInsurer.length > 1 &&
            ((popUpsFilterData == (undefined || null)
            ? initialPolicyType.policy == "ownDamage"
            : popUpsFilterData == "ownDamage") ?
            ((Object.keys(inputs).includes('tpPolicyNumber') &&inputs.tpPolicyNumber.length > 1) && (Object.keys(inputs).includes('existingTPExpDate')&&inputs.existingTPExpDate.length > 1)&&(Object.keys(inputs).includes('prevTpInsurer') &&inputs.prevTpInsurer.length > 1)):true) &&
            inputs.loan.length > 1&&
            (Object.keys(inputs).includes("financierName")
              ? inputs.financierName.length > 1
              : true) &&
            (Object.keys(inputs).includes("financedValue")
              ? inputs.financierName.length > 1
              : true) &&
            (Object.keys(inputs).includes("financierCity")
              ? inputs.financierCity.length > 1
              : true) &&
            (Object.keys(inputs).includes("financierAddress")
              ? inputs.financierAddress.length > 1
              : true)
        : // inputs.externalKit.length > 1
          Object.values(inputs).length >= ( (Object.keys(inputs).includes('loan')&&inputs.loan == "yes")?tileType == "RSA"?9: 8 :tileType == "RSA"?7: 7) &&
          inputs.carRegisterNo.length > 1 &&
            // (tileType == "RSA"?inputs.vehicleOwnership.length > 1:true) &&
            inputs.engineNo.length > 1 &&
          inputs.chasisNo.length > 1 &&
          inputs.carRegistrationDate.length > 1 &&
          (Object.keys(inputs).includes("financierName")
            ? inputs.financierName.length > 1
            : true) &&
          (Object.keys(inputs).includes("financedValue")
            ? inputs.financierName.length > 1
            : true) &&
          (Object.keys(inputs).includes("financierCity")
            ? inputs.financierCity.length > 1
            : true) &&
          (Object.keys(inputs).includes("financierAddress")
            ? inputs.financierAddress.length > 1
            : true) &&
          inputs.loan.length > 1
      // inputs.externalKit.length > 1
    ) {
      setProceed(true);
      localStorage.setItem("step", 4);
      localStorage.setItem("step-4_Values", JSON.stringify(inputs));
    } else {
      setProceed(false);
    }
    // if (Object.values(inputs).length >= (inputs.loan == "yes" ? 8 : 5)) {
    //   setProceed(true);
    //   localStorage.setItem("step", 4);
    //   localStorage.setItem("step-4_Values", JSON.stringify(inputs));
    // }
  }, [initialPolicyType, inputs, isCarRegistered,tileType, popUpsFilterData]);
  
console.log('last input',inputs)
  useEffect(() => {
    if (/^[a-zA-Z0-9]*$/.test(inputs.carRegisterNo) == false) {
      setCarRegisterNoError(true);
    }
    if (/^[a-zA-Z0-9]*$/.test(inputs.engineNo) == false) {
      setEngineNoError(true);
    }
    if (/^[a-zA-Z0-9]*$/.test(inputs.chasisNo) == false) {
      setChasisNoError(true);
    }
    if (/^[a-zA-Z0-9]*$/.test(inputs.tpPolicyNumber) == false) {
      setTpPolicyNumberError(true);
    }
    if (/^[a-zA-Z0-9- /]*$/.test(inputs.prevPolicyNo) == false) {
      setPrevPolicyNoError(true);
    }
    if (/^[a-zA-Z- /]*$/.test(inputs.financierName) == false) {
      inputs.loan == "yes" && setFinancierNameError(true);
    }
  }, [
    inputs,
    inputs.carRegisterNo,
    inputs.engineNo,
    inputs.tpPolicyNumber,
    inputs.chasisNo,
    inputs.prevPolicyNo,
    inputs.financierName,
    carRegisterNoError,
    chasisNoError,
    prevPolicyNoError,
    financierNameError,
    proceed
  ]);



 // Replace '2023-09-04' with your desired car purchase date
 const carPurchaseDate = initialPolicyType&&initialPolicyType.registrationDate;



 useEffect(() => {


 
/* ------------------------------- For Start Date In Expired Tp Date ------------------------------ */
   if (carPurchaseDate) {
    const carPurchaseDateObj = new Date(carPurchaseDate);
    
    // Subtract 1 month
    carPurchaseDateObj.setMonth(carPurchaseDateObj.getMonth() - 1);

    const formattedTpExpireDate = carPurchaseDateObj.toISOString().split('T')[0];
    setTpStartDate(formattedTpExpireDate);
  }

/* ------------------------------- For End Date In Expired Tp Date ------------------------------ */


  if (carPurchaseDate) {
    const carPurchaseDateObj = new Date(carPurchaseDate);
    // Subtract one day
    carPurchaseDateObj.setDate(carPurchaseDateObj.getDate() - 1);

    // Add 3 years
    carPurchaseDateObj.setFullYear(carPurchaseDateObj.getFullYear() + 3);

    const formattedTpExpireDate = carPurchaseDateObj.toISOString().split('T')[0];
    setTpExpireDate(formattedTpExpireDate);
  }


 }, [carPurchaseDate]);


  return (
    <div>
      <>
        <NavHeader dropClose={[closeNav, setCloseNav]} />
        <main
          className={`lg:w-full overflow-x-hidden   text-sm lg:text-base  lg:pt- bg-no-repeat bg-cover  ${
            isPopWindowOpen ? "h-screen " : ""
          }   `}
          style={{ backgroundImage: `url(${LandingBackground})` }}
          onClick={() => {
            setCloseNav(false);
          }}
        >

<InsuranceHeader popwindow={[isPopWindowOpen, setIsPopWindowOpen] } />
          <div className="pt-5 lg:pt-8 pb-16 lg:pb-0">
            <InsuranceTracker />
          </div>

          {/* <InsuranceHeader popwindow={[isPopWindowOpen, setIsPopWindowOpen]} /> */}
          <div className="pt-5 lg:pt-8 pb-16 lg:pb">
            {/* <InsuranceTracker /> */}
          </div>
          <form
            onChange={handleChange}
            className={`${isPopWindowOpen ? "hidden" : ""}`}
          >
            <div className="slider-in justify-center overflow-x-hidden bg-white mx-5 lg:m-auto drop-shadow-lg lg:w-[55%]    rounded-xl p-4 ">
              <div className="flex items-center space-x-2 pb-4">
                <div className="w-6 h-6 lg:w-8 lg:h-8 bg-hero text-white rounded-full items-center flex justify-center">
                  4
                </div>
                <h1 className="m  text-solid lg:text-xl  font-semibold">
                  Vehicle Details
                </h1>
              </div>
              <hr className="text-[#DBDBDB] pb-4" />
              <div className="">
                <InputPP
                  className="w-full lg:w-96 border uppercase border-[#d7d5d5] rounded-md  px-2 lg:px-3 py-1.5 lg:py-2 my-2"
                  parenetClassName="lg:w-max"
                  labelClassName="text-[#959595] font-medium pl-1 w-20"
                  id="carRegisterNo"
                  disableTrue={tileType=='Reliance'?true:false}

                  label="Car Registration Number"
                  type="text"
                  placeholder="eg. AP01 XXXX"
                  value={tileType=='Reliance'?isCarRegistered=='true'?normalCarRegNo:'NEW': inputs.carRegisterNo}
                  errorState={[carRegisterNoError, setCarRegisterNoError]}
                  // state={[ownerName, setOwnerName]}
                />
                <div className="flex flex-col lg:flex-row lg:space-x-5 py-3">
                  <InputPP
                    className="w-full xl:w-96 border border-[#d7d5d5] rounded-md  px-2 lg:px-3 py-1.5 lg:py-2 my-2"
                    parenetClassName="lg:w-max"
                    labelClassName="text-[#959595] font-medium pl-1 w-20"
                    id="engineNo"
                    label="Engine Number"
                    type="text"
                    placeholder="Enter Engine Number"
                    value={inputs.engineNo}
                    errorState={[engineNoError, setEngineNoError]}
                    // state={[ownerName, setOwnerName]}
                  />
                  <InputPP
                    className="w-full xl:w-96 border border-[#d7d5d5] rounded-md  px-2 lg:px-3 py-1.5 lg:py-2 my-2"
                    parenetClassName="lg:w-max"
                    labelClassName="text-[#959595] font-medium pl-1 w-20"
                    id="chasisNo"
                    label="Chasis Number"
                    type="text"
                    placeholder="Enter Chasis Number "
                    value={inputs.chasisNo}
                    errorState={[chasisNoError, setChasisNoError]}
                    // state={[ownerName, setOwnerName]}
                  />
                </div>
                <InputPP
                  className="w-full date lg:w-96 border border-[#d7d5d5] rounded-md  px-2 lg:px-3 py-1.5 lg:py-2 my-2"
                  parenetClassName="lg:w-max"
                  labelClassName="text-[#959595] font-medium pl-1 w-20"
                  id="carRegistrationDate"
                  label="Car Registration Date"
                  type="date"
                  disableTrue={tileType=='Reliance'?true:false}
                  placeholder=""
                  value={tileType=='Reliance'?initialPolicyType.registrationDate:inputs.carRegistrationDate}
                  errorState={[carRegisterDateError, setCarRegisterDateError]}
                  // state={[ownerName, setOwnerName]}
                />
                <div className=" my-4 ">
                  <DropDownPP
                    className="w-full  lg:w-96 border border-[#d7d5d5] rounded-md  px-2 lg:px-3 py-1.5 lg:py-2.5 my-2"
                    labelClassName="text-[#959595] font-medium pl-1 w-20"
                    parentClassName={`${
                      isCarRegistered == "true" || isCarRegistered == true
                        ? ""
                        : "hidden"
                    }`}
                    label="Previous Policy Insurer "
                    id="prevPolicyInsurer"
                    differentDrop={false}
                    options={prevInsurerData2}
                    value={inputs.prevPolicyInsurer}
                    errorState={[
                      prevPolicyInsurerError,
                      setPrevPolicyInsurerError
                    ]}
                    special={true}
                  />
                </div>
                <InputPP
                  className={`  w-full lg:w-96 border border-[#d7d5d5] rounded-md px-2 lg:px-3 py-1.5 lg:py-2 my-2`}
                  parenetClassName={`${
                    isCarRegistered == "true" || isCarRegistered == true
                      ? ""
                      : "hidden"
                  }`}
                  labelClassName="text-[#959595] font-medium pl-1"
                  id="prevPolicyNo"
                  label="Previous Policy Number"
                  type="text"
                  placeholder="Policy number"
                  value={inputs.prevPolicyNo}
                  errorState={[prevPolicyNoError, setPrevPolicyNoError]}
                  // state={[emailId, setEmailId]}
                />
                <div className="space-y-4 mt-4">
                  <InputPP
                    className="w-full date lg:w-96 border border-[#d7d5d5] rounded-md px-2 lg:px-3 py-1.5 lg:py-2 my-2"
                    labelClassName="text-[#959595] font-medium pl-1"
                    parenetClassName={`${
                      (isCarRegistered == "true" || isCarRegistered == true)&&(popUpsFilterData == (undefined || null)
                      ? initialPolicyType.policy == "ownDamage"
                      : popUpsFilterData == "ownDamage")
                        ? ""
                        : "hidden"
                    }`}
                    id="existingTPExpDate"
                    label="Existing TP Expiry Date"
                    type="date"
                    placeholder=""
                    tDate={tpStartDate}
                    maxDate={tpExpireDate}
                    value={inputs.existingTPExpDate}
                    errorState={[exisTPExpDateError, setExisTPExpDateError]}
                    // state={[emailId, setEmailId]}
                  />

                <InputPP
                  className={`  w-full lg:w-96 border border-[#d7d5d5] rounded-md px-2 lg:px-3 py-1.5 lg:py-2 my-2`}
                  parenetClassName={`${
                    (isCarRegistered == "true" || isCarRegistered == true)&&(popUpsFilterData == (undefined || null)
                    ? initialPolicyType.policy == "ownDamage"
                    : popUpsFilterData == "ownDamage")
                      ? ""
                      : "hidden"
                  }`}
                  labelClassName="text-[#959595] font-medium pl-1"
                  id="tpPolicyNumber"
                  label="Tp Policy Number"
                  type="text"
                  placeholder="Tp Policy Number"
                  value={inputs.tpPolicyNumber}
                  errorState={[tpPolicyNumberError, setTpPolicyNumberError]}
                  // state={[emailId, setEmailId]}
                />
<div className={`${
                      (isCarRegistered == "true" || isCarRegistered == true)&&(popUpsFilterData == (undefined || null)
                      ? initialPolicyType.policy == "ownDamage"
                      : popUpsFilterData == "ownDamage")
                        ? ""
                        : "hidden"
                    } `}>

<DropDownPP
                    className="w-full  lg:w-96 border border-[#d7d5d5] rounded-md px-2 lg:px-3 py-1.5 lg:py-2.5 my-2"
                    labelClassName="text-[#959595] font-medium pl-1 w-20"
                    
                    label="Previous TP insurer"
                    id="prevTpInsurer"
                    differentDrop={false}
                    options={prevInsurerData2}
                    value={inputs.prevTpInsurer}
                    errorState={[
                      prevTpInsurerError,
                      setPrevTpInsurerError
                    ]}
                    special={true}
                  />
</div>   
</div>

<div className="">

<RadioPP
                      title="Do you have a valid PUC? (Its required without this You cant buy RSA Policy)"
                      errorState={[carPucError, setCarPucError]}
                      value={'true'}
                      objValues={   [ 
                      {
                        id: "true",
                        name: "puc",
                        value: "Yes"
                      },
                      {
                        id: "false",
                        name: "puc",
                        value: "No"
                      },
                    ] }
                    />
</div>

 

                <div className="py-4 space-y-5 lg:space-y-3">
                  <RadioPP
                    title="Is your car currently on loan?"
                    value={inputs.loan}
                    errorState={[carLoanError, setCarLoanError]}

                    objValues={[
                      {
                        id: "yes",
                        name: "loan",
                        value: "Yes"
                      },
                      {
                        id: "no",
                        name: "loan",
                        value: "No"
                      }
                    ]}
                  />
                  
                  <InputPP
                    className="w-full lg:w-96 border border-[#d7d5d5] rounded-md px-2 lg:px-3 py-1.5 lg:py-2 my-2"
                    parenetClassName={`${
                      inputs.loan == "yes" ? "" : "hidden"
                    } lg:w-max`}
                    labelClassName="text-[#959595] font-medium pl-1 w-20"
                    id="financierName"
                    label="Financier Name"
                    type="text"
                    placeholder="Financier Name"
                    value={inputs.financierName}
                    errorState={[financierNameError, setFinancierNameError]}
                    // state={[ownerName, setOwnerName]}
                  />
                  {/* <InputPP
                    className="w-full lg:w-96 border border-[#d7d5d5] rounded-md  px-3 py-2 my-2"
                    parenetClassName={`${
                      inputs.loan == "yes" &&tileType == "RSA" ? "" : "hidden"
                    }  lg:w-max`}
                    labelClassName="text-[#959595] font-medium pl-1 w-20"
                    id="financedValue"
                    label="Financed Value?"
                    type="text"
                    placeholder="Financed Value"
                    value={inputs.financedValue}
                    errorState={[financedValue, setFinancedValue]}
                    // state={[ownerName, setOwnerName]}
                  /> */}
                  <InputPP
                    className="w-full lg:w-96 border border-[#d7d5d5] rounded-md px-2 lg:px-3 py-1.5 lg:py-2 my-2"
                    parenetClassName={`${
                      inputs.loan == "yes" ? "" : "hidden"
                    } lg:w-max`}
                    labelClassName="text-[#959595] font-medium pl-1 w-20"
                    id="financiercity"
                    label="Financier City"
                    type="text"
                    placeholder="City"
                    value={inputs.financiercity}
                    errorState={[financierCityError, setFinancierCityError]}
                    // state={[ownerName, setOwnerName]}
                  />
                  <InputPP
                    className="w-full lg:w-96 border border-[#d7d5d5] rounded-md  px-2 lg:px-3 py-1.5 lg:py-2 my-2"
                    parenetClassName={`${
                      inputs.loan == "yes" ? "" : "hidden"
                    } lg:w-max`}
                    labelClassName="text-[#959595] font-medium pl-1 w-20"
                    id="financierAddress"
                    label="Financier Address"
                    type="text"
                    placeholder="Address"
                    value={inputs.financierAddress}
                    errorState={[financierAddress, setFinancierAddress]}
                    // state={[ownerName, setOwnerName]}
                  />
                  <div className={`${tileType == "RSA"?'':'hidden'}`}>


                  {/* <RadioPP
                    title="Has the vehicle ownership changed in last 12 months?"
                    value={inputs.vehicleOwnership}
                    errorState={[vehicleOwnershipError, setvehicleOwnershipError]}
                    objValues={[
                      {
                        id: "Yes",
                        name: "vehicleOwnership",
                        value: "Yes"
                      },
                      {
                        id: "No",
                        name: "vehicleOwnership",
                        value: "No"
                      }
                    ]}
                  /> */}
                 
                  </div>

                </div>
              </div>
            </div>
            <div className="flex justify-center space-x-5 py-8 m-auto  w-[53%] ">
              <Link to="/carinsurance/car-profile/step-3">
                <button className="bg-[#293651] hidden lg:block w-24 py-2 rounded-full text-white">
                  Back
                </button>
              </Link>
              <div className="flex flex-1"></div>
              {/* <Link to="/carinsurance/car-profile/step-1"> */}
              <input
                type="submit"
                className="bg-hero w-max px-5 py-2 rounded-full text-white focus:outline-none cursor-pointer "
                onClick={handleSubmit}
                value="Review and Make Payment"
              />
              {/* </Link> */}
            </div>
          </form>
        </main>
      </>
    </div>
  );
};

export default CarInfoStep4;
