import React, { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import DropDownPP from "../components/customs/DropDownPP";
import InputPP from "../components/customs/InputPP";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { RsaQuote1 } from "../components/carInsurance/quotation/rsaApis/RsaQuote1";

const RegisteredModel = ({ detailsPop, URL }) => {
  const [active, setActive] = useState(false);
  const [active2, setActive2] = useState(false);
  const [isDetailsPopUp, setisDetailsPopUp] = detailsPop;
  const [inputs, setInputs] = useState({});
  const [claimValue, setClaimValue] = useState("No");
  const [claimValue2, setClaimValue2] = useState("No");
  const [prevPolicyDateError, setPrevPolicyDateError] = useState();
  const [apiData, setApiData] = useState({});
  const [proceed, setProceed] = useState(false);
  const [preCustDetails, setPreCustDetails] = useState([]);
  const [makeandModel, setMakeandModel] = useState();
  const [prevPolicyError, setPrevPolicyError] = useState(false)
  const [carInfo, setCarInfo] = useState([]);
  const [carRegistrationNumber, setCarRegistrationNumber] = useState();
  const [isCarRegisterd, setIsCarRegisterd] = useState();
  const [claimValueError, setClaimValueError] = useState(false);
  const [claimValueError2, setClaimValueError2] = useState(false);
  const [ncbError, setNcbError] = useState(false);
  const [prevExpError, setPrevExpError] = useState(false);
  const [jwtToken, setJwtToken] = useState(localStorage.getItem("jwt_token"));
  const [actionType, setActionType] = useState();

  const {fetchQuoteRsa} = RsaQuote1()

  const toggle = [
    { id: "1", value: "No" },
    { id: "2", value: "Yes" }
  ];
  const toggle2 = [
    { id: "1", value: "No" },
    { id: "2", value: "Yes" }
  ];

  // const ncbOptions = ["0", "20", "25", "35", "45", "50"];
  const ncbOptions = [
    {
      id: "0",
      value: "0"
    },
    {
      id: "20",
      value: "20"
    },
    {
      id: "25",
      value: "25"
    },
    {
      id: "35",
      value: "35"
    },
    {
      id: "45",
      value: "45"
    },
    {
      id: "50",
      value: "50"
    }
    // {
    //   id:'6',
    //   value:'55'
    // },
    // {
    //   id:'7',
    //   value:'65'
    // },
  ];
 
  const prevPolicyOptionsOld = [
    {
      id:'comprehensive',
      value:'Comprehensive'
    },
    {
      id:'thirdParty',
      value:'Third Party',
    },
    {
      id:'ownDamage',
      value:'Own Damage'
    },
   
  ]
  const prevPolicyOptionsNew = [
    {
      id:'bundled',
      value:'Bundled'
    },
    {
      id:'thirdParty',
      value:'Third Party'
    }
  ]

  const navigate = useNavigate();

  //  for generating token
  useEffect(() => {
    const onStorage = () => {
      setJwtToken(localStorage.getItem("jwt_token"));
    };

    window.addEventListener("storage", onStorage);

    return () => {
      window.removeEventListener("storage", onStorage);
    };
  }, []);
  const carInfoData = localStorage.getItem("car_Info");
  const makeandModelData = localStorage.getItem("car_Info_make_model");
  const carRegisterdData = localStorage.getItem("isCarRegistered");
  const preCustdetailData = localStorage.getItem("pre_CustomerDetials");

  const carRegistrationNumberData = localStorage.getItem("car_Intro");

  const actionvalue = sessionStorage.getItem("action_type");

  useEffect(() => {
    setPreCustDetails(JSON.parse(preCustdetailData));
    setCarInfo(JSON.parse(carInfoData));
    setMakeandModel(makeandModelData);
    setIsCarRegisterd(carRegisterdData);
    setCarRegistrationNumber(JSON.parse(carRegistrationNumberData));
    setActionType(actionvalue);
  }, [
    actionvalue,
    carInfoData,
    carRegisterdData,
    carRegistrationNumberData,
    makeandModelData,
    preCustdetailData
  ]);

  // console.log(carRegistrationNumber.registrationNumber);

  const handleChange = (e) => {
    const name = e.target.name;

    const value = e.target.value;
    setInputs((values) => ({
      ...values,
      [name]:
        name === "predvNCB" || name === " " ? e.target.id : value
    }));
  };

  const todayDate = new Date();
  let cDay = todayDate.getDate();
  let cMonth = todayDate.getMonth() + 1;
  let cYear = todayDate.getFullYear();
  if (cDay < 10) {
    cDay = "0" + cDay;
  }
  if (cMonth < 10) {
    cMonth = "0" + cMonth;
  }

  console.log('d',inputs)
  const handleSubmit = (e) => {
    localStorage.setItem("api_Load", false);

    e.preventDefault();
    setProceed(true);
    if (claimValue == "" || claimValue == undefined) {
      setClaimValueError(true);
    } else {
      setClaimValueError(false);
    }
    if (claimValue2 == "" || claimValue2 == undefined) {
      setClaimValueError2(true);
    } else {
      setClaimValueError2(false);
    }
    if (inputs.prevNCB == "" || inputs.prevNCB == undefined) {
      setNcbError(true);
    } else {
      setNcbError(false);
    }
    if (inputs.prevPolicyType == "" || inputs.prevPolicyType == undefined) {
      setPrevPolicyError(true);
    } else {
      setPrevPolicyError(false);
    }
    if (inputs.policyExp == "" || inputs.policyExp == undefined) {
      setPrevExpError(true);
    } else {
      setPrevExpError(false);
    }
    if (
      claimValue2 == "" ||
      claimValue == "" ||
      (claimValue == "No" && inputs.prevNCB == "") ||
      inputs.policyExp == "" ||
      claimValue == undefined ||
      (claimValue == "No" && inputs.prevNCB == undefined) ||
      inputs.policyExp == undefined||
      inputs.prevPolicyType == undefined
    ) {
    } else {
      navigate(URL);
    }
  };


  useEffect(() => {
    if (
      Object.values(inputs).length >= (claimValue == "No" ? 3 : 2) &&
      (claimValue == "No" ? inputs.prevNCB.length > 0 : true) &&
      Object.keys(inputs).includes("policyExp") &&
      inputs.policyExp.length > 0&&  Object.keys(inputs).includes("prevPolicyType")  
    ) {
      localStorage.setItem(
        actionType == "Car"
          ? "registered_model_ownership"
          : "bike_registered_model_ownership",
        claimValue2
      );
      localStorage.setItem(
        actionType == "Car" ? "registered_model" : "bike_registered_model",
        JSON.stringify(inputs)
      );
      localStorage.setItem(
        actionType == "Car"
          ? "registered_model_claim"
          : "bike_registered_model_claim",
        claimValue
      );
    }
  }, [inputs, claimValue, actionType, claimValue2]);


  return (
    <div className="bg-white modal   w-[80%]  lg:w-[450px] lg:h-96 overflow-auto px-5 mt-10 lg:mt-0 lg:px-5 py-5 pb-14 lg:pb-5 rounded-2xl relative   z-40">
      <AiOutlineClose
        className="ml-auto mb-2 cursor-pointer w-6 h-6 p-1 rounded-full bg-[#45454542]"
        onClick={() => {
          setisDetailsPopUp(false);
        }}
      />
      <form onChange={handleChange}>
        <div className="m-auto">
          <div className="">
            <div className="flex items-center">
              <h1 className="text-[#959595] text-[15.8px] font-medium pl-1 ">
                Vehicle ownership change in past 12 months?{" "}
              </h1>
              <div className="flex  m-auto my-2 ">
                <div
                  className={`toggle-button relative  ${active2 ? "active bg-[#00a78eb4] " : "bg-[#a7160069]"}`}
                  onClick={(e) => {
                    setActive2(!active2);
                    setClaimValue2(active2 ? "No" : "Yes");
                  }}
                >
                  <div className="slider"></div>

                  <p className={`${active2?'hidden':''} absolute right-1  top-0.5 font-semibold text-sm text-white `}>No</p>
                  <p className= {`${active2?'':'hidden'} pt-0.5 pl-1 font-semibold text-sm text-white `}>Yes</p>
                </div>
              </div>
            </div>

            {claimValueError2 && (
              <p className="text-[#c62424] text-sm ">
                Please Choose Any One Option
              </p>
            )}
<div className="flex items-center">

            <h1 className="text-[#959595] text-[15.8px] font-medium pl-1 ">
              Any claims ?{" "}
            </h1>
            <div className="flex  m-auto my-2 ">
              <div
                className={`toggle-button  ${active ?  "active bg-[#00a78eb4] " : "bg-[#a7160069]"}`}
                onClick={(e) => {
                  setActive(!active);
                  setClaimValue(active? "No" : "Yes");
                }}
              >
                <div className="slider"></div>
                
                <p className={`${active?'hidden':''} absolute right-1  top-0.5 font-semibold text-sm text-white `}>No</p>
                  <p className= {`${active?'':'hidden'} pt-0.5 pl-1 font-semibold text-sm text-white `}>Yes</p>
               
              </div>
            </div>
</div>

            {claimValueError && (
              <p className="text-[#c62424] text-sm ">
                Please Choose Any One Option
              </p>
            )}

            {claimValue == "No" && (
              <DropDownPP
                className="w-full  border border-[#d7d5d5] rounded-md  px-3 py-2.5 my-2"
                labelClassName="text-[#959595] text-[15.8px]  font-medium pl-1 w-20"
                label="Previous NCB"
                id="prevNCB"
                differentDrop={true}
                options={ncbOptions}
                //   value={inputs.prevPolicyInsurer}
                errorState={[ncbError, setNcbError]}
              />
            )}
            <InputPP
              className="w-full date   border border-[#d7d5d5] rounded-md  px-3 py-2 my-2"
              // parenetClassName="lg:w-max"
              labelClassName="text-[#959595] text-[15.8px]  font-medium pl-1 w-20"
              id="policyExp"
              label="Policy expiry date"
              type="date"
              tDate={cYear + "-" + cMonth + "-" + cDay}
              placeholder=""
              //   value={inputs.carRegistrationDate}
              errorState={[prevExpError, setPrevExpError]}
              // state={[ownerName, setOwnerName]}
            />
                     <DropDownPP
                className="w-full  border border-[#d7d5d5] rounded-md  px-3 py-2.5 my-2"
                labelClassName="text-[#959595] text-[15.8px]  font-medium pl-1 w-20"
                label="Previous Policy Type"
                id="prevPolicyType"
                differentDrop={true}
                options={ prevPolicyOptionsOld}
                //   value={inputs.prevPolicyInsurer}
                errorState={[prevPolicyError, setPrevPolicyError]}
              />
          </div>
          {prevPolicyDateError && (
            <p className="text-sm pb-2 text-[#d24a4a]">
              Please contact our customer support team for renewal since your
              old policy has expired.
            </p>
          )}
          {/* <Link to="/carinsurance/quote"> */}
          <input
            type="submit"
            className="bg-hero w-32  m-auto flex justify-center text-center  py-2 rounded-full text-white "
            onClick={handleSubmit}
            // value={`${isregistered ? "Next" : "View Quote"} `}
            value="View Quote"
          />
          {/* </Link> */}
        </div>
      </form>
    </div>
  );
};

export default RegisteredModel;

//  {
//             registrationNo:
//               isCarRegisterd == "false"
//                 ? "no"
//                 : carRegistrationNumber.registrationNumber,
//             makemodel: makeandModel,
//             typeOfFuel: carInfo.fuelType,
//             vehicleVariant: "",
//             typeofpolicy: carInfo.policy,

//             name: preCustDetails.name,
//             email: preCustDetails.email,
//             contactNo: preCustDetails.mobileNo,
//             message: inputs.details,
//             previousNcb: claimValue !== "No" ? 0 : inputs.prevNCB,
//             prevYearPolicyEndDate: inputs.policyExp
//           },
