import React, { useState, useEffect } from "react";
import { AiOutlineDown, AiOutlineClose } from "react-icons/ai";
import { IoIosInformation } from "react-icons/io";
import axios from "axios";
import CpaCheckbox from "../../../../UI/quotation/CpaCheckbox";
import CpaCheckboxModel from "../../../../UI/quotation/CpaCheckbox";
import PreviousPolicyAdds from "../../../../UI/quotation/PreviousPolicyAdds";
import DropDownPP from "../../../customs/DropDownPP";

import { RsaAddonsQuote } from "../rsaApis/RsaAddonsQuote";
import { RelianceAddonsQuote } from "../relianceApis/RelianceAddonsQuote";
import { GodigitAddonQuote } from "../godigitApis/GodigitAddonsQuote";

export const PolicyFilterCard4 = ({
  filterCardData,
  closeState = "",
  parentClass = "",
  discData = "",
  covData = "",
  checkedAddonCount='',
  custCovdata = "",
  mission,
  placeValue,
  loading,loadingData
}) => {
  const [closePopUp, setClosePopUp] = closeState;
  const [inputs, setInputs] = useState({});

  const [checkInput1, setCheckInput1] = useState('')
  const [checkInput2, setCheckInput2] = useState('')
  const [checkInput3, setCheckInput3] = useState('')
  const [checkInput4, setCheckInput4] = useState('1')
  const [checkInput1Error, setCheckInput1Error] = useState(false)
  const [checkInput2Error, setCheckInput2Error] = useState(false)
  const [checkInput3Error, setCheckInput3Error] = useState(false)
  // const [jwtToken, setJwtToken] = useState('')
   const [defaultRadioValue, setDefaultRadioValue] = useState()
const [laceHolderValue, setPlaceHolderValue] = useState({})
  const [changeAction, setChangeAction] = useState(false)
  const [checkedAddonCnt, setCheckedAddonCnt] = checkedAddonCount
const [isRsaNilDep, setIsRsaNilDep] = useState(false)
const [isPrevAddsVal, setIsPrevAddsVal] = useState(null)
 const [loader, setLoader] = loading
 const [finalPolicyType, setFinalPolicyType] = useState(null)
 const [oldPolicyType, setOldPolicyType] = useState()
const [checkedValues, setCheckedValues] = useState(1)
const [isCpaModelOpen, setIsCpaModelOpen] = useState(false)
const [cpaCoverDetails, setCpaCoverDetails] = useState([])
const [updateValuesAgain, setUpdateValuesAgain] = useState(true)
const [openInfo, setOpenInfo] = useState()
const [tppdChange, setTppdChange] = useState(true)
const [activeCheckbox , setActiveCheckbox ] = useState(0)
const [openInfo2, setOpenInfo2] = useState()
const [openInfo3, setOpenInfo3] = useState()
  const [openDialog, setOpenDialog] = useState(false)
  const [noChange, setNoChange] = useState(false)
  const [CarIsRegistered, setCarIsRegistered] = useState()
const [isChecked, setIsChecked] = useState(true);
const [lastInputs, setLastInputs] = useState({})
const [firstAddonCall, setFirstAddonCall] = useState(false)
const [placeSiVal, setPlaceSiVal] = useState({})
const [stateChanged, setStateChanged] = useState(false)

/* ----------  Redux State values -------- */

const {fetchAddonsQuoteRsa,updateAddonsValueRsa ,updateCheckInputValuesRsa,updateCpaCoverValuesRsa,updatePrevAddValuesRsa} = RsaAddonsQuote()

const { fetchAddonsQuoteReliance, updateAddonsValueReliance, updateCheckInputValuesReliance,updateCpaCoverValuesReliance,updatePrevAddValuesReliance} =RelianceAddonsQuote()


const {fetchAddonQuoteGodigit, updateAddonTypeValueGodigit ,updateCheckInputValuesGodigit} = GodigitAddonQuote()




  const handleChange = (e) => {
    setStateChanged(true)

    updateCpaCoverValuesRsa(cpaCoverDetails)
    updateCpaCoverValuesReliance(cpaCoverDetails)
    updatePrevAddValuesRsa(isPrevAddsVal)
    updatePrevAddValuesReliance(isPrevAddsVal)
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({
      ...values,
      [name]: e.target.checked
    }))
    updateAddonsValueRsa((values) => ({
      ...values,
      [name]: e.target.checked
    }))
    updateAddonsValueReliance((values) => ({
      ...values,
      [name]: e.target.checked
    }))
    updateAddonTypeValueGodigit((values) => ({
      ...values,
      [name]: e.target.checked
    }))
setChangeAction(true)

// inputs.ownerDriverPAcover&& setIsCpaModelOpen(true)
  
  };


  const carRegistered = localStorage.getItem("isCarRegistered");
  const radioValue = localStorage.getItem('car_Info')
  const finalpolicytype = sessionStorage.getItem('inputs_policyType')
  const lastCheckValues = sessionStorage.getItem('last_input_addons_check')
  const defCheckedAddons = sessionStorage.getItem('input_addons_check')
 
  const requestSta = localStorage.getItem('apiLoaded')
  const cpaValues = sessionStorage.getItem('cpaValues')
  const tppdVal = sessionStorage.getItem('tppdVal')
  const first_add_call = sessionStorage.getItem('firstAddons')
const defSiVal = sessionStorage.getItem('check_inputs')

  useEffect(()=>{

    setCarIsRegistered (JSON.parse(carRegistered))
setDefaultRadioValue(JSON.parse(radioValue))
setLastInputs(JSON.parse(lastCheckValues))
setNoChange(requestSta=='true'?true:false)



setTppdChange(tppdVal)
setFirstAddonCall(first_add_call)

},[carRegistered, lastCheckValues, first_add_call, finalpolicytype, radioValue, requestSta, tppdVal, defSiVal])

  
// console.log('tppd',tppdChange)

useEffect(()=>{

 if (lastCheckValues==null&&finalPolicyType==(undefined||null)?oldPolicyType&&oldPolicyType!=="ownDamage":finalPolicyType&&finalPolicyType!=="ownDamage"){setInputs({
    ...inputs,
    ownerDriverPAcover:isChecked,
   
  })}
  else if(lastCheckValues==null) {
    setInputs({
      ...inputs,
      ownerDriverPAcover:false,
     
    })
  }

},[finalPolicyType, isChecked, oldPolicyType])

useEffect(()=>{
  if(lastCheckValues!==null) {
    setInputs(JSON.parse(lastCheckValues))
  }
 
},[])

useEffect(()=>{
  setFinalPolicyType(finalpolicytype)
setOldPolicyType(JSON.parse(radioValue).policy)


},[finalpolicytype, radioValue])
useEffect(()=>{
  if(defCheckedAddons&&!stateChanged) {
    console.log('im executed')
    updateAddonsValueReliance(JSON.parse(defCheckedAddons))
    updateAddonsValueRsa(JSON.parse(defCheckedAddons))
    updateAddonTypeValueGodigit(JSON.parse(defCheckedAddons))
  }
  if(Object.keys(placeSiVal).length>0&&!stateChanged) {
    updateCheckInputValuesReliance(placeSiVal.checkInput1,placeSiVal.checkInput2,placeSiVal.checkInput3)
    updateCheckInputValuesRsa(placeSiVal.checkInput1,placeSiVal.checkInput2,placeSiVal.checkInput3)
    updateCheckInputValuesGodigit(placeSiVal.checkInput1,placeSiVal.checkInput2,placeSiVal.checkInput3)
  }
  if(defSiVal&&!stateChanged) {
    setPlaceSiVal(JSON.parse(defSiVal))

  }
},[defCheckedAddons,stateChanged,defSiVal])
useEffect(()=>{
  if(isPrevAddsVal){
    updatePrevAddValuesRsa(isPrevAddsVal)
    updatePrevAddValuesReliance(isPrevAddsVal)
  }
  if(cpaCoverDetails){
    updateCpaCoverValuesRsa(cpaCoverDetails)
    updateCpaCoverValuesReliance(cpaCoverDetails)
  }

},[isPrevAddsVal,cpaCoverDetails])
 
// For Counting Number of Checked values in Addons
useEffect(()=>{
 
  setCheckedValues(0)
    if((finalPolicyType == (undefined || null)
                        ? oldPolicyType !== "thirdParty"
                        : finalPolicyType !== "thirdParty") ){
   const selectAddonOption= ['']

                          const trueValues = Object.values(lastInputs)
                          trueValues.forEach(function (x) { selectAddonOption[x] = (selectAddonOption[x] || 0) + 1; });
                          setCheckedValues(selectAddonOption.true==undefined?'0':selectAddonOption.true)
                        }else {
                          const selectAddonOption= ['']
                          const trueValues = Object.values(lastInputs)
                          const  truthyCheck=[lastInputs.externalBiFuelkit,lastInputs.paidDrivercover,lastInputs.ownerDriverPAcover,lastInputs.unnamedpassengerPAcover]
                          
                         
                          if((finalPolicyType == (undefined || null)
                          ? oldPolicyType == "thirdParty"
                          : finalPolicyType == "thirdParty") && (lastInputs.externalBiFuelkit|| lastInputs.paidDrivercover||lastInputs.ownerDriverPAcover||lastInputs.unnamedpassengerPAcover)) {
  
                            truthyCheck.forEach(function (x) { selectAddonOption[x] = (selectAddonOption[x] || 0) + 1; });
                            setCheckedValues(selectAddonOption.true==undefined?'0':+selectAddonOption.true+1)
                            // setCheckedAddonCnt(selectAddonOption.true==undefined?'0':selectAddonOption.true)
                          }
                        }
},[finalPolicyType, lastInputs, oldPolicyType])

useEffect(()=>{
  // sessionStorage.setItem('firstAddons',true)
  setCheckedAddonCnt(+checkedValues>1?+checkedValues-1:checkedValues)
},[checkedValues, setCheckedAddonCnt])


useEffect(()=>{
  if(inputs.paidDrivercover){
    setOpenInfo(13)
    setOpenInfo2(0)
    setOpenInfo3(0)
    setOpenDialog(true)
  }
  

  setTimeout(()=>{
    setOpenInfo(0)
    setOpenDialog(false)

   
  },3000)
},[inputs.paidDrivercover])
useEffect(()=>{
  
  if(inputs.ownerDriverPAcover){
    setOpenInfo2(14)
    setOpenInfo(0)
    setOpenInfo3(0)
    setOpenDialog(true)

  }
 

  setTimeout(()=>{
    setOpenInfo2(0)
    
    setOpenDialog(false)

  },3000)
},[  inputs.ownerDriverPAcover ])


useEffect(()=>{
  if( !inputs.ownerDriverPAcover ){

   changeAction && setIsCpaModelOpen(true)
  }else{
    setIsCpaModelOpen(false)
    
  }
},[ changeAction, inputs.ownerDriverPAcover])
useEffect(()=>{
 
  if(inputs.unnamedpassengerPAcover){
    setOpenInfo3(15)
    setOpenInfo(0)
    setOpenInfo2(0)
    setOpenDialog(true)

  }

  setTimeout(()=>{
   
    setOpenInfo3(0)
    setOpenDialog(false)

  },3000)
},[inputs.unnamedpassengerPAcover])

 
useEffect(()=>{
  if(Object.keys(placeSiVal).length>0) {
    setCheckInput1(placeSiVal.checkInput1)
    setCheckInput2(placeSiVal.checkInput2)
    setCheckInput3(placeSiVal.checkInput3)
  }
},[placeSiVal])

useEffect(()=>{

  if(inputs.electricalaccessories&& checkInput1=='') {
      setCheckInput1Error(true)
    
    }else {
      setCheckInput1Error(false)
    
    }
    if(inputs.nonElectricalaccessories&& checkInput2==''){
    setCheckInput2Error(true)
    
    }else {
      setCheckInput2Error(false)
    
    }
    if(inputs.externalBiFuelkit&&checkInput3=='') {
      setCheckInput3Error(true)
    
    }else {
      setCheckInput3Error(false)
    
    
    }

},[checkInput1,checkInput2,checkInput3,inputs.electricalaccessories,inputs.nonElectricalaccessories,inputs.externalBiFuelkit])
 

useEffect(()=>{

if(inputs.zeroDepreciation){
setIsRsaNilDep(true)
}else {
  setIsRsaNilDep(false)

}

},[inputs.zeroDepreciation])

 

useEffect(()=>{

  setTimeout(()=>{
    setUpdateValuesAgain(!updateValuesAgain)
  },2000)
},[inputs])


const handleFilterData = (e)=>{
  e.preventDefault()
  sessionStorage.setItem('firstAddons',false)

  // Get the current object value from sessionStorage
  const currentValue = inputs 
  
  // Update the specific property (e.g., key2)
  
  if(currentValue!==null){
  
    currentValue.lastownerDriverPAcover =  currentValue.ownerDriverPAcover
  }
  // Set the updated object back into sessionStorage
  sessionStorage.setItem('last_input_addons_check', JSON.stringify(currentValue));

  changeAction&&checkInput1Error==false&&checkInput2Error==false&&checkInput3Error==false&&localStorage.setItem('apiLoaded',false)
  if(Object.values(inputs).length>0 && checkInput1Error==false&&checkInput2Error==false&&checkInput3Error==false )  {
    setLoader(true)
    setPlaceHolderValue(inputs)
    sessionStorage.setItem("input_addons_check", JSON.stringify(inputs));

}
sessionStorage.setItem('check_inputs',JSON.stringify({checkInput1,checkInput2,checkInput3,checkInput4}))
checkInput1Error==false&&checkInput2Error==false&&checkInput3Error==false&& setClosePopUp(false);
 
 Object.values(inputs).length>0&&fetchAddonsQuoteRsa()
 Object.values(inputs).length>0&&fetchAddonsQuoteReliance()
 Object.values(inputs).length>0&&fetchAddonQuoteGodigit()
 

  }

 

 
  const [checkedState, setCheckedState] = useState({});

  // Function to handle checkbox change
  const handleCheckboxChange = (id) => {
    const newCheckedState = {
...checkedState,
      [id]: !checkedState[id]
    };
    setCheckedState(newCheckedState);
    sessionStorage.setItem('checkboxState', JSON.stringify(newCheckedState));
  };

  useEffect(() => {
    // Read the checkbox state from sessionStorage on component mount
    const savedState = JSON.parse(sessionStorage.getItem('checkboxState')) || {};
    setCheckedState(savedState);
  }, []);


  return (
    <>
      <div
            className={ `${isCpaModelOpen?'':'hidden'} ${(finalPolicyType==(undefined||null)?oldPolicyType&&oldPolicyType=="ownDamage":finalPolicyType&&finalPolicyType=="ownDamage")?'hidden':''}   `}
            onClick={() => { 
            //   setclosePopUp(!closePopUp);
            }}
          >
            <CpaCheckboxModel  callBackFun={handleFilterData} cpaVal ={[cpaCoverDetails, setCpaCoverDetails]} isModelOpen={ [isCpaModelOpen, setIsCpaModelOpen]}/>

          </div>
          <div className={`${isRsaNilDep?'':'hidden'}`}>
            <PreviousPolicyAdds addValue={ [isPrevAddsVal, setIsPrevAddsVal] } isModelOpen ={ [isRsaNilDep, setIsRsaNilDep] }/>
          </div>
      {filterCardData.map((items, key) => (
        <>
          <div
            className={`  ${parentClass}  bg-white z-0 h-  relative px-4 pt-2 shadow-[2px_2px_50px_rgba(0,167,142,0.15)] rounded-lg`}
          >
            <div className="flex items-center pb-5">
              <div className="">

              <h1 className="font-semibold text-[17px] ">{items.title}</h1>
              <h2 className=" ml-0 text-[15px]  text-[#777777] text-start hidden lg:block"> {`(${+checkedValues>1?+checkedValues-1:checkedValues})`} Applied</h2>
              </div>
              <div className="flex flex-1"></div>
              <button className={`${noChange==true?'':'opacity-[0.7]'} hidden lg:block bg-hero text-white px-4 py-1 ml-auto     rounded-xl`} disabled={noChange==true?false:true} onClick={
handleFilterData }>
                            Apply
                          </button>
             
              <AiOutlineClose
                className="bg-[rgba(0,167,142,0.1)] lg:hidden text-[#989898] w-8 h-8 p-2 rounded-full"
                onClick={() => {
                  setClosePopUp(false);
                }}
              />
            </div>
            

            {items.type === "checkbox" && (
              <div
                className="flex flex-col   "
                onChange={(e) => {
                  // const names = e.target.name;
                  // console.log({ ...names, names: e.target.id });
                }}
              >
                  {items.checkBoxData.map((item, key) => (
                    <form onChange={handleChange} >
                      <div className={`${items.odAddons&&key>11?'hidden':''} ${defaultRadioValue&&defaultRadioValue.fuelType ==('inbuilt')&&item.name=='externalBiFuelkit'&&(finalPolicyType == (undefined || null)
                        ? oldPolicyType !== "thirdParty"
                        : finalPolicyType !== "thirdParty")?'hidden':''} ${key<11 &&(finalPolicyType == (undefined || null)
                        ? oldPolicyType == "thirdParty"
                        : finalPolicyType == "thirdParty")  ? 'hidden':'' }  relative space-x-4 pb-2`} 
                        onClick={()=>{
                      }} >
                        <div className="flex gap-2   items-center">

                        <div key={item.id} className="custom-checkbox" onClick={() => handleCheckboxChange(item.id)}>
          <input
            type="checkbox"
            name={item.name}
            // defaultChecked={!!checkedState[item.id]}
            checked={!!checkedState[item.id]}
            id={item.name}
            onChange={() => handleCheckboxChange(item.id)}
          />
  <label
                          className="text-[#3f3838]  text-sm lg:text-base "
                          htmlFor={item.name}
                        >
                          
                        </label>
                        </div>

                        <label
                          className="text-[#3f3838]  text-sm  "
                          htmlFor={item.name}
                        >
                          {item.label}
                        </label>

                        {/* <input
                          type="checkbox"
                          name={item.name}
                          defaultChecked={item.check&&isChecked}
                          id={item.name}
                          onChange={item.check&&handleCheckboxChange}
                          className='my-checkbox'
                          // defaultChecked={inputs.electricalaccessories || inputs.nonElectricalaccessories||inputs.externalBiFuelkit?true:false}
                          // checked={resetButton? false:' '}
                          // checked={
                          //   inputs.paOwnerDriver == "on" ||
                          //   inputs.zeroDepreciation == "on"
                          //     ? true
                          //     : false
                          // }
                        />
                        <label
                          className="text-[#3f3838] text-sm lg:text-base "
                          htmlFor={item.name}
                        >
                          {item.label}
                        </label> */}
                        <IoIosInformation    name={item.name}  id={item.name} className={`${item.check||item.checkalt?'':'hidden'} ${openDialog?'':'bou nce'} ${item.isCus?'':'hidden'}  text-white text-xl rounded-full bg-[#2c9648] font-extrabold `} onMouseEnter={()=>{
                       setOpenDialog(true)
                       openDialog&&setOpenInfo(item.id)
                       setOpenInfo2(item.id)
                       setOpenInfo3(item.id)
                       if(openDialog==false){
                         setOpenInfo(0)
                       }
                        }}
                        onMouseLeave={()=>{
                          setOpenDialog(false)
                          openDialog&&setOpenInfo(0)
                          setOpenInfo2(0)
                          setOpenInfo3(0)
                          if(openDialog==false){
                            setOpenInfo(0)
                          }
                        }}
                        />
                     <div   name={item.name}  id={item.name} className={`bg-white w-max absolute   ${openInfo==item.id&&openDialog?'': `${openInfo==item.id?'':`${openInfo2==item.id&&openInfo!==13?'':`${openInfo3==item.id&&openInfo!==13?'':'hidden'}`}`}`} ${openDialog?'':'hidden'} rounded-lg drop-shadow-xl shadow-lg ${item.checkClass}    font-semibold   z-10 text-xs  p-3`}>
                          <h2 className="w-max">
                        {item.checkText}
                        
                          </h2>
                        </div>
                      
                        </div>
                       

                        <input type="number"    defaultValue={checkInput1}   onChange={(e)=>{
                          setCheckInput1(e.target.value)
                          updateCheckInputValuesRsa(e.target.value,checkInput2,checkInput3)
                          updateCheckInputValuesReliance(e.target.value,checkInput2,checkInput3)
                        
                          updateCheckInputValuesGodigit(e.target.value,checkInput2,checkInput3)
                        
                        }} className={`${item.isInput1 &&inputs.electricalaccessories?'':'hidden'} text-center  focus:outline-none border-b border-b-hero`} name='paOwner'  />
                        {checkInput1Error&&<h2 className={`text-[#d64947] text-sm  ${item.isInput1 &&inputs.electricalaccessories?'':'hidden'}`}>Please enter an Amount</h2> }

                        <input type="number"  defaultValue={checkInput2} onChange={(e)=>{
                          setCheckInput2(e.target.value)
                          updateCheckInputValuesRsa(checkInput1,e.target.value,checkInput3)
                          updateCheckInputValuesReliance(checkInput1,e.target.value,checkInput3)
                          updateCheckInputValuesGodigit(checkInput1,e.target.value,checkInput3)

                        }} className={`${item.isInput2 &&inputs.nonElectricalaccessories  ?'':'hidden'}   text-center focus:outline-none border-b border-b-hero`} name='paOwner'  />
                        {checkInput2Error&&<h2 className={`text-[#d64947] text-sm  ${item.isInput2 &&inputs.nonElectricalaccessories?'':'hidden'}`}>Please enter an Amount</h2> }


                        <input type="number"  defaultValue={checkInput3} onChange={(e)=>{
                          setCheckInput3(e.target.value)
                       updateCheckInputValuesRsa(checkInput1,checkInput2,e.target.value)
                       updateCheckInputValuesReliance(checkInput1,checkInput2,e.target.value)
                       updateCheckInputValuesGodigit(checkInput1,checkInput2,e.target.value)
                        }} className={`${item.isInput3 &&inputs.externalBiFuelkit?'':'hidden'}  text-center focus:outline-none border-b border-b-hero`} name='paOwner'  />
                      <div className={`${item.isInput4 && inputs.ownerDriverPAcover?'':'hidden'} ${CarIsRegistered?'hidden':''}`}>

                        <select  className="w-full text-sm  border border-[#d7d5d5] rounded-md focus:outline-none px-2 py-1 mt-1 " 
                        onChange={(e)=>{
                          setCheckInput4(e.target.value)
                        }}
                        name="cpaValue" id="cpaValue">
                          <option value="">Select CPA Value</option>
                          <option value="1">1</option>
                          <option value="3">3</option>
                        </select>
                     
                      </div>


{checkInput3Error&&<h2 className={`text-[#d64947] text-sm  ${item.isInput3 &&inputs.externalBiFuelkit?'':'hidden'}`}>Please enter an Amount</h2> }

                      </div>
                    </form>
                  ))}
                  <div className="flex pt-5 items-center">
                    <h2 className="text-start ml-0 flex lg:hidden"> {`(${+checkedValues>1?+checkedValues-1:checkedValues})`} Applied</h2>
                    <div className="ml-auto  flex space-x-5 items-center">
                      {items.buttonName.map((item, key) => (
                        <div className="flex">
                  
                        </div>
                      ))}
                       <button className={`${noChange==true?'':'opacity-[0.7]'} lg:hidden bg-hero text-white px-4 py-2 ml-auto  flex  rounded-xl`} disabled={noChange==true?false:true} onClick={
handleFilterData }>
                            Apply
                          </button>
                    </div>
                  </div>
              </div>
            )}
          </div>
        </>
      ))}
    </>
  );
};
