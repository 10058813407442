import React,{useState,useEffect} from 'react'
import RadioPP from '../../components/customs/RadioPP'

const PreviousPolicyAdds = ({isModelOpen,addValue}) => {
const [isPopOpen, setIsPopOpen] = isModelOpen
const [errorMessage, setErrorMessage] = useState(false)

const [isZeroDepOpted, setIsZeroDepOpted] = addValue

 

const handleOptionChange = (event) => {
  setIsZeroDepOpted(event.target.value === 'yes');
};

useEffect(()=>{
    
},[isZeroDepOpted])

const handleSubmit = ()=>{
    if (isZeroDepOpted == null)    {
        
        setIsPopOpen(true)
        setErrorMessage(true)
   
    }else {
      setErrorMessage(false)
        setIsPopOpen(false)

        }
        if(isZeroDepOpted!==null) {
            sessionStorage.setItem('prevAddons',isZeroDepOpted)
    
    
        }

}
const toggleModal = ()=>{
    if (isZeroDepOpted == null)    {
        
        setIsPopOpen(true)
   
    }else {
        
        setIsPopOpen(false)

        }
}
  return (
<main className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-[#393b3c] bg-opacity-50 z-50">

<section className="bg-white p-5 text-center md:p- rounded-md flex flex-col justify-center m-5 lg:m-0  items-center">

<p className=' md:text-lg font-semibold pb-2'>Is zero depreciation opted in Previous Policy?</p>
<div className="flex items-center gap-5">

      <div className='flex gap-1'>
        <input
          type="radio"
          id="yes"
          name="zeroDep"
          value="yes"
          checked={isZeroDepOpted === true}
          onChange={handleOptionChange}
        />
        <label htmlFor="yes">Yes</label>
      </div>
      <div className='flex gap-1'>
        <input
          type="radio"
          id="no"
          name="zeroDep"
          value="no"
          checked={isZeroDepOpted === false}
          onChange={handleOptionChange}
        />
        <label htmlFor="no">No</label>
      </div>
</div>

<div className="">

<button onClick={handleSubmit} className="mt-4 w-32 m-auto border text-[#1f243c] font-semibold border-hero hover:bg-[#00a78e49]  py-1 rounded-md hover:bg-blue-600 hover:drop-shadow-md  hover: drop-shadow-hero focus:outline-none">
  Submit
</button>
{/* <button onClick={toggleModal} className="mt-4 w-full rounded-md border  border-[#c34920] text-[#1f243c] py-2 focus:outline-none">
  Close
</button> */}
</div>
<p className={`${errorMessage?'':'hidden'} text-[#d65f50] pt-2`}>Please Select an option</p>
</section>

</main>

    )
}

export default PreviousPolicyAdds