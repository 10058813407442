import React, { useState, useEffect } from "react";
import { useDispatch,useSelector } from 'react-redux';

import Footer from "./components/Footer";
import HomeLanding from "./pages/HomeLanding";
import NavHeader from "./components/NavHeader";
import { BrowserRouter, Routes, Route,  } from "react-router-dom";
// import {useHistory} from 'react-router-dom';
import CarInsuranceLanding from "./pages/CarInsuranceLanding";
import CarInfo from "./components/carInsurance/CarInfo";
import CarInfoStep1 from "./components/carInsurance/step1forms/manual/CarInfoStep1";
import CarInfoStep2 from "./components/carInsurance/CarInfoStep2";
import CarInfoStep3 from "./components/carInsurance/CarInfoStep3";
import CarInfoStep4 from "./components/carInsurance/CarInfoStep4";
import CarInfoStep5 from "./components/carInsurance/CarInfoStep5";
import CarInfoStep6 from "./components/carInsurance/CarInfoStep6";
import CarQuotation from "./components/carInsurance/CarQuotation";
import CustomerDetails from "./components/carInsurance/CustomerDetails";
import Blogs from "./pages/Blogs";
import BlogsEntry from "./pages/BlogsEntry";
import ContactUs from "./pages/ContactUs";
import HealthInsuranceLanding from "./pages/HealthInsuranceLanding";
import TermInsuranceLanding from "./pages/TermInsuranceLanding";
import AboutUs from "./pages/AboutUs";
import BikeInsuranceLanding from "./pages/BikeInsuranceLanding";
import TermsandConditions from "./components/conditions/TermsandConditions";
import PrivacyPolicy from "./components/conditions/PrivacyPolicy";
import ScrollToTop from "./components/ScrollToTop";
import JoinPosp from "./pages/JoinPosp";
import Claims from "./pages/Claims";
import ReviewDetails from "./components/carInsurance/ReviewDetails";
import LoadingSpinner from "./components/paymentStatus/LoadingSpinner";
import PaymentSuccess from "./components/paymentStatus/PaymentSuccess";
import PaymentFailure from "./components/paymentStatus/PaymentFailure";
import Dummy from "./components/Dummy";
import BikeInfo from "./components/bikeInsurance/BikeInfo";
import CarIntro from "./components/carInsurance/CarIntro";
import BikeIntro from "./components/bikeInsurance/BikeIntro";
import BikeCustDetails from "./components/bikeInsurance/BikeCustDetails";
import BikeQuotation from "./components/bikeInsurance/BikeQuotation";
import BikeReviewDetails from "./components/bikeInsurance/BikeReviewDetails";
import BikeInfoStep4 from "./components/bikeInsurance/BikeInfoStep4";
import BikeInfoStep5 from "./components/bikeInsurance/BikeInfoStep5";
import BikeInfoStep6 from "./components/bikeInsurance/BikeInfoStep6";
import BikeInfoStep3 from "./components/bikeInsurance/BikeInfoStep3";
import BikeInfoStep2 from "./components/bikeInsurance/BikeInfoStep2";
import BikeInfoStep1 from "./components/bikeInsurance/step1forms/manual/BIkeInfoStep1";
import Login from "./components/authentication/Login";
import axios from "axios";
import CarStep1Manage from "./components/carInsurance/CarStep1Manage";
import NotFoundPage from "./UI/404NotFound";
import BikeStep1Manage from "./components/bikeInsurance/BikeStep1Manage";
import NotificationPermission from './components/NotificationPermission';
import sendNotification from './utils/NotificationUtils';
import Kyc from "./pages/Kyc";
import Lms from "./pages/Lms";
import PaymentStatus from "./components/payment/PaymentStatus";
import HealthIntro1 from "./components/healthInsurance/presteps/HealthIntro1";
import HealthIntro2 from "./components/healthInsurance/presteps/HealthIntro2";
import HealthIntro3 from "./components/healthInsurance/presteps/HealthIntro3";
import HealthIntro4 from "./components/healthInsurance/presteps/HealthIntro4";
import HealthIntro5 from "./components/healthInsurance/presteps/HealthIntro5";
import HealthIntro6 from "./components/healthInsurance/presteps/HealthIntro6";
import HealthIntro7 from "./components/healthInsurance/presteps/HealthIntro7";
import KycModal from "./UI/KycModal";
import LocalStorageCheck from "./UI/LocalStorageCheck";
import ReactGA from 'react-ga'

import { quoteActions } from "./store/slices/quote-slice-rsa";
import { quoteActionsReliance } from "./store/slices/quote-slice-reliance";
import { quoteActionsGodigit } from "./store/slices/quote-slice-godigit";


const TRACKING_ID = "G-G8ZLDNY0Q9" // ID FOR GOOGLE ANALYTICS
ReactGA.initialize(TRACKING_ID)


function App() {
const [proposalDataApi, setProposalDataApi] = useState({})
const [isDataLoaded, setIsDataLoaded] = useState(false)
const [defSessionQuoteVal, setDefSessionQuoteVal] = useState({})

  const carUrl = "/carinsurance/car-profile";
  const bikeUrl = "/bikeinsurance/bike-profile";
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

  const defSessQuoteVal = sessionStorage.getItem('sessQuoApiVal')

  const dispatch = useDispatch();

  useEffect(()=>{
    if(defSessQuoteVal){

      setDefSessionQuoteVal(JSON.parse(defSessQuoteVal))
    }
  },[defSessQuoteVal])

  // const API_ENDPOINT = process.env.REACT_APP_ENV
  // const API_ENDPOINT2 = process.env.REACT_APP_API_URL



  // console.log(window.location.protocol=='http:',window.location.href)
//   useEffect(()=>{
//     if(window.location.href.includes('https')){
// return true;
//     } else{
//       if(window.location.hostname!=='localhost'){
//         const url =window.location.href
//       url.replace(/^http:\/\//i, 'https://');
//      window.location.href=url.replace(/^http:\/\//i, 'https://')
//      console.log('chagned',url.replace(/^http:\/\//i, 'https://'))
//     }
//     }
//   },[])

useEffect(() => {
  // Set smooth scrolling behavior on mount
  document.documentElement.style.scrollBehavior = 'smooth';

  // Clean up and restore original behavior on unmount
  return () => {
    document.documentElement.style.scrollBehavior = 'auto';
  };
}, []);


useEffect(() => {
  // Example: send a notification after 5 seconds
  const notificationTimeout = setTimeout(() => {
    sendNotification('Welcome back! Thanks for visiting.');
  }, 5000);

  return () => {
    clearTimeout(notificationTimeout);
  };
}, []);

 
 
let rsaApiData = useSelector(state=>state.quoteRsa.apiData)
 
let godigitApiData = useSelector(state=>state.quoteGodigit.apiData)
 
let relianceApiData = useSelector(state=>state.quoteReliance.apiData)

console.log('sess api',rsaApiData)

useEffect(()=>{
  if( Object.keys(defSessionQuoteVal).length>0&& rsaApiData && Object.keys(rsaApiData).length<1 ){
       dispatch(quoteActions.setApiDataValues(defSessionQuoteVal.rsaQuoteResponse )) 

  }
  if( Object.keys(defSessionQuoteVal).length>0&& godigitApiData && Object.keys(godigitApiData).length<1 ){
       dispatch(quoteActionsGodigit.setApiDataValues(defSessionQuoteVal.goDigitQuoteResponse )) 

  }
  if( Object.keys(defSessionQuoteVal).length>0&& relianceApiData && Object.keys(relianceApiData).length<1 ){
       dispatch(quoteActionsReliance.setApiDataValues(defSessionQuoteVal.relianceQuoteResponse )) 

  }

  

},[defSessionQuoteVal,])







///////////////////////////////// Generating Authentication Token  ////////////////////////////////////


  useEffect(() => {

    axios
      .post(
        `${ API_ENDPOINT}/token`,
        {
          
            username: "oneadmin",
            credentials: "$%#@gh817655$$#1jk"
    
        },
        {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",

          }
        }
        )
        .then((res) => {
          localStorage.setItem('jwt_token',res.data)

        //   setTokenLoaded(true)
        // setJwtToken(res.data);
      })
      
      .catch((error) => console.log(error));
        
      }, [])

  useEffect(() => {
    
    const interval = setInterval(() => {
       axios
      .post(
        `${API_ENDPOINT}/token`,
        {
          
            username: "oneadmin",
            credentials: "$%#@gh817655$$#1jk"
    
        },
        {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json"
          }
        }
        )
        .then((res) => {
          localStorage.setItem('jwt_token',res.data)
          window.dispatchEvent(new Event("storage"));
        // setJwtToken(res.data);
      })
      
      .catch((error) => console.log(error));
    
  
    },240000);
  
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])

  // console.log('app',proposalDataApi)
 
  //  if(window.location.href=='/')
  let loggedIn=false
  return (
    <div className="App">
      <BrowserRouter>
            <LocalStorageCheck/>
            <NotificationPermission />
        <ScrollToTop>
          <Routes>
            <Route exact path="/" element={<HomeLanding />} />
            <Route exact path="/article" element={<Blogs />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/contactus" element={<ContactUs />} />
            <Route exact path="/lms" element={<Lms />} />
          
            <Route exact path="/kyc" element={<Kyc />} />
            <Route exact path="/kycVer" element={<KycModal propLoad={[isDataLoaded, setIsDataLoaded]} />} />
            <Route
              exact
              path="/article/:slug"
              element={<BlogsEntry />}
            />
            <Route exact path="/join-as-posp" element={<JoinPosp />} />
            <Route exact path="/claims" element={<Claims />} />
            <Route exact path="/dummy" element={<Dummy />} />
            <Route
              exact
              path="/carInsurance"
              element={<CarInsuranceLanding />}
            />
            <Route
              exact
              path="/bikeInsurance"
              element={<BikeInsuranceLanding />}
            />
            <Route
              exact
              path="/healthInsurance"
              element={<HealthInsuranceLanding />}
            />
            <Route
              exact
              path="/termInsurance"
              element={<TermInsuranceLanding />}
            />
            <Route exact path="/aboutus" element={<AboutUs />} />
            <Route
              exact
              path="/payments/complete"
              element={<LoadingSpinner />}
            />
            <Route exact path="/terms" element={<TermsandConditions />} />

            <Route exact path="/privacy" element={<PrivacyPolicy />} />
            <Route exact path="/payments/sucess" element={<PaymentSuccess />} />
            <Route exact path="/payments/loading" element={<LoadingSpinner />} />
            <Route exact path="/rsa/payments/complete" element={<PaymentStatus />} />
            <Route
              exact
              path="/payments/failure"
              element={<PaymentFailure />}
            />

            {/* Car Insurance End-to-End Flow Route Path */}
            <Route
              exact
              path="/carinsurance/car-profile/prestep-1"
              element={<CarIntro />}
            />
            <Route
              exact
              path="/carinsurance/car-profile/prestep-2"
              element={<CarInfo />}
            />
            <Route
              exact
              path="/carinsurance/details"
              element={<CustomerDetails />}
            />
            <Route
              exact
              path="/carinsurance/quote"
              element={<CarQuotation />}
            />
            <Route exact path={`${carUrl}/step-1`} element={<CarStep1Manage />} />
            <Route exact path={`${carUrl}/step-2`} element={<CarInfoStep2 />} />
            <Route exact path={`${carUrl}/step-3`} element={<CarInfoStep3 />} />
            <Route exact path={`${carUrl}/step-4`} element={<CarInfoStep4 proposalData={[proposalDataApi, setProposalDataApi]} dataLoaded={[isDataLoaded, setIsDataLoaded]} />} />
            <Route
              exact
              path={`${carUrl}/preview`}
              element={<ReviewDetails  proposalData={[proposalDataApi, setProposalDataApi]} dataLoaded={[isDataLoaded, setIsDataLoaded]} />}
            />
            <Route exact path={`${carUrl}/step-5`} element={<CarInfoStep5  propData={[proposalDataApi, setProposalDataApi]} propLoad={[isDataLoaded, setIsDataLoaded]}  />} />
            {/* <Route exact path={`${carUrl}/step-6`} element={<CarInfoStep6 />} /> */}
            <Route exact path={`/payment/complete`} element={<CarInfoStep6 />} />

            {/* Bike Insurance End-to-End Route Path */}

            <Route
              exact
              path="/bikeinsurance/bike-profile/prestep-1"
              element={<BikeIntro />}
            />
            <Route
              exact
              path="/bikeinsurance/bike-profile/prestep-2"
              element={<BikeInfo />}
            />
            <Route
              exact
              path="/bikeinsurance/details"
              element={<BikeCustDetails />}
            />

            <Route
              exact
              path="/bikeinsurance/quote"
              element={<BikeQuotation />}
            />

            <Route
              exact
              path={`${bikeUrl}/step-1`}
              element={<BikeStep1Manage />}
            />
            <Route
              exact
              path={`${bikeUrl}/step-2`}
              element={<BikeInfoStep2 />}
            />
            <Route
              exact
              path={`${bikeUrl}/step-3`}
              element={<BikeInfoStep3 />}
            />
            <Route
              exact
              path={`${bikeUrl}/step-4`}
              element={<BikeInfoStep4   proposalData={[proposalDataApi, setProposalDataApi]} dataLoaded={[isDataLoaded, setIsDataLoaded]} />}
            />
            <Route
              exact
              path={`${bikeUrl}/preview`}
              element={<BikeReviewDetails  proposalData={[proposalDataApi, setProposalDataApi]} dataLoaded={[isDataLoaded, setIsDataLoaded]} />}
            />
            <Route
              exact
              path={`${bikeUrl}/step-5`}
              element={<BikeInfoStep5 propData={[proposalDataApi, setProposalDataApi]} propLoad={[isDataLoaded, setIsDataLoaded]}  />}
            />
            <Route
              exact
              path={`${bikeUrl}/step-6`}
              element={<BikeInfoStep6 />}
            />
            {/* Bike Insurance End-to-End Route Path End Here */}


            {/*------------------------- Health Insurance End-to-End Route Path -------------- */}

            <Route
              exact
              path="/healthinsurance/health-profile/prestep-1"
              element={<HealthIntro1 />}
            />
            <Route
              exact
              path="/healthinsurance/health-profile/prestep-2"
              element={<HealthIntro2 />}
            />
            <Route
              exact
              path="/healthinsurance/health-profile/prestep-3"
              element={<HealthIntro3 />}
            />
            <Route
              exact
              path="/healthinsurance/health-profile/prestep-4"
              element={<HealthIntro4 />}
            />

            <Route
              exact
              path="/healthinsurance/health-profile/prestep-5"
              element={<HealthIntro5 />}
            />

            <Route
              exact
              path={`/healthinsurance/health-profile/prestep-6`}
              element={<HealthIntro6 />}
            />
            <Route
              exact
              path={`/healthinsurance/health-profile/prestep-7`}
              element={<HealthIntro7 />}
            />
            <Route
              exact
              path={`${bikeUrl}/step-3`}
              element={<BikeInfoStep3 />}
            />
            <Route
              exact
              path={`${bikeUrl}/step-4`}
              element={<BikeInfoStep4   proposalData={[proposalDataApi, setProposalDataApi]} dataLoaded={[isDataLoaded, setIsDataLoaded]} />}
            />
            <Route
              exact
              path={`${bikeUrl}/preview`}
              element={<BikeReviewDetails  proposalData={[proposalDataApi, setProposalDataApi]} dataLoaded={[isDataLoaded, setIsDataLoaded]} />}
            />
            <Route
              exact
              path={`${bikeUrl}/step-5`}
              element={<BikeInfoStep5 />}
            />
            <Route
              exact
              path={`${bikeUrl}/step-6`}
              element={<BikeInfoStep6 />}
            />
            {/* Bike Insurance End-to-End Route Path End Here */}

          <Route path='*'  element={<NotFoundPage/>} />
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </div>
  );
}

export default App;
