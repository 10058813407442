// api/quoteAPI.js
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { quoteActionsReliance } from "../../../../store/slices/quote-slice-reliance";
import { quoteActionsReliance } from "../../../store/slices/quote-slice-reliance";

import { carProposalAction } from "../../../store/slices/car-proposal";
import { Link, useNavigate } from "react-router-dom";

import axios from "axios";

let isValid = false;
export const GodigitProposal = ( ) => {
    const metroCities = ["Kolkata", "Chennai", "Delhi", "Mumbai"];

  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    prevPolicyNo:"",
    existingTPExpDate:'',
    financiercity:'',
    chasisNo:'',
    engineNo:'',
    loan:'',
    financierAddress:''
 
 
  });
  const [proceed, setProceed] = useState(false);
  const [carRegisterNoError, setCarRegisterNoError] = useState(false);
  const [engineNoError, setEngineNoError] = useState(false);
  const [chasisNoError, setChasisNoError] = useState(false);
  const [carRegisterDateError, setCarRegisterDateError] = useState(false);
  const [prevPolicyInsurerError, setPrevPolicyInsurerError] = useState(false);
  const [prevTpInsurerError, setPrevTpInsurerError] = useState(false)
  const [prevPolicyNoError, setPrevPolicyNoError] = useState(false);
  const [tpPolicyNumberError, setTpPolicyNumberError] = useState(false);
  const [exisTPExpDateError, setExisTPExpDateError] = useState(false);
  const [carLoanError, setCarLoanError] = useState(false);
  const [load, setLoad] = useState(false);

  const [previousInsurerId, setPreviousInsurerId] = useState({});


  const [financierNameError, setFinancierNameError] = useState(false);
  const [financedValue, setFinancedValue] = useState(false)
  const [financierCityError, setFinancierCityError] = useState(false);
  const [financierAddress, setFinancierAddress] = useState(false);
  const [vehicleOwnershipError, setvehicleOwnershipError] = useState(false);
  const [jwtToken, setJwtToken] = useState(localStorage.getItem("jwt_token"));
  const [pincodeDetails, setPincodeDetails] = useState({});
  const [step1Values, setStep1Values] = useState({});
  const [carRegistrationNumber, setCarRegistrationNumber] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [rtoRegionCode, setRtoRegionCode] = useState("");
  const [manufactureYear, setManufactureYear] = useState();
  const [normalCarRegNo, setNormalCarRegNo] = useState();
  const [initialPolicyType, setInitialPolicyType] = useState({});
  const [productCodeValue, setProductCodeValue] = useState(false);
  const [step2Values, setStep2Values] = useState({});
  const [step3Values, setStep3Values] = useState({});
  const [step4Values, setStep4Values] = useState({});
  const [registeredModelValues, setRegisteredModelValues] = useState(null);
  const [modelRegionId, setModelRegionId] = useState({});
  const [popUpsFilterData, setPopUpsFilterData] = useState({});
  const [prevClaimValue, setPrevClaimValue] = useState();
  const [idvValue, setIdvValue] = useState();
  const [dateOfBirth, setDateOfBirth] = useState();
  const [isCarRegistered, setIsCarRegistered] = useState();
  const [tileType, setTileType] = useState();
  const [popData, setPopData] = useState();
  const [selectedNCB, setSelectedNCB] = useState(null);

  const [ratesValues, setRatesValues] = useState();
const [rsaKycApiData, setRsaKycApiData] = useState()
const [ownership, setOwnership] = useState()
const [reasonCpaVal, setReasonCpaVal] = useState([])
const [tppdChange, setTppdChange] = useState()
const [prevAddons, setPrevAddons] = useState()
const [carPucError, setCarPucError] = useState(false)
  const [finalAddons, setFinalAddons] = useState({});
  const [tpNewDate, setTpNewDate] = useState();
  const [finalAddonsInput, setFinalAddonsInput] = useState({});
  const [inputDiscount, setInputDiscount] = useState();
  const [manufactureMonth, setManufactureMonth] = useState();
  const [masterID, setMasterID] = useState({});
  const [inputIdvValue, setInputIdvValue] = useState()
//   const [previousInsurerId, setPreviousInsurerId] = useState({});
  const [prevTpInsurerId, setPrevTpInsurerId] = useState({})
  const [previousInsurerId2, setPreviousInsurerId2] = useState({});
  const [prevInsurerData, setPrevInsurerData] = useState([]);
  const [prevInsurerData2, setPrevInsurerData2] = useState([]);
  const [loadAgain, setLoadAgain] = useState(false);
  const [isPopWindowOpen, setIsPopWindowOpen] = useState(false);
  const [closeNav, setCloseNav] = useState(false);
  const [tpExpireDate, setTpExpireDate] = useState('');
  const [tpStartDate, setTpStartDate] = useState('')
  const [makeErrorTrue, setMakeErrorTrue] = useState(false);
const [modelName, setModelName] = useState('')
const [policyTerm, setPolicyTerm] = useState(1)
  const [godigitKycDetails, setGodigitKycDetails] = useState([]);
  const carRegistered = localStorage.getItem("isCarRegistered");
  const filterData = sessionStorage.getItem("inputs_policyType");
  const godigitKycData = localStorage.getItem("godigitKycInput");
  let registeredModelValue = localStorage.getItem("registered_model");
const rsaKycApi = localStorage.getItem('rsaKyc_apidata')
  const carOwnerDetails = localStorage.getItem("step-1_Values");
  const personalDetails = localStorage.getItem("step-2_Values");
  const addressDetails = localStorage.getItem("step-3_Values");
  const vechileDetails = localStorage.getItem("step-4_Values");
  const pincodeData = localStorage.getItem("pincode_Details");
  const insurerId = localStorage.getItem("insurance_Id");
  const insurerTpId = localStorage.getItem("insurance-Tp_Id");
  let registeredModelOwnershipValue= localStorage.getItem('registered_model_ownership')
const inputIdvVal = sessionStorage.getItem('inputs_IDV')
  const radioValue = localStorage.getItem("car_Info");
  let registeredModelClaimValue = localStorage.getItem(
    "registered_model_claim"
  );
  const cpaValues = sessionStorage.getItem('cpaValues');
  const tppdVal = sessionStorage.getItem('tppdVal')

  const carRegNO = localStorage.getItem("car_Intro");
  const modelRegion = localStorage.getItem("regionCode");
  const tileValue = localStorage.getItem("tile_Type_Detail");
  const apidata = localStorage.getItem("apiData");
  const finaladdon = sessionStorage.getItem("input_addons_check");
  const finaladdoninput = sessionStorage.getItem("check_inputs");
  const finalDiscount = sessionStorage.getItem("inputs_discount");
  const masteridvalues = localStorage.getItem("master_ID");
  const prevPolicyHolderNames = localStorage.getItem("prev_policy_names");
  const rateValue = localStorage.getItem("ratesValues");
  const prevOptedAddon = sessionStorage.getItem('prevAddons')
  const model = localStorage.getItem("car_Info_make_model");
  const currentNcbVal = sessionStorage.getItem('currentNcb')

  const [proposalApiData, setProposalApiData] = useState();
  const [isDataLoaded, seTisDataLoaded] = useState();
  /* -------------------  API ENDPOINT -------------- */
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  /* ---------------------------------- -------------- */
  const dispatch = useDispatch();



  /* --------------- Redux Values ------------------------ */
  let rsaApiData = useSelector(state=>state.quoteRsa.apiData)

  let godigitApiData = useSelector(state=>state.quoteGodigit.apiData)

  let relianceApiData = useSelector(state=>state.quoteReliance.apiData)

  /* --------------------------------------------------------- */

  // useEffect(()=>{
  //   if(Object.values(masterID).length>0){
  //     setLoad(true)
  //   }
  // },[masterID])

  useEffect(() => {
    setTileType(tileValue);
    setPopData(JSON.parse(apidata));
  }, [tileValue, apidata]);
  useEffect(() => {
    const vehicleNumberHandle = () => {
      let dob = [""];
      let spliting = step2Values.dateOfBirth.split("-");
      dob.unshift(spliting);
      setDateOfBirth(dob[0][2] + "/" + dob[0][1] + "/" + dob[0][0]);
    };
    step2Values.dateOfBirth && vehicleNumberHandle();
  }, [step2Values.dateOfBirth]);

  // useEffect(() => {
  //   setIdvValue(
  //     (tileType == "Reliance" &&
  //     Object.keys(relianceApiData).length>0  &&relianceApiData.MotorPolicy.IDV) ||
  //       (tileType == "GoDigit" &&
  //       Object.keys(godigitApiData).length>0 && godigitApiData.vehicle.vehicleIDV.idv) ||
  //       (tileType == "RSA" &&
  //       Object.keys(rsaApiData).length>0&&rsaApiData.PREMIUMDETAILS.DATA.MODEL_IDV)
  //   );
  // }, [godigitApiData, relianceApiData, rsaApiData, tileType]);

  useEffect(() => {
    setPopUpsFilterData(filterData);
    setRegisteredModelValues(JSON.parse(registeredModelValue));
    setPrevClaimValue(registeredModelClaimValue);
    carRegNO !== null
      ? setCarRegistrationNumber(JSON.parse(carRegNO).registrationNumber)
      : setCarRegistrationNumber(inputs.carRegisterNo);
    setRsaKycApiData(JSON.parse(rsaKycApi))
    setManufactureYear(JSON.parse(radioValue).manufactureYear);
setModelName(model)

    carRegNO && setNormalCarRegNo(JSON.parse(carRegNO).registrationNumber);
    setInitialPolicyType(JSON.parse(radioValue));
    setPincodeDetails(JSON.parse(pincodeData));
    setModelRegionId(JSON.parse(modelRegion));
    setStep1Values(JSON.parse(carOwnerDetails));
    setStep2Values(JSON.parse(personalDetails));
    setStep3Values(JSON.parse(addressDetails));
    setStep4Values(JSON.parse(vechileDetails));
    setFinalAddonsInput(JSON.parse(finaladdoninput));
    setPolicyTerm(finaladdoninput? JSON.parse(finaladdoninput).checkInput4:1);
    setSelectedNCB(currentNcbVal)

    setInputDiscount(finalDiscount);
    setOwnership(registeredModelOwnershipValue)
    setInputIdvValue(inputIdvVal)
    setMasterID(JSON.parse(masteridvalues));
  setReasonCpaVal(cpaValues)
  setTppdChange(tppdVal)
  setPrevAddons(prevOptedAddon)

    setPrevInsurerData2(JSON.parse(prevPolicyHolderNames));
    setGodigitKycDetails(JSON.parse(godigitKycData));
    setFinalAddons(JSON.parse(finaladdon));
    // load&&setPreviousInsurerId2(JSON.parse(insurerId))
    setRatesValues(JSON.parse(rateValue));

    setIsCarRegistered(carRegistered);
  }, [carOwnerDetails, prevOptedAddon, load, cpaValues, registeredModelOwnershipValue, rsaKycApi, rateValue, inputs, inputIdvVal, godigitKycData, prevPolicyHolderNames, finalDiscount, insurerId, masteridvalues, finaladdoninput, carRegistered, finaladdon, modelRegion, pincodeData, radioValue, carRegNO, registeredModelClaimValue, vechileDetails, filterData, registeredModelValue, addressDetails, personalDetails, tppdVal, model, currentNcbVal]);

  /* ----------------- Date Logics --------------------*/

  const userPrevPolicyExp =
    registeredModelValues && registeredModelValues.policyExp;
  const startDate = new Date(isCarRegistered =='true'? userPrevPolicyExp : Date.now());

 
  const policyStartDate = new Date(startDate.getTime() + 24 * 60 * 60 * 1000); // Add one day in milliseconds
  const policyEndDate = new Date(
    startDate.getTime() + 365 * 24 * 60 * 60 * 1000
  ); // Add one year in milliseconds
  const tppolicyEndDate = new Date(
    startDate.getTime() + 3 * 365 * 24 * 60 * 60 * 1000
  );

  const formatDate = date =>
  `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`;

// Calculate and format prevPolicyStartDate as one year earlier and one day more
const prevPolicyStartDate = formatDate(new Date(startDate.getFullYear() - 1, startDate.getMonth(), startDate.getDate() + 1));

// prevPolicyEndDate remains the same as startDate
const prevPolicyEndDate = formatDate(startDate);

  /* ---------------- Date Logics End Here ---------------------  */

  /* ---------------------- To Update the Policy type value  -------------------------*/
//   const updateIdvTypeValueReliance = (value) => {
//     setIdVValue(value);
//   };

//   /* -------------------------------------------------------------- */
//   useEffect(()=>{
//     if(finalpolicytype){
//       setFinalPolicyType(finalpolicytype)
//     }else {
//       setFinalPolicyType(defaultRadioValue&&defaultRadioValue.policy)
//     }
//   },[defaultRadioValue, finalpolicytype])

  useEffect(() => {
    const onStorage = () => {
      setJwtToken(localStorage.getItem("jwt_token"));
    };

    window.addEventListener("storage", onStorage);

    return () => {
      window.removeEventListener("storage", onStorage);
    };
  }, []);

  // console.log('isVlue',isValuesRead,carInfo,modelRegionId,masterID,inputStatus)
  useEffect(() => {
    dispatch(quoteActionsReliance.setInputsRead());

    //  callbackfun()
  }, []);



  useEffect(() => {
    /* ---------------- For Getting ID's of Previous Insurer -------------- */
   
    Object.keys(inputs).includes("prevPolicyInsurer") &&
      axios
        .get(`${API_ENDPOINT}/insurance/id?name=${inputs.prevPolicyInsurer}&companyName=${tileValue}`, {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json"
          }
        })
        .then((response) => {
          localStorage.setItem(
            "insurance_Id",
            JSON.stringify(response.data[0])
          );
          setPreviousInsurerId(response.data[0]);
          setLoad(true);
        })
        .catch((err) => {
          console.log(err);
        });
   
    Object.keys(inputs).includes("prevTpInsurer") &&
      axios
        .get(`${API_ENDPOINT}/insurance/id?name=${inputs.prevTpInsurer}&companyName=${tileValue}`, {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json"
          }
        })
        .then((response) => {
          localStorage.setItem(
            "insurance-Tp_Id",
            JSON.stringify(response.data[0])
          );
          setPrevTpInsurerId(response.data[0]);
          setLoad(true);
        })
        .catch((err) => {
          console.log(err);
        });

    // https://staging-policymart.co.in/api/v1/insurance/id?name=
  }, [inputs,tileValue]);

  const updateInputValuesGodigit = (valueSet) =>{
    setInputs({
        prevPolicyNo:valueSet.prevPolicyNo,
        existingTPExpDate:valueSet.existingTPExpDate,
        financiercity:valueSet.financiercity,
        chasisNo:valueSet.chasisNo,
        engineNo:valueSet.engineNo,
        loan:valueSet.loan,
        financierAddress:valueSet.financierAddress,
        previousInsurerId:valueSet.previousInsurerId
    })
  }

  const callbackfun =  ()=>{
 

    // if (
    //   Object.keys(carInfo).length > 0 &&
    //   Object.keys(modelRegionId).length > 0 &&
    //   Object.keys(masterID).length > 0&&
    //   Object.keys(finalAddons).length > 0&&
    //   selectedNCB!==null&&
    //   (finalPolicyType!==null||finalPolicyType!==undefined)
    // ) {
    //   setMakeRequestSuc(true)
    //   sessionStorage.setItem("inputSet", true);
    //   dispatch(quoteActionsReliance.setInputsRead());
    // } else {
    //   sessionStorage.setItem("inputSet", false);
    //   dispatch(quoteActionsReliance.setInputsRead());
    // }
 
    }

      /* ------------------ Manufacture Month ----------------------- */
  const manufacturemonth = new Date(initialPolicyType.registrationDate);
  let carMonth = manufacturemonth.getMonth() + 1;
  if (carMonth < 10) {
    carMonth = "0" + carMonth;
  }
  useEffect(() => {
    setVehicleNumber(
        carRegistrationNumber&&  carRegistrationNumber.replace(
        /^([a-zA-Z]{2})(\d{2})([a-zA-Z]{2})(\d+)$/,
        "$1-$2-$3-$4"
      )
    );
  }, [carRegistrationNumber]);
 
 
  const fetchProposalGodigit = () => {
    dispatch(quoteActionsReliance.setQuoteLoading(true));

    axios
    .post(
      `${API_ENDPOINT}/proposal/godigit`,
      //
      {

       

        enquiryId: "GODIGIT_CQ_PVT_CAR_PACKAGE_01",
        contract: {
          insuranceProductCode:
            isCarRegistered == "true" || isCarRegistered == true
              ? (productCodeValue
                  ? popUpsFilterData == "comprehensive" && 20101
                  : initialPolicyType.policy == "comprehensive" && 20101) ||
                (productCodeValue
                  ? popUpsFilterData == "thirdParty" && 20102
                  : initialPolicyType.policy == "thirdParty" && 20102) ||
                (productCodeValue
                  ? popUpsFilterData == "ownDamage" && 20103
                  : initialPolicyType.policy == "ownDamage" && 20103)
              : (productCodeValue
                  ? popUpsFilterData == "bundled" && 20101
                  : initialPolicyType.policy == "bundled" && 20101) ||
                (productCodeValue
                  ? popUpsFilterData == "thirdParty" && 20102
                  : initialPolicyType.policy == "thirdParty" && 20102), // product code
          subInsuranceProductCode:
            isCarRegistered == "true" || isCarRegistered == true
              ? "PB"
              :((popUpsFilterData==undefined||null)?initialPolicyType.policy=="bundled":popUpsFilterData=="bundled")
              ? 31
              : 30, // pb

              startDate:  policyStartDate
              .toLocaleDateString("en-GB")
              .replace(/\//g, "-")
              .split("-")
              .reverse()
              .join("-"), // cover from
              endDate:  (!isCarRegistered&&(popUpsFilterData==(null||undefined)?initialPolicyType.policy == "thirdParty":popUpsFilterData=='thirdParty' ))?tppolicyEndDate.toLocaleDateString("en-GB")
              .replace(/\//g, "-")
              .split("-")
              .reverse()
              .join("-"): policyEndDate
                .toLocaleDateString("en-GB")
                .replace(/\//g, "-")
                .split("-")
                .reverse()
                .join("-"), 
          // "startDate":null,
          // "endDate":null,

          policyHolderType: "INDIVIDUAL",
          externalPolicyNumber: "PARTNER_POL_NO",
          isNCBTransfer: null,
          coverages: {
            voluntaryDeductible:
              inputDiscount == null || undefined
                ? "ZERO"
                : inputDiscount == "None"
                ? "ZERO"
                :(( (popUpsFilterData==undefined||null))?initialPolicyType.policy=="thirdParty":popUpsFilterData=="thirdParty")
                ? "ZERO"
                : (inputDiscount == "2500" && "TWENTYFIVE_HUNDRED") ||
                  (inputDiscount == "5000" && "FIVE_THOUSAND") ||
                  (inputDiscount == "7500" && "SEVENTYFIVE_HUNDRED") ||
                  (inputDiscount == "15000" && "FIFTEEN_THOUSAND"), // disc addon
            thirdPartyLiability: {
              isTPPD: true //
            },
            ownDamage: {
              discount: {
                userSpecialDiscountPercent: 0,
                discounts: []
              },
              surcharge: {
                loadings: []
              }
            },
            personalAccident: {
              selection:
                finalAddons == ("" || null || undefined)
                  ? false
                  : finalAddons.ownerDriverPAcover
                  ? true
                  : false, // pa owner driver true
              coverTerm: 0,
              coverAvailability: "AVAILABLE",
              netPremium: "INR 0.00"
            },
            accessories: {
              cng: {
                selection:
                  finalAddons == null || undefined
                    ? false
                    : finalAddons.externalBiFuelkit
                    ? true
                    : false, // bifuel true
                insuredAmount:
                  (finalAddons == null || undefined) &&
                  (finalAddonsInput == null || undefined)
                    ? 0
                    : finalAddonsInput && finalAddonsInput.checkInput3?finalAddonsInput.checkInput3:'0' // bifuel input
              },
              electrical: {
                selection:
                  finalAddons == null || undefined
                    ? false
                    : finalAddons.electricalaccessories
                    ? true
                    : false, // electric
                insuredAmount:
                  finalAddons == null || undefined
                    ? 0
                    : finalAddonsInput && finalAddonsInput.checkInput1?finalAddonsInput.checkInput1:'0' // electric input
              },
              nonElectrical: {
                selection:
                  finalAddons == null || undefined
                    ? false
                    : finalAddons.nonElectricalaccessories
                    ? true
                    : false, // non electric
                insuredAmount:
                  finalAddons == null || undefined
                    ? 0
                    : finalAddonsInput && finalAddonsInput.checkInput2?finalAddonsInput.checkInput2:'0' // non electric Input
              }
            },
            addons: {
              partsDepreciation: {
                claimsCovered: 'TWO',
                selection:
                  finalAddons == null || undefined
                    ? false
                    : finalAddons.zeroDepreciation
                    ? true
                    : false // zero dep true
              },
              roadSideAssistance: {
                selection:
                  finalAddons == null || undefined
                    ? false
                    : finalAddons.roadSideAssistance
                    ? true
                    : false // road
              },
              engineProtection: {
                selection:
                  finalAddons == null || undefined
                    ? false
                    : finalAddons.engineProtect
                    ? true
                    : false /// engine
              },
              tyreProtection: {
                selection:
                  finalAddons == null || undefined
                    ? false
                    : finalAddons.tyreProtection
                    ? true
                    : false // tyre
              },
              rimProtection: {
                selection: null
              },
              returnToInvoice: {
                selection:
                  finalAddons == null || undefined
                    ? false
                    : finalAddons.returntoInvoice
                    ? true
                    : false // return
              },
              consumables: {
                selection:
                  finalAddons == null || undefined
                    ? false
                    : finalAddons.consumables
                    ? true
                    : false // consu
              }
            },
            legalLiability: {
              paidDriverLL: {
                selection: null,
                insuredCount: null
              },
              employeesLL: {
                selection: null,
                insuredCount: null
              },
              unnamedPaxLL: {
                selection: null,
                insuredCount: null
              },
              cleanersLL: {
                selection: null,
                insuredCount: null
              },
              nonFarePaxLL: {
                selection: null,
                insuredCount: null
              },
              workersCompensationLL: {
                selection: null,
                insuredCount: null
              }
            },
            unnamedPA: {
              unnamedPax: {
                selection:
                  finalAddons == null || undefined
                    ? false
                    : finalAddons.unnamedpassengerPAcover
                    ? true
                    : false, // unnamed passweneger cover
                insuredAmount:null,
                insuredCount: null
              },
              unnamedPaidDriver: {
                selection:
                  finalAddons == null || undefined
                    ? false
                    : finalAddons.paidDrivercover
                    ? true
                    : false, // paid driver
                insuredAmount: null,
                insuredCount: null
              },
              unnamedHirer: {
                selection: null,
                insuredAmount: null,
                insuredCount: null
              },
              unnamedPillionRider: {
                selection: null,
                insuredAmount: null,
                insuredCount: null
              },
              unnamedCleaner: {
                selection: null,
                insuredAmount: null,
                insuredCount: null
              },
              unnamedConductor: {
                selection: null,
                insuredAmount: null,
                insuredCount: null
              }
            }
          }
        },
        vehicle: {

          // "isVehicleNew":   isCarRegistered == "true" || isCarRegistered == true
          //                     ? false
          //                     : true,
          //     "vehicleMaincode": masterID.goDigitVehicleCode[0],
              // "licensePlateNumber":   isCarRegistered == "true" || isCarRegistered == true
              //                 ? vehicleNumber.replaceAll("-", "").toUpperCase()
              //                 : `${modelRegionId.reliance.regionCode.replace("-", "")}NEW`,
              // "vehicleIdentificationNumber": null,
              // "engineNumber": "hkhdufhfu2736274",
              // "manufactureDate": "2021-06-26",
              // "registrationDate": "2021-06-26",
              // "vehicleIDV": {
              //     "idv": 0,
              //     "defaultIdv": null,
              //     "minimumIdv": 399768,
              //     "maximumIdv": 599651
              // },
           
          isVehicleNew:
            isCarRegistered == "true" || isCarRegistered == true
              ? false
              : true, ///  new vehicle false :::: Error if i make True
//  "vehicleMaincode": "1111810803", // MASTER ID goDigitVehicleCode
       
              vehicleMaincode: masterID.goDigitVehicleCode[0], // master main code
          licensePlateNumber:
            isCarRegistered == "true" || isCarRegistered == true
              ? vehicleNumber.replaceAll("-", "").toUpperCase()
              :`${modelRegionId.reliance.regionCode.replace("-", "")}` ,
               // num plate
              // `${modelRegionId.reliance.regionCode.replace("-", "")}NEW`
          vehicleIdentificationNumber:
            isCarRegistered == "true" || isCarRegistered == true
              ? null
              : "ABC12345678911111",
          engineNumber: inputs.engineNo, // engine
          "manufactureDate": inputs.carRegistrationDate , // registration date
          // // manufactureDate: null, // registration date
          registrationDate: inputs.carRegistrationDate, // registration date
          "vehicleIDV": {

          

          "idv": +inputIdvValue,
          "defaultIdv": null,
          "minimumIdv": godigitApiData&&godigitApiData.vehicle.vehicleIDV.minimumIdv,
          "maximumIdv":  godigitApiData&&godigitApiData.vehicle.vehicleIDV.maximumIdv,
          },
// if added idv filter
          usageType: null,
          permitType: null,
          motorType: null
        },
        "previousInsurer": {
          // "isPreviousInsurerKnown": true,
          // "previousInsurerCode": 159,
          // "previousPolicyNumber": 8686948888564861,
          // "previousPolicyExpiryDate": "2023-07-10",
          // "isClaimInLastYear": true,
          // "originalPreviousPolicyType": 20101,
          // "previousPolicyType": 20101,
          // "previousNoClaimBonus": 0,
          // "currentThirdPartyPolicy": null,
  

          "isPreviousInsurerKnown":   isCarRegistered == "true" || isCarRegistered == true
          ? true
          : false,
          "previousInsurerCode": previousInsurerId.goDigitCode,
          "previousPolicyNumber": inputs.prevPolicyNo,
          "previousPolicyExpiryDate":  '2024-7-10',
          "isClaimInLastYear": prevClaimValue == "Yes" ? true : false,
          "originalPreviousPolicyType": isCarRegistered == "true" || isCarRegistered == true
          ? (productCodeValue
              ? popUpsFilterData == "comprehensive" && '1OD_3TP'
              : initialPolicyType.policy == "comprehensive" && '1OD_3TP') ||
            (productCodeValue
              ? popUpsFilterData == "thirdParty" && "1OD_3TP"
              : initialPolicyType.policy == "thirdParty" && "1OD_3TP") ||
            (productCodeValue
              ? popUpsFilterData == "ownDamage" && "1OD_3TP"
              : initialPolicyType.policy == "ownDamage" && "1OD_3TP")
          : (productCodeValue
              ? popUpsFilterData == "bundled" && "1OD_3TP"
              : initialPolicyType.policy == "bundled" && "1OD_3TP") ||
            (productCodeValue
              ? popUpsFilterData == "thirdParty" && "1OD_3TP"
              : initialPolicyType.policy == "thirdParty" && "1OD_3TP"),
              
          "previousPolicyType": isCarRegistered == "true" || isCarRegistered == true
          ? (productCodeValue
              ? popUpsFilterData == "comprehensive" && "1OD_1TP"
              : initialPolicyType.policy == "comprehensive" && "1OD_1TP") ||
            (productCodeValue
              ? popUpsFilterData == "thirdParty" && "0OD_1TP"
              : initialPolicyType.policy == "thirdParty" && "0OD_1TP") ||
            (productCodeValue
              ? popUpsFilterData == "ownDamage" && "1OD_0TP"
              : initialPolicyType.policy == "ownDamage" && "1OD_0TP")
          : (productCodeValue
              ? popUpsFilterData == "bundled" && "1OD_1TP"
              : initialPolicyType.policy == "bundled" && "1OD_1TP") ||
            (productCodeValue
              ? popUpsFilterData == "thirdParty" && "0OD_1TP"
              : initialPolicyType.policy == "thirdParty" && "0OD_1TP"),
              "previousNoClaimBonus":  isCarRegistered == "true" || isCarRegistered == true?  ((registeredModelValues&&registeredModelValues.prevNCB==(null || undefined))||prevClaimValue=='Yes')?0:`${registeredModelValues&&+registeredModelValues.prevNCB}`:'0',
              "currentThirdPartyPolicy": {
                "currentThirdPartyPolicyInsurerCode": null,
                "currentThirdPartyPolicyNumber": inputs.tpPolicyNumber,
                "currentThirdPartyPolicyStartDateTime": inputs.carRegistrationDate,
                "currentThirdPartyPolicyExpiryDateTime": inputs.existingTPExpDate
            }
        },
        // previousInsurer: {
        //   isPreviousInsurerKnown: true,
        //   previousInsurerCode: previousInsurerId.goDigitCode, // prev comp code
        //   previousPolicyNumber: inputs.prevPolicyNo, // prev policy num
        //   previousPolicyExpiryDate:
        //     prevYear + "-" + prevMonth + "-" + prevActDay, // prev expiry date
        //   isClaimInLastYear: prevClaimValue == "Yes" ? false : true, // model popup
        //   originalPreviousPolicyType: null,
        //   previousPolicyType: null,
        //   previousNoClaimBonus:
        //   ((registeredModelValues&&registeredModelValues.prevNCB==(null || undefined))||prevClaimValue=='Yes')?0:`${registeredModelValues&&+registeredModelValues.prevNCB}` , /// pop up ncb
        //   currentThirdPartyPolicy: null
        // },
        persons: [
          {
            personType: "INDIVIDUAL",
            addresses: [
              {
                addressType: "PRIMARY_RESIDENCE",
                flatNumber: null,
                streetNumber: null,
                street: "",
                district: "",
                state: "27",
                city: "Pune",
                country: "IN",
                pincode: "411001"
              }
            ],

            communications: [
              {
                communicationType: "MOBILE",
                "communicationId": step1Values.mobile, // mobile num
                // communicationId: "9381624403", // mobile num
                isPrefferedCommunication: true
              },
              {
                communicationType: "EMAIL",
                communicationId: step1Values.email, // email
                isPrefferedCommunication: true
              }
            ],
            identificationDocuments: [
              {
                identificationDocumentId: "",
                documentType: "AADHAR_CARD",
                documentId: "",
                issuingAuthority: "",
                issuingPlace: "",
                issueDate: "2019-11-22",
                expiryDate: "2019-11-22"
              }
            ],
            isPolicyHolder: true,
            isVehicleOwner: true,
            firstName: step1Values.firstName, // first name
            middleName: null,
            lastName: step1Values.lastName, // last name
            dateOfBirth: godigitKycDetails.dob, // dob
            // "dateOfBirth": dateOfBirth, // dob **
            gender: step2Values.gender.toUpperCase(), // gender
            isDriver: true,
            isInsuredPerson: true
          }
        ],
        nominee: {
          firstName: step2Values.nomineeName, // nominee name
          middleName: "",
          lastName: "",
          dateOfBirth: step2Values.nomineedateOfBirth, // dob
          relation: step2Values.relation, // relation
          personType: "INDIVIDUAL"
        },
        pospInfo: {
          isPOSP: false,
          pospName: "",
          pospUniqueNumber: "",
          pospLocation: "",
          pospPanNumber: "",
          pospAadhaarNumber: "",
          pospContactNumber: ""
        },
        dealer: {
          dealerName: "",
          city: "",
          deliveryDate: null
        },
        motorQuestions: {
          furtherAgreement: "",
          selfInspection: false,
          financer: ""
        },
        kyc: {
          isKYCDone: godigitKycDetails.registered == "yes" ? true : false,

          ckycReferenceDocId:
            godigitKycDetails.registered == "yes" ? "D02" : "D07",

          ckycReferenceNumber:"JQZPK5302K",

          dateOfBirth: godigitKycDetails.dob,

          photo: ""
        }
      },

      {
        headers: {
            authorization: jwtToken,
            "Content-Type": "application/json",
            accept: "application/json",
            "x-rsa-type": 1
        }
      }
    )
    .then((res) => {
      // setApiData(res);
      setProposalApiData(res.data.MotorPolicy);
          seTisDataLoaded(true);
          localStorage.setItem(
            "payment_Data",
            JSON.stringify(res.data.MotorPolicy)
          );
          localStorage.setItem("proposal_loaded", true);
          dispatch(carProposalAction.setApiFailed(false));
          dispatch(carProposalAction.setProposalApiData(res.data.MotorPolicy));

    })

    .catch((error) => {
      dispatch(carProposalAction.setApiFailed(true));
      console.log(error)});
    // setIsValidReq(false)

  };

  return { fetchProposalGodigit,callbackfun ,updateInputValuesGodigit};
};