import { createSlice } from "@reduxjs/toolkit";

const quoteSliceGodigit = createSlice({
    name:'quoteGodigit',
    initialState:{
        isInputsRead:false,
        loader:true,
        firstLoader:true,
        apiData:{},
       
        loadApiData:{}
    },
    reducers:{
        setQuoteLoading(state,action){
            state.loader=action.payload;
            // state.loader=action.valueLod;
        },
        addFirstQuoteLoad(state, action) {
          state.firstLoader = action.payload
        //   state.firstLoader = action.valueFirLod
        },
        setApiDataValues(state, action) {
            state.apiData = action.payload
            // state.apiData = action.apiData
        },
       
        setInputsRead (state,action) {
            state.isInputsRead = true;
        },
        setLoadApiDataValues(state,action) {

        },
        
    }
})

export default quoteSliceGodigit.reducer;
export const quoteActionsGodigit = quoteSliceGodigit.actions