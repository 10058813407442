import React, { useState, useEffect } from "react";
import NavHeader from "../NavHeader";
import { Link, useNavigate } from "react-router-dom";
import InputPP from "../customs/InputPP";
import RadioPP from "../customs/RadioPP";
import { useSelector } from "react-redux";

import LandingBackground from "../../assets/Svg/LandingPageBck.svg";
 
import DropDownPP from "../customs/DropDownPP";
import InsuranceHeader from "../../UI/insurance/InsuranceHeader";
import InsuranceTracker from "../../UI/insurance/InsuranceTracker";

const CarInfoStep2 = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({

 
    dateOfBirth:'',
age:''


  });
  const [fetchedNomAge, setFetchedNomAge] = useState('')
  const [proceed, setProceed] = useState(false);
  const [genderError, setGenderError] = useState(false);
  const [marriedError, setMarriedError] = useState(false);
  const [dateofBirthError, setDateofBirthError] = useState(false);
  const [nomineedateofBirthError, setNomineeDateofBirthError] = useState(false)
  const [nomineeNameError, setNomineeNameError] = useState(false);

  const [addonCheck, setAddonCheck] = useState()
  const [ageError, setAgeError] = useState(false);
const [typeOfPolicy, setTypeOfPolicy] = useState()
const [kycData1, setKycData1] = useState(null)
const [updateAPiValues, setUpdateAPiValues] = useState()
const [appointeeNameError, setAppointeeNameError] = useState(false)
  const [relationError, setRelationError] = useState(false);
  const [isPopWindowOpen, setIsPopWindowOpen] = useState(false)
  const [closeNav, setCloseNav] = useState(false)
  // const handleChange = (e) => {
  //   const name = e.target.name;
  //   const value = e.target.value;
  //   setInputs((values) => ({ ...values, [name]: value }));
  // };

  const tileSelected = localStorage.getItem('tile_Type_price')
  const reliaceKyc = localStorage.getItem("relianceKyc_apidata")
  const rsaKyc = localStorage.getItem("rsaKyc_apidata")
  const godigitKyc = localStorage.getItem('godigitKycInput')
  const inputValues = localStorage.getItem("step-2_Values");
const addonValue = sessionStorage.getItem('input_addons_check')

let rsaApiData = useSelector(state=>state.quoteRsa.apiData)

 

useEffect(()=>{

  addonValue&&setAddonCheck(JSON.parse(addonValue))
},[addonValue])

  const handleChange = (e) => {

    const name = e.target.name;

    const value = e.target.value;
    setInputs((values) => ({
      ...values,
      [name]: name === "gender" || name === "married" ? e.target.id :  value
    }));
  };



  const handleSubmit = (e) => {
    e.preventDefault();


    if (inputs.gender == undefined || inputs.gender == "") {
      setGenderError(true);
    } else {
      setGenderError(false);
    }
    if (inputs.married == undefined || inputs.married == "") {
      setMarriedError(true);
    } else {
      setMarriedError(false);
    }
    if (inputs.dateOfBirth == undefined || inputs.dateOfBirth == "") {
      setDateofBirthError(true);
    }  
    if (inputs.nomineedateOfBirth == undefined || inputs.nomineedateOfBirth == "") {
      setNomineeDateofBirthError(true);
    } else {
      setNomineeDateofBirthError(false);
    }
    if (inputs.appointeeName == undefined || inputs.appointeeName == "") {
      setAppointeeNameError(true);
    } else {
      setAppointeeNameError(false);
    }
    if (
      inputs.nomineeName == undefined ||
      inputs.nomineeName == "" ||
      inputs.age == undefined ||
      inputs.age == "" ||
      inputs.relation == undefined ||
      inputs.relation == ""
    ) {
      setNomineeNameError(true);
    } else {
      setNomineeNameError(false);
    }

    if (
      addonCheck&&addonCheck.ownerDriverPAcover?(Object.values(inputs).length >=(inputs.age<18?8:7) && 
    inputs.gender.length > 1 &&
      inputs.married.length > 1 &&
      inputs.dateOfBirth.length > 0 &&
      inputs.nomineedateOfBirth.length > 0 &&
      inputs.nomineeName.length > 0 &&
      // inputs.age.length > 0 &&
      inputs.relation.length > 1&&
(inputs.age<18?inputs.appointeeName.length>0:true) )
: (Object.values(inputs).length >=   4 &&
      inputs.gender.length > 1 &&
      inputs.married.length > 1 &&
      inputs.dateOfBirth.length > 0 )
     

    ) {
      localStorage.setItem("step", 2);
      localStorage.setItem("step-2_Values", JSON.stringify(inputs));
    }
    
    !nomineeNameError && (inputs.age<18?!appointeeNameError:true ) && navigate(proceed ? "/carinsurance/car-profile/step-3" : "");




  };

  // console.log('drop',inputs, Object.values(inputs).length >= 7 ,
  // inputs.gender.length > 1 ,
  // inputs.married.length > 1 ,
  // inputs.dateOfBirth.length > 0 ,
  // inputs.nomineedateOfBirth.length > 0 ,
  // inputs.nomineeName.length > 0 ,
  // inputs.age.length  ,
  // inputs.relation.length > 1)


  useEffect(()=>{

    setUpdateAPiValues(JSON.parse(inputValues))
    setTypeOfPolicy(tileSelected)
  },[reliaceKyc,tileSelected,inputValues])
  
  useEffect(()=>{
    if(tileSelected=="Reliance") {
    setKycData1(JSON.parse(reliaceKyc))
    
  }
  if(tileSelected=='RSA') {
    setKycData1(JSON.parse(rsaKyc))
    
  }
  if(tileSelected=='GoDigit') {
    setKycData1(JSON.parse(godigitKyc))
    
  }
},[godigitKyc, reliaceKyc, rsaKyc, tileSelected])

  useEffect(()=>{

    /* ------------- Fetching Nominee Age A/c To DOB */
    
    var dob = new Date( inputs.nomineedateOfBirth );
    
    var ageDiffMs = Date.now() - dob.getTime();

    // Convert the time difference from milliseconds to years
    var ageDate = new Date(ageDiffMs);
    var age = Math.abs(ageDate.getUTCFullYear() - 1970);
    
    // var month_diff = Date.now() - dob.getTime();
    // var age_dt = new Date(month_diff); 
    // var year = age_dt.getUTCFullYear();
    // var age = Math.abs(year - 1970);
    setFetchedNomAge((age||age===0)?age:'')
    /* -------------------------------- Checking User Age -------------------- */
    
    var dob2 = new Date( inputs.dateOfBirth );
    
       
    
    var month_diff2 = Date.now() - dob2.getTime();
    var age_dt2 = new Date(month_diff2); 
    var year2 = age_dt2.getUTCFullYear();
    var age2 = Math.abs(year2 - 1970);
    if(age2<17) {
      setDateofBirthError(true)
      setProceed(false)
    } else if(age2>17){
      
      setDateofBirthError(false)
      // setProceed(true)
    }
    
    
    
    
    },[inputs.dateOfBirth,fetchedNomAge, inputs.nomineedateOfBirth])

  useEffect(()=>{

    typeOfPolicy=='Reliance'&& setInputs({
      ...inputs,
      dateOfBirth: kycData1&&kycData1.kyc_data.CKYC.result.PERSONAL_DETAILS.DOB,
      age:fetchedNomAge&&fetchedNomAge,
     
    });
    typeOfPolicy=='RSA'&& setInputs({
      ...inputs,
      dateOfBirth: kycData1&&kycData1.dateOfBirth,
      age:fetchedNomAge&&fetchedNomAge,
     
    });
    typeOfPolicy=='GoDigit'&& setInputs({
      ...inputs,
      dateOfBirth: kycData1&&kycData1.dob,
      age:fetchedNomAge&&fetchedNomAge,
     
    });
  },[updateAPiValues,fetchedNomAge, kycData1, typeOfPolicy])

// console.log('kaliiima', (inputs.age<18?inputs.appointeeName.length>0:true))
// console.log('jai',proceed,inputs.age<18,!appointeeNameError )

  useEffect(() => {
    inputValues && setInputs(JSON.parse(inputValues));
  }, [inputValues]);
  useEffect(() => {
    if (
      addonCheck&&addonCheck.ownerDriverPAcover==(true||'true')?(Object.values(inputs).length >=(inputs&&inputs.age<18?8:7) && 
    inputs.gender.length > 1 &&
      inputs.married.length > 1 &&
      inputs.dateOfBirth.length > 0 &&
      inputs.nomineedateOfBirth.length > 0 &&
      inputs.nomineeName.length > 0 &&
      // inputs.age.length > 0 &&
      inputs.relation.length > 1&&
inputs.age<18&&inputs.appointeeName.length>0
 )
: (Object.values(inputs).length >=   4 &&
      inputs.gender.length > 1 &&
      inputs.married.length > 1 &&
      inputs.dateOfBirth.length > 0 )
     
    ) {
      setProceed(true);
      // localStorage.setItem("step", 2);
      // localStorage.setItem("step-2_Values", JSON.stringify(inputs));
      
    }
     if(addonCheck&&addonCheck.ownerDriverPAcover&&(Object.values(inputs).length >=(inputs&&inputs.age<18?8:7 ))) {
      setProceed(true);

    }
  }, [addonCheck, inputs]);


  useEffect(()=>{
    if( Object.keys(inputs).includes('nomineeName') && inputs.nomineeName.length<1 ) {
      setNomineeNameError(true)
    } else if( Object.keys(inputs).includes('nomineeName') && inputs.nomineeName.length>0) {
      setNomineeNameError(false)

    }
    // if( Object.keys(inputs).includes('age') && inputs.age.length<1 ) {
    //   setAgeError(true)
    // } else if( Object.keys(inputs).includes('age') && inputs.age.length>0) {
    //   setAgeError(false)

    // }
    if( Object.keys(inputs).includes('appointeeName') && inputs.appointeeName.length<1 ) {
      setAppointeeNameError(true)
    } else if( Object.keys(inputs).includes('appointeeName') && inputs.appointeeName.length>0) {
      setAppointeeNameError(false)

    }
  },[inputs])

  const todayDate = new Date()
  let fDay = todayDate.getDate() 
  let fMonth = todayDate.getMonth() + 1;
  let fYear = todayDate.getFullYear();
  if(fDay<10) {
    fDay= '0'+fDay
  }
  if(fMonth<10) {
    fMonth='0'+fMonth
  }


  return (
    <div>
      <>
        <NavHeader dropClose={ [closeNav, setCloseNav] } />
        <main
          className={`  w-full overflow-x-hidden  lg:pt- bg-no-repeat bg-cover   ${isPopWindowOpen?'h-screen ':''}   text-sm lg:text-base  `}
          style={{ backgroundImage: `url(${LandingBackground})` }}  onClick={()=>{
            setCloseNav(false)
          }}
        >
          <InsuranceHeader popwindow={[isPopWindowOpen, setIsPopWindowOpen] } />
          <div className="pt-5 lg:pt-8 pb-16 lg:pb-">
            <InsuranceTracker />
          </div>
          <form onChange={handleChange}  className={`${isPopWindowOpen?'hidden':''}`}>
            <div
              className={`slider-in justify-center bg-white mx-5 lg:m-auto overflow-x-hidden drop-shadow-lg lg:w-[55%]    rounded-xl p-4  `}
            >
              <div className="flex items-center space-x-2 pb-4">
                <div className="w-6 h-6 lg:w-8 lg:h-8 bg-hero text-white rounded-full items-center flex justify-center">
                  2
                </div>
                <h1 className="m  text-solid lg:text-xl  font-semibold">
                  Personal Details
                </h1>
              </div>
              <hr className="text-[#DBDBDB] pb-4" />
              <div className="">
                <RadioPP
                  title="Gender"
                  errorState={[genderError, setGenderError]}
                  value={inputs.gender}
                  objValues={[
                    {
                      id: "male",
                      name: "gender",
                      value: "Male"
                    },
                    {
                      id: "female",
                      name: "gender",
                      value: "Female"
                    }
                  ]}
                />
                <div className="my-4">
                  <RadioPP
                    errorState={[marriedError, setMarriedError]}
                    value={inputs.married}
                    title="Married"
                    objValues={[
                      {
                        id: "yes",
                        name: "married",
                        value: "Yes"
                      },
                      {
                        id: "no",
                        name: "married",
                        value: "No"
                      }
                    ]}
                  />
                </div>
                <div className="my-4  w-full">
                  
                  <InputPP
                    className="w-full date lg:w-96 border border-[#d7d5d5] rounded-md px-2 lg:px-3 py-1.5 lg:py-2 my-2"
                    parenetClassName="w-full"
                    labelClassName="text-[#959595] font-medium pl-1 w-20"
                    id="dateOfBirth"
                    label="Date of Birth (Min.Age 18) "
                    type="date"
                    placeholder="Day"
                    value={tileSelected!=='GoDigit'?inputs.dateOfBirth.split('-').reverse('').join('-'):(inputs&&inputs.dateOfBirth)?inputs.dateOfBirth:  inputs.dateOfBirth.split('-').reverse('').join('-')}
                    errorState={[dateofBirthError, setDateofBirthError]}
disableTrue={tileSelected!=='GoDigit'? true:false}
                    // state={[ownerName, setOwnerName]}
                  />
                </div>
                <div className={`${addonCheck&&addonCheck.ownerDriverPAcover==true?'':'hidden'}`}>

                <InputPP
                    className="w-full date lg:w-96 border border-[#d7d5d5] rounded-md  px-2 lg:px-3 py-1.5 lg:py-2 my-2"
                    parenetClassName="w-full"
                    labelClassName="text-[#959595] font-medium pl-1 w-20"
                    id="nomineedateOfBirth"
                    label="Nominee Date of Birth"
                    type="date"
                    placeholder="Day"
                    maxDate={fYear + "-" + fMonth + "-" + fDay}
                    value={inputs.nomineedateOfBirth}
                    errorState={[nomineedateofBirthError, setNomineeDateofBirthError]}

                    // state={[ownerName, setOwnerName]}
                  />
                  
                <div className="flex flex-col lg:flex-row lg:flex-shrink lg:space-x-7">
                  <InputPP
                    className="w-full xl:w-96 border border-[#d7d5d5] rounded-md  px-2 lg:px-3 py-1.5 lg:py-2 my-2"
                    labelClassName="text-[#959595] font-medium pl-1"
                    id="nomineeName"
                    label="Nominee Details"
                    type="text"
                    placeholder="Nominee Name"
                    value={inputs.nomineeName}
                    errorState={[nomineeNameError, setNomineeNameError]}
                    // state={[emailId, setEmailId]}
                  />
                  <div className="flex space-x-3 lg:space-x-7">
                    <InputPP
                      className="w-28 lg:w-24 border border-[#d7d5d5] rounded-md px-2 lg:px-3 py-1.5 lg:py-2 my-2"
                      labelClassName="text-[#959595] font-medium pl-1"
                      id="age"
                      type="number"
                      // disableTrue={true}
                      placeholder="Age"
                      required={false}
                      value={inputs.age}
                      disableTrue={true}
                      // errorState={[ageError, setAgeError]} // state={[mobileNumber, setMobileNumber]}
                    />
                    <DropDownPP
                      className="w-24lg:w-44 border border-[#d7d5d5] rounded-md focus:outline-none px-2 lg:px-3 py-1.5 lg:py-2 my-2"
                      labelClassName="hidden"
                      label="Relation"
                      id="relation"
                      required={false}
                      value={inputs.relation}
                      options={[
                        "Son",
                        "Daughter",
                        "Wife",
                        "Husband",
                        "Mother",
                        "Father"
                      ]}
                    />
                  </div>
                  </div>
                      <div className={`${(inputs.age<18)?inputs.age==''&&inputs.age!==0?'hidden':'':'hidden'}`}>

                  <InputPP
                      className="w-full lg:w-40 border border-[#d7d5d5] rounded-md  px-2 lg:px-3 py-1.5 lg:py-2 my-2"
                      labelClassName="text-[#959595] font-medium pl-1"
                      id="appointeeName"
                      type="text"
                      label="Appointee Name"
                      // disableTrue={true}
                      placeholder="Appointee Name"
                      required={true}
                      value={inputs.appointeeName}
                      errorState={[appointeeNameError, setAppointeeNameError]} // state={[mobileNumber, setMobileNumber]}
                      />
                      </div>
                      </div>
                  
                  {/* <InputPP
                    className="w-44 border border-[#d7d5d5] rounded-md  px-3 py-2 my-2"
                    labelClassName="text-[#959595] font-medium pl-1"
                    id="relation"
                    type="text"
                    required={false}
                    placeholder="Relation"
                    // state={[mobileNumber, setMobileNumber]}
                  /> */}
               
              </div>
            </div>
            <div className="flex justify-center m-auto space-x-5 py-8 w-[53%] ">
              <Link to="/carinsurance/car-profile/step-1">
                <button className="bg-[#293651] hidden lg:block w-24 py-2 rounded-full text-white">
                  Back
                </button>
              </Link>
              <div className="flex flex-1"></div>
              {/* <Link to="/carinsurance/car-profile/step-3"> */}
              <input
                type="submit"
                className="bg-hero w-max px-5 py-2 rounded-full text-white focus:outline-none cursor-pointer "
                onClick={handleSubmit}
                value=" Continue to Communication Address"
              />
              {/* </Link> */}
            </div>
          </form>
        </main>
      </>
    </div>
  );
};

export default CarInfoStep2;
