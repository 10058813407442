import React,{useState} from "react";
import { Link } from "react-router-dom";
import NavHeader from "../components/NavHeader";
import LandingBackground from "../assets/Svg/LandingPageBck.svg";
import CarLandingSvg1 from "../assets/Svg/CarLandingSvg1.svg";
import CarLandingSvg2 from "../assets/Svg/CarLandingSvg2.svg";
import QuoteVector from "../assets/Vector/QuoteVector.svg";
import Testimonial from "../components/slider/Testimonial";
import Article from "../components/Article";
import Partners from "../components/slider/Partners";
import Faq from "../components/Faq";
import Footer from "../components/Footer";
const CarInsuranceLanding = () => {
  const [closeNav, setCloseNav] = useState(false)
  return (
    <>
      <NavHeader   dropClose={ [closeNav, setCloseNav] }
 />
      <main
        className="w-full  bg-no-repeat bg-cover  "
        style={{ backgroundImage: `url(${LandingBackground})` }}  onClick={()=>{
          setCloseNav(false)
        }}
      >
        {/* Section - 1 */}
        <section className="flex flex-col-reverse lg:flex-row  items-center lg:space-x-32 justify-around py-7 lg:px-32 lg:py-24">
          <div className="space-y-5">
            <h1 className="text-2xl lg:text-5xl text-[#293651] text-center lg:text-start lg:leading-snug ">
              Better Insurance, For <br />
              <span className="text-hero font-bold">Better Journey</span>
            </h1>
            <h2 className=" text-[#777777] text-sm lg:text-base leading-5 lg:leading-loose  text-center lg:text-start px-5 lg:px-0 ">
              Car insurance provides compensation to the{" "}
              <br className="lg:hidden " /> policyholder in{" "}
              <br className="hidden lg:block" /> the event their car is stolen
              or <br className="lg:hidden  " /> damaged due to any{" "}
              <br className="hidden lg:block" /> unforeseen circumstances.
            </h2>
            <Link to="/carinsurance/car-profile/prestep-1">
              <img src={QuoteVector} alt="QuoteVector" className="pt-6 px-6 lg:px-0" />
            </Link>
          </div>
          <div className=" ">
            <img
              src={CarLandingSvg1}
              alt="CarLandingSvg1"
              className="pt-3 px-10 lg:px-0 pb-8 lg:py-0"
            />
          </div>
        </section>

        {/* Section -2  what is Car Insurance */}

        <section className="flex flex-col lg:flex-row items-center lg:space-x-20 px-4 py-7 lg:px-40 lg:py-20">
          <img
            src={CarLandingSvg2}
            alt="CarLandingSvg2"
            className="px-5 lg:px-0 pb-14 lg:pb-0"
          />
          <div className="">
            <h1 className="text-hero font-bold text-lg lg:text-3xl pb-2 lg:pb-5">
              What is car insurance?
            </h1>
            <h2 className="text-sm lg:w-[434px] text-[#777777] lg:leading-8">
              Car Insurance, also known as auto or motor insurance, is a type of
              vehicle insurance policy that protects you and your car from any
              risks and damages caused from accidents, thefts or natural
              disasters. So, you will be financially secure in case of any
              losses that may be incurred because of any such unforeseen
              circumstances. In addition to that, you will also be protected
              from third-party liabilities.
            </h2>
          </div>
        </section>

        {/* Section -3 Testimonial */}
        <section className="py-8   ">
          <Testimonial />
        </section>

        {/* section -4 Articles */}
        <section className="py-10 lg:py-20  flex justify-center">
          <Article />
        </section>

        {/* Section - 5 Partners */}
        <section className="  px-6 lg:px-32  m-auto">
          <Partners />
        </section>
        {/* Section -6 Faqs */}
        <section className="lg:py-16 lg:px-40">
          <Faq />
        </section>
        {/* Global  Footer */}
        <Footer />
      </main>
    </>
  );
};

export default CarInsuranceLanding;
