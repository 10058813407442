// api/quoteAPI.js
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { quoteActionsGodigit } from "../../../../store/slices/quote-slice-godigit";

import axios from "axios";

export const GodigitIdvQuote = () => {
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [carRegistrationNumber, setCarRegistrationNumber] = useState("");
  const [carInfo, setCarInfo] = useState({});
  const [oldPolicyType, setOldPolicyType] = useState();
  const [inputStatus, setInputStatus] = useState(false);
  // const [closePopUp, setClosePopUp] = closeState;
  const [isCoupenSelected, setIsCoupenSelected] = useState(false);
  const [preCustomerDetails, setPreCustomerDetails] = useState()
const [idvValue, setIdVValue] = useState({})
 
const [finalPolicyType, setFinalPolicyType] = useState({})
  const [inputAddon, setInputAddon] = useState({});
  const [isDisActive, setIsDisActive] = useState();
  const [finalAddonsInput, setFinalAddonsInput] = useState({});

  // const [placeHolderValue, setPlaceHolderValue] = placeValue;
  // const [jwtToken, setJwtToken] = useState('')
  const [rtoRegionCode, setRtoRegionCode] = useState("");
  const [defaultRadioValue, setDefaultRadioValue] = useState();
  const [addonsData, setAddonsData] = useState({});
  const [inputDiscount, setInputDiscount] = useState();
  const [finalIDVValue, setFinalIDVValue] = useState();
  const [resetButton, setResetButton] = useState(false);
  const [noChange, setNoChange] = useState(false);
  const [selectedNCB, setSelectedNCB] = useState(0);
  const [reasonCpaVal, setReasonCpaVal] = useState();
  const [tppdChange, setTppdChange] = useState(true);
  const [prevAddons, setPrevAddons] = useState();
  const [isJWTCall, setIsJWTCall] = useState(false);
  const [quoteLoading, setquoteLoading] = useState(false);
  const [apiData, setApiData] = useState({});
  const [manufactureYear, setManufactureYear] = useState();
  const [initialPolicyType, setInitialPolicyType] = useState({});
  const [masterID, setMasterID] = useState({});
  const [rsaIdvValue, setRsaIdvValue] = useState();
  const [tokenLoaded, setTokenLoaded] = useState(false);
  const [prevClaimValue, setPrevClaimValue] = useState();
  const [registeredModelValues, setRegisteredModelValues] = useState({});
  const [popUpsFilterData, setPopUpsFilterData] = useState({});
  const [makeRequest, setMakeRequest] = useState(false);
  const [ownership, setOwnership] = useState();
  const [changeAction, setChangeAction] = useState(false);
  const [CarIsRegistered, setCarIsRegistered] = useState();
  const [changeButtonValue, setChangeButtonValue] = useState(true);
  const [modelRegionId, setModelRegionId] = useState({});
  const [ratesValues, setRatesValues] = useState();
  const [radioValues, setRadioValues] = useState(true);
  const [buttonValue, setButtonValue] = useState();
  const [jwtToken, setJwtToken] = useState(localStorage.getItem("jwt_token"));
  const [finalAddons, setFinalAddons] = useState({});
  // For Counting Number of Checked values in Addons
  const selectAddonOption = [""];
  const trueValues = Object.values(inputAddon);
  trueValues.forEach(function (x) {
    selectAddonOption[x] = (selectAddonOption[x] || 0) + 1;
  });
  localStorage.setItem(
    "checked_addons",
    addonsData && selectAddonOption.true == undefined
      ? "0"
      : selectAddonOption.true
  );

  const [isValidReq, setIsValidReq] = useState(true);
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const dispatch = useDispatch();

  //  State from Redux
  let isValuesRead = useSelector((state) => state.quoteRsa.isInputsRead);

  //Values from LocalStorage
  // const filterData = localStorage.getItem("popupsData");
  const inputActive = sessionStorage.getItem("inputSet");
  let registeredModelValue = localStorage.getItem("registered_model");
  let registeredModelClaimValue = localStorage.getItem(
    "registered_model_claim"
  );
  const cpaValues = sessionStorage.getItem("cpaValues");
  const finalpolicytype = sessionStorage.getItem('inputs_policyType')

  const filterData = localStorage.getItem("popupsData");
  const carRegistered = localStorage.getItem("isCarRegistered");
  const radioValue = localStorage.getItem("car_Info");
  const carRegNO = localStorage.getItem("car_Intro");
  const finaladdon = sessionStorage.getItem("input_addons_check");
  const finalDiscount = sessionStorage.getItem("inputs_discount");
  const finalidvvalue = sessionStorage.getItem("inputs_IDV");
  const finaladdoninput = sessionStorage.getItem("check_inputs");
  const modelRegion = localStorage.getItem("regionCode");
  const masteridvalues = localStorage.getItem("master_ID");
  const rateValue = localStorage.getItem("ratesValues");
  const rsaInitIdv = localStorage.getItem("rsaIdvvalue");
  const requestSta = localStorage.getItem("apiLoaded");
  const tppdVal = sessionStorage.getItem("tppdVal");
  const prevOptedAddon = sessionStorage.getItem("prevAddons");
  const preCustDetails= localStorage.getItem('pre_CustomerDetials')

  // const finalPolicyType = sessionStorage.getItem('inputs_policyType')

  let registeredModelOwnershipValue = localStorage.getItem(
    "registered_model_ownership"
  );

  useEffect(() => {
    setInputStatus(inputActive);

    setPopUpsFilterData(JSON.parse(filterData));
    setRsaIdvValue(rsaInitIdv);
    setCarIsRegistered(JSON.parse(carRegistered));
    setDefaultRadioValue(JSON.parse(radioValue));
    setRegisteredModelValues(JSON.parse(registeredModelValue));
    setPrevClaimValue(registeredModelClaimValue);
    // setPolicyType(finalPolicyType)
    setManufactureYear(JSON.parse(radioValue).manufactureYear);
    carRegNO &&
      setCarRegistrationNumber(JSON.parse(carRegNO).registrationNumber);
    // console.log('im calling again and again',popUpsFilterData.policyData)
    setFinalAddons(JSON.parse(finaladdon));
setPreCustomerDetails(JSON.parse(preCustDetails))
setOldPolicyType(JSON.parse(radioValue).policy)
setFinalPolicyType(finalpolicytype)

    setInputDiscount(finalDiscount);
    setOwnership(registeredModelOwnershipValue);
    setNoChange(requestSta);
    setReasonCpaVal(cpaValues);
    setTppdChange(tppdVal);
    setPrevAddons(prevOptedAddon);
    setFinalIDVValue(finalidvvalue);
    setFinalAddonsInput(JSON.parse(finaladdoninput));
    setModelRegionId(JSON.parse(modelRegion));
    setInitialPolicyType(JSON.parse(radioValue));
    setMasterID(JSON.parse(masteridvalues));
    setRatesValues(JSON.parse(rateValue));
  }, [carRegNO, carRegistered, cpaValues, filterData, finalDiscount, finaladdon, finaladdoninput, finalidvvalue, finalpolicytype, inputActive, masteridvalues, modelRegion, preCustDetails, prevOptedAddon, radioValue, rateValue, registeredModelClaimValue, registeredModelOwnershipValue, registeredModelValue, requestSta, rsaInitIdv, tppdVal]);

  /* ----------------- Date Logics --------------------*/

  const userPrevPolicyExp =
    registeredModelValues && registeredModelValues.policyExp;

  const startDate = new Date(CarIsRegistered ? userPrevPolicyExp : Date.now());
  const policyStartDate = new Date(startDate.getTime() + 24 * 60 * 60 * 1000); // Add one day in milliseconds
  const policyEndDate = new Date(
    startDate.getTime() + 365 * 24 * 60 * 60 * 1000
  ); // Add one year in milliseconds
  const tppolicyEndDate = new Date(
    startDate.getTime() + 3 * 365 * 24 * 60 * 60 * 1000
  );
  // console.log('date',startDate)

  /* ---------------- Date Logics End Here ---------------------  */

  /* ---------------------- To Update the Policy type value  -------------------------*/
  const updateIdvTypeValueGodigit = (value) => {
    setIdVValue(value);
  };

  /* -------------------------------------------------------------- */

  useEffect(() => {
    const onStorage = () => {
      setJwtToken(localStorage.getItem("jwt_token"));
    };

    window.addEventListener("storage", onStorage);

    return () => {
      window.removeEventListener("storage", onStorage);
    };
  }, []);

  // console.log('isVlue',isValuesRead,carInfo,modelRegionId,masterID,inputStatus)
  useEffect(() => {
    dispatch(quoteActionsGodigit.setInputsRead());

    if (
      Object.keys(carInfo).length > 0 &&
      Object.keys(modelRegionId).length > 0 &&
      Object.keys(masterID).length > 0
    ) {
      dispatch(quoteActionsGodigit.setInputsRead());
      sessionStorage.setItem("inputSet", true);
    } else {
      dispatch(quoteActionsGodigit.setInputsRead());
      sessionStorage.setItem("inputSet", false);
    }
  }, [carInfo, dispatch, masterID, modelRegionId]);

  console.log("valu", finalPolicyType);

  useEffect(() => {
    setVehicleNumber(
      carRegistrationNumber.replace(
        /^([a-zA-Z]{2})(\d{2})([a-zA-Z]{2})(\d+)$/,
        "$1-$2-$3-$4"
      )
    );
  }, [carRegistrationNumber]);

  const fetchIdvQuoteGodigit= () => {
    dispatch(quoteActionsGodigit.setQuoteLoading(true));

     
      axios
        .post(
          `${API_ENDPOINT}/quotes/godigit`,
          {
            enquiryId: "GODIGIT_QQ_PVT_CAR_PACKAGE_01",
            insuranceProductCode:  finalPolicyType==undefined||null? CarIsRegistered?  (oldPolicyType=="comprehensive" && 20101) || (oldPolicyType=="thirdParty"&&20102) || (oldPolicyType=="ownDamage"&&20103)    : oldPolicyType=='bundled'?20101:20102 : CarIsRegistered?  (finalPolicyType=="comprehensive" && 20101) || (finalPolicyType=="thirdParty"&&20102) || (finalPolicyType=="ownDamage"&&20103)    :finalPolicyType=='bundled'?20101:20102  , 

            subInsuranceProductCode:CarIsRegistered
            ? "PB"
            : (finalPolicyType==undefined||null?oldPolicyType=="bundled":  finalPolicyType == "bundled")
            ? 31
            : 30,
            policyHolderType: "INDIVIDUAL",
            voluntaryDeductible:
              inputDiscount == null || undefined
                ? "ZERO"
                : inputDiscount == "None"
                ? "ZERO"
                : finalPolicyType == "thirdParty"
                ? "ZERO"
                : (inputDiscount == "2500" && "TWENTYFIVE_HUNDRED") ||
                  (inputDiscount == "5000" && "FIVE_THOUSAND") ||
                  (inputDiscount == "7500" && "SEVENTYFIVE_HUNDRED") ||
                  (inputDiscount == "15000" && "FIFTEEN_THOUSAND"), // discount two thousand and five hundred from Discount filter

            licensePlateNumber: CarIsRegistered
            ? carRegistrationNumber.replaceAll("-", "").toUpperCase()
            : `${modelRegionId.goDigit.regionCode.replace("-", "")}NEW`, // registration number if new vehicle : RTOCODE EX MH12NEW
            // vehicleMaincode:"1111810803", // MASTER ID goDigitVehicleCode
            vehicleMaincode: masterID.goDigitVehicleCode[0], // MASTER ID goDigitVehicleCode
            pinCode: null,
            "digitRegistrationDate":  initialPolicyType.registrationDate, // manufacture year
            "digitManufactureDate":  initialPolicyType.registrationDate, // manufacture year
            digitIsNewVehicle: CarIsRegistered ? false : true, // false old vehicle  // not working if i add true
            vehicleIDV: idvValue==(''||null||undefined)?null: (finalPolicyType==(null||undefined)?oldPolicyType == "thirdParty":finalPolicyType=='thirdParty')?null: idvValue, // idv filter type
            motorType: null,
            vehicleIdentificationNumber: null,
            engineNumber: null,
            isPreviousInsurerKnown: true,
            previousInsurerCode: 159,
            previousPolicyNumber: "8686948888564865",
            previousPolicyExpiryDate: CarIsRegistered
            ? userPrevPolicyExp
            : "", // from pop up expiry // from pop up expiry
            isClaimInLastYear: CarIsRegistered
              ? prevClaimValue == "Yes"
                ? true
                : false
              : false, // yes or no from pop up

            originalPreviousPolicyType:  "1OD_3TP",
           
            "previousPolicyType": finalPolicyType==undefined||null? CarIsRegistered?  (oldPolicyType=="comprehensive" && 20101) || (oldPolicyType=="thirdParty"&&20102) || (oldPolicyType=="ownDamage"&&20103)    : oldPolicyType=='bundled'?20101:20102 : CarIsRegistered?  (finalPolicyType=="comprehensive" && 20101) || (finalPolicyType=="thirdParty"&&20102) || (finalPolicyType=="ownDamage"&&20103)    :finalPolicyType=='bundled'?20101:20102 ,
            "previousNoClaimBonus": CarIsRegistered?  ((registeredModelValues&&registeredModelValues.prevNCB==(null || undefined))||prevClaimValue=='Yes')?0:`${registeredModelValues&&+registeredModelValues.prevNCB}`:'0', // previous ncb  need api
            currentThirdPartyPolicy: null,
            startDate: policyStartDate
            .toLocaleDateString("en-GB")
            .replace(/\//g, "-").split("-").reverse().join("-"), // cover from
          endDate: (!CarIsRegistered&&(finalPolicyType==(null||undefined)?oldPolicyType == "thirdParty":finalPolicyType=='thirdParty' ))?tppolicyEndDate.toLocaleDateString("en-GB")
          .replace(/\//g, "-")
          .split("-")
          .reverse()
          .join("-"): policyEndDate
            .toLocaleDateString("en-GB")
            .replace(/\//g, "-")
            .split("-")
            .reverse()
            .join("-"),

            isNCBTransfer: null,
            "thirdPartyLiabilityIsTppd": (finalPolicyType==undefined||null?oldPolicyType=="ownDamage":finalPolicyType=="ownDamage")?false:true,
            discountUserSpecialDiscountPercent: 0,
            discountDiscounts: [],
            surchargeLoadings: [],

            personalAccidentSelection:
              finalAddons == null || undefined
                ? true
                : finalAddons.ownerDriverPAcover
                ? true
                : false, // owner pa driver ? true:false
            personalAccidentInsuredAmount: null,
            personalAccidentCoverTerm: null,
            cngSelection:
              finalAddons == null || undefined
                ? false
                : finalAddons.externalBiFuelkit
                ? true
                : false, //bifuel is true
            cngInsuredAmount: (finalAddonsInput==null||undefined)?0: finalAddonsInput.checkInput3,

            electricalSelection:
              finalAddons == null || undefined
                ? false
                : finalAddons.electricalaccessories
                ? true
                : false, //electric true
            electricalInsuredAmount: (finalAddonsInput==null||undefined)?0: finalAddonsInput.checkInput1,
            nonElectricalSelection:
              finalAddons == null || undefined
                ? false
                : finalAddons.nonElectricalaccessories
                ? true
                : false, // non electric true :false
            nonElectricalInsuredAmount: (finalAddonsInput==null||undefined)?0: finalAddonsInput.checkInput2,
            partsDepreciationClaimsCovered: 'TWO', // zero depriciation
            partsDepreciationSelection:    finalAddons == null || undefined
            ? false
            : finalAddons.zeroDepreciation
            ? true
            : false,
            roadSideAssistanceSelection:
              finalAddons == null || undefined
                ? false
                : finalAddons.roadSideAssistance
                ? true
                : false, // road side true
            engineProtectionSelection:
              finalAddons == null || undefined
                ? false
                : finalAddons.engineProtect
                ? true
                : false, // engine protect true
            tyreProtectionSelection:
              finalAddons == null || undefined
                ? false
                : finalAddons.tyreProtection
                ? true
                : false, // tyre protec
            rimProtectionSelection: false,
            returnToInvoiceSelection:
              finalAddons == null || undefined
                ? false
                : finalAddons.returntoInvoice
                ? true
                : false, //return to invoice
            consumablesSelection:
              finalAddons == null || undefined
                ? false
                : finalAddons.consumables
                ? true
                : false, // consumble
            paidDriverLlSelection:false , // paid driver cover
            paidDriverLlInsuredCount:0, // if above true ? 1 : null
            unNamedPaidDriverSelection:finalAddons == null || undefined
            ? false
            : finalAddons.paidDrivercover
            ? true
            : false, // un named driver
            unNamedPaidDriverInsuredAmount: null,//** */
            unNamedPaidDriverInsuredCount: null,
             "unnamedPaxSelection":finalAddons == null || undefined
             ? false
             : finalAddons.unnamedpassengerPAcover
             ? true
             : false,
     "unnamedPaxInsuredAmount":100000,
     "unnamedPaxInsuredCount":4,

       "nominee": null,
       "dealer": null,
       "persons": [],







          },

          {
            headers: {
              authorization: jwtToken,
              "Content-Type": "application/json",
              accept: "application/json",
              "x-rsa-type": 2
            }
          }
        )
        .then((res) => {
            dispatch(quoteActionsGodigit.addFirstQuoteLoad(false));
            dispatch(quoteActionsGodigit.setApiDataValues(res.data.goDigitQuoteResponse));
            dispatch(quoteActionsGodigit.setQuoteLoading(false));
            const sessQuoApiVal = sessionStorage.getItem('sessQuoApiVal')
        if(sessQuoApiVal){
          const sessQuoApiValObj = JSON.parse(sessQuoApiVal)
          if(sessQuoApiValObj.goDigitQuoteResponse){
            sessQuoApiValObj.goDigitQuoteResponse =res.data.goDigitQuoteResponse
          }
          sessionStorage.setItem('sessQuoApiVal', JSON.stringify(sessQuoApiValObj))
        }
            // localStorage.setItem("apiData", JSON.stringify(res));
            localStorage.setItem("apiLoaded", true);
            
          })
    
          .catch((error) =>{
    
            if(error){
              dispatch(quoteActionsGodigit.setQuoteLoading(false));
              dispatch(quoteActionsGodigit.setApiDataValues(null));
      
      
              }
    
          
         
          console.log(error) });
        }

  return { fetchIdvQuoteGodigit, updateIdvTypeValueGodigit };
};
