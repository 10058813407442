import React, { useState, useEffect } from "react";
import LandingBackground from "../../../assets/Svg/LandingPageBck.svg";
import MaleSvg from '../../../assets/Vector/Health/male.svg'
import FemaleSvg from '../../../assets/Vector/Health/female.svg'
import { Link, useNavigate } from "react-router-dom";
import NavHeader from "../../NavHeader";
import FormBar from "../../FormBar";
import RadioPP from "../../customs/RadioPP";
import DropDownPP from "../../customs/DropDownPP";


const HealthIntro4 = () => {
  const navigate = useNavigate();

  const [closeNav, setCloseNav] = useState(false)
  const [selectedGender, setSelectedGender] = useState()
  const [showError, setShowError] = useState(false)
 const [inputs, setInputs] = useState({})
 const [diseaseError, setDiseaseError] = useState(false)
 const [personsValue, setPersonsValue] = useState({})

  const genderValue = localStorage.getItem('health_gender')
  const selectedPerson = localStorage.getItem('health_family_age')
  const selectedFamDis = localStorage.getItem('health_fam_Dis')
  const handleSubmit = (e) => {
    e.preventDefault();


         localStorage.setItem('health_fam_Dis',JSON.stringify(inputs))
        navigate("/healthinsurance/health-profile/prestep-5" );
  

  };

  const handleChange = (e) => {
    const name = e.target.name;

    const value = e.target.value;
    setInputs((values) => ({
      ...values,
      [name]: value
    }));

  
  };


useEffect(()=>{
  if(genderValue) {

    setSelectedGender(genderValue)
  }
  setPersonsValue(JSON.parse(selectedPerson))
},[genderValue, selectedPerson])


useEffect(()=>{
  selectedFamDis&&setInputs(JSON.parse(selectedFamDis))
},[selectedFamDis])


const memberList = [
    {
        id:1,
        name:'Self',

    },
    {
        id:2,
        name:'Wife'
    }
]


  return (
    <>
      <NavHeader dropClose={ [closeNav, setCloseNav] } />
      <main
        className="w-full pb-10 lg:pt-8 bg-no-repeat bg-cover   "
        style={{ backgroundImage: `url(${LandingBackground})` }}  onClick={()=>{
          setCloseNav(false)
        }}
      >
        
        <form onChange={handleChange}>
          <h1 className="text-xl lg:text-3xl text-[#293651] font-semibold  py-10   lg:pt-4 text-center">
            Health Insurance Quote
          </h1>
          {/* Steps Bar */}
          <div className="lg:w-[55%] rounded-t-md bg-[#aab8aa27] m-auto " >

<div className={`lg:w-[56.6%] rounded-t-md  bg-hero py-[3px]`}  style={  {transition: '36.6% 5s ease'}}></div>
</div>

          <div className=" justify-center bg-white mx-5 lg:m-auto shadow-[0px_0px_25px_rgba(0,0,0,0.1)] lg:drop-shadow-lg lg:w-[55%] h-[500px   px-4 py-5">
          
            <h1 className="mr-auto font-medium text-solid lg:text-xl py-4 pl-4">
            Any preexisting dieaseases?
            </h1>
            <hr className="text-[#DBDBDB] pb-4" />
            <div className="">
              <p className="px-5 text-[#7e8488] text-[15px]">
              By making this declaration, we can identify the optimal policy and prevent any claim rejections. Rest assured, your information will remain strictly confidential.
              </p>
              <div className="pl-5">

              {Object.keys(personsValue).map((key) => {
        if (personsValue[key] === true) {
          // return <p key={key}>{key}: {personsValue[`${key}_value`]}</p>;
          return  <div className="flex items-center ">
          <h1 className="pt-7 pr-5">
          {key}
          </h1>
          <DropDownPP
                        className="w-24 lg:w-44 border border-[#d7d5d5] rounded-md focus:outline-none px-3 py-2.5 "
                        labelClassName="hidden"
                        label="Disease"
                        id={key}
                        required={false}
                        value={inputs[`${key}`]}
                        options={[
                          "No Disease",
                          "Diabetes",
                          "Blood pressure",
                          "Heart diseases",
                          "Other conditions",
                         
                        ]}
                      />
      </div>
        }
        return null;
      })}
{/* {memberList.map((item)=>(
   
))} */}
           
              </div>

            </div>
         
          <h1 className={`${showError?'':'hidden'} text-center pt-2 text-sm text-[#eb5648] `}>Please Select Gender</h1>

          </div>
          <div className="flex justify-center space-x-5 pt-8 ">
            <Link to="/healthinsurance/health-profile/prestep-3">
            <button className="bg-[#293651]  w-24 py-2 rounded-full text-white">
              Back
            </button>
            </Link>
            <input
              type="submit"
              className="bg-hero w-24 py-2 rounded-full text-white cursor-pointer "
              onClick={handleSubmit}
              value="Next"
            />
          </div>
        </form>
      </main>
    </>
  );
};

export default HealthIntro4;
