import React, { useState, useEffect } from "react";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import LoadingSpinner from "../../paymentStatus/LoadingSpinner";
import {  PolicyFilterCard1 } from "./FilterTypes/PolicyFilterCard1";
import { PolicyFilterCard2 } from "./FilterTypes/PolicyFilterCard2";
import { PolicyFilterCard3 } from "./FilterTypes/PolicyFilterCard3";
import { PolicyFilterCard4 } from "./FilterTypes/PolicyFilterCard4";

export const FilterType = ({
  filterData,
  hideFilterTrue,
  closeState = "",
  custQuoteData,
  isQuoteLoad,
  isPopOpened,
  mission,
  loading,
  loadingData
}) => {
  const [policyData, setPolicyData] = useState();
  const [coverData, setCoverData] = useState();
  const [discountData, setDiscountData] = useState();
  const [addonsData, setAddonsData] = useState({});
  const [addonsChecked, setAddonsChecked] = useState('')
  const [filterPopData, setFilterPopData] = useState({})
  const [oldPolicyType, setOldPolicyType] = useState()

  const [policytypePlace, setPolicytypePlace] = useState()
  const [idvPlace, setIdvPlace] = useState()
  const [discountPlace, setDiscountPlace] = useState()
  const [addonsPlace, setAddonsPlace] = useState({})
const [checkedValue, setCheckedValue] = useState('0')
const [isThirdParty, setIsThirdParty] = useState(false)
  const [policy, setPolicy] = useState(false);
  // const [missionQuick, setMissionQuick] = mission
  const [cover, setCover] = useState(false);
  const [covCustIDV, setCovCustIDV] = useState();
  const [discount, setDiscount] = useState(false);
  const [addons, setAddons] = useState(false);
  const [minIDVValue, setMinIDVValue] = useState();
  const [maxIDVValue, setMaxIDVValue] = useState();
  const [isNewBike, setIsNewBike] = useState()
 const [isDetailsOpen, setIsDetailsOpen] = useState()
const [actionType, setActionType] = useState('')
const [checkData, setCheckData] = useState(false)
const [relianceIdvValues, setRelianceIdvValues] = useState([])
const [relMinIdvVal, setRelMinIdvVal] = useState()
const [relMaxIdvVal, setRelMaxIdvVal] = useState()
 const [missionAwake, setMissionAwake] = mission
const [loader, setLoader] = loading

const [loadApiData, setLoadApiData] = loadingData
  const [closePopUp, setClosePopUp] = closeState;
  const [initialLoader, setInitialLoader] = useState(false)
const [compareData, setCompareData] = useState({})
  const popData = (localStorage.getItem('bike_popupsData'))
  const isNewBikeValue = localStorage.getItem('isBikeRegistered')
  const actiontype = localStorage.getItem('action_type')
  const isNew = localStorage.getItem('isBikeRegistered')
  const addonsCheckData = localStorage.getItem("bike_input_addons_check")
  const checkedaddonsData = localStorage.getItem('checked_addons')
  const popup = sessionStorage.getItem('detail_open')
  const comparedata = localStorage.getItem('apiData')
  const initialLoad = localStorage.getItem('apiLoaded')
  const policyplaceholder = sessionStorage.getItem('bike_inputs_policyType')
  const radioValue = localStorage.getItem('bike_Info')
  const relianceidv = sessionStorage.getItem('bike_relianceIdvs')


  // const idvplaceholder= localStorage.getItem('inputs_IDV')
  // const discountplacehodler = localStorage.getItem('inputs_discount')
  // const addonsplaceholder = localStorage.getItem('input_addons_check')
  // console.log( Object.keys(addonsData) )
  
useEffect(()=>{
  setIsThirdParty( (policyplaceholder))
setOldPolicyType(JSON.parse(radioValue).policy)
setActionType(actiontype)

},[policyplaceholder,actiontype,radioValue])
  useEffect(()=>{
    setInitialLoader(initialLoad)

  },[initialLoad])
  
  
  // For Counting Number of Checked values in Addons
  useEffect(()=>{

    const selectAddonOption= ['']
    const trueValues = Object.values(addonsPlace)
    trueValues.forEach(function (x) { selectAddonOption[x] = (selectAddonOption[x] || 0) + 1; });
    setCheckedValue(selectAddonOption.true==undefined&&selectAddonOption.false==undefined?'1':selectAddonOption.true?selectAddonOption.true:0)
  },[addonsPlace])
  
  // console.log('place',policytypePlace,idvPlace==minIDVValue,discountPlace,addonsPlace,checkedValue)

  useEffect(()=>{

    setIsNewBike(isNewBikeValue)
    setIsDetailsOpen(popup)
   initialLoader=='true'&& setCompareData(JSON.parse(comparedata))
  },[isNewBikeValue,initialLoader,comparedata,popup,isNew])
  useEffect(()=>{

    if(initialLoader=='true'&&compareData&&Object.values(compareData).length>0){
      setCheckData(true)
    }else{
      setCheckData(false)
    }

  },[compareData,initialLoader])
  
  useEffect(()=>{
   
    setFilterPopData(JSON.parse(popData))
    setAddonsData(JSON.parse(addonsCheckData))
    setRelianceIdvValues(JSON.parse(relianceidv))


  },[popData, addonsCheckData, relianceidv])


  useEffect(() => {
    if (policy === false) {
      setClosePopUp(false);
    } else {
      setClosePopUp(true);
    }
  }, [policy]);
  useEffect(() => {
    if (cover === false) {
      setClosePopUp(false);
    } else {
      setClosePopUp(true);
    }
  }, [cover]);
  useEffect(() => {
    if (discount === false) {
      setClosePopUp(false);
    } else {
      setClosePopUp(true);
    }
  }, [discount]);
  useEffect(() => {
    if (addons === false) {
      setClosePopUp(false);
    } else {
      setClosePopUp(true);
    }
  }, [addons]);
  useEffect(() => {
   
    isPopOpened &&
      localStorage.setItem(
        "bike_popupsData",
        JSON.stringify({ policyData, coverData, discountData })
      );
      
    }, [policyData, coverData, discountData, isPopOpened]);
    useEffect(()=>{
  isPopOpened &&
    localStorage.setItem(
      "addonsPop",
      JSON.stringify( addonsData )
    );

},[addonsData,isPopOpened])

useEffect(()=>{
  setRelMinIdvVal(relianceIdvValues&&relianceIdvValues[0])
  setRelMaxIdvVal(relianceIdvValues&&relianceIdvValues[1])
},[relianceIdvValues])
  // console.log(policyData, coverData, discountData, addonsData);
  const a = "40";
  const b = "200";
  const c = "30";
  // console.log('chekcing',Object.keys(filterPopData).length!==0)

  // console.log('discount', discountData)
  // For Min & Max IDV Values for Cover
  useEffect(() => {
    const minValuesToCompare =checkData ? [ (compareData.data.rsaQuoteResponse==null?null:+compareData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.MINIMUM_IDV),
        (compareData.data.goDigitQuoteResponse==null?null:  +compareData.data.goDigitQuoteResponse.vehicle.vehicleIDV.minimumIdv),
        (compareData.data.relianceQuoteResponse==null?null: +relMinIdvVal)
            ] : ''

            const nonNullValues =checkData&& minValuesToCompare.filter(value => value !== null);
            const minIDv = checkData&&Math.min(...nonNullValues);
    const maxIDv = checkData
      ? Math.max(
        (compareData.data.rsaQuoteResponse==null?0:+compareData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.MAXIMUM_IDV),
        (compareData.data.goDigitQuoteResponse==null?0:  +compareData.data.goDigitQuoteResponse.vehicle.vehicleIDV.maximumIdv),
        (compareData.data.relianceQuoteResponse==null?0: +relMaxIdvVal)
        )
      : "";
    setMinIDVValue(minIDv!==Infinity?minIDv:0);
    setMaxIDVValue(maxIDv);
  }, [ loadApiData,compareData,checkData]);
// console.log('obj',(initialLoader))
const isMobile = window.innerWidth <= 1025;

  return (
    <>
    <div className={`lg:flex lg:flex-col lg:gap-y-5 ${isDetailsOpen=='true'?'z-10':''} relative   shrink grow w-full justify-evenly i ms-center space-y-5 lg:space-y-0`}>
      {filterData.map((item, key) => (
        <div className={`${hideFilterTrue&&key>2?'hidden':''} space-y-2`}>
          {/* {console.log('this is the key',item.id,key+1)} */}
          <h1 className={` ${(item.id==2||item.id==3)&& (isThirdParty == (undefined || null)
                      ? oldPolicyType !== "thirdParty"
                      : isThirdParty !== "thirdParty") ?'lg:text-[#5f5f62]' :(item.id==2||item.id==3)&&'text-[#cdc8c8d7]'} ${(item.id==1||item.id==4)&&'lg:text-[#777777]'} font-bold text-[14px]  -[#777777]  `}>
            {item.title}
          </h1>
          <div className="flex flex-col">
            <div className={`${(item.id==2||item.id==3 )&& (isThirdParty == (undefined || null)
                  ? oldPolicyType !== "thirdParty"
                  : isThirdParty !== "thirdParty")?'lg:bg-white rounded-lg':(item.id==2||item.id==3 )&&'lg:bg-[#cdc8c8d7] cursor-not-allowed text-[#665d5deb] '}  relative w-[ %]    lg:border lg:border-hero lg:rounded-lg flex shrink grow items-center `}  onClick={() => {
                 
                 if (item.id == "1") {
                    setPolicy(!policy);
                    setCover(false);
                    setDiscount(false);
                    setAddons(false);
                    setClosePopUp(true);
                  }

                  if (item.id == "2") {
                    (isThirdParty == (undefined || null)
                      ? oldPolicyType !== "thirdParty"
                      : isThirdParty !== "thirdParty")&&setCover(!cover);
                    setPolicy(false);
                    setDiscount(false);
                    setAddons(false);
                    setClosePopUp(true);
                  }
                  if (item.id == "3") {
                    (isThirdParty == (undefined || null)
                    ? oldPolicyType !== "thirdParty"
                    : isThirdParty !== "thirdParty")&& setDiscount(!discount);
                    setCover(false);
                    setPolicy(false);
                    setAddons(false);
                    setClosePopUp(true);
                  }
                  if (item.id == "4") {
                    setAddons(!addons);
                    setCover(false);
                    setDiscount(false);
                    setPolicy(false);
                    setClosePopUp(true);
                  }
                }}>
              {policy||cover||discount||addons ? (
                 (policy&&item.id=='1') || (cover&&item.id=='2') || (discount&&item.id=='3')  || (addons&&item.id=='4')  ?  <AiOutlineUp className="absolute z-10 right-2   lg:text-[#989898] " />: <AiOutlineDown className="absolute z-10 right-2   lg:text-[#989898] " />
              ) : (
             <AiOutlineDown className="absolute z-10 right-2   lg:text-[#989898] " />
              )}

             {/* {console.log('ffdf', (filterPopData.coverData!==(maxIDVValue && minIDVValue)&&filterPopData.coverData) || (filterPopData.coverData===minIDVValue?'Best Deal':'Lowest' )) } */}
              <input
                type="text"
                placeholder={item.placeHolder}
                autoComplete={false}
                
                className={`focus:outline-none rounded-lg  shrink w-full caret-white cursor-pointer capitalize block lg:w-40  xl:w-64  bg-[rgba(0,167,142,0)]   h-10 px-3 text-[15px]   relative`}
                
                  value={ filterPopData &&((key+1==1? policytypePlace&& policytypePlace.replace(/([A-Z])/g, ' $1').trim():'' )||(key+1==2 ? (isThirdParty == (undefined || null)
                  ? oldPolicyType !== "thirdParty"
                  : isThirdParty !== "thirdParty")?  (idvPlace):'Not Applicable'    :'' )||(key+1==3 ? (isThirdParty == (undefined || null)
                  ? oldPolicyType !== "thirdParty"
                  : isThirdParty !== "thirdParty")?discountPlace:'Not Applicable' :''  )||(key+1==4 ? `${checkedValue} Applied` :'' ))}
                    // (idvPlace==minIDVValue?'Lowest IDV' :'') ||(idvPlace==maxIDVValue?'Best Value':'')|| (idvPlace)
                // ||(key+1==3 || filterPopData.discountData)||(key+1==4 ||  filterPopData.policyData)
                // onClick={() => {
                //   if (item.id == "1") {
                //     setPolicy(true);
                //     setCover(false);
                //     setDiscount(false);
                //     setAddons(false);
                //     setClosePopUp(true);
                //   }

                //   if (item.id == "2") {
                //     setCover(true);
                //     setPolicy(false);
                //     setDiscount(false);
                //     setAddons(false);
                //     setClosePopUp(true);
                //   }
                //   if (item.id == "3") {
                //     setDiscount(true);
                //     setCover(false);
                //     setPolicy(false);
                //     setAddons(false);
                //     setClosePopUp(true);
                //   }
                //   if (item.id == "4") {
                //     setAddons(true);
                //     setCover(false);
                //     setDiscount(false);
                //     setPolicy(false);
                //     setClosePopUp(true);
                //   }
                // }}
                onChange={(e) => {
                  // alert(e.target.value);
                }}
              />
            </div>
            { item.id == "1" && (

              <div
                className={`${policy?'':'hidden'}`}>
              <div
                className={`absolute  z-20 py-2`}
                onChange={(e) => {
                  setPolicyData(e.target.id);
                }}
              >
                <PolicyFilterCard1
                  parentClass="w-80 lg:w-72"
                mission={[missionAwake, setMissionAwake]}
                loading ={ [loader, setLoader]}
                loadingData ={[loadApiData, setLoadApiData]}
                placeValue = {[policytypePlace, setPolicytypePlace] }
                  closeState={[policy, setPolicy]}
                  discData={[discountData, setDiscountData]}
                  filterCardData={[
                    {
                      id: "1",
                      title: "Policy Type",
                      type: "radio",
                      radioInputClass: "hidden",
                      buttonName: "Confirm",
                          radioData: isNewBike !=='true' ? [ 
                            // {
                            //       id: "all",
                            //       title: "All",
                            //       subTitle: "",
                            //       name: "policyType"
                            //     },
                                {
                                  id: "bundled",
                                  title: "Bundled",
                                  // subTitle:
                                  name: "policyType"
                                },
                                {
                                  id: "thirdParty",
                                  title: "Third Party",
                                  selectId:'1',

                                  subTitle:
                                  "This covers accidental damage to any property or any other person but does not cover damage to your car.",
                                  
                                  name: "policyType"
                                },
                                
                          ]: [
                            // {
                            //       id: "all",
                            //       title: "All",
                            //       subTitle: "",
                            //       name: "policyType"
                            //     },
                                {
                                  id: "comprehensive",
                                  title: "Comprehensive",
                               
                                  name: "policyType"
                                },
                                {
                                  id: "thirdParty",
                                  title: "Third Party",
                                  selectId:'1',
                                  subTitle:
                                  "This covers accidental damage to any property or any other person but does not cover damage to your car.",
                                  name: "policyType"
                                },
                                {
                                  id: "ownDamage",
                                  selectId:'2',
                                  title: "Own Damage",
                                  subTitle:
                                  "This covers only accidental damages to your car. ",

                                  name: "policyType"
                                },
                            ],
                      // radioData: [
                      //   {
                      //     id: "all",
                      //     title: "All",
                      //     subTitle: "",
                      //     name: "policyType"
                      //   },
                      //   {
                      //     id: "comprehensive",
                      //     title: "Comprehensive",
                      //     subTitle:
                      //       "This covers your car (around 50%) and any damage to any third party.",
                      //     name: "policyType"
                      //   },
                      //   {
                      //     id: "third Party",
                      //     title: "Third Party",
                      //     subTitle:
                      //       "This covers only accidental damage to any property or any other person but does not cover damage to your car.",
                      //     name: "policyType"
                      //   },
                      //   {
                      //     id: "ownDamage",
                      //     title: "Own Damage",
                      //     name: "policyType"
                      //   },
                      // ]
                    }
                  ]}
                />
              </div>
              </div>
            )}
            {item.id == "2" && (
              <div  className={` ${cover?'':'hidden'} `}>
              <div
                className={`absolute ${cover?'':'hidden'} z-20   py-2`}
                onChange={(e) => {
                  const names = e.target.id;
                  setCoverData(e.target.value);
                }}
              >
                <PolicyFilterCard2
                mission={[missionAwake, setMissionAwake]}
                  parentClass="w-80 lg:w-72"
                  closeState={[cover, setCover]}
                loading ={ [loader, setLoader]}
                loadingData ={[loadApiData, setLoadApiData]}
                placeValue={[idvPlace, setIdvPlace]}
                  covData={[coverData, setCoverData]}
                  custCovdata={[covCustIDV, setCovCustIDV]}
                  minIdv={minIDVValue}
                  maxIdv={maxIDVValue}
                  errorMessages2={covCustIDV < minIDVValue || covCustIDV > maxIDVValue
                    ? true
                    : false}
                  filterCardData={[
                    {
                      id: "2",
                      title: "Cover Amount (IDV)",
                      type: "radio2",
                      inputLabel: `Please enter an IDV between ${minIDVValue} and ${maxIDVValue}`,
                      // inputLabel: `Please enter an IDV between 11111 and 222222`,
                      radioInputClass: "block",
                      name: "customIDV",
                      buttonName: "Apply",
                      errorMessage:
                        covCustIDV < minIDVValue || covCustIDV > maxIDVValue
                          ? true
                          : false,
                      radioData: [
                        {
                          id: "4",
                          title: "Best Deal",
                          subTitle: "",
                          value: maxIDVValue,
                          name: "coverAmount"
                        },
                        {
                          id: "5",
                          title: "Lowest IDV",
                          subTitle: "",
                          value: minIDVValue,
                          name: "coverAmount"
                        },
                        {
                          id: "6",
                          title: "Choose your own IDV",
                          subTitle: "",
                          value: covCustIDV,
                          name: "coverAmount"
                        }
                      ]
                    }
                  ]}
                />
              </div>
              </div>
            )}
            { item.id == "3" && (
              <div className={`${discount?'':'hidden'}`}>
              <div
                className="absolute z-10 py-2"
                onChange={(e) => {
                  // setDiscountData(e.target.value);
                  // console.log(e.target.value);
                }}
              >
                <PolicyFilterCard3
                  parentClass="w-80 lg:w-64  "
                mission={[missionAwake, setMissionAwake]}
                loading ={ [loader, setLoader]}
                loadingData ={[loadApiData, setLoadApiData]}
placeValue={[discountPlace, setDiscountPlace] }
                  closeState={[discount, setDiscount]}
                  name="discount"
                  discData={[discountData, setDiscountData]}
                  filterCardData={[
                    {
                      id: "1",
                      title: "Discount",
                      type: "select",
                      inputLabel: "Please choose a Voluntary Deductible amount",
                      buttonName: "Apply",
                      selectData: ["None", "1000", "2000","2500", "3000"]
                    }
                  ]}
                />
              </div>
              </div>
            )}
            { item.id == "4" && (
              <div className={`${addons ? '':'hidden'} lg:hidden`}>
              <div
                className={`absolute      z-10   py-2`}
                onChange={(e) => {
                  setAddonsData(...e.target.id,e.target.id);
                }}
              >
                    {isMobile?
                <PolicyFilterCard4
                  parentClass="w-80 lg:w-96   h-96 overflow-y-scroll mobile-view   lg:left-[-123px]  "
                mission={[missionAwake, setMissionAwake]}
                loading ={ [loader, setLoader]}
                loadingData ={[loadApiData, setLoadApiData]}
                placeValue={[addonsPlace, setAddonsPlace]}
                  closeState={[addons, setAddons]}
                  addsData={[addonsChecked, setAddonsChecked]}
                 filterCardData={ (isThirdParty == (undefined || null)
                  ? oldPolicyType !== "thirdParty"
                  : isThirdParty !== "thirdParty")?[


                    {
                      id: "1",
                      title: "Add-Ons Cover",
                      type: "checkbox",
                      buttonName: ["Reset", "Apply"],
                      checkBoxData: [
                        // {
                        //   id: "1",
                        //   label: "PA Owner Drive   ",
                        //   name: "paOwnerDriver"
                        // },
                       
                       
                        
                        {
                          id: "13",
                          label: "Paid Driver cover",
                          name: "paidDrivercover",
                          checkalt:true,
                          checkText:'Suminsured Amount : 200000',
                          checkClass:'-right-4  -top-12 lg:-top-10'

                        },
                        
                        {
                          id: "14",
                          label: "Owner Driver PA cover",
                          name: "ownerDriverPAcover",
                          check:true,
                          checkText:'Compulsory as per Govt',
                          checkClass : '-right-5 -top-12 lg:-top-10'
                        },
                        {
                          id: "15",
                          label: "Unnamed passenger PA cover",
                          name: "unnamedpassengerPAcover",
                          checkalt:true,
                          checkText:'Suminsured Amount : 100000',
                          checkClass:'-right-4  -top-12 lg:-top-10'
                        },
                      

//                         • PA owner driver
// 		• 
// 		• 
// 		• Liability to paid driver  //notneed
// 		•  - default 1lac
// 		• Anti-theft device yes / no //not n3e  
// 		• 
// 		•
// 



                      
                      ]
                    }
                  ] :[

                    {
                      id: "1",
                      title: "Add-Ons Cover",
                      type: "checkbox",
                      buttonName: ["Reset", "Apply"],
                      checkBoxData: [
                      
                        {
                          id: "13",
                          label: "Paid Driver cover",
                          name: "paidDrivercover",
                          checkalt:true,
                          checkText:'Suminsured Amount : 200000',
                          checkClass:'-right-4  -top-12 lg:-top-10'

                        },
                        
                        {
                          id: "14",
                          label: "Owner Driver PA cover",
                          name: "ownerDriverPAcover",
                          check:true,
                          checkText:'Compulsory as per Govt',
                          checkClass : '-right-5 -top-12 lg:-top-10'
                        },
                        {
                          id: "15",
                          label: "Unnamed passenger PA cover",
                          name: "unnamedpassengerPAcover",
                          checkalt:true,
                          checkText:'Suminsured Amount : 100000',
                          checkClass:'-right-4  -top-12 lg:-top-10'
                        },
                      ]
                    }
                  ]
                
                }
                />:<div></div>}
              </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
    <div className="z-20">

      {/* <LoadingSpinner navTrue={false}/> */}
    </div>
    </>

  );
};




