import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import LandingBackground from "../../../../assets/Svg/LandingPageBck.svg";
import BikeInsuranceHeader from "../../../../UI/insurance/BikeInsuranceHeader";
import BikeInsuranceTracker from "../../../../UI/insurance/BikeInsuranceTracker";
import InsuranceHeader from "../../../../UI/insurance/InsuranceHeader";
import InsuranceTracker from "../../../../UI/insurance/InsuranceTracker";
import DropDownPP from "../../../customs/DropDownPP";
import InputPP from "../../../customs/InputPP";
import RadioPP from "../../../customs/RadioPP";
import NavHeader from "../../../NavHeader";

const BikeInfoStep1 = () => {
  // const [ownerName, setOwnerName] = useState("");
  const navigate = useNavigate();
  const [inputs, setInputs] =  useState({
    salutation:'',
    firstName:'',
    lastName:'',
    email:'',
    mobile:''

  });
  const [proceed, setProceed] = useState(false);

  const [ownerFirstNameError, setOwnerFirstNameError] = useState(false);
  const [ownerLastNameError, setOwnerLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [gstinError, setGstinError] = useState(false)
  const [salutationError, setSalutationError] = useState(false)
  const [mobileError, setMobileError] = useState(false);
  const [isPopWindowOpen, setIsPopWindowOpen] = useState(false)
  const [updateAPiValues, setUpdateAPiValues] = useState()

  const [registeredError, setRegisteredError] = useState(false);
  const [verifyNumberError, setVerifyNumberError] = useState(false);
  const [isVerifyTrue, setIsVerifyTrue] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [otpSentOnce, setOtpSentOnce] = useState(false);
  const [isVerifyOtp, setIsVerifyOtp] = useState(false);
const [typeOfPolicy, setTypeOfPolicy] = useState()
  const [testingInput, setTestingInput] = useState(false);
  const [relianceKycData, setRelianceKycData] = useState(null)

  const inputValues = localStorage.getItem("bike_step-1_Values");
  const reliaceKyc = localStorage.getItem("relianceKyc_apidata")


  const tileSelected = localStorage.getItem('tile_Type_price')
  var validRegex =/^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useEffect(()=>{
    setRelianceKycData(JSON.parse(reliaceKyc))

    setUpdateAPiValues(inputValues)
    setTypeOfPolicy(tileSelected)
  },[inputValues, reliaceKyc, tileSelected])
  const handleChange = (e) => {
    const name = e.target.name;

    const value = e.target.value;

    setInputs((values) => ({
      ...values,
      [name]: name === "registered" ? e.target.id : value
    }));
  };
  // const handleChange = (e) => {
  //   const name = e.target.name;

  //   const value = e.target.value;
  //   setInputs((values) => ({
  //     ...values,
  //     [name]: name === "isRegisteredAddress" ? e.target.checked : value
  //   }));
  // };


  useEffect(()=>{

    updateAPiValues==null&& typeOfPolicy=='Reliance'&& setInputs({
      ...inputs,
      firstName: relianceKycData&&relianceKycData.kyc_data.CKYC.result.PERSONAL_DETAILS.FNAME,
      lastName:relianceKycData&&relianceKycData.kyc_data.CKYC.result.PERSONAL_DETAILS.LNAME,
      email:relianceKycData&&relianceKycData.kyc_data.CKYC.result.PERSONAL_DETAILS.EMAIL,
      mobile:relianceKycData&&relianceKycData.kyc_data.CKYC.result.PERSONAL_DETAILS.MOB_NUM,
    });
  },[updateAPiValues, relianceKycData, typeOfPolicy])
  const handleSubmit = (e) => {
    e.preventDefault();

    // const letters = /^[a-zA-Z]*$/;
    // if (!inputs.ownerName.match(letters)) {
    //   setOwnerNameError(true);
    //   console.log("input is wrong");
    //   setProceed(false);
    // }

    // if (inputs.registered == undefined || inputs.registered == "") {
    //   setRegisteredError(true);
    // } else {
    //   setRegisteredError(false);
    // }
    if (/^[a-zA-Z ]*$/.test(inputs.firstName) == false) {
      setOwnerFirstNameError(true);
      setTestingInput(true);
    } else {
      // setOwnerFirstNameError(false);
    }
    if (/^[a-zA-Z ]*$/.test(inputs.lastName) == false) {
     setOwnerLastNameError(true);
     setTestingInput(true);
    } else {
    //  setOwnerLastNameError(false);
    }
    if (inputs.gstin == undefined || inputs.gstin == "") {
      setGstinError(true);
    } else {
      setGstinError(false);
    }
    if (inputs.salutation == undefined || inputs.gstin == "") {
      setSalutationError(true);
    } else {
      setSalutationError(false);
    }
    if (inputs.email == undefined || inputs.email == "") {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    if (inputs.mobile == undefined || inputs.mobile == "") {
      setMobileError(true);
    } 
    if (inputs.verifyNumber == undefined || inputs.verifyNumber == "") {
      setVerifyNumberError(true);
    } else {
      setVerifyNumberError(false);
    }



    if (
      Object.values(inputs).length >=5&&
      inputs.firstName!==null&& inputs.firstName.length > 0 &&
      inputs.lastName!==null&& inputs.lastName.length > 0 &&
      inputs.salutation.length > 1 &&
      inputs.mobile!==null&&  inputs.mobile.length > 1 &&
      inputs.email!==null&&    inputs.email.length > 1 
      // (typeOfPolicy==='RSA'&&inputs.mobile.length > 1) &&
      // (typeOfPolicy==='RSA'&&inputs.verifyNumberError.length > 1)
    ) {
      localStorage.setItem("bike_step", 1);
      localStorage.setItem("bike_step-1_Values", JSON.stringify(inputs));
    }
    if (Object.values(inputs).length >= 5) {
      localStorage.setItem("bike_step", 1);
      localStorage.setItem("bike_step-1_Values", JSON.stringify(inputs));
    }

    !ownerFirstNameError&&!ownerLastNameError&&!salutationError&&!mobileError&&!emailError&& navigate(proceed ? "/bikeinsurance/bike-profile/step-2" : "");
  };

  useEffect(() => {
    const inputValues = localStorage.getItem("bike_step-1_Values");
    inputValues && setInputs(JSON.parse(inputValues));
  }, []);

  useEffect(() => {
    if (/^[a-zA-Z ]*$/.test(inputs.firstName) == false||(/^[a-zA-Z ]*$/.test(inputs.lastName) == false)) {
      setOwnerFirstNameError(true);
      setProceed(false);
    }
    if (/^[a-zA-Z ]*$/.test(inputs.lastName) == false) {
      setProceed(false);
      setOwnerLastNameError(true);

    }
  }, [inputs, proceed]);

 
  useEffect(() => {

    if (
      Object.values(inputs).length >=5&&
      inputs.firstName!==null&& inputs.firstName.length > 1 &&
      inputs.lastName!==null&&inputs.lastName.length > 1 &&
      inputs.salutation.length > 1 &&
      inputs.mobile!==null&&  inputs.mobile.length > 1 &&
      inputs.email!==null&& inputs.email.length > 1
     
    ) {
      setProceed(true);
    }else{
      setProceed(false)
    }
    
    
  }, [inputs,ownerFirstNameError,ownerLastNameError,emailError,gstinError]);
  useEffect(() => {
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);

    if (inputs.verifyNumber == undefined || inputs.verifyNumber == "") {
      setIsVerifyOtp(false);
    } else {
      setIsVerifyOtp(true);
    }
    if (inputs.firstName == undefined || inputs.firstName == "") {
      setOwnerFirstNameError(true);
      // localStorage.removeItem("step-1_Values");
    } 
    else {
      setOwnerFirstNameError(false);
    }
    if (inputs.lastName == undefined || inputs.lastName == "") {
        setOwnerLastNameError(true);
      setProceed(false);

      // localStorage.removeItem("step-1_Values");
    } 
    else {
       setOwnerLastNameError(false);
    }
    if(proceed) {
       setOwnerLastNameError(false);
      setOwnerFirstNameError(false);

    }
  }, [isVerifyTrue,proceed, typeOfPolicy,inputs, isVerifyOtp]);



useEffect(()=>{
   /* --------------- Validating Mobile Number ------------------ */
 if (
  Object.keys(inputs).includes('mobile')&&
  inputs.mobile.length !==10
) {
  setMobileError(true)
}else if (Object.keys(inputs).includes('mobile')&&
inputs.mobile.length ==10){
  setMobileError(false)
}

/* ----------------- Validating Email Id ---------------------- */

if (
  Object.keys(inputs).includes('email')&&
  inputs.email.toLowerCase().match(validRegex)
) {
  // setProceed(true);
  setEmailError(false)
}else if(Object.keys(inputs).includes('email')) {
  // setProceed(false);
  setEmailError(true)
}
},[inputs, validRegex])

  const verifyMobileHandler = (e) => {
    e.preventDefault();
    if (inputs.mobile == undefined || inputs.mobile == "") {
      setIsVerifyTrue(false);
      setShowMessage(false);
    } else {
      setIsVerifyTrue(true);
      setShowMessage(true);
      setOtpSentOnce(true);
    }

    if (inputs.mobile == undefined || inputs.mobile == "") {
      setMobileError(true);
    } 
  };

  return (
    <>
      {/* <NavHeader /> */}
      <main
          className={`lg:w-full   overflow-x-hidden  lg:pt-16 bg-no-repeat bg-cover  ${isPopWindowOpen?'h-screen ':''} `}
        style={{ backgroundImage: `url(${LandingBackground})` }}
      >
        <BikeInsuranceHeader popwindow={[isPopWindowOpen, setIsPopWindowOpen] } />
        <div className="pt-5 lg:pt-10 pb-16 lg:pb-24">
          <BikeInsuranceTracker />
        </div>

        <form onChange={handleChange}  className={`${isPopWindowOpen?'hidden ':''}`}>
          <div className="slider-in justify-center bg-white mx-5 lg:m-auto drop-shadow-lg lg:w-[55%] overflow-x-hidden    rounded-xl p-4 ">
            <div className="flex items-center space-x-2 pb-4">
              <div className="w-6 h-6 lg:w-8 lg:h-8 bg-hero text-white rounded-full items-center flex justify-center">
                1
              </div>
              <h1 className="m  text-solid lg:text-xl  font-semibold">
                Bike Owner Details
              </h1>
            </div>
            <hr className="text-[#DBDBDB] pb-4" />
            <div className="">
              <div className="lg:mb-3">

              {/* <RadioPP
                title="Bike Registered In Company Name?"
                errorState={[registeredError, setRegisteredError]}
                value={inputs.registered}
                objValues={[
                  {
                    id: "yes",
                    name: "registered",
                    value: "Yes"
                  },
                  {
                    id: "no",
                    name: "registered",
                    value: "No"
                  }
                ]}
                /> */}
                </div>
              
                  {/* <InputPP
                  className="w-full lg:w-64   px-3 py-2 my-2 rounded-md"
                  labelClassName="text-[#959595] font-medium pl-1 "
                  id="gstin"
                      label="Enter the GST in for the company"
                  type="text"
                  parenetClassName={`${inputs.registered=='yes'?'':'hidden'}`}
                  placeholder="GST'in Number"
                  // state={[ownerName, setOwnerName]}
                  value={inputs.gstin}
                  changeFunction={handleChange}
                  errorState={ [gstinError, setGstinError]}
                  
                /> */}

              <div className="my-4 lg:flex lg:gap-5">
              <DropDownPP
                      className="w-24lg:w-44 border border-[#d7d5d5] rounded-md focus:outline-none px-3 py-2.5 my-2"
                      labelClassName="text-[#959595] font-medium pl-1"
                      // parentClassName={`${typeOfPolicy=='Reliance'?'':'hidden'}`}
errorState={[salutationError, setSalutationError]}
                      label="Salutation"
                      id="salutation"
                      required={true}
                      value={inputs.salutation}
                      options={[
                        "Mr",
                        "Mrs",
                        "Miss",
                        "Master",
                        
                      ]}
                      />
                <InputPP
                  className="w-full lg:w-64   px-3 py-2 my-2 rounded-md"
                  labelClassName="text-[#959595] font-medium pl-1 "
                  id="firstName"
                  label= 'Bike Owner FirstName (As Per RC Copy )'
                  type="text"
                  placeholder="Bike Owner FirstName"
                  // state={[ownerName, setOwnerName]}
                  value={inputs.firstName}
                  changeFunction={handleChange}
                  errorState={ [ownerFirstNameError, setOwnerFirstNameError]}
                  // disableTrue={relianceKycData&&relianceKycData.kyc_data.CKYC.result.PERSONAL_DETAILS.FNAME&&true}
                />

</div>
                <InputPP
                  className={`  w-full lg:w-64   px-3 py-2 my-2 rounded-md`}
                  labelClassName="text-[#959595] font-medium pl-1 "
                  // parenetClassName={`${typeOfPolicy=='RSA'?'':'hidden'}`}
                  id="lastName"
                  label="Bike Owner LastName "
                  type="text"
                  placeholder="Bike Owner LastName"
                  // state={[ownerName, setOwnerName]}
                  value={inputs.lastName}
                  changeFunction={handleChange}
                  errorState={ [ownerLastNameError, setOwnerLastNameError]}
                  // disableTrue={ relianceKycData&&relianceKycData.kyc_data.CKYC.result.PERSONAL_DETAILS.LNAME&&true}
                />
               
             
              <InputPP
                className="w-full    px-3 py-2 my-2 rounded-md"
                labelClassName="text-[#959595] font-medium pl-1 "

                id="email"
                label="Email address"
                type="email"
                placeholder="Enter Email"
                value={inputs.email }
                changeFunction={handleChange}
                errorState={[emailError, setEmailError]}
              />

<InputPP
                  className="w-full lg:w-80   px-3 py-2 my-2 rounded-md"
                  parenetClassName={``}

                  labelClassName="text-[#959595] font-medium pl-1 "
                  id="mobile"
                  label="Mobile number"
                  type="number"
                  placeholder="Enter Mobile Number"
                  value={inputs.mobile}
                  changeFunction={handleChange}
                  errorState={[mobileError, setMobileError]}
                />
            
            </div>
            {/* <h1>Please Fill All Inputs</h1> */}
          </div>
          <div className="flex justify-center space-x-5 py-8 ">
            {/* <Link to={proceed ? `/carinsurance/car-profile/step-2` : ""}>
              <input type="submit" /> */}
            <input
              type="submit"
              className="bg-hero w-max px-5 py-2 rounded-full text-white focus:outline-none cursor-pointer "
              onClick={handleSubmit}
              value=" Continue to Personal Details"
            />
            {/* </Link> */}
          </div>
        </form>
      </main>
    </>
  );
};

export default BikeInfoStep1;
