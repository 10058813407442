import BlogImg1 from "../assets/Vector/Blogs/BlogImg1.svg";
import BlogImg2 from "../assets/Vector/Blogs/BlogImg2.svg";

export const TrendingArticleData = [
  {
    id: "1",
    img: BlogImg1,
    title:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    date: "20-08-2022"
  },
  {
    id: "2",
    img: BlogImg2,
    title:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    date: "20-08-2022"
  },
  {
    id: "3",
    img: BlogImg1,
    title:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    date: "20-08-2022"
  },
  {
    id: "4",
    img: BlogImg2,
    title:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    date: "20-08-2022"
  },
  {
    id: "5",
    img: BlogImg1,
    title:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    date: "20-08-2022"
  }
];
