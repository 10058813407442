import React, { useState, useEffect } from "react";

import LandingBackground from "../assets/Svg/LandingPageBck.svg";
import NavHeader from "../components/NavHeader";
import ClaimSvg from "../assets/Vector/ClaimsVec/ClaimSvg.svg";
import ClaimSvg1 from "../assets/Vector/ClaimsVec/ClaimSvg1.svg";
import ClaimSvg2 from "../assets/Vector/ClaimsVec/ClaimSvg2.svg";
import ClaimSvg3 from "../assets/Vector/ClaimsVec/ClaimSvg3.svg";
import ClaimSvg4 from "../assets/Vector/ClaimsVec/ClaimSvg4.svg";
import ClaimSvg5 from "../assets/Vector/ClaimsVec/ClaimSvg5.svg";
import ClaimCard from "../UI/ClaimCard";
import ClaimAssistantCard from "../UI/ClaimAssistantCard";
import Footer from "../components/Footer";

const Claims = () => {
  const [closeNav, setCloseNav] = useState(false)
  return (
    <>
      <NavHeader   dropClose={ [closeNav, setCloseNav] }
 />
      <main
        className="w bg-[rgba(0,167,142,0.1)] lg:bg-none   bg-no-repeat bg-cover pt-4 lg:pt-20 lg:px-32"
        //   style="background-image:url('../assets/FooterSvg/irdaLogo.png);"
        style={{ backgroundImage: `url(${LandingBackground})` }}  onClick={()=>{
          setCloseNav(false)
        }}
      >
        <div className="lg:flex lg:gap-40 lg:justify-center  items-center">
          <div className="flex flex-col-reverse lg:flex-col">
            <div>
              <h1 className="text-2xl lg:text-5xl text-center lg:text-start py-4 lg:py-0 font-bold lg:leading-snug">
                We help you with your <br />
                <span className="text-hero"> Claim Assistance </span> needs
              </h1>
              <h2 className="text-center lg:text-start text-[#777777] lg:text-lg pb-4 lg:py-6">
                A POSP (Point of Salesperson) is the name <br /> given to an
                insurance agent who can sell <br /> specific insurance products
              </h2>
            </div>
            <img src={ClaimSvg} alt="ClaimSvg" className="m-auto pt-10 px-20" />
          </div>
          {/* <div className="flex flex-1" /> */}
          <div className="px-3 lg:px-0">
            <ClaimCard />
          </div>
        </div>
        <div className="py-20 lg:py-32 lg:pb-60 lg:m-auto ">
          <h1 className="font-bold text-hero text-xl lg:text-3xl text-center pb-7 lg:pb-10">
            How claim assistance works?
          </h1>
          <ClaimAssistantCard />
        </div>
      </main>

      {/* Global Footer */}
      <Footer />
    </>
  );
};

export default Claims;
