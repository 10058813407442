import React, { useState, useEffect } from "react";
import NavHeader from "../components/NavHeader";
import LandingBackground from "../assets/Svg/LandingPageBck.svg";
import SectionBck from "../UI/SectionBck";
import {
  AiOutlineWhatsApp,
  AiOutlineRight,
  AiOutlineMail
} from "react-icons/ai";
import { BiPhone } from "react-icons/bi";
import Footer from "../components/Footer";
import ContactUsCard from "../UI/ContactUsCard";
import axios from "axios";

const mobileClaimData = [
  {
    id: "1",
    emoji: BiPhone,
    title: "Call us ",
    bgColor: "rgba(254,206,76,0.1)",
    textColor: "#FECE4C"
  },
  {
    id: "2",
    emoji: AiOutlineWhatsApp,
    title: "Connect on Whatsapp",
    bgColor: "rgba(0,167,142,0.1)",
    textColor: "#00A78E"
  },
  {
    id: "3",
    emoji: AiOutlineMail,
    title: "Email us",
    bgColor: "rgba(108,99,255,0.1)",
    textColor: "#6C63FF"
  }
];

const Lms = () => {
  const [jwtToken, setJwtToken] = useState(localStorage.getItem('jwt_token'))
  const [closeNav, setCloseNav] = useState(false)

  // useEffect(() => {
  //   fetch("/contacts", {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       'authorization':jwtToken

  //     },

  //     //make sure to serialize your JSON body
  //     body: JSON.stringify({
  //       id: "1",
  //       name: "Preetham Nayak",
  //       email: "bpmnayak143@gmail.com",
  //       contactNo: "9381624403",
  //       message: "I have A Query Related Car Insurance",
  //       submitDate: "2022-09-12"
  //     })
  //   }).then((response) => {
  //     console.log(response);
  //     //do something awesome that makes the world a better place
  //   });
  // }, [jwtToken]);

// Jwt Token Generator
  useEffect(() => {
    const onStorage = () => {
     setJwtToken(localStorage.getItem('jwt_token'))
    };
  
    window.addEventListener('storage', onStorage);
  
    return () => {
        window.removeEventListener('storage', onStorage);
    };
  }, []);
  useEffect(() => {
    axios.get(`/blogs/1`, {
      headers: {
        Authorization: `${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }).then((response)=>{
      // regionCode.push(response.data[0].modelRegionId,response.data[0].stateId)

        })
        .catch((err)=>{
          console.log(err)
        })


 
  }, [jwtToken]);

  

  return (
    <>
      <NavHeader   dropClose={ [closeNav, setCloseNav] }
 />
      <main
        className="lg:w-full bg-[rgba(0,167,142,0.1)] lg:bg-none   bg-no-repeat bg-cover  "
        //   style="background-image:url('../assets/FooterSvg/irdaLogo.png);"
        style={{ backgroundImage: `url(${LandingBackground})` }}  onClick={()=>{
          setCloseNav(false)
        }}
      >
        <div className="hidden lg:block">
          {/* <SectionBck title="Contact Us" /> */}
        </div>
        <h1 className="lg:hidden font-bold text-2xl text-center pt-14 pb-6">
          Contact Us
        </h1>

        <h1 className="text-center text-hero font-bold text-3xl py-10 hidden lg:block">
          We would like to simplify everything about insurance <br /> with a
          seamless process.
        </h1>
 
        <div className="hidden lg:flex gap-40 m-auto justify-center  center px-40  pb-14">
        
         
          {/* <div className="flex flex-1"></div> */}
          <ContactUsCard apiPath='lms' />
        </div>
        <div className="lg:hidden">
          <ContactUsCard apiPath='lms' />
        </div>
        {/* Global Footer   */}
        <Footer />
      </main>
    </>
  );
};

export default Lms;

export const ContactCard = ({ contactData }) => {
  return (
    <div className="flex flex-col">
      {contactData.map((item) => (
        <div className="shadow-[8px_8px_20px_rgba(0,167,142,0.18)] bg-white w-80 p-4 space-y-4 rounded-2xl mb-12">
          <item.emoji className="bg-[rgba(0,167,142,0.1)] text-hero w-12 h-12 p-2 rounded-[10px]" />
          <div className="space-y-2">
            <h1 className="font-bold text-xl">{item.title}</h1>
            <h2 className="">{item.name}</h2>
          </div>
          <h2 className="font-medium text-lg pb-7">{item.contact}</h2>
        </div>
      ))}
    </div>
  );
};

export const MobileContactCard = () => {
  return (
    <div className="lg:hidden bg-white shadow-[8px_8px_20px_rgba(0,167,142,0.18)] p-4">
      {mobileClaimData.map((item, key) => (
        <div className="flex gap-4 items-center pb-4 pt-2 " key={item.id}>
          <item.emoji
            className={`bg-[${item.bgColor}] text-[${item.textColor}] w-10 text-2xl`}
          />
          <h3 className="font-bold "> {item.title} </h3>
          <AiOutlineRight className="ml-auto" />
        </div>
      ))}
    </div>
  );
};
