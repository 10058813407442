import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import NavHeader from "../../NavHeader";
import InputPP from "../../customs/InputPP";

const manufactureYear = [
  2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019,
  2020, 2021, 2022
];

const HealthIntro7 = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [proceed, setProceed] = useState(false);
  const [isDetails, setIsDetails] = useState(false);
  const [closeNav, setCloseNav] = useState(false)
  const [isRegisteredModel, setIsRegisteredModel] = useState(false);
  const [isregistered, setIsRegistered] = useState("");

  const [nameError, setNameError] = useState(false);
  const [mobileNoError, setMobileNoError] = useState(false);
  const [emailError, setEmailError] = useState(false);


  const handleChange = (e) => {
    const name = e.target.name;

    const value = e.target.value;
    setInputs((values) => ({
      ...values,
      [name]: value
    }));
  };
 
  const handleSubmit = (e) => {
    e.preventDefault();

   

    if (inputs.name == undefined || inputs.name == "") {
      setNameError(true);
    } 

    if (inputs.email == undefined || inputs.email == "") {
      setEmailError(true);
    } 
    if (inputs.mobileNo == undefined || inputs.mobileNo == "") {
      setMobileNoError(true);
    } 
  /// Storing Values in LocalStorage
    if (
      Object.values(inputs).length >= 3 
      &&!nameError&&!mobileNoError&&!emailError
    ) {
      // setProceed(true);
      localStorage.setItem("health_CustomerDetials", JSON.stringify(inputs));
    }

    // Object.keys(inputs).length==3  &&!nameError&&!mobileNoError&&!emailError&&navigate(
    //   proceed && isregistered == "true" 
    //     ? setIsRegisteredModel(true)
    //     : "/carinsurance/quote"
    // );
  
  };


  // useEffect(() => {
  //   if (isRegisteredModel == false) {
  //     setIsRegistered("false");
  //   }
  // }, [isRegisteredModel]);

  const inputValues = localStorage.getItem("health_CustomerDetials");
  var validRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  useEffect(() => {
    inputValues && setInputs(JSON.parse(inputValues));
  }, [inputValues]);
  
  // console.log('gg',inputs.mobileNo.length)
  useEffect(() => {




    if (
      Object.values(inputs).length >= 3 &&
      inputs.name.length > 0 
      &&!nameError&&!mobileNoError&&!emailError
    ) {
      setProceed(true);
    }
   /* --------------- Validating Mobile Number ------------------ */
    if (
      Object.keys(inputs).includes('name')&&
      inputs.name.length <1
    ) {
      // setProceed(false);
      setNameError(true)
      // localStorage.setItem("pre_CustomerDetials", JSON.stringify(inputs));
    }else if (Object.keys(inputs).includes('name')&&
    inputs.name.length >0){
      // setProceed(true);
      setNameError(false)
    }
    if (
      Object.keys(inputs).includes('mobileNo')&&
      inputs.mobileNo.length !==10
    ) {
      // setProceed(false);
      setMobileNoError(true)
      // localStorage.setItem("pre_CustomerDetials", JSON.stringify(inputs));
    }else if (Object.keys(inputs).includes('mobileNo')&&
    inputs.mobileNo.length ==10){
      // setProceed(true);
      setMobileNoError(false)
    }

/* ----------------- Validating Email Id ---------------------- */

    if (
      Object.keys(inputs).includes('email')&&
      inputs.email.toLowerCase().match(validRegex)
    ) {
      // setProceed(true);
      setEmailError(false)
    }else if(Object.keys(inputs).includes('email')) {
      // setProceed(false);
      setEmailError(true)
    }

    if (
      Object.keys(inputs).includes('name')&&
      inputs.name.length<1
    ) {
      // setProceed(true);
      setNameError(true)
    }else if(Object.keys(inputs).includes('name')) {
      // setProceed(false);
      setNameError(false)
    }
    


    
  }, [emailError, inputs, mobileNoError, nameError, validRegex]);



  return (
    <>
      <NavHeader   dropClose={ [closeNav, setCloseNav] }
 />
      
      <main
        className="w-full pb-10 lg:pt-8 bg-no-repeat bg-cover   "
       
      >
        <h1 className="text-xl lg:text-3xl text-[#293651] font-semibold  py-7   lg:pt-4 text-center">
        Health Insurance Quote

        </h1>
        

        <form onChange={handleChange}>
           {/* Steps Bar */}
         <div className="lg:w-[55%] rounded-t-md bg-[#aab8aa27] m-auto ">

<div className={`lg:w-[100%] rounded-t-md  bg-hero py-[3px]`}></div>
</div>

{/* ------------------------------------------ */}
          <div className=" justify-center bg-white mx-5 lg:m-auto shadow-[0px_0px_25px_rgba(0,0,0,0.1)] lg:drop-shadow-lg lg:w-[55%] h-[500px   px-4 py-5">
            <h1 className="mr-auto font-medium text-solid text-xl py-4 lg:pl-4">
              Your Detail
            </h1>
            
            <hr className="text-[#DBDBDB] pb-4" />
            <div className="lg:flex">
              <div className="space-y-5">
                <InputPP
                  className="w-full lg:w-80 px-3 py-2 my-2 rounded-md"
                  labelClassName="text-[#959595] font-medium pl-1 "
                  id="name"
                  label="Name"
                  type="text"
                  placeholder="Name"
                  value={inputs.name}
                  errorState={[nameError, setNameError]}
                />
                <InputPP
                  className="w-full lg:w-80lg:w-80   px-3 py-2 my-2 rounded-md"
                  labelClassName="text-[#959595] font-medium pl-1 "
                  id="mobileNo"
                  label="Mobile Number"
                  type="number"
                  maxlengths="10"
                  placeholder="Mobile Number"
                  value={inputs.mobileNo}
                  errorState={[mobileNoError, setMobileNoError]}
                />
                <InputPP
                  className="w-full lg:w-80  px-3 py-2 my-2 rounded-md"
                  labelClassName="text-[#959595] font-medium pl-1 "
                  id="email"
                  label="Email"
                  type="email"
                  placeholder="Email "
                  value={inputs.email}
                  errorState={[emailError, setEmailError]}
                />
              </div>

              <div className="flex flex-1"></div>
              {/* <img src={CustSvg} alt="CustSvg" className="w-1/3 hidden lg:block" /> */}
            </div>
          </div>
          <div className="flex justify-center space-x-5 pt-8 ">
            <Link to="/healthinsurance/health-profile/prestep-6">
              <button className="bg-[#293651]  w-24 py-2 rounded-full text-white">
                Back
              </button>
            </Link>

            <input
              type="submit"
              className="bg-hero w-32   py-2 rounded-full text-white "
              onClick={handleSubmit}
              value={`${isregistered ? "Next" : "View Quote"} `}
              // value="View Quote"
            />
          </div>
        </form>
      </main>
    </>
  );
};

export default HealthIntro7;
