import React, { useState } from "react";
import CircleLoader from "react-spinners/BeatLoader";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "orange"
};

const QuoteLoading = ({ navTrue = true,size=28 }) => {
  const [loading, setLoading] = useState(true);

  function parse(input) {
    function parseSingle(input) {
      var parts = input.split("||"),
        part,
        record = {};

      for (var i = 0; i < parts.length; i++) {
        part = parts[i].split("=");
        record[part[0]] = part[1];
      }

      return record;
    }

    var parts = input.split("++"),
      records = [];

    for (var i = 0; i < parts.length; i++) {
      records.push(parseSingle(parts[i]));
    }

    return records;
  }

 
  return (
    <div>
      <>
        <main
          className="lg:w-full py-20 lg:py-52 bg-no-repeat bg-cover overflow-x-hidden  "
          //   style={{ backgroundImage: `url(${LandingBackground})` }}
        >
          <div className="flex justify-center items-center ">
            <CircleLoader
              cssOverride={override}
              size={size}
              color={"#00A78E"}
              loading={loading}
              speedMultiplier={1.5}
            />
          
          </div>
        </main>
      </>
    </div>
  );
};

export default QuoteLoading;
