import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import InputPP from "../customs/InputPP";
import axios from "axios";

export const PospJoinForm = () => {
  const [datas, setDatas] = useState({});
  const [inputs, setInputs] = useState({});
  const [isChecked, setIsChecked] = useState(false)
  const [error, setError] = useState(false);
  const [jwtToken, setJwtToken] = useState(localStorage.getItem('jwt_token'))
  const [success, setSuccess] = useState(false);
/* -------------------  API ENDPOINT -------------- */
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
/* ---------------------------------- -------------- */
useEffect(() => {
  const onStorage = () => {
    setJwtToken(localStorage.getItem('jwt_token'));
  };

  window.addEventListener('storage', onStorage);

  return () => {
      window.removeEventListener('storage', onStorage);
  };
}, []);


  const handleChange = (e) => {
    const name = e.target.name;

    const value = e.target.value;
    setInputs((values) => ({
      ...values,
      [name]: value
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      inputs.user_Name == "" ||
      inputs.user_Email == "" ||
      inputs.user_Mobile == "" ||
      // inputs.user_Countrycode == "" ||
      inputs.pincode == "" ||
      inputs.user_Name == undefined ||
      inputs.user_Email == undefined ||
      // inputs.user_Countrycode == undefined ||
      inputs.user_Mobile == undefined ||
      inputs.pincode == undefined
    ) {
      setError(true);
    } else {
      setError(false);
    }

 Object.keys(inputs).length>=4 && isChecked&&  axios
      .post(
        `${API_ENDPOINT}/contacts`,
        {
          // id: 0,
          name: inputs.user_Name,
          email: inputs.user_Email,
          contactNo: inputs.user_Mobile,
          pincode: inputs.pincode
        },
        {
          headers: {
        Authorization: `${jwtToken}`,
        "Content-Type": "application/json",
            Accept: "application/json"
          }
        }
      )
      
      .then((res) => {

      setSuccess(true)} ) 
      .catch((error) => console.log(error));

    // Email Validation
    if (
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inputs.user_Email)
    ) {
      return true;
    }
    alert("You have entered an invalid email address!");
  };
  return (
    <div className="bg-hero lg:w-[350px] px-5 mx-4 lg:mx-0 py-2 h-max rounded-lg">
      <div className="flex items-center">
        <h2 className="text-xl font-bold text-white text-center w-full">
          JOIN AS POSP
        </h2>
      </div>
      <form onChange={handleChange}>
        <div className="bg-[rgba(0,0,0,0.2)] px-2 py-2 space-y-3 mt-4 ">
          <InputPP
            id="user_Name"
            className="w-full p-2 rounded-lg"
            labelClassName="hidden"
            type="text"
            placeholder="Your Name"
            brClass="hidden"
            required={false}
          />
          <InputPP
            id="user_Email"
            className="w-full p-3 rounded-lg"
            labelClassName="hidden"
            type="email"
            placeholder="Your Email ID"
            brClass="hidden"
            required={false}
          />
          <div className="flex space-x-4 w-full">
            {/* <InputPP
              id="user_Countrycode"
              className="w-16 p-3 rounded-lg"
              labelClassName=""
              type="number"
              placeholder="+91"
              brClass="hidden"
              required={false}
            /> */}
            <InputPP
              id="user_Mobile"
              className="w-full p-3 rounded-lg"
              labelClassName="hidden"
              type="number"
              placeholder="Your Phone number"
              brClass="hidden"
              parenetClassName="w-full"
              required={false}
            />
          </div>
          <InputPP
            id="pincode"
            className="w-full p-3 rounded-lg"
            labelClassName="hidden"
            type="number"
            placeholder="Pincode"
            brClass="hidden"
            required={false}
          />
          <input
            type="submit"
            className={`bg-[#FECE4C]  text-[#084F44] w-full p-2 rounded-lg text-lg font-bold focus:outline-none cursor-pointer ${!isChecked?'disabled ':''} `}
            onClick={handleSubmit}
            value="GET STARTED"
          />{" "}
        </div>
      {error && (
        <>
          <h3 className="text-[#f83e3ef7] font-extrabold text-center ">
            Please fill all the mandatory fields
          </h3>
        </>
      )}
      {success &&isChecked && (
        <h3 className="text-white pt-3 font-semibold text-center">
          ✅ Your form has been Submitted Successfully. our Support team will
          Contact you shortly{" "}
        </h3>
      )}
      <h3
        className={`text-white ${
          success&&isChecked ? "hidden" : ""
        } text-center text-sm py-2 flex items-center justify-center space-x-2`}
      >
        <input type="checkbox" id="isChecked" name="isChecked" onClick={(e)=>{
          setIsChecked(e.target.checked)
        }} />
        <h1 className="">
          I agree to the{" "}
          <Link to="/terms">
            {" "}
            <span className="underline">Terms & Conditions</span>{" "}
          </Link>
        </h1>
      </h3>
    </form>
    </div>

  );
};
