import React, { useState,useEffect } from "react";
import DigitLogo from "../../assets/Svg/BrandPartner/BrandPolicy-digit.svg";
import SundaramLogo from "../../assets/Svg/BrandPartner/BrandPolicy-sundaram.svg";
import RelianceLogo from "../../assets/Svg/BrandPartner/BrandPolicy-reliance.png";
import QuoteDetailPop from "../QuoteDetailPop";
import ScrollToTop from "../../components/ScrollToTop";
import QuoteDetailPopTemp from "../QuoteDetailPopTemp";

const BikeInsuranceHeader = ({popwindow=''}) => {
  const [isDetailPopUp, setIsDetailPopUp] = useState(false);
  const [isDetails, setIsDetails] = useState(false);
  const [tileType, setTileType] = useState('')
  const [tilePrice, setTilePrice] = useState()
  const [bikeModel, setBikeModel] = useState('')
  const [bikeInfo, setbikeInfo] = useState({})
  const [popData, setPopData] = useState()
const [popWindow, setPopWindow] = popwindow



  const tileValue = localStorage.getItem("tile_Type_price")
  const tileprice = localStorage.getItem("tile_price")
  const bikeName = localStorage.getItem("bike_Info_make_model")
  const bikeValues = localStorage.getItem("bike_Info")
  const popdata = localStorage.getItem("apiData")

  const formatter = new Intl.NumberFormat('en-IN');
  useEffect(()=>{
    setPopWindow(isDetailPopUp)
  },[isDetailPopUp])


  // (tileType == "Reliance" &&
  //         popData.data.relianceQuoteResponse.MotorPolicy.DerivedVehicleIDV) ||
  //       (tileType == "GoDigit" &&
  //         popData.data.goDigitQuoteResponse.vehicle.vehicleIDV.idv) ||
  //       (tileType == "RSA" &&
  //         popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.MODEL_IDV)
  useEffect(()=>{
    setTileType(tileValue)
    setTilePrice(tileprice)
    setBikeModel(bikeName)
    setbikeInfo(JSON.parse(bikeValues))
    setPopData(JSON.parse(popdata))
    
  },[tileValue,bikeName,bikeValues,popdata,tileprice])
  return (
    <>
      {isDetailPopUp && (
        <div className="relative">
          <div
            className={` absolute bg-[rgba(0,0,0,0.2)] w-full h-screen lg:-top-16  left z-10 overflow-hidden`}      onClick={() => {
              setIsDetailPopUp(!isDetailPopUp);
            }}
          >
            </div>
            <div
              className=" absolute flex flex-col w-full justify-center  items-center"
         
            >
              {/* <ScrollToTop> */}
              <QuoteDetailPopTemp detailsData={popData} detailsPop={[isDetailPopUp, setIsDetailPopUp]} />
              {/* </ScrollToTop> */}
            </div>
        </div>
      )}
      <div className={`px-3 lg:px-0 pt-14 lg:pt-20 relative `}>
        <div className="lg:w-[1000px] lg:m-auto  bg-hero h-[100px] relative flex justify-center items-center rounded-lg">
          <div className="absolute -top-[40%] flex items-center bg-white  w-[95%] h-[70px] m-auto px-6 rounded-md">
            <img src={(tileType == "Reliance"&&RelianceLogo) || (tileType == "GoDigit" && DigitLogo) || (tileType == "RSA"&& SundaramLogo )} alt="tile Logo" className="w-20 lg:w-max lg:pb-4" />
            <div className="ml-auto ">
              <h1 className="text-hero font-bold lg:text-2xl text-right">
                {`₹ ${formatter.format(tilePrice)}`}
              </h1>
              <p className="text-[#848484] text-[10px] text-base">
                (Price exclusive of GST)
              </p>
            </div>
          </div>
          <div className="w-full flex flex-col lg:flex-row  lg:items-center pt-5 px-4 text-white space-y-2 lg:space-y-0 lg:space-x-2">
            <h1 className="font-bold w-max lg:text-2xl">{bikeModel} </h1>
            <div className="flex items-center space-x-2">
              <div className="w-[8px] h-[8px] bg-[#FFBA00] rounded-full" />
              <p className="text-xs lg:text-xl">{bikeInfo.manufactureYear}</p>
              <div className="w-[8px] h-[8px] bg-[#FFBA00] rounded-full" />
              <p className="text-xs lg:text-xl">{bikeInfo.fuelType}</p>
            </div>
          </div>
          <h1
            className="bg-[rgba(223,255,250,0.15)]  mt-5 lg:mr-20 mx-4 py-1 text-white rounded-md font-medium cursor-pointer w-32 text-center"
            onClick={() => {
              setIsDetailPopUp(true);
            }}
          >
            Details
          </h1>
        </div>
      </div>
    </>
  );
};

export default BikeInsuranceHeader;
