import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import InsuranceHeader from "../../../../UI/insurance/InsuranceHeader";
import InsuranceTracker from "../../../../UI/insurance/InsuranceTracker";
import LandingBackground from "../../../../assets/Svg/LandingPageBck.svg";
import DropDownPP from "../../../customs/DropDownPP";
import InputPP from "../../../customs/InputPP";
import RadioPP from "../../../customs/RadioPP";
import NavHeader from "../../../NavHeader";
import axios from "axios";

const CarInfoStep1MobileVerify = () => {
  // const [ownerName, setOwnerName] = useState("");
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [proceed, setProceed] = useState(false);
  const [kycData1, setKycData1] = useState(null)

  const [ownerFirstNameError, setOwnerFirstNameError] = useState(false);
  const [ownerLastNameError, setOwnerLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [gstinError, setGstinError] = useState(false)
  const [salutationError, setSalutationError] = useState(false)
  const [mobileError, setMobileError] = useState(false);
  const [registeredError, setRegisteredError] = useState(false);
  const [verifyNumberOTPError, setverifyNumberOTPError] = useState(false);
  const [isVerifyTrue, setIsVerifyTrue] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [otpSentOnce, setOtpSentOnce] = useState(false);
  const [isVerifyOtpMessage, setisVerifyOtpMessage] = useState(false);
const [typeOfPolicy, setTypeOfPolicy] = useState()
  const [testingInput, setTestingInput] = useState(false);
  const [isPopWindowOpen, setIsPopWindowOpen] = useState(false)
  const [updateAPiValues, setUpdateAPiValues] = useState()
  const [jwtToken, setJwtToken] = useState(localStorage.getItem('jwt_token'))
  const [generatedOtpValue, setGeneratedOtpValue] = useState()
  const [wrongOtpError, setWrongOtpError] = useState(false)

  const tileSelected = localStorage.getItem('tile_Type_price')
  const rsaKyc = localStorage.getItem('rsaKyc_apidata')
  const inputValues = localStorage.getItem("step-1_Values");
const otpData = localStorage.getItem('Otp_verified')
  var validRegex =/^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const mobileNumberRegex = /^[6789]\d{9}$/;

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

  useEffect(()=>{
    setTypeOfPolicy(tileSelected)
    setUpdateAPiValues(inputValues)

  },[inputValues, tileSelected])
  // console.log(typeOfPolicy)
  useEffect(() => {
    const onStorage = () => {
      setJwtToken(localStorage.getItem('jwt_token'));
    };
  
    window.addEventListener('storage', onStorage);
  
    return () => {
        window.removeEventListener('storage', onStorage);
    };
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;

    const value = e.target.value;

    setInputs((values) => ({
      ...values,
      [name]: name === "registered" ? e.target.id : value
    }));
  };
  // const handleChange = (e) => {
  //   const name = e.target.name;

  //   const value = e.target.value;
  //   setInputs((values) => ({
  //     ...values,
  //     [name]: name === "isRegisteredAddress" ? e.target.checked : value
  //   }));
  // };


  useEffect(()=>{
    
    if(tileSelected=='RSA'){
      setKycData1(JSON.parse(rsaKyc))

    }
  },[rsaKyc, tileSelected])

   /* ------------------------- Prefilling Input Values  Based on Kyc data Start Here ----------------------- */
   useEffect(()=>{
     
    updateAPiValues==null&& typeOfPolicy=='RSA'&& setInputs({
      ...inputs,
      firstName: kycData1&&kycData1.firstName,
      lastName:kycData1&&kycData1.lastName,
      // email:kycData1&&kycData1.kyc_data.CKYC.result.PERSONAL_DETAILS.EMAIL,
      // mobile:kycData1&&kycData1.kyc_data.CKYC.result.PERSONAL_DETAILS.MOB_NUM,
    });
  },[updateAPiValues, kycData1, typeOfPolicy])
  
  /* ------------------------- Prefilling Input Values  Based on Kyc data End Here ----------------------- */
 




  
  const handleSubmit = (e) => {
    e.preventDefault();

    // const letters = /^[a-zA-Z]*$/;
    // if (!inputs.ownerName.match(letters)) {
    //   setOwnerNameError(true);
    //   console.log("input is wrong");
    //   setProceed(false);
    // }

    // if (inputs.registered == undefined || inputs.registered == "") {
    //   setRegisteredError(true);
    // } else {
    //   setRegisteredError(false);
    // }
    if (/^[a-zA-Z ]*$/.test(inputs.firstName) == false) {
      setOwnerFirstNameError(true);
      setTestingInput(true);
    } else {
      // setOwnerFirstNameError(false);
    }
    if (/^[a-zA-Z ]*$/.test(inputs.lastName) == false) {
     setOwnerLastNameError(true);
     setTestingInput(true);
    } else {
    //  setOwnerLastNameError(false);
    }
     
    if (inputs.salutation == undefined || inputs.gstin == "") {
      setSalutationError(true);
    } else {
      setSalutationError(false);
    }
    if (inputs.email == undefined || inputs.email == "") {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    if (inputs.mobile == undefined || inputs.mobile == "") {
      setMobileError(true);
    } 
   


    if (
      Object.values(inputs).length >=6&&
      inputs.firstName!==null&&  inputs.firstName.length > 0 &&
      inputs.lastName!==null&& inputs.lastName.length > 0 &&
       
      inputs.salutation.length > 1 &&
      inputs.mobile!==null&& inputs.mobile.length > 1 &&
      inputs.verifyNumberOTP!==null&& inputs.verifyNumberOTP.length > 1 &&
      inputs.email!==null&& inputs.email.length > 1 
     
    ) {
      setProceed(true);

      localStorage.setItem("step", 1);
      localStorage.setItem("step-1_Values", JSON.stringify(inputs));
    }


    // !salutationError && !ownerFirstNameError && !ownerLastNameError &&!mobileError && !emailError&&!verifyNumberOTPError&& true&& navigate(proceed ? "/carinsurance/car-profile/step-2" : "");
    !salutationError && !ownerFirstNameError && !ownerLastNameError &&!mobileError && !emailError&&!verifyNumberOTPError&& wrongOtpError&&(wrongOtpError.type=='success' || wrongOtpError.message=='Mobile no. already verified')&& navigate(proceed ? "/carinsurance/car-profile/step-2" : "");
  };

useEffect(()=>{
  if (
    Object.values(inputs).length >=6&&
    inputs.firstName!==null&&  inputs.firstName.length > 0 &&
    inputs.lastName!==null&& inputs.lastName.length > 0 &&
     
    inputs.salutation.length > 1 &&
    inputs.mobile!==null&& inputs.mobile.length > 1 &&
    inputs.verifyNumberOTP!==null&& inputs.verifyNumberOTP.length > 1 &&
    inputs.email!==null&& inputs.email.length > 1 
   
  ) {
        setProceed(true);
      }else{
        setProceed(false)
      }
      
},[inputs, wrongOtpError])
useEffect(()=>{
  inputValues && setInputs(JSON.parse(inputValues));

},[inputValues])

  useEffect(() => {
    otpData&&setWrongOtpError(JSON.parse(otpData).data )
  }, [otpData]);

  useEffect(() => {
    if (/^[a-zA-Z ]*$/.test(inputs.firstName) == false||(/^[a-zA-Z ]*$/.test(inputs.lastName) == false)) {
      setOwnerFirstNameError(true);
      setProceed(false);
    }
    if (/^[a-zA-Z ]*$/.test(inputs.lastName) == false) {
      setProceed(false);
      setOwnerLastNameError(true);

    }
  }, [inputs,typeOfPolicy, proceed]);




 
  // useEffect(() => {

  //   if (
  //     Object.values(inputs).length >=5&&
  //     inputs.firstName!==null&&  inputs.firstName.length > 0 &&
  //     inputs.lastName!==null&& inputs.lastName.length > 0 &&
       
  //     inputs.salutation.length > 1 &&
  //     inputs.mobile!==null&& inputs.mobile.length > 1 &&
  //     inputs.verifyNumberOTP!==null&& inputs.verifyNumberOTP.length > 1 &&
  //     inputs.email!==null&& inputs.email.length > 1 
     
  //   ) {
  //     setProceed(true);
  //   }else{
  //     setProceed(false)
  //   }
    
    
  // }, [inputs,ownerFirstNameError,ownerLastNameError,emailError,gstinError]);


  

  // useEffect(() => {
  //   if (
  //     Object.values(inputs).length >= 6 )
      
  //     // (typeOfPolicy==='RSA'&&(inputs.mobile.length > 10 || inputs.mobile.length<10 ))
  //    {
  //     setProceed(false);
  //    setMobileError(true)
  //   }
  //   else{
  //     setMobileError(false)
  //   }
  // }, [inputs,typeOfPolicy]);


  // useEffect(() => {
   
  //   if (Object.values(inputs).length >=6 &&!ownerFirstNameError&&!ownerLastNameError&&!emailError) {
  //     setProceed(true);
  //   }
  // }, [inputs,typeOfPolicy,ownerFirstNameError,ownerLastNameError,emailError,gstinError]);


  useEffect(() => {
  

    if(Object.keys(inputs).includes('mobile')) {
      if(inputs.mobile.length<10|| inputs.mobile.length>10) {
        setMobileError(true)
        setisVerifyOtpMessage(false);
        setIsVerifyTrue(false);
        setOtpSentOnce(false)

      }else {
        setMobileError(false)
        setisVerifyOtpMessage(true);

      }
    }

    if (inputs.verifyNumberOTP == undefined || inputs.verifyNumberOTP == ""  ) {
      setisVerifyOtpMessage(false);
    } else {
      setisVerifyOtpMessage(true);
    }
    if (inputs.firstName == undefined || inputs.firstName == "") {
      setOwnerFirstNameError(true);
      setProceed(false);
      // localStorage.removeItem("step-1_Values");
    } 
    else {
      setOwnerFirstNameError(false);
    }
    if (inputs.lastName == undefined || inputs.lastName == "") {
        setOwnerLastNameError(true);
      setProceed(false);

      // localStorage.removeItem("step-1_Values");
    } 
    else {
       setOwnerLastNameError(false);
    }
    if(proceed) {
       setOwnerLastNameError(false);
      setOwnerFirstNameError(false);

    }
  }, [isVerifyTrue,proceed, typeOfPolicy,inputs, isVerifyOtpMessage]);



useEffect(()=>{
  /* --------------- Validating Mobile Number ------------------ */
if (
 Object.keys(inputs).includes('mobile')&&
 !mobileNumberRegex.test(inputs.mobile)
) {
 setMobileError(true)
}else if (Object.keys(inputs).includes('mobile')&&
inputs.mobile.length ==10){
 setMobileError(false)
}

/* ----------------- Validating Email Id ---------------------- */

if (
 Object.keys(inputs).includes('email')&&
 inputs.email.toLowerCase().match(validRegex)
) {
 // setProceed(true);
 setEmailError(false)
}else if(Object.keys(inputs).includes('email')) {
 // setProceed(false);
 setEmailError(true)
}
},[inputs, validRegex])

  const verifyMobileHandler = (e) => {
    e.preventDefault();
   

  };


 

  /* ------------------------- Otp Verification & Sending APi Start Here ----------------- */

/* ------------------- APi for Sending OTp start Here -------------------- */
 
const sendOtpHandle = (e)=>{
  e.preventDefault()
  if (inputs.mobile == undefined || inputs.mobile == "") {
    setIsVerifyTrue(false);
    setShowMessage(false);
    setMobileError(true)
  } else {
    // setIsVerifyTrue(true);
    // setShowMessage(true);
  }
  
  if ((inputs.mobile.length<10|| inputs.mobile.length>10)) {
    setMobileError(true);
    setIsVerifyTrue(false);
    
  } else {
    // setMobileError(false);
    setIsVerifyTrue(true);
    setShowMessage(true);
    
    setOtpSentOnce(true);
  }
  // setOtpSentOnce(true);

  setTimeout(() => {
    setShowMessage(false);
  }, 5000);
 !mobileError&&axios
  .post(
    `${API_ENDPOINT}/otp/send?mobileNumber=${inputs.mobile}`,
    {
       
    },

    {
      headers: {
        authorization: jwtToken,
        "Content-Type": "application/json",
        accept: "application/json",
        // authkey:'381962AfREH8N29631edcf2P1',
        'Access-Control-Allow-Origin':'*'

      }
    }
  )
  .then((res) => {
   setGeneratedOtpValue(res)

    // console.log(res.data.length > 0);
  })

  .catch((error) => console.log(error));
  }
/* ------------------- APi for Sending OTp End Here -------------------- */
  
/* ---------------- Api For Verifying OTP Start here ---------------------- */
const verifyMobileOTP =(e)=>{
  e.preventDefault()

  if (inputs.verifyNumberOTP == undefined || inputs.verifyNumberOTP == "" ) {
    setverifyNumberOTPError(true);
  } else {
    setverifyNumberOTPError(false);
  }

 !verifyNumberOTPError&& !mobileError&&axios.get(`${API_ENDPOINT}/otp/verify?mobileNumber=${inputs.mobile}&otp=${inputs.verifyNumberOTP}  `, {
  headers: {
    Authorization: `${jwtToken}`,
    'Content-Type': 'application/json',
    authkey:'381962AfREH8N29631edcf2P1'

  },
}).then((res)=>{

localStorage.setItem('Otp_verified',JSON.stringify(res))
setWrongOtpError(res.data)
    })
    .catch((err)=>{
      console.log(err)
    })
  }

/* ---------------- Api For Verifying OTP End here ---------------------- */

/* ---------------- Api For Resend OTP Start here ---------------------- */
const resendOtpHandle = (e)=>{
  e.preventDefault()

  !mobileError&&axios.get(`${API_ENDPOINT}/otp/retry?mobileNumber=${inputs.mobile}
 `, {
  headers: {
    Authorization: `${jwtToken}`,
    'Content-Type': 'application/json',

  },
}).then((res)=>{
  setWrongOtpError(res.data)


console.log(res)
    })
    .catch((err)=>{
      console.log(err)
    })
  }

/* ---------------- Api For Resend OTP End here ---------------------- */

  /* ------------------------- Otp Verification & Sending APi End Here ----------------- */

  // console.log('test 1',wrongOtpError.type=='success')

// useEffect(()=>{
//   if (inputs.verifyNumberOTP == undefined || inputs.verifyNumberOTP == "" ) {
//     setverifyNumberOTPError(true);
//   } else {
//     setverifyNumberOTPError(false);
//   }


// },[inputs])

useEffect(()=>{
  if(wrongOtpError.message==
    "OTP verified success"){

    setverifyNumberOTPError(false);
  }
},[wrongOtpError])
  

  return (
    <>
      {/* <NavHeader /> */}
      <main
        className={`${typeOfPolicy=='RSA'?'':'hidden'}   ${isPopWindowOpen?'h-screen ':''}  lg:w-full overflow-x-hidden  lg:pt- bg-no-repeat bg-cover   text-sm lg:text-base   `}
        style={{ backgroundImage: `url(${LandingBackground})` }}
      >
        <div className="">

        <InsuranceHeader popwindow={[isPopWindowOpen, setIsPopWindowOpen] } />
        </div>
        <div className="pt-5 lg:pt- pb-16 lg:pb-">
          <InsuranceTracker />
        </div>

        <form onChange={handleChange}  className={`${isPopWindowOpen?'hidden ':''}`}>
          <div className="slider-in justify-center bg-white mx-5 lg:m-auto drop-shadow-lg lg:w-[55%] overflow-x-hidden    rounded-xl p-4 ">
            <div className="flex items-center space-x-2 pb-4">
              <div className="w-6 h-6 lg:w-8 lg:h-8 bg-hero text-white rounded-full items-center flex justify-center">
                1
              </div>
              <h1 className="m  text-solid lg:text-xl  font-semibold">
                Car Owner Details
              </h1>
            </div>
            <hr className="text-[#DBDBDB] pb-4" />
            <div className="">
              <div className="lg:mb-3">

              {/* <RadioPP
                title="Car Registered In Company Name?"
                errorState={[registeredError, setRegisteredError]}
                value={inputs.registered}
                objValues={[
                  {
                    id: "yes",
                    name: "registered",
                    value: "Yes"
                  },
                  {
                    id: "no",
                    name: "registered",
                    value: "No"
                  }
                ]}
                /> */}
                </div>
                  {/* <InputPP
                  className="w-full lg:w-64   px-3 py-2 my-2 rounded-md"
                  labelClassName="text-[#959595] font-medium pl-1 "
                  id="gstin"
                      label="Enter the GST in for the company"
                  type="text"
                  parenetClassName={`${inputs.registered=='yes'?'':'hidden'}`}
                  placeholder="GST'in Number"
                  // state={[ownerName, setOwnerName]}
                  value={inputs.gstin}
                  changeFunction={handleChange}
                  errorState={ [gstinError, setGstinError]}
                  
                /> */}

              <div className="my-4 lg:flex lg:gap-5">
              <DropDownPP
                      className="w-24lg:w-44 border border-[#d7d5d5] rounded-md focus:outline-none px-3 py-2.5 my-2"
                      labelClassName="text-[#959595] font-medium pl-1"
                      // parentClassName={`${typeOfPolicy=='Reliance'?'':'hidden'}`}
errorState={[salutationError, setSalutationError]}
                      label="Salutation"
                      id="salutation"
                      required={true}
                      value={inputs.salutation}
                      options={[
                        "Mr",
                        "Mrs",
                        "Miss",
                        "Master",
                        
                      ]}
                      />
                <InputPP
                  className="w-full lg:w-64   px-3 py-2 my-2 rounded-md"
                  labelClassName="text-[#959595] font-medium pl-1 "
                  id="firstName"
                  label= ' Car Owner FirstName (As Per RC Copy )'
                  type="text"
                  placeholder="Car Owner FirstName"
                  // state={[ownerName, setOwnerName]}
                  value={inputs.firstName}
                  changeFunction={handleChange}
                  errorState={ [ownerFirstNameError, setOwnerFirstNameError]}
                  
                  />
                  </div>

             
                <InputPP
                  className={`  w-full lg:w-64   px-3 py-2 my-2 rounded-md`}
                  labelClassName="text-[#959595] font-medium pl-1 "
                  // parenetClassName={`${typeOfPolicy=='RSA'?'':'hidden'}`}
                  id="lastName"
                  label="Car Owner LastName "
                  type="text"
                  placeholder="Car Owner LastName"
                  // state={[ownerName, setOwnerName]}
                  value={inputs.lastName}
                  changeFunction={handleChange}
                  errorState={ [ownerLastNameError, setOwnerLastNameError]}
                />
               
              <InputPP
                className="w-full    px-3 py-2 my-2 rounded-md"
                labelClassName="text-[#959595] font-medium pl-1 "

                id="email"
                label="Email address"
                type="email"
                placeholder="Enter Email"
                value={inputs.email }
                changeFunction={handleChange}
                errorState={[emailError, setEmailError]}
              />
              <div className="flex flex-col lg:flex-row lg:items-center lg:gap-5 ju">
                <InputPP
                  className="w-full lg:w-80   px-3 py-2 my-2 rounded-md"
                  parenetClassName={``}

                  labelClassName="text-[#959595] font-medium pl-1 "
                  id="mobile"
                  label="Mobile number"
                  type="number"
                  placeholder="Enter Mobile Number"
                  value={inputs.mobile}
                  changeFunction={handleChange}
                  errorState={[mobileError, setMobileError]}
                />
                {/* <div className=""></div> */}
                <button
                  className={`bg-hero ${wrongOtpError&&(wrongOtpError.type=='success' || wrongOtpError.message=='Mobile no. already verified'?'hidden':'')} ${otpSentOnce?'hidden':''} ${
                    isVerifyOtpMessage ? "" : ""
                  }  text-white px-4 mt-5 rounded-xl py-1`}
                  // onClick={sendOtpHandle}
                  onClick={sendOtpHandle}
                >
                Send Otp
                </button>
                <button
                  className={`bg-hero ${otpSentOnce?'':'hidden'} ${
                    isVerifyOtpMessage ? "" : ""
                  }  text-white px-4  mt-5 rounded-xl py-1`}
                  onClick={wrongOtpError&& wrongOtpError.message=='otp_expired'? sendOtpHandle:resendOtpHandle}
                >
                   Resend Otp 
                </button>
                
              </div>
              {showMessage && (
                <h2
                  className={`${
                    isVerifyOtpMessage ? "" : ""
                  } text-sm lg:text-base`}
                >
                  OTP sent successfully to your mobile number
                </h2>
              )}

              <div className="flex gap-5  items-center">


              {isVerifyTrue && (
                <InputPP
                  className="w-full lg:w-80   px-3 py-2 my-2 rounded-md"
                  labelClassName="hidden "
                  label="Mobile OTP"
                parenetClassName={``}

                  // required={true}
                  brClass="hidden"
                  id="verifyNumberOTP"
                  type="number"
                  placeholder="Enter OTP,Sent on your Mobile"
                  value={ inputs.verifyNumberOTP }
                  changeFunction={handleChange}
                  errorState={[verifyNumberOTPError, setverifyNumberOTPError]}
                  />
                  )}
              <button
                  className={`bg-hero  ${Object.keys(inputs).includes("verifyNumberOTP")&&inputs.verifyNumberOTP.length!==6?'cursor-not-allowed opacity-75':''} ${
                    otpSentOnce ? "" : " hidden"
                  }  text-white px-4   rounded-xl py-1`}
                  onClick={verifyMobileOTP}
                  disabled={Object.keys(inputs).includes("verifyNumberOTP")&&inputs.verifyNumberOTP.length!==6}
                  >
                 Verify
                </button>
            </div>
            {wrongOtpError&&['error','success'].includes(wrongOtpError&&wrongOtpError.type) &&<h1 className={`${wrongOtpError&&(wrongOtpError.type=='success' || wrongOtpError.message=='Mobile no. already verified') ?'text-hero':'text-[#b54a39]'}  text-sm`}> {  wrongOtpError.message=='OTP expired' ? 'OTP expired! Please Click on Resend OTP': wrongOtpError&&wrongOtpError.message   }</h1> }
            {verifyNumberOTPError&&Object.keys(inputs).length>=5&&<h1 className="text-[#b54a39]  text-sm">Please Verify Mobile Number</h1>}

            {/* <h1>Please Fill All Inputs</h1> */}
                  </div>
          </div>
          <div className="flex justify-center space-x-5 py-8 ">
           
            <input
              type="submit"
              className="bg-hero w-max px-5 py-2 rounded-full text-white focus:outline-none cursor-pointer "
              onClick={handleSubmit}
              value=" Continue to Personal Details"
            />
            {/* </Link> */}
          </div>
        </form>
      </main>
    </>
  );
};

export default CarInfoStep1MobileVerify;
