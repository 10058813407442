import React,{useState,useEffect} from "react";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaYoutube,
  FaLinkedinIn,
  FaInstagram
} from "react-icons/fa";
import Shield from "../assets/FooterSvg/shield.png";
import HeroLogo from "../assets/newlogo.jpg";

import IrdaLogo from "../assets/FooterSvg/irdaLogo.png";

const Insurance = [
  {
    title: "Car Insurance",
    link: "/carInsurance"
  },
  {
    title: "Bike Insurance",
    link: "/bikeInsurance"
  },
  {
    title: "Health insurance",
    link: "/healthInsurance"
  },
  {
    title: "Term insurance",
    link: "/termInsurance"
  }
];
const Company = [
  {
    title: "About Us",
    link: "/aboutus"
  },
  {
    title: "Articles",
    link: "/article"
  },
  {
    title: "Claims",
    link: "/claims"
  },
  {
    title: "Join as POSP",
    link: "/join-as-posp"
  },
  {
    title: "Privacy Policy",
    link: "/privacy"
  },
  {
    title: "Contact Us",
    link: "/contactus"
  }
];

const Footer = () => {

  const [isHomepage, setIsHomepage] = useState(false)

  useEffect(()=>{
    if(window.location.pathname=='/'){
      setIsHomepage(true)
    }else{
      setIsHomepage(false)
    }
  },[])
  return (
    <main className="overflow-hidden shadow-[0px_-16px_94px_rgba(0,167,142,0.2)]  ">
      <section className="lg:w-screen lg:flex justify-between px-6 lg:px-12 pt-20 xl:px-32      ">
        {/* section-1 */}
        <div className="">
          {/* Logo */}
          <img src={HeroLogo} alt="HeroLogo" className="w-40 lg:w-[10.5rem] lg:pb-3 " />


          <p
            className="w-64 py-2 leading-relaxed text-[#1f243c] font-inter font-semibold"
     
          >
            "Affordable Insurance Plans <br /> Just for you"
          </p>

          <div className="flex items-center space-x-4 lg:space-x-2 py-2">
            <div className="bg-hero p-1 rounded-[6px]">
              <FaFacebookF className="text-white text-md " />
            </div>
            <div className="bg-hero p-1 rounded-[6px]">
              <FaYoutube className="text-white text-md " />
            </div>
            <div className="bg-hero p-1 rounded-[6px]">
              <FaLinkedinIn className="text-white text-md " />
            </div>
            <div className="bg-hero p-1 rounded-[6px]">
              <FaInstagram className="text-white text-md " />
            </div>
          </div>
        </div>

        {/* section -2  Insurance */}
        <div className="py-5 lg:py-0">
          <h1 className="text-[#848488] text-lg font-semibold flex items-center pb-2">
            {" "}
            Insurance
          </h1>
          {Insurance.map((item) => (
            <Link to={item.link}>
              <div className="flex items-center   leading-8 cursor-pointer">
                {/* <img src={Shield} alt="Shield" className="flex" /> */}
                <h1
                  className="text-[#1f243c] font-inter"
              
                >
                  {item.title}
                </h1>
              </div>
            </Link>
          ))}
        </div>

        {/* section -3 Company  */}
        <div className="">
          <h1 className="text-[#848488] text-lg font-semibold flex items-center pb-2 ">
            {" "}
            Company
          </h1>
          {Company.map((item) => (
            <Link to={item.link}>
              <div className="flex items-center space-x- 2 leading-8 cursor-pointer">
                {/* <img src={Shield} alt="Shield" className="flex" /> */}
                <h1
                  className="text-[#1f243c]"
               
                >
                  {item.title}
                </h1>
              </div>
            </Link>
          ))}
        </div>

        {/* section - 4 */}
        <div className="text-solid text-sm pt-7 lg:pt-0 leading-loose lg:leading-6">
          <img src={IrdaLogo} alt="IrdaLogo" className="pb-2 lg:pb-0" />

          <h2 className="text-hero font-semibold text-base py-1">
            SmartTech Insurance Brokers LLP
          </h2>
          <p className="">
            IRDAI Registration No : <span className="font-semibold">731</span>{" "}
          </p>
          <p className="">
            Valid till : <span className="font-semibold">23/11/2026</span>{" "}
          </p>
          <p className="">
            License category :{" "}
            <span className="font-semibold">
              Direct Broker <br />
              (Life and General){" "}
            </span>{" "}
          </p>
        </div>
      </section>

      <div className="w-full lg:flex  bg-hero mt-10 lg:px-32 py-4 text-white ">
        <h1 className="text-[11px] text-center lg:text-base pb-3 lg:pb-0">
          © 2023 SmartTech Insurance Brokers LLP, All Rights Reserved.
        </h1>
       
        <div className="lg:flex flex-1 " />
        <div className="flex space-x-4 lg:space-x-7 overflow-x-hidden  justify-center text-[11px] lg:text-base">
          <Link to="/privacy">
            {" "}
            <h1 className="">Privacy Policy</h1>{" "}
          </Link>
          <Link to="/terms">
            {" "}
            <h1 className="">Terms & Conditions</h1>
          </Link>
        </div>
      </div>
     
      <div className={` ${isHomepage?'':'hidden'}  text-center text-xs lg:text-sm text-[#3e3f41c5] px-5 py-3   lg:px-20 `}>
        <h1  className="">Website Approved by IRDA(Govt of India) Reg No.IRDA/DB821/20 Valid till: 23-11-2026 <br />

*Insurance is the subject matter of solicitation. Visitors are hereby informed that their information submitted on the website may be shared with insurers. The product information for comparison displayed on this website is of the insurers with whom our company has an agreement.Product information is authentic and solely based on the information received from the Insurer. <br />

*The information provided on this website/page is for information purpose only. Policymart does not in any form or manner endorse the information so provided on the website and strives to provide factual and unbiased information to customers to assist in making informed insurance choices.</h1>

<p className="">
  © 2023 SmartTech Insurance Brokers LLP. All rights reserved. </p>

<p className="">
  Policymart and Policymart Logo are registered trademarks of SmartTech Insurance Brokers LLP. </p>
      </div>


  
    </main>
  );
};

export default Footer;
