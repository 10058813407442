import React,{useState,useEffect,useRef} from "react";

import { Link, useNavigate } from "react-router-dom";
import { AiOutlineRight } from "react-icons/ai";
import parse from "html-react-parser";
import BlogImg1 from "../assets/Vector/Blogs/BlogImg1.svg";
import BlogImg2 from "../assets/Vector/Blogs/BlogImg2.svg";

import { FaCalendarAlt } from "react-icons/fa";
import BlogsPagination from "../components/pagination/BlogsPagination";
const BlogCard = ({ blogData, mainClass = "lg:flex-row", hideTag }) => {
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(true);

  const cardRef = useRef(null);
  const [showCard, setShowCard] = useState(false);

  useEffect(() => {
    const aboutObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setShowCard(true);
          aboutObserver.unobserve(entry.target);
        }
      });
    }, {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    });

    if (cardRef.current) {
      aboutObserver.observe(cardRef.current);
    }

    

    return () => {
      aboutObserver.disconnect();
    };
  }, []);
  return (
    <div>
      <div className="block ">
        {blogData.map((item, key) => (
          <>
            <div
              className={` ${
                key > 5 && showMore ? "  " : ""
              }  ${showCard?`article    card${key+1}`:'cls'}  pb-6 cursor-pointer `} key={key} ref={cardRef}
            >
              <div
                className={`lg:w-max ${
                  item.isArticle ? "p-1" : "p-8"
                } flex  flex-col ${mainClass} h-max  lg:space-x-6 items-center  bg-white shadow-[8px_8px_36px_rgba(0,167,142,0.18)] rounded-lg mx-3 lg:mx-0 `}
              >
                <img
                  src={item.imgUrl}
                  alt={item.imgUrl}
                  className="w-[320px] h-[221px] lg:pr-4 rounded-xl"
                />
                <div className="w-full ">
                  <div className="flex flex-1"></div>
                  {/* For Desktop Date  */}
                  <div className="hidden lg:hidden items-center justify-end  space-x-2 ">
                    <FaCalendarAlt className="text-hero text-lg" />
                    <p className="text-xs ">{item.date}</p>
                  </div>
                  <h1
                    className={`text-[#293651]  ${
                      item.isArticle ? "hidden" : ""
                    } font-semibold lg:text-lg pt-3 pb-1`}
                  >
                    {parse(item.title)}
                  </h1>
                  <button
                    className={`bg-[#E6F6F4]  ${
                      item.isArticle ? "hidden lg:block" : "hidden"
                    }  lg:mt-3 capitalize text-hero text-sm font-semibold p-1 rounded-3xl px-4`}
                  >
                    {item.category}
                  </button>
                  <div className="flex items-center">

                  <p
                    className={` ${
                      item.isArticle
                        ? "lg:text-lg lg:pb-4 lg:pt-3 text-[#4A4949] lg:w-[15rem] text-start"
                        : "lg:text-sm text-solid lg:w-[357px] "
                    }  text-xs   leading-6 `}
                  >
                    {item.isArticle ? parse(item.intro) : parse(item.subTitle)}
                  </p>
                    {/* <div className="flex flex-1"></div> */}
                  <AiOutlineRight className={`${item.isArticle?'bg-[#BCE7E0] hidden lg:block font-bold text-hero p-2.5 mr- text-4xl rounded-full':'hidden'}`}/>
                  </div>

                </div>
                <div className={`flex ${hideTag} w-full pt-4 lg:hidden `}>
                  <button className="bg-[#E6F6F4] capitalize text-hero text-sm font-semibold p-1 rounded-3xl px-4">
                    {item.category}
                  </button>
                  <div className="flex flex-1"></div>
                  <div className="  items-center justify-end  hidden space-x-2 ">
                    <FaCalendarAlt className="text-hero text-lg" />
                    <p className="text-xs ">{item.date}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden ">
              {key > 6 && key < 8 && (
                <div
                  className={`  ${showMore ? "" : "hidden"}   `}
                  onClick={() => {
                    setShowMore(false);
                  }}
                >
                  <button className="flex justify-center m-auto w-32 py-2 rounded-full bg-hero text-white">
                    Load More
                  </button>
                </div>
              )}
            </div>
          </>
        ))}
      </div>
      {/* <div className="lg:hidden">
        <BlogsPagination />
      </div> */}

      {/* 
      {blogData.length >= 4 && (
        <button className="flex justify-center m-auto w-32 py-2 rounded-full bg-hero text-white">
          Load More
        </button>
      )}
      )} */}
    </div>
  );
};

export default BlogCard;

const SingleBlogCard = ({ blogData }) => {
  return (
    <>
      {blogData.map((item, key) => (
        <div className={`  pb-6 cursor-pointer`}>
          <div className="w-max p-4 flex space-x-6 items-center  bg-white shadow-[8px_8px_36px_rgba(0,167,142,0.18)] rounded-lg ">
            <img src={item.imgUrl} alt={item.imgUrl} />
            <div className="w-full ">
              <div className="flex flex-1"></div>
              <div className="flex items-center justify-end  space-x-2 ">
                <FaCalendarAlt className="text-hero text-lg" />
                <p className="text-xs ">{item.date}</p>
              </div>
              <h1 className="text-[#293651] font-semibold text-lg pt-3 pb-1">
                {item.title}
              </h1>
              <p className="text-sm text-solid w-[357px] leading-6 ">
                {item.subTitle}
              </p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
