import React, { useState, useEffect } from "react";
import Carousel, { consts } from "react-elastic-carousel";
import testimonials from "../../data/TestimonialData";
import { FaArrowLeft, FaArrowRight, FaRegCircle } from "react-icons/fa";
import { VscCircleLargeFilled } from "react-icons/vsc";
function myArrow({ type, onClick, isEdge }) {
  const pointer =
    type === consts.PREV ? (
      <FaArrowLeft className="bg-[#00A78E] w-10 text-white h-10 rounded-full p-2" />
    ) : (
      <FaArrowRight className="bg-[#00A78E] text-white w-10 h-10 rounded-full p-2" />
    );
  return (
    <button onClick={onClick} disabled={isEdge}>
      {pointer}
    </button>
  );
}

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 1 },
  { width: 768, itemsToShow: 1 },
  { width: 1200, itemsToShow: 3 }
];

const Testimonial = () => {
  return (
    <section className=" bg-[#bebfc43b] px-3 lg:px-0">
      <div className="py-10 lg:py-20">
        <h2 className="text-[#1f243c] font-bold text-xl lg:text-3xl  text-center pb-14">
          Check what’s client <br className="lg:hidden" /> say about us
        </h2>

        <Carousel
          breakPoints={breakPoints}
          className={` lg:p-10`}
          renderArrow={myArrow}
          // pagination={false}
          showArrows={false}
          renderPagination={({ pages, activePage, onClick }) => {
            return (
              <div className="flex space-x-1">
                {pages.map((page) => {
                  const isActivePage = activePage === page;
                  return (
                    <VscCircleLargeFilled
                      key={page}
                      onClick={() => onClick(page)}
                      active={isActivePage}
                      className={` ${
                        isActivePage ? "text-hero" : "text-solid"
                      } `}
                    />
                  );
                })}
              </div>
            );
          }}
        >
          {testimonials.map((item, key) => (
            <div
              key={key}
              className="bg-white w-full lg:w-[400px] lg:h-[300px] p-2 lg:px-5 py-2 mb-10 shadow-lg rounded-2xl "
            >
              <h2 className="text-hero text-center font-medium text-base py-2">
                {item.name}
              </h2>
              <h2 className="text-[#4A4949] text-sm text-center pb-4 ">
                {item.title}
              </h2>
              <h1 className=" text-center m-auto text-[#545454]">
                {item.quote}
              </h1>
              <h2 className="text-xl text-center pt-2">{item.rating}</h2>
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default Testimonial;
