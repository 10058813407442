import React, { useState, useEffect } from "react";
import { addonsData } from '../../data/AddonsData';
import { AiOutlineCheck, AiOutlineClose, AiOutlineRight } from "react-icons/ai";
import { IoIosInformation } from "react-icons/io";



export   const TickCrossComponent = ({ isTick }) => {
  if (isTick) {
    return  <AiOutlineCheck
    className= "text-hero"
  /> // Render tick mark
  } else {
    return <AiOutlineClose
    className="text-[#ed3d3d]"
  />; // Render cross mark
  }
}


export const FeaturesContainer = ({ typeTiles,values,isshow=true,isAddonShow=false }) => {


    const [showMore, setShowMore] = useState(true);
    const [checkedAddons, setCheckedAddons] = useState({})
    const [discountValue, setDiscountValue] = useState()
    const [arrayAddons, setArrayAddons] = useState([])
    const [name, setName] = useState([])
  const [popData, setPopData] = useState();

    const [value, setValue] = useState([])
  const [showDefaultAdd, setShowDefaultAdd] = useState(true)
  const [openDialog, setOpenDialog] = useState(false)
const [addonValues, setAddonValues] = useState()
const [policyType, setPolicyType] = useState();

    const [listOfAddons, setListOfAddons] = useState([])
    const [discShowOption, setDiscShowOption] = useState(false)
    const [registeredModel, setRegisteredModel] = useState(false)
    const [finalPolicyType, setFinalPolicyType] = useState()
    const [oldPolicyType, setOldPolicyType] = useState()
const [isVehicleRegistered, setIsVehicleRegistered] = useState()
const [actionType, setActionType] = useState()
    const addonsplaceholder = sessionStorage.getItem('input_addons_check')
    const addonsplaceholder2 = sessionStorage.getItem('bike_input_addons_check')
  const carDiscountplacehodler = sessionStorage.getItem('inputs_discount')
  const bikeDiscountplacehodler = sessionStorage.getItem('bike_inputs_discount')
    const radioValue = localStorage.getItem('car_Info') 
  const apidata = localStorage.getItem('apiData')

    const radioValue2 = localStorage.getItem('bike_Info') 
    const finalpolicytype = sessionStorage.getItem('inputs_policyType')
    const finalpolicytype2 = sessionStorage.getItem('bike_inputs_policyType')
const carRegistered = localStorage.getItem('isCarRegistered')
const bikeRegistered = localStorage.getItem('isBikeRegistered')
const registeredmodel = localStorage.getItem('registered_model_claim')
const registeredmodel2 = localStorage.getItem('bike_registered_model_claim')
  const actionValue = sessionStorage.getItem('action_type')
 const listForReliance= ["consumables","zeroDepreciation",'externalBiFuelkit',"engineProtect","keyLoss","tyreProtection","lossOfPersonalBelongings", 'electricalaccessories','nonElectricalaccessories','paidDrivercover','ownerDriverPAcover','unnamedpassengerPAcover']


//  const listForReliance= [{type:'Reliance',values:["consumables","zeroDepreciation",'externalBiFuelkit',"engineProtect","keyLoss",'electricalaccessories','nonElectricalaccessories','paidDrivercover','ownerDriverPAcover','unnamedpassengerPAcover']}]
const listForRsa = [ "consumables","zeroDepreciation",'externalBiFuelkit',"engineProtect","keyLoss",'electricalaccessories','nonElectricalaccessories','paidDrivercover','ownerDriverPAcover','unnamedpassengerPAcover',"roadSideAssistance","returntoInvoice","ncbProtection","lossOfPersonalBelongings","tyreProtection"]
const listForGodigit = [ "consumables","zeroDepreciation",'externalBiFuelkit',"engineProtect",'electricalaccessories','nonElectricalaccessories','paidDrivercover','ownerDriverPAcover','unnamedpassengerPAcover',"roadSideAssistance","returntoInvoice","tyreProtection"]

const tickMarkForRSA = [
  {
    id:'1',
    name:'consumables',
    isTick:![0, '0.0', null].includes(popData&&popData.data.rsaQuoteResponse&&popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.CONSUMABLE_COVER)

  },
  {
    id:'2',
    name:'zeroDepreciation',
    isTick:![0, '0.0', null].includes(popData&&popData.data.rsaQuoteResponse&&popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.DEPRECIATION_WAIVER)
  },
  {
    id:'3',
    name:'externalBiFuelkit',
    isTick:![0, '0.0', null].includes(popData&&popData.data.rsaQuoteResponse&&popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.BI_FUEL_KIT)
  },
  {
    id:'4',
    name:'engineProtect',
    isTick:![0, '0.0', null].includes(popData&&popData.data.rsaQuoteResponse&&popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.ENGINE_PROTECTOR)
  },
  {
    id:'5',
    name:'keyLoss',
    isTick:![0, '0.0', null].includes(popData&&popData.data.rsaQuoteResponse&&popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.KEY_REPLACEMENT)
  },
  {
    id:'6',
    name:'electricalaccessories',
    isTick:![0, '0.0', null].includes(popData&&popData.data.rsaQuoteResponse&&popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.ELECTRICAL_ACCESSORIES)
  },
  {
    id:'7',
    name:'nonElectricalaccessories',
    // isTick:![0, '0.0', null].includes(popData&&popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.NON_ELECTRICAL_ACCESSORIES)
 isTick:true
  },
  {
    id:'8',
    name:'paidDrivercover',
    isTick:![0, '0.0', null].includes(popData&&popData.data.rsaQuoteResponse&&popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.LIABILITY.PA_COVER_TO_PAID_DRIVER)
  },
  {
    id:'9',
    name:'ownerDriverPAcover',
    isTick:![0, '0.0', null].includes(popData&&popData.data.rsaQuoteResponse&&popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.LIABILITY.UNDER_SECTION_III_OWNER_DRIVER)
  },
  {
    id:'10',
    name:'unnamedpassengerPAcover',
    isTick:![0, '0.0', null].includes(popData&&popData.data.rsaQuoteResponse&&popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.LIABILITY.UNNAMED_PASSENGRS)
  },
  {
    id:'11',
    name:'roadSideAssistance',
    isTick:![0, '0.0', null].includes(popData&&popData.data.rsaQuoteResponse&&popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.ROADSIDE_ASSISTANCE_COVER)
  },
  {
    id:'12',
    name:'returntoInvoice',
    isTick:![0, '0.0', null].includes(popData&&popData.data.rsaQuoteResponse&&popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.VEHICLE_REPLACEMENT_COVER
      )
  },
  {
    id:'13',
    name:'ncbProtection',
    isTick:![0, '0.0', null].includes(popData&&popData.data.rsaQuoteResponse&&popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.NCB_PROTECTOR)
  },
  {
    id:'14',
    name:'lossOfPersonalBelongings',
    isTick:![0, '0.0', null].includes(popData&&popData.data.rsaQuoteResponse&&popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.LOSS_OF_BAGGAGE)
  },
  {
    id:'15',
    name:'tyreProtection',
    isTick:![0, '0.0', null].includes(popData&&popData.data.rsaQuoteResponse&&popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.TYRE_COVER)
  },
]

    useEffect(()=>{
        // setRegisteredModel(registeredmodel)
        // setAddonValues(JSON.parse(addonsplaceholder))
  // setFinalPolicyType(finalpolicytype)
  setActionType(actionValue)
  // actionType&&setOldPolicyType(JSON.parse(actionType=='Car'? radioValue:radioValue2).policy)

    },[ actionValue, actionType])


    // console.log('test1',popData,tickMarkForRSA[0].isTick)

    useEffect(()=>{
      if(addonValues&&addonValues.ownerDriverPAcover==false) {
        setShowDefaultAdd(false)
      }
    },[addonValues])
    
useEffect(()=>{
if((discountValue==(''||null||undefined)==false)) {
    setDiscShowOption(true)
}else {
    setDiscShowOption(false)

}

},[discountValue])

useEffect(()=>{
  setPopData(JSON.parse(apidata));

},[apidata])


useEffect(()=>{
  if(actionType=='Car'){

    setOldPolicyType(JSON.parse(radioValue).policy);
    setFinalPolicyType(finalpolicytype);
    setAddonValues(JSON.parse(addonsplaceholder))
    setRegisteredModel(registeredmodel)
    setDiscountValue(carDiscountplacehodler)
    setIsVehicleRegistered(carRegistered)


  return ;

  }
  if(actionType==='Bike') {
    setFinalPolicyType(finalpolicytype2)
    setOldPolicyType(JSON.parse(radioValue2).policy)
    setAddonValues(JSON.parse(addonsplaceholder2))
    setRegisteredModel(registeredmodel2)
    setDiscountValue(bikeDiscountplacehodler)
  setIsVehicleRegistered(bikeRegistered)



  }



},[actionType, addonsplaceholder, addonsplaceholder2, bikeDiscountplacehodler, bikeRegistered, carDiscountplacehodler, carRegistered, finalpolicytype, finalpolicytype2, radioValue, radioValue2, registeredmodel, registeredmodel2])



    useEffect(()=>{
 
       
        (actionType=='Car'?addonsplaceholder:addonsplaceholder2==(''||null||undefined))==false&&  arrayAddons.unshift(JSON.parse(actionType=='Car'?addonsplaceholder:addonsplaceholder2))
    
    },[actionType, addonsplaceholder, addonsplaceholder2, arrayAddons])
 useEffect(()=>{
    // for(var i=0; i<name.length/2; i++)  {
    //     listOfAddons.push({id:i+1, name: name[i], isTrue: value[i]});
    // }
    function getTrueKeys(obj) {
        var ret = [];
        
        for (var key in obj) {
           if (obj.hasOwnProperty(key) && obj[key] === true) {
              ret.push(key);
           }
        }
        return setListOfAddons(ret);
     }
     getTrueKeys(addonValues)
     
 },[addonValues])

 const isTick = tickMarkForRSA.map(obj=>obj.isTick===true)
//  console.log('shiting',isTick.find(item=>item===true))
    return (
      <div className={`bg-[#F4F4F4]  lg:space-x-4  ${isAddonShow?'':'lg:flex hidden'}  rounded-lg px-2`}>
        {values.map((item, key) => (
          <div key={key} >
           <div className={`${isshow==false?'hidden':''}`} >

            <h1 className="text-[#989898] leading-7 lg:w-max text-[10px] lg:text-[12.5px]">
              {item.name}
            </h1>
            <p className="text-[10px] lg:text-sm font-bold">{item.value}</p>
           </div>

            {/*  For Discount */}
            <div
              className={` ${
                (key > 0 && key<2)? "" : "hidden"
              } ${isshow==false?'hidden':''}    gap-2`}
            >
               
                <div className="flex flex-col gap-y-2">

                  {((isVehicleRegistered==('false'||false)&&(discountValue==( null) || discountValue=='None' ))||(registeredModel=='Yes'&& (discountValue==( null) || discountValue=='None' ))) ? <p className="text-[10px] lg:text-sm font-bold">None</p> : <>
                  <div
                    className={`    ${( registeredModel=='Yes'||isVehicleRegistered==('false'||false) )?'hidden':''} flex items-center gap-1 bg- white w-max  p-1 lg:p-0 lg:pr-2 rounded-3xl`}
                  >
                   
                      <AiOutlineCheck
                        className={` text-hero  }`}
                      />

                     <div className="flex flex-col gap-y-2">
                    <p
                      className={` 
                         "text-[#263238]"  
                         text-[10px] lg:text-xs w-max`}
                        >
                           No Claim Bonus
                 
                    </p>
                   
                        </div> 
                  </div>
                 
                  <div
                    className={`  ${discountValue==( null) || discountValue=='None'?'hidden':''}  flex items-center gap-1 bg-wh ite w-max  p-1 lg:p-0 lg:pr-2 rounded-3xl`}
                  >
                   
                      <AiOutlineCheck
                        className={` text-hero`}
                      />

                     <div className="flex flex-col gap-y-2">
                     <p
                      className={`  "text-[#263238]" 
                         text-[10px] lg:text-xs w-max`}
                        >
                   Voluntary Deductible 
                    </p>
                   
                        </div> 
                  </div>
                  
                  
                  </>}
                </div>

                 
         


             
              
              
            
               

            </div>

           

{/*  For Addons */}

            <div
              className={`   ${
                key >  1? "" : "hidden"
              }  relative `}
            >
                {((listOfAddons.length<1&&!showDefaultAdd))? <p className="  font-semibold" > None</p> : 
<>
              {/* ------------------------ CheckMark For Reliance Start Here -------------- */}
             <div className={`${((finalPolicyType==undefined||null)?oldPolicyType=="ownDamage":finalPolicyType=="ownDamage"&&actionType==='Bike')?'hid den':''} `}>

              <div className={`${typeTiles!=='Reliance'?'hidden':''} grid grid-cols-1 lg:grid-cols-2  gap-2 lg:gap-x-4`}>

              {listOfAddons.map((item, key, row) => (
                <>
                  <div
                    className={`  ${
                      key > 1 && showMore ? "hidden lg:flex" : "flex"
                    }  ${item=='lastownerDriverPAcover'?'hidden lg:hidden':''}  ${item=='ownerDriverPAcover'?'hidden':''}  items-center gap-3 lg:mr-5 bg-whi te w-full relative  p-1 lg:p-0.5   rounded-3xl`}
                  >
                    {listForReliance.includes(item)&&typeTiles=='Reliance'  ? (
                      <AiOutlineCheck
                        className={`${listForReliance.includes(`${item}`) ? "text-hero" : ""}`}
                      />
                    ) : (
                      <AiOutlineClose
                        className={`${listForReliance.includes(`${item}`)? "" : "text-[#ed3d3d]"}`}
                      />
                    )}
                    <p
                      className={` ${
                        listForReliance.includes(item) ? "text-[#263238]" : "text-[#212020]"
                      }  text-[10px] lg:text-xs capitalize  w-max`}
                    >
                    {((item==='unnamedpassengerPAcover'&&'Unnamed Passegner PA Cover')||(item==='electricalaccessories'&&'Electrical Accessories')||(item==='paidDrivercover'&&'Paid Driver Cover')||(item==='nonElectricalaccessories'&&'Non-Electrical Accessories')||(item==='externalBiFuelkit'&&'External Bifuel kit')||(item==='returntoInvoice'&&'Return to Invoice'))|| (item&&item.replace(/([a-z])([A-Z])/g, '$1 $2'))}
                    </p>
                   
                  </div>

                 
                  {key > 1 && key < 3 && (
                    <p
                      className={`${key > 1} ${
                        showMore ? "" : "hidden"
                      } text-[10px]  w-max font-semibold lg:hidden`}
                      onClick={() => {
                        setShowMore(false);
                      }}
                    >
                      {listOfAddons.length - 2} + more
                    </p>
                  )}
                </>
              ))}

</div>

                      {/* -------------------- CheckMark For Reliance End Here -------------- */}
              {/* ------------------------ CheckMark For Rsa Start Here -------------- */}
              <div className={`${typeTiles!=='RSA'?'hidden':''}`}>
              {listOfAddons.map((items, key, row) => (
                <div className={`${key>0?'hidden':''}  grid grid-cols-1 lg:grid-cols-2  gap-2 lg:gap-x-4 `}>
              {tickMarkForRSA.map((item, key, row) => (
                <div className={` ${listOfAddons.includes(item.name)?'':'hidden'} ${item.name==='ownerDriverPAcover'?'hidden':''}`}>
                  <div
                    className={`   ${
                      key > 1 && showMore ? "hidden lg:flex" : "flex"
                    } ${item=='lastownerDriverPAcover'?'hidden lg:hidden':''}   ${item.name=='ownerDriverPAcover'?'hidden':''}  items-center gap-3 lg:mr-5 bg-whi te w-full relative  p-1 lg:p-0.5   rounded-3xl`}
                  >
                   
                    {item.isTick?  <AiOutlineCheck
                        className= "text-hero" 
                      />: <AiOutlineClose
                      className="text-[#ed3d3d]"
                    />}
                  <p
                      className={` ${
                        listForRsa.includes(item.name) ? "text-[#263238]" : "text-[#212020]"
                      }  text-[10px] lg:text-xs capitalize  w-max`}
                    >
                   {((item.name==='unnamedpassengerPAcover'&&'Unnamed Passegner PA Cover')||(item.name==='electricalaccessories'&&'Electrical Accessories')||(item.name==='paidDrivercover'&&'Paid Driver Cover')||(item.name==='nonElectricalaccessories'&&'Non-Electrical Accessories')||(item.name==='externalBiFuelkit'&&'External Bifuel kit')||(item.name==='returntoInvoice'&&'Return to Invoice'))|| (item.name.replace(/([a-z])([A-Z])/g, '$1 $2'))}
                    </p>
                   
                  </div>

                 
                  {key > 1 && key < 3 && (
                    <p
                      className={`${key > 1}  ${item.isTick?'':"hidden"} ${
                        showMore ? "" : "hidden"
                      } text-[10px]  w-max font-semibold lg:hidden`}
                      onClick={() => {
                        setShowMore(false);
                      }}
                    >
                      {listOfAddons.length - 2} + more
                    </p>
                  )}
                </div>
              ))}
</div>
             ) )}

</div>

                      {/* -------------------- CheckMark For Rsa End Here -------------- */}
              {/* ------------------------ CheckMark For Godigit Start Here -------------- */}
              <div className={`${typeTiles!=='Godigit'?'hidden':''}  grid grid-cols-1 lg:grid-cols-2  gap-2 lg:gap-x-4`}>

              {listOfAddons.map((item, key, row) => (
                <>
                  <div
                    className={`  ${
                      key > 1 && showMore ? "hidden lg:flex" : "flex"
                    } ${item=='lastownerDriverPAcover'?'hidden lg:hidden':''}  ${item=='ownerDriverPAcover'?'hidden':''}  items-center gap-3 lg:mr-5 bg-whi te w-max relative  p-1 lg:p-0.5   rounded-3xl`}
                  >
                    {listForGodigit.includes(item)&&typeTiles=='Godigit'  ? (
                      <AiOutlineCheck
                        className={`${listForGodigit.includes(`${item}`) ? "text-hero" : ""}`}
                      />
                    ) : (
                      <AiOutlineClose
                        className={`${listForGodigit.includes(`${item}`)? "" : "text-[#ed3d3d]"}`}
                      />
                    )}
                    <p
                      className={` ${
                        listForGodigit.includes(item) ? "text-[#263238]" : "text-[#212020]"
                      }  text-[10px] lg:text-xs capitalize  w-max`}
                    >
                  {(item==='unnamedpassengerPAcover'&&'Unnamed Passegner PA Cover')||(item==='electricalaccessories'&&'Electrical Accessories')||(item==='paidDrivercover'&&'Paid Driver Cover')||(item==='nonElectricalaccessories'&&'Non-Electrical Accessories')||(item==='externalBiFuelkit'&&'External Bifuel kit')||(item==='returntoInvoice'&&'Return to Invoice')|| (item&&item.replace(/([a-z])([A-Z])/g, '$1 $2'))}
                    </p>
                   
                  </div>

                 
                  {key > 1 && key < 3 && (
                    <p
                      className={`${key > 1} ${
                        showMore ? "" : "hidden"
                      } text-[10px]  w-max font-semibold lg:hidden`}
                      onClick={() => {
                        setShowMore(false);
                      }}
                    >
                      {listOfAddons.length - 2} + more
                    </p>
                  )}
                </>
              ))}

</div>
</div>

                      {/* -------------------- CheckMark For Godigit End Here -------------- */}

               {/* ------------ownerDriverPAcover By Default Addon ------------------------------- */}
            {((finalPolicyType==undefined||null)?oldPolicyType=="ownDamage":finalPolicyType=="ownDamage")? <p className={`${ key > 1?'hidden':''} font-semibold`} > None</p> :<div
                    className={` ${showDefaultAdd?'':'hidden'}  ${((finalPolicyType==undefined||null)?oldPolicyType=="ownDamage":finalPolicyType=="ownDamage")?'hidden':''} flex    items-center gap-3 lg:mr-5 bg-whit e w-max    mb-1 mt-1  p-1 lg:p-0.5  rounded-3xl`}
                  >
                     
                      <AiOutlineCheck
                        className="text-hero" 
                      />
                   
                    <p
                      className= "text-[#263238] text-[10px] lg:text-xs capitalize  w-max"
                    >
                 Owner Driver PA Cover
                    </p>
                    <div className="">

                    <IoIosInformation className={` ${openDialog?'':'bounce'}  text-white text-xl rounded-full bg-[#2c9648] font-extrabold `} onClick={()=>{
                          setOpenDialog(!openDialog)
                        }}/>
                    <div className={`bg-white w-44 mt-3 absolute lg:left-0 xl:-left-0  -top-14 ${openDialog?'':'hidden'} rounded-lg drop-shadow-xl shadow-lg  z-50 text-xs  font-semibold   p-4`}>
                    <p className="w-max">

Compulsory as per Govt
  </p>                        </div>
                        </div>
                  </div>  }
              
              </>
              }

            </div>
          </div>
        ))}
      </div>
    );
  };


   
 