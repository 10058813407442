import React, { useState, useEffect,useRef } from "react";
import LandingBackground from "../../../assets/Svg/LandingPageBck.svg";
import MaleSvg from '../../../assets/Vector/Health/male.svg'
import FemaleSvg from '../../../assets/Vector/Health/female.svg'
import { Link, useNavigate } from "react-router-dom";
import NavHeader from "../../NavHeader";
import Checkbox from "../../customs/CheckBoxPP";
import InputPP from "../../customs/InputPP";
import { SlCalender } from "react-icons/sl";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DropDownPP from "../../customs/DropDownPP";

const HealthIntro2 = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({})

  const [closeNav, setCloseNav] = useState(false)
  const [selectedGender, setSelectedGender] = useState()
  const [showError, setShowError] = useState(false)
const [isChecked1, setIsChecked1] = useState(false);
const [isChecked2,setIsChecked2] = useState(false);
const [isChecked3, setIsChecked3] = useState(false);
const [isChecked4, setIsChecked4] = useState(false);
const [isChecked5, setIsChecked5] = useState(false);
const [isChecked6, setIsChecked6] = useState(false);
 const [genderType, setGenderType] = useState({})
 const [counter, setCounter] = useState(1);
 const [counter2, setCounter2] = useState(1);
 const [selectedDate, setSelectedDate] = useState(null);
const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

const buttonRef = useRef(null);

 let [currentValue, setCurrentValue] = useState(1)
 let [currentValue2, setCurrentValue2] = useState(1)
  const genderValue = localStorage.getItem('health_gender')
  const ageValues = localStorage.getItem('health_family_age')



  const handleSubmit = (e) => {
    e.preventDefault();

  
         localStorage.setItem('health_family_age',JSON.stringify(inputs))
        navigate("/healthinsurance/health-profile/prestep-3" );
    

  };
  const handleChange = (e) => {
    const name = e.target.name;

    const value = e.target.value;
    setInputs((values) => ({
      ...values,
      [name]: name.includes("value")? value:  e.target.checked
    }));

  
  };

useEffect(()=>{
  if(genderValue) {

    setGenderType(genderValue)
  }
},[ genderValue])

useEffect(()=>{
  ageValues&&  setInputs(JSON.parse(ageValues))

},[ageValues,inputs])

const handleCheckboxChange = (item,key) => {
  // setIsChecked(!isChecked);
  if(item&&key+1===1) {
    setIsChecked1(!isChecked1)
  }
  else if (item&&key+1===2){
setIsChecked2(!isChecked2)
  }
  else if (item&&key+1===3){
setIsChecked3(!isChecked3)
  }
  else if (item&&key+1===4){
setIsChecked4(!isChecked4)
  }
  else if (item&&key+1===5){
setIsChecked5(!isChecked5)
  }
  else if (item&&key+1===6){
setIsChecked6(!isChecked6)
  }
  if(currentValue<1){
    setIsChecked5(false)
  }


};


const questions = [
  {
    id:1,
    question:'Self',
    inputLimit:'1',
    checked:isChecked1

},
  {
    id:2,
    question:genderType==='male'?'Wife':'Husband',
    inputLimit:'1',
    checked:isChecked2


},
  {
    id:3,
    question:'Mother',
    inputLimit:'1',
    checked:isChecked3


},
  {
    id:4,
    question:'Father',
    inputLimit:'1',
    checked:isChecked4


},
  {
    id:5,
    question:'Son',
    inputLimit:'4',
    checked:isChecked5


},
  {
    id:6,
    question:'Daughter',
    inputLimit:'4',
    checked:isChecked6


},
]

/* ---------------------------- Increment and Decrement Handler ------------------------ */

  const divArray = Array.from({ length: counter }, (_, index) => index);
  const divArray2 = Array.from({ length: counter2 }, (_, index) => index);


const increaseChild =()=>{
  if(currentValue>=0&&currentValue<4){

    setCurrentValue(currentValue+1)
    setCounter(counter + 1);

  }
}

const decreaseChild = ()=>{
  if(currentValue>0) {

    setCurrentValue(currentValue-1)
    setCounter(counter -1);
  }

  
 
}
const increaseChild2 =()=>{
  if(currentValue2>=0&&currentValue2<4){

    setCurrentValue2(currentValue2+1)
    setCounter2(counter2 + 1);
  }
}

const decreaseChild2 = ()=>{
  if(currentValue2>0) {

    setCurrentValue2(currentValue2-1)
    setCounter2(counter2 -1);

  }
 
}

useEffect(()=>{
  if(currentValue<1){
    setIsChecked5(false)
  }
  if(currentValue2<1){
    setIsChecked6(false)
  }
},[currentValue, currentValue2])

/* ------------------------------------------------------------------------ */

/* ------------------ Parent Age DropDown Values--------------------------   */

const numberOptions = [];
  
  for (let i = 18; i <= 100; i++) {
    numberOptions.push(i);
  }
  
  const numberArray = []
  for (let i = 18; i <= 100; i++) {
    numberArray.push(`${i} yr`);
  }
  /* --------------------------------------------------------- */

  /* ------------------------- For Childrens -------------------- */
  const customArray = ['17-90 Days', '3-12 Months'];

  for (let i = 1; i <= 30; i++) {
    customArray.push(`${i} yr`);
  }

/* ------------------------------------------------------------------ */

useEffect(()=>{
  if(isChecked5&&currentValue<1){
    setCurrentValue(currentValue+1)
    setCounter(counter+1)
  }
  if(isChecked6&&currentValue2<1){
    setCurrentValue2(currentValue2+1)
    setCounter2(counter2+1)
  }
  const checkSon = document.getElementsByClassName('checkbox-input')
  if(currentValue<1){
    setIsChecked5(false)
  }

},[counter, counter2, currentValue, currentValue2, isChecked5, isChecked6])




  const handleClick = () => {
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  };

  return (
    <>
      <NavHeader dropClose={ [closeNav, setCloseNav] } />
      <main
        className="w-full pb-10 lg:pt-8 bg-no-repeat bg-cover   "
        style={{ backgroundImage: `url(${LandingBackground})` }}  onClick={()=>{
          setCloseNav(false)
        }}
      >
        <form onChange={handleChange} >
          <h1 className="text-xl lg:text-3xl text-[#293651] font-semibold  py-10   lg:pt-4 text-center">
            Health Insurance Quote
          </h1>
          <div className="lg:w-[55%] rounded-t-md bg-[#aab8aa27] m-auto ">

<div className={`lg:w-[18.3%]  rounded-t-md  bg-hero py-[3px]`}></div>
</div>
          <div className=" justify-center bg-white mx-5 lg:m-auto shadow-[0px_0px_25px_rgba(0,0,0,0.1)] lg:drop-shadow-lg lg:w-[55%] h-[500px   px-4 py-5">
            <h1 className="mr-auto font-medium text-solid lg:text-xl py-4 pl-4">
            {/* Select all who will be part of this health policy */}
            Who will be covered by this health policy?
            </h1>
            
            <hr className="text-[#DBDBDB] pb-4" />
            
          <div className="w-[70%] m-auto">
           <div className="grid lg:grid-cols-2 gap-2">
            {questions.map((item,key)=>(
              <div className="flex-col ">
<div className="flex">

        <label className="checkbox-label" ref={buttonRef} >
      <input
       
        type="checkbox"
        id={key}
      
  
   name={item.question}
   checked={item.checked}
   onChange={()=>{handleCheckboxChange(item.id,key)}}
        className="checkbox-input"
      />
      <span className="checkbox-custom"></span>

      {`${item.question}`}
      <h1 className={`${(item.question==='Son')?'':'hidden'} `}>
      {currentValue>1?'s ':''}
      </h1>
      <h1 className={`${(item.question==='Daughter')?'':'hidden'} `}>
      {currentValue2>1?'s ':''}
      </h1>
      <span className={`${(item.question==='Son')?'':'hidden'} flex pl-2 `}>
      <h1 className={`${currentValue>0?'':'hidden'} ${isChecked5?'':'hidden'} `}> { currentValue}</h1>
      </span>
      <span className={`${(item.question==='Daughter')?'':'hidden'} flex  pl-2 `}>
      <h1 className={`${currentValue2>0?'':'hidden'} ${isChecked6?'':'hidden'} `}> { currentValue2}</h1>
      </span>

     
    </label>       
    <div className={`${('Son').includes(item.question)&&(isChecked5)?'':'hidden'}  cursor-pointer ml-5 w-12  flex `}>
        <div className="border text-center w-6 hover:bg-[#2ca95a6d]" onClick={decreaseChild}>-</div>
        <div className="w-6 border-r border-t border-b text-center hover:bg-[#2ca95a6d]"  onClick={increaseChild}>+</div>
      </div>
    <div className={`${('Daughter').includes(item.question)&&(isChecked6)?'':'hidden'}  cursor-pointer ml-5 w-12  flex `}>
        <div className="border text-center w-6 hover:bg-[#2ca95a6d]" onClick={decreaseChild2}>-</div>
        <div className="w-6 border-r border-t border-b text-center hover:bg-[#2ca95a6d]"  onClick={increaseChild2}>+</div>
      </div>
      </div>
 <div className="flex relative  items-center gap-2 mr-5">

 <DropDownPP
 className={` ${item.checked?'':'hidden'} ${(item.question==='Son'||item.question==='Daughter')?'hidden':''} w-max  my-1 lg:w-40 border border-[#d7d5d5]  focus:outline-none  px-3 py-1  rounded-md`}
 labelClassName="hidden"
                      label="Age"
                      id={`${item.question}_value`}
                      

                      required={false}
                      // value={`${inputs.item.question}_value}`}
 brClass="hidden"

                        options={(item.question==='Son'||item.question==='Daughter')?customArray:numberArray}
                    />

                    <div className=""> 

{divArray.map((key)=>(
<div className="flex-col">
  <h1 className={` ${(item.question==='Son')?'':'hidden'} ${isChecked5?'':'hidden'}`}>Son - {key+1} </h1>
 <DropDownPP
 className={` ${item.checked?'':'hidden'} ${(item.question==='Son')?'':'hidden'} w-max flex  my-1 lg:w-52 border border-[#d7d5d5]  focus:outline-none  px-3 py-1  rounded-md`}
 labelClassName={` hidden`}
                      label={`Age`}
                      id={`${item.question}_value_${currentValue}`}
                      

                      required={false}
                      value={``}
 brClass="hidden"

                        options={(item.question==='Son'||item.question==='Daughter')?customArray:numberArray}
                    />
</div>
                    
                    ))}
                    </div>
                    <div className="">

{divArray2.map((key)=>(
<div className="flex-col">
<h1 className={` ${(item.question==='Daughter')?'':'hidden'} ${isChecked6?'':'hidden'}`}>Daughter - {key+1} </h1>

 <DropDownPP
 className={` ${item.checked?'':'hidden'} ${(item.question==='Daughter')?'':'hidden'} w-max  my-1 lg:w-40 border border-[#d7d5d5]  focus:outline-none  px-3 py-1  rounded-md`}
 labelClassName="hidden"
                      label="Age"
                      id={`${item.question}_value_${currentValue2}`}
                      

                      required={false}
                      value={``}
 brClass="hidden"

                        options={(item.question==='Son'||item.question==='Daughter')?customArray:numberArray}
                    />
</div>
                    
                    ))}
                    </div>

 {/* <InputPP
 className={` ${item.checked?'':'hidden'}  w-max   lg:w-max   px-3 py-1  rounded-md`}
 labelClassName="hidden"
 id={`${item.question}_value`}
 label="Select Age"
 type="text"
 brClass="hidden"
 placeholder="Select Age"
 value=''
 changeFunction={handleChange}
 // errorState={[registrationError, setRegistrationError]}
/> */}
{/* <SlCalender  onClick={handleCalendarClick}
        style={{ cursor: 'pointer' }} className={` ${item.checked?'':'hidden'} z- `}/>
<InputPP
                    className={` ${item.checked?'':'hidden'} w-5 h-5 border-hero absolute top-0 right-3  z-0 border  rounded-md   px-3 py-2 my-2`}
                    labelClassName="hidden"
                    id={`${item.question}_value_Age`}
                    name={`${item.question}_value_Age`}
                    // label={isVehicleRegistered!=='true'?'Date Of Purchase': `Registration Date`}
                    type="date"
                    // tDate={fifteenYearsAgo.toISOString().split("T")[0]}
                    placeholder="Registration Date"
                    value=''
                    // errorState={[registrationDateError, setRegistrationDateError] }
                    // state={[emailId, setEmailId]}
                    />
                   <span  id={`${item.question}_value_Age`}> 

                    {(isDatePickerOpen&&key+1==item.id) && (
                      <DatePicker
        className={` ${key>1?'hidden':''} `}
          selected={selectedDate}
          onChange={handleDateChange}
          dateFormat="yyyy-MM-dd"
          id={`${item.is}_value_Age`}
          open={isDatePickerOpen&&key+1==item.id?true:false}
          />
          )}
          </span> */}

</div>
</div>
            ))}
         
           </div>
          </div>
          <h1 className={`${showError?'':'hidden'} text-center pt-2 text-sm text-[#eb5648] `}>Please Select Gender</h1>

          </div>
          <div className="flex justify-center space-x-5 pt-8 ">
            <Link to="/healthinsurance/health-profile/prestep-1">
            <button className="bg-[#293651]  w-24 py-2 rounded-full text-white">
              Back
            </button>
            </Link>
            <input
              type="submit"
              className="bg-hero w-24 py-2 rounded-full text-white cursor-pointer "
              onClick={handleSubmit}
              value="Next"
            />
          </div>
        </form>
      </main>
    </>
  );
};

export default HealthIntro2;
