import React, { useState, useEffect } from "react";
import { AiOutlineDown, AiOutlineClose } from "react-icons/ai";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { RsaIdvQuote } from "../rsaApis/RsaIdvQuote";
import { RelianceIdvQuote } from "../relianceApis/RelianceIdvQuote";
import { GodigitIdvQuote } from "../godigitApis/GodigitIdvQuote";
import axios from "axios";


export const PolicyFilterCard2 = ({
  filterCardData,
  closeState = "",
  parentClass = "",
  discData = "",
  addsData = "",
  covData = "",
  errorMessages2,
  custCovdata = "",
  mission,
  minIdv,
  maxIdv,
  placeValue,
  loading,loadingData
}) => {
    const [closePopUp, setClosePopUp] = closeState;
    const [isCoupenSelected, setIsCoupenSelected] = useState(false);
    const [inputs, setInputs] = useState({
      coverAmount:0
    });
    const [errorMessage2, setErrorMessage2] =useState(errorMessages2)
    const [inputAddon, setInputAddon] = useState({})
    const [isDisActive, setIsDisActive] = useState();
    // const [jwtToken, setJwtToken] = useState('')
    const [carRegistrationNumber, setCarRegistrationNumber] = useState('')
    const [vehicleNumber, setVehicleNumber] = useState('')
    const [rtoRegionCode, setRtoRegionCode] = useState('')
  const [oldPolicyType, setOldPolicyType] = useState()
  const [ratesValues, setRatesValues] = useState()
  const [changeAction, setChangeAction] = useState(false)
  const [selectedNCB, setSelectedNCB] = useState(0);
  const [reasonCpaVal, setReasonCpaVal] = useState([])

  const [placeHolderValue, setPlaceHolderValue] = placeValue
     const [defaultRadioValue, setDefaultRadioValue] = useState()
    const [coverData, setCoverData] = covData;
    const [discountData, setDiscountData] = discData;
    const [cstCvdata, setCstCvdata] = custCovdata;
    const [addonsData, setAddonsData] = useState({});
    const [isInfoOpen, setIsInfoOpen] = useState(false)
    const [isApiLoaded, setIsApiLoaded] = useState(false)
   const [loader, setLoader] = loading
   const [loadApiData, setLoadApiData] = loadingData
  const [missionAwake, setMissionAwake] = mission
  const [inputDiscount, setInputDiscount] = useState()
    const [resetButton, setResetButton] = useState(false)
  const [isJWTCall, setIsJWTCall] = useState(false)
    const [quoteLoading, setquoteLoading] = useState(false);
    const [apiData, setApiData] = useState({});
const [rsaIdvValue, setRsaIdvValue] = useState()
const [tppdChange, setTppdChange] = useState(true)
const [prevAddons, setPrevAddons] = useState()

    const [manufactureYear, setManufactureYear] = useState()
    const [tokenLoaded, setTokenLoaded] = useState(false)
    const [prevClaimValue, setPrevClaimValue] = useState()
  const [registeredModelValues, setRegisteredModelValues] = useState({})
    const [popUpsFilterData, setPopUpsFilterData] = useState({})
    const [makeRequest, setMakeRequest] = useState(false)
    const [CarIsRegistered, setCarIsRegistered] = useState()
  const [buttonValue, setButtonValue] = useState()
  const [ownership, setOwnership] = useState()
  const [noChange, setNoChange] = useState(false)
  const [cpaValue, setCpaValue] = useState('1')

  const [jwtToken, setJwtToken] = useState(localStorage.getItem('jwt_token'))
  const [finalAddons, setFinalAddons] = useState({})
  const [finalAddonsInput, setFinalAddonsInput] = useState({})
  const [showCustomBox, setShowCustomBox] = useState()
  const [custBoxError, setCustBoxError] = useState(false)
  const [finalPolicyType, setFinalPolicyType] = useState()
  const [masterID, setMasterID] = useState({})
  const [modelRegionId, setModelRegionId] = useState({})
  const [initialPolicyType, setInitialPolicyType] = useState({})
  const [actionType, setActionType] = useState('')
  const [modelName, setModelName] = useState('')

/* ------------ Redux State Values ------------ */
    const {fetchIdvQuoteRsa,updateIdvValueRsa} = RsaIdvQuote()
    const {fetchIdvQuoteReliance,updateIdvTypeValueReliance} = RelianceIdvQuote()
      const { fetchIdvQuoteGodigit, updateIdvTypeValueGodigit }          = GodigitIdvQuote()
    // For Counting Number of Checked values in Addons
   const selectAddonOption= ['']
   const trueValues = Object.values(inputAddon)
   trueValues.forEach(function (x) { selectAddonOption[x] = (selectAddonOption[x] || 0) + 1; });
    localStorage.setItem('checked_addons',addonsData&&selectAddonOption.true==undefined?'0':selectAddonOption.true)
   
  const MINUTE_MS = 30000;

  /* -------------------  API ENDPOINT -------------- */
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
/* ---------------------------------- -------------- */

  
  useEffect(() => {
    const onStorage = () => {
      setJwtToken(localStorage.getItem('jwt_token'));
    };
  
    window.addEventListener('storage', onStorage);
  
    return () => {
        window.removeEventListener('storage', onStorage);
    };
  }, []);
  
    const handleChange = (e) => {
  
   
      const name = e.target.name;
      const value = e.target.value;
      setInputs((values) => ({
        ...values,
        [name]: e.target.value
           
      }))
      // setAddonsData(inputs);
      setChangeAction(true)
      updateIdvValueRsa(value)
      updateIdvTypeValueReliance(value)
      updateIdvTypeValueGodigit(value)
    };
  

   useEffect(()=>{
    if(inputs.coverAmount<minIdv||inputs.coverAmount>maxIdv){
      setErrorMessage2(true)
    }else {
      setErrorMessage2(false)

    }
    //  if (inputs.coverAmount>maxIdv) {
    //   setErrorMessage2(true)
    // }
    // else {
    //   setErrorMessage2(false)

    // }
      if (inputs.coverAmount===minIdv){
      setErrorMessage2(false)
    }
      if (inputs.coverAmount===maxIdv) {
      setErrorMessage2(false)
    }
   },[inputs, maxIdv, minIdv])
useEffect(()=>{

  const availableNCBValues = [0, 20, 25, 35, 45, 50];
  const selectedValue = parseInt(registeredModelValues&&registeredModelValues.prevNCB, 10);
    if ( selectedValue< 50) {
      // Automatically select the next higher value
      setSelectedNCB(availableNCBValues[availableNCBValues.indexOf(selectedValue) + 1]);
    } else {
      // Keep the value as is if it's already the highest
      setSelectedNCB(50);
    }

},[  registeredModelValues])
   
    // console.log(item.id == checkedData.policyData);
    // var checkedData = JSON.parse(localStorage.getItem("popupsData"));
    // console.log(checkedData.policyData);
    // const addonCheck = JSON.parse(localStorage.getItem("addon_check_data"));
  
  
    useEffect(() => {
      closePopUp&&localStorage.setItem("addon_check_data", JSON.stringify(inputs));    
      setAddonsData(inputs);
      // setFirstCheckedData(checkedData);
    }, [inputs,closePopUp]);
  ///////////// we are at handling min and max null issues and  pending integration
    let registeredModelValue= localStorage.getItem('registered_model')
    let registeredModelClaimValue= localStorage.getItem('registered_model_claim')
    const filterData = localStorage.getItem("popupsData");
    const carRegistered = localStorage.getItem("isCarRegistered");
    const radioValue = localStorage.getItem('car_Info')
    const carRegNO = localStorage.getItem('car_Intro')
    const finaladdon = sessionStorage.getItem('input_addons_check')
    const finalDiscount = sessionStorage.getItem('inputs_discount')
  const cpaValues = sessionStorage.getItem('cpaValues');
  const tppdVal = sessionStorage.getItem('tppdVal')

    const finalpolicytype = sessionStorage.getItem('inputs_policyType')
const masteridvalues= localStorage.getItem('master_ID')
const finaladdoninput = sessionStorage.getItem('check_inputs')
const modelRegion = localStorage.getItem('regionCode')
const actiontype = sessionStorage.getItem("action_type")
const rateValue = localStorage.getItem('ratesValues')
  const rsaInitIdv = localStorage.getItem("rsaIdvvalue")
  let registeredModelOwnershipValue= localStorage.getItem('registered_model_ownership')
  const requestSta = localStorage.getItem('apiLoaded')
  const prevOptedAddon = sessionStorage.getItem('prevAddons')
    const model = localStorage.getItem("car_Info_make_model");

  const cpatenure = sessionStorage.getItem('check_inputs')

    useEffect(()=>{
  
      //  console.log('im from car quote', isNewCar?"":popUpsFilterData.policyData=='bundled'?2374:2347)
    setRsaIdvValue(rsaInitIdv)
    setPopUpsFilterData(JSON.parse(filterData))
      setCarIsRegistered (JSON.parse(carRegistered))
  setDefaultRadioValue(JSON.parse(radioValue))
  setRegisteredModelValues(JSON.parse(registeredModelValue))   
  setPrevClaimValue(registeredModelClaimValue)
  setManufactureYear(JSON.parse(radioValue).manufactureYear)
setModelName(model)

  setNoChange(requestSta)
  setReasonCpaVal(cpaValues)
  setPrevAddons(prevOptedAddon)

  carRegNO&&setCarRegistrationNumber(JSON.parse(carRegNO).registrationNumber)
  // console.log('im calling again and again',popUpsFilterData.policyData)
  setFinalAddons(JSON.parse(finaladdon))
  setFinalAddonsInput(JSON.parse(finaladdoninput))
  setInputDiscount(finalDiscount)
  setFinalPolicyType(finalpolicytype)
  setMasterID(JSON.parse(masteridvalues))
  setModelRegionId(JSON.parse(modelRegion))
  setInitialPolicyType(JSON.parse(radioValue))
  setActionType(actiontype)
  setOwnership(registeredModelOwnershipValue)
  setTppdChange(tppdVal)

  setOldPolicyType(JSON.parse(radioValue).policy)
  setRatesValues(JSON.parse(rateValue))
setCpaValue(cpatenure?JSON.parse(cpatenure).checkInput4:1)
  
  },[filterData, prevOptedAddon, rsaInitIdv, actiontype, rateValue, finaladdon, modelRegion, masteridvalues, finaladdoninput, finalpolicytype, finalDiscount, carRegistered, radioValue, carRegNO, registeredModelClaimValue, registeredModelValue, registeredModelOwnershipValue, requestSta, cpaValues, tppdVal, cpatenure, model])
  
  // useEffect(()=>{
  //   setAddonsData(JSON.parse(addOns))
  
  // },[addOns])
  
    //Retriving Vehicle Registration Number 
    useEffect(()=>{
  
      const vehicleNumberHandle = ()=>{
        let vehNo = ['']
        let spliting = carRegistrationNumber.split('')
        vehNo.unshift(spliting)
        setVehicleNumber( carRegistrationNumber.length<10? vehNo[0][0]+vehNo[0][1]+'-'+vehNo[0][2]+vehNo[0][3]+'-'+vehNo[0][4]+'-'+vehNo[0][5]+vehNo[0][6]+vehNo[0][7]+vehNo[0][8] : vehNo[0][0]+vehNo[0][1]+'-'+vehNo[0][2]+vehNo[0][3]+'-'+vehNo[0][4]+ vehNo[0][5]+'-'+vehNo[0][6]+vehNo[0][7]+vehNo[0][8]+vehNo[0][9])
    
        setRtoRegionCode(vehNo[0][0]+vehNo[0][1]+vehNo[0][2]+vehNo[0][3])
        // console.log(vehNo[0][0]+vehNo[0][1]+'-'+vehNo[0][2]+vehNo[0][3]+'-'+vehNo[0][4]+'-'+vehNo[0][5]+vehNo[0][6]+vehNo[0][7]+vehNo[0][8])
      }
      vehicleNumberHandle()
    },[carRegistrationNumber])
  
    // Vehicle registrationNumber
  
  
    // console.log(())
  
     // Creating todays Date
 

  
  /* For Generating +1 Day  for Cover from and Cover To Start Here for New vehicle */

  let currentDate ;

// Add logic to set the date based on a condition
if (carRegistered==='true' ) {
  currentDate=(new Date(registeredModelValues&&registeredModelValues.policyExp) ); // Add one day
} else if (carRegistered==='false') {
  currentDate=(new Date() ); // Add two days
}
  let cDay = currentDate.getDate()+1;
  let endCDay = currentDate.getDate();
  
  
  
  let cMonth = currentDate.getMonth() + 1;
  let cYear = currentDate.getFullYear();

  if((cYear+1)%4===0&&endCDay==28) {
    endCDay+=1
  }

  if (cMonth === 11) {
    cMonth = 0;
    cYear += 1;
  } else if (cMonth === 1) {
    let febDays = (cYear % 4 === 0 && (cYear % 100 !== 0 || cYear % 400 === 0)) ? 29 : 28;
    if (cDay === febDays) {
      cDay = 0;
      cMonth += 1;
    }
  } else {
    if (cDay === new Date(cYear, cMonth + 1, 0).getDate()) {
      cDay = 0;
      cMonth += 1;
    }
  }
  let nextDateNew = new Date(cYear, cMonth+1, cDay + 1);
  
  let newMonth1New = nextDateNew.getMonth()
  /* For Generating +1 Day  for Cover from and Cover To Start Here for New vehicle */

  const userEnterDate = new Date(registeredModelValues&&registeredModelValues.policyExp)
  let prevDay = userEnterDate.getDate()
    let prevvDay = userEnterDate.getDate()+1
  
  let prevMonth = userEnterDate.getMonth() + 1;
  let prevYear = userEnterDate.getFullYear();
  let prevActDay =userEnterDate.getDate()
  let tpPrevYear =userEnterDate.getFullYear()-1
  
  if((prevYear+1)%4===0&&prevDay==28) {
    prevDay+=1
  }
  
    var dateFormatTotime = new Date(userEnterDate);
    var increasedDate = new Date(dateFormatTotime.getTime() +(1 *86400000) );
  
    // var dateString = 'Mon Jun 30 2014 00:00:00';
  
    var startDate = new Date(registeredModelValues&&registeredModelValues.policyExp);
    
    // seconds * minutes * hours * milliseconds = 1 day 
    var day = 60 * 60 * 24 * 1000;
    
    var endDate = new Date(startDate.getTime() + day);

    var toCurDate = new Date(currentDate.getTime()+day)
    var fromPrevDay = new Date(userEnterDate.getTime()+day)



 /* ----------------------------------------------------- */
 const startDates = new Date(currentDate);
 startDates.setDate(currentDate.getDate()+1);
 
 // Calculate the end date (coverTo) as the same day next year, adjusting for leap years
 const nextYear = currentDate.getFullYear() + 1;
 
 
 /* ----------------------------------- */
 function getDaysInFebruaryNextYear() {
   const currentYear = new Date().getFullYear();
   const nextYear = currentYear + 1;
 
   // Create a date for March 1st of the next year
   const marchFirstNextYear = new Date(nextYear, 2, 1);
 
   // Subtract one day to get the last day of February in the next year
   const lastDayOfFebruaryNextYear = new Date(marchFirstNextYear - 1);
 
   // Get the day of the month to determine the number of days in February
   return lastDayOfFebruaryNextYear.getDate();
 }
 
 const daysInFebruaryNextYear = getDaysInFebruaryNextYear();
 /* ------------------------------------------------ */
 
 
 const isNextYearLeap = new Date(nextYear, 1, 29).getDate() === 29;
 const endDates = isNextYearLeap && nextYear%4===0&&daysInFebruaryNextYear===29&&currentDate.getMonth()===1
   ? new Date(nextYear, 1, 29)
   : new Date(nextYear, currentDate.getMonth(), currentDate.getDate() );
 // Format the dates as DD-MM-YYYY
 const formattedStartDate = formatDate(startDates);
 const formattedEndDate = formatDate(endDates);
 
 // Function to format date as DD-MM-YYYY
 function formatDate(date) {
   const day = String(date.getDate()).padStart(2, '0');
   const month = String(date.getMonth() + 1).padStart(2, '0');
   const year = date.getFullYear();
   return `${day}-${month}-${year}`;
 }
 
 
 /* -------------------------------- */
 
  




  /* For Generating +1 Day  for Cover from and Cover To Start Here */
  const userEnterDate1 = new Date(registeredModelValues&&registeredModelValues.policyExp)
  


let day1 = userEnterDate1.getDate();
let month1 = userEnterDate1.getMonth();
let year1 = userEnterDate1.getFullYear();

if (month1 === 11) {
  month1 = 0;
  year1 += 1;
} else if (month1 === 1) {
  let febDays = (year1 % 4 === 0 && (year1 % 100 !== 0 || year1 % 400 === 0)) ? 29 : 28;
  if (day1 === febDays) {
    day1 = 0;
      month1 += 1;
  }
} else {
  if (day1 === new Date(year1, month1 + 1, 0).getDate()) {
    day1 = 0;
      month1 += 1;
  }
}
let nextDate = new Date(year1, month1+1, day1 + 1);

// console.log('kalimayi',finalAddons==(''||null||undefined) || ((finalPolicyType==undefined||null)?oldPolicyType=="ownDamage":finalPolicyType=="ownDamage"),((finalPolicyType==undefined||null)?oldPolicyType=="ownDamage":finalPolicyType=="ownDamage"))


/* ---------------- Date Function For Old Vehicle start Here ---------------------  */


let dateString = CarIsRegistered&&registeredModelValues ? registeredModelValues.policyExp.split("-").reverse().join('-'):'2023-1-5'
// let dateString ='2023-1-5'
let dateParts = dateString.split("-");
let yearAdd1 = parseInt(dateParts[2]);
let monthAdd1 = parseInt(dateParts[1]) - 1;
let dayAdd1 = parseInt(dateParts[0]);

let dateAdd1 = new Date(yearAdd1, monthAdd1, dayAdd1);
dateAdd1.setDate(dateAdd1.getDate() + 1);

let newYear = dateAdd1.getFullYear();
let newMonth = String(dateAdd1.getMonth() + 1).padStart(2, "0");;
let newDay =  String(dateAdd1.getDate()).padStart(2, "0");

let newDateString = `${newDay}-${newMonth}-${newYear}`;
/* +1 year */

let dateCt1 = new Date(`${yearAdd1+1}-${monthAdd1+1}-${dayAdd1}`);
dateCt1.setDate(dateCt1.getDate());

 
let newCtYear = dateCt1.getFullYear();
let newCtMonth = String(dateCt1.getMonth() + 1).padStart(2, "0");;
let newCtDay =  String(dateCt1.getDate()).padStart(2, "0");

let newCtDateString = `${newCtDay}-${newCtMonth}-${newCtYear}`;

/* ------------------- */

/* -1 day */

let dateRem1 = new Date(`${newYear-1}-${newMonth}-${newDay}`);

dateRem1.setDate(dateRem1.getDate() -1);

 
let newRemYear = dateRem1.getFullYear();
let newRemMonth = String(dateRem1.getMonth() + 1).padStart(2, "0");;
let newRemDay =  String(dateRem1.getDate()).padStart(2, "0");

let newRemDateString = `${newRemDay}-${newRemMonth}-${newRemYear}`;


/* ---------------- Date Function For Old Vehicle End Here ---------------------  */

let newMonth1 = nextDate.getMonth()
  /* For Generating +1 Day  for Cover from and Cover To End Here */
const handleFilterData = (e)=>{
  e.preventDefault()
  if(inputs.coverAmount=='') {
    setCustBoxError(true )
    setClosePopUp(true);
  }else{
    setCustBoxError(false )
    errorMessage2==false&&  setClosePopUp(false);
    errorMessage2==false&&  changeAction&& localStorage.setItem('apiLoaded',false)
    sessionStorage.setItem("inputs_IDV",inputs.coverAmount)
    //  setPlaceHolderValue(inputs.coverAmount)
}
Object.values(inputs).length > 0 &&fetchIdvQuoteRsa()
Object.values(inputs).length > 0 &&fetchIdvQuoteReliance()
Object.values(inputs).length > 0 &&fetchIdvQuoteGodigit()
 

  }
  useEffect(()=>{
    // localStorage.setItem('apiData',JSON.stringify(apiData))

    const numberInput = document.getElementById(coverData);

   coverData&& numberInput.addEventListener('wheel', (event) => {
      if (numberInput === document.activeElement) {
        event.preventDefault();
      }
    });
  },[coverData])


  function GFG_FUN() {
    var input = document.getElementsByClassName(coverData);
      
    input.addEventListener("mousewheel", 
        function(event){ 
            this.blur() 
        });
    }

   

  return (
    <>
      {filterCardData.map((items, key) => (
        <>
          <div
            className={` ${parentClass} bg-white z-0  p-5 shadow-[2px_2px_50px_rgba(0,167,142,0.15)] rounded-lg`}
          >
            <div className="flex items-center pb-5">
              <h1 className="font-semibold text-lg ">{items.title}</h1>
              <div className="flex flex-1"></div>
              <AiOutlineClose
                className="bg-[rgba(0,167,142,0.1)] text-[#989898] w-8 h-8 p-2 rounded-full"
                onClick={() => {
                  setClosePopUp(false);
                }}
              />
            </div>
            <div className="">
              {items.type === "radio2" && (
                <form onChange={handleChange}>
                  <div className="space-y-4 ">
                    {items.radioData.map((item, key) => (
                      <div className=" ">
                      <div className="pb-2 flex items-center "   onChange={(e)=>{
                      }} >
                        <input
                          type="radio"
                          id={item.id}
                          value={item.value}
                          name={item.name}
                          onClick={(e)=>{
                           if(e.target.id=='6'){
                            setShowCustomBox(true)
                          }else{
                             setShowCustomBox(false)
                            
                           }
                          }}
                          defaultChecked={
                            item.id ==JSON.parse(radioValue).policy
                            // Object.values(firstCheckedData).length > 0==undefined ?JSON.parse(radioValue).policy:checkedData.policyData
                            //   ? firstCheckedData.policyData
                            //   : ""
                          }
                        />
                         {" "}
                          
                        <label for={item.id} className="text-sm lg:text-base lg:pr-3">
                          {item.title}
                        </label>
                       
                        </div>
                        
                      </div>
                    ))}
                    <div
                      className={`${items.radioInputClass} ${
                       showCustomBox ? "" : "hidden"
                      } `}
                    >
                      <label className="text-[11px]  text-[#989898]" htmlFor="">
                        {items.inputLabel}
                      </label>
                      <input
                      onScroll={ GFG_FUN}
                        onChange={(e) => {
 setCustBoxError(false)
                          setCstCvdata(e.target.value);
                          setCoverData(e.target.value);
                        }}
                        type="number"

                        id={coverData}
                        name='coverAmount'
                        value={cstCvdata}
                        className="w-full border px-4 my-1 number border-[#989898] focus:outline-none rounded-lg py-1"
                      />
                      {(items.errorMessage || custBoxError) && (
                        <h3 className="text-[10px] text-[#c95050]">
                          Please enter a value within the range mentioned above
                        </h3>
                      )}
                    </div>
                    <button
                      className={`${noChange==='true'?'':'opacity-[0.7]'} bg-hero text-white px-4 py-2 ml-auto  flex rounded-xl`}
                      onClick={handleFilterData}
                      disabled={noChange==='true'?false:true} 
                    >
                      {items.buttonName}
                    </button>
                  </div>
                </form>
              )}
            
            </div>

          
          </div>
        </>
      ))}
    </>
  );
};
