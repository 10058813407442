import React, { useState, useEffect } from "react";
import LandingBackground from "../../../assets/Svg/LandingPageBck.svg";
import MaleSvg from '../../../assets/Vector/Health/male.svg'
import FemaleSvg from '../../../assets/Vector/Health/female.svg'
import { Link, useNavigate } from "react-router-dom";
import NavHeader from "../../NavHeader";
import FormBar from "../../FormBar";


const HealthIntro1 = () => {
  const navigate = useNavigate();

  const [closeNav, setCloseNav] = useState(false)
  const [selectedGender, setSelectedGender] = useState()
  const [showError, setShowError] = useState(false)
 

  const genderValue = localStorage.getItem('health_gender')
  const handleSubmit = (e) => {
    e.preventDefault();

    if( ('male','female').includes(selectedGender)){
        setShowError(false)
         localStorage.setItem('health_gender',selectedGender)
        navigate("/healthinsurance/health-profile/prestep-2" );
    }else {
      setShowError(true)
    }

  };

  const handleAutodirect = (gender)=>{
    setSelectedGender(gender)
    localStorage.setItem('health_gender',gender)
    setTimeout(()=>{

      navigate("/healthinsurance/health-profile/prestep-2" );
    },500)

  }

useEffect(()=>{
  if(genderValue) {

    setSelectedGender(genderValue)
  }
},[genderValue])

  return (
    <>
      <NavHeader dropClose={ [closeNav, setCloseNav] } />
      <main
        className="w-full pb-10 lg:pt-8 bg-no-repeat bg-cover   "
        style={{ backgroundImage: `url(${LandingBackground})` }}  onClick={()=>{
          setCloseNav(false)
        }}
      >
        
        <form >
          <h1 className="text-xl lg:text-3xl text-[#293651] font-semibold  py-10   lg:pt-4 text-center">
            Health Insurance Quote
          </h1>
          {/* Steps Bar */}
          <div className="lg:w-[55%] rounded-t-md bg-[#aab8aa27] m-auto ">

<div className={`lg:w-[9.1%] rounded-t-md  bg-hero py-[3px]`}></div>
</div>

          <div className=" justify-center bg-white mx-5 lg:m-auto shadow-[0px_0px_25px_rgba(0,0,0,0.1)] lg:drop-shadow-lg lg:w-[55%] h-[500px   px-4 py-5">
          
            <h1 className="mr-auto font-medium text-solid lg:text-xl py-4 pl-4">
            What is your gender?
            </h1>
            <hr className="text-[#DBDBDB] pb-4" />
          <div className="py-4">
            <div className="brder border-[#cecec8] rounded-md  w-80 m-auto flex justify-center gap-10 cursor-pointer">
            <div className={` border-[#cecec8] m-auto py-5  brder-r w-40 `} onClick={()=>{handleAutodirect('male')}}>
            <img src={MaleSvg} alt="maleSvg" className={`w-32 ${selectedGender==='male'?'rounded-full bg-[#00a7592e]':""}  m-auto` } />
                <h1 className="text-center font-medium  text-sm pt-2">Male</h1>
            </div>
            <div className={` w-40 py-5`} onClick={()=>{handleAutodirect('female')}}>
                <img src={FemaleSvg} alt="femaleSvg" className={` ${selectedGender==='female'?'rounded-full bg-[#00a7592e]':""} w-32 m-auto`} />
                <h1 className="text-center font-medium text-sm pt-2">Female</h1>
            </div>
            </div>
          </div>
          <h1 className={`${showError?'':'hidden'} text-center pt-2 text-sm text-[#eb5648] `}>Please Select Gender</h1>

          </div>
          <div className="flex justify-center space-x-5 pt-8 ">
            <Link to="/healthinsurance">
            <button className="bg-[#293651]  w-24 py-2 rounded-full text-white">
              Back
            </button>
            </Link>
            <input
              type="submit"
              className="bg-hero w-24 py-2 rounded-full text-white cursor-pointer "
              onClick={handleSubmit}
              value="Next"
            />
          </div>
        </form>
      </main>
    </>
  );
};

export default HealthIntro1;
