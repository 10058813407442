import React, { useState, useEffect } from "react";
import LandingBackground from "../../assets/Svg/LandingPageBck.svg";
import { policyPartnerData } from "../../data/PolicyPartnerData";
import PolicyPlanCard from "../../UI/PolicyPlanCard";
import QuoteDetailPop from "../../UI/QuoteDetailPop";
import Footer from "../Footer";
import NavHeader from "../NavHeader";
import ScrollToTop from "../ScrollToTop";
import VehicleDetails from "../VehicleDetails";
import axios from "axios";
import QuoteLoading from "../../UI/QuoteLoading";
import QuotePolicyFilter2 from "../QuotePolicyFilter2";
import QuoteDetailPopTemp from "../../UI/QuoteDetailPopTemp";
import CardSkelton from "../../UI/CardSkelton";
import { PolicyFilterCard4 } from "./quotation/FilterTypes/PolicyFilterCard4";
import { BikeRelianceQuote1 } from "./quotation/relianceApis/BikeRelianceQuote1";
import { useSelector } from "react-redux";
import PolicyPlanCardBike from "../../UI/PolicyPlanCardBike";
import QuoteDetailPopBike from "../../UI/QuoteDetailPopBike";



const BikeQuotation = () => {
  const [closePopUp, setclosePopUp] = useState(true);
  const [isDetails, setIsDetails] = useState(false);
  const [vehicleNumber, setVehicleNumber] = useState('')
  const [bikeRegistrationNumber, setBikeRegistrationNumber] = useState('')
  const [count, setCount] = useState(1);
  const [firstLoader, setFirstLoader] = useState(true)
  const [addonsPlace, setAddonsPlace] = useState({})
  const [addons, setAddons] = useState(false);
  const [addonsChecked, setAddonsChecked] = useState('')
  const [isThirdParty, setIsThirdParty] = useState(false)

  const [quoteDetailsData, QuoteDetailsData] = useState();
  const [modelValue, setModelValue] = useState("");
  const [quoteLoading, setquoteLoading] = useState(false);
  const [apiData, setApiData] = useState({});
const [isApiLoaded, setIsApiLoaded] = useState(false)
const [load, setLoad] = useState(false)
  const [popUpsFilterData, setPopUpsFilterData] = useState({})
  const [BikeIsRegistered, setBikeIsRegistered] = useState()
  const [initialPolicyType, setInitialPolicyType] = useState({})
const [jwtToken, setJwtToken] = useState(localStorage.getItem('jwt_token'))
const [uniApiData, setUniApiData] = useState(JSON.parse(localStorage.getItem('apiData')))
const [api, setApi] = useState({})
const [tokenLoaded, setTokenLoaded] = useState(false)
  const [updateIdvValue, setUpdateIdvValue] = useState("0");
const [loader, setLoader] = useState()
const [loader1, setLoader1] = useState()
const [loadApiData, setLoadApiData] = useState({})
const [loadApiData1, setLoadApiData1] =useState({})
const [apiLoads, setApiLoads] = useState()
  const [bikeInfo, setBikeInfo] = useState({});
const [initialLoad, setInitialLoad] = useState(true)
const [masterID, setMasterID] = useState({})
const [modelRegionId, setModelRegionId] = useState({})
const [rtoRegionCode, setRtoRegionCode] = useState('')
const [manufactureYear, setManufactureYear] = useState()
const [oldPolicyType, setOldPolicyType] = useState()
const [registeredModelValues, setRegisteredModelValues] = useState({})
const [prevClaimValue, setPrevClaimValue] = useState()
const [closeNav, setCloseNav] = useState(false)
const [tileType, setTileType] = useState();
const [preCustomerDetails, setPreCustomerDetails] = useState()
const [ownership, setOwnership] = useState()
const [selectedNCB, setSelectedNCB] = useState(0);
const [inputStatus, setInputStatus] = useState(false)



  const filterData = localStorage.getItem("bike_popupsData");
  const bikeRegistered = localStorage.getItem("isBikeRegistered");
  const prevPolicyType = localStorage.getItem('bike_Info')
  const apiload = localStorage.getItem('api_Load')
  const universalApiData = localStorage.getItem('apiData')
  const masteridvalues= localStorage.getItem('bike_master_ID')
  const modelRegion = localStorage.getItem('bike_regionCode')
  const bikeRegNO = localStorage.getItem('bike_Intro')
  const radioValue = localStorage.getItem('bike_Info')
  let registeredModelValue= localStorage.getItem('bike_registered_model')
  let registeredModelClaimValue= localStorage.getItem('bike_registered_model_claim')
  let registeredModelOwnershipValue= localStorage.getItem('bike_registered_model_ownership')
  const tileValue = localStorage.getItem("tile_Type_Detail");
  const preCustDetails= localStorage.getItem('pre_BikeCustomerDetials')
  const policyplaceholder = sessionStorage.getItem('bike_inputs_policyType')
  const inputActive = sessionStorage.getItem("bikeinputSet")

  // localStorage.setItem('inputs_discount','')

  
/* -------------------  API ENDPOINT -------------- */
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
/* ---------------------------------- -------------- */

// Redux Management 
const { fetchBikeQuoteReliance ,callbackfun} = BikeRelianceQuote1();



useEffect(()=>{

  const availableNCBValues = [0, 20, 25, 35, 45, 50];
  const selectedValue = parseInt(registeredModelValues&&registeredModelValues.prevNCB, 10);
    if ( selectedValue< 50) {
      // Automatically select the next higher value
      setSelectedNCB(availableNCBValues[availableNCBValues.indexOf(selectedValue) + 1]);
    } else {
      // Keep the value as is if it's already the highest
      setSelectedNCB(50);
    }

},[  registeredModelValues])


  useEffect(()=>{

    setApiLoads(apiload)
    setPopUpsFilterData(JSON.parse(filterData))
    setBikeIsRegistered (JSON.parse(bikeRegistered))
    setInitialPolicyType(JSON.parse(prevPolicyType))
    setLoadApiData1(JSON.parse(universalApiData))
setMasterID(JSON.parse(masteridvalues))
setModelRegionId(JSON.parse(modelRegion))
bikeRegNO&&setBikeRegistrationNumber(JSON.parse(bikeRegNO).registrationNumber)
setManufactureYear(JSON.parse(radioValue).manufactureYear)
setOldPolicyType(JSON.parse(radioValue).policy)
setRegisteredModelValues(JSON.parse(registeredModelValue))   
setPrevClaimValue(registeredModelClaimValue)
setOwnership(registeredModelOwnershipValue)
setTileType(tileValue);
setPreCustomerDetails(JSON.parse(preCustDetails))
setIsThirdParty( policyplaceholder)
setInputStatus(inputActive)

  },[filterData, bikeRegNO, registeredModelOwnershipValue, tileValue, registeredModelClaimValue, radioValue, registeredModelValue, apiload, modelRegion, masteridvalues, bikeRegistered, universalApiData, prevPolicyType, policyplaceholder, preCustDetails, inputActive])

  useEffect(()=>{

    const vehicleNumberHandle = ()=>{
      let vehNo = ['']
      let spliting = bikeRegistrationNumber.split('')
      vehNo.unshift(spliting)
      setVehicleNumber( bikeRegistrationNumber.length<10? vehNo[0][0]+vehNo[0][1]+'-'+vehNo[0][2]+vehNo[0][3]+'-'+vehNo[0][4]+'-'+vehNo[0][5]+vehNo[0][6]+vehNo[0][7]+vehNo[0][8] : vehNo[0][0]+vehNo[0][1]+'-'+vehNo[0][2]+vehNo[0][3]+'-'+vehNo[0][4]+ vehNo[0][5]+'-'+vehNo[0][6]+vehNo[0][7]+vehNo[0][8]+vehNo[0][9])
      setRtoRegionCode(vehNo[0][0]+vehNo[0][1]+vehNo[0][2]+vehNo[0][3])
      // console.log(vehNo[0][0]+vehNo[0][1]+'-'+vehNo[0][2]+vehNo[0][3]+'-'+vehNo[0][4]+'-'+vehNo[0][5]+vehNo[0][6]+vehNo[0][7]+vehNo[0][8])
    }
    vehicleNumberHandle()
  },[bikeRegistrationNumber])


  let currentDate ;

// Add logic to set the date based on a condition
if (bikeRegistered==='true' ) {
  currentDate=(new Date(registeredModelValues&&registeredModelValues.policyExp) ); // Add one day
} else if (bikeRegistered==='false') {
  currentDate=(new Date() ); // Add two days
}
  let cDay = currentDate.getDate();
  let cMonth = currentDate.getMonth() + 1;
  let cYear = currentDate.getFullYear();
  let newCDay = currentDate.getDate()-1
  let endCDay = currentDate.getDate();
    
  if((cYear+1)%4===0&&endCDay==28) {
    endCDay+=1
  }
  
  
  const userEnterDate = new Date(registeredModelValues&&registeredModelValues.policyExp)
  let prevDay = userEnterDate.getDate()
    let prevvDay = userEnterDate.getDate()+1
  
  let prevMonth = userEnterDate.getMonth() + 1;
  let prevYear = userEnterDate.getFullYear();
  let prevActDay =userEnterDate.getDate()
  let tpPrevYear =userEnterDate.getFullYear()-1
  if((prevYear+1)%4===0&&prevDay==28) {
    prevDay+=1
  }
  
    var dateFormatTotime = new Date(userEnterDate);
    var increasedDate = new Date(dateFormatTotime.getTime() +(1 *86400000) );
  
    // var dateString = 'Mon Jun 30 2014 00:00:00';
  
    var startDate = new Date(registeredModelValues&&registeredModelValues.policyExp);
    
    // seconds * minutes * hours * milliseconds = 1 day 
    var day = 60 * 60 * 24 * 1000;
    
    var endDate = new Date(startDate.getTime() + day);
    var toCurDate = new Date(currentDate.getTime()+day)

    var fromPrevDay = new Date(userEnterDate.getTime()+day)


    
    
    
 /* For Generating +1 Day  for Cover from and Cover To Start Here for New vehicle */

  



//  if (cMonth === 11) {
//    cMonth = 0;
//    cYear += 1;
//  } else if (cMonth === 1) {
//    let febDays = (cYear % 4 === 0 && (cYear % 100 !== 0 || cYear % 400 === 0)) ? 29 : 28;
//    if (cDay === febDays) {
//      cDay = 0;
//      cMonth += 1;
//    }
//  } else {
//    if (cDay === new Date(cYear, cMonth + 1, 0).getDate()) {
//      cDay = 0;
//      cMonth += 1;
//    }
//  }
 let nextDateNew = new Date(cYear, cMonth+1, cDay + 1);
 
 let newMonth1New = nextDateNew.getMonth()





/* ---------------- Date Function For Old Vehicle start Here ---------------------  */


let dateString = BikeIsRegistered&&registeredModelValues ? registeredModelValues.policyExp.split("-").reverse().join('-'):'2023-1-5'
// let dateString ='2023-1-5'
let dateParts = dateString.split("-");
let yearAdd1 = parseInt(dateParts[2]);
let monthAdd1 = parseInt(dateParts[1]) - 1;
let dayAdd1 = parseInt(dateParts[0]);

let dateAdd1 = new Date(yearAdd1, monthAdd1, dayAdd1);
dateAdd1.setDate(dateAdd1.getDate() + 1);

let newYear = dateAdd1.getFullYear();
let newMonth = String(dateAdd1.getMonth() + 1).padStart(2, "0");;
let newDay =  String(dateAdd1.getDate()).padStart(2, "0");

let newDateString = `${newDay}-${newMonth}-${newYear}`;
/* +1 year */

let dateCt1 = new Date(`${yearAdd1+1}-${monthAdd1+1}-${dayAdd1}`);
dateCt1.setDate(dateCt1.getDate());

 
let newCtYear = dateCt1.getFullYear();
let newCtMonth = String(dateCt1.getMonth() + 1).padStart(2, "0");;
let newCtDay =  String(dateCt1.getDate()).padStart(2, "0");

let newCtDateString = `${newCtDay}-${newCtMonth}-${newCtYear}`;

/* ------------------- */

/* -1 day */

let dateRem1 = new Date(`${newYear-1}-${newMonth}-${newDay}`);

dateRem1.setDate(dateRem1.getDate() -1);

 
let newRemYear = dateRem1.getFullYear();
let newRemMonth = String(dateRem1.getMonth() + 1).padStart(2, "0");;
let newRemDay =  String(dateRem1.getDate()).padStart(2, "0");

let newRemDateString = `${newRemDay}-${newRemMonth}-${newRemYear}`;


/* ---------------- Date Function For Old Vehicle End Here ---------------------  */



/* For Generating +1 Day  for Cover from and Cover To Start Here */
  


let day1 = userEnterDate.getDate();
let month1 = userEnterDate.getMonth();
let year1 = userEnterDate.getFullYear();

if (month1 === 11) {
  month1 = 0;
  year1 += 1;
} else if (month1 === 1) {
  let febDays = (year1 % 4 === 0 && (year1 % 100 !== 0 || year1 % 400 === 0)) ? 29 : 28;
  if (day1 === febDays) {
    day1 = 0;
      month1 += 1;
  }
} else {
  if (day1 === new Date(year1, month1 + 1, 0).getDate()) {
    day1 = 0;
      month1 += 1;
  }
}
let nextDate = new Date(year1, month1+1, day1 + 1);

let newMonth1 = nextDate.getMonth()
  /* For Generating +1 Day  for Cover from and Cover To End Here */

  useEffect(()=>{
  // setUniApiData (JSON.parse(universalApiData))
},[])

// useEffect(()=>{
//   if(apiLoads=='false'){
//     setLoader1(true)
//   }
//   if(apiLoads=='true') {
//     setLoader1(false)
//   }
// },[apiLoads])
useEffect(()=>{

  if(isDetails){

    document.body.style.overflow = "hidden";
  }  else {
    document.body.style.overflow = "visible";
  }
  if(Object.keys(loadApiData).length<1){
    setInitialLoad(true)
  }
  else {
    setInitialLoad(false)
  }


},[isDetails, loadApiData])
// console.log('lousa',Object.values(masterID).length,masterID,load)
  
useEffect(()=>{
  if(Object.values(masterID).length>0){
    setLoad(true)
  }
},[masterID])

/* ----------------------------------------------------- */
const startDates = new Date(currentDate);
startDates.setDate(currentDate.getDate()+1);

// Calculate the end date (coverTo) as the same day next year, adjusting for leap years
const nextYear = currentDate.getFullYear() + 1;


/* ----------------------------------- */
function getDaysInFebruaryNextYear() {
  const currentYear = new Date().getFullYear();
  const nextYear = currentYear + 1;

  // Create a date for March 1st of the next year
  const marchFirstNextYear = new Date(nextYear, 2, 1);

  // Subtract one day to get the last day of February in the next year
  const lastDayOfFebruaryNextYear = new Date(marchFirstNextYear - 1);

  // Get the day of the month to determine the number of days in February
  return lastDayOfFebruaryNextYear.getDate();
}

const daysInFebruaryNextYear = getDaysInFebruaryNextYear();
/* ------------------------------------------------ */


const isNextYearLeap = new Date(nextYear, 1, 29).getDate() === 29;
const endDates = isNextYearLeap && nextYear%4===0&&daysInFebruaryNextYear===29&&currentDate.getMonth()===1
  ? new Date(nextYear, 1, 29)
  : new Date(nextYear, currentDate.getMonth(), currentDate.getDate() );
// Format the dates as DD-MM-YYYY
const formattedStartDate = formatDate(startDates);
const formattedEndDate = formatDate(endDates);

// Function to format date as DD-MM-YYYY
function formatDate(date) {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
}


/* -------------------------------- */


useEffect(() => {
  const onStorage = () => {
    setUniApiData(JSON.parse(localStorage.getItem('apiData')))
    setIsApiLoaded(localStorage.getItem('apiLoaded'))
  };

  window.addEventListener('storage', onStorage);

  return () => {
      window.removeEventListener('storage', onStorage);
  };
}, []);

useEffect(() => {
  const onStorage = () => {
   setJwtToken(localStorage.getItem('jwt_token'))
  };

  window.addEventListener('storage', onStorage);

  return () => {
      window.removeEventListener('storage', onStorage);
  };
}, []);

/* --------------- Redux Setup -------------------- */

let isFirstApiCallReliance = useSelector(state=>state.bikeQuoteReliance.firstLoader)

/* ---------------------------------- */


useEffect(()=>{
  // inputStatus&&isFirstApiCallRsa&& fetchQuoteRsa()
  // inputStatus&&  isFirstApiCallGodigit&&fetchQuoteGodigit()
  // inputStatus&& isFirstApiCallReliance&& fetchBikeQuoteReliance()
  },[isFirstApiCallReliance, inputStatus])


/* -------------------------------- for OwnerPaDriver Logic ------------------- */
useEffect(()=>{

  sessionStorage.setItem('bike_input_addons_check', JSON.stringify({ownerDriverPAcover: oldPolicyType == "ownDamage" ?false:true}));
  sessionStorage.setItem('bike_last_input_addons_check', JSON.stringify({ownerDriverPAcover: oldPolicyType == "ownDamage" ?false:true}));
  // sessionStorage.setItem("first_call",false)
  
  
  },[oldPolicyType])

  // useEffect(()=>{
  //   inputStatus&&isFirstApiCallRsa&& fetchQuoteRsa()
  //   inputStatus&&  isFirstApiCallGodigit&&fetchQuoteGodigit()
  // },[inputStatus,isFirstApiCallGodigit,isFirstApiCallReliance,isFirstApiCallRsa])
  // Use useEffect to start interval when component mounts
  // console.log('inputstatus',inputStatus)
//   useEffect(()=>{
//  fetchBikeQuoteReliance()
  
//     },[])

//     useEffect(() => {
//       const intervalId = setInterval(() => {
//         // Check if inputs are valid
//         if ( inputStatus!=='true') {
//           callbackfun() // Call handleSubmit if inputs are valid
//         }
//       }, 1000); // Interval set to 5 seconds (adjust as needed)
  
//       // Cleanup function to clear interval when component unmounts
//       return () => clearInterval(intervalId);
//     }, [callbackfun, inputStatus]); // Run effect whenever inputsValid changes

useEffect(()=>{

  
  /* ------------ For Getting Prev Insurer Names ---------------- */
  axios.get(`${API_ENDPOINT}/all/insurance/name`, {
      headers: {
        Authorization: `${jwtToken}`,
        'Content-Type': 'application/json',
      },
  },).then((response)=>{

    const values = []
    response.data.map((item)=>(
      values.push(item.standardPreviousInsurerName)
    ))
    // setPrevInsurerData(values)
    localStorage.setItem('prev_policy_names',JSON.stringify(values))
    // response.data.standardPreviousInsurerName.map((item)=>(
      // setLoadAgain(true)
    //   (item)
    // ))
    })
    .catch((err)=>{
      console.log(err)
    })


  },[])


  useEffect(()=>{
    // if(Object.keys(defSessionQuoteVal).length>0)  {
  
    //   dispatch(quoteActions.setApiDataValues(defSessionQuoteVal.rsaQuoteResponse ))
   
    // } 
  
      
      sessionStorage.setItem('bike_sessQuoApiVal', JSON.stringify({
        relianceQuoteResponse:{},
        goDigitQuoteResponse:{},
        rsaQuoteResponse:{},
        
        
      }))
   
  },[])


useEffect(()=>{
setLoader1(true)
     
load&& axios
 .post(
   `${API_ENDPOINT}/quotes`,
   
     

     //  For RSA
     {



      
    
      
/////////////////////////////////////////////////
      "royalSundaramBikeQuote": {
        "premium": 0.0,
        "proposerDetails": {
            "title": "Mr",
            "firstName": "Sumit",
            "lastName": "Bansal",
            "emailId":preCustomerDetails&& preCustomerDetails.email,
            "userID": 0,
            "mobileNo": "7010226240",
            "dateOfBirth": "23/09/1989",
            "occupation": "Others",
            "nomineeName": "Vanila Garg",
            "nomineeAge": "29",
            "relationshipWithNominee": "Spouse",
            "permanentAddress1": "H.No ",
            "permanentAddress2": "1288,Phase-2,Ramdarbar",
            "permanentCity": "CHANDIGARH",
            "permanentPincode": "160002",
            "sameAdressReg": "Yes",
            "residenceAddressOne": null,
            "residenceAddressTwo": null,
            "residenceCity": null,
            "residencePinCode": null
        },
        "vehicleDetails": {
            "engineNumber": "",
            "chassisNumber": "",
            "registrationNumber":  vehicleNumber.toUpperCase().split('-').join(''),
            "isTwoWheelerFinanced": "No",
            "vehicleSubLine": "motorCycle",
            "vehicleModelCode": masterID.rsaModelCode[0],
            // "vehicleModelCode": "ZWTV1535",
            "planOpted": "Flexi Plan",
            "yearOfManufacture": manufactureYear,
            "drivingExperience": "1",
            "voluntaryDeductible": '',
            "isValidDrivingLicenseAvailable": "Yes",
            "hdnDepreciation": false,
            "hdnEngineProtector": false,
            "coveredParkingOrGroundParking": "No",
            "coveredParkingNotBasement": "No",
            "unknownParking": "No",
            "hdnFullInvoice": false,
            "fullInvoicePlan1": "No",
            "fullInvoicePlan2": "No",
            "vehicleManufacturerName": "",
            "idv": 0,
            "idvFor2Year": 0,
            "idvFor3Year": 0,
            "idvFor4Year": 0,
            "idvFor5Year": 0,
            "discountIdvPercent": 0,
            "modifiedIdv": 0,
            "discountIDVPercent2Year": 0,
            "modifiedIDVfor2Year": 0,
            "discountIDVPercent3Year": 0,
            "modifiedIDVfor3Year": 0,
            "discountIDVPercent4Year": 0,
            "modifiedIDVfor4Year": 0,
            "discountIDVPercent5Year": 0,
            "modifiedIDVfor5Year": 0,
            "vehicleMostlyDrivenOn": "City roads",
            "vehicleRegDate":  bikeInfo.registrationDate.split('-').reverse().join('/'),
            "vehicleRegisteredInTheNameOf": "Individual",
            "modelName": "",
            "productName":BikeIsRegistered ?"RolloverTwoWheeler":"BrandNewTwoWheeler",
            "typeOfCover": BikeIsRegistered?  (oldPolicyType=="comprehensive" && "Comprehensive") || (oldPolicyType=="thirdParty"&&"LiabilityOnly") || (oldPolicyType=="ownDamage"&&"standalone")    : oldPolicyType=='bundled'?"Bundled":"LiabilityOnly",
            "fuelType": "Petrol",
            "region": "East Region",
            "carRegisteredCity": "",
            "isProductCheck": "true",
            "engineCapacityAmount": "",
            "personalAccidentCoverForUnnamedPassengers": "",
            "accidentCoverForPaidDriver": "",
            "legalliabilityToPaidDriver": "No",
            "legalliabilityToEmployees": "No",
            "noClaimBonusPercent": "",
            "ncbcurrent": "",
            "claimAmountReceived": "0",
            "claimsReported": "0",
            "ncbprevious": ((registeredModelValues&&(prevClaimValue=='Yes'||registeredModelValues.prevNCB==(null || undefined))))?0:`${registeredModelValues&&+registeredModelValues.prevNCB}`,
            "vechileOwnerShipChanged":ownership=='Yes'?'Yes': "No",
            "cpaCoverisRequired": "Yes",
            "cpaPolicyTerm": 0,
            "cpaCoverDetails": {
                "noEffectiveDrivingLicense": false,
                "cpaCoverWithInternalAgent": true,
                "standalonePAPolicy": false
            },
            "rtoName": modelRegionId.rsa.rtoName,
            "addlDiscount": 0,
            "addOnsOptedInPreviousPolicy": "",
            "previousPolicyNo": "",
            "previousInsurerName": "",
            "previousPolicyType": "comprehensive",
            "isPreviousPolicyHolder": "false",
            "previousPolicyExpiryDate": BikeIsRegistered? (prevActDay<10 ? `0${prevActDay}` :prevActDay)    + "/" + (prevMonth<10 ? `0${prevMonth}` : prevMonth) + "/" + prevYear:'' ,
            "claimsMadeInPreviousPolicy": prevClaimValue=='Yes'?'Yes':'No',
            "policyTerm": "",
            "validPUCAvailable": "Yes",
            "pucnumber": "",
            "pucvalidUpto": "29/12/2023"
        },
        "source": "STP",
        "utmSource": "",
        "utmMedium": "",
        "utmCampign": "",
        "utmTerm": "",
        "utmContent": "",
        "employeeCode": "",
        "branchCode": "",
        "empBranchCode": "",
        "regionCode": "",
        "posOpted": false,
        "authenticationDetails": {
            "apikey": "310ZQmv/bYJMYrWQ1iYa7s43084=",
            "agentId": "AG023760"
        }
    },

   
  
     // FOR RELIANCE

     "businessType": BikeIsRegistered ?"5":'1',
     "vehicleMakeId":masterID.reliance[0].makeId,
     "vehicleModelId":  masterID.reliance[0].modelId,
   
   
     "rtoLocationId":   Math.floor(modelRegionId.reliance.modelRegionId),
     "stateOfRegistrationId":  Math.floor(modelRegionId.reliance.stateId),
     "rtoRegionCode":modelRegionId.reliance.regionCode.replace('-',''),
     "zone": modelRegionId.reliance.modelZoneName,
     "seatingCapacity": 2,
     "licencedCarryingCapacity": 0,
     "noOfWheels": 2,
     "exShowroomPrice": 0,
     "idv": 0,
     "registrationNumber":BikeIsRegistered? vehicleNumber.toUpperCase(): `${modelRegionId.reliance.regionCode}-NEW`,
     "manufacturerMonth": 1,
     "manufacturerYear": manufactureYear,
     "dateOfPurchase":bikeInfo.registrationDate,
     "typeOfFuel": (initialPolicyType.fuelType == 'petrol'&&1) ||(initialPolicyType.fuelType == 'diesel'&&2)||(initialPolicyType.fuelType == 'petrol+cng'&&5)||(initialPolicyType.fuelType == 'electric'&&6),
     "branchCode": 9202,
     "productCode":  BikeIsRegistered?  (oldPolicyType=="comprehensive" && 2312) || (oldPolicyType=="thirdParty"&&2348) || (oldPolicyType=="ownDamage"&&2308)    : oldPolicyType=='bundled'?2375:2370 ,

     "coverFrom":    formattedStartDate.split("-").reverse().join('-'), // user inpuut 
     
     "coverTo":  formattedEndDate.split("-").reverse().join('-') ,

     "otherSystemName": 1,
     "clientType": 0,
     "agentName": "Direct",
     "ncbReservingLetter": "string",
     "miscTypeOfVehicleId": 0,
     "userId": "100002",

     "isPAToDriverCovered": false,
     "paToDriverCoveredNoOfItems": 1,
     "paToDriverCoveredSumInsured": 200000,
     "isPAToUnNamedPassenger": false,
     "paToUnNamedPassengerCoveredNoOfItems": 2,
     "paToUnNamedPassengerCoveredSumInsured": 100000,
     "isPAToNamedPassengerCovered": false,
     "paToNamedPassengerCoveredNoOfItems": 2,
     "paToNamedPassengerCoveredSumInsured": 100000,
     "isLiabilityToPaidDriverCovered": false,
     "liabilityToPaidDriverCoveredNoOfItems": 1,

     "isTPPDCover": oldPolicyType=="ownDamage"?false: true,
     "tppdSumInsured": 6000,
     "tppdIsChecked":oldPolicyType=="ownDamage"?false: true,
     "isVoluntaryDeductableOpted": false,
     "voluntaryDeductibleSumInsured": 0,
     "isNilDepreciation": false,
     "nilDeprectiationApplicableRate": 1.3,
     "isSecurePlus": false,
     "securePlusApplicableRate": 1.5,
     "isSecurePremium": false,
     "securePremiumApplicableRate": 0.95,
     "isAntiTheftDeviceFitted": false,
     "antiTheftIsChecked": 3,
     "antiTheftNoOfItems": 0,
     "isMotorQuote": false,
     "isHavingValidDrivingLicense": true,
     "isMotorQuoteFlow": false,

     "isElectricalItemFitted": false,
     "electricalItemsTotalSi": 1000000,
     "isNonElectricalItemFitted": false,
     "nonElectricalItemsTotalSi": 100,


     "ncbEligibilityCriteria": prevClaimValue=='Yes'?'1':'2', // popupwindow
     "previousNcb":((registeredModelValues&&registeredModelValues.prevNCB==(null || undefined))||prevClaimValue=='Yes')?0:registeredModelValues&&registeredModelValues.prevNCB,
     "isNcbApplicable":prevClaimValue == "Yes" ? false : true, // yes ? true : false
 

     "isPAToOwnerDriverCovered":  oldPolicyType=="ownDamage"?false: true,
    //  "nomineeName": "",
    //  "nomineeAddress": "",
    //  "nomineeRelationship": "",
    //  "nomineeDob": "",
    //  "cpaCovertenure": 1,
    //  "prevYearInsurer": 4,


     "prevYearPolicyEndDate":prevYear  + "-" + (prevMonth<10 ? `0${prevMonth}` : prevMonth) + "-" +   (prevActDay<10 ? `0${prevActDay}` :prevActDay) ,

     "prevYearPolicyNo": "8686948888564865",

     "prevYearPolicyStartDate": tpPrevYear + "-" + (prevMonth<10 ? `0${prevMonth}` : prevMonth) + "-" +   (prevvDay<10 ? `0${prevvDay}`: (fromPrevDay.getDate()<10? `0${fromPrevDay.getDate()}` :fromPrevDay.getDate() )),
     "isPreviousPolicyDetailsAvailable": "true",
     "prevYearPolicyType": "1",
     "sourceSystemId": "100002",
     "authToken": "Pass@123",
     "basicLiability": oldPolicyType=="ownDamage"?false: true, 

     "newVehicle":  BikeIsRegistered ?false:true,
     "basicODCoverage": oldPolicyType=="thirdParty"?false:true,
     "biFuelIsMandatory": false,
     "biFuelSumInsured": 100.0,


      "biFuelFuelType": initialPolicyType.fuelType ==("petrol+cng"||'cng')?"CNG":"LPG",
      "biFuelKit": initialPolicyType.fuelType == ('petrol+cng'||'petrol+lpg')?true:false,
      "biFuelIsChecked": initialPolicyType.fuelType == ('petrol+cng'||'petrol+lpg')?true:false,
      "biFuelIsLpgCng": initialPolicyType.fuelType == ('petrol+cng'||'petrol+lpg')?true:false,
 
      
    "name": preCustomerDetails&&preCustomerDetails.name,
    "phoneNo":preCustomerDetails&&preCustomerDetails.mobileNo,
    "email":preCustomerDetails&& preCustomerDetails.email,

     




     // FOR GODIGIT

        "enquiryId": "GODIGIT_CQ_TWO_WHEELER_PACKAGE_01",
        'insuranceProductCode':BikeIsRegistered? (initialPolicyType.policy=="comprehensive" && 20201) || (initialPolicyType.policy=="thirdParty"&&20202) || (initialPolicyType.policy=="ownDamage"&&20203)    : initialPolicyType.policy=='bundled'?20201:20202  , // product code from type of policy
        'subInsuranceProductCode': BikeIsRegistered?'PB':initialPolicyType.policy=='bundled'?51:50,
     "policyHolderType": "INDIVIDUAL",
     "voluntaryDeductible": "ZERO", // discount two thousand and five hundred from Discount filter
     "licensePlateNumber": BikeIsRegistered?  vehicleNumber.replaceAll('-','').toUpperCase() : `${modelRegionId.goDigit.regionCode.replace('-','')}`, // registration number if new vehicle : RTOCODE EX MH12NEW
     "vehicleMaincode":masterID.goDigitVehicleCode[0], // MASTER ID goDigitVehicleCode
     "pinCode": null, 
     "digitRegistrationDate": initialPolicyType&&initialPolicyType.registrationDate, // manufacture year
     "digitManufactureDate":  initialPolicyType&&initialPolicyType.registrationDate, // manufacture year
     "digitIsNewVehicle":  BikeIsRegistered? false: true, // false old vehicle  // not working if i add true
     "vehicleIDV": null,// idv filter type
     "motorType": null, 
     "vehicleIdentificationNumber": null,
     "engineNumber": null,
     "isPreviousInsurerKnown":  BikeIsRegistered? true: false,
     "previousInsurerCode": null,
     "previousPolicyNumber": null,
     "previousPolicyExpiryDate": prevYear  + "-" + (prevMonth<10 ? `0${prevMonth}` : prevMonth) + "-" +  (prevDay<10? `0${prevDay}` :prevDay ), // from pop up expiry
     "isClaimInLastYear":  BikeIsRegistered? prevClaimValue=='Yes'?true:false :false, // yes or no from pop up
    
   
     "originalPreviousPolicyType": "1OD_5TP",
     "previousPolicyType": BikeIsRegistered? (initialPolicyType.policy=="comprehensive" && 20201) || (initialPolicyType.policy=="thirdParty"&&20202) || (initialPolicyType.policy=="ownDamage"&&20203)    : initialPolicyType.policy=='bundled'?20201:20202 ,
    //  'previousNoClaimBonus':,
    "previousNoClaimBonus": BikeIsRegistered?  ((registeredModelValues&&registeredModelValues.prevNCB==(null || undefined))||prevClaimValue=='Yes')?'ZERO':`${registeredModelValues&&+registeredModelValues.prevNCB}`:'ZERO',// previous ncb  need api
     "currentThirdPartyPolicy": null,
     startDate:  formattedStartDate.split("-").reverse().join('-'), // cover from
     endDate: formattedEndDate.split("-").reverse().join('-'), 
     
     
     "isNCBTransfer": null,
     "thirdPartyLiabilityIsTppd": (['thirdParty',"ownDamage"]).includes(initialPolicyType&&initialPolicyType.policy) ?false: true,
     "discountUserSpecialDiscountPercent": 0,
     "discountDiscounts": [],
     "surchargeLoadings": [],


     "personalAccidentSelection": true, // owner pa driver ? true:false
     "personalAccidentInsuredAmount": null,
     "personalAccidentCoverTerm": 1,
     "cngSelection": false, //bifuel is true 
     "cngInsuredAmount": null,

     "electricalSelection": false, //electric true 
     "electricalInsuredAmount": null,
     "nonElectricalSelection": false, // non electric true :false
     "nonElectricalInsuredAmount": null,
     "partsDepreciationClaimsCovered": null, // zero depriciation
     "partsDepreciationSelection": false,
     "roadSideAssistanceSelection": false, // road side true
     "engineProtectionSelection": false, // engine protect true
     "tyreProtectionSelection": false, // tyre protec
     "rimProtectionSelection": false,
     "returnToInvoiceSelection": false, //return to invoice
     "consumablesSelection": false, // consumble 
     "paidDriverLlSelection": false, // paid driver cover
     "paidDriverLlInsuredCount": null, // if above true ? 1 : null
     "unNamedPaidDriverSelection": false, // un named driver
     "unNamedPaidDriverInsuredAmount": null,
     "unNamedPaidDriverInsuredCount": null,
     "unnamedPaxSelection": false,
     "unnamedPaxInsuredAmount": 100000,
     "unnamedPaxInsuredCount": 2,
 

   },
   
   {
     headers: {
       'authorization':jwtToken,
       "Content-Type": "application/json",
       accept: "application/json",
     "x-rsa-type":1
     }
   }
 )
 .then((res) => {
  setFirstLoader(false)
setApiData(res);
setLoader1(false)
setLoadApiData1(res)
// setIsApiLoaded(true)
// localStorage.setItem('apiLoaded',isApiLoaded)
localStorage.setItem('apiLoaded',true)


localStorage.setItem('apiData',JSON.stringify(res))
sessionStorage.setItem('bike_relianceIdvs',JSON.stringify([res.data.relianceQuoteResponse.MotorPolicy.MinIDV,res.data.relianceQuoteResponse.MotorPolicy.MaxIDV]))
  
 })

 .catch((error) => console.log(error));


},[load])

  


useEffect(()=>{

  // sessionStorage.removeItem('bike_inputs_policyType')
  // sessionStorage.removeItem('bike_check_inputs')
  // sessionStorage.removeItem('bike_input_addons_check')
  // sessionStorage.removeItem('bike_inputs_IDV')
  // sessionStorage.removeItem('bike_inputs_discount')
},[])

   useEffect(() => {
    localStorage.setItem(
      "addonsPop",
      JSON.stringify({})
    );
     const updatedIDVValue = localStorage.getItem("bike_popupsData");
    

  }, [bikeRegistered,BikeIsRegistered,isApiLoaded,initialPolicyType.policy,filterData,jwtToken,tokenLoaded]);
  useEffect(() => {
    const bikeDetails = localStorage.getItem("bike_Info");
    const model = localStorage.getItem("bike_Info_make_model");

    setBikeInfo(JSON.parse(bikeDetails));
    setModelValue(model);
  }, []);
  useEffect(()=>{
    localStorage.setItem('apiLoaded',quoteLoading)
  },[quoteLoading])

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Update the count value and reset to 1 when it reaches 3
      setCount((prevCount) => (prevCount % 3) + 1);
    }, 850); // Interval is set to 1000 milliseconds (1 second)

    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures that the effect runs only once on component mount


  return (
    <>
      <NavHeader dropClose={ [closeNav, setCloseNav] } />
      {isDetails && (
        <> 
        <div
          className={` absolute bg-[rgba(98,97,97,0.43)] w-full h-screen  left z-20 overflow-hidden`} onClick={()=>{
            setIsDetails(!isDetails)
          }}
        >
          </div>
          <div
            className=" lg:absolute flex flex-col w-full lg:h-full justify-center  overflow-x-hidden items-center"
            onClick={() => {
              setclosePopUp(!closePopUp);
            }}
          >
            <ScrollToTop>
              <QuoteDetailPopBike
                detailsData={uniApiData}
                detailsPop={[isDetails, setIsDetails]}
                selectedNcb = {selectedNCB}
              />
            </ScrollToTop>
          </div>
      </>
      )}
      <main
        className={`lg:w-full px-5 lg:px- 32 pt- bg-no-repeat bg-cover lg:overflow-x-hidden ${isDetails?'hidden lg:block  ':''}`}
        style={{ backgroundImage: `url(${LandingBackground})` }}  onClick={()=>{
          setCloseNav(false)
        }}
      >
        {/* Vehicle Details */}
        <div className="lg:flex gap-4">
        <div className="">
        <div className="pt-5 ">
          <VehicleDetails
            details={[modelValue, bikeInfo.manufactureYear, bikeInfo.fuelType]}
            editURL="/bikeinsurance/bike-profile/prestep-2"
          />
        </div>
        {/* Policy Filter */}
        <div className="py-4 lg:h-screen ">
          <QuotePolicyFilter2
            quoteLoaded={quoteLoading}
            // quoteData={apiData}
            isPopOpen={[closePopUp, setclosePopUp]}
loading={[loader, setLoader]}
       loadingData={[loadApiData, setLoadApiData]}
            detailsPop={[isDetails, setIsDetails]}
            objPolicy={policyPartnerData}
            redirectUrl="/bikeinsurance/bike-profile/step-1"
            quoteData={uniApiData}
            dataLoading={quoteLoading}
            quoteDetailDatas={[quoteDetailsData, QuoteDetailsData]}
          />
        </div>
        </div>

        <div className="">
          {/* <h2 className="text-sm lg:text-lg font-semibold">
            {policyPartnerData.length} Plans
          </h2> */}
          {/* <div className="flex text-xs  space-x-1">
            <h2 className="text-[#808080] ">found for Order Id : </h2>
            <p className="text-hero font-semibold"> MGPN3UFXF28</p>
          </div> */}
        </div>
        <hr className="text-[#CECECE] my" />



      {/* ----------------- tile for Initial Quote Request ------------ */}
      <div className="md:w-[51%] md:m-auto lg:m-0">
    
        <div className={` ${loader==true||false?'hidden':''} ${initialLoad?'':'hidden'} ${loader1?'hidden':''}  pt-5 pb-8`}>
        <PolicyPlanCardBike
            detailsPop={[isDetails, setIsDetails]}
            objPolicy={policyPartnerData}
            redirectUrl='/kyc'
            dataLoading={ [loader1, setLoader1]}
            quoteData={[apiData, setApiData]}
            quoteDetailData={[quoteDetailsData, QuoteDetailsData]}
          />
        </div>
        <div className={`${initialLoad ?'':'hidden'} ${loader1?'':'hidden'} pt-5 pb-8`}>
       
        <div className='flex flex-col gap-y-5'> 
      
      {Array(count).fill(1).map((_,i)=>(
        <div className="">

      
        <CardSkelton />
        </div>
      ))}
     
        </div>
        </div>

        

        {/* ----------------- End tile Here -------------------------- */}

        {/* ------------- tile for Filter type quote request ----------------- */}
        <div className={` ${loader?'hidden':''}  ${initialLoad?'hidden':''}    pt-5 pb-8`}>
        <PolicyPlanCardBike
            detailsPop={[isDetails, setIsDetails]}
            objPolicy={policyPartnerData}
            redirectUrl= '/kyc'
            dataLoading={ [loader, setLoader]}
            quoteData={[loadApiData, setLoadApiData]}
            quoteDetailData={[quoteDetailsData, QuoteDetailsData]}
          />
        </div>
        <div className={`${loader?'':'hidden'} ${firstLoader?'hidden':''}   pt-5 pb-8`}>
       
        <div className='flex flex-col gap-y-5'> 
      
      {Array(count).fill(1).map((_,i)=>(
        <div className="">

      
        <CardSkelton />
        </div>
      ))}
     
        </div>
        </div>

        {/* ----------------------- End of tile here -------------- */}
        </div>
        
<div className={`${ (isThirdParty == (undefined || null)
                  ? oldPolicyType == "ownDamage"
                  : isThirdParty == "ownDamage")?'hidden' : ''}   `}>
 
                


<PolicyFilterCard4
                parentClass=" w-80 lg:w-[19%] popup-div  lg:mt-5  desktop-view overflow-x-hidden overflow-y-auto   hidden lg:block  lg:absolute lg:right-5 lg:left-[-1 23px]  "
                loading ={ [loader, setLoader]}
                loadingData ={[loadApiData, setLoadApiData]}
                placeValue={[addonsPlace, setAddonsPlace]}
                  closeState={[addons, setAddons]}
                  addsData={[addonsChecked, setAddonsChecked]}
                 filterCardData={ (isThirdParty == (undefined || null)
                  ? oldPolicyType !== "thirdParty"
                  : isThirdParty !== "thirdParty")?[


                    {
                      id: "1",
                      title: "Add-Ons Cover",
                      type: "checkbox",
                      buttonName: ["Reset", "Apply"],
                      checkBoxData: [
                        // {
                        //   id: "1",
                        //   label: "PA Owner Drive   ",
                        //   name: "paOwnerDriver"
                        // },
                       
                       
                        
                        {
                          id: "13",
                          label: "Paid Driver cover",
                          name: "paidDrivercover",
                          checkalt:true,
                          checkText:'Suminsured Amount : 200000',
                          checkClass:'-right-4  -top-12 lg:-top-10'

                        },
                        
                        {
                          id: "14",
                          label: "Owner Driver PA cover",
                          name: "ownerDriverPAcover",
                          check:true,
                          checkText:'Compulsory as per Govt',
                          checkClass : '-right-5 -top-12 lg:-top-10'
                        },
                        {
                          id: "15",
                          label: "Unnamed passenger PA cover",
                          name: "unnamedpassengerPAcover",
                          checkalt:true,
                          checkText:'Suminsured Amount : 100000',
                          checkClass:'-right-4  -top-12 lg:-top-10'
                        },
                      

//                         • PA owner driver
// 		• 
// 		• 
// 		• Liability to paid driver  //notneed
// 		•  - default 1lac
// 		• Anti-theft device yes / no //not n3e  
// 		• 
// 		•
// 



                      
                      ]
                    }
                  ] :[

                    {
                      id: "1",
                      title: "Add-Ons Cover",
                      type: "checkbox",
                      buttonName: ["Reset", "Apply"],
                      checkBoxData: [
                      
                        {
                          id: "13",
                          label: "Paid Driver cover",
                          name: "paidDrivercover",
                          checkalt:true,
                          checkText:'Suminsured Amount : 200000',
                          checkClass:'-right-4  -top-12 lg:-top-10'

                        },
                        
                        {
                          id: "14",
                          label: "Owner Driver PA cover",
                          name: "ownerDriverPAcover",
                          check:true,
                          checkText:'Compulsory as per Govt',
                          checkClass : '-right-5 -top-12 lg:-top-10'
                        },
                        {
                          id: "15",
                          label: "Unnamed passenger PA cover",
                          name: "unnamedpassengerPAcover",
                          checkalt:true,
                          checkText:'Suminsured Amount : 100000',
                          checkClass:'-right-4  -top-12 lg:-top-10'
                        },
                      ]
                    }
                  ]
                
                }
                />
       </div>
        </div>
        
      </main>
      {/* Global Footer */}
<div className={`${isDetails?'hidden lg:block':''}`}>

      <Footer />
</div>
    </>
  );
};

export default BikeQuotation;






