import React, { useState ,useEffect} from 'react';
import RadioPP from '../../components/customs/RadioPP';

const CpaCheckboxModel = ({isModelOpen,cpaVal,callBackFun}) => {
  const [isOpen, setIsOpen] =isModelOpen
  const [formData, setFormData] = useState(null);
const [actionType, setActionType] = useState()
  const [cpaValues, setCpaValues] = cpaVal
  const [error, setError] = useState('');
  const [defSelVal, setDefSelVal] = useState()
  const [bikedefSelVal, setBikeDefSelVal] = useState()

  const userAction = sessionStorage.getItem('action_type')
const  actionVal = sessionStorage.getItem('cpaValues')
const  bike_actionVal = sessionStorage.getItem('bike_cpaValues')

  useEffect(()=>{
    setActionType(userAction)

    if(actionVal&&actionType=='Car') {
      setFormData(actionVal)
      
    }
    if(bike_actionVal&&actionType!=='Car') {
      setFormData(bike_actionVal)
    }
    setDefSelVal(actionVal)
    setBikeDefSelVal(bike_actionVal)
  },[userAction, actionVal, bike_actionVal, actionType])

  const toggleModal = () => {
    setIsOpen(!isOpen);
    setError('');




  // Get the current object value from sessionStorage
  const currentValue = JSON.parse(sessionStorage.getItem('input_addons_check'));
  
  // Update the specific property (e.g., key2)
  
  
  if(currentValue!==null){
  
    currentValue.ownerDriverPAcover = currentValue.lastownerDriverPAcover
  }
  
  // Set the updated object back into sessionStorage
  sessionStorage.setItem('input_addons_check', JSON.stringify(currentValue));
  
  

  };





  const handleChange = (e) => {
    const { name, id } = e.target;
    setFormData(id );
  };

  const handleSubmit = (e) => {
setCpaValues(formData)
    if (!formData) {
      setError('At least one option must be selected.');
      return;
    }

   error.length<1&& sessionStorage.setItem(actionType=='Car'?'cpaValues':'bike_cpaValues',formData)

    // Handle form submission with selected values

    // Close modal
    callBackFun(e)
    toggleModal();
  };

  const checkboxLabels = [
    { id: 'havingLicense', groupName:'cpa', label: 'Registered owner does not have a valid Driving License' },
    { id: 'havingPolicy', groupName:'cpa',  label: 'Registered owner having CPA cover with other motor policies' },
    { id: 'havingInsured',groupName:'cpa',   label: 'Registered owner having PA cover of Rs.15 lakhs and above' }
  ];

  return (
    <div>
    
      {isOpen && (
        <div className={`${isOpen?'fixed top-0 left-0 w-full h-full flex justify-center items-center bg-[#393b3c] bg-opacity-50 z-50':'hidden'} `}>
          <div className="bg-white mx-5 lg:mx-0 p-6 rounded w-2/5">
            <h2 className="text-lg font-semibold mb-4"> Reason for not opting the CPA </h2>
            <div className="">

                <div className="  mb-2 ">
                
                {checkboxLabels.map((item,key)=>(

<div className="flex items-center gap-3 mb-2  " key={key}>
<input
  type="radio"
  id={item.id}
  onChange={handleChange}
   defaultChecked={actionType=='Car'?item.id==defSelVal:item.id==bikedefSelVal}
  name={item.groupName} // Each radio button should have a unique name within its group
  className='mr-'
/>
<label htmlFor={item.id}>{item.label}</label>
</div>
                ))}

     
        
                {/* <input
                  type="radio"
                  className=" h-5 w-5 text-blue-600 text-sm md:text-base"
                  name={name}
                  id={id}
                  onChange={handleChange}
                />
                <label  htmlFor={name} className="ml-2">{label}</label> */}
              </div>

            </div>

            {error && <div className="text-[#da5e4e] mt-2">{error}</div>}
            <div className="flex items-center gap-5">

            <button onClick={handleSubmit} className="mt-4 w-full bg-hero text-white py-2 rounded-md hover:bg-blue-600 hover:drop-shadow-md  hover: drop-shadow-hero focus:outline-none">
              Submit
            </button>
            <button onClick={toggleModal} className="mt-4 w-full rounded-md  bg-[#c34920] text-white py-2 focus:outline-none">
              Close
            </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CpaCheckboxModel;
