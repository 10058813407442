import BlogImg1 from "../assets/Vector/Blogs/BlogImg1.svg";
import BlogImg2 from "../assets/Vector/Blogs/BlogImg2.svg";
import BikeImg2 from "../assets/Vector/Blogs/bikeImg2.jpg";
import BikeImg1 from "../assets/Vector/Blogs/bikeImg11.jpg";
import HealthImg1 from "../assets/Vector/Blogs/healthImg2.jpg";
import HealthImg2 from "../assets/Vector/Blogs/healthImg3.jpg";
import TermImg1 from "../assets/Vector/Blogs/termImg1.jpg";
import TermImg2 from "../assets/Vector/Blogs/termImg2.jpg";


export const ArticleBlogsData = [

  {
    id: "1",
    imgUrl: BlogImg1,
    category: "car",
   isArticle:true,
   slug:'deciding-the-right-insurance-for-your-car',
   intro:'Car insurance in India is mandatory for all...',
    date: "10-01-2023",
    title: "Deciding the right insurance for your car",
    subTitle: `<p>Car insurance in India is mandatory for all vehicles that are driven on public roads... </p>`,
    description:`	<p  >Car insurance in India is mandatory for all vehicles that are driven on public roads. There are several types of car insurance policies available in India, and the most basic one is third-party liability insurance, which is required by law. This type of insurance covers any damage or injury that you may cause to another person or their property while operating your vehicle.
    <br/>
    <br/>
In addition to third-party liability insurance, there are other types of car insurance policies available in India, such as comprehensive insurance and standalone own-damage insurance. Comprehensive insurance is a more comprehensive policy that covers not just third-party liability but also damage to your own vehicle in the event of an accident, theft, or natural disaster. Standalone own-damage insurance, on the other hand, covers only damage to your own vehicle in the event of an accident, and does not include third-party liability coverage.
    <br/>
Car insurance premiums in India are typically calculated based on a number of factors, such as the make and model of the vehicle, the age and experience of the driver, and the location where the vehicle is primarily driven. 
    <br/>
    <br/>
It's always good to go through the terms and condition and coverage of the policy before purchasing. Also you should compare and contrast the premium rates and coverage offered by different insurance providers in order to make an informed decision.
    <br/>
    <br/>
There are several add-ons or riders that you can include in your car insurance policy to enhance your coverage. Some of the most common car insurance add-ons are:
    <br/>
1.	<b>Zero Depreciation Cover : </b> This add-on offers coverage for the full value of your car's parts without any deduction for depreciation. This coverage is particularly useful for newer vehicles.
    <br/>
2.	<b>Engine Protection Cover: </b> This add-on covers the cost of repairs to your car's engine in the event of damage due to accidental oil leakage or water ingression.
    <br/>
3.	<b>NCB (No Claim Bonus) Protector:</b> This add-on allows you to retain your no claim bonus even if you make a claim during the policy period.
    <br/>
4.	<b>Return to Invoice (RTI) Cover: </b> This add-on covers the difference between the insurer's settlement and the original invoice value of the car in case of a total loss.
    <br/>
5.	<b>Roadside Assistance: </b> This add-on offers assistance in case of a breakdown or accident, such as towing services, emergency fuel delivery, and assistance with changing a flat tire.
    <br/>
6.	<b>Consumable Cover:</b> This add-on covers the cost of certain consumable items, such as oil, lubricants, and batteries that are needed in the process of repairing your car following an accident.
    <br/>
7.<b>	Key Replacement Cover: </b> This add-on covers the cost of replacing lost or stolen car keys.
    <br/>
8.	<b>Accessory Cover:</b> This add-on covers the cost of any non-standard accessories fitted to your car, such as stereo systems, alloy wheels, and seat covers.
    <br/>
These are some of the common add-ons available in India, However it's not available with every insurance company and also depends on the type of policy you are opting for. It's good to review the terms and coverage before adding any add-ons to your policy to ensure that you are getting the coverage that you need at a price that you can afford.
    <br/>
  </p>`
  },
  
  {
    id: "2",
    imgUrl: BikeImg2,
    category: "bike",
   isArticle:true,
intro:'Purchasing the right insurance for your bike...',
    link: "/bikeInsurance",
    date: "10-01-2023",
    title: "Purchasing the right insurance for your bike",
    subTitle: `	<p>Purchasing the right insurance for your bike in India can be a bit complicated as there are a few different types of policies available and each one offers different levels of coverage. Here are some tips to help you purchase the right insurance for your bike  ...</p>
  `,
  description:`		<p>Purchasing the right insurance for your bike in India can be a bit complicated as there are a few different types of policies available and each one offers different levels of coverage. Here are some tips to help you purchase the right insurance for your bike:
  <br/>
a.	<b>Understand the different types of bike insurance:</b> There are two types of bike insurance policies available in India: third-party liability insurance and comprehensive insurance. Third-party liability insurance is mandatory and covers any damage or injury you may cause to another person or their property while operating your bike. Comprehensive insurance is optional and covers not just third-party liability but also damage to your own bike in the event of an accident, theft, or natural disaster.
  <br/>
b.	<b>Assess your needs:</b> Based on your biking habits, lifestyle and budget, you can decide which type of insurance would be best for you.
  <br/>
c.	<b>Compare coverage and costs:</b> Get quotes from different insurance providers and compare the coverage and costs of each policy. Make sure to read the fine print and understand the exclusions, riders and add-ons available.
  <br/>
d.<b>	Discounts:</b> Look for discounts and offers from different insurance providers. Many insurance providers offer discounts for safety features, such as helmets and anti-theft devices, and for being a member of a biking club or organization.
  <br/>
e.	<b>Check the insurance company’s reputation: </b> You should do a background check of the company and read reviews before making a purchase.
  <br/>
f.	<b>Claims process :</b> You should also find out about the claims process and how easy or difficult it is to make a claim.
  <br/>
g.	<b>Add-on coverage: </b>Look for add-on coverage options like consumable coverage, zero-depreciation coverage, accessories coverage, roadside assistance etc. which can be useful in case of damage to the vehicle.
  <br/>
By following these tips, you can ensure that you are purchasing the right insurance for your bike and getting the best coverage at the most affordable price.
  <br/>
</p>`
  },

 
  {
    id: "3",
    imgUrl: HealthImg1,
    category: "health",
   isArticle:true,
    intro:'There are several benefits of having a health ...',
    link: "/healthInsurance",
    date: "10-01-2023",
    title: "Know the benefits of health insurance policy",
    subTitle: `	<p>There are several benefits of having a health insurance policy in India:
    Financial protection: Health insurance provides financial protection against unexpected medical expenses. It covers the cost of hospitalization, diagnostic tests, surgeries, and other medical expenses, which can be financially draining ...</p>
  `,
  description:`	<p>There are several benefits of having a health insurance policy in India:
  <br/>
a.	<b>Financial protection:</b> Health insurance provides financial protection against unexpected medical expenses. It covers the cost of hospitalization, diagnostic tests, surgeries, and other medical expenses, which can be financially draining.
  <br/>
b.<b>	Wide coverage: </b> Health insurance policies in India offer wide coverage for various medical expenses, including pre- and post-hospitalization expenses, ambulance charges, and even Ayurvedic and homeopathic treatments.
  <br/>
c.	<b>Tax benefits:</b> Health insurance policies are eligible for tax deductions under Section 80D of the Income Tax Act, 1961. This means that premiums paid towards health insurance are tax-exempt up to a certain limit.
  <br/>
d.	<b>Cashless treatment:</b> Health insurance policies offer the option of cashless treatment at network hospitals. This means that the policyholder does not have to pay any upfront costs and the insurance company will directly pay the hospital for the medical expenses.
  <br/>
e.	<b>Peace of mind:</b> Having a health insurance policy gives the policyholder peace of mind knowing that they are financially protected in case of any medical emergencies. It also allows them to focus on their recovery rather than worrying about financial stress.
  <br/>
</p>`
  },
 
];
