// api/quoteAPI.js
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { quoteActionsReliance } from "../../../../store/slices/quote-slice-reliance";
import { quoteActionsReliance } from "../../../store/slices/quote-slice-reliance";

import { carProposalAction } from "../../../store/slices/car-proposal";
import { Link, useNavigate } from "react-router-dom";

import axios from "axios";

let isValid = false;
export const RsaProposal = ( ) => {
    const metroCities = ["Kolkata", "Chennai", "Delhi", "Mumbai"];

  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    prevPolicyNo:"",
    existingTPExpDate:'',
    financiercity:'',
    chasisNo:'',
    engineNo:'',
    loan:'',
    financierAddress:''
 
 
  });
  const [proceed, setProceed] = useState(false);
  const [carRegisterNoError, setCarRegisterNoError] = useState(false);
  const [engineNoError, setEngineNoError] = useState(false);
  const [chasisNoError, setChasisNoError] = useState(false);
  const [carRegisterDateError, setCarRegisterDateError] = useState(false);
  const [prevPolicyInsurerError, setPrevPolicyInsurerError] = useState(false);
  const [prevTpInsurerError, setPrevTpInsurerError] = useState(false)
  const [prevPolicyNoError, setPrevPolicyNoError] = useState(false);
  const [tpPolicyNumberError, setTpPolicyNumberError] = useState(false);
  const [exisTPExpDateError, setExisTPExpDateError] = useState(false);
  const [carLoanError, setCarLoanError] = useState(false);
  const [load, setLoad] = useState(false);

  const [previousInsurerId, setPreviousInsurerId] = useState({});


  const [financierNameError, setFinancierNameError] = useState(false);
  const [financedValue, setFinancedValue] = useState(false)
  const [financierCityError, setFinancierCityError] = useState(false);
  const [financierAddress, setFinancierAddress] = useState(false);
  const [vehicleOwnershipError, setvehicleOwnershipError] = useState(false);
  const [jwtToken, setJwtToken] = useState(localStorage.getItem("jwt_token"));
  const [pincodeDetails, setPincodeDetails] = useState({});
  const [step1Values, setStep1Values] = useState({});
  const [carRegistrationNumber, setCarRegistrationNumber] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [rtoRegionCode, setRtoRegionCode] = useState("");
  const [manufactureYear, setManufactureYear] = useState();
  const [normalCarRegNo, setNormalCarRegNo] = useState();
  const [initialPolicyType, setInitialPolicyType] = useState({});
  const [productCodeValue, setProductCodeValue] = useState(false);
  const [step2Values, setStep2Values] = useState({});
  const [step3Values, setStep3Values] = useState({});
  const [step4Values, setStep4Values] = useState({});
  const [registeredModelValues, setRegisteredModelValues] = useState(null);
  const [modelRegionId, setModelRegionId] = useState({});
  const [popUpsFilterData, setPopUpsFilterData] = useState({});
  const [prevClaimValue, setPrevClaimValue] = useState();
  const [idvValue, setIdvValue] = useState();
  const [dateOfBirth, setDateOfBirth] = useState();
  const [isCarRegistered, setIsCarRegistered] = useState();
  const [tileType, setTileType] = useState();
  const [popData, setPopData] = useState();
  const [selectedNCB, setSelectedNCB] = useState(null);

  const [ratesValues, setRatesValues] = useState();
const [rsaKycApiData, setRsaKycApiData] = useState()
const [ownership, setOwnership] = useState()
const [reasonCpaVal, setReasonCpaVal] = useState([])
const [tppdChange, setTppdChange] = useState()
const [prevAddons, setPrevAddons] = useState()
const [carPucError, setCarPucError] = useState(false)
  const [finalAddons, setFinalAddons] = useState({});
  const [tpNewDate, setTpNewDate] = useState();
  const [finalAddonsInput, setFinalAddonsInput] = useState({});
  const [inputDiscount, setInputDiscount] = useState();
  const [manufactureMonth, setManufactureMonth] = useState();
  const [masterID, setMasterID] = useState({});
  const [inputIdvValue, setInputIdvValue] = useState()
//   const [previousInsurerId, setPreviousInsurerId] = useState({});
  const [prevTpInsurerId, setPrevTpInsurerId] = useState({})
  const [previousInsurerId2, setPreviousInsurerId2] = useState({});
  const [prevInsurerData, setPrevInsurerData] = useState([]);
  const [prevInsurerData2, setPrevInsurerData2] = useState([]);
  const [loadAgain, setLoadAgain] = useState(false);
  const [isPopWindowOpen, setIsPopWindowOpen] = useState(false);
  const [closeNav, setCloseNav] = useState(false);
  const [tpExpireDate, setTpExpireDate] = useState('');
  const [tpStartDate, setTpStartDate] = useState('')
  const [makeErrorTrue, setMakeErrorTrue] = useState(false);
const [modelName, setModelName] = useState('')
const [policyTerm, setPolicyTerm] = useState(1)
  const [godigitKycDetails, setGodigitKycDetails] = useState([]);
  const carRegistered = localStorage.getItem("isCarRegistered");
  const filterData = sessionStorage.getItem("inputs_policyType");
  const godigitKycData = localStorage.getItem("godigitKycInput");
  let registeredModelValue = localStorage.getItem("registered_model");
const rsaKycApi = localStorage.getItem('rsaKyc_apidata')
  const carOwnerDetails = localStorage.getItem("step-1_Values");
  const personalDetails = localStorage.getItem("step-2_Values");
  const addressDetails = localStorage.getItem("step-3_Values");
  const vechileDetails = localStorage.getItem("step-4_Values");
  const pincodeData = localStorage.getItem("pincode_Details");
  const insurerId = localStorage.getItem("insurance_Id");
  const insurerTpId = localStorage.getItem("insurance-Tp_Id");
  let registeredModelOwnershipValue= localStorage.getItem('registered_model_ownership')
const inputIdvVal = sessionStorage.getItem('inputs_IDV')
  const radioValue = localStorage.getItem("car_Info");
  let registeredModelClaimValue = localStorage.getItem(
    "registered_model_claim"
  );
  const cpaValues = sessionStorage.getItem('cpaValues');
  const tppdVal = sessionStorage.getItem('tppdVal')

  const carRegNO = localStorage.getItem("car_Intro");
  const modelRegion = localStorage.getItem("regionCode");
  const tileValue = localStorage.getItem("tile_Type_Detail");
  const apidata = localStorage.getItem("apiData");
  const finaladdon = sessionStorage.getItem("input_addons_check");
  const finaladdoninput = sessionStorage.getItem("check_inputs");
  const finalDiscount = sessionStorage.getItem("inputs_discount");
  const masteridvalues = localStorage.getItem("master_ID");
  const prevPolicyHolderNames = localStorage.getItem("prev_policy_names");
  const rateValue = localStorage.getItem("ratesValues");
  const prevOptedAddon = sessionStorage.getItem('prevAddons')
  const model = localStorage.getItem("car_Info_make_model");
  const currentNcbVal = sessionStorage.getItem('currentNcb')

  const [proposalApiData, setProposalApiData] = useState();
  const [isDataLoaded, seTisDataLoaded] = useState();
  /* -------------------  API ENDPOINT -------------- */
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  /* ---------------------------------- -------------- */
  const dispatch = useDispatch();



  /* --------------- Redux Values ------------------------ */
  let rsaApiData = useSelector(state=>state.quoteRsa.apiData)

  let godigitApiData = useSelector(state=>state.quoteGodigit.apiData)

  let relianceApiData = useSelector(state=>state.quoteReliance.apiData)

  /* --------------------------------------------------------- */

  // useEffect(()=>{
  //   if(Object.values(masterID).length>0){
  //     setLoad(true)
  //   }
  // },[masterID])

  useEffect(() => {
    setTileType(tileValue);
    setPopData(JSON.parse(apidata));
  }, [tileValue, apidata]);
  useEffect(() => {
    const vehicleNumberHandle = () => {
      let dob = [""];
      let spliting = step2Values.dateOfBirth.split("-");
      dob.unshift(spliting);
      setDateOfBirth(dob[0][2] + "/" + dob[0][1] + "/" + dob[0][0]);
    };
    step2Values.dateOfBirth && vehicleNumberHandle();
  }, [step2Values.dateOfBirth]);

  // useEffect(() => {
  //   setIdvValue(
  //     (tileType == "Reliance" &&
  //     Object.keys(relianceApiData).length>0  &&relianceApiData.MotorPolicy.IDV) ||
  //       (tileType == "GoDigit" &&
  //       Object.keys(godigitApiData).length>0 && godigitApiData.vehicle.vehicleIDV.idv) ||
  //       (tileType == "RSA" &&
  //       Object.keys(rsaApiData).length>0&&rsaApiData.PREMIUMDETAILS.DATA.MODEL_IDV)
  //   );
  // }, [godigitApiData, relianceApiData, rsaApiData, tileType]);

  useEffect(() => {
    setPopUpsFilterData(filterData);
    setRegisteredModelValues(JSON.parse(registeredModelValue));
    setPrevClaimValue(registeredModelClaimValue);
    carRegNO !== null
      ? setCarRegistrationNumber(JSON.parse(carRegNO).registrationNumber)
      : setCarRegistrationNumber(inputs.carRegisterNo);
    setRsaKycApiData(JSON.parse(rsaKycApi))
    setManufactureYear(JSON.parse(radioValue).manufactureYear);
setModelName(model)

    carRegNO && setNormalCarRegNo(JSON.parse(carRegNO).registrationNumber);
    setInitialPolicyType(JSON.parse(radioValue));
    setPincodeDetails(JSON.parse(pincodeData));
    setModelRegionId(JSON.parse(modelRegion));
    setStep1Values(JSON.parse(carOwnerDetails));
    setStep2Values(JSON.parse(personalDetails));
    setStep3Values(JSON.parse(addressDetails));
    setStep4Values(JSON.parse(vechileDetails));
    setFinalAddonsInput(JSON.parse(finaladdoninput));
    setPolicyTerm(finaladdoninput? JSON.parse(finaladdoninput).checkInput4:1);
    setSelectedNCB(currentNcbVal)

    setInputDiscount(finalDiscount);
    setOwnership(registeredModelOwnershipValue)
    setInputIdvValue(inputIdvVal)
    setMasterID(JSON.parse(masteridvalues));
  setReasonCpaVal(cpaValues)
  setTppdChange(tppdVal)
  setPrevAddons(prevOptedAddon)

    setPrevInsurerData2(JSON.parse(prevPolicyHolderNames));
    setGodigitKycDetails(JSON.parse(godigitKycData));
    setFinalAddons(JSON.parse(finaladdon));
    // load&&setPreviousInsurerId2(JSON.parse(insurerId))
    setRatesValues(JSON.parse(rateValue));

    setIsCarRegistered(carRegistered);
  }, [carOwnerDetails, prevOptedAddon, load, cpaValues, registeredModelOwnershipValue, rsaKycApi, rateValue, inputs, inputIdvVal, godigitKycData, prevPolicyHolderNames, finalDiscount, insurerId, masteridvalues, finaladdoninput, carRegistered, finaladdon, modelRegion, pincodeData, radioValue, carRegNO, registeredModelClaimValue, vechileDetails, filterData, registeredModelValue, addressDetails, personalDetails, tppdVal, model, currentNcbVal]);

  /* ----------------- Date Logics --------------------*/

  const userPrevPolicyExp =
    registeredModelValues && registeredModelValues.policyExp;
  const startDate = new Date(isCarRegistered =='true'? userPrevPolicyExp : Date.now());

 
  const policyStartDate = new Date(startDate.getTime() + 24 * 60 * 60 * 1000); // Add one day in milliseconds
  const policyEndDate = new Date(
    startDate.getTime() + 365 * 24 * 60 * 60 * 1000
  ); // Add one year in milliseconds
  const tppolicyEndDate = new Date(
    startDate.getTime() + 3 * 365 * 24 * 60 * 60 * 1000
  );

  const formatDate = date =>
  `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`;

// Calculate and format prevPolicyStartDate as one year earlier and one day more
const prevPolicyStartDate = formatDate(new Date(startDate.getFullYear() - 1, startDate.getMonth(), startDate.getDate() + 1));

// prevPolicyEndDate remains the same as startDate
const prevPolicyEndDate = formatDate(startDate);

var date = new Date(inputs.existingTPExpDate);
let tpDay = date.getDate();
let tpMonth = date.getMonth() + 1;
let tpYear = date.getFullYear() - 3;
if (tpDay < 10) {
  tpDay = "0" + tpDay;
}
if (tpMonth < 10) {
  tpMonth = "0" + tpMonth;
}

  /* ---------------- Date Logics End Here ---------------------  */

  /* ---------------------- To Update the Policy type value  -------------------------*/
//   const updateIdvTypeValueReliance = (value) => {
//     setIdVValue(value);
//   };

//   /* -------------------------------------------------------------- */
//   useEffect(()=>{
//     if(finalpolicytype){
//       setFinalPolicyType(finalpolicytype)
//     }else {
//       setFinalPolicyType(defaultRadioValue&&defaultRadioValue.policy)
//     }
//   },[defaultRadioValue, finalpolicytype])

  useEffect(() => {
    const onStorage = () => {
      setJwtToken(localStorage.getItem("jwt_token"));
    };

    window.addEventListener("storage", onStorage);

    return () => {
      window.removeEventListener("storage", onStorage);
    };
  }, []);

  // console.log('isVlue',isValuesRead,carInfo,modelRegionId,masterID,inputStatus)
  useEffect(() => {
    dispatch(quoteActionsReliance.setInputsRead());

    //  callbackfun()
  }, []);



  useEffect(() => {
    /* ---------------- For Getting ID's of Previous Insurer -------------- */
   
    Object.keys(inputs).includes("prevPolicyInsurer") &&
      axios
        .get(`${API_ENDPOINT}/insurance/id?name=${inputs.prevPolicyInsurer}&companyName=${tileValue}`, {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json"
          }
        })
        .then((response) => {
          localStorage.setItem(
            "insurance_Id",
            JSON.stringify(response.data[0])
          );
          setPreviousInsurerId(response.data[0]);
          setLoad(true);
        })
        .catch((err) => {
          console.log(err);
        });
   
    Object.keys(inputs).includes("prevTpInsurer") &&
      axios
        .get(`${API_ENDPOINT}/insurance/id?name=${inputs.prevTpInsurer}&companyName=${tileValue}`, {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json"
          }
        })
        .then((response) => {
          localStorage.setItem(
            "insurance-Tp_Id",
            JSON.stringify(response.data[0])
          );
          setPrevTpInsurerId(response.data[0]);
          setLoad(true);
        })
        .catch((err) => {
          console.log(err);
        });

    // https://staging-policymart.co.in/api/v1/insurance/id?name=
  }, [inputs,tileValue]);

  const updateInputValuesRsa = (valueSet) =>{
    setInputs({
        prevPolicyNo:valueSet.prevPolicyNo,
        existingTPExpDate:valueSet.existingTPExpDate,
        financiercity:valueSet.financiercity,
        chasisNo:valueSet.chasisNo,
        engineNo:valueSet.engineNo,
        loan:valueSet.loan,
        financierAddress:valueSet.financierAddress,
        previousInsurerId:valueSet.previousInsurerId
    })
  }

  const callbackfun =  ()=>{
 

    // if (
    //   Object.keys(carInfo).length > 0 &&
    //   Object.keys(modelRegionId).length > 0 &&
    //   Object.keys(masterID).length > 0&&
    //   Object.keys(finalAddons).length > 0&&
    //   selectedNCB!==null&&
    //   (finalPolicyType!==null||finalPolicyType!==undefined)
    // ) {
    //   setMakeRequestSuc(true)
    //   sessionStorage.setItem("inputSet", true);
    //   dispatch(quoteActionsReliance.setInputsRead());
    // } else {
    //   sessionStorage.setItem("inputSet", false);
    //   dispatch(quoteActionsReliance.setInputsRead());
    // }
 
    }

      /* ------------------ Manufacture Month ----------------------- */
  const manufacturemonth = new Date(initialPolicyType.registrationDate);
  let carMonth = manufacturemonth.getMonth() + 1;
  if (carMonth < 10) {
    carMonth = "0" + carMonth;
  }
  useEffect(() => {
    setVehicleNumber(
        carRegistrationNumber&&  carRegistrationNumber.replace(
        /^([a-zA-Z]{2})(\d{2})([a-zA-Z]{2})(\d+)$/,
        "$1-$2-$3-$4"
      )
    );
  }, [carRegistrationNumber]);
 
 
  const fetchProposalRsa = () => {
    dispatch(quoteActionsReliance.setQuoteLoading(true));

    axios
    .post(
      `${API_ENDPOINT}/proposal/rsa/4w?isOTPVerified=yes`,
      //
      {
        "isNewUser": "No",
        "isproductcheck": "No",
        "istranscheck": "No",
        "isFinalCalculateCall": "No",
        "isPosOpted": "",
        "posCode": "",
        "quoteId": Object.keys(rsaApiData).length>0&& rsaApiData.PREMIUMDETAILS.DATA.QUOTE_ID, // quote Response RSA Call QUoteID  
        "premium":  Object.keys(rsaApiData).length>0&& rsaApiData.PREMIUMDETAILS.DATA.GROSS_PREMIUM,
        "reqType": "JSON",
        "respType": "JSON",
        "source": "",
        "posDetails": {
            "aadhaar": "",
            "licenceExpiryDate": "",
            "mobile": "",
            "name": "",
            "pan": ""
        },
        "proposerDetails": {
            "strTitle": step1Values.salutation, // salutation 
            "strFirstName": step1Values.firstName, // first Name step1
            "strLastName":step1Values.lastName, // last Name
            "strEmail": step1Values.email, // email 
            "strMobileNo": step1Values.mobile, // mobile No
            "dateOfBirth": dateOfBirth&&dateOfBirth.split('/').reverse().join('/'), // Dob
            "occupation": "",
            "nomineeName":step2Values.nomineeName, // nominee Name 
            "nomineeAge": step2Values.age, // nominee Age
            "relationshipWithNominee": step2Values.relation, // Relation Ship with Nominee TBC
            "guardianName":step2Values.appointeeName
            ,//TBC
            "guardianAge": "22", // TBC
            "relationshipwithGuardian": "Son", // TBC 
            "contactAddress1":step3Values.address, // ADDRESS 1 
            "contactAddress2": step3Values.address2, // ADDRESS 2 
            "contactAddress3": "",  
            "contactAddress4": "",
            "contactCity":rsaKycApiData&&rsaKycApiData.city, /// city
            "contactPincode": step3Values.pincode, /// pincode
            "same_addr_reg": "Yes", 
            "addressOne": "",
            "addressTwo": "",
            "addressThree": "",
            "regCity": pincodeDetails.cityOrVillageName, // city
            "regPinCode": step3Values.pincode, // pincode
            "aadharNumber": "",
            "panNumber": rsaKycApiData&&rsaKycApiData.panNumber,  //pan card Number
            "cKycNumber": rsaKycApiData&&rsaKycApiData.ckycNo,  // ckyc NO
            "validatePan": "Yes",
      "uploadForm": "",
      "form60": "No",
      "form49a": "No",
      "fileFormat": "string",
      "fileName": "string",
            "kycDone": "true",//// kyc verified
            "eKycRefNumber": "",
            "GSTIN": "",
            "clientCode": "",
            "eiaNumber": "",
            "irName": "",
            "isLoginCheck": "",
            "isNewUser": "",
            "strPhoneNo": "",
            "strStdCode": "",
            "updatePanAaadharLater": "",
            "userName": ""
        },
        "vehicleDetails": {
            "yearOfManufacture": manufactureYear, // manufacture year
            "carRegisteredCity": modelRegionId.rsa.cityName, // city from RTO location
            "hdnRoadSideAssistanceCover": finalAddons == null || undefined
            ? false
            : finalAddons.roadSideAssistance
            ? true
            : false, // road Side assistnac
            "roadSideAssistancePlan1": finalAddons == null || undefined
            ? "No"
            : finalAddons.roadSideAssistance
            ? "Yes"
            : "No", // road side yes or no
      "roadSideAssistanceCover": finalAddons == null || undefined
      ? 'Off'
      : finalAddons.roadSideAssistance
      ? "On"
      : "Off",
      "roadSideAssistancePlan2": "no",
            "vehicleregDate": initialPolicyType&&initialPolicyType.registrationDate.split('-').reverse().join('/'), // registration Date
            "previousPolicyExpiryDate":  isCarRegistered == "true" ? userPrevPolicyExp&&userPrevPolicyExp.split("-").reverse().join("/"):'', // prev policy date
            "vehicleManufacturerName": "",
            "vehicleModelCode": masterID.rsaModelCode[0], 
            "rtoName":modelRegionId.rsa.rtoName, 
            "drivingExperience": "",
            "original_idv":  Object.keys(rsaApiData).length>0&& rsaApiData.PREMIUMDETAILS.DATA.IDV,  // idv same as quote 2nd logic
            "original_idv_2year": "0",
            "original_idv_3year": "0",
            "vehicleRegisteredInTheNameOf": "Individual",
            "companyNameForCar": "",
            // "isBiFuelKit":'No',//TBC
            //   "isBiFuelKitYes":'Inbuilt', // TBC
            "isBiFuelKit":  ['petrol+cng', 'petrol+lpg', 'cng', 'InBuilt'].indexOf(initialPolicyType.fuelType) !== -1   
            ? 'Yes' :(!["" || null || undefined].includes(finalAddons)&& finalAddons.externalBiFuelkit)
              ? 'Yes'
              :'No',
              "isBiFuelKitYes":['petrol+cng', 'petrol+lpg', 'cng', 'InBuilt'].indexOf(initialPolicyType.fuelType) !== -1 
              ? 'Inbuilt' : (!["" || null || undefined].includes(finalAddons)&& finalAddons.externalBiFuelkit)
              ? 'Add On'
              :'Inbuilt',
              "typeOfBiFuelKit":['petrol+cng', 'petrol+lpg', 'cng', 'InBuilt'].indexOf(initialPolicyType.fuelType) !== -1 
              ? 'Inbuilt' : (!["" || null || undefined].includes(finalAddons)&& finalAddons.externalBiFuelkit)
              ? 'Add On'
              :'Inbuilt',
            "addonValue": (finalAddonsInput==null||undefined)?0: finalAddonsInput.checkInput3,
            "vehicleMostlyDrivenOn": "fnejkwnfjen",
            "automobileAssociationMembership": "No",
            "isCarOwnershipChanged":tileType == "RSA"&& ownership=='Yes'?'Yes': "No",
            "isPreviousPolicyHolder": "true",
            "previuosPolicyNumber": inputs.prevPolicyNo, // prev policy number
            "previousInsurerName": inputs.prevPolicyInsurer, // policy prev insurer Name
            "previousinsurersCorrectAddress": "",
            "claimsReported": "0",
            "claimAmountReceived": "0",
            "hdnKeyReplacement": finalAddons == null || undefined
              ? false
              : finalAddons.keyLoss
              ? true
              :  false, //keyLoss True : false
              "keyreplacement": finalAddons == null || undefined
              ?  "Off"
              : finalAddons.keyLoss
              ? "On"
              :  "Off", 
              "hdnDepreciation": finalAddons == null || undefined
              ? false
              : finalAddons.zeroDepreciation
              ? prevAddons=='true'? true :false
              :  false, // zero Depricia True : false
              "depreciationWaiver": finalAddons == null || undefined
              ?  "Off"
              : finalAddons.zeroDepreciation
              ? prevAddons=='true'?"On":'Off'
              :  "Off", 
             
            "hdnWindShield": false,
            "hdnProtector": finalAddons == null || undefined
              ?  false
              : finalAddons.engineProtect
              ? true
              :  false, /// engine Protoctor true:false
              "engineprotector": finalAddons == null || undefined
              ?  "Off"
              : finalAddons.engineProtect
              ? "On"
              :  "Off", 
              "hdnNCBProtector": finalAddons == null || undefined
              ?  false
              : finalAddons.ncbProtection
              ? true
              :  false, // ncb protoctors true:false
              "ncbprotector": finalAddons == null || undefined
              ?  "Off"
              : finalAddons.ncbProtection
              ? "On"
              :  "Off",
          
            "hdnRoadTax": false,
            "hdnSpareCar": false,
            "spareCar": "off",
            "hdnInvoicePrice":  false, // returned invoice true:false
            "invoicePrice":"Off",
            "hdnLossOfBaggage": finalAddons == null || undefined
            ?  false
            : finalAddons.lossOfPersonalBelongings
            ? true
            :  false, // loss of baggage true : false
            "lossOfBaggage": finalAddons == null || undefined
            ?  "Off"
            : finalAddons.lossOfPersonalBelongings
            ? "On"
            :  "Off",
            
            "legalliabilitytopaiddriver": "No",
            "legalliabilitytoemployees": "No",
            "fibreglass": "no",
            "engineNumber": inputs.engineNo, // engine NUmb
            "chassisNumber": inputs.chasisNo, // chasis Num
            "financierName": inputs.financierName, // financier name loan yes 
            "isCarFinanced":  inputs.loan == "yes" ? 'Yes' :"No", // loan true 
            "isCarFinancedValue": "Hypothecation",
            "towingChargesCover": "No",
            "hdnTyreCover":  finalAddons == null || undefined
              ? false
              : finalAddons.tyreProtection
              ? true
              : false , // tyre protoctor true :false
              "tyreCover":  finalAddons == null || undefined
              ?  "Off"
              : finalAddons.tyreProtection
              ? "On"
              :  "Off" ,
            
            "cpaPolicyTerm": isCarRegistered == "true" ?"1":policyTerm, 
            "cpaCoverDetails": {
              "companyName": "test",
              "cpaCoverWithInternalAgent":!finalAddons.ownerDriverPAcover&& reasonCpaVal=="havingInsured"?true:false,
              "expiryDate": "",
              "noEffectiveDrivingLicense": !finalAddons.ownerDriverPAcover&&reasonCpaVal=="havingLicense"?true:false,
              "policyNumber": "",
              "standalonePAPolicy":!finalAddons.ownerDriverPAcover&& reasonCpaVal=="havingPolicy"?true:false
            },
            "cpaSumInsured": 1500000,
            "cpaCoverisRequired": finalAddons == null || undefined
            ?  "Yes"
            : finalAddons.ownerDriverPAcover
            ? "Yes"
            :  "No",//pa owner driver cover
            "ProductName": ['true',true].includes(isCarRegistered)  ?"RolloverCar":"BrandNewCar", /// prdct name
            "typeOfCover":['true',true].includes(isCarRegistered) ? (productCodeValue
              ? popUpsFilterData == "comprehensive" && "Comprehensive"
              : initialPolicyType.policy == "comprehensive" && "Comprehensive") ||
            ((productCodeValue
              ? popUpsFilterData == "thirdParty" && "LiabilityOnly"
              : initialPolicyType.policy == "thirdParty") &&
              "LiabilityOnly") ||
            (productCodeValue
              ? popUpsFilterData == "ownDamage" && "standalone"
              : initialPolicyType.policy == "ownDamage" && "standalone")
          : (productCodeValue
              ? popUpsFilterData == "bundled" && "Bundled"
              : initialPolicyType.policy == "bundled" && "Bundled") ||
            (productCodeValue
              ? popUpsFilterData == "thirdParty" && "LiabilityOnly"
              : initialPolicyType.policy == "thirdParty" && "LiabilityOnly"), // type of cover
            "accidentcoverforpaiddriver": finalAddons == ("" || null || undefined)
            ? '0'
            : finalAddons.paidDrivercover
            ? '200000'
            : '0',
            "isValidDrivingLicenseAvailable": "Yes",
            "averageMonthlyMileageRun": "",
            "claimsMadeInPreviousPolicy":prevClaimValue == "Yes" ? "Yes" : "No",  // claims yes or no
            "cover_dri_othr_car_ass": "No",
            "cover_elec_acc":   finalAddons == null || undefined
            ? "No"
            : finalAddons.electricalaccessories
            ? "Yes"
            : "No", // elect yes No
            "electricalAccessories": {
                "electronicAccessoriesDetails": [
                    {
                        "makeModel": "",
                        "nameOfElectronicAccessories": "",
                        "value":  finalAddons == null || undefined
                        ? 0
                        : finalAddonsInput && finalAddonsInput.checkInput1
                    }
                ]
            },
            "valueofelectricalaccessories":  finalAddons == null || undefined
            ? 0
            : finalAddonsInput && finalAddonsInput.checkInput1,
            "engineCapacityAmount": "",
            "fuelType": "", // fuel type
            "towingChargesCover_SI": "1000",
            "hdtDiscount": 10,
            "noClaimBonusPercent": ((registeredModelValues&&registeredModelValues.prevNCB==(null || undefined))||prevClaimValue=='Yes')?0:`${registeredModelValues&&+registeredModelValues.prevNCB}`, // ncb +1
            "cover_non_elec_acc": finalAddons == null || undefined
            ? "No"
            : finalAddons.nonElectricalaccessories
            ? "Yes"
            : "No", // non elect
            "nonElectricalAccesories": {
                "nonelectronicAccessoriesDetails": [
                    {
                        "makeModel": "",
                        "nameOfElectronicAccessories": "",
                        "value": finalAddons == null || undefined
                        ? 0
                        : finalAddonsInput && finalAddonsInput.checkInput2
                    }
                ]
            },
            "valueofnonelectricalaccessories": finalAddons == null || undefined
            ? 0
            : finalAddonsInput && finalAddonsInput.checkInput2, 
            "personalaccidentcoverforunnamedpassengers":finalAddons == ("" || null || undefined)
            ? '0'
            : finalAddons.unnamedpassengerPAcover
            ? "200000"
            : "0" , // unnamed addon true 2lak 
            "policySD": policyStartDate
            .toLocaleDateString("en-GB")
            .replace(/\//g, "-")
            .split("-")
            .reverse()
            .join("-"), // same as reliance cover from 
            "policyED":  (!isCarRegistered&&(popUpsFilterData==(null||undefined)?initialPolicyType.policy == "thirdParty":popUpsFilterData=='thirdParty' ))?tppolicyEndDate.toLocaleDateString("en-GB")
            .replace(/\//g, "-")
            .split("-")
            .reverse()
            .join("-"): policyEndDate
              .toLocaleDateString("en-GB")
              .replace(/\//g, "-")
              .split("-")
              .reverse()
              .join("-") 
            , // cover to
           
        
            "policyTerm":isCarRegistered == "true" ?"1":policyTerm ,
            "region": "",
            "spareCarLimit": "",
            "valueOfLossOfBaggage": "15000",
            "vehicleSubLine": "",
            "voluntarydeductible":  inputDiscount == ("" || null || undefined)
            ? "0"
            :(( (popUpsFilterData==undefined||null))?initialPolicyType.policy=="thirdParty":popUpsFilterData=="thirdParty")
            ? "0"
            : inputDiscount == "None"
            ? "0"
            : inputDiscount, // disco
            "modelName": modelName,
            "modified_idv_2year_value": "0",
            "modified_idv_3year_value": "0",
            "modified_idv_value": idvValue == ("" || null || undefined)
            ? "0"
            :  (( (popUpsFilterData==undefined||null))?initialPolicyType.policy=="thirdParty":popUpsFilterData=="thirdParty")
            ? "0"
            : idvValue,// idv value
            "modify_your_idv": "0", 
            "pucnumber": "",
            "pucvalidUpto": "",
            "technicalDiscount": 0,
            "validPUCAvailable":'Yes',
            "VIRNumber": "",
            "registrationNumber":  isCarRegistered == "true" ? inputs.carRegisterNo: `${modelRegionId.rsa.rto}-NEW`, //reg no 
            "registrationchargesRoadtax": "Off",
          
                
            "previousPolicyType":['true',true].includes(isCarRegistered)  ? registeredModelValues&&registeredModelValues.prevPolicyType  : (productCodeValue
              ? popUpsFilterData == "bundled" && "Bundled"
              : initialPolicyType.policy == "bundled" && "Bundled") ||
            (productCodeValue
              ? popUpsFilterData == "thirdParty" && "LiabilityOnly"
              : initialPolicyType.policy == "thirdParty" && "LiabilityOnly"),  // policy type
            "planOpted": "",
            "ncbcurrent": "0",
            "ncbprevious":  ((registeredModelValues&&registeredModelValues.prevNCB==(null || undefined))||prevClaimValue=='Yes')?0:`${registeredModelValues&&+registeredModelValues.prevNCB}`,
            "addOnsOptedInPreviousPolicy": finalAddons&& ((finalAddons.zeroDepreciation&&"DepreciationWaiver") ||
              
            (finalAddons.engineProtect&&"AggravationCover") ||
            (finalAddons.consumables&&"consumableCover")),
            "consumableCover":finalAddons&&finalAddons.consumables?"On":"Off",
            "tppdLimit":tppdChange=='true'? 6000:null,
            "campaignDiscount": "",
            "isBreakinInsurance": "No",
            "isBundleDiscountApply": "No",
            "isUsedCar": "No",
            "isVehicleInspected": "No",
            "hdnVehicleReplacementCover": finalAddons == null || undefined
            ?  false
            : finalAddons.returntoInvoice
            ? true
            :  false,
      "vehicleReplacementCover": finalAddons == null || undefined
      ?  "No"
      : finalAddons.returntoInvoice
      ? "Yes"
      :  "No" ,
      "fullInvoicePrice": "Yes",
      "fullInvoicePriceRoadtax": "No",
      "fullInvoicePriceRegCharges": "No",
      "fullInvoicePriceInsuranceCost": "No",
        },
        "authenticationDetails": {
            "apikey": "310ZQmv/bYJMYrWQ1iYa7s43084=",
            "agentId": "BA506423"
        },
        "existingTPPolicyDetails": {
            "tpInsurer": inputs.prevTpInsurer, // tp insurer
            "tpPolicyNumber":(
              popUpsFilterData == (undefined || null)
                ? initialPolicyType.policy == "ownDamage"
                : popUpsFilterData == "ownDamage"
            )
              ? step4Values.tpPolicyNumber
              : inputs.prevPolicyNo, // tp policy No
            "tpCity":pincodeDetails.cityOrVillageName,
            "tpPincode":  step3Values.pincode,
            "tpInceptionDate": `${tpDay}/${tpMonth}/${tpYear}`,
            "tpExpiryDate": Object.keys(inputs).includes("existingTPExpDate") &&
            inputs.existingTPExpDate.split('-').reverse().join('/'), // tp expiry Date
            "tpPolicyTerm": 2//TBC
        }
 
     
      },

      {
        headers: {
          authorization: jwtToken,
          "Content-Type": "application/json",
          accept: "application/json",
          "x-rsa-type": 1
        }
      }
    )
    .then((res) => {
      // setApiData(res);
      setProposalApiData(res.data.MotorPolicy);
          seTisDataLoaded(true);
          localStorage.setItem(
            "payment_Data",
            JSON.stringify(res.data.MotorPolicy)
          );
          localStorage.setItem("proposal_loaded", true);
          dispatch(carProposalAction.setApiFailed(false));
          dispatch(carProposalAction.setProposalApiData(res.data.updateVehicleResponse));

    })

    .catch((error) => {
      dispatch(carProposalAction.setApiFailed(true));
      console.log(error)});
    // setIsValidReq(false)

  };

  return { fetchProposalRsa,callbackfun ,updateInputValuesRsa};
};