import DigitLogo from "../assets/Svg/BrandPartner/BrandPolicy-digit.svg";
import SundaramLogo from "../assets/Svg/BrandPartner/BrandPolicy-sundaram.svg";
import RelianceLogo from "../assets/Svg/BrandPartner/BrandPolicy-reliance.png";

export const policyPartnerData = [
  {
    Id: "1",
    title: " 1 Year OD and 3 Year TP Cover",
    logo: DigitLogo,
    price: "₹ 250.00",
    Idv: "₹ 3,033",
    featuresContainer: [
      {
        name: "Cashless Garages",
        value: "Not Available"
      },

      {
        name: "Discounts",
        value: "NA"
      },
      {
        name: "Add Ons:",
        value: "None"
      }
    ]
  },
  {
    Id: "2",
    title: " 1 Year OD and 3 Year TP Cover",
    logo: SundaramLogo,
    Idv: "₹ 3,033",

    price: "₹ 250.00",
    featuresContainer: [
      {
        name: "Cashless Garages",
        value: "Not Available"
      },

      {
        name: "Discounts",
        value: "NA"
      },
      {
        name: "Add Ons:",
        value: "None"
      }
    ]
  },
  {
    Id: "3",
    title: " 1 Year OD and 3 Year TP Cover",
    logo: RelianceLogo,
    Idv: "₹ 3,033",

    price: "₹ 250.00",
    featuresContainer: [
      {
        name: "Cashless Garages",
        value: "Not Available"
      },

      {
        name: "Discounts",
        value: "NA"
      },
      {
        name: "Add Ons:",
        value: "None"
      }
    ]
  }
];
