import React from "react";
import SectionBackground from "../assets/Svg/BlogsBck.jpg";
const SectionBck = ({ title }) => {
  return (
    <section
      className="h-52 lg:h-72 lg:-mt-10 bg-no-repeat bg-cover"
      style={{ backgroundImage: `url(${SectionBackground})` }}
    >
      <div className="flex justify-center h-full">
        <h1 className="text-[#293651] relative z-2 font-semibold text-xl lg:text-3xl flex flex-col justify-center">
          {title}
        </h1>
      </div>
    </section>
  );
};

export default SectionBck;
