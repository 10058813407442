import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const faqData = [
  {
    id: "1",
    question: "How can I purchase an Insurance Policy?",
    answer:
      "You can use our online platform to compare and purchase car and bike insurance plans. It is a 3-step process. Provide vehicle information. Compare quotes and purchase policy by providing the required information and making payment. While we are working on issuing the term and health insurance policies online, you can contact us for any health or term insurance requirements for you and your family members.If you have any questions about the process, you can call or chat with us on WhatsApp."
  },
  {
    id: "2",
    question: ""
  }
];
const PospFAQ = () => {
  const [que1, setQue1] = useState(false);
  const [que2, setQue2] = useState(false);
  const [que3, setQue3] = useState(false);
  const [que4, setQue4] = useState(false);
  const [que5, setQue5] = useState(false);
  return (
    <div className="pb-12">
      <div className=" flex items-center justify-center text-sm font-normal lg:items-start px-4 lg:px-0">
        <div>
          {/* ////////// que-1 ////////////// */}
          <div
            className={`  lg:w-[814px] flex items-center bg-hero text-white px-4  py-2  ${
              que1 ? "" : " mb-7 lg:mb-10"
            }  rounded-[10px] text-lg font-semibold`}
            onClick={() => {
              setQue1(!que1);
              setQue2(false);
              setQue3(false);
              setQue4(false);
              setQue5(false);
            }}
          >
            <h1 className="text-sm lg:text-lg">What exactly is POSP? </h1>
            <div className="flex  flex-1" />
            {que1 ? <FaChevronUp /> : <FaChevronDown />}
          </div>
          {que1 && (
            <div className=" lg:w-[814px] mb-7 lg:mb-10 p-5 shadow-[6px_6px_10px_rgba(0,167,142,0.16)] rounded-lg">
              <p className=" lg:w-[750px]    text-[#888888] text-start ">
                PoSP stands for Point of Sales Person. A PoSP is essentially an
                insurance agent, who has the authority to sell insurance
                policies directly to consumers. This agent can sell a variety of
                insurance products, right from motor and health to business
                insurance. insurance.
              </p>
            </div>
          )}

          {/* ///////////// que-2 ///////////////// */}
          <div
            className={` lg:w-[814px] flex items-center bg-hero text-white px-4  py-2  ${
              que2 ? "" : "mb-7 lg:mb-10"
            }  rounded-[10px] text-lg font-semibold`}
            onClick={() => {
              setQue2(!que2);
              setQue1(false);
              setQue3(false);
              setQue4(false);
              setQue5(false);
            }}
          >
            <h1 className="text-sm lg:text-lg">
              What are the eligibility requirements to sell insurance on
              Policymart?
            </h1>
            <div className="flex flex-1" />
            {que2 ? <FaChevronUp /> : <FaChevronDown />}
          </div>
          {que2 && (
            <div className=" lg:w-[814px] mb-7 lg:mb-10 p-5 shadow-[6px_6px_10px_rgba(0,167,142,0.16);] rounded-lg">
              <p className=" lg:w-[750px]  text-start  text-[#888888] ">
                You need to be at least 18 years old, with a minimum
                qualification of class 10 to be eligible to sell insurance on
                Policymart as a PoS agent.
              </p>
            </div>
          )}

          {/* //////////// que-4 ////////////////// */}

          <div
            className={` lg:w-[814px] flex items-center bg-hero text-white px-4  py-2  ${
              que3 ? "" : "mb-7 lg:mb-10"
            }  rounded-[10px] text-lg font-semibold`}
            onClick={() => {
              setQue3(!que3);
              setQue1(false);
              setQue2(false);
              setQue4(false);
              setQue5(false);
            }}
          >
            <h1 className="text-sm lg:text-lg">
              What documents do I need to submit on the app?
            </h1>
            <div className="flex flex-1" />
            {que3 ? <FaChevronUp /> : <FaChevronDown />}
          </div>
          {que3 && (
            <div className=" lg:w-[814px] mb-7 lg:mb-10 p-5 shadow-[6px_6px_10px_rgba(0,167,142,0.16);] rounded-lg">
              <p className=" lg:w-[750px]  text-start   text-[#888888] ">
                To register with us as a PoSP, you need to upload the below
                documents: Photo PAN card Address proof (driving
                license/passport/light bill) 10th/12th class marksheet
              </p>
            </div>
          )}

          {/* ///////// que-5 ///////// */}

          <div
            className={` lg:w-[814px] flex items-center bg-hero text-white px-4  py-2  ${
              que4 ? "" : "mb-7 lg:mb-10"
            }  rounded-[10px] text-lg font-semibold`}
            onClick={() => {
              setQue4(!que4);
              setQue1(false);
              setQue3(false);
              setQue2(false);
              setQue5(false);
            }}
          >
            <h1 className="text-sm lg:text-lg">
              Do I have to undergo training before I can start selling policies?
            </h1>
            <div className="flex flex-1" />
            {que4 ? <FaChevronUp /> : <FaChevronDown />}
          </div>
          {que4 && (
            <div className=" lg:w-[814px] mb-7 lg:mb-10 p-5 shadow-[6px_6px_10px_rgba(0,167,142,0.16);] rounded-lg">
              <p className=" lg:w-[750px]  text-start   text-[#888888] ">
                Yes, you need to complete a basic 15-hour PoSP training as
                mandated by IRDAI. Post training, you need take the examination
                to certify as a PoSP. All the material required for the
                examination will be provided by us.
              </p>
            </div>
          )}

          {/* ////////// que-5 //////////// */}

          <div
            className={` lg:w-[814px] flex items-center bg-hero text-white px-4  py-2  ${
              que5 ? "" : "mb-7 lg:mb-10"
            }  rounded-[10px] text-lg font-semibold`}
            onClick={() => {
              setQue5(!que5);
              setQue1(false);
              setQue3(false);
              setQue4(false);
              setQue2(false);
            }}
          >
            <h1 className="text-sm lg:text-lg">
              Do I have to undergo training before selling policies?{" "}
            </h1>
            <div className="flex flex-1" />
            {que5 ? <FaChevronUp /> : <FaChevronDown />}
          </div>
          {que5 && (
            <div className=" lg:w-[814px] mb-7 lg:mb-10 p-5 shadow-[6px_6px_10px_rgba(0,167,142,0.16);] rounded-lg">
              <p className=" lg:w-[750px]  text-start   text-[#888888] ">
                Yes, our team has insurance experts from various lines of
                business, retail or commercial. We can provide assistance based
                on your requirement.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PospFAQ;
