import React from "react";
import PospEmj1 from "../assets/Vector/PospEmjs/PospEmj1.svg";
import PospEmj2 from "../assets/Vector/PospEmjs/PospEmj2.svg";
import PospEmj3 from "../assets/Vector/PospEmjs/PospEmj3.svg";
import PospEmj4 from "../assets/Vector/PospEmjs/PospEmj4.svg";
import PospEmj5 from "../assets/Vector/PospEmjs/PospEmj5.svg";
import PospEmj6 from "../assets/Vector/PospEmjs/PospEmj6.svg";

const pospData = [
  {
    id: "1",
    emoji: PospEmj1,
    title: "Paperless Policy",
    subTitle: "All our processes are online, with no paperwork involved."
  },
  {
    id: "2",
    emoji: PospEmj2,
    title: "24*7 Support",
    subTitle: "We have a 24x7 support team, just for you."
  },
  {
    id: "3",
    emoji: PospEmj3,
    title: "Paperless Policy",
    subTitle:
      "No long procedures or tiring paperwork. We issue insurance policies instantly online, without any inconvenience."
  },
  {
    id: "4",
    emoji: PospEmj4,
    title: "Training Support",
    subTitle: "Self-Learning Module for Enhanced Product Learning"
  },
  {
    id: "5",
    emoji: PospEmj5,
    title: "Timely Commission",
    subTitle:
      "All our commissions are settled quickly. Your account will be credited with your commission every 15 days."
  },
  {
    id: "6",
    emoji: PospEmj6,
    title: "Get Certified",
    subTitle: "Get Training and Certification as per IRDAI norms"
  }
];

const PospCard = () => {
  return (
    <div className="grid lg:grid-cols-3 gap-x-20   lg:justify-center lg:m-auto">
      {pospData.map((item, key) => (
        <div className="bg-white   flex flex-col justify-center items-center h-[216px] shadow-[8px_8px_20px_rgba(0,167,142,0.16)] my-4 py-4 rounded-2xl  ">
          <img src={item.emoji} alt={item.emoji} className="m-auto" />
          <h1 className="text-lg font-semibold text-center">{item.title}</h1>
          <h2 className="text-sm text-center m-auto  w-[69%]">{item.subTitle}</h2>
        </div>
      ))}
    </div>
  );
};

export default PospCard;
