// api/quoteAPI.js
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { quoteActionsReliance } from "../../../../store/slices/quote-slice-reliance";

import axios from "axios";

let isValid = false;
export const BikeReliancePolicyQuote = () => {
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [bikeRegistrationNumber, setbikeRegistrationNumber] = useState("");
  const [bikeInfo, setbikeInfo] = useState({});
  const [oldPolicyType, setOldPolicyType] = useState();
  const [inputStatus, setInputStatus] = useState(false);
  // const [closePopUp, setClosePopUp] = closeState;
  const [isCoupenSelected, setIsCoupenSelected] = useState(false);
  const [preCustomerDetails, setPreCustomerDetails] = useState()
  const [finalPolicyType, setFinalPolicyType] = useState()
  const [bikeIsRegistered, setBikeIsRegistered] = useState();
  const [policyType, setPolicyType] = useState({});

  const [inputAddon, setInputAddon] = useState({});
  const [isDisActive, setIsDisActive] = useState();
  const [finalAddonsInput, setFinalAddonsInput] = useState({});
const [idvValue, setIdVValue] = useState()
  // const [placeHolderValue, setPlaceHolderValue] = placeValue;
  // const [jwtToken, setJwtToken] = useState('')
  const [rtoRegionCode, setRtoRegionCode] = useState("");
  const [defaultRadioValue, setDefaultRadioValue] = useState();
  const [addonsData, setAddonsData] = useState({});
  const [inputDiscount, setInputDiscount] = useState();
  const [finalIDVValue, setFinalIDVValue] = useState();
  const [resetButton, setResetButton] = useState(false);
  const [noChange, setNoChange] = useState(false);
  const [selectedNCB, setSelectedNCB] = useState(0);
  const [reasonCpaVal, setReasonCpaVal] = useState();
  const [tppdChange, setTppdChange] = useState(true);
  const [prevAddons, setPrevAddons] = useState();
  const [isJWTCall, setIsJWTCall] = useState(false);
  const [quoteLoading, setquoteLoading] = useState(false);
  const [apiData, setApiData] = useState({});
  const [manufactureYear, setManufactureYear] = useState();
  const [initialPolicyType, setInitialPolicyType] = useState({});
  const [masterID, setMasterID] = useState({});
  const [rsaIdvValue, setRsaIdvValue] = useState();
  const [tokenLoaded, setTokenLoaded] = useState(false);
  const [prevClaimValue, setPrevClaimValue] = useState();
  const [registeredModelValues, setRegisteredModelValues] = useState({});
  const [popUpsFilterData, setPopUpsFilterData] = useState({});
  const [makeRequest, setMakeRequest] = useState(false);
  const [ownership, setOwnership] = useState();
  const [changeAction, setChangeAction] = useState(false);
  const [changeButtonValue, setChangeButtonValue] = useState(true);
  const [modelRegionId, setModelRegionId] = useState({});
  const [ratesValues, setRatesValues] = useState();
  const [radioValues, setRadioValues] = useState(true);
  const [buttonValue, setButtonValue] = useState();
  const [jwtToken, setJwtToken] = useState(localStorage.getItem("jwt_token"));
  const [finalAddons, setFinalAddons] = useState({});
  // For Counting Number of Checked values in Addons
  const selectAddonOption = [""];
  const trueValues = Object.values(inputAddon);
  trueValues.forEach(function (x) {
    selectAddonOption[x] = (selectAddonOption[x] || 0) + 1;
  });
  localStorage.setItem(
    "checked_addons",
    addonsData && selectAddonOption.true == undefined
      ? "0"
      : selectAddonOption.true
  );

  const [isValidReq, setIsValidReq] = useState(true);
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const dispatch = useDispatch();

  //  State from Redux
  let isValuesRead = useSelector((state) => state.quoteRsa.isInputsRead);

  //Values from LocalStorage
  // const filterData = localStorage.getItem("popupsData");
  const inputActive = sessionStorage.getItem("inputSet");
  let registeredModelValue = localStorage.getItem("bike_registered_model");
  let registeredModelClaimValue = localStorage.getItem(
    "bike_registered_model_claim"
  );
  const cpaValues = sessionStorage.getItem("cpaValues");

  const filterData = localStorage.getItem("popupsData");
  const bikeRegistered = localStorage.getItem("isBikeRegistered");
  const radioValue = localStorage.getItem("bike_Info");
  const bikeRegNO = localStorage.getItem("bike_Intro");
  const finaladdon = sessionStorage.getItem("bike_input_addons_check");
  const finalDiscount = sessionStorage.getItem("bike_inputs_discount");
  const finalidvvalue = sessionStorage.getItem("bike_inputs_IDV");
  const finaladdoninput = sessionStorage.getItem("check_inputs");
  const modelRegion = localStorage.getItem("bike_regionCode");
  const masteridvalues = localStorage.getItem("bike_master_ID");
  const rateValue = localStorage.getItem("ratesValues");
  const rsaInitIdv = localStorage.getItem("rsaIdvvalue");
  const requestSta = localStorage.getItem("apiLoaded");
  const tppdVal = sessionStorage.getItem("tppdVal");
  const prevOptedAddon = sessionStorage.getItem("prevAddons");
  const preCustDetails= localStorage.getItem('pre_BikeCustomerDetials')
  const finalpolicytype = sessionStorage.getItem('bike_inputs_policyType')

  // const policyType = sessionStorage.getItem('inputs_policyType')

  let registeredModelOwnershipValue = localStorage.getItem(
    "registered_model_ownership"
  );

  useEffect(() => {
    setInputStatus(inputActive);
    setBikeIsRegistered(JSON.parse(bikeRegistered));

    setPopUpsFilterData(JSON.parse(filterData));
    setRsaIdvValue(rsaInitIdv);
    setBikeIsRegistered(JSON.parse(bikeRegistered));
    setDefaultRadioValue(JSON.parse(radioValue));
    setRegisteredModelValues(JSON.parse(registeredModelValue));
    setPrevClaimValue(registeredModelClaimValue);
    // setPolicyType(policyType)
    setManufactureYear(JSON.parse(radioValue).manufactureYear);
    bikeRegNO &&
      setbikeRegistrationNumber(JSON.parse(bikeRegNO).registrationNumber);
    // console.log('im calling again and again',popUpsFilterData.policyData)
    setFinalAddons(JSON.parse(finaladdon));
setPreCustomerDetails(JSON.parse(preCustDetails))
setFinalPolicyType(finalpolicytype)
setOldPolicyType(JSON.parse(radioValue).policy)

    setInputDiscount(finalDiscount);
    setOwnership(registeredModelOwnershipValue);
    setNoChange(requestSta);
    setReasonCpaVal(cpaValues);
    setTppdChange(tppdVal);
    setPrevAddons(prevOptedAddon);
    setFinalIDVValue(finalidvvalue);
    setFinalAddonsInput(JSON.parse(finaladdoninput));
    setModelRegionId(JSON.parse(modelRegion));
    setInitialPolicyType(JSON.parse(radioValue));
    setMasterID(JSON.parse(masteridvalues));
    setRatesValues(JSON.parse(rateValue));
  }, [bikeRegNO, bikeRegistered, finalpolicytype, cpaValues, filterData, finalDiscount, finaladdon, finaladdoninput, finalidvvalue, inputActive, masteridvalues, modelRegion, preCustDetails, prevOptedAddon, radioValue, rateValue, registeredModelClaimValue, registeredModelOwnershipValue, registeredModelValue, requestSta, rsaInitIdv, tppdVal]);

  /* ----------------- Date Logics --------------------*/

  const userPrevPolicyExp =
    registeredModelValues && registeredModelValues.policyExp;
  const startDate = new Date(bikeIsRegistered ? userPrevPolicyExp : Date.now());
  const policyStartDate = new Date(startDate.getTime() + 24 * 60 * 60 * 1000); // Add one day in milliseconds
  const policyEndDate = new Date(
    startDate.getTime() + 365 * 24 * 60 * 60 * 1000
  ); // Add one year in milliseconds



  const formatDate = date =>
    `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`;
  
  // Calculate and format prevPolicyStartDate as one year earlier and one day more
  const prevPolicyStartDate = formatDate(new Date(startDate.getFullYear() - 1, startDate.getMonth(), startDate.getDate() + 1));
  
  // prevPolicyEndDate remains the same as startDate
  const prevPolicyEndDate = formatDate(startDate);
  /* ---------------- Date Logics End Here ---------------------  */



  useEffect(() => {
    const onStorage = () => {
      setJwtToken(localStorage.getItem("jwt_token"));
    };

    window.addEventListener("storage", onStorage);

    return () => {
      window.removeEventListener("storage", onStorage);
    };
  }, []);

  // console.log('isVlue',isValuesRead,bikeInfo,modelRegionId,masterID,inputStatus)
  useEffect(() => {
    dispatch(quoteActionsReliance.setInputsRead());

    if (
      Object.keys(bikeInfo).length > 0 &&
      Object.keys(modelRegionId).length > 0 &&
      Object.keys(masterID).length > 0
    ) {
      dispatch(quoteActionsReliance.setInputsRead());
      sessionStorage.setItem("inputSet", true);
    } else {
      dispatch(quoteActionsReliance.setInputsRead());
      sessionStorage.setItem("inputSet", false);
    }
  }, [bikeInfo, dispatch, masterID, modelRegionId]);


  useEffect(() => {
    setVehicleNumber(
      bikeRegistrationNumber.replace(
        /^([a-zA-Z]{2})(\d{2})([a-zA-Z]{2})(\d+)$/,
        "$1-$2-$3-$4"
      )
    );
  }, [bikeRegistrationNumber]);
 


  /* ---------------------- To Update the Policy type value  -------------------------*/
  const updateBikePolicyTypeValueReliance = (value) => {
    setPolicyType(value);
  };

  /* -------------------------------------------------------------- */


  useEffect(()=>{

    const availableNCBValues = [0, 20, 25, 35, 45, 50];
    const selectedValue = parseInt(registeredModelValues&&registeredModelValues.prevNCB, 10);
      if ( selectedValue< 50) {
        // Automatically select the next higher value
        setSelectedNCB(availableNCBValues[availableNCBValues.indexOf(selectedValue) + 1]);
      } else {
        // Keep the value as is if it's already the highest
        setSelectedNCB(50);
      }
  
  },[  registeredModelValues])
  const fetchBikePolicyQuoteReliance = () => {
    dispatch(quoteActionsReliance.setQuoteLoading(true));

    isValidReq&&    axios
      .post(
        `${API_ENDPOINT}/quotes/reliance`,
        {
          businessType: bikeIsRegistered ? 5 : 1,
          vehicleMakeId:
            Object.values(masterID).length < 1
              ? null
              : masterID.reliance[0].makeId,
          vehicleModelId:
            Object.values(masterID).length < 1
              ? null
              : masterID.reliance[0].modelId,
          // "vehicleVariant": "LXI CNG",
          // "cubicCapacity": 998,
          rtoLocationId: Math.floor(modelRegionId.reliance.modelRegionId),
          stateOfRegistrationId: Math.floor(modelRegionId.reliance.stateId),
          rtoRegionCode: modelRegionId.reliance.regionCode.replace("-", ""),
          zone: modelRegionId.reliance.modelZoneName,
          seatingCapacity: 2,
          licencedbikeryingCapacity: 0,
          noOfWheels: 0,
          exShowroomPrice: 0,
          idv:
          finalIDVValue == ("" || null || undefined)
            ? 0
            : policyType == "thirdParty"
            ? 0
            : finalIDVValue,
          registrationNumber: bikeIsRegistered
            ? bikeRegistrationNumber.replace(
              /^([a-zA-Z]{2})(\d{2})([a-zA-Z]{2})(\d+)$/,
              "$1-$2-$3-$4"
            ).toUpperCase()
            : `${modelRegionId.regionCode}-NEW`,
          // "registrationDate": "2021-07-01", // not req
          registrationDate: initialPolicyType.registrationDate,
          manufacturerMonth: 1,
          manufacturerYear: manufactureYear,
          dateOfPurchase: initialPolicyType.registrationDate,
          typeOfFuel:
            !["" || null || undefined].includes(finalAddons) &&
            finalAddons &&
            finalAddons.externalBiFuelkit
              ? 5
              : (initialPolicyType.fuelType == "petrol" && 1) ||
                (initialPolicyType.fuelType == "diesel" && 2) ||
                ([
                  "petrol+cng",
                  "cng",
                  "lpg",
                  "petrol+lpg",
                  "inbuilt"
                ].includes(initialPolicyType.fuelType) &&
                  5) ||
                (initialPolicyType.fuelType == "electric" && 6),
          branchCode: 9202,
          productCode: bikeIsRegistered? (policyType == "comprehensive" && 2312) ||
          (policyType == "thirdParty" && 2348) ||
          (policyType == "ownDamage" && 2308)
        : policyType == "bundled"
        ? 2375
        : 2370 ,
          // user inpuut
          coverFrom: policyStartDate
            .toLocaleDateString("en-GB")
            .replace(/\//g, "-")
            .split("-")
            .reverse()
            .join("-"), // user inpuut

          coverTo: policyEndDate
            .toLocaleDateString("en-GB")
            .replace(/\//g, "-")
            .split("-")
            .reverse()
            .join("-"),
          otherSystemName: 1,
          clientType: 0,
          agentName: "Direct",
          ncbReservingLetter: "string",
          miscTypeOfVehicleId: 0,
          userId: "100002",
          isPAToDriverCovered:
            finalAddons == ("" || null || undefined)
              ? false
              : finalAddons.paidDrivercover
              ? true
              : false,
          paToDriverCoveredNoOfItems: 1,
          paToDriverCoveredSumInsured: 200000,

          isPAToUnNamedPassenger:
            finalAddons == ("" || null || undefined)
              ? false
              : finalAddons.unnamedpassengerPAcover
              ? true
              : false,
          paToUnNamedPassengerCoveredNoOfItems: 2,
          paToUnNamedPassengerCoveredSumInsured: 100000,

          isPAToNamedPassengerCovered: false,
          paToNamedPassengerCoveredNoOfItems: 2,
          paToNamedPassengerCoveredSumInsured: 100000,

          isLiabilityToPaidDriverCovered: false,
          liabilityToPaidDriverCoveredNoOfItems: 1,

          isTPPDCover:  policyType && policyType == "ownDamage" ? false : true,
          tppdSumInsured: 6000,
          tppdIsChecked: policyType && policyType == "ownDamage" ? false : true,
          isVoluntaryDeductableOpted:
          inputDiscount == ("" || null || undefined)
          ? false
          : policyType == "thirdParty"
          ? false
          : inputDiscount == "None"
          ? false
          : inputDiscount == "None"
          ? false
          : true,  // if discount is not zero ? true : false
          voluntaryDeductibleSumInsured:
          inputDiscount == ("" || null || undefined)
          ? "0"
          : policyType == "thirdParty"
          ? "0"
          : inputDiscount == "None"
          ? "0"
          : +inputDiscount, /// discount value

          isNilDepreciation: false,
          nilDeprectiationApplicableRate: 1.3,

          isSecurePlus:false,
          securePlusApplicableRate:1.5,

          isSecurePremium:false,
          securePremiumApplicableRate: 0.95,

          isAntiTheftDeviceFitted: false,
          antiTheftIsChecked: 3,
          antiTheftNoOfItems: 0,

          isMotorQuote: false,
          isHavingValidDrivingLicense: true,
          isMotorQuoteFlow: false,
          isElectricalItemFitted:false, // once ticked electri accesor
          electricalItemsTotalSi:0, // above box value
          isNonElectricalItemFitted: false, // non electric
          nonElectricalItemsTotalSi:0, // value above

          ncbEligibilityCriteria: prevClaimValue == "Yes" ? "1" : "2",
          previousNcb: registeredModelValues&&registeredModelValues.prevNCB == (null || undefined)
          ? 0
          : registeredModelValues&&registeredModelValues.prevNCB,
          isNcbApplicable: prevClaimValue == "Yes" ? false : true, // yes ? true : false
          currentNcb:
          registeredModelValues&&registeredModelValues.prevNCB == (null || undefined)
              ? 0
              : selectedNCB,

          // isPAToOwnerDriverCovered:
          // policyType == "ownDamage" ?false:true,
          isPAToOwnerDriverCovered:  finalAddons == ("" || null || undefined)
          ? policyType == "ownDamage"
            ? false
            : true
          : policyType == "ownDamage"
          ? false
          : finalAddons.ownerDriverPAcover,
          nomineeName: "preetham",
          nomineeAddress: "rmm",
          nomineeRelationship: "cousin",
          nomineeDob: "17/09/1990",
          cpaCovertenure: 1,
          
       "name": preCustomerDetails.name,
       "phoneNo":preCustomerDetails.mobileNo,
       "email": preCustomerDetails.email,

          //   "mandatory": true, // not req
          //  "checked": true, // not requ
          //   "islpgCng": true,  // not req

          sourceSystemId: "100002",
          authToken: "Pass@123",

          basicLiability:    policyType == "ownDamage"?false:true,
         
          newVehicle: bikeIsRegistered ?false:true,
          basicODCoverage: policyType == "ownDamage"?false:true,

          prevYearPolicyEndDate: bikeIsRegistered ? prevPolicyEndDate.split("-").reverse().join("-") : "",

          prevYearPolicyNo: "8686948888564865",

          prevYearPolicyStartDate: bikeIsRegistered ?prevPolicyStartDate.split("-").reverse().join("-")  : "",
          isPreviousPolicyDetailsAvailable: "true",
          prevYearPolicyType: "1",

          // biFuelIsChecked: true,
          biFuelIsChecked: false,
          biFuelIsMandatory: false,
          // biFuelIsLpgCng:  true,
          biFuelIsLpgCng:  false,
          biFuelSumInsured:0, // from addons bifuel input
          biFuelFuelType: "LPG",

          vehicleVariant: "",
          cubicCapacity: 0,
          isNewVehicle: false,
          isBasicLiability: true,
          isBasicODCoverage: true,
          isBiFuelKit:  false,
          antiTheftIsMandatory: false,
          antiTheftPackageName: "",
          paToNamedPassengerCoveredPackageName: "",




  
 
      





          
        },

        {
          headers: {
            authorization: jwtToken,
            "Content-Type": "application/json",
            accept: "application/json",
            "x-rsa-type": 2
          }
        }
      )
      .then((res) => {
        dispatch(quoteActionsReliance.addFirstQuoteLoad(false));
        dispatch(quoteActionsReliance.setApiDataValues(res.data.relianceQuoteResponse ));
        dispatch(quoteActionsReliance.setQuoteLoading(false));

        localStorage.setItem("apiData", JSON.stringify(res));
        localStorage.setItem("apiLoaded", true);

// localStorage.setItem('ratesValues',JSON.stringify(res&&res.data.relianceQuoteResponse.MotorPolicy.lstAddonCovers.length>0?[res.data.relianceQuoteResponse.MotorPolicy.lstAddonCovers[0].rate,res.data.relianceQuoteResponse.MotorPolicy.lstAddonCovers[1].rate,res.data.relianceQuoteResponse.MotorPolicy.lstAddonCovers[2].rate]:[]))
if(res.data.relianceQuoteResponse){

  sessionStorage.setItem('relianceIdvs',JSON.stringify([res.data.relianceQuoteResponse.MotorPolicy.MinIDV,res.data.relianceQuoteResponse.MotorPolicy.MaxIDV]))
} 
      })

      .catch((error) => {
      
        console.log(error)
        if(error.response.status===500){
          dispatch(quoteActionsReliance.setQuoteLoading(false));
          dispatch(quoteActionsReliance.setApiDataValues(null));
  
        }
        
      });
    setIsValidReq(false)

  };

  return { fetchBikePolicyQuoteReliance,updateBikePolicyTypeValueReliance };
};