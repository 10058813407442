import React, { useState, useEffect } from "react";
import NavHeader from "../NavHeader";
import LandingBackground from "../../assets/Svg/LandingPageBck.svg";
import CustSvg from "../../assets/Svg/customerSvg.svg";
import Search from "../searchBox/Search";
import carModel from "../../data/CarModel";
import { Link, useNavigate } from "react-router-dom";
import InputPP from "../customs/InputPP";
import ScrollToTop from "../ScrollToTop";
import RegisteredModel from "../../UI/RegisteredModel";
import { RsaQuote1 } from "./quotation/rsaApis/RsaQuote1";

import { useDispatch } from "react-redux";
import { quoteActions } from "../../store/slices/quote-slice-rsa";
import { quoteActionsReliance } from "../../store/slices/quote-slice-reliance";
import { quoteActionsGodigit } from "../../store/slices/quote-slice-godigit";

const manufactureYear = [
  2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019,
  2020, 2021, 2022
];

const CustomerDetails = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [proceed, setProceed] = useState(false);
  const [isDetails, setIsDetails] = useState(false);
  const [closeNav, setCloseNav] = useState(false)
  const [isRegisteredModel, setIsRegisteredModel] = useState(false);
  const [isregistered, setIsRegistered] = useState("");
  const [oldPolicyType, setOldPolicyType] = useState()

  const [nameError, setNameError] = useState(false);
  const [mobileNoError, setMobileNoError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const radioValue = localStorage.getItem('car_Info')


  const {fetchQuoteRsa} = RsaQuote1()

  const dispatch = useDispatch()

  const handleChange = (e) => {
    const name = e.target.name;

    const value = e.target.value;
    setInputs((values) => ({
      ...values,
      [name]: value
    }));
  };
useEffect(()=>{
setOldPolicyType(JSON.parse(radioValue).policy)

},[radioValue])
useEffect(()=>{
dispatch(quoteActions.setQuoteLoading(true))
dispatch(quoteActions.addFirstQuoteLoad(true))
dispatch(quoteActions.setApiDataValues({}))

dispatch(quoteActionsReliance.setQuoteLoading(true))
dispatch(quoteActionsReliance.addFirstQuoteLoad(true))
dispatch(quoteActionsReliance.setApiDataValues({}))

dispatch(quoteActionsGodigit.setQuoteLoading(true))
dispatch(quoteActionsGodigit.addFirstQuoteLoad(true))
dispatch(quoteActionsGodigit.setApiDataValues({}))
sessionStorage.setItem("first_call",true)
localStorage.setItem('apiLoaded',false)
sessionStorage.setItem('inputSet',false)
},[])

 

  useEffect(()=>{
 
    // Set the updated object back into sessionStorage
 sessionStorage.setItem('input_addons_check', JSON.stringify({ownerDriverPAcover: oldPolicyType == "ownDamage" ?false:true}));
    sessionStorage.setItem('last_input_addons_check', JSON.stringify({ownerDriverPAcover: oldPolicyType == "ownDamage" ?false:true}));
    sessionStorage.setItem("first_call",false)
    sessionStorage.removeItem('inputs_discount')
    sessionStorage.removeItem('inputs_IDV')
    sessionStorage.setItem('checkboxState',JSON.stringify({"14": oldPolicyType == "ownDamage" ?false:true}))
    },[ oldPolicyType])


  const handleSubmit = (e) => {
    e.preventDefault();

   

    if (inputs.name == undefined || inputs.name == "") {
      setNameError(true);
    } 

    if (inputs.email == undefined || inputs.email == "") {
      setEmailError(true);
    } 
    if (inputs.mobileNo == undefined || inputs.mobileNo == "") {
      setMobileNoError(true);
    } 
  /// Storing Values in LocalStorage
    if (
      Object.values(inputs).length >= 3 
      &&!nameError&&!mobileNoError&&!emailError
    ) {
      // setProceed(true);
      localStorage.setItem("pre_CustomerDetials", JSON.stringify(inputs));
    }

    Object.keys(inputs).length==3  &&!nameError&&!mobileNoError&&!emailError&&navigate(
      proceed && isregistered == "true" 
        ? setIsRegisteredModel(true)
        : "/carinsurance/quote"
    );
   
  };


  // useEffect(() => {
  //   if (isRegisteredModel == false) {
  //     setIsRegistered("false");
  //   }
  // }, [isRegisteredModel]);

  const inputValues = localStorage.getItem("pre_CustomerDetials");
  const registeredVehicle = localStorage.getItem("isCarRegistered");
  var validRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const mobileNumberRegex = /^[6789]\d{9}$/;
  useEffect(() => {
    inputValues && setInputs(JSON.parse(inputValues));
    registeredVehicle && setIsRegistered(registeredVehicle);
  }, [inputValues,registeredVehicle]);
  
  // console.log('gg',inputs.mobileNo.length)
  useEffect(() => {




    if (
      Object.values(inputs).length >= 3 &&
      inputs.name.length > 0 
      &&!nameError&&!mobileNoError&&!emailError
    ) {
      setProceed(true);
    }
   /* --------------- Validating Mobile Number ------------------ */
    if (
      Object.keys(inputs).includes('name')&&
      inputs.name.length <1
    ) {
      // setProceed(false);
      setNameError(true)
      // localStorage.setItem("pre_CustomerDetials", JSON.stringify(inputs));
    }else if (Object.keys(inputs).includes('name')&&
    inputs.name.length >0){
      // setProceed(true);
      setNameError(false)
    }
    if (
      Object.keys(inputs).includes('mobileNo')&&
      !mobileNumberRegex.test(inputs.mobileNo)
    ) {
      // setProceed(false);
      setMobileNoError(true)
      // localStorage.setItem("pre_CustomerDetials", JSON.stringify(inputs));
    }else if (Object.keys(inputs).includes('mobileNo')&&
    inputs.mobileNo.length ==10){
      // setProceed(true);
      setMobileNoError(false)
    }

/* ----------------- Validating Email Id ---------------------- */

    if (
      Object.keys(inputs).includes('email')&&
      inputs.email.toLowerCase().match(validRegex)
    ) {
      // setProceed(true);
      setEmailError(false)
    }else if(Object.keys(inputs).includes('email')) {
      // setProceed(false);
      setEmailError(true)
    }

    if (
      Object.keys(inputs).includes('name')&&
      inputs.name.length<1
    ) {
      // setProceed(true);
      setNameError(true)
    }else if(Object.keys(inputs).includes('name')) {
      // setProceed(false);
      setNameError(false)
    }
    


    
  }, [emailError, inputs, mobileNoError, nameError, validRegex]);

useEffect(()=>{
  if(isRegisteredModel){

    document.body.style.overflow = "hidden";
  }  else {
    document.body.style.overflow = "visible";
  }
  if (
    Object.values(inputs).length >= 3 
    &&!nameError&&!mobileNoError&&!emailError
  ) {
    setProceed(true);
  }


},[emailError, inputs, isRegisteredModel, mobileNoError, nameError])


  return (
    <>
      <NavHeader   dropClose={ [closeNav, setCloseNav] }
 />
      {isRegisteredModel && (
        <div
          className={` absolute bg-[rgba(0,0,0,0.5)] w-full  h-full  left z-10 overflow-x-hidden`}
        >
          <div
            className="  flex flex-col lg:h-full justify-center   items-center"
            onClick={() => {
              // setclosePopUp(!closePopUp);
              
            }}
          >
            <ScrollToTop>
              {/* <QuoteDetailPop detailsPop={[isDetails, setIsDetails]} /> */}
              <RegisteredModel
                URL="/carinsurance/quote"
                detailsPop={[isRegisteredModel, setIsRegisteredModel]}
              />
            </ScrollToTop>
          </div>
        </div>
      )}
      <main
        className="w-full pb-10 lg:pt-8 bg-no-repeat bg-cover   "
        style={{ backgroundImage: `url(${LandingBackground})` }}  onClick={()=>{
          setCloseNav(false)
        }}
      >
        <h1 className="text-xl lg:text-3xl text-[#293651] font-semibold  py-7   lg:pt-4 text-center">
          Please Fill Your Details
        </h1>

        <form onChange={handleChange}>
          <div className=" justify-center bg-white mx-5 lg:m-auto shadow-[0px_0px_25px_rgba(0,0,0,0.1)] lg:drop-shadow-lg lg:w-[55%] h-[500px   px-4 py-5">
            <h1 className="mr-auto font-medium text-solid text-xl py-4 lg:pl-4">
              Your Detail
            </h1>
            <hr className="text-[#DBDBDB] pb-4" />
            <div className="lg:flex">
              <div className="space-y-5">
                <InputPP
                  className="w-full lg:w-80 px-3 py-2 my-2 rounded-md"
                  labelClassName="text-[#959595] font-medium pl-1 "
                  id="name"
                  label="Name"
                  type="text"
                  placeholder="Name"
                  value={inputs.name}
                  errorState={[nameError, setNameError]}
                />
                <InputPP
                  className="w-full lg:w-80lg:w-80   px-3 py-2 my-2 rounded-md"
                  labelClassName="text-[#959595] font-medium pl-1 "
                  id="mobileNo"
                  label="Mobile Number"
                  type="number"
                  maxlengths="10"
                  placeholder="Mobile Number"
                  value={inputs.mobileNo}
                  errorState={[mobileNoError, setMobileNoError]}
                />
                <InputPP
                  className="w-full lg:w-80  px-3 py-2 my-2 rounded-md"
                  labelClassName="text-[#959595] font-medium pl-1 "
                  id="email"
                  label="Email"
                  type="email"
                  placeholder="Email "
                  value={inputs.email}
                  errorState={[emailError, setEmailError]}
                />
              </div>

              <div className="flex flex-1"></div>
              <img src={CustSvg} alt="CustSvg" className="w-1/3 hidden lg:block" />
            </div>
          </div>
          <div className="flex justify-center space-x-5 pt-8 ">
            <Link to="/carinsurance/car-profile/prestep-2">
              <button className="bg-[#293651]  w-24 py-2 rounded-full text-white">
                Back
              </button>
            </Link>

            <input
              type="submit"
              className="bg-hero w-32   py-2 rounded-full text-white "
              onClick={handleSubmit}
              value={`${isregistered =='true'? "Next" : "View Quote"} `}
              // value="View Quote"
            />
          </div>
        </form>
      </main>
    </>
  );
};

export default CustomerDetails;
