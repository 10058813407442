import React, { useState, useEffect } from "react";
import LandingBackground from "../../../assets/Svg/LandingPageBck.svg";
import MaleSvg from '../../../assets/Vector/Health/male.svg'
import FemaleSvg from '../../../assets/Vector/Health/female.svg'
import { Link, useNavigate } from "react-router-dom";
import NavHeader from "../../NavHeader";
import FormBar from "../../FormBar";
import InputPP from "../../customs/InputPP";
import axios from "axios";


const HealthIntro5 = () => {
  const navigate = useNavigate();

  const [closeNav, setCloseNav] = useState(false)
  const [selectedGender, setSelectedGender] = useState()
  const [showError, setShowError] = useState(false)
const [jwtToken, setJwtToken] = useState(localStorage.getItem('jwt_token'))
const [preDis, setPreDis] = useState()
const [inputs, setInputs] = useState({})
const [validPincode, setValidPincode] = useState()
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT


  const pincode = localStorage.getItem('health_pincode')
  const handleChange = (e) => {
    const name = e.target.name;

    const value = e.target.value;

    setInputs((values) => ({
      ...values,
      [name]: name === "isRegisteredAddress" ? e.target.checked : value
    }));
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    if(validPincode){
        setShowError(false)
         localStorage.setItem('health_pincode',JSON.stringify(inputs))
        navigate("/healthinsurance/health-profile/prestep-6" );
    }else {
      setShowError(true)
    }


  };

  const handleAutodirect = (gender)=>{
    setSelectedGender(gender)
    localStorage.setItem('health_gender',gender)
    setTimeout(()=>{

      navigate("/healthinsurance/health-profile/prestep-2" );
    },500)

  }

useEffect(()=>{
if(validPincode){
  setShowError(false)
}
else{
  setShowError(true)
}
},[ validPincode])

useEffect(()=>{
  pincode&& setInputs(JSON.parse(pincode))
},[pincode])

useEffect(() => {
  Object.values(inputs).length===1&& axios.get(`${API_ENDPOINT}/reliance/pincode/master?pincode=${inputs.pincode}`, {
     headers: {
       Authorization: `${jwtToken}`,
       'Content-Type': 'application/json',
     },
 },).then((data)=>{
     setValidPincode(true)
    //  setPincodeDetails(data)
// setLoad(true)
   //  (data.data.message).includes('please enter valid pincode')?setPincodeLoaded(true):setPincodeLoaded(false)
      })
   .catch((err)=>{
     console.log(err)
     setValidPincode(false)
   })
  
 

 }, [API_ENDPOINT, inputs, jwtToken]);


  return (
    <>
      <NavHeader dropClose={ [closeNav, setCloseNav] } />
      <main
        className="w-full pb-10 lg:pt-8 bg-no-repeat bg-cover   "
        style={{ backgroundImage: `url(${LandingBackground})` }}  onClick={()=>{
          setCloseNav(false)
        }}
      >
        
        <form onChange={handleChange} >
          <h1 className="text-xl lg:text-3xl text-[#293651] font-semibold  py-10   lg:pt-4 text-center">
            Health Insurance Quote
          </h1>
          {/* Steps Bar */}
          <div className="lg:w-[55%] rounded-t-md bg-[#aab8aa27] m-auto ">

<div className={`lg:w-[70.1%] rounded-t-md  bg-hero py-[3px]`}></div>
</div>

{/* ------------------------------------------ */}

          <div className=" justify-center bg-white mx-5 lg:m-auto shadow-[0px_0px_25px_rgba(0,0,0,0.1)] lg:drop-shadow-lg lg:w-[55%] h-[500px   px-4 py-5">
          
            <h1 className="mr-auto font-medium text-solid lg:text-xl py-4 pl-4">
            What is your pincode?            </h1>
            <hr className="text-[#DBDBDB] pb-4" />
            <p className="px-5 text-[#7e8488] text-[15px]">
            Your health insurance premium could be influenced by the location where you reside.
              </p>
          <div className="py-4 px-5">
          <InputPP
                    className="w-full uppercase lg:w-72   px-3 py-2 my-2 rounded-md"
                    labelClassName="hidden "
                    id="pincode"
                    label="Registration Number (Format EX: MH01AF0000 )"
                    type="text"
                    brClass="hidden"
                    placeholder="Enter Your Pincode"
                    value={inputs.pincode}
                    // value={proceed ? inputs.registrationNumber : ""}
                    // changeFunction={handleChange}
                    // errorState={[registrationError, setRegistrationError]}
                  />
          </div>
          <h1 className={`${showError?'':'hidden'} text-center pt-2 text-sm text-[#eb5648] `}>Please Enter Valid Pincode</h1>

          </div>
          <div className="flex justify-center space-x-5 pt-8 ">
            <Link to={preDis&&preDis.isPreDis=='yes'? "/healthinsurance/health-profile/prestep-4":"/healthinsurance/health-profile/prestep-3" }
>
            <button className="bg-[#293651]  w-24 py-2 rounded-full text-white">
              Back
            </button>
            </Link>
            <input
              type="submit"
              className="bg-hero w-24 py-2 rounded-full text-white cursor-pointer "
              onClick={handleSubmit}
              value="Next"
            />
          </div>
        </form>
      </main>
    </>
  );
};

export default HealthIntro5;
