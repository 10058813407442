import React, { useState, useEffect } from "react";
import NavHeader from "../NavHeader";
import LandingBackground from "../../assets/Svg/LandingPageBck.svg";
import BikeInsuSvg from "../../assets/Svg/BikeInsuSvg.svg";

import Search from "../searchBox/Search";
import { Link, useNavigate } from "react-router-dom";
import DropDownPP from "../customs/DropDownPP";
import RadioPP from "../customs/RadioPP";
import axios from "axios";
import InputPP from "../customs/InputPP";




const manufactureYear = [
  2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019,
  2020, 2021, 2022
];

const rtoNumbers = ['MH01','AP01','TS01','TS19','TS20']

const BikeInfor = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({manufactureYear});
  const [proceed, setProceed] = useState(false);
const [regionData, setRegionData] = useState()
const [variantData, setVariantData] = useState([])
const [makeModelData, setMakeModelData] = useState([])
  const [isVehicleRegistered, setIsVehicleRegistered] = useState()
  const [modelValue, setModelValue] = useState("");
  const [variantValue, setVariantValue] = useState('')
  const [rtoValue, setRtoValue] = useState('')
  const [variantValueError, setVariantValueError] = useState(false)
  const [fuleValueLoaded, setFuleValueLoaded] = useState(false)
const [registrationDateError, setRegistrationDateError] = useState(false)
  const [rtoValueError, setRtoValueError] = useState(false)
  const [modelNameError, setModelNameError] = useState(false);
  const [yearError, setYearError] = useState(false);
  const [fuleValue, setFuleValue] = useState([])

  const [fuelTypeError, setFuelTypeError] = useState(false);
  const [policyError, setPolicyError] = useState(false);
  const [jwtToken, setJwtToken] = useState(localStorage.getItem('jwt_token'))
const [valueChange, setValueChange] = useState()
const [closeNav, setCloseNav] = useState(false)
const [updateTheValue, setUpdateTheValue] = useState(false)
const [showPolicyError, setShowPolicyError] = useState(false)

  // const handleChange = (e) => {
  //   const name = e.target.name;
  //   const value = e.target.value;
  //   setInputs((values) => ({ ...values, [name]: value }));
  //   if (inputs.ownerName == "") {
  //     localStorage.removeItem("ownerName");
  //   }
  // };
//api end POint

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT


useEffect(()=>{
  setInputs({...inputs,
    manufactureYear:updateTheValue?inputs.registrationDate.split('-')[0]:'',
    policy:isVehicleRegistered&&isVehicleRegistered!=='true'?((Object.keys(inputs).includes('policy')&& inputs.policy=='thirdParty')?inputs.policy:'bundled' ):inputs.policy?inputs.policy:''
  })
},[inputs.registrationDate, isVehicleRegistered, updateTheValue])

  const handleChange = (e) => {
    const name = e.target.name;

    const value = e.target.value;
    setInputs((values) => ({
      ...values,
      [name]: name === "fuelType" || name == "policy" ? e.target.id : value
    }));
    if(name== "makeAndmodel" ){
      setValueChange(true)
    }
    else if(name== "fuelType" ){
      setValueChange(true)
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setShowPolicyError(true)

    if (inputs.makeAndmodel == undefined || inputs.makeAndmodel == "") {
      setModelNameError(true);
    } else {
      setModelNameError(false);
    }
    // if (inputs.manufactureYear == undefined || inputs.manufactureYear == "") {
    //   setYearError(true);
    // } else {
    //   setYearError(false);
    // }
    if (inputs.registrationDate == undefined || inputs.registrationDate == "") {
      setRegistrationDateError(true)
    }
    if (inputs.rtoName == undefined || inputs.rtoName == "") {
      setRtoValueError(true);
    } else {
      setRtoValueError(false);
    }
    if (inputs.variantName == undefined || inputs.variantName == "") {
      setVariantValueError(true);
    } else {
      setVariantValueError(false);
    }
    if (  (Object.keys(inputs).includes('policy') && inputs.policy.length<1) ||(showPolicyError&&isVehicleRegistered=='true'&&inputs.policy=='bundled')) {
      setPolicyError(true);
    } else {
      setPolicyError(false);
    }
    if (inputs.fuelType == undefined || inputs.fuelType == "") {
      setFuelTypeError(true);
    } else {
      setFuelTypeError(false);
    }
    ///////////////// for Generation region code ////////////////////////////////////


    axios.get(`${API_ENDPOINT}/newrto/combined?rto=${rtoValue}`, {
      headers: {
        Authorization: `${jwtToken}`,
        'Content-Type': 'application/json',
      },
    }).then((response)=>{
      // regionCode.push(response.data[0].modelRegionId,response.data[0].stateId)

         localStorage.setItem('bike_regionCode',JSON.stringify(response.data)) 
        })
        .catch((err)=>{
          console.log(err)
        })



        /////////////////////// For  Master Id ////////////////

        axios.get(`${API_ENDPOINT}/master/id?makeModel=${modelValue}&fuelType=${inputs.fuelType}&variant=${variantValue}`, {
          headers: {
            Authorization: `${jwtToken}`,
            'Content-Type': 'application/json',
        'type':'2w'

          },
      },).then((response)=>{
         localStorage.setItem('bike_master_ID',JSON.stringify(response.data)) 

        })
        .catch((err)=>{
          console.log(err)
        })


        //  Storing input data in localstorage

if (
  Object.values(inputs).length >= 7 &&
  inputs.makeAndmodel.length > 0 &&
  inputs.rtoName.length > 0 &&
  inputs.variantName.length>0 &&
  inputs.registrationDate.length > 0 &&
  inputs.policy.length > 0 &&
  inputs.fuelType.length > 0
) {
  setProceed(true);
  localStorage.setItem(`bike_Info`, JSON.stringify(inputs));
  localStorage.setItem("bike_Info_make_model", modelValue);
  localStorage.setItem("bike_Info_rto_value", rtoValue);
  localStorage.setItem("bike_Info_variant_value", variantValue);

}


!modelNameError&&!rtoValueError&&!variantValueError&&!yearError&&!policyError&&!fuelTypeError&& (isVehicleRegistered=='true'?isVehicleRegistered=='true'&&inputs.policy!=='bundled':true)&&navigate(proceed ? "/bikeinsurance/details" : "");


  };

  const isNew = localStorage.getItem('isBikeRegistered')
  const token = localStorage.getItem('jwt_token')
// useEffect(()=>{
//   setJwtToken(token)
// },[token])
// console.log('tokne fron',jwtToken)
useEffect(() => {
  const onStorage = () => {
    setJwtToken(localStorage.getItem('jwt_token'));
  };

  window.addEventListener('storage', onStorage);

  return () => {
      window.removeEventListener('storage', onStorage);
  };
}, []);

  useEffect(() => {
    const inputValues = localStorage.getItem("bike_Info");
    const modelValue = localStorage.getItem("bike_Info_make_model");
    const rtovalue = localStorage.getItem("bike_Info_rto_value")
    const variantvalue = localStorage.getItem("bike_Info_variant_value")
    const isVehicleRegisteredValue = localStorage.getItem('isBikeRegistered')
    setIsVehicleRegistered(isVehicleRegisteredValue)
    inputValues && setInputs(JSON.parse(inputValues));
    modelValue && setModelValue(modelValue);
    rtovalue&&setRtoValue(rtovalue)
    variantvalue && setVariantValue(variantvalue)
  }, [isVehicleRegistered,isNew]);


  useEffect(()=>{
    setFuleValueLoaded(false)

      /* ----------------------------- For Generating Fuel type ---------------------------- */

      modelValue.length>0&&axios
      .post(
        `${API_ENDPOINT}/fuel`,
        {
            "makeModel":modelValue
        },
  
        {
          headers: {
            authorization: jwtToken,
            "Content-Type": "application/json",
            accept: "application/json",
        'type':'2w'

          }
        }
      )
      .then((res) => {
       setFuleValue(res.data)
   setFuleValueLoaded(true)

  
        // console.log(res.data.length > 0);
      })
  
      .catch((error) => console.log(error));
  },[modelValue])
  

  useEffect(()=>{


  ///////////////////////////// for generating RTO codes ////////////////////////////////////
 axios.get(`${API_ENDPOINT}/newrto/list`, {
      headers: {
        Authorization: `${jwtToken}`,
        'Content-Type': 'application/json',
      },
  },).then((response)=>{
      setRegionData(response.data)
      
    })
    .catch((err)=>{
      console.log(err)
    })

      


        

  ///////////////////////////// for generating Make Model ////////////////////////////////////
    axios.get(`${API_ENDPOINT}/unique/makemodel`, {
      headers: {
        Authorization: `${jwtToken}`,
        'Content-Type': 'application/json',
        'type':'2w'
      },
  },).then((response)=>{
       const values = []
       response.data.map((item)=>(
         values.push(item.makeModel)
       ))
      setMakeModelData(values.sort())
    })
    .catch((err)=>{
      console.log(err)
    })





    
    
    
  },[jwtToken,modelValue])

  useEffect(()=>{

    if (  Object.keys(inputs).includes('makeAndmodel') && inputs.makeAndmodel.length<1) {
      setModelNameError(true);
    } else if(Object.keys(inputs).includes('makeAndmodel') && inputs.makeAndmodel.length>0) {
      setModelNameError(false);
    }
    
    if (  Object.keys(inputs).includes('registrationDate') && inputs.registrationDate.length<1) {
      setRegistrationDateError(true);
    } else if(Object.keys(inputs).includes('registrationDate') && inputs.registrationDate.length>0) {
      setRegistrationDateError(false);
    }
    if (  Object.keys(inputs).includes('rtoName') && inputs.rtoName.length<1) {
      setRtoValueError(true);
    } else if(Object.keys(inputs).includes('rtoName') && inputs.rtoName.length>0) {
      setRtoValueError(false);
    }
    if (  Object.keys(inputs).includes('variantName') && inputs.variantName.length<1) {
      setVariantValueError(true);
    } else if(Object.keys(inputs).includes('variantName') && inputs.variantName.length>0) {
      setVariantValueError(false);
    }
    if (  (Object.keys(inputs).includes('policy') && inputs.policy.length<1)||(showPolicyError&&isVehicleRegistered=='true'&&inputs.policy=='bundled')) {
      setPolicyError(true);
    } else if(Object.keys(inputs).includes('policy') && inputs.policy.length>0) {
      setPolicyError(false);
    }
    if (  Object.keys(inputs).includes('fuelType') && inputs.fuelType.length<1) {
      setFuelTypeError(true);
    } else if(Object.keys(inputs).includes('fuelType') && inputs.fuelType.length>0) {
      setFuelTypeError(false);
    }

    if(Object.keys(inputs).includes('registrationDate')){
      setUpdateTheValue(true)
    }



  },[inputs, isVehicleRegistered, showPolicyError])


  /* ----------- Generating today's Date -------------- */
  const todayDate = new Date()
  let fDay = todayDate.getDate() 
  let fMonth = todayDate.getMonth() + 1;
  let fYear = todayDate.getFullYear();
  if(fDay<10) {
    fDay= '0'+fDay
  }
  if(fMonth<10) {
    fMonth='0'+fMonth
  }

  /* ----------- For Generating Master ID ------------------------- */

  useEffect(()=>{
    
    axios.get(`${API_ENDPOINT}/unique/variant?makeModel=${modelValue}&fuelType=${inputs.fuelType}`, {
      headers: {
        Authorization: `${jwtToken}`,
        'Content-Type': 'application/json',
        'type':'2w'

      },
  },).then((response)=>{
      const values = []
      response.data.map((item)=>(
        values.push(item.variant)
      ))
      setVariantData(values)
    })
    .catch((err)=>{
      console.log(err)
    })


   
  },[jwtToken,modelValue,inputs])
  
  useEffect(() => {
    
if (
  Object.values(inputs).length >= 7 &&
  inputs.makeAndmodel.length > 0 &&
  inputs.rtoName.length > 0 &&
  inputs.variantName.length>0 &&
  inputs.registrationDate.length > 0 &&
  inputs.policy.length > 0 &&
  inputs.fuelType.length > 0
) {
  setProceed(true);}
   
  }, [inputs, rtoValue,variantValue,modelValue]);
  useEffect(()=>{
    valueChange&& setVariantValue('')
  },[inputs.fuelType,valueChange,inputs.makeAndmodel])

  return (
    <>
      <NavHeader dropClose={ [closeNav, setCloseNav] } />
      <main
        className="w-full pb-10 lg:pt-16 bg-no-repeat bg-cover   "
        style={{ backgroundImage: `url(${LandingBackground})` }}  onClick={()=>{
          setCloseNav(false)
        }}
      >
        <form onChange={handleChange}>
          <h1 className="text-xl lg:text-3xl text-[#293651] font-semibold  py-10   lg:pt-4 text-center">
            Bike Insurance Quote
          </h1>
          <div className=" justify-center bg-white mx-5 lg:m-auto shadow-[0px_0px_25px_rgba(0,0,0,0.1)] lg:drop-shadow-lg lg:w-[55%] h-[500px   px-4 py-5">
            <h1 className="mr-auto font-medium text-solid lg:text-xl py-4 pl-4">
              Your Bike Detail
            </h1>
            <hr className="text-[#DBDBDB] pb-4" />
            <div className="lg:flex">
              <div className="lg:pl-5">
                <div className="relative ">
                  <h1 className="text-[#727272] text-sm lg:text-base font-medium">
                    Make & Model
                  </h1>

                  <Search
                    placeholder="eg. Bajaj Discover "
                    data={makeModelData}
                    id='Make And Model'
                    name="makeAndmodel"
                    values={modelValue}
                    changeFunction={[modelValue, setModelValue]}
                    errorState={[modelNameError, setModelNameError]}
                  />
                  <div className={`${Object.keys(inputs).includes('makeAndmodel')?'':'hidden'} pb-5`}>
                  {fuleValueLoaded&&fuleValue.length>0?<RadioPP
                    title="Fuel Type"
                    value={inputs.fuelType}
                    isCarRadio={true}
                    errorState={[fuelTypeError, setFuelTypeError]}
                    objValues={
                      fuleValue&& fuleValue.map((item)=>(

                        
                       
                          {
                            id:item.toLowerCase(),
                            name:'fuelType',
                            value:item
                          }
                        
                      ))

                    }
                  />: <p className={``}>Loading...</p>}
                  </div>
                  <h1 className="text-[#727272] text-sm lg:text-base font-medium">
                   Variant
                  </h1>
                  <Search
                    placeholder="Select Your Variant Name"
                    data={variantData}
                    id='Variant Name'
                    name="variantName"
                    changeValue = {true}
                    values={variantValue}
                    changeFunction={[variantValue, setVariantValue]}
                    errorState={  [variantValueError, setVariantValueError] }
                  />

<div className="absolute  w-full ">

<InputPP
                    className="w-full lg:w-80 border border-[#d7d5d5] rounded-md   px-3 py-2 my-2"
                    labelClassName="text-[#959595] font-medium pl-1"
                    id="registrationDate"
                    name='registrationDate'
                    label={isVehicleRegistered!=='true'?'Date Of Purchase': `Registration Date`}
                    type="date"
                    placeholder="Registration Date"
                    maxDate={fYear + "-" + fMonth + "-" + fDay}
                    value={inputs.registrationDate}
                    errorState={[registrationDateError, setRegistrationDateError] }
                    // state={[emailId, setEmailId]}
                    />
                    </div>

                  <h1 className="text-[#727272] text-sm lg:text-base  mt-28 font-medium">
                   Select Your RTO
                  </h1>
                  <Search
                    placeholder="Select Your RTO"
                    data={regionData}
                    id='RTO Name'
                    name="rtoName"
                    values={rtoValue}
                    changeFunction={[rtoValue, setRtoValue]}
                    errorState={ [rtoValueError, setRtoValueError]}
                  />
                </div>
                <div className="">
                  {/* <h1 className="text-[#959595] font-medium">
                    Manufacturing Year
                  </h1> */}

                  {/* <DropDownPP
                    className="w-full lg:w-80 border border-[#d7d5d5] rounded-md focus:outline-none px-2 py-2 my-2"
                    labelClassName=" text-[#959595]  text-sm lg:text-base font-medium"
                    label="Manufacturing Year"
                    id="manufactureYear"
                    value={inputs.manufactureYear}
                    options={manufactureYear}
                    errorState={[yearError, setYearError]}
                  /> */}
                  {/* <select
                    type="text"
                    className="w-80 border border-[#d7d5d5] rounded-md focus:outline-none px-3 py-2 my-2"
                    placeholder="eg. i20, beat"
                  >
                    {manufactureYear.map((item) => (
                      <option value={item}>{item}</option>
                    ))}
                  </select> */}
                </div>
                <div className=" text-sm lg:text-base">
                 

                  {/* <div className="flex items-center">
                    <input
                      type="radio"
                      id="diesel"
                      name="fuel"
                      value="Diesel"
                      onChange={(e) => {
                      }}
                    />
                    <input type="radio" id="dieseel" name="fuel" value="e" /> {" "}
                    <label for="diesel">Diesel</label>
                    <label for="diesel">Diesel</label>
                  </div> */}
                  {/* <h1 className="text-[#959595] font-medium">Fuel Type</h1>
                  <div className="flex  space-x-6 py-2 ">
                     {" "}
                
                    <div className="flex items-center">
                      <input
                        type="radio"
                        id="petrol"
                        name="fuel"
                        value="Petrol"
                      />
                        <label for="petrol">Petrol</label>
                    </div>
                  </div> */}

                  <div className="  text-sm lg:text-base py-4">
                    <RadioPP
                      title="Type of policy ?"
                      errorState={[policyError, setPolicyError]}
                      value={isVehicleRegistered!=='true'?(Object.keys(inputs).includes('policy')&& inputs.policy=='thirdParty')?inputs.policy:'bundled' :inputs.policy}
                      objValues={ isVehicleRegistered!=='true' ? [ 
                      {
                        id: "bundled",
                        name: "policy",
                        value: "Bundled"
                      },
                      {
                        id: "thirdParty",
                        name: "policy",
                        value: "Third Party"
                      },
                    ]: [
                        {
                          id: "ownDamage",
                          name: "policy",
                          value: "Own Damage"
                        },
                        {
                          id: "comprehensive",
                          name: "policy",
                          value: "Comprehensive"
                        },
                        {
                          id: "thirdParty",
                          name: "policy",
                          value: "Third Party"
                        }
                      ]}
                    />

                    {/* <h1 className="text-[#959595] ">Type of policy ?</h1>
                    <div className="flex  space-x-6 py-2 ">
                       {" "}
                      <div className="flex items-center">
                        <input
                          type="radio"
                          id="ownDamage"
                          name="policy"
                          value="Own Damage"
                        />
                          <label for="ownDamage">Own Damage</label>
                      </div>
                      <div className="flex items-center">
                        <input
                          type="radio"
                          id="comopresensive"
                          name="policy"
                          value="Compresensive"
                        />
                          <label for="comopresensive">Compresensive</label>
                      </div>
                      <div className="flex items-center">
                        <input
                          type="radio"
                          id="thirdParty"
                          name="policy"
                          value="Third Party"
                        />
                          <label for="thirdParty">Third Party</label>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="lg:flex flex-1"></div>
              <img src={BikeInsuSvg} alt="BikeInsuSvg" className="lg:w-[40%]  xl:w-[45%] hidden lg:block" />
            </div>
          </div>
          <div className="flex justify-center space-x-5 pt-8 ">
            <Link to="/bikeinsurance/bike-profile/prestep-1">
              <button className="bg-[#293651]  w-24 py-2 rounded-full text-white">
                Back
              </button>
            </Link>
            {/* <Link to="/carinsurance/details"> */}
            <input
              type="submit"
              className="bg-hero w-24 py-2 rounded-full text-white cursor-pointer "
              onClick={handleSubmit}
              value="Next"
            />
            {/* </Link> */}
          </div>
        </form>
      </main>
    </>
  );
};

export default BikeInfor;