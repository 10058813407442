
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import BrandLogo from "../assets/Svg/BrandPartner/BrandPolicy-digit.svg";
import DigitLogo from "../assets/Svg/BrandPartner/BrandPolicy-digit.svg";
import SundaramLogo from "../assets/Svg/BrandPartner/BrandPolicy-sundaram.svg";
import RelianceLogo from "../assets/Svg/BrandPartner/BrandPolicy-reliance.png";
import axios from "axios";
import LoadingEffect from "./LoadingEffect";
import RSAPayment from "../components/payment/RSAPayment";
import WaitLoadPayment from "./WaitLoadPayment";

const PlanSummary = ({proposalDatas,dataLoaded}) => {
  const [tileType, setTileType] = useState('')
  const [tilePrice, setTilePrice] = useState()
  const [navigateURL, setNavigateURL] = useState()
  const [loading, setLoading] = useState(false)
  const [isTermsSelected, setIsTermsSelected] = useState(false)
  const [termsError, setTermsError] = useState(false)
const [paymentValues, setPaymentValues] = useState({})
const [proposalLoaded, setProposalLoaded] = useState(false)
const [inputs, setInputs] = useState({});
const [jwtToken, setJwtToken] = useState(localStorage.getItem('jwt_token'))
const [proposalLoaded2, setProposalLoaded2] = useState(  localStorage.getItem('proposal_loaded')  )
const [productCodeValue, setProductCodeValue] = useState(false)
const [isBikeRegistered, setIsBikeRegistered] = useState()
const [showProposalError, setShowProposalError] = useState(false)
const [messageValue, setMessageValue] = useState('')
const [payloadTrue, setPayloadTrue] = useState(false)

const [popUpsFilterData, setPopUpsFilterData] = useState({})
const [initialPolicyType, setInitialPolicyType] = useState({})
const [proposalDataApi, setProposalDataApi] = proposalDatas
const [isDataLoaded, setIsDataLoaded] = dataLoaded
const [proposalData, setProposalData] = useState({})
const [loadData, setLoadData] = useState(true)
const [idvValue, setIdvValue] = useState()
const [premiumValue, setPremiumValue] = useState()
const [subtotalValue, setSubtotalValue] = useState()
const [gstValue, setGstValue] = useState()
const [totalValue, settotalValue] = useState()
const [acitonType, setAcitonType] = useState()
const [quoteApiData, setQuoteApiData] = useState()
const [relianceKycForm, setRelianceKycForm] = useState()
const [personalDetails, setPersonalDetails] = useState()

// const proposal =   localStorage.getItem('proposal_loaded')
// useEffect(()=>{
//   setProposalLoaded2(proposal)
// },[proposal])

  const tileValue = localStorage.getItem("tile_Type_price")
  const tileprice = localStorage.getItem("tile_price")
  const paymentIdValues = localStorage.getItem("bike_payment_Data")
  const proposaldata = localStorage.getItem('bike_payment_Data')
  const bikeRegistered = localStorage.getItem("isBikeRegistered")
  const filterData = sessionStorage.getItem("bike_inputs_policyType");
  const bikeRadioValue = localStorage.getItem('bike_Info')
const actiontype = sessionStorage.getItem('action_type')
const step1Value= localStorage.getItem('bike_step-1_Values')
const apiData= localStorage.getItem("apiData")
const relianceForm = localStorage.getItem('relianceKyc')
/* -------------------  API ENDPOINT -------------- */
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
/* ---------------------------------- -------------- */
/* --------------------- To Stop Reloading Page ----------------- */
// window.onbeforeunload = function () {return false;}
// window.onload = function(){return false;}
/* --------------------------------------------------------------- */
  const navigate = useNavigate();


  useEffect(()=>{
    if(popUpsFilterData&&popUpsFilterData.length>0){

      setProductCodeValue(true)
    }
    else{
      setProductCodeValue(false)
      
    }
  },[popUpsFilterData])

  useEffect(()=>{

    if(tileType==='Reliance') {
      setMessageValue(`Error :  ${paymentValues&&paymentValues.ErrorMessages}`)
    }
   else if(tileType==='RSA') {
      setMessageValue(paymentValues&&paymentValues.updateVehicleError)
    }
    
  
  
  },[paymentValues, tileType])
  useEffect(()=>{

    if((paymentValues&&(paymentValues.updateVehicleResponse||paymentValues.gProposalResponse))==null) {
      paymentValues&& setShowProposalError(true)
    } else {
      setShowProposalError(false)
    }
  },[paymentValues])
  // useEffect(()=>{
  //   if(   paymentValues&&   Object.values(paymentValues).length===0 
  //   ){
  //     setProposalLoaded(false)
  //   }
  //   else{
  //     setProposalLoaded(true)
  //   }
  // },[paymentValues])

  useEffect(()=>{
    setTileType(tileValue)
    setTilePrice(tileprice)
    setProposalData(JSON.parse(proposaldata))
    setIsBikeRegistered(bikeRegistered)
    setPersonalDetails(JSON.parse(step1Value))

    filterData&& setPopUpsFilterData(filterData)
    setAcitonType(actiontype)
    setQuoteApiData(JSON.parse(apiData))
    setRelianceKycForm(JSON.parse(relianceForm))
    if(Object.keys(proposalDataApi).length<1&&isDataLoaded==false){

      proposalLoaded2=='true' &&  setPaymentValues(JSON.parse(paymentIdValues))
    }else {
      setPaymentValues(proposalDataApi)
    }
  },[tileValue, apiData, relianceForm, actiontype, tileprice, isDataLoaded, proposalDataApi, proposalLoaded2, bikeRegistered, bikeRadioValue, filterData, paymentIdValues, proposaldata, step1Value])
 

 useEffect(()=>{
 
if(acitonType=="Bike") {
  bikeRadioValue&&setInitialPolicyType(JSON.parse(bikeRadioValue))

}

 },[acitonType,bikeRadioValue])
  useEffect(() => {
    const onStorage = () => {
      setJwtToken(localStorage.getItem('jwt_token'));
      setProposalLoaded2(localStorage.getItem('proposal_loaded'))
    };
  
    window.addEventListener('storage', onStorage);
  
    return () => {
        window.removeEventListener('storage', onStorage);
    };
  }, []);
//// godigit idv

/* 
idv : from user input vehicle.vehicleIDV.idv

premium : grossPremium - total tax

subtotal : premium
gst : total tax
 toal : gross premium


*/
useEffect(()=>{
  if((Object.keys(paymentValues).length<1||isDataLoaded==false) && proposalLoaded2=='false' ){

    setLoadData(true)
  }else {
    setLoadData(false)
    
  }
},[paymentValues,isDataLoaded,proposalLoaded2])



useEffect(()=>{
if(tileType== "Reliance" &&Object.keys(paymentValues).length>0) {
  setIdvValue(paymentValues.IDV)
  setPremiumValue(paymentValues.NetPremium)
  setSubtotalValue(paymentValues.NetPremium)
  setGstValue((+((+paymentValues.FinalPremium) )- (+paymentValues.NetPremium)).toFixed(2))
  settotalValue((+paymentValues.FinalPremium ))
}
if(tileType == "GoDigit"&&  Object.keys(paymentValues).length>0  ) {
  setIdvValue(paymentValues.vehicle.vehicleIDV.idv)
  setPremiumValue(paymentValues.netPremium.slice(3))
  setSubtotalValue(paymentValues.netPremium.slice(3))
  setGstValue(paymentValues.serviceTax.totalTax.slice(3))
  settotalValue(paymentValues.grossPremium.slice(3))
}
if(tileType == "RSA"&&  (paymentValues&& paymentValues.gProposalResponse!==null&&paymentValues.updateVehicleResponse!==null ) &&  Object.keys(paymentValues).length>0  ) {
  setIdvValue(quoteApiData&&quoteApiData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.IDV)
  setPremiumValue(paymentValues&&Object.keys(paymentValues).length>0&&(+paymentValues.updateVehicleResponse.PREMIUMDETAILS.DATA.LIABILITY.TOTAL_LIABILITY_PREMIUM + +paymentValues.updateVehicleResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.TOTAL_OD_PREMIUM))
  setSubtotalValue(paymentValues&&Object.keys(paymentValues).length>0&&(+paymentValues.updateVehicleResponse.PREMIUMDETAILS.DATA.LIABILITY.TOTAL_LIABILITY_PREMIUM + +paymentValues.updateVehicleResponse.PREMIUMDETAILS.DATA.OD_PREMIUM.TOTAL_OD_PREMIUM))
   setGstValue(paymentValues&&Object.keys(paymentValues).length>0&&(+paymentValues.updateVehicleResponse.PREMIUMDETAILS.DATA.IGST+ +paymentValues.updateVehicleResponse.PREMIUMDETAILS.DATA.CGST + +paymentValues.updateVehicleResponse.PREMIUMDETAILS.DATA.SGST+ +paymentValues.updateVehicleResponse.PREMIUMDETAILS.DATA.UTGST))
  settotalValue(paymentValues&&Object.keys(paymentValues).length>0&&paymentValues.updateVehicleResponse.PREMIUMDETAILS.DATA.GROSS_PREMIUM)
 
}
//  else {
//   setIdvValue('0')
//   setPremiumValue('0')
//   setSubtotalValue('0')
//   setGstValue('0')
//   settotalValue('0')
//  }
},[tileType,quoteApiData,paymentValues])

  const handlePayment = ()=> {
    setPayloadTrue(true)

    isTermsSelected==false? setTermsError(true) : setTermsError(false)

    // (tileType == "Reliance" ) ||
    // (tileType == "GoDigit" ) ||
    // (tileType == "RSA" )

        /* -------------------------------- Payment Api For Reliance ------------------------------------------  */
        messageValue.length<10&& tileType == "Reliance" &&isTermsSelected &&!loadData &&
     axios(
      
      {
      
      url:`${API_ENDPOINT}/payment`,
      method:'POST',
     
      data:{
        "proposalNo":`${paymentValues.ProposalNo}` , //proposal api
        "userId": "100002",
        "amount": `${paymentValues.FinalPremium}`, // from proposal final amount
        "paymentType": "1",
        "responseUrl": window.location.hostname=='localhost'? "http://localhost:3000/payment/complete" : window.location.hostname==='www.policymart.co.in'?'www.policymart.co.in/payment/complete': "https://staging-policymart.co.in/payment/complete" ,

  
    "ckyc" : quoteApiData.data.requestId, /// Request ID FROM QUOTE
    "IsDocumentUpload" : "false",
    "panNo" :relianceKycForm.panNo, // get it from KYC FORM
    // "panNo" :'JQZPK5303L', // get it from KYC FORM
    "isForm60" : "false"
      },
         
          headers: {
            'authorization':jwtToken,
            "Content-Type": "application/json",
            // accept: "application/pdf",
            "x-insurance-provider":1 // 1 : reliance , 2: godigit , 3 : rsa
          
        }
      
       } ).then((response)=>{
      setNavigateURL(response.data)
      setLoading(true)
     window.location.href=response.data
    })
    .catch((err)=>{
      console.log(err)
    })

        /* -------------------------------- Payment Api For Reliance End Here ------------------------------------------  */

        /* -------------------------------- Payment Api For Godigit ------------------------------------------  */
       tileType == "GoDigit"&&  isTermsSelected &&!loadData&&
       axios(
        
        {
        
        url:`${API_ENDPOINT}/payment`,
        method:'POST',
       
        data:{
          
          "applicationId": proposalData.applicationId,
"successReturnUrl" : window.location.hostname=='localhost'? "http://localhost:3000/payments/sucess" : window.location.hostname==='www.policymart.co.in'?'www.policymart.co.in/payments/sucess': "https://staging-policymart.co.in/payments/sucess" ,
"cancelReturnUrl" : window.location.hostname=='localhost'? "http://localhost:3000/payments/failure" : window.location.hostname==='www.policymart.co.in'?'www.policymart.co.in/payments/failure': "https://staging-policymart.co.in/payments/failure"  ,


          // "proposalNo":`${paymentValues.ProposalNo}` , //proposal api
          // "userId": "100002",
          // "amount": `${paymentValues.FinalPremium}`, // from proposal final amount
          // "paymentType": "1",
          // "responseUrl": window.location.hostname=='localhost'? "http://localhost:3000/payment/complete" :"https://staging-policymart.co.in/payment/complete"
        },
           
            headers: {
              'authorization':jwtToken,
              "Content-Type": "application/json",
              // accept: "application/pdf",
              "x-insurance-provider":2 // 1 : reliance , 2: godigit , 3 : rsa
            
          }
        
         } ).then((response)=>{
        setNavigateURL(response.data)
        setLoading(true)
        setPayloadTrue(false)

       window.location.href=response.data
      })
      .catch((err)=>{
        console.log(err)
      })

        /* -------------------------------- Payment Api For Godigit End Here  ------------------------------------------  */

}

/* -------- Object for RSA Payment values start Here ----------------------- */

const paymentDetails =tileType == "RSA"&& [
  {
    price:  (paymentValues&& paymentValues.gProposalResponse!==null&&paymentValues.updateVehicleResponse!==null )&&paymentValues&&Object.keys(paymentValues).length>0&&paymentValues.updateVehicleResponse.PREMIUMDETAILS.DATA.GROSS_PREMIUM,
    quoteId:  (paymentValues&& paymentValues.gProposalResponse!==null&&paymentValues.updateVehicleResponse!==null )&&paymentValues&&Object.keys(paymentValues).length>0&&paymentValues.updateVehicleResponse.PREMIUMDETAILS.DATA.QUOTE_ID,
    firstName:personalDetails&&personalDetails.firstName,
    email:personalDetails&&personalDetails.email,
    mobile:personalDetails&&personalDetails.mobile,
    returnUrl : window.location.hostname=='localhost'? "https://staging-policymart.co.in/rsa/payments/complete" : window.location.hostname==='www.policymart.co.in'?'www.policymart.co.in/rsa/payments/complete': "https://staging-policymart.co.in/rsa/payments/complete",
    
  }
]
/* -------- Object for RSA Payment values End Here ----------------------- */



  return (
    <div className="lg:w-[395px] h-[450px] p-3 lg:p-6 shadow-[0px_4px_36px_rgba(0,167,142,0.1)] rounded-lg">
      <h1 className="font-bold lg:text-2xl">Plan Summary</h1>
      <div className="flex items-center gap-8 lg:gap-8 pt-8">
      <img src={(tileType == "Reliance"&&RelianceLogo) || (tileType == "GoDigit" && DigitLogo) || (tileType == "RSA"&& SundaramLogo )} alt="Tile Logo"className={`w-20  ${tileType == "RSA"?'lg:w-36':'lg:w-max '} lg:pb-4`} />
        <div className="space-y-2 w-full">
          <div className={`  ${(Object.keys(popUpsFilterData).length>0
                  ?popUpsFilterData=="thirdParty"
                  :initialPolicyType.policy=="thirdParty")?'hidden':'' }  flex `}>
            <p className="text-[#545454] text-sm">IDV</p>
            <div className="flex flex-1"></div>
            <p className="font-semibold text-sm"> {loadData? <div className="flex gap-x-2"><LoadingEffect/><LoadingEffect/><LoadingEffect/></div>  : `₹ ${idvValue}` }</p>
          </div>
          <div className="flex w-full ">
            <p className="text-[#545454] lg:w-max lg:pr-2 text-sm">Plan Type</p>
            <div className="flex flex-1"></div>
            <p className="font-semibold lg:w-max text-sm ">{isBikeRegistered=='true'  ?( (productCodeValue?popUpsFilterData =='comprehensive' &&'Comprehensive':initialPolicyType.policy=='comprehensive'&&"Comprehensive" )  ) || ((productCodeValue? popUpsFilterData =="thirdParty" &&'ThirdParty' :initialPolicyType.policy=="thirdParty")&&'Third Party') || (productCodeValue?(popUpsFilterData=="ownDamage"&&'Own Damage'):(initialPolicyType.policy=="ownDamage"&&'Own Damage'))    : (productCodeValue?(popUpsFilterData =='bundled' &&'Bundled'):(initialPolicyType.policy=='bundled'&&'Bundled') )|| (productCodeValue?popUpsFilterData =='thirdParty' &&'ThirdParty':initialPolicyType.policy=='thirdParty'&&'ThirdParty' )}</p>
          </div>
          <div className="flex ">
            <p className="text-[#545454] text-sm">Premium</p>
            <div className="flex flex-1"></div>
            <p className="font-semibold text-sm">{loadData? <div className="flex gap-x-2"><LoadingEffect/><LoadingEffect/><LoadingEffect/></div> :`₹ ${premiumValue}` }</p>
          </div>
        </div>
      </div>

      <div className="pt-12 space-y-3">
        <div className="flex itmes-center w-full">
          <h1 className="text-[#545454] text-sm">SubTotal</h1>
          <div className="flex flex-1"></div>
          <p className="text-sm">{loadData? <div className="flex gap-x-2"><LoadingEffect/><LoadingEffect/><LoadingEffect/></div> :`₹ ${subtotalValue} ` }</p>
        </div>
        <div className="flex itmes-center w-full">
          <h1 className="text-[#545454] text-sm">GST (18%)</h1>
          <div className="flex flex-1"></div>
          <p className="text-sm"> {loadData? <div className="flex gap-x-2"><LoadingEffect/><LoadingEffect/><LoadingEffect/></div> :`₹ ${gstValue } ` }</p>
        </div>
        <div className="flex itmes-center w-full">
          <h1 className=" font-semibold text-sm">Total</h1>
          <div className="flex flex-1"></div>
          <p className="text-sm font-semibold"> {loadData? <div className="flex gap-x-2"><LoadingEffect/><LoadingEffect/><LoadingEffect/></div> :`₹ ${totalValue}` }</p>
        </div>
      </div>

      <div className={`${(showProposalError&&!loadData)?'':'hidden' } ${tileType==='Godigit'?'hidden':''}  ${messageValue&&messageValue.length>9?'':'hidden'} `}>

<p className="text-[#d14a43] font-semibold text-center text-sm py-2">" {messageValue} "</p>
</div>
      <div className="flex space-x-3 py-6 ">
        <input type="checkbox" checked={isTermsSelected} onClick={(e)=>{
          setIsTermsSelected(e.target.checked)
        }} />

        <p className="text-xs">
          I agree to the{" "}
          <Link to="/terms">
            {" "}
            <span className="text-hero font-semibold">Terms & Conditions </span>
          </Link>
          and confirm that all the information provided is true to my knowledge.{" "}
        </p>
      </div>
      {tileType == "RSA" ?<RSAPayment paymentData={paymentDetails} disabled={paymentValues&&(paymentValues.updateVehicleError||paymentValues.gProposalError
)!==null} loadData={loadData}/> : <button disabled={loadData ||messageValue.length>9?true:false} className={`bg-[#FECE4C] ${messageValue.length>9?'bg-[#fecf4c62] text-[#11131266]':''}  w-full py-2 rounded-lg font-semibold`} onClick={handlePayment} >
      {loadData?'Loading...': ' Make Payment' }
      </button>}
      {payloadTrue?<WaitLoadPayment/>:<></>}
    </div>
  );
};

export default PlanSummary;



























