// api/quoteAPI.js
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { quoteActionsReliance } from "../../../../store/slices/quote-slice-reliance";

import axios from "axios";

export const RelianceAddonsQuote = () => {
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [carRegistrationNumber, setCarRegistrationNumber] = useState("");
  const [carInfo, setCarInfo] = useState({});
  const [oldPolicyType, setOldPolicyType] = useState();
  const [addonsValuetatus, setaddonsValuetatus] = useState(false);
  // const [closePopUp, setClosePopUp] = closeState;
  const [isCoupenSelected, setIsCoupenSelected] = useState(false);
  const [preCustomerDetails, setPreCustomerDetails] = useState()
  const [finalPolicyType, setFinalPolicyType] = useState()
const [deductValue, setDeductValue] = useState()
  const [inputAddon, setInputAddon] = useState({});
  const [isDisActive, setIsDisActive] = useState();
  const [addonsValueInput, setaddonsValueInput] = useState({});
const [idvValue, setIdVValue] = useState({})
const [addonsValue, setAddonsValue] = useState({})

const [checkInput2, setCheckInput2] = useState()
const [checkInput3, setCheckInput3] = useState()
const [checkInput1, setCheckInput1] = useState()
const [cpaCoverDetails, setCpaCoverDetails] = useState()
const [isPrevAddsVal, setIsPrevAddsVal] = useState(null)

  // const [placeHolderValue, setPlaceHolderValue] = placeValue;
  // const [jwtToken, setJwtToken] = useState('')
  const [rtoRegionCode, setRtoRegionCode] = useState("");
  const [defaultRadioValue, setDefaultRadioValue] = useState();
  const [addonsData, setAddonsData] = useState({});
  const [inputDiscount, setInputDiscount] = useState();
  const [finalIDVValue, setFinalIDVValue] = useState();
  const [resetButton, setResetButton] = useState(false);
  const [noChange, setNoChange] = useState(false);
  const [selectedNCB, setSelectedNCB] = useState(null);
  const [reasonCpaVal, setReasonCpaVal] = useState();
  const [tppdChange, setTppdChange] = useState(true);
  const [prevAddons, setPrevAddons] = useState();
  const [isJWTCall, setIsJWTCall] = useState(false);
  const [quoteLoading, setquoteLoading] = useState(false);
  const [apiData, setApiData] = useState({});
  const [manufactureYear, setManufactureYear] = useState();
  const [initialPolicyType, setInitialPolicyType] = useState({});
  const [masterID, setMasterID] = useState({});
  const [rsaIdvValue, setRsaIdvValue] = useState();
  const [tokenLoaded, setTokenLoaded] = useState(false);
  const [prevClaimValue, setPrevClaimValue] = useState();
  const [registeredModelValues, setRegisteredModelValues] = useState({});
  const [popUpsFilterData, setPopUpsFilterData] = useState({});
  const [makeRequest, setMakeRequest] = useState(false);
  const [ownership, setOwnership] = useState();
  const [changeAction, setChangeAction] = useState(false);
  const [CarIsRegistered, setCarIsRegistered] = useState();
  const [changeButtonValue, setChangeButtonValue] = useState(true);
  const [modelRegionId, setModelRegionId] = useState({});
  const [ratesValues, setRatesValues] = useState();
  const [radioValues, setRadioValues] = useState(true);
  const [buttonValue, setButtonValue] = useState();
  const [jwtToken, setJwtToken] = useState(localStorage.getItem("jwt_token"));
 
  // For Counting Number of Checked values in Addons
  const selectAddonOption = [""];
  const trueValues = Object.values(inputAddon);
  trueValues.forEach(function (x) {
    selectAddonOption[x] = (selectAddonOption[x] || 0) + 1;
  });
  localStorage.setItem(
    "checked_addons",
    addonsData && selectAddonOption.true == undefined
      ? "0"
      : selectAddonOption.true
  );

  const [isValidReq, setIsValidReq] = useState(true);
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const dispatch = useDispatch();

  //  State from Redux
  let isValuesRead = useSelector((state) => state.quoteRsa.isaddonsValueRead);

  //Values from LocalStorage
  // const filterData = localStorage.getItem("popupsData");
  const inputActive = sessionStorage.getItem("addonsValueet");
  let registeredModelValue = localStorage.getItem("registered_model");
  let registeredModelClaimValue = localStorage.getItem(
    "registered_model_claim"
  );
  const cpaValues = sessionStorage.getItem("cpaValues");

  const filterData = localStorage.getItem("popupsData");
  const carRegistered = localStorage.getItem("isCarRegistered");
  const radioValue = localStorage.getItem("car_Info");
  const carRegNO = localStorage.getItem("car_Intro");
  const finaladdon = sessionStorage.getItem("input_addons_check");
  const finalDiscount = sessionStorage.getItem("inputs_discount");
  const finalidvvalue = sessionStorage.getItem("inputs_IDV");
  const finaladdoninput = sessionStorage.getItem("check_addonsValue");
  const modelRegion = localStorage.getItem("regionCode");
  const masteridvalues = localStorage.getItem("master_ID");
  const rateValue = localStorage.getItem("ratesValues");
  const rsaInitIdv = localStorage.getItem("rsaIdvvalue");
  const requestSta = localStorage.getItem("apiLoaded");
  const tppdVal = sessionStorage.getItem("tppdVal");
  const prevOptedAddon = sessionStorage.getItem("prevAddons");
  const preCustDetails= localStorage.getItem('pre_CustomerDetials')
  const finalpolicytype = sessionStorage.getItem('inputs_policyType')
  const currentNcbVal = sessionStorage.getItem('currentNcb')
  const carDetails = localStorage.getItem("car_Info");
  const finaladdoninputVal = sessionStorage.getItem("check_inputs");

  // const policyType = sessionStorage.getItem('addonsValue_policyType')

  let registeredModelOwnershipValue = localStorage.getItem(
    "registered_model_ownership"
  );

  useEffect(() => {
    setaddonsValuetatus(inputActive);
    setSelectedNCB(currentNcbVal)
    setCarInfo(JSON.parse(carDetails));

    setPopUpsFilterData(JSON.parse(filterData));
    setRsaIdvValue(rsaInitIdv);
    setCarIsRegistered(JSON.parse(carRegistered));
    setDefaultRadioValue(JSON.parse(radioValue));
    setRegisteredModelValues(JSON.parse(registeredModelValue));
    setPrevClaimValue(registeredModelClaimValue);
    // setPolicyType(policyType)
    setManufactureYear(JSON.parse(radioValue).manufactureYear);
    carRegNO &&
      setCarRegistrationNumber(JSON.parse(carRegNO).registrationNumber);
    // console.log('im calling again and again',popUpsFilterData.policyData)
setPreCustomerDetails(JSON.parse(preCustDetails))
setFinalPolicyType(finalpolicytype)
setOldPolicyType(JSON.parse(radioValue).policy)

    setInputDiscount(finalDiscount);
    setOwnership(registeredModelOwnershipValue);
    setNoChange(requestSta);
    setReasonCpaVal(cpaValues);
    setTppdChange(tppdVal);
    setPrevAddons(prevOptedAddon);
    setFinalIDVValue(finalidvvalue);
    setaddonsValueInput(JSON.parse(finaladdoninput));
    setModelRegionId(JSON.parse(modelRegion));
    setInitialPolicyType(JSON.parse(radioValue));
    setMasterID(JSON.parse(masteridvalues));
    setRatesValues(JSON.parse(rateValue));
  }, [carRegNO, carRegistered, finalpolicytype, cpaValues, filterData, finalDiscount, finaladdon, finaladdoninput, finalidvvalue, inputActive, masteridvalues, modelRegion, preCustDetails, prevOptedAddon, radioValue, rateValue, registeredModelClaimValue, registeredModelOwnershipValue, registeredModelValue, requestSta, rsaInitIdv, tppdVal, currentNcbVal, carDetails]);


  useEffect(()=>{
    if(finaladdoninputVal) {
      setCheckInput1(JSON.parse(finaladdoninputVal).checkInput1)
      setCheckInput2(JSON.parse(finaladdoninputVal).checkInput2)
      setCheckInput3(JSON.parse(finaladdoninputVal).checkInput3)
    }
  },[finaladdoninputVal])

  /* ----------------- Date Logics --------------------*/

  

  const userPrevPolicyExp =
    registeredModelValues && registeredModelValues.policyExp;
  const startDate = new Date(CarIsRegistered ? userPrevPolicyExp : Date.now());
  const policyStartDate = new Date(startDate.getTime() + 24 * 60 * 60 * 1000); // Add one day in milliseconds
  const policyEndDate = new Date(
    startDate.getTime() + 365 * 24 * 60 * 60 * 1000
  ); // Add one year in milliseconds
  const tppolicyEndDate = new Date(
    startDate.getTime() + 3 * 365 * 24 * 60 * 60 * 1000
  );

  const formatDate = date =>
    `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`;
  
  // Calculate and format prevPolicyStartDate as one year earlier and one day more
  const prevPolicyStartDate = formatDate(new Date(startDate.getFullYear() - 1, startDate.getMonth(), startDate.getDate() + 1));
  
  // prevPolicyEndDate remains the same as startDate
  const prevPolicyEndDate = formatDate(startDate);
  /* ---------------- Date Logics End Here ---------------------  */
/* ---------------------- To Update the Policy type value  -------------------------*/
const updateAddonsValueReliance = (value) =>{
    setAddonsValue(value)
}

const updateCheckInputValuesReliance= (value1,value2,value3) =>{
setCheckInput1(value1);
setCheckInput2(value2);
setCheckInput3(value3)
}

const updateCpaCoverValuesReliance = (value) =>[
    setCpaCoverDetails(value)
]

const updatePrevAddValuesReliance =(value)=>{
    setIsPrevAddsVal(value)
}
/* -------------------------------------------------------------- */
useEffect(()=>{
  if(finalpolicytype){
    setFinalPolicyType(finalpolicytype)
  }else {
    setFinalPolicyType(defaultRadioValue&&defaultRadioValue.policy)
  }
},[defaultRadioValue, finalpolicytype])
  useEffect(() => {
    const onStorage = () => {
      setJwtToken(localStorage.getItem("jwt_token"));
    };

    window.addEventListener("storage", onStorage);

    return () => {
      window.removeEventListener("storage", onStorage);
    };
  }, []);

  // console.log('isVlue',isValuesRead,carInfo,modelRegionId,masterID,addonsValuetatus)
  useEffect(() => {
    dispatch(quoteActionsReliance.setInputsRead());

    if (
      Object.keys(carInfo).length > 0 &&
      Object.keys(modelRegionId).length > 0 &&
      Object.keys(masterID).length > 0
    ) {
      dispatch(quoteActionsReliance.setInputsRead());
      sessionStorage.setItem("inputSet", true);
    } else {
      dispatch(quoteActionsReliance.setInputsRead());
      sessionStorage.setItem("inputSet", false);
    }
  }, [carInfo, dispatch, masterID, modelRegionId]);

  
  useEffect(() => {
    setVehicleNumber(
      carRegistrationNumber.replace(
        /^([a-zA-Z]{2})(\d{2})([a-zA-Z]{2})(\d+)$/,
        "$1-$2-$3-$4"
      )
    );
  }, [carRegistrationNumber]);

//  
// console.log('mallika',addonsValue)
  const fetchAddonsQuoteReliance = () => {
    dispatch(quoteActionsReliance.setQuoteLoading(true));

     
      axios
        .post(
          `${API_ENDPOINT}/quotes/reliance`,
          {
            businessType: CarIsRegistered ? 5 : 1,
            vehicleMakeId:
              Object.values(masterID).length < 1
                ? null
                : masterID.reliance[0].makeId,
            vehicleModelId:
              Object.values(masterID).length < 1
                ? null
                : masterID.reliance[0].modelId,
            // "vehicleVariant": "LXI CNG",
            // "cubicCapacity": 998,
            rtoLocationId: Math.floor(modelRegionId.reliance.modelRegionId),
            stateOfRegistrationId: Math.floor(modelRegionId.reliance.stateId),
            rtoRegionCode: modelRegionId.reliance.regionCode.replace("-", ""),
            zone: modelRegionId.reliance.modelZoneName,
            seatingCapacity: 4,
            licencedCarryingCapacity: 0,
            noOfWheels: 0,
            exShowroomPrice: 0,
            idv:finalIDVValue==(''||null||undefined)?0:  (finalPolicyType==(null||undefined)?oldPolicyType == "thirdParty":finalPolicyType=='thirdParty') ?0: finalIDVValue,
            registrationNumber: CarIsRegistered
              ?  carRegistrationNumber.replace(/(\w{2})(\d{2})(\w{2})(\d+)/, '$1-$2-$3-$4')
              : `${modelRegionId.regionCode}-NEW`,
            // "registrationDate": "2021-07-01", // not req
            registrationDate: initialPolicyType.registrationDate,
            manufacturerMonth: 1,
            manufacturerYear: manufactureYear,
            dateOfPurchase: initialPolicyType.registrationDate,
                 
            "typeOfFuel":(!["" || null || undefined].includes(addonsValue)&& addonsValue.externalBiFuelkit)?5: (initialPolicyType.fuelType == 'petrol'&&1) ||(initialPolicyType.fuelType == 'diesel'&&2)||(['petrol+cng','cng','lpg','petrol+lpg','inbuilt'].includes(initialPolicyType.fuelType) &&5)||(initialPolicyType.fuelType == 'electric'&&6),
            branchCode: 9202,
            productCode:finalPolicyType==(''||null||undefined)? CarIsRegistered?  (oldPolicyType=="comprehensive" && 2311) || (oldPolicyType=="thirdParty"&&2347) || (oldPolicyType=="ownDamage"&&2309)    :oldPolicyType=='bundled'?2374:2371 : CarIsRegistered?  (finalPolicyType=="comprehensive" && 2311) || (finalPolicyType=="thirdParty"&&2347) || (finalPolicyType=="ownDamage"&&2309)    :finalPolicyType=='bundled'?2374:2371 ,
            // user inpuut
            coverFrom: policyStartDate
              .toLocaleDateString("en-GB")
              .replace(/\//g, "-")
              .split("-")
              .reverse()
              .join("-"), // user inpuut

            coverTo:  (!CarIsRegistered&&(finalPolicyType==(null||undefined)?oldPolicyType == "thirdParty":finalPolicyType=='thirdParty' ))?tppolicyEndDate.toLocaleDateString("en-GB")
            .replace(/\//g, "-")
            .split("-")
            .reverse()
            .join("-"): policyEndDate
              .toLocaleDateString("en-GB")
              .replace(/\//g, "-")
              .split("-")
              .reverse()
              .join("-"),
            otherSystemName: 1,
            clientType: 0,
            agentName: "Direct",
            ncbReservingLetter: "string",
            miscTypeOfVehicleId: 0,
            userId: "100002",
            isPAToDriverCovered:addonsValue.paidDrivercover?true:false,
            paToDriverCoveredNoOfItems: 1,
            paToDriverCoveredSumInsured: 200000,

            "isPAToUnNamedPassenger":addonsValue.unnamedpassengerPAcover?true:false,
            paToUnNamedPassengerCoveredNoOfItems: 5,
            paToUnNamedPassengerCoveredSumInsured: 200000,

            isPAToNamedPassengerCovered: false,
            paToNamedPassengerCoveredNoOfItems: 3,
            paToNamedPassengerCoveredSumInsured: 100000,

            isLiabilityToPaidDriverCovered: false,
            liabilityToPaidDriverCoveredNoOfItems: 1,
            "isTPPDCover":( (  (finalPolicyType==undefined||null))?oldPolicyType=="ownDamage":finalPolicyType=="ownDamage")?false:true,
            tppdSumInsured: 750000,
            "tppdIsChecked":( (  (finalPolicyType==undefined||null))?oldPolicyType=="ownDamage":finalPolicyType=="ownDamage")?false:true,
            "isVoluntaryDeductableOpted": inputDiscount==(''||null||undefined)?false: finalPolicyType=="thirdParty"?false: inputDiscount=='None'?false:inputDiscount=='None'?false:true, // if discount is not zero ? true : false
            "voluntaryDeductibleSumInsured": inputDiscount==(''||null||undefined)?'0': finalPolicyType=="thirdParty"?'0': inputDiscount=='None'?'0':inputDiscount, /// discount value

            "isNilDepreciation":addonsValue.zeroDepreciation&&(addonsValue.consumables||addonsValue.engineProtect||addonsValue.keyLoss)?false:addonsValue.zeroDepreciation?true:false  ,
            "nilDeprectiationApplicableRate":ratesValues&& ratesValues[0],

            "isSecurePlus": (((addonsValue.zeroDepreciation&&addonsValue.consumables&&addonsValue.engineProtectf&&addonsValue.lossOfPersonalBelongings) || (addonsValue.consumables&&addonsValue.engineProtect&&addonsValue.lossOfPersonalBelongings) ||(addonsValue.consumables||addonsValue.engineProtect||addonsValue.lossOfPersonalBelongings))) && (addonsValue.tyreProtection!==true) ? true:false ,
            securePlusApplicableRate: ratesValues && +ratesValues[1],

            "isSecurePremium":addonsValue.tyreProtection?true:false,
            "securePremiumApplicableRate":ratesValues&& ratesValues[2],

            isAntiTheftDeviceFitted: false,
            antiTheftIsChecked: 3,
            antiTheftNoOfItems: 0,

            isMotorQuote: false,
            isHavingValidDrivingLicense: true,
            isMotorQuoteFlow: false,
            "isElectricalItemFitted":addonsValue.electricalaccessories?true:false,// once ticked electri accesor
            "electricalItemsTotalSi":addonsValue.electricalaccessories?checkInput1:0, // above box value
            "isNonElectricalItemFitted":addonsValue.nonElectricalaccessories?true:false,// non electric
            "nonElectricalItemsTotalSi":addonsValue.nonElectricalaccessories?checkInput2:0,// value above

            ncbEligibilityCriteria: prevClaimValue == "Yes" ? "1" : "2",
            previousNcb:
            ((registeredModelValues &&
              registeredModelValues.prevNCB == (null || undefined)) ||
            prevClaimValue == "Yes")
              ? 0
              : registeredModelValues && registeredModelValues.prevNCB,
            isNcbApplicable:  CarIsRegistered?   prevClaimValue == "Yes" ? false : true:false, // yes ? true : false
            currentNcb:
              (registeredModelValues &&
                registeredModelValues.prevNCB == (null || undefined)) ||
              prevClaimValue == "Yes"
                ? 0
                : selectedNCB,
                // isPAToOwnerDriverCovered:true,

            // isPAToOwnerDriverCovered:
            // policyType == "ownDamage" ?false:true,
            "isPAToOwnerDriverCovered":addonsValue.ownerDriverPAcover?((finalPolicyType==undefined||null)?oldPolicyType=="ownDamage":finalPolicyType=="ownDamage")?false: addonsValue.ownerDriverPAcover:((finalPolicyType==undefined||null)?oldPolicyType=="ownDamage":finalPolicyType=="ownDamage")?false:addonsValue.ownerDriverPAcover,
            nomineeName: "preetham",
            nomineeAddress: "rmm",
            nomineeRelationship: "cousin",
            nomineeDob: "17/09/1990",
            cpaCovertenure: 1,
            
         "name": preCustomerDetails.name,
         "phoneNo":preCustomerDetails.mobileNo,
         "email": preCustomerDetails.email,

            //   "mandatory": true, // not req
            //  "checked": true, // not requ
            //   "islpgCng": true,  // not req

            sourceSystemId: "100002",
            authToken: "Pass@123",

            basicLiability: true,
            // biFuelKit:true, // other than cng it is false
            "biFuelKit":['petrol+cng','inbuilt','petrol+lpg'].includes(defaultRadioValue.fuelType)  ? true : (!["" || null || undefined].includes(addonsValue)&& addonsValue.externalBiFuelkit)
            ? true
            :false, // other than cng it is false
            newVehicle: CarIsRegistered? false:true,
            basicODCoverage:
            ( ( (finalPolicyType==undefined||null))?oldPolicyType=="thirdParty":finalPolicyType=="thirdParty")?false:true,

            prevYearInsurer: 4,
            prevYearPolicyEndDate: CarIsRegistered ? prevPolicyEndDate.split("-").reverse().join("-") : "",

            prevYearPolicyNo: "8686948888564865",

            prevYearPolicyStartDate: CarIsRegistered ? prevPolicyStartDate.split("-").reverse().join("-") : "",
            isPreviousPolicyDetailsAvailable: "true",
            prevYearPolicyType: "1",

            // biFuelIsChecked: true,
            biFuelIsChecked: false,
            biFuelIsMandatory: false,
            // biFuelIsLpgCng:  true,
            "biFuelIsLpgCng": ["petrol+cng", "inbuilt", "cng","lpg","petrol+lpg"].includes(
              defaultRadioValue.fuelType
            )
           
              ? true
              : false,
            "biFuelSumInsured": addonsValue.externalBiFuelkit?checkInput3:0, 
            "biFuelFuelType": defaultRadioValue.fuelType ==("petrol+cng"||'cng') ? "CNG":"LPG",

            vehicleVariant: "",
            cubicCapacity: 0,
            isNewVehicle:  CarIsRegistered? false:true,
            isBasicLiability: true,
            isBasicODCoverage: true,
            isBiFuelKit:  ["petrol+cng", "inbuilt", "cng","lpg","petrol+lpg"].includes(
              defaultRadioValue.fuelType
            )
              ? true:
              
              addonsValue.externalBiFuelkit? true
                : false,
            antiTheftIsMandatory: false,
            antiTheftPackageName: "",
            paToNamedPassengerCoveredPackageName: ""



       
           
       
            
     
    
          },

          {
            headers: {
              authorization: jwtToken,
              "Content-Type": "application/json",
              accept: "application/json",
              "x-rsa-type": 2
            }
          }
        )
        .then((res) => {
          setIsValidReq(true);

          dispatch(quoteActionsReliance.addFirstQuoteLoad(false));
        dispatch(quoteActionsReliance.setApiDataValues(res.data.relianceQuoteResponse ));
        dispatch(quoteActionsReliance.setQuoteLoading(false));

        const sessQuoApiVal = sessionStorage.getItem('sessQuoApiVal')
        if(sessQuoApiVal){
          const sessQuoApiValObj = JSON.parse(sessQuoApiVal)
          if(sessQuoApiValObj.relianceQuoteResponse){
            sessQuoApiValObj.relianceQuoteResponse =res.data.relianceQuoteResponse
          }
          sessionStorage.setItem('sessQuoApiVal', JSON.stringify(sessQuoApiValObj))
        }

        // localStorage.setItem("apiData", JSON.stringify(res));
        localStorage.setItem("apiLoaded", true);
        sessionStorage.setItem('relianceReqId',res.data.requestId)

  
        })

        .catch((error) =>{

          if(error){
          dispatch(quoteActionsReliance.setQuoteLoading(false));
          dispatch(quoteActionsReliance.setApiDataValues(null));
  
  
          }
  
        
       
        console.log(error) });
    setIsValidReq(false);
  };

  return { fetchAddonsQuoteReliance, updateAddonsValueReliance, updateCheckInputValuesReliance,updateCpaCoverValuesReliance,updatePrevAddValuesReliance};
};
