import React,{useState,useEffect} from 'react'

import axios from "axios";
import LoadingSpinner from '../paymentStatus/LoadingSpinner';
import PaymentSuccess from '../paymentStatus/PaymentSuccess';
import PaymentFailure from '../paymentStatus/PaymentFailure';



const PaymentStatus = () => {

const [rsaPaymentValue, setRsaPaymentValue] = useState()
const [apiQuoteData, setApiQuoteData] = useState()
const [paymentStatus, setPaymentStatus] = useState(null)
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
const [jwtToken, setJwtToken] = useState(localStorage.getItem('jwt_token'))
const apiData= sessionStorage.getItem("sessQuoApiVal")

useEffect(() => {
    const onStorage = () => {
      setJwtToken(localStorage.getItem('jwt_token'));
    };
  
    window.addEventListener('storage', onStorage);
  
    return () => {
        window.removeEventListener('storage', onStorage);
    };
  }, []);

  useEffect(()=>{
  setApiQuoteData(JSON.parse(apiData))

  },[apiData])

  useEffect(()=>{
    // axios.get(`https://staging-policymart.co.in/api/v1/payment/transactionHistory?quoteId=BA506423VPC0809493 `, {
    axios.get(`${API_ENDPOINT}/payment/transactionHistory?quoteId=${apiQuoteData&&apiQuoteData.rsaQuoteResponse.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.QUOTE_ID}`, {
        headers: {
          Authorization: `${jwtToken}`,
          'Content-Type': 'application/json',
           
        },
    },).then((response)=>{
        setRsaPaymentValue(response.data.data)
        localStorage.setItem("rsa_Trans_Payload",JSON.stringify(response.data.data))
      })
      .catch((err)=>{
        console.log(err)
      })
  

},[apiQuoteData])


  return (
    <div>
<div className={`${rsaPaymentValue==null?'':'hidden'} `}>

        <LoadingSpinner/>
</div>

<div className={`${rsaPaymentValue==null?'hidden':''}`}>

{rsaPaymentValue&&rsaPaymentValue.paymentStatus=="Accepted"?<PaymentSuccess rsaPaymentData={rsaPaymentValue}/> : <PaymentFailure/> }
    </div>
    </div>
  )
}

export default PaymentStatus