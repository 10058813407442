import React,{useState,useEffect,useRef} from "react";
import FaqSvg from "../assets/Svg/FaqSvg.svg";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const faqData = [
  {
    id: "1",
    question: "How can I purchase an Insurance Policy?",
    answer:
      "You can use our online platform to compare and purchase car and bike insurance plans. It is a 3-step process. Provide vehicle information. Compare quotes and purchase policy by providing the required information and making payment. While we are working on issuing the term and health insurance policies online, you can contact us for any health or term insurance requirements for you and your family members.If you have any questions about the process, you can call or chat with us on WhatsApp."
  },
  {
    id: "2",
    question: ""
  }
];
const Faq = () => {
 const [activeCard, setActiveCard] = useState(0)

  const cardRef = useRef(null);
  const [showCard, setShowCard] = useState(false);

  const faqCnt = [
    {
      id:1,
      que:'How can I purchase an Insurance Policy?',
      ans:"You can use our online platform to compare and purchase car and bike insurance plans. It is a 3-step process. Provide vehicle information. Compare quotes and purchase policy by providing the required information and making payment. While we are working on issuing the term and health insurance policies online, you can contact us for any health or term insurance requirements for you and your family members.If you have any questions about the process, you can call or chat with us on WhatsApp."
    },
    {
      id:2,
      que:'Do you provide any claims assistance?',
      ans:"We will help you in filing a claim with the insurance company, guide you on the documents required, and work with you till the claim is settled by the insurance company."
    },
    {
      id:3,
      que:'Will you be able to help in renewing an expired policy?',
      ans:"Yes, you can contact our insurance consultants to get your policy renewed. We will require the previous year's policy and more details to contact insurance companies."
    },
    {
      id:4,
      que:'What happens if there are any issues while purchasing a policy online?',
      ans:"Click on the contact us where you can contact us through WhatsApp or give us a call and we can check the status and issue the policy immediately."
    },
    {
      id:5,
      que:'Can I contact Policymart for any Insurance consultation?',
      ans:"Yes, our team has insurance experts from various lines of business, retail or commercial. We can provide assistance based on your requirement."
    },

  ]

  useEffect(() => {
    const aboutObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setShowCard(true);
          aboutObserver.unobserve(entry.target);
        }
      });
    }, {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    });

    if (cardRef.current) {
      aboutObserver.observe(cardRef.current);
    }

    

    return () => {
      aboutObserver.disconnect();
    };
  }, []);
  return (
    <div className="pb-12" ref={cardRef}>
      <h1 className="text-xl lg:text-3xl font-bold text-[#1f243c] text-center pb-5 lg:pb-12">
        FAQ
      </h1>
      <div className="flex flex-col-reverse lg:flex-row lg:items-start px-4 lg:px-0">
        <div className={`${showCard?'fa q1':'c ls'}`}>
          {faqCnt.map((item,key)=>(
            <div className=" " onClick={()=>{
              setActiveCard(item.id)
              setShowCard(!showCard)
              
            }}>

          <div
            className={`${activeCard===key+1  ? 'bg-hero  text-white':'bg-white'}  lg:w-[550px] flex items-center  px-4  py-2 my-5 lg:mt-8 drop-shadow-md border border-[#9292953c]  rounded-[10px] text-lg font-semibold`}
         
          >
            <h1 className="text-sm lg:text-base">
             {item.que}
            </h1>
            <div className="flex  flex-1" />
            {activeCard===key+1  ? <FaChevronUp /> : <FaChevronDown />}
          </div>
       
            <div className={` ${activeCard===key+1 ?'':'hidden'} lg:w-[550px] mb-7 lg:mb-10 p-5 lg:pt-0 shadow-[6px_6px_10px_rgba(0,167,142,0.16)] rounded-lg`}>
              <p className=" lg:w-[508px] text-[#1f243c] ">
         {item.ans}
              </p>
            </div>
       
      
      </div>
          ))}

    
        </div>
        
        <div className={`lg:flex flex-1 `} />
        <img src={FaqSvg} alt="FaqSvg" className={`${showCard?'f aq2':'cl s'} px-10 lg:px-0 pb-7 lg:pb-0`} />
      </div>
    </div>
  );
};

export default Faq;
