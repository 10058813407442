import BlogImg1 from "../assets/Vector/Blogs/BlogImg1.svg";
import BlogImg2 from "../assets/Vector/Blogs/BlogImg2.svg";
import BikeImg2 from "../assets/Vector/Blogs/bikeImg2.jpg";
import BikeImg1 from "../assets/Vector/Blogs/bikeImg11.jpg";
import HealthImg1 from "../assets/Vector/Blogs/healthImg2.jpg";
import HealthImg2 from "../assets/Vector/Blogs/healthImg3.jpg";
import TermImg1 from "../assets/Vector/Blogs/termImg1.jpg";
import TermImg2 from "../assets/Vector/Blogs/termImg2.jpg";


export const blogsData = [
  {
    id: "1",
    imgUrl: BlogImg1,
    category: "car",
  
   slug:'deciding-the-right-insurance-for-your-car',
    date: "10-01-2023",
    title: "Deciding the right insurance for your car",
    subTitle: `<p>Car insurance in India is mandatory for all vehicles that are driven on public roads. There are several types of car insurance policies available in India, and the most basic one is third-party liability insurance, which is required by law. This type of insurance covers any damage or injury that you may cause to another person or their property while operating your vehicle ... </p>`,
    description:`	<p  >Car insurance in India is mandatory for all vehicles that are driven on public roads. There are several types of car insurance policies available in India, and the most basic one is third-party liability insurance, which is required by law. This type of insurance covers any damage or injury that you may cause to another person or their property while operating your vehicle.
    <br/>
    <br/>
In addition to third-party liability insurance, there are other types of car insurance policies available in India, such as comprehensive insurance and standalone own-damage insurance. Comprehensive insurance is a more comprehensive policy that covers not just third-party liability but also damage to your own vehicle in the event of an accident, theft, or natural disaster. Standalone own-damage insurance, on the other hand, covers only damage to your own vehicle in the event of an accident, and does not include third-party liability coverage.
    <br/>
Car insurance premiums in India are typically calculated based on a number of factors, such as the make and model of the vehicle, the age and experience of the driver, and the location where the vehicle is primarily driven. 
    <br/>
    <br/>
It's always good to go through the terms and condition and coverage of the policy before purchasing. Also you should compare and contrast the premium rates and coverage offered by different insurance providers in order to make an informed decision.
    <br/>
    <br/>
There are several add-ons or riders that you can include in your car insurance policy to enhance your coverage. Some of the most common car insurance add-ons are:
    <br/>
1.	<b>Zero Depreciation Cover : </b> This add-on offers coverage for the full value of your car's parts without any deduction for depreciation. This coverage is particularly useful for newer vehicles.
    <br/>
2.	<b>Engine Protection Cover: </b> This add-on covers the cost of repairs to your car's engine in the event of damage due to accidental oil leakage or water ingression.
    <br/>
3.	<b>NCB (No Claim Bonus) Protector:</b> This add-on allows you to retain your no claim bonus even if you make a claim during the policy period.
    <br/>
4.	<b>Return to Invoice (RTI) Cover: </b> This add-on covers the difference between the insurer's settlement and the original invoice value of the car in case of a total loss.
    <br/>
5.	<b>Roadside Assistance: </b> This add-on offers assistance in case of a breakdown or accident, such as towing services, emergency fuel delivery, and assistance with changing a flat tire.
    <br/>
6.	<b>Consumable Cover:</b> This add-on covers the cost of certain consumable items, such as oil, lubricants, and batteries that are needed in the process of repairing your car following an accident.
    <br/>
7.<b>	Key Replacement Cover: </b> This add-on covers the cost of replacing lost or stolen car keys.
    <br/>
8.	<b>Accessory Cover:</b> This add-on covers the cost of any non-standard accessories fitted to your car, such as stereo systems, alloy wheels, and seat covers.
    <br/>
These are some of the common add-ons available in India, However it's not available with every insurance company and also depends on the type of policy you are opting for. It's good to review the terms and coverage before adding any add-ons to your policy to ensure that you are getting the coverage that you need at a price that you can afford.
    <br/>
  </p>`
  },
  {
    id: "2",
    imgUrl: BlogImg2,
    category: "car",
   slug:'ways-to-decrease-your-premium',

    date: "10-01-2023",
    title: "Ways to decrease your premium",
    subTitle: `	<p>Opt for a higher voluntary deductible: A voluntary deductible is the amount that you agree to pay out of your own pocket before the insurance company starts covering the costs. If you agree to a higher voluntary deductible, your insurance premium will be lower ...</p>
  `,
  description:`	<p>1.	<b>Opt for a higher voluntary deductible: </b> A voluntary deductible is the amount that you agree to pay out of your own pocket before the insurance company starts covering the costs. If you agree to a higher voluntary deductible, your insurance premium will be lower.
  <br/>
2.	<b>Maintain a good driving record: </b> A clean driving record can help you get lower insurance premiums, so try to avoid getting traffic violations and accidents.
  <br/>
3.	<b>Install safety features:</b> Cars with safety features like airbags and anti-theft systems are considered less risky to insure and can therefore lead to lower premiums.
  <br/>
4.<b>	Consider the type of coverage:</b> Be mindful of the coverage you are choosing, as there are different types of coverage and each one has different premium rates. Compare these rates and choose the one that best fits your needs and budget.
  <br/>
5.	<b>Bundle your policies:</b> Some insurance providers offer discounts for bundling multiple policies, so consider bundling your car insurance with your home or health insurance.
  <br/>
6.<b>	Compare different insurance providers: </b> Compare the premiums and coverage of different insurance providers and look for discounts and offers.
  <br/>
7.	Use a membership or loyalty program: Many insurance providers offer membership or loyalty programs that can help you save money on your car insurance premium.
  <br/>
  <br/>
There are several factors that can increase the risk associated with car insurance in India, including:
  <br/>
1.	<b>Location: </b> If a vehicle is primarily driven in an area with a high incidence of accidents or theft, the risk of an insurance claim is higher, and therefore the insurance premium will be higher.
  <br/>
2.<b>	Make and model of the vehicle:</b> Some makes and models of cars are considered more prone to accidents or theft, and therefore may carry higher insurance risks and premiums.
  <br/>
3.	<b>Age and experience of the driver: </b> Younger, less experienced drivers are considered higher risks than older, more experienced drivers, and may therefore be charged higher premiums.
  <br/>
4.	<b>Driving history: </b> A driver with a history of traffic violations or accidents may be considered a higher risk, and therefore may be charged higher premiums.
  <br/>
5.	<b>Claims history: </b> If a driver has a history of making insurance claims, the insurer may consider them a higher risk and charge higher premiums.
  <br/>
6.<b>	Occupation: </b> If a driver is considered to have a high-risk occupation, such as a delivery driver or a taxi driver, the insurer may charge higher premiums.
  <br/>
7.<b>	Modifications on the car : </b> Some modifications like high end sound systems, alloy wheels can make the car more attractive for thieves and also more costly to repair in the event of an accident.
  <br/>
  <br/>
It's important to keep in mind that these factors can vary from one insurance provider to another, so it's always a good idea to shop around and compare the prices and coverage offered by different insurers. You should always provide accurate and honest information to your insurance provider in order to get the best rates.
  <br/>
</p>`
  },
  {
    id: "3",
    imgUrl: BikeImg2,
    category: "bike",
    link: "/bikeInsurance",
    date: "10-01-2023",
    title: "Purchasing the right insurance for your bike",
    subTitle: `	<p>Purchasing the right insurance for your bike in India can be a bit complicated as there are a few different types of policies available and each one offers different levels of coverage. Here are some tips to help you purchase the right insurance for your bike  ...</p>
  `,
  description:`		<p>Purchasing the right insurance for your bike in India can be a bit complicated as there are a few different types of policies available and each one offers different levels of coverage. Here are some tips to help you purchase the right insurance for your bike:
  <br/>
a.	<b>Understand the different types of bike insurance:</b> There are two types of bike insurance policies available in India: third-party liability insurance and comprehensive insurance. Third-party liability insurance is mandatory and covers any damage or injury you may cause to another person or their property while operating your bike. Comprehensive insurance is optional and covers not just third-party liability but also damage to your own bike in the event of an accident, theft, or natural disaster.
  <br/>
b.	<b>Assess your needs:</b> Based on your biking habits, lifestyle and budget, you can decide which type of insurance would be best for you.
  <br/>
c.	<b>Compare coverage and costs:</b> Get quotes from different insurance providers and compare the coverage and costs of each policy. Make sure to read the fine print and understand the exclusions, riders and add-ons available.
  <br/>
d.<b>	Discounts:</b> Look for discounts and offers from different insurance providers. Many insurance providers offer discounts for safety features, such as helmets and anti-theft devices, and for being a member of a biking club or organization.
  <br/>
e.	<b>Check the insurance company’s reputation: </b> You should do a background check of the company and read reviews before making a purchase.
  <br/>
f.	<b>Claims process :</b> You should also find out about the claims process and how easy or difficult it is to make a claim.
  <br/>
g.	<b>Add-on coverage: </b>Look for add-on coverage options like consumable coverage, zero-depreciation coverage, accessories coverage, roadside assistance etc. which can be useful in case of damage to the vehicle.
  <br/>
By following these tips, you can ensure that you are purchasing the right insurance for your bike and getting the best coverage at the most affordable price.
  <br/>
</p>`
  },
  {
    id: "4",
    imgUrl: BikeImg1,
    category: "bike",
    link: "/bikeInsurance",
    date: "10-01-2023",
    title: "Uses of PA policy",
    subTitle: `		<p>PA, or personal accident insurance, is a type of insurance policy that provides financial compensation in the event of accidental injury or death. In India, there are several uses for PA insurance policies</p>
  `,
  description:`	<p>1.	<b>PA, or personal accident insurance:</b> is a type of insurance policy that provides financial compensation in the event of accidental injury or death. In India, there are several uses for PA insurance policies:
  <br/>
2.	<b>Personal Accident coverage for the policyholder:</b> Personal accident insurance policies provide coverage for the policyholder in case of accidental injury or death, regardless of who is at fault. This means that in case of an accident, the policyholder will receive a financial payout that can be used to cover medical expenses, lost wages, and other costs associated with the accident.
  <br/>
3.	<b>Coverage for passengers:</b> Some personal accident insurance policies also provide coverage for passengers in the event of an accident, regardless of whether they are in the policyholder's car or in another vehicle.
  <br/>
4.<b>	Coverage for co-passengers:</b> Some policies provides coverage for co-passenger in the car while travelling.
  <br/>
5.	<b>Coverage for drivers of commercial vehicles:</b> Personal accident insurance policies can also be purchased by the owner of commercial vehicles such as taxis, trucks, and buses to provide coverage for the drivers of these vehicles in case of an accident.
  <br/>
6.	<b>Add-on to existing policies:</b> Many car or bike insurance policies in India include personal accident cover for the driver as an add-on, It's a optional coverage but can be useful in case of accident, it can provide the driver with financial protection against accidental injury or death.
  <br/>
7.	It's important to note that the coverage and benefits offered by PA insurance policies can vary from one provider to another. It's important to review the terms and conditions of the policy and understand the coverage offered before purchasing a policy to ensure that you are getting the protection that you need.
  <br/>
</p>`
  },
  {
    id: "5",
    imgUrl: HealthImg2,
    category: "health",
    link: "/healthInsurance",
    date: "10-01-2023",
    title: "Rising medical costs",
    subTitle: `<p>Lack of regulation: In India, there is a lack of regulation in the healthcare sector, which has led to a lack of standardization in pricing. This has led to hospitals and clinics charging exorbitant fees for medical procedures, tests, and treatments ...</p>
  `,
  description:`	<p>a.	<b>Lack of regulation:</b> In India, there is a lack of regulation in the healthcare sector, which has led to a lack of standardization in pricing. This has led to hospitals and clinics charging exorbitant fees for medical procedures, tests, and treatments.
  <br/>
b.	<b>Lack of government control:</b> The government has limited control over the healthcare sector in India, which has allowed private hospitals and clinics to dominate the market. This lack of competition has allowed them to charge high prices for medical services.
  <br/>
c.	<b>Lack of insurance coverage:</b> Many people in India do not have health insurance coverage, which means they are unable to afford medical care when they need it. This has led to a rise in the number of people who are unable to access healthcare due to financial constraints.
  <br/>
d.	<b>Increasing costs of medical treatments:</b> The cost of medical treatments in India has been increasing rapidly, with the price of drugs and medical procedures rising every year. This has put a strain on people's finances, as they struggle to afford the cost of healthcare.
  <br/>
e.<b>	Lack of infrastructure:</b> There is a lack of healthcare infrastructure in many parts of India, which means that people are unable to access medical care when they need it. This has led to a rise in the number of people who are unable to access healthcare due to a lack of facilities.
  <br/>
</p>`
  },
  {
    id: "6",
    imgUrl: HealthImg1,
    category: "health",
    link: "/healthInsurance",
    date: "10-01-2023",
    title: "Know the benefits of health insurance policy",
    subTitle: `	<p>There are several benefits of having a health insurance policy in India:
    Financial protection: Health insurance provides financial protection against unexpected medical expenses. It covers the cost of hospitalization, diagnostic tests, surgeries, and other medical expenses, which can be financially draining ...</p>
  `,
  description:`	<p>There are several benefits of having a health insurance policy in India:
  <br/>
a.	<b>Financial protection:</b> Health insurance provides financial protection against unexpected medical expenses. It covers the cost of hospitalization, diagnostic tests, surgeries, and other medical expenses, which can be financially draining.
  <br/>
b.<b>	Wide coverage: </b> Health insurance policies in India offer wide coverage for various medical expenses, including pre- and post-hospitalization expenses, ambulance charges, and even Ayurvedic and homeopathic treatments.
  <br/>
c.	<b>Tax benefits:</b> Health insurance policies are eligible for tax deductions under Section 80D of the Income Tax Act, 1961. This means that premiums paid towards health insurance are tax-exempt up to a certain limit.
  <br/>
d.	<b>Cashless treatment:</b> Health insurance policies offer the option of cashless treatment at network hospitals. This means that the policyholder does not have to pay any upfront costs and the insurance company will directly pay the hospital for the medical expenses.
  <br/>
e.	<b>Peace of mind:</b> Having a health insurance policy gives the policyholder peace of mind knowing that they are financially protected in case of any medical emergencies. It also allows them to focus on their recovery rather than worrying about financial stress.
  <br/>
</p>`
  },
  {
    id: "7",
    imgUrl: TermImg2,
    category: "term",
    link: "/carInsurance",
    date: "10-01-2023",
    title: "Different types of life insurance products",
    subTitle: `<p>Term Life Insurance: This type of life insurance provides coverage for a specific period of time, usually between 10-30 years. It provides a financial payout to the beneficiary in case of the policyholder's death during the term of the policy ... </p>
  `,
  description:`	<p>a.	<b>Term Life Insurance:</b> This type of life insurance provides coverage for a specific period of time, usually between 10-30 years. It provides a financial payout to the beneficiary in case of the policyholder's death during the term of the policy.
  <br/>
b.	<b>Whole Life Insurance:</b> This type of life insurance provides coverage for the entire lifetime of the policyholder. It also includes a savings component, allowing the policyholder to accumulate cash value over time.
  <br/>
c.	<b>Endowment Policy:</b> This type of life insurance combines protection with savings. It provides a financial payout to the beneficiary in case of the policyholder's death, as well as a lump sum payment at the end of the policy term.
  <br/>
d.	<b>Unit Linked Insurance Plan (ULIP):</b> This type of life insurance combines protection with investment. A portion of the premium is used to provide life insurance coverage, while the remainder is invested in a variety of financial instruments, such as mutual funds and stocks.
  <br/>
e.	<b>Money Back Policy:</b> This type of life insurance provides regular payments to the policyholder during the policy term, in addition to a financial payout to the beneficiary in case of the policyholder's death.
  <br/>
f.	<b>Annuity:</b> This type of life insurance provides a regular income to the policyholder during their retirement years. It is typically purchased by individuals who want to ensure a steady stream of income in their later years.
  <br/>
</p>`

  },
  {
    id: "8",
    imgUrl: TermImg1,
    category: "term",
    link: "/carInsurance",
    date: "10-01-2023",
    title: "Investment products available in market",
    subTitle: `	<p>Traditional life insurance policies: These are the most common types of life insurance policies that offer a combination of insurance coverage and investment opportunities. The premiums paid towards these policies are invested in various investment instruments, and the policyholder is entitled to receive a certain amount of returns upon maturity of the policy ...</p>
  `,
  description:`	<p><b>Traditional life insurance policies:</b> These are the most common types of life insurance policies that offer a combination of insurance coverage and investment opportunities. The premiums paid towards these policies are invested in various investment instruments, and the policyholder is entitled to receive a certain amount of returns upon maturity of the policy.
  <br/>
a.<b>	Unit-linked insurance plans (ULIPs):</b> These are investment-oriented insurance policies that offer flexibility in terms of investment options. Policyholders can choose to invest in a variety of funds such as equity, debt, or a mix of both, depending on their risk appetite. ULIPs also offer the benefit of insurance coverage.
  <br/>
b.	<b>Endowment policies:</b> These policies offer a fixed sum upon maturity of the policy, which can be used for various purposes such as education, retirement, or any other financial goals. Endowment policies also offer a certain amount of insurance coverage.
  <br/>
c.	<b>Money back policies: </b>These policies offer periodic payouts to the policyholder during the policy term, in addition to the sum assured upon maturity. Money back policies can be used to meet regular financial commitments such as paying for children's education or other expenses.
  <br/>
d.<b>	Whole life insurance policies:</b> These policies offer lifelong insurance coverage to the policyholder and also offer investment opportunities. The premiums paid towards these policies are invested in various investment instruments, and the policyholder is entitled to receive a certain amount of returns upon maturity of the policy.
  <br/>
  <br/>
</p>`

  },
];
