import React, { useState, useEffect } from "react";
import ContactusFrame from "../assets/Svg/ContactusFrame.svg";
import DropDownPP from "../components/customs/DropDownPP";
import axios from "axios";
import InputPP from "../components/customs/InputPP";

const ContactUsCard = ({apiPath}) => {
  const [datas, setDatas] = useState({});
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [jwtToken, setJwtToken] = useState(localStorage.getItem('jwt_token'))


  /* -------------------  API ENDPOINT -------------- */
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
/* ---------------------------------- -------------- */

  const handleChange = (e) => {
    const name = e.target.name;

    const value = e.target.value;
    setInputs((values) => ({
      ...values,
      [name]: value
    }));
  };

  //generating JWt token 
  useEffect(() => {
    const onStorage = () => {
     setJwtToken(localStorage.getItem('jwt_token'))
    };
  
    window.addEventListener('storage', onStorage);
  
    return () => {
        window.removeEventListener('storage', onStorage);
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      inputs.user_Name == "" ||
      inputs.user_Email == "" ||
      inputs.insurance_type == "" ||
      inputs.user_Mobile == "" ||
      // inputs.user_Countrycode == "" ||
      inputs.details == "" ||
      inputs.user_Name == undefined ||
      inputs.user_Email == undefined ||
      // inputs.user_Countrycode == undefined ||
      inputs.user_Mobile == undefined ||
      inputs.insurance_type == undefined ||
      inputs.details == undefined
    ) {
      setError(true);
    } else {
      setError(false);
      setSuccess(true);
    }


    
    axios
      .post(
        `
       ${API_ENDPOINT}/${apiPath}`,
        {
          name: inputs.user_Name,
          email: inputs.user_Email,
          contactNo: inputs.user_Mobile,
          message: inputs.details,
          insuranceType : inputs.insurance_type
        },
        {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
            'authorization':jwtToken

          }
        }
      )
      .then((res) =>{})
      .catch((error) => console.log(error));

    // Mobile Number Validation
    if (Object.values(inputs).length > 0 && inputs.user_Mobile.length < 10) {
      setError(true);
      setSuccess(false);
    }

    // Email Validation
    if (
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inputs.user_Email)
    ) {
      return true;
    }
    alert("You have entered an invalid email address!");
  };
  return (
    <div className="bg-hero lg:w-[350px] h-max pb-5 px-5 mx-4 lg:mx-0 py- rounded-lg">
      <div className="flex items-center">
        <h2 className="font-semibold text-[18px] l text-white lg:leading-8">
          Request a <br />
          <span className="text-[#FECE4C]">Callback </span> from <br /> our Team
        </h2>
        <div className="flex flex-1"></div>
        <img src={ContactusFrame} alt="ContactusFrame" className="w-36 lg:w-40" />
      </div>
      <form onChange={handleChange}>
        <div className="bg-[rgba(0,0,0,0.2)] px-3 py-2 space-y-3 mt-2 ">
          <InputPP
            id="user_Name"
            className="w-full p-3 rounded-lg"
            labelClassName="hidden"
            type="text"
            placeholder="Your Name"
            brClass="hidden"
            required={false}
          />
          <InputPP
            id="user_Email"
            className="w-full p-3 rounded-lg"
            labelClassName="hidden"
            type="email"
            placeholder="Your Email ID"
            brClass="hidden"
            required={false}
          />
          <div className="flex space-x-4 w-full">
            {/* <InputPP
              id="user_Countrycode"
              className="w-16 p-3 rounded-lg"
              labelClassName=""
              type="number"
              placeholder="+91"
              brClass="hidden"
              required={false}
            /> */}
            <InputPP
              id="user_Mobile"
              className="w-full p-3 rounded-lg"
              labelClassName="hidden"
              type="number"
              placeholder="Your Phone number"
              brClass="hidden"
              parenetClassName="w-full"
              required={false}
            />
          </div>
          <DropDownPP
            brClass="hidden"
            label="Insurance"
            labelClassName="hidden"
            placeholder="Your Name"
            id="insurance_type"
            options={[
              "Car Insurance",
              "Bike Insurance",
              "Health Insurance",
              "Term Insurance"
            ]}
            className="px-2 w-full py-3 rounded-lg "
          />
          <textarea
            cols="30"
            rows="10"
            className="w-full h-32 p-2 rounded-lg focus:outline-none"
            placeholder="Details"
            name="details"
          ></textarea>
          <input
            type="submit"
            className="bg-[#FECE4C]  text-[#084F44] w-full p-2 rounded-lg text-lg font-bold focus:outline-none cursor-pointer "
            onClick={handleSubmit}
            value="REQUEST CALLBACK"
          />{" "}
        </div>
      </form>
      {error && (
        <>
          <p className="text-[#e11010f7] font-bold  text-center ">
            Please fill all the mandatory fields
          </p>
        </>
      )}
      {success && (
        <p className="text-white pt-3 font-semibold text-center">
          ✅ Your form has been Submitted Successfully. our Support team will
          Contact you shortly{" "}
        </p>
      )}
    </div>
  );
};

export default ContactUsCard;
