import React,{useState} from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const CardSkelton = () => {
const colorCode= '#6abbaf2c'
  return (
    <div className='w-full space-y-3 border border-[#999fa41d]'>
        <div className='card-skelton items-center'>
   <div className="left-col">

   <Skeleton baseColor={colorCode} circle  width={55} height={55}/>
   </div>

<div className="right-col">

   <Skeleton  baseColor={colorCode} height={40} className='w-full'  />
</div>

     </div>


     <div className=''>
   <Skeleton   baseColor={colorCode}   />
   <Skeleton   baseColor={colorCode} />

     </div>
    </div>
  )
}

export default CardSkelton