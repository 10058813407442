import React, { useState, useEffect } from "react";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import PolicyPlanCard from "../UI/PolicyPlanCard";
import { FilterType } from "./carInsurance/quotation/FilterType";
import LoadingSpinner from "./paymentStatus/LoadingSpinner";

const QuotePolicyFilter = ({ quoteData, loading,loadingData,quoteLoaded, isPopOpen,detailsPop,objPolicy,dataLoading,quoteDetailDatas }) => {
  const [closePopUp, setClosePopUp] = useState(false);
  const [isMobileFilter, setIsMobileFilter] = useState(false);
  const [missionAwake, setMissionAwake] = useState()
  const [oldPolicyType, setOldPolicyType] = useState();

const [loader, setLoader] = loading
const [loadApiData, setLoadApiData] =loadingData
  //PolicyPlanData
  const [isDetailsPopUp, setisDetailsPopUp] = detailsPop;
  const [quoteDetailsData, QuoteDetailsData] =quoteDetailDatas
const [uniApiData, setUniApiData] = useState({})
const [policyType, setPolicyType] = useState()
const [actionType, setActionType] = useState('')

const universalApiData = localStorage.getItem('apiData')
const radioValue = localStorage.getItem("car_Info");
const policy = sessionStorage.getItem('inputs_policyType')
const actiontype = sessionStorage.getItem('action_type')



useEffect(()=>{
 setActionType(actiontype)

},[actiontype])

// useEffect(()=>{
//   setUniApiData (JSON.parse(universalApiData))

// },[universalApiData])

// useEffect(() => {
//   window.localStorage.getItem("apiData");
//   window.dispatchEvent(new Event("storage"));

// window.addEventListener('storage', () => {
//     console.log("change to local storage!");
// })

  // const onStorage = () => {
  //   setUniApiData(JSON.parse(localStorage.getItem('apiData')));
  // };

  // window.removeEventListener('storage', onStorage);
  
  // return () => {
  //   window.addEventListener('storage', onStorage);
  // };
// }, []);


  return (
    <>
      <div className="lg:bg-white  lg:shadow-[8px_8px_36px_rgba(0,167,142,0.18)]     lg:w-full xl:w-[333px] lg:h-[150p x]  flex flex-col justify-center m-  rounded-md lg:p-4">
        <h1 className="font-bold  text-white hidden lg:block text-base">
          {/* Quick Filters */}
        </h1>
        <div className="lg:bg-[r gba(0,0,0,0.11)] rounded-lg my-2 lg:p-2">
          {/* <div
            className={`${
              closePopUp
                ? "absolute bg-[rgba(0,0,0,0.2)] w-screen h-screen top-0 left-0"
                : "hidden"
            } `}
          ></div> */}
          <div className="relative">
            {/* For Desktop View Filters */}
            <div className="relative hidden lg:block">
              <FilterType
              mission={ [missionAwake, setMissionAwake]}
                custQuoteData={quoteData}
                isPopOpened={isPopOpen}
                isQuoteLoad={quoteLoaded}
                loading={[loader, setLoader]}
                loadingData={[loadApiData, setLoadApiData]}
                closeState={[closePopUp, setClosePopUp]}
                filterData={ actionType=='Car' ? [
                  {
                    id: "1",
                    title: "Policy Type",
                    placeHolder: "All"
                  },
                  {
                    id: "2",
                    title: "Cover Amount (IDV)",
                    placeHolder: "Best Deal"
                  },
                  {
                    id: "3",
                    title: "Discount",
                    placeHolder: "None"
                  },
                  // {
                  //   id: "4",
                  //   title: "Add-Ons",
                  //   placeHolder: "None"
                  // }
                ]
              :
                
              [
                {
                  id: "1",
                  title: "Policy Type",
                  placeHolder: "All"
                },
                {
                  id: "2",
                  title: "Cover Amount (IDV)",
                  placeHolder: "Best Deal"
                },
                {
                  id: "3",
                  title: "Discount",
                  placeHolder: "None"
                },
                // {
                //   id: "4",
                //   title: "Add-Ons",
                //   placeHolder: "None"
                // }
              ]

              }
              />
            </div>

           
            {/* For Mobile View Filter */}
            <div className="relative lg:hidden  ">
              <div
                className="bg-hero p-2 mb-4 text-white flex items-center rounded-md "
                onClick={() => {
                  setIsMobileFilter(!isMobileFilter);
                }}
              >
                <h1 className="font-bold  ">Quick Filters</h1>
                <div className="flex flex-1"></div>
                {isMobileFilter ? <AiOutlineUp /> : <AiOutlineDown />}
              </div>
              <div className={`${isMobileFilter?'':'hidden'}`}>

            
        
                <FilterType
                mission={ [missionAwake, setMissionAwake]}
                custQuoteData={quoteData}
                isPopOpened={isPopOpen}
                isQuoteLoad={quoteLoaded}
                loading={[loader, setLoader]}
                loadingData={[loadApiData, setLoadApiData]}
                closeState={[closePopUp, setClosePopUp]}

                filterData={ actionType=='Car' ? [
                  {
                    id: "1",
                    title: "Policy Type",
                    placeHolder: "All"
                  },
                  {
                    id: "2",
                    title: "Cover Amount (IDV)",
                    placeHolder: "Best Deal"
                  },
                  {
                    id: "3",
                    title: "Discount",
                    placeHolder: "None"
                  },
                  {
                    id: "4",
                    title: "Add-Ons",
                    placeHolder: "None"
                  }
                ]
              :
                
              [
                {
                  id: "1",
                  title: "Policy Type",
                  placeHolder: "All"
                },
                {
                  id: "2",
                  title: "Cover Amount (IDV)",
                  placeHolder: "Best Deal"
                },
                {
                  id: "3",
                  title: "Discount",
                  placeHolder: "None"
                },
                // {
                //   id: "4",
                //   title: "Add-Ons",
                //   placeHolder: "None"
                // }
              ]

              }
                />
             
                </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* <div className={`${loader?'':'hidden'}  pt-5 pb-8`}> */}
        {/* <PolicyPlanCard
            detailsPop={[isDetailsPopUp, setisDetailsPopUp] }
            objPolicy={objPolicy}
            redirectUrl="/carinsurance/car-profile/step-1"
            quoteData={uniApiData}
            dataLoading={dataLoading}
            quoteDetailData={[quoteDetailsData, QuoteDetailsData]}
          /> */}
          {/* <LoadingSpinner navTrue={false}/> */}
        {/* </div> */}
        </>
  );
};

export default QuotePolicyFilter;
