import React from "react";
import { Link } from "react-router-dom";
import NavHeader from "../components/NavHeader";

const ProposalFailure = () => {
  return (
    <React.Fragment>

    <div className="bg-white w-80 drop-shadow-lg shadow-xl flex flex-col items-center justify-center lg:py-5 lg:px-4">
      <div className="">
        <picture>
          <source
            srcSet="https://fonts.gstatic.com/s/e/notoemoji/latest/1f612/512.webp"
            type="image/webp"
          />
          <img
            src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f612/512.gif"
            alt="😒"
            width="60"
            height="60"
          />
        </picture>
      </div>
      <div className="py-3   space-y-2">
        <h1 className="text-center font-semibold">Something Went Wrong</h1>
        <p className="text-center font-medium text-sm capitalize">It's not you, it's us. Please try again later.</p>
        <div className="w-max m-auto">

        <Link to='/'>
        <p className="bg-hero text-white w-20 text-center py-1.5 rounded-md lg: mt-2  ">
        Home
        </p>
        </Link>
        </div>
      </div>
    </div>
    </React.Fragment>

  );
};

export default ProposalFailure;
