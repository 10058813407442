import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  AiOutlineCheck,AiOutlineClose
 
} from "react-icons/ai";
import MakePayment from "../MakePayment";
import KycModal from "../../UI/KycModal";
import QuoteLoading from "../../UI/QuoteLoading";
import { useSelector } from "react-redux";
import NavHeader from "../NavHeader";
import ProposalFailure from "../../UI/ProposalFailure";

const BikeInfoStep5 = ({propLoad}) => {
  const navigate = useNavigate();

  const [kycData, setKycData] = useState()
const [isDataLoaded, setIsDataLoaded] = propLoad
const [proposalData, setProposalData] = useState({})
const [typeOfPolicy, setTypeOfPolicy] = useState()
const [godigitReSta, setGodigitReSta] = useState('')
const [kycResponse, setKycResponse] = useState()
const [jwtToken, setJwtToken] = useState(localStorage.getItem('jwt_token'))
const [kycloading, setKycloading] = useState(false)


const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
const tileSelected = localStorage.getItem('tile_Type_Detail')
  const godigitKycData = localStorage.getItem("godigitKycInput");
  const propData = localStorage.getItem('bike_payment_Data')
  const godigtSta = sessionStorage.getItem('godigitSta')

  let isApiFailed = useSelector(state=>state.carProposal.isApiFailed)

  useEffect(()=>{

  Object.values(proposalData).length>0&&  godigitReSta!=='DONE' && isDataLoaded&&axios.get(`${API_ENDPOINT}/kyc/kycStatus?policyNumber=${proposalData&&proposalData.policyNumber}`, {
    // isDataLoaded&&axios.get(`${API_ENDPOINT}/kyc/kycStatus?policyNumber=undefined`, {
    headers: {
      Authorization: `${jwtToken}`,
      'Content-Type': 'application/json',
    },
},).then((response)=>{

  setKycResponse(response.data.goDigit_kycStatusResponse.kycVerificationStatus)
  sessionStorage.setItem('godigitSta',response.data.goDigit_kycStatusResponse.kycVerificationStatus)

  setTimeout(()=>{

    !isApiFailed&& response.data.goDigit_kycStatusResponse.kycVerificationStatus==='DONE'&&navigate('/bikeinsurance/bike-profile/preview')
  },3000)
  })
  .catch((err)=>{
    console.log(err)
  })
},[proposalData,isApiFailed, isDataLoaded])

const handleVerify =()=>{
  setKycloading(true)

  godigitReSta!=='DONE' && axios.get(`${API_ENDPOINT}/kyc/kycStatus?policyNumber=${proposalData&&proposalData.policyNumber}`, {
    headers: {
      Authorization: `${jwtToken}`,
      'Content-Type': 'application/json',
    },
},).then((response)=>{
  setKycloading(false)

  setKycResponse(response.data.goDigit_kycStatusResponse.kycVerificationStatus)
  sessionStorage.setItem('godigitSta',response.data.goDigit_kycStatusResponse.kycVerificationStatus)

  if(  response.data.goDigit_kycStatusResponse.kycVerificationStatus=='DONE'){
    setTimeout(()=>{
    
    
      !isApiFailed&&   navigate('/bikeinsurance/bike-profile/preview')
    },3000)
  }
  else if (response.data.goDigit_kycStatusResponse.link!==''){
    window.open(response.data.goDigit_kycStatusResponse.link, '_blank');

  }
  })
  .catch((err)=>{
    setKycloading(false)

    console.log(err)
  })
  
}
useEffect(()=>{
  if(Object.keys(proposalData).length>0){
    setIsDataLoaded(true)
  }
  setGodigitReSta(godigtSta)
},[proposalData,godigtSta])


useEffect(()=>{
  if(godigitReSta==='DONE') {
    setKycResponse('DONE')
  }
  },[godigitReSta])
  
useEffect(()=>{
  godigitKycData&& setKycData(JSON.parse(godigitKycData))
  setProposalData(JSON.parse(propData))
  setTypeOfPolicy(tileSelected)
},[godigitKycData, propData, tileSelected])

useEffect(()=>{

  // if(){

   
  // }
 
  typeOfPolicy&&typeOfPolicy!=="GoDigit"  && navigate('/bikeinsurance/bike-profile/preview')
 
  
},[ typeOfPolicy])


  return (
    <div>


    {isApiFailed?<>
    <NavHeader/>
   <div className="flex items-center justify-center flex-col h-screen">
    <ProposalFailure/>
    </div> 
    
    </>:
    <>
  <div className=" n">

  {/* {isDataLoaded&&(kycResponse&&kycResponse.kycVerificationStatus=='NOT_DONE')? <KycModal/>:<QuoteLoading/>} */}
  {isDataLoaded? <div className='flex items-center justify-center flex-col h-screen'> 
    <div className="absolute   bg-[rgba(119,117,117,0.43)] w-screen h-screen z-0"></div>
<div className='border-2  bg-white rounded-md border-white shadow-xl drop-shadow-xl w-96 h-60  z-50'>
    <div className="flex flex-col justify-center items-center mt-16">
    <AiOutlineCheck className={`${kycResponse==='DONE'?'':'hidden'} bg-hero tick-animation text-white m-auto w-16 h-16 rounded-full p-2 mb-2 font-bold`}/>
    <AiOutlineClose className={`${kycResponse==='FAILED'?'':'hidden'} bg-[#F44336] text-white m-auto w-14 h-14 tick-animation mb-3  rounded-full p-3 font-extrabold   `} />


    <button className={`${kycResponse==='NOT_DONE'?'':'hidden'}   bg-hero w-max px-5 py-2 rounded-full text-white focus:outline-none cursor-pointer mb-2`} onClick={handleVerify} >
    {kycloading?'Verifying...':'Verify Kyc'}</button>
    <p className={` ${kycResponse==='DONE'?'hidden':''}  px-7 leading-5 text-center text-base py-2 text-[#2e302f]`}>
    {kycResponse=='FAILED'? 'KYC verification failed. Please contact us to proceed.':  'Please verify your KYC on the Godigit page before making payment.' }
    </p>
    <p className={` ${kycResponse==='DONE'?'':'hidden'} px-7 leading-5 text-center text-sm font- py-2 text-[#2e302f]`}>
    KYC verified
    </p>
    </div>
    
    </div></div>:<QuoteLoading/>}
  </div>
    </>

    }
  <div className=""></div>
  {/* <MakePayment title="Buy Car Insurance" /> */}
</div>
  );
};

export default BikeInfoStep5;
