import React, { useState,useEffect } from "react";
import NavHeader from "../components/NavHeader";
import { Link } from "react-router-dom";
import LandingBackground from "../assets/Svg/LandingPageBck.svg";
import SectionBck from "../UI/SectionBck";
import Footer from "../components/Footer";
import BlogCard from "../UI/BlogCard";
import { blogsData } from "../data/BlogsData";

import BlogContactUs from "../components/blogs/BlogContactUs";

const blogType = [
  {
    id: "1",
    type:'car',
    title: "Car Insurance"
  },
  {
    id: "2",
    type:'bike',
    title: "Bike Insurance"
  },
  {
    id: "3",
    type:'health',
    title: "Health Insurance"
  },
  {
    id: "4",
    type:'term',
    title: "Term Insurance"
  }
];

const mobileBlogType = [
  // {
  //   id: "1",
  //   title: "All",
  //   type:'all'
  // },
  {
    id: "2",
    title: "Car",
    type:'car'
  },
  {
    id: "3",
    title: "Bike",
    type:'bike'
  },
  {
    id: "4",
    title: "Health",
    type:'health'
  },
  {
    id: "5",
    title: "Term",
    type:'term'
  }
];


  

const Blogs = () => {
  const [active, setActive] = useState(0);
  const [closeNav, setCloseNav] = useState(false)
  const [selectedBlogType, setSelectedBlogType] = useState('car')
  const typeBlog = sessionStorage.getItem('typeBlog')
  const checkobj = [
    {
    id:'1',
    title:'title',
    desc:'this is Desc'
  },
    {
    id:'1',
    title:'title',
    desc:'this is Desc'
  },
]

useEffect(()=>{
  sessionStorage.setItem('typeBlog',typeBlog=='null'?'car':typeBlog)

},[typeBlog])
useEffect(()=>{

setSelectedBlogType(typeBlog)
  

},[typeBlog])


  const convertToSlug = (text) => {
    return text.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'')
  }
  return (
    <>
      <NavHeader   dropClose={ [closeNav, setCloseNav] }
 />


      <main
        className="w-full  bg-no-repeat bg-cover pb-20  "
        style={{ backgroundImage: `url(${LandingBackground})` }}  onClick={()=>{
          setCloseNav(false)
        }}
      >
        {/* page Heading for Desktop view */}
        <div className="hidden lg:block">
          <SectionBck title="Insurance News & Articles" />
        </div>
        {/* Page Heading for Mobile View */}
        <h1 className="lg:hidden font-bold text-2xl pt-10 pb-8 text-center">
          News & Articles
        </h1>
        <div className="flex flex-col lg:flex-row lg:pt-16 lg:space-x-10 lg:px-44 ">
          <div className="">
            <div className="hidden lg:block pb-10 space-x-3">
              {/* Categories type for Desktop View */}
              {blogType.map((item, key) => (
                <button
                  className={`${
                    item.type === selectedBlogType
                      ? "bg-[#293651] text-white"
                      : "text-[#293651] bg-[#EEF4FF] border border-[#293651]"
                  }  font-semibold  px-5 py-1 rounded-full`}
                  onClick={(e) => {
                    e.preventDefault();
                    setActive(key);
                    setSelectedBlogType(item.type)
                    sessionStorage.setItem('typeBlog',item.type)

                  }}
                >
                  {item.title}
                </button>
              ))}
            </div>
            {/* category type for Mobile View */}
            <div className="lg:hidden w-full flex m-auto justify-center  pb-10 space-x-3">
              {mobileBlogType.map((item, key) => (
                <button
                  className={`${
                    item.type === selectedBlogType
                      ? "bg-[#293651] text-white"
                      : "text-[#293651] bg-[#EEF4FF] border border-[#293651]"
                  }  font-semibold    text-xs px-3 py-1 rounded-full`}
                  onClick={(e) => {
                    e.preventDefault();
                    setActive(key);
                    setSelectedBlogType(item.type)
                    sessionStorage.setItem('typeBlog',item.type)

                  }}
                >
                  {item.title}
                </button>
              ))}
            </div>

            {blogsData.filter((blog)=>{

              return  blog.category==selectedBlogType}).map((blogs,key)=>{
                let slug= [convertToSlug(blogs.title)]
                return (
                  <>
              <div className={`${key>0?'hi dden':' '}`} key={blogs.id}>
                  <Link to={`/article/${slug}`}>
                    {/* {blogs.title} */}
              <BlogCard blogData={[blogs]} />
              </Link>
              </div>
            
            </>
            )})
            }
        </div>
          <div className="px-5 lg:px-0 pt-10 lg:pt-0">
            <BlogContactUs />
          </div>
        </div>
      </main>
      {/* Global Footer */}
      <Footer />
    </>
  );
};

export default Blogs;
