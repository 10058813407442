import { IoMdHappy } from "react-icons/md";

const testimonials = [
  {
    id: "1",
    quote:
      "For my new car, I received a very competitive quote. They thoroughly explained the coverage and were able to obtain the same coverage at a lower cost than the dealer was offering for the same policy.",

    name: "Neelakantan",
    title: "Chennai",
    rating: "⭐⭐⭐⭐⭐"
  },
  {
    id: "2",
    quote:
      "I recently renewed my car insurance policy with them. When I told them about another provider's quote, they agreed to match the coverage benefits for a lower price and issued the policy before my expiry date.",
    name: "Balasubramaniam",
    title: "Bengaluru",
    rating: "⭐⭐⭐⭐⭐"
  },
  {
    id: "3",
    quote:
      "Renewing my bike insurance was a simple process. When others refused to raise the IDV, their team approached the insurer and got it done. If you have any questions or requests, they are willing to listen to your concerns and provide solutions on an individual basis. Thank you, team!",
    name: "Dharma",
    title: "Salem",
    rating: "⭐⭐⭐⭐⭐"
  },
  {
    id: "4",
    quote:
      "I spoke with their experts, who warned me about the dangers of relying on inaccurate quotes from another source. I talked it over with them and had the changes made so there would be no problems afterwards. They had assisted me in this process even though I hadn't bought the coverage through them.",
    name: "Abhishek ",
    title: "Bangalore",
    rating: "⭐⭐⭐⭐⭐"
  }
];
export default testimonials;
