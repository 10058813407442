import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// const currentLocation = window.location.pathname;

const tracker = [
  {
    step: 1,
    value: `Vehicle Owner Details`
  },
  {
    step: 2,
    value: "Personal Details",
    currentUrl: "/carinsurance/car-profile/step-2"
  },
  {
    step: 3,
    value: "Communication Address",
    currentUrl: "/carinsurance/car-profile/step-3"
  },
  {
    step: 4,
    value: "Vehicle Details",
    line: true,
    currentUrl: "/carinsurance/car-profile/step-4"
  }
];

const InsuranceTracker = () => {
  const [currentLocation, setCurrentLocation] = useState("");
  const [stepValue, setStepValue] = useState(1);
  const [actionType, setActionType] = useState();
  const location = useLocation();
  const  actiontype = sessionStorage.getItem('action_type')
useEffect(()=>{
  setActionType(actiontype)
},[actiontype])

const stepvalue = localStorage.getItem("step" );
  useEffect(() => {
    
    setStepValue(stepvalue);
  }, [actionType,stepvalue]);
  return (
    <div className=" w-full flex justify-center items-center  ">
      {tracker.map((item, key) => (
        <div className="">
          <div className="flex   items-center pb-4">
            <div
              className={`${
                key <= stepValue
                  ? "bg-hero text-white "
                  : "bg-white drop-shadow-xl text-hero"
              } w-12 h-12  lg:w-14 lg:h-14 rounded-full text-center flex justify-center lg:text-2xl font-bold items-center`}
            >
              {item.step}
            </div>
            <div
              className={`${item.line ? "hidden" : ""} ${
                key < stepValue ? "bg-hero" : "bg-[#D9D9D9]"
              } h-1 lg:h-1.5 w-10 lg:w-36 `}
            />
          </div>
          <h1 className=" absolute   w-14 lg:w-[7rem] text-[#545454] font-medium text-[10px] lg:text-sm text-center flex items-center ">
            {item.value}
          </h1>
        </div>
      ))}
    </div>
  );
};

export default InsuranceTracker;
