import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineUp, AiOutlineDown } from "react-icons/ai";
// const carOwnerDetails = JSON.parse(localStorage.getItem("step-1 Values"));

// const details = [
//   {
//     id: "1",
//     name: "Car Registered ?",
//     ans: carOwnerDetails.registered
//   },
//   {
//     id: "2",
//     name: "Car Owenr Name",
//     ans: carOwnerDetails.ownerName
//   },
//   {
//     id: "3",
//     name: "Email Address",
//     ans: carOwnerDetails.email
//   },
//   {
//     id: "4",
//     name: "Mobile Number",
//     ans: carOwnerDetails.mobile
//   }
// ];

const DetailsReviewCard = ({ step, link, title, cardData = "", isOpen }) => {
  const [boxOpen1, setBoxOpen1] = useState(false);
  const [boxOpen2, setBoxOpen2] = useState(false);
  const [boxOpen3, setBoxOpen3] = useState(false);
  const [boxOpen4, setBoxOpen4] = useState(false);
  return (
    <div className=" md:w-full xl:w-[700px] p-5 rounded-lg shadow-[0px_4px_36px_rgba(0,167,142,0.1)]">
      <div
        className="flex  items-center"
        onClick={() => {
          setBoxOpen1(!boxOpen1);
         
        }}
      >
        <div className="flex items-center space-x-4">
          <h2 className="w-6 h-6 text-center bg-hero text-white rounded-full  ">
            {step}
          </h2>
          <h1 className="font-semibold lg:text-lg">{title}</h1>
        </div>
        <div className="flex flex-1"></div>

        <Link to={link}>
          {" "}
          <button className="font-semibold text-white bg-hero w-16 py-1 rounded-md hidden lg:block ">
            Edit
          </button>
        </Link>
        <div className="lg:pl-5 text-[#989898]">
          {boxOpen1 ? <AiOutlineUp /> : <AiOutlineDown />}
        </div>
      </div>
      {(boxOpen1 || isOpen) && (
        <div className={`pt-5 grid lg:grid-cols-2  gap-x-10 gap-y-5`}>
          {cardData.map((item, key) => (
            <div className={`  space-y-2`}>
              <h1 className="font-semibold text-xs lg:text-base">
                {item.name}
              </h1>
              <h2 className="text-[#545454] capitalize text-[10px] lg:text-base ">
                {item.ans}
              </h2>
            </div>
          ))}
          <Link to={link}>
            {" "}
            <button className="font-semibold text-white bg-hero w-16 py-1 rounded-md lg:hidden  float-right ">
              Edit
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default DetailsReviewCard;
