import React, { useState, useEffect } from "react";
import Svg1 from "../../assets/Svg/contactUsBlog.svg";
import axios from "axios";
import InputPP from "../customs/InputPP";
import DropDownPP from "../customs/DropDownPP";

const BlogContactUs = () => {
  const [datas, setDatas] = useState([]);
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [jwtToken, setJwtToken] = useState(localStorage.getItem('jwt_token'))


  useEffect(() => {
    const onStorage = () => {
      setJwtToken(localStorage.getItem('jwt_token'));
    };
  
    window.addEventListener('storage', onStorage);
  
    return () => {
        window.removeEventListener('storage', onStorage);
    };
  }, []);

  //   useEffect(() => {
  //   fetch("https://chroniclingamerica.loc.gov/search/pages/results/?andtext=florid&format=json")
  //   .then(response => response.json())
  //   .then(data => {
  //     console.log(data);
  // // make sure to add the empty dependency array, or you will end
  // // up in an infinite loop
  // }), [datas])

 

  // useEffect(() => {
  //   fetch("https://www.staging-policymart.co.in/api/v1/blogs/2")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setDatas(data);
  //     });
  // }, []);

  // useEffect(() => {
  //   axios
  //     .get(`https://www.staging-policymart.co.in/api/v1/blogs/1`, {
  //       headers: {
  //         "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
  //       }
  //     })
  //     .then((data) => setDatas(data.data))
  //     .catch(
  //       (error) => console.log(error)
  //       // if (error.response) {
  //       //   console.log(error.response.headers);
  //       // } else if (error.request) {
  //       //   console.log(error.request);
  //       // } else {
  //       //   console.log(error.message);
  //       // }
  //       // console.log(error.config);
  //     );
  // }, []);
  // console.log(datas);

  // let bodyData = [
  //   {
  //     id: "1",
  //     title: "string",
  //     category: "string",
  //     // image: "string",
  //     subTitle: "string",
  //     author: "string",
  //     date: "2022-09-12T06:49:14.720Z",
  //     content: "string"
  //   }
  // ];

  // useEffect(() => {
  //   axios({
  //     method: "post",
  //     url: "http://www.staging-policymart.co.in/api/v1/contacts",
  //     data: bodyData,
  //     headers: {
  //       "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
  //       "Content-Type": "application/json"
  //     }
  //   })
  //     .then(function (response) {
  //       //handle success
  //       console.log(response);
  //     })
  //     .catch((error) => {
  //       console.log(error.response.data);
  //     });
  // }, []);

  // console.log(datas);

  const handleChange = (e) => {
    const name = e.target.name;

    const value = e.target.value;
    setInputs((values) => ({
      ...values,
      [name]: value
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      inputs.user_Name == "" ||
      inputs.user_Email == "" ||
      inputs.insurance_type == "" ||
      inputs.details == "" ||
      inputs.user_Name == undefined ||
      inputs.user_Email == undefined ||
      inputs.insurance_type == undefined ||
      inputs.details == undefined
    ) {
      setError(true);
    } else {
      setError(false);
      setSuccess(true);
    }
    // alert("error");

  Object.keys(inputs).length>=4&&  axios
      .post(
        "/contacts",
        {
          // id: 0,
          name: inputs.user_Name,
          email: inputs.user_Email,
          insurance_type:inputs.insurance_type,
          message:inputs.details
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${jwtToken}`,

            Accept: "application/json"
          }
        }
      )
      .then((res) =>{})
      .catch((error) => console.log(error));

    // Email Validation
    if (
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inputs.user_Email)
    ) {
      return true;
    }
    alert("You have entered an invalid email address!");
  };
  return (
    <div className="bg-hero px-3  py-4 rounded-xl w">
      <div className="flex items-center  space-x-7">
        <h1 className="text-white font-semibold leading-6">
          Find the Right <br /> Insurance & Save <br /> upto{" "}
          <span className="text-[#FECE4C]  font-extrabold">75%*</span>{" "}
        </h1>
        <img src={Svg1} alt="Svg1" />
      </div>
      <form onChange={handleChange}>
        <div className="bg-[rgba(0,0,0,0.2)] p-2 lg:w-max m-auto my-3 space-y-2">
          <InputPP
            brClass="hidden"
            labelClassName="hidden"
            placeholder="Your Name"
            className="p-2 w-full lg:w-72 rounded-lg "
            id="user_Name"
          />
          <InputPP
            brClass="hidden"
            labelClassName="hidden"
            placeholder="Your Email ID"
            className="p-2 w-full lg:w-72rounded-lg "
            id="user_Email"
          />
          <DropDownPP
            brClass="hidden"
            label="Insurance"
            labelClassName="hidden"
            placeholder="Your Name"
            id="insurance_type"
            options={[
              "Car Insurance",
              "Bike Insurance",
              "Health Insurance",
              "Term Insurance"
            ]}
            className="px-2 w-full lg:w-72 py-3 rounded-lg "
          />
          <textarea
            cols="30"
            rows="10"
            className="w-full lg:w-72 h-24 p-2 rounded-lg focus:outline-none"
            id='details'
            placeholder="Details"
            name="details"
          ></textarea>
          <br />
          <input
            type="submit"
            className="bg-[#FECE4C]  text-[#084F44] w-full lg:w-72 p-2 rounded-lg text-lg font-bold focus:outline-none cursor-pointer "
            onClick={handleSubmit}
            value="Get A Quote"
          />{" "}
        </div>
      </form>
      {error && (
        <>
          <h2 className="text-[#e11010f7] font-bold  text-center ">
            Please fill all the mandatory fields
          </h2>
        </>
      )}
      {success && (
        <h2 className="text-white pt-3 font-semibold text-center">
          ✅ Your form has been Submitted <br /> Successfully. our Support team
          will <br /> Contact you shortly{" "}
        </h2>
      )}
    </div>
  );
};

export default BlogContactUs;
