import React, { useState, useEffect } from "react";
import LandingBackground from "../../assets/Svg/LandingPageBck.svg";
import { policyPartnerData } from "../../data/PolicyPartnerData";
import PolicyPlanCard from "../../UI/PolicyPlanCard";
import QuoteDetailPop from "../../UI/QuoteDetailPop";
import Footer from "../Footer";
import NavHeader from "../NavHeader";
import QuotePolicyFilter from "../QuotePolicyFilter";
import ScrollToTop from "../ScrollToTop";
import VehicleDetails from "../VehicleDetails";
import { PolicyFilterCard } from "./quotation/FilterTypes/PolicyFilterCard1";
import axios from "axios";
import LoadingSpinner from "../paymentStatus/LoadingSpinner";
import QuoteLoading from "../../UI/QuoteLoading";
import QuoteDetailPopTemp from "../../UI/QuoteDetailPopTemp";
import { PolicyFilterCard4 } from "./quotation/FilterTypes/PolicyFilterCard4";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import CardSkelton from "../../UI/CardSkelton";
import ReactGA from "react-ga4";

import {useDispatch, useSelector } from "react-redux";
import { quoteActions } from "../../store/slices/quote-slice-rsa";

import { RelianceQuote1 } from "./quotation/relianceApis/Reliance1Quote";
import { RsaQuote1 } from "./quotation/rsaApis/RsaQuote1";
import { GodigitQuote1 } from "./quotation/godigitApis/GodigitQuote1";


const CarQuotation = () => {
  const [closePopUp, setclosePopUp] = useState(true);
  const [isDetails, setIsDetails] = useState(false);
  const [addonsPlace, setAddonsPlace] = useState({})
  const [addons, setAddons] = useState(false);
  const [addonsChecked, setAddonsChecked] = useState('')
  const [isThirdParty, setIsThirdParty] = useState(false)
  const [count, setCount] = useState(3);
const [defState, setDefState] = useState()
  const [quoteDetailsData, QuoteDetailsData] = useState();
  const [modelValue, setModelValue] = useState("");
  const [quoteLoading, setquoteLoading] = useState(false);
  const [apiData, setApiData] = useState({});
const [isApiLoaded, setIsApiLoaded] = useState(false)
const [load, setLoad] = useState(false)
  const [CarIsRegistered, setCarIsRegistered] = useState()
  const [initialPolicyType, setInitialPolicyType] = useState({})
const [jwtToken, setJwtToken] = useState(localStorage.getItem('jwt_token'))
const [uniApiData, setUniApiData] = useState(JSON.parse(localStorage.getItem('apiData')))
const [api, setApi] = useState({})
const [tokenLoaded, setTokenLoaded] = useState(false)
  const [updateIdvValue, setUpdateIdvValue] = useState("0");
const [loader, setLoader] = useState()
const [loader1, setLoader1] = useState()
const [loadApiData, setLoadApiData] = useState({})
const [loadApiData1, setLoadApiData1] =useState({})
const [apiLoads, setApiLoads] = useState()
  const [carInfo, setCarInfo] = useState({});
const [initialLoad, setInitialLoad] = useState(true)
const [masterID, setMasterID] = useState({})
const [modelRegionId, setModelRegionId] = useState({})
const [rtoRegionCode, setRtoRegionCode] = useState('')
const [manufactureYear, setManufactureYear] = useState()
const [oldPolicyType, setOldPolicyType] = useState()
const [registeredModelValues, setRegisteredModelValues] = useState({})
const [prevClaimValue, setPrevClaimValue] = useState()
const [closeNav, setCloseNav] = useState(false)
const [firstLoader, setFirstLoader] = useState(true)
const [tileType, setTileType] = useState();
const [carRtoCodeRsa, setCarRtoCodeRsa] = useState()
const [ownership, setOwnership] = useState()
const [selectedNCB, setSelectedNCB] = useState(0);
const [modelName, setModelName] = useState('')
const [cpaValue, setCpaValue] = useState('1')
const [filterCardData, setFilterCardData] = useState( [
  // {
  //   id: "1",
  //   label: "PA Owner Drive   ",
  //   name: "paOwnerDriver"
  // },
  {
    id: "1",
    label: "Zero Depreciation ",
    check:false,
    name: "zeroDepreciation"
  },
  {
    id: "2",
    label: "Road Side Assistance ",
    check:false,
    name: "roadSideAssistance"
  },
  {
    id: "3",
    label: "Consumables",
    check:false,
    name: "consumables"
  },
  {
    id: "4",
    label: "Key loss",
    check:false,
    name: "keyLoss"
  },
  {
    id: "5",
    label: "Return to Invoice ",
    check:false,
    name: "returntoInvoice"
  },
  {
    id: "6",
    label: "Engine Protect",
    check:false,
    name: "engineProtect",
    
  },
  {
    id: "7",
    label: "NCB protection",
    check:false,
    name: "ncbProtection"
  },
  {
    id: "8",
    label: "Tyre Protection",
    check:false,
    name: "tyreProtection"
  },
 
  {
    id: "9",
    label: "Loss of personal belongings ",
    check:false,
    name: "lossOfPersonalBelongings"
  },
  {
    id: "10",
    label: "Electrical accessories ",
    name: "electricalaccessories",
    check:false,
    isInput1:true
  },
  {
    id: "11",
    label: "Non-electrical accessories",
    name: "nonElectricalaccessories",
    check:false,
    isInput2:true

  },
  {
    id: "12",
    label: "External Bi-Fuel kit",
    name: "externalBiFuelkit",
    check:false,
    isInput3:true

  },
  {
    id: "13",
    label: "Paid Driver cover",
    name: "paidDrivercover",
    check:false,
    checkalt:true,
    checkText:'Sum insured  : 200000',
    checkClass:'right-6  -top-12 lg:-top-10'
  },
  
  {
    id: "14",
    label: "Owner Driver PA cover",
    name: "ownerDriverPAcover",
    check:true,
    isCus:true,
    checkText:'Compulsory as per Govt',
    checkClass : '-right-5 -top-12 lg:-top-10',
    isInput4:true

  },
  {
    id: "15",
    label: "Unnamed passenger PA cover",
    name: "unnamedpassengerPAcover",
    check:false,
    checkalt:true,
    checkText:'Sum insured : 100000',
    checkClass:'-right-4  -top-12 lg:-top-10'

  },
]);
const [inputStatus, setInputStatus] = useState(false)

const [godigitLoad, setGodigitLoad] = useState(false)
const [rsaLoad, setRsaLoad] = useState(false)
const [relianceLoad, setRelianceLoad] = useState(false)
const [initialCall, setInitialCall] = useState(sessionStorage.getItem("first_call"))
const { fetchQuoteReliance ,callbackfun} = RelianceQuote1();
const {fetchQuoteRsa} = RsaQuote1()
const {fetchQuoteGodigit}  = GodigitQuote1()

const [preCustomerDetails, setPreCustomerDetails] = useState()

const dispatch = useDispatch()


  // setUpdateIdvValue(updateIdvValue.coverData);
  // const filterData = localStorage.getItem("popupsData");
  const carRegistered = localStorage.getItem("isCarRegistered");
  const prevPolicyType = localStorage.getItem('car_Info')
  const apiload = localStorage.getItem('api_Load')
  const universalApiData = localStorage.getItem('apiData')
  const masteridvalues= localStorage.getItem('master_ID')
  const modelRegion = localStorage.getItem('regionCode')
  const carRegNO = localStorage.getItem('car_Intro')
  const preCustDetails= localStorage.getItem('pre_CustomerDetials')
  const radioValue = localStorage.getItem('car_Info')

  let registeredModelValue= localStorage.getItem('registered_model')
  let registeredModelClaimValue= localStorage.getItem('registered_model_claim')
  const tileValue = localStorage.getItem("tile_Type_Detail");
const carRtoRsa = localStorage.getItem('car_Info_rto_value')
let registeredModelOwnershipValue= localStorage.getItem('registered_model_ownership')
const policyplaceholder = sessionStorage.getItem('inputs_policyType')
const inputActive = sessionStorage.getItem("inputSet")
const cpatenure = sessionStorage.getItem('check_inputs')

const firstCall = sessionStorage.getItem("first_call")
  // localStorage.setItem('inputs_discount','')

  
/* -------------------  API ENDPOINT -------------- */
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
/* ---------------------------------- -------------- */


  useEffect(()=>{

    setApiLoads(apiload)
    setCarRtoCodeRsa(carRtoRsa)
    // setPopUpsFilterData(JSON.parse(filterData))
    setCarIsRegistered (JSON.parse(carRegistered))
    setInitialPolicyType(JSON.parse(prevPolicyType))
    // setLoadApiData1(JSON.parse(universalApiData))
setMasterID(JSON.parse(masteridvalues))
setModelRegionId(JSON.parse(modelRegion))
setManufactureYear(JSON.parse(radioValue).manufactureYear)
setOldPolicyType(JSON.parse(radioValue).policy)
setModelName(JSON.parse(radioValue).makeAndmodel)
setRegisteredModelValues(JSON.parse(registeredModelValue))   
setPrevClaimValue(registeredModelClaimValue)
setTileType(tileValue);
setPreCustomerDetails(JSON.parse(preCustDetails))
setOwnership(registeredModelOwnershipValue)
setIsThirdParty( (policyplaceholder))
setInputStatus(inputActive)


  },[tileValue,carRtoRsa,inputActive,policyplaceholder,carRegNO,registeredModelOwnershipValue,preCustDetails,registeredModelClaimValue,radioValue,registeredModelValue,apiload,modelRegion,masteridvalues,carRegistered,universalApiData, prevPolicyType,])
  
  
  ReactGA.initialize("G-3CEV86FDMQ");

  // for Google Analytics 
  
  useEffect(()=>{
    //non interaction Event
  ReactGA.send ({
    hitType: "Car Quotation Page",
    page: window.location.pathname,
    title: "Main Home Page"
  })
     
  },[])

  /* ---------------------------- Redux Setup ------------------- */


  let isQuoteRsaLoader = useSelector(state=>state.quoteRsa.loader)
  let rsaApiData = useSelector(state=>state.quoteRsa.apiData)
  let isFirstApiCallRsa = useSelector(state=>state.quoteRsa.firstLoader)

  let isQuoteGodigitLoader = useSelector(state=>state.quoteGodigit.loader)
  let godigitApiData = useSelector(state=>state.quoteGodigit.apiData)
  let isFirstApiCallGodigit = useSelector(state=>state.quoteGodigit.firstLoader)

  let isQuoteRelianceLoader = useSelector(state=>state.quoteReliance.loader)
  let relianceApiData = useSelector(state=>state.quoteReliance.apiData)
  let isFirstApiCallReliance = useSelector(state=>state.quoteReliance.firstLoader)

  /* --------------------------------------------------------------- */
 
  useEffect(()=>{
  inputStatus&&isFirstApiCallRsa&& fetchQuoteRsa()
  inputStatus&&  isFirstApiCallGodigit&&fetchQuoteGodigit()
},[inputStatus,isFirstApiCallGodigit,isFirstApiCallReliance,isFirstApiCallRsa])
// Use useEffect to start interval when component mounts
console.log('inputstatus',inputStatus)
useEffect(()=>{
    inputStatus=='true'&& isFirstApiCallReliance&& fetchQuoteReliance()

  },[fetchQuoteReliance, inputStatus, isFirstApiCallReliance])
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      // Check if inputs are valid
      if ( inputStatus!=='true') {
        callbackfun() // Call handleSubmit if inputs are valid
      }
    }, 1000); // Interval set to 5 seconds (adjust as needed)

    // Cleanup function to clear interval when component unmounts
    return () => clearInterval(intervalId);
  }, [callbackfun, inputStatus]); // Run effect whenever inputsValid changes

  const sessionData = sessionStorage.getItem('input_addons_check');
 

  useEffect(()=>{
    // if(Object.keys(defSessionQuoteVal).length>0)  {
  
    //   dispatch(quoteActions.setApiDataValues(defSessionQuoteVal.rsaQuoteResponse ))
   
    // } 
  
      
      sessionStorage.setItem('sessQuoApiVal', JSON.stringify({
        relianceQuoteResponse:{},
        goDigitQuoteResponse:{},
        rsaQuoteResponse:{},
        
        
      }))
   
  },[])

  useEffect(() => {
 
      if (sessionData) {
        const sessionValues = JSON.parse(sessionData);
        const updatedData = filterCardData.map(item => {
          if (sessionValues[item.name]) {
            return { ...item, check: true };
          }
          return item;
        });
        setFilterCardData(updatedData);
      }
    
 
  }, [sessionData]); 


/* ------------ NCB values addup ------------- */

useEffect(()=>{

  const availableNCBValues = [0, 20, 25, 35, 45, 50];
  const selectedValue = parseInt(registeredModelValues&&registeredModelValues.prevNCB, 10);
    if ( selectedValue< 50) {
      // Automatically select the next higher value
      setSelectedNCB(availableNCBValues[availableNCBValues.indexOf(selectedValue) + 1]);
    sessionStorage.setItem('currentNcb',availableNCBValues[availableNCBValues.indexOf(selectedValue) + 1])

    } else {
      // Keep the value as is if it's already the highest
      setSelectedNCB(50);
      sessionStorage.setItem('currentNcb',50)

    }

},[  registeredModelValues])

/* ------------------------------------- */


useEffect(()=>{
  if(isDetails){

    document.body.style.overflow = "hidden";
  }  else {
    document.body.style.overflow = "visible";
  }
 


},[isDetails, loadApiData])
useEffect(()=>{

  if(!isQuoteGodigitLoader){
    setGodigitLoad(true)
    setCount(count<1?0:count-1)
  }
  else {
    setGodigitLoad(false)
  }
  if( !isQuoteRsaLoader){
    setCount(count<1?0:count-1)
    setRsaLoad(true)

  }
  else {
    setRsaLoad(false)
  }
  if( !isQuoteRelianceLoader){
    setRelianceLoad(true)
    setCount(count<1?0:count-1)

  }
  else {
    setRelianceLoad(false)
  }

},[count, isQuoteGodigitLoader, isQuoteRelianceLoader, isQuoteRsaLoader])

  
useEffect(()=>{
  if(Object.values(masterID).length>0){
    setLoad(true)
  }
},[masterID])


useEffect(() => {
  const onStorage = () => {
    setUniApiData(JSON.parse(localStorage.getItem('apiData')))
    setIsApiLoaded(localStorage.getItem('apiLoaded'))
  };

  window.addEventListener('storage', onStorage);

  return () => {
      window.removeEventListener('storage', onStorage);
  };
}, []);

useEffect(() => {
  const onStorage = () => {
   setJwtToken(localStorage.getItem('jwt_token'))
  };

  window.addEventListener('storage', onStorage);

  return () => {
      window.removeEventListener('storage', onStorage);
  };
}, []);


/* -------------------------------- for OwnerPaDriver Logic ------------------- */




useEffect(()=>{

  
  /* ------------ For Getting Prev Insurer Names ---------------- */
  axios.get(`${API_ENDPOINT}/all/insurance/name`, {
      headers: {
        Authorization: `${jwtToken}`,
        'Content-Type': 'application/json',
      },
  },).then((response)=>{

    const values = []
    response.data.map((item)=>(
      values.push(item.standardPreviousInsurerName)
    ))
    // setPrevInsurerData(values)
    localStorage.setItem('prev_policy_names',JSON.stringify(values))
    // response.data.standardPreviousInsurerName.map((item)=>(
      // setLoadAgain(true)
    //   (item)
    // ))
    })
    .catch((err)=>{
      console.log(err)
    })


  },[])

  useEffect(() => {
    const carDetails = localStorage.getItem("car_Info");
    const model = localStorage.getItem("car_Info_make_model");

    setCarInfo(JSON.parse(carDetails));
    setModelValue(model);
  }, []);

 
useEffect(()=>{

  // sessionStorage.removeItem('inputs_policyType')
  // sessionStorage.removeItem('check_inputs')
  // sessionStorage.removeItem('input_addons_check')
  // sessionStorage.removeItem('inputs_IDV')
  // sessionStorage.removeItem('inputs_discount')
  // localStorage.setItem("popupsData",[])
},[])

   useEffect(() => {
    localStorage.setItem(
      "addonsPop",
      JSON.stringify({})
    );
    //  const updatedIDVValue = localStorage.getItem("popupsData");
    

  }, [carRegistered,CarIsRegistered,isApiLoaded,initialPolicyType.policy,jwtToken,tokenLoaded]);
 


 console.log('rsa Quote',isQuoteGodigitLoader)
 

  return (
    <>
      <NavHeader dropClose={ [closeNav, setCloseNav] } />
      {isDetails && (
        <> 
        <div
          className={` absolute bg-[rgba(119,117,117,0.43)] w-full h-screen  left z-20 overflow-hidden`} onClick={()=>{
            setIsDetails(!isDetails)
          }}
        >
          </div>
          <div
            className=" lg:absolute flex flex-col w-full lg:h-full justify-center  overflow-x-hidden items-center"
            onClick={() => {
              setclosePopUp(!closePopUp);
            }}
          >
            <ScrollToTop>
              <QuoteDetailPopTemp
                detailsData={uniApiData}
                selectedNcb = {selectedNCB}
                detailsPop={[isDetails, setIsDetails]}
              />
              {/* <QuoteDetailPop
                detailsData={uniApiData}
                detailsPop={[isDetails, setIsDetails]}
              /> */}
            </ScrollToTop>
          </div>
      </>
      )}
      <main
        className={`lg:w-full px-5 lg:px- 32 pt- bg-no-repeat bg-cover lg:overflow-x-hidden ${isDetails?'hidden lg:block  ':''} `}
        style={{ backgroundImage: `url(${LandingBackground})` }}  onClick={()=>{
          setCloseNav(false)
        }}
      >
        {/* Vehicle Details */}
        <div className="lg:flex gap-4">
        <div className="">

        <div className="pt-5   ">
          <VehicleDetails
            details={[modelValue, carInfo.manufactureYear, carInfo.fuelType]}
            editURL="/carinsurance/car-profile/prestep-2"
          />
        </div>
         
        {/* Policy Filter */}
        <div className="py-4 lg:h-screen ">
          <QuotePolicyFilter
            quoteLoaded={quoteLoading}
            // quoteData={apiData}
            isPopOpen={[closePopUp, setclosePopUp]}
loading={[loader, setLoader]}
       loadingData={[loadApiData, setLoadApiData]}
            detailsPop={[isDetails, setIsDetails]}
            objPolicy={policyPartnerData}
            redirectUrl="/reliancekyc"
            quoteData={uniApiData}
            dataLoading={quoteLoading}
            quoteDetailDatas={[quoteDetailsData, QuoteDetailsData]}
          />
        </div>
        </div>

        <div className=" ">
          {/* <h2 className="text-sm lg:text-lg font-semibold">
            {policyPartnerData.length} Plans
          </h2> */}
          {/* <div className="flex text-xs  space-x-1">
            <h2 className="text-[#808080] ">found for Order Id : </h2>
            <p className="text-hero font-semibold"> MGPN3UFXF28</p>
          </div> */}
        </div>
        <hr className="text-[#CECECE] my3" />



      {/* ----------------- tile for Initial Quote Request ------------ */}
    <div className="md:w-[51%] md:m-auto lg:m-0">
{/* 
        <div className={` ${loader==true||false?'hidden':''} ${initialLoad?'':'hidden'} ${loader1?'hidden':''}    pt-5 pb-8`}>
        <PolicyPlanCard
            detailsPop={[isDetails, setIsDetails]}
            objPolicy={policyPartnerData}
            redirectUrl='/kyc'
            dataLoading={ [loader1, setLoader1]}
            quoteData={[apiData, setApiData]}
            quoteDetailData={[quoteDetailsData, QuoteDetailsData]}
          />
        </div>
        <div className={`${initialLoad ?'  ':'hidden'} ${loader1?'':'hidden'} pt-5 pb-8`}>
       <div className='flex flex-col gap-y-5'> 
      
      {Array(1).fill(1).map((_,i)=>(
        <div className="">

      
        <CardSkelton />
        </div>
      ))}
     
        </div>
        </div> */}

        <div className={`  ${loader==true||false?'hidd en':''} ${initialLoad?'':'hidd en'} ${loader1?'hid den':''}    pt-5 pb-5`}>
        <PolicyPlanCard
            detailsPop={[isDetails, setIsDetails]}
            objPolicy={policyPartnerData}
            redirectUrl='/kyc'
            dataLoading={ [loader1, setLoader1]}
            quoteData={[apiData, setApiData]}
            quoteDetailData={[quoteDetailsData, QuoteDetailsData]}
          />
        </div>
        <div className={`pt-1   space-y-5`}>
        <div className={`${isQuoteRsaLoader?'':'hidden'}`}>

      
        <CardSkelton />
        </div>
        <div className={`${isQuoteGodigitLoader?'':'hidden'}`}>

      
        <CardSkelton />
        </div>
        <div className={`${isQuoteRelianceLoader?'':'hidden'}`}>

      
        <CardSkelton />
        </div>
      </div>
     

        

        {/* ----------------- End tile Here -------------------------- */}

        {/* ------------- tile for Filter type quote request ----------------- */}
        {/* <div className={` ${loader?'hidden':''}  ${initialLoad?'hidden':''}    pt-5 pb-8`}>
        <PolicyPlanCard
            detailsPop={[isDetails, setIsDetails]}
            objPolicy={policyPartnerData}
            redirectUrl='/kyc'
            dataLoading={ [loader, setLoader]}
            quoteData={[loadApiData, setLoadApiData]}
            quoteDetailData={[quoteDetailsData, QuoteDetailsData]}
          />
        </div>
        <div className={`${loader?'':'hidden'} ${firstLoader?'hidden':''}   pt-5 pb-8`}>
       
        <div className='flex flex-col gap-y-5'> 
      
      {Array(count).fill(1).map((_,i)=>(
        <div className="">

      
        <CardSkelton />
        </div>
      ))}
     
        </div>
        </div> */}
        </div>


<div className="">
 
                


        <PolicyFilterCard4
                  parentClass=" w-80 lg:w-[19%]  lg:mt-5 lg:h-[82%] desktop-view overflow-x-hidden overflow-y-auto   hidden lg:block  lg:absolute lg:right-5 lg:left-[-1 23px]  "
                // mission={[missionAwake, setMissionAwake]}
                loading={[loader, setLoader]}
                loadingData={[loadApiData, setLoadApiData]}
                placeValue={[addonsPlace, setAddonsPlace]}
                  closeState={[addons, setAddons]}
                  addsData={[addonsChecked, setAddonsChecked]}
                  checkedAddonCount={[defState, setDefState] }
                 filterCardData={ [


                    {
                      id: "1",
                      title: "Add-Ons Cover",
                      type: "checkbox",
                      buttonName: ["Reset", "Apply"],
                      
                      odAddons: (isThirdParty==(null||undefined)?oldPolicyType=='ownDamage':isThirdParty=='ownDamage')?true:false,
                      checkBoxData: filterCardData
                    }
                  ]  
                
                }
                />
              </div>

        </div>

        {/* ----------------------- End of tile here -------------- */}
        
      </main>
      {/* Global Footer */}
<div className={`${isDetails?'hidden lg:block':''} lg:mt-40`}>

      <Footer />
</div>
    </>
  );
};

export default CarQuotation;
