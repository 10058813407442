// LoadingPopUp.js
import React from 'react';

const WaitLoadPayment = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center overflow-hidden justify-center bg-[rgba(0,0,0,0.27)] z- 50 animate-fade-in">
    <div className="bg-white p-8 rounded shadow-lg animate-scale-in">
    <p className="text-lg font-semibold mb-4 animate-pulse">Processing Payment</p>
        
      <div className="animate-spin rounded-full m-auto h-8 w-8 border-t-2 border-b-2 border-blue-500 border-opacity-75"></div>
    </div>
  </div>

  );
};

export default WaitLoadPayment;
