import React,{useState,useEffect,useRef} from "react";
import Partner1 from "../../assets/Partners/Partner1.svg";
import Partner2 from "../../assets/Partners/partner2.svg";
import Partner3 from "../../assets/Partners/partner3.svg";
import Partner4 from "../../assets/Partners/partner4.svg";
import Partner5 from "../../assets/Partners/partner5.svg";
import Partner6 from "../../assets/Partners/Partner6.svg";
import Partner7 from "../../assets/Partners/Partner7.svg";
import Partner8 from "../../assets/Partners/Partner8.svg";
import Partner9 from "../../assets/Partners/Partner9.svg";
import Partner10 from "../../assets/Partners/Partner10.svg";
import Partner11 from "../../assets/Partners/Partner11.svg";
import Partner12 from "../../assets/Partners/Partner12.svg";
import Partner13 from "../../assets/Partners/Partner13.svg";
import Partner14 from "../../assets/Partners/Partner14.svg";
import Partner15 from "../../assets/Partners/Partner16.svg";
import Partner16 from "../../assets/Partners/Partner15.png";
import Partner17 from "../../assets/Partners/Partner17.png";
import Partner18 from "../../assets/Partners/Partner18.png";
import Partner19 from "../../assets/Partners/Partner19.png";
import Partner20 from "../../assets/Partners/Partner20.jpg";
import Partner21 from "../../assets/Partners/Partner21.png";
import Partner22 from "../../assets/Partners/Partner22.png";
import Partner23 from "../../assets/Partners/Partner23.png";
import Partner24 from "../../assets/Partners/Partner24.svg";
import Partner25 from "../../assets/Partners/Partner25.jpeg";
import Partner26 from "../../assets/Partners/Partner26.jpeg";
import Partner27 from "../../assets/Partners/Partner27.svg";
import { VscCircleLargeFilled } from "react-icons/vsc";
import Carousel, { consts } from "react-elastic-carousel";

const imgUrl = [
  Partner1,
  Partner2,
  Partner3,
  Partner4,
  Partner21,
  Partner22,
  Partner23,
  Partner24,
  Partner25,
  Partner5,
  Partner6,
  Partner7,
  Partner8,
  Partner9,
  Partner10,
  Partner11,
  Partner12,
  Partner13,
  Partner14,
  Partner15,
  Partner16,
  Partner17,
  Partner18,
  Partner19,
  Partner20,
  Partner26,
  Partner27,
];

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 1 },
  { width: 768, itemsToShow: 0 },
  { width: 1000, itemsToShow: 5 }
];

const Partners = () => {

  const cardRef = useRef(null);
  const [showCard, setShowCard] = useState(false);

  useEffect(() => {
    const aboutObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setShowCard(true);
          aboutObserver.unobserve(entry.target);
        }
      });
    }, {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    });

    if (cardRef.current) {
      aboutObserver.observe(cardRef.current);
    }

    

    return () => {
      aboutObserver.disconnect();
    };
  }, []);
  return (
    <div ref={cardRef}>
      <h1 className="text-[#1f243c] font-bold text-xl lg:text-3xl text-center pb-5">
        Our Partners
      </h1>
      {/* 
      <Carousel
        breakPoints={breakPoints}
        className={` p-10 grid grid-cols-5  `}
        // renderArrow={myArrow}
        // pagination={false}
        showArrows={false}
        renderPagination={({ pages, activePage, onClick }) => {
          return (
            <div className="flex space-x-1">
              {pages.map((page) => {
                const isActivePage = activePage === page;
                return (
                  <VscCircleLargeFilled
                    key={page}
                    onClick={() => onClick(page)}
                    active={isActivePage}
                    className={` ${
                      isActivePage ? "text-hero" : "text-[#00a78e72]"
                    } text-`}
                  />
                );
              })}
            </div>
          );
        }}
      > */}
      <div className={`grid  grid-cols-3 gap-y-16 gap-x-3 lg:gap-0 lg:grid-cols-6 justify-center items-center m-auto`}>
        {imgUrl.map((item, key) => (
          <div key={key} className={`pb-5 ${showCard?'popup':'cls'}  `}  >
            <img src={item} alt={item} className={`${key+221==17?'w- full mr-2':' lg:w-[147px] '} lg:h- [147px] filter grayscale`} />
          </div>
        ))}
      </div>
      {/* </Carousel> */}
    </div>
  );
};

export default Partners;
