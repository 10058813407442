import React,{useState} from 'react'
import NotfoundImg from '../assets/Vector/400Error.svg'
import NavHeader from '../components/NavHeader'
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  const [closeNav, setCloseNav] = useState(false)
  return (
    <>
<NavHeader   dropClose={ [closeNav, setCloseNav] }
 />
    <div className="flex flex-col justify-center items-center h-screen px-6   " onClick={()=>{
          setCloseNav(false)
        }}>
 
<img src={NotfoundImg} alt="NotfoundImg" className='lg:w-[28%] relative' />
<Link to='/'>
<button className='bg-hero text-white w-28 py-1.5 mt-5 rounded-lg font-semibold'>Home</button>
</Link>


{/* <div className="text-center">

<h2 className="font-semibold text-base lg:text-lg  py-2 lg:py">The page you're looking for doesn't exist</h2>
<p className="lg:text-base text-center text-xs   text-[#3b3f3b]">Don't panic just click the Home button to return home!</p>
</div> */}
    </div>
    </>
  )
}

export default NotFoundPage