import React, { useState, useEffect } from "react";
import { AiOutlineDown, AiOutlineClose } from "react-icons/ai";
import { BsFillInfoCircleFill, BsNutFill } from "react-icons/bs";
import axios from "axios";

import { RsaDiscountQuote } from "../rsaApis/RsaDiscountQuote";
import { RelianceDiscountQuote } from "../relianceApis/RelianceDiscountQuote";
import { GodigitDiscountQuote } from "../godigitApis/GodigitDiscountQuote";
export const PolicyFilterCard3 = ({
  filterCardData,
  closeState = "",
  parentClass = "",
  discData = "",
  addsData = "",
  covData = "",
  custCovdata = "",
  mission,
  placeValue,
  loading,loadingData
}) => {
    const [closePopUp, setClosePopUp] = closeState;
    const [discountData, setDiscountData] = discData;

    const [isCoupenSelected, setIsCoupenSelected] = useState(false);
    const [inputs, setInputs] = useState();
    const [isDisActive, setIsDisActive] = useState();
const [changeAction, setChangeAction] = useState(false)
  const [oldPolicyType, setOldPolicyType] = useState()
  const [noChange, setNoChange] = useState(false)
  const [finalPolicyType, setFinalPolicyType] = useState()
  const [tppdChange, setTppdChange] = useState(true)

  /* -------- Redux States  --------- */

  const {fetchDiscountQuoteRsa,updateDiscountValueRsa} = RsaDiscountQuote()
  const {fetchDiscountQuoteReliance,updateDiscountTypeValueReliance} = RelianceDiscountQuote()
    const {fetchDiscountQuoteGodigit, updateDiscountTypeValueGodigit }      =GodigitDiscountQuote()

    useEffect(() => {
      closePopUp&&localStorage.setItem("addon_check_data", JSON.stringify(inputs));    
    }, [inputs,closePopUp]);
  
  
    let registeredModelValue= localStorage.getItem('registered_model')

    const radioValue = localStorage.getItem('car_Info')

    const finalpolicytype = sessionStorage.getItem('inputs_policyType')

  const requestSta = localStorage.getItem('apiLoaded')

    useEffect(()=>{
      setNoChange(requestSta)
  setFinalPolicyType(finalpolicytype)
  setOldPolicyType(JSON.parse(radioValue).policy)

  },[finalpolicytype, radioValue, registeredModelValue, requestSta])


    useEffect(()=>{
      sessionStorage.setItem('tppdVal',true)
    },[])


  const handleFilterData = (e)=>{
    e.preventDefault()
    changeAction&&localStorage.setItem('apiLoaded',false)
    
    Object.values(inputs).length > 0 &&fetchDiscountQuoteRsa()
    Object.values(inputs).length > 0 &&fetchDiscountQuoteReliance()
    Object.values(inputs).length > 0 &&fetchDiscountQuoteGodigit()

    setClosePopUp(false);
    if(((inputs==(''||undefined||null))==false || true)){
     
      sessionStorage.setItem('inputs_discount',inputs)
    }


  }


  return (
    <>
      {filterCardData.map((items, key) => (
        <>
          <div
            className={` ${parentClass} bg-white z-0  p-5 shadow-[2px_2px_50px_rgba(0,167,142,0.15)] rounded-lg`}
          >
            <div className="flex items-center pb-5">
              <h1 className="font-semibold text-lg ">{items.title}</h1>
              <div className="flex flex-1"></div>
              <AiOutlineClose
                className="bg-[rgba(0,167,142,0.1)] text-[#989898] w-8 h-8 p-2 rounded-full"
                onClick={() => {
                  setClosePopUp(false);
                }}
              />
            </div>
            <div className={``}>
             
              {items.type === "select" && (
                <div className="" name="discount">
                  <form onChange={(e)=>{
                  }}>
                    <div className={`${(finalPolicyType==undefined||null)?oldPolicyType=="thirdParty":finalPolicyType=="thirdParty"?'hidden':''}`}>

                    <label className="text-[10px] text-[#726b6b]" htmlFor="">
                      {items.inputLabel}
                    </label>

                    <div className="grid grid-cols-3 py-5" name={items.name}>
                      {items.selectData.map((item, key) => (
                        <>
                          <form >
  <div class="discount-container">

                          <div
                            className={`${
                              key == isDisActive || item==discountData
                                ? "bg-hero text-white"
                                : "text-[#726b6b]"
                            } border border-[#D9D9D9] w-14 py-2 mb-2 discount-button text-center rounded-md`}
                            name="discount"
                            onClick={(e) => {
                             setDiscountData(e.target.id);
                             updateDiscountValueRsa(e.target.id)
                             updateDiscountTypeValueReliance(e.target.id)
                             updateDiscountTypeValueGodigit(e.target.id)
                             setInputs(e.target.id)
                             setChangeAction(true)
                              setIsDisActive(key);
                              setIsCoupenSelected(!isCoupenSelected);
                            }}
                            id={item}
                          >
                            <h2
                              className="text-xs "
                              itemID={item}
                              id={item}
                              name={item}

                            >
                              ₹ {item}
                            </h2>
                          </div>
                          </div>
                          </form>
                        </>
                      ))}
                    </div>

                    </div>
                            <hr className="text-[#98989c99] py-2" />
                            <div className={`${((finalPolicyType==undefined||null)?oldPolicyType=="ownDamage":finalPolicyType=="ownDamage")?'hidden':''}`}>

                            <h2 className="font-semibold pb-3">
                              TPPD 
                            </h2>
                    <div className="flex gap-1 items-center">
                    <div className="custom-checkbox  ">
    <input type="checkbox"
    name="tppd" id="tppd"
    defaultChecked={true}
    onChange={(e)=>{
      setTppdChange(e.target.checked)
      sessionStorage.setItem('tppdVal',e.target.checked)
    }}

                          />
      
  <label htmlFor="tppd" 
                          className="text-[#3f3838]  text-sm lg:text-base "
                        >
                          
                        </label>
                        </div>
                      <label htmlFor="tppd" className="text-sm ml-2">

                      Tppd Discount ? 
                      </label>
                      </div>
                      
                    </div>
                    <button
                      className={`${noChange==='true'?'':'opacity-[0.7]'} bg-hero text-white px-4 py-2 ml-auto  flex rounded-xl  mt-16 lg:mt-0`}
                      onClick={handleFilterData}
                      disabled={noChange==='true'?false:true} 
                    >
                      {items.buttonName}
                    </button>
                  </form>
                </div>
              )}
            </div>

           
          </div>
        </>
      ))}
    </>
  );
};
