import React from "react";

const InsuranceCard = ({ ImgUrl, InsName }) => {
  return (
    <div className="w-36 h-36 bg-white shadow-lg drop-shadow-sm hover:drop-shadow-2xl rounded-xl shadow-[#00a78e1f]">
      <img src={ImgUrl} alt="ImgUrl" className="m-auto" />
      <h2 className="text-[#293651] text-center font-semibold">{InsName}</h2>
    </div>
  );
};

export default InsuranceCard;
