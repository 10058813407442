import React, { useState, useEffect } from "react";
import { AiOutlineDown, AiOutlineClose } from "react-icons/ai";
import { BsFillInfoCircleFill } from "react-icons/bs";
import axios from "axios";


export const PolicyFilterCard2 = ({
  filterCardData,
  closeState = "",
  parentClass = "",
  discData = "",
  addsData = "",
  covData = "",
  errorMessages2,
  custCovdata = "",
  mission, 
   minIdv,
  maxIdv,
  placeValue,
  loading,loadingData
}) => {
    const [closePopUp, setClosePopUp] = closeState;
    const [isCoupenSelected, setIsCoupenSelected] = useState(false);
    const [inputs, setInputs] = useState({});
    const [inputAddon, setInputAddon] = useState({})
    const [isDisActive, setIsDisActive] = useState();
    // const [jwtToken, setJwtToken] = useState('')
    const [bikeRegistrationNumber, setBikeRegistrationNumber] = useState('')
    const [vehicleNumber, setVehicleNumber] = useState('')
    const [rtoRegionCode, setRtoRegionCode] = useState('')
  const [oldPolicyType, setOldPolicyType] = useState()
  const [noChange, setNoChange] = useState(false)

  const [placeHolderValue, setPlaceHolderValue] = placeValue
     const [defaultRadioValue, setDefaultRadioValue] = useState()
    const [coverData, setCoverData] = covData;
    const [discountData, setDiscountData] = discData;
    const [cstCvdata, setCstCvdata] = custCovdata;
    const [addonsData, setAddonsData] = useState({});
    const [isInfoOpen, setIsInfoOpen] = useState(false)
    const [isApiLoaded, setIsApiLoaded] = useState(false)
  const [rsaIdvValue, setRsaIdvValue] = useState()
  const [loader, setLoader] = loading
   const [loadApiData, setLoadApiData] = loadingData
  const [missionAwake, setMissionAwake] = mission
  const [inputDiscount, setInputDiscount] = useState()
const [preCustomerDetails, setPreCustomerDetails] = useState()
const [ownership, setOwnership] = useState()
const [selectedNCB, setSelectedNCB] = useState(0);
const [errorMessage2, setErrorMessage2] =useState(errorMessages2)
const [changeAction, setChangeAction] = useState(false)
const [tppdChange, setTppdChange] = useState(true)
const [reasonCpaVal, setReasonCpaVal] = useState()

    const [resetButton, setResetButton] = useState(false)
  const [isJWTCall, setIsJWTCall] = useState(false)
    const [quoteLoading, setquoteLoading] = useState(false);
    const [apiData, setApiData] = useState({});
    const [manufactureYear, setManufactureYear] = useState()
    const [tokenLoaded, setTokenLoaded] = useState(false)
    const [prevClaimValue, setPrevClaimValue] = useState()
  const [registeredModelValues, setRegisteredModelValues] = useState({})
    const [popUpsFilterData, setPopUpsFilterData] = useState({})
    const [makeRequest, setMakeRequest] = useState(false)
    const [bikeIsRegistered, setBikeIsRegistered] = useState()
  const [buttonValue, setButtonValue] = useState()
  const [jwtToken, setJwtToken] = useState(localStorage.getItem('jwt_token'))
  const [finalAddons, setFinalAddons] = useState({})
  const [finalAddonsInput, setFinalAddonsInput] = useState({})
  const [showCustomBox, setShowCustomBox] = useState()
  const [custBoxError, setCustBoxError] = useState(false)
  const [finalPolicyType, setFinalPolicyType] = useState()
  const [masterID, setMasterID] = useState({})
  const [modelRegionId, setModelRegionId] = useState({})
  const [initialPolicyType, setInitialPolicyType] = useState({})
  const [actionType, setActionType] = useState('')

    // For Counting Number of Checked values in Addons
   const selectAddonOption= ['']
   const trueValues = Object.values(inputAddon)
   trueValues.forEach(function (x) { selectAddonOption[x] = (selectAddonOption[x] || 0) + 1; });
    localStorage.setItem('checked_addons',addonsData&&selectAddonOption.true==undefined?'0':selectAddonOption.true)
   
  const MINUTE_MS = 30000;

  /* -------------------  API ENDPOINT -------------- */
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
/* ---------------------------------- -------------- */

  
  useEffect(() => {
    const onStorage = () => {
      setJwtToken(localStorage.getItem('jwt_token'));
    };
  
    window.addEventListener('storage', onStorage);
  
    return () => {
        window.removeEventListener('storage', onStorage);
    };
  }, []);
  
    const handleChange = (e) => {
  
   
      const name = e.target.name;
      const value = e.target.value;
      setInputs((values) => ({
        ...values,
        [name]: e.target.value
           
      }))
      // setAddonsData(inputs);
      setChangeAction(true)

    };
  
   
   
    // console.log(item.id == checkedData.policyData);
    // var checkedData = JSON.parse(localStorage.getItem("bike_popupsData"));
    // console.log(checkedData.policyData);
    // const addonCheck = JSON.parse(localStorage.getItem("addon_check_data"));
  
  
    useEffect(() => {
      closePopUp&&localStorage.setItem("addon_check_data", JSON.stringify(inputs));    
      setAddonsData(inputs);
      // setFirstCheckedData(checkedData);
    }, [inputs,closePopUp]);
  ///////////// we are at handling min and max null issues and  pending integration
    let registeredModelValue= localStorage.getItem('bike_registered_model')
    let registeredModelClaimValue= localStorage.getItem('bike_registered_model_claim')
    const filterData = localStorage.getItem("bike_popupsData");
    const bikeRegistered = localStorage.getItem("isBikeRegistered");
    const radioValue = localStorage.getItem('bike_Info')
    const bikeRegNO = localStorage.getItem('bike_Intro')
    const finaladdon = sessionStorage.getItem('bike_input_addons_check')
    const finalDiscount = sessionStorage.getItem('bike_inputs_discount')
    const finalpolicytype = sessionStorage.getItem('bike_inputs_policyType')
const masteridvalues= localStorage.getItem('bike_master_ID')
const finaladdoninput = sessionStorage.getItem('bike_check_inputs')
const modelRegion = localStorage.getItem('bike_regionCode')
const actiontype = sessionStorage.getItem("action_type") 
 const rsaInitIdv = localStorage.getItem("rsaIdvvalue")
 const preCustDetails= localStorage.getItem('pre_BikeCustomerDetials')
 const requestSta = localStorage.getItem('apiLoaded')
 const tppdVal = sessionStorage.getItem('bike_tppdVal')
 const cpaValues = sessionStorage.getItem('bike_cpaValues');

 let registeredModelOwnershipValue= localStorage.getItem('bike_registered_model_ownership')

    useEffect(()=>{
      setRsaIdvValue(rsaInitIdv)
  
      //  console.log('im from car quote', isNewCar?"":popUpsFilterData.policyData=='bundled'?2374:2347)
      setPopUpsFilterData(JSON.parse(filterData))
      setBikeIsRegistered (JSON.parse(bikeRegistered))
  setDefaultRadioValue(JSON.parse(radioValue))
  setRegisteredModelValues(JSON.parse(registeredModelValue))   
  setPrevClaimValue(registeredModelClaimValue)
  setManufactureYear(JSON.parse(radioValue).manufactureYear)
  bikeRegNO&&setBikeRegistrationNumber(JSON.parse(bikeRegNO).registrationNumber)
  // console.log('im calling again and again',popUpsFilterData.policyData)
  setFinalAddons(JSON.parse(finaladdon))
  setFinalAddonsInput(JSON.parse(finaladdoninput))
  setInputDiscount(finalDiscount)
  setFinalPolicyType(finalpolicytype)
  setMasterID(JSON.parse(masteridvalues))
  setModelRegionId(JSON.parse(modelRegion))
  setInitialPolicyType(JSON.parse(radioValue))
  setActionType(actiontype)
setPreCustomerDetails(JSON.parse(preCustDetails))
setOwnership(registeredModelOwnershipValue)
setNoChange(requestSta)
setTppdChange(tppdVal)
setReasonCpaVal(cpaValues)


  setOldPolicyType(JSON.parse(radioValue).policy)
  
  },[filterData, actiontype, preCustDetails, finaladdon, modelRegion, masteridvalues, finaladdoninput, finalpolicytype, finalDiscount, bikeRegistered, radioValue, bikeRegNO, registeredModelClaimValue, registeredModelValue, rsaInitIdv, registeredModelOwnershipValue, requestSta, tppdVal, cpaValues])
  
  // useEffect(()=>{
  //   setAddonsData(JSON.parse(addOns))
  
  // },[addOns])
  
    //Retriving Vehicle Registration Number 

    useEffect(()=>{
      if(inputs.coverAmount<minIdv||inputs.coverAmount>maxIdv){
        setErrorMessage2(true)
      }else {
        setErrorMessage2(false)
  
      }
      
        if (inputs.coverAmount===minIdv){
        setErrorMessage2(false)
      }
        if (inputs.coverAmount===maxIdv) {
        setErrorMessage2(false)
      }
     },[inputs, maxIdv, minIdv])
    useEffect(()=>{
  
      const vehicleNumberHandle = ()=>{
        let vehNo = ['']
        let spliting = bikeRegistrationNumber.split('')
        vehNo.unshift(spliting)
        setVehicleNumber( bikeRegistrationNumber.length<10? vehNo[0][0]+vehNo[0][1]+'-'+vehNo[0][2]+vehNo[0][3]+'-'+vehNo[0][4]+'-'+vehNo[0][5]+vehNo[0][6]+vehNo[0][7]+vehNo[0][8] : vehNo[0][0]+vehNo[0][1]+'-'+vehNo[0][2]+vehNo[0][3]+'-'+vehNo[0][4]+ vehNo[0][5]+'-'+vehNo[0][6]+vehNo[0][7]+vehNo[0][8]+vehNo[0][9])
    
        setRtoRegionCode(vehNo[0][0]+vehNo[0][1]+vehNo[0][2]+vehNo[0][3])
        // console.log(vehNo[0][0]+vehNo[0][1]+'-'+vehNo[0][2]+vehNo[0][3]+'-'+vehNo[0][4]+'-'+vehNo[0][5]+vehNo[0][6]+vehNo[0][7]+vehNo[0][8])
      }
      vehicleNumberHandle()
    },[bikeRegistrationNumber])
  
    // Vehicle registrationNumber
  

    
useEffect(()=>{

  const availableNCBValues = [0, 20, 25, 35, 45, 50];
  const selectedValue = parseInt(registeredModelValues&&registeredModelValues.prevNCB, 10);
    if ( selectedValue< 50) {
      // Automatically select the next higher value
      setSelectedNCB(availableNCBValues[availableNCBValues.indexOf(selectedValue) + 1]);
    } else {
      // Keep the value as is if it's already the highest
      setSelectedNCB(50);
    }

},[  registeredModelValues])
  
    // console.log(())
  
     // Creating todays Date
     let currentDate ;

     // Add logic to set the date based on a condition
     if (bikeRegistered==='true' ) {
       currentDate=(new Date(registeredModelValues&&registeredModelValues.policyExp) ); // Add one day
     } else if (bikeRegistered==='false') {
       currentDate=(new Date() ); // Add two days
     }
  let cDay = currentDate.getDate();
  let cMonth = currentDate.getMonth() + 1;
  let cYear = currentDate.getFullYear();
  let endCDay = currentDate.getDate();
    

  if((cYear+1)%4===0&&endCDay==28) {
    endCDay+=1
  }
  const userEnterDate = new Date(registeredModelValues&&registeredModelValues.policyExp)
  let prevDay = userEnterDate.getDate()
    let prevvDay = userEnterDate.getDate()+1
  
  let prevMonth = userEnterDate.getMonth() + 1;
  let prevYear = userEnterDate.getFullYear();
  let prevActDay =userEnterDate.getDate()
  let tpPrevYear =userEnterDate.getFullYear()-1
  if((prevYear+1)%4===0&&prevDay==28) {
    prevDay+=1
  }
  
    var dateFormatTotime = new Date(userEnterDate);
    var increasedDate = new Date(dateFormatTotime.getTime() +(1 *86400000) );
  
    // var dateString = 'Mon Jun 30 2014 00:00:00';
  
    var startDate = new Date(registeredModelValues&&registeredModelValues.policyExp);
    
    // seconds * minutes * hours * milliseconds = 1 day 
    var day = 60 * 60 * 24 * 1000;
    
    var endDate = new Date(startDate.getTime() + day);

    var toCurDate = new Date(currentDate.getTime()+day)
    var fromPrevDay = new Date(userEnterDate.getTime()+day)


    /* For Generating +1 Day  for Cover from and Cover To Start Here */

  

 /* ----------------------------------------------------- */
 const startDates = new Date(currentDate);
 startDates.setDate(currentDate.getDate()+1);
 
 // Calculate the end date (coverTo) as the same day next year, adjusting for leap years
 const nextYear = currentDate.getFullYear() + 1;
 
 
 /* ----------------------------------- */
 function getDaysInFebruaryNextYear() {
   const currentYear = new Date().getFullYear();
   const nextYear = currentYear + 1;
 
   // Create a date for March 1st of the next year
   const marchFirstNextYear = new Date(nextYear, 2, 1);
 
   // Subtract one day to get the last day of February in the next year
   const lastDayOfFebruaryNextYear = new Date(marchFirstNextYear - 1);
 
   // Get the day of the month to determine the number of days in February
   return lastDayOfFebruaryNextYear.getDate();
 }
 
 const daysInFebruaryNextYear = getDaysInFebruaryNextYear();
 /* ------------------------------------------------ */
 
 
 const isNextYearLeap = new Date(nextYear, 1, 29).getDate() === 29;
 const endDates = isNextYearLeap && nextYear%4===0&&daysInFebruaryNextYear===29&&currentDate.getMonth()===1
   ? new Date(nextYear, 1, 29)
   : new Date(nextYear, currentDate.getMonth(), currentDate.getDate() );
 // Format the dates as DD-MM-YYYY
 const formattedStartDate = formatDate(startDates);
 const formattedEndDate = formatDate(endDates);
 
 // Function to format date as DD-MM-YYYY
 function formatDate(date) {
   const day = String(date.getDate()).padStart(2, '0');
   const month = String(date.getMonth() + 1).padStart(2, '0');
   const year = date.getFullYear();
   return `${day}-${month}-${year}`;
 }
 
 
 /* -------------------------------- */
 
  

    
/* ---------------- Date Function For Old Vehicle start Here ---------------------  */


let dateString = bikeIsRegistered&&registeredModelValues ? registeredModelValues.policyExp.split("-").reverse().join('-'):'2023-1-5'
// let dateString ='2023-1-5'
let dateParts = dateString.split("-");
let yearAdd1 = parseInt(dateParts[2]);
let monthAdd1 = parseInt(dateParts[1]) - 1;
let dayAdd1 = parseInt(dateParts[0]);

let dateAdd1 = new Date(yearAdd1, monthAdd1, dayAdd1);
dateAdd1.setDate(dateAdd1.getDate() + 1);

let newYear = dateAdd1.getFullYear();
let newMonth = String(dateAdd1.getMonth() + 1).padStart(2, "0");;
let newDay =  String(dateAdd1.getDate()).padStart(2, "0");

let newDateString = `${newDay}-${newMonth}-${newYear}`;
/* +1 year */

let dateCt1 = new Date(`${yearAdd1+1}-${monthAdd1+1}-${dayAdd1}`);
dateCt1.setDate(dateCt1.getDate());

 
let newCtYear = dateCt1.getFullYear();
let newCtMonth = String(dateCt1.getMonth() + 1).padStart(2, "0");;
let newCtDay =  String(dateCt1.getDate()).padStart(2, "0");

let newCtDateString = `${newCtDay}-${newCtMonth}-${newCtYear}`;

/* ------------------- */

/* -1 day */

let dateRem1 = new Date(`${newYear-1}-${newMonth}-${newDay}`);

dateRem1.setDate(dateRem1.getDate() -1);

 
let newRemYear = dateRem1.getFullYear();
let newRemMonth = String(dateRem1.getMonth() + 1).padStart(2, "0");;
let newRemDay =  String(dateRem1.getDate()).padStart(2, "0");

let newRemDateString = `${newRemDay}-${newRemMonth}-${newRemYear}`;


/* ---------------- Date Function For Old Vehicle End Here ---------------------  */



  let day1 = userEnterDate.getDate();
  let month1 = userEnterDate.getMonth();
  let year1 = userEnterDate.getFullYear();
  
  if (month1 === 11) {
    month1 = 0;
    year1 += 1;
  } else if (month1 === 1) {
    let febDays = (year1 % 4 === 0 && (year1 % 100 !== 0 || year1 % 400 === 0)) ? 29 : 28;
    if (day1 === febDays) {
      day1 = 0;
        month1 += 1;
    }
  } else {
    if (day1 === new Date(year1, month1 + 1, 0).getDate()) {
      day1 = 0;
        month1 += 1;
    }
  }
  let nextDate = new Date(year1, month1+1, day1 + 1);
  
  let newMonth1 = nextDate.getMonth()


   /* For Generating +1 Day  for Cover from and Cover To Start Here for New vehicle */

  
   if (cMonth === 11) {
     cMonth = 0;
     cYear += 1;
   } else if (cMonth === 1) {
     let febDays = (cYear % 4 === 0 && (cYear % 100 !== 0 || cYear % 400 === 0)) ? 29 : 28;
     if (cDay === febDays) {
       cDay = 0;
       cMonth += 1;
     }
   } else {
     if (cDay === new Date(cYear, cMonth + 1, 0).getDate()) {
       cDay = 0;
       cMonth += 1;
     }
   }
   let nextDateNew = new Date(cYear, cMonth+1, cDay + 1);
   
   let newMonth1New = nextDateNew.getMonth()
    /* For Generating +1 Day  for Cover from and Cover To End Here */

const handleFilterData = (e)=>{
  e.preventDefault()
  if(inputs.coverAmount=='') {
    setCustBoxError(true )
    setClosePopUp(true);
  }else{
    setCustBoxError(false )
     errorMessage2==false&&  setClosePopUp(false);
    errorMessage2==false&&  changeAction&& localStorage.setItem('apiLoaded',false)
 
   
    sessionStorage.setItem("bike_inputs_IDV",inputs.coverAmount)
     setPlaceHolderValue(inputs.coverAmount)
}
 
  errorMessage2==false&&(Object.values(inputs.coverAmount)=='')==false&&setLoader(true)

  errorMessage2==false&&(Object.values(inputs.coverAmount)=='')==false&&axios
    .post(
      `${API_ENDPOINT}/quotes`,
      {
       // For RSA


      

    "royalSundaramBikeQuote": {
      "premium": 0.0,
      "proposerDetails": {
          "title": "Mr",
          "firstName": "Sumit",
          "lastName": "Bansal",
          "emailId": preCustomerDetails&& preCustomerDetails.email,
          "userID": 0,
          "mobileNo": "7010226240",
          "dateOfBirth": "23/09/1989",
          "occupation": "Others",
          "nomineeName": "Vanila Garg",
          "nomineeAge": "29",
          "relationshipWithNominee": "Spouse",
          "permanentAddress1": "H.No ",
          "permanentAddress2": "1288,Phase-2,Ramdarbar",
          "permanentCity": "CHANDIGARH",
          "permanentPincode": "160002",
          "sameAdressReg": "Yes",
          "residenceAddressOne": null,
          "residenceAddressTwo": null,
          "residenceCity": null,
          "residencePinCode": null
      },
      "vehicleDetails": {
          "engineNumber": "",
          "chassisNumber": "",
          "registrationNumber":  vehicleNumber.toUpperCase().split('-').join(''),
          "isTwoWheelerFinanced": "No",
          "vehicleSubLine": "motorCycle",
          "vehicleModelCode": masterID.rsaModelCode[0],
          // "vehicleModelCode": "ZWTV1535",
          "planOpted": "Flexi Plan",
          "yearOfManufacture": manufactureYear,
          "drivingExperience": "1",
       
          "voluntaryDeductible": inputDiscount == ("" || null || undefined)
          ? ""
          : inputs.policyType == "thirdParty"
          ? ""
          : inputDiscount == "None"
          ? ""
          : inputDiscount,
          "isValidDrivingLicenseAvailable": "Yes",
          "hdnDepreciation": false,
          "hdnEngineProtector": false,
          "coveredParkingOrGroundParking": "No",
          "coveredParkingNotBasement": "No",
          "unknownParking": "No",
          "hdnFullInvoice": false,
          "fullInvoicePlan1": "No",
          "fullInvoicePlan2": "No",
          "vehicleManufacturerName": "",
          "idv": 0,
          "idvFor2Year": 0,
          "idvFor3Year": 0,
          "idvFor4Year": 0,
          "idvFor5Year": 0,
          "discountIdvPercent": 0,
          "modifiedIdv":  inputs.coverAmount==(''||null||undefined)?0:( ((actionType =='Car')&& (finalPolicyType==undefined||null))?oldPolicyType=="thirdParty":finalPolicyType=="thirdParty")?0: inputs.coverAmount,
          "discountIDVPercent2Year": 0,
          "modifiedIDVfor2Year": 0,
          "discountIDVPercent3Year": 0,
          "modifiedIDVfor3Year": 0,
          "discountIDVPercent4Year": 0,
          "modifiedIDVfor4Year": 0,
          "discountIDVPercent5Year": 0,
          "modifiedIDVfor5Year": 0,
          "vehicleMostlyDrivenOn": "City roads",
          "vehicleRegDate": defaultRadioValue.registrationDate.split('-').reverse().join('/'),
          "vehicleRegisteredInTheNameOf": "Individual",
          "modelName": "",
          "productName":bikeIsRegistered ?"RolloverTwoWheeler":"BrandNewTwowheeler",
          "typeOfCover":finalPolicyType==undefined||null? bikeIsRegistered?  (oldPolicyType=="comprehensive" && "Comprehensive") || (oldPolicyType=="thirdParty"&&"LiabilityOnly") || (oldPolicyType=="ownDamage"&&"standalone")    : oldPolicyType=='bundled'?"Bundled":"LiabilityOnly" : bikeIsRegistered?  (finalPolicyType=="comprehensive" && "Comprehensive") || (finalPolicyType=="thirdParty"&&"LiabilityOnly") || (finalPolicyType=="ownDamage"&&"standalone")    :finalPolicyType=='bundled'?"Bundled":"LiabilityOnly",
          "fuelType": "Petrol",
          "region": "East Region",
          "carRegisteredCity": "",
          "isProductCheck": "true",
          "engineCapacityAmount": "",
          "personalAccidentCoverForUnnamedPassengers":  finalAddons == ("" || null || undefined)
          ? '0'
          : finalAddons.unnamedpassengerPAcover
          ? "100000"
          : "0",
          "accidentCoverForPaidDriver":  finalAddons == ("" || null || undefined)
          ? '0'
          : finalAddons.paidDrivercover
          ? "100000"
          : '0',
          "legalliabilityToPaidDriver": "No",
          "legalliabilityToEmployees": "No",
          "noClaimBonusPercent": "",
          "ncbcurrent": "",
          "claimAmountReceived": "0",
          "claimsReported": "0",
          "ncbprevious": ((registeredModelValues&&registeredModelValues.prevNCB==(null || undefined))||prevClaimValue=='Yes')?0:`${registeredModelValues&&+registeredModelValues.prevNCB}`,
          "vechileOwnerShipChanged":ownership=='Yes'?'Yes': "No",
          "cpaCoverisRequired":finalAddons == null || undefined
          ?  "No"
          : finalAddons.ownerDriverPAcover
          ? "Yes"
          :  "No",
          "cpaPolicyTerm": 0,
          "cpaCoverDetails": {
            "cpaCoverWithInternalAgent":!finalAddons.ownerDriverPAcover&& reasonCpaVal=="havingInsured"?true:false,
            "noEffectiveDrivingLicense": !finalAddons.ownerDriverPAcover&&reasonCpaVal=="havingLicense"?true:false,
            "standalonePAPolicy":!finalAddons.ownerDriverPAcover&& reasonCpaVal=="havingPolicy"?true:false
          },
          "rtoName": modelRegionId.rsa.rtoName,
          "addlDiscount": 0,
          "addOnsOptedInPreviousPolicy": "",
          "tppdLimit":tppdChange? 6000:null,
          "previousPolicyNo": "",
          "previousInsurerName": "",
          "previousPolicyType": "comprehensive",
          "isPreviousPolicyHolder": "false",
          "previousPolicyExpiryDate":  (prevActDay<10 ? `0${prevActDay}` :prevActDay)    + "/" + (prevMonth<10 ? `0${prevMonth}` : prevMonth) + "/" + prevYear ,
          "claimsMadeInPreviousPolicy":  prevClaimValue=='Yes'?'Yes':'No',
          "policyTerm": "",
          "validPUCAvailable": "Yes",
          "pucnumber": "",
          "pucvalidUpto": "29/12/2023"
      },
      "source": "STP",
      "utmSource": "",
      "utmMedium": "",
      "utmCampign": "",
      "utmTerm": "",
      "utmContent": "",
      "employeeCode": "",
      "branchCode": "",
      "empBranchCode": "",
      "regionCode": "",
      "posOpted": false,
      "authenticationDetails": {
          "apikey": "310ZQmv/bYJMYrWQ1iYa7s43084=",
          "agentId": "AG023760"
      }
  },











    //    "royalSundaramQuote": {
    //     "respType": "JSON",
    //     "reqType": "JSON",
    //     "isproductcheck": "No",
    //     "istranscheck": "No",
    //     "isNewUser": "No",
    //     "premium": 0.0,
    //     "quoteId": "",
    //     "authenticationDetails": {
    //         "apikey": "310ZQmv/bYJMYrWQ1iYa7s43084=",
    //         "agentId": "BA506423"
    //     },
    //     "proposerDetails": {
    //       "title": "Mr",
    //       "firstName": "Sumit",
    //       "lastName": "Bansal",
    //       "emailId": "gauri.shankar@royalsundaram.in",
    //       "userID": 0,
    //       "mobileNo": "7010226240",
    //       "dateOfBirth": "23/09/1989",
    //       "occupation": "Others",
    //       "nomineeName": "Vanila Garg",
    //       "nomineeAge": "29",
    //       "relationshipWithNominee": "Spouse",
    //       "permanentAddress1": "H.No ",
    //       "permanentAddress2": "1288,Phase-2,Ramdarbar",
    //       "permanentCity": "CHANDIGARH",
    //       "permanentPincode": "160002",
    //       "sameAdressReg": "Yes",
    //       "ResidenceAddressOne": "H.No ",
    //       "ResidenceAddressTwo": "1288,Phase-2,Ramdarbar",
    //       "ResidenceCity": "CHANDIGARH",
    //       "ResidencePinCode": "160002"
  
    //     },
    //     "vehicleDetails": {
    //         "yearOfManufacture":manufactureYear, // manufacture Year from step1
    //         "carRegisteredCity": "",
    //         "original_idv":  rsaIdvValue,    // initial idv value from First quote call 
    //         // "original_idv":inputs.coverAmount==(''||null||undefined)? rsaIdvValue:"0",    // initial idv value from First quote call 
    //         "modify_your_idv": "0",
    //         "modified_idv_value": inputs.coverAmount==(''||null||undefined)?0:( ((actionType =='Car')&& (finalPolicyType==undefined||null))?oldPolicyType=="thirdParty":finalPolicyType=="thirdParty")?0: inputs.coverAmount, // idv addon Value
    //         "vehicleregDate": defaultRadioValue.registrationDate.split('-').reverse().join('/'), // registration Date
    //         "previousPolicyExpiryDate":(prevActDay<10 ? `0${prevActDay}` :prevActDay)    + "/" + (prevMonth<10 ? `0${prevMonth}` : prevMonth) + "/" + prevYear, // modal window policyExpir
    //         "rtoName":  modelRegionId.rsa.rtoName, // rto Name
    //         "vehicleManufacturerName": "",
    //         "vehicleModelCode": "ZWTV2324", //Tbd
    //         "drivingExperience": "",
    //         "vehicleRegisteredInTheNameOf": "Individual",
    //         "companyNameForCar": "",
    //         "vechileOwnerShipChanged": "No",
    //         "isPreviousPolicyHolder": "true",
    //         "ProductName": bikeIsRegistered ?"RolloverTwoWheeler":"BrandNewTwowheeler", // Rollovercar for old car and new car BrandNewCar
    //         "typeOfCover":  actionType =='Car'&& finalPolicyType==undefined||null? bikeIsRegistered?  (oldPolicyType=="comprehensive" && "Comprehensive") || (oldPolicyType=="thirdParty"&&"LiabilityOnly") || (oldPolicyType=="ownDamage"&&"standalone")    : oldPolicyType=='bundled'?2374:2371 : bikeIsRegistered?  (finalPolicyType=="comprehensive" && "Comprehensive") || (finalPolicyType=="thirdParty"&&"LiabilityOnly") || (finalPolicyType=="ownDamage"&&"standalone")    :finalPolicyType=='bundled'?"Bundled":"LiabilityOnly",// type of policy
    //         "averageMonthlyMileageRun": "",
    //         "chassisNumber": "",
    //         "engineCapacityAmount": "",
    //         "engineNumber": "",
    //         "fuelType": "",
    //         "isTwoWheelerFinanced": "No",
    //         "isTwoWheelerFinancedValue": "",
    //         "financierName": "",
    //         "policySD": bikeIsRegistered? `${newDay}/${newMonth}/${newYear}`: (toCurDate.getDate()<10?`0${toCurDate.getDate()}`: toCurDate.getDate())  + "/" + (newMonth1New-1<10? `0${newMonth1New-1}` :newMonth1New-1)  + "/" + ((endCDay=='31'&& cMonth>11)? cYear+1 : cYear ), // same as reliance cover from 
    //         "policyED": bikeIsRegistered ?  `${newCtDay}/${newCtMonth}/${newCtYear}`:endCDay+ "/" + (cMonth<10 ? `0${cMonth}` : cMonth)   + "/" + (endCDay<10 ? `0${endCDay}` : cYear+1  ) ,// cover to 
    //         "policyTerm": "0",
    //         "region": "",
    //         "vehicleMostlyDrivenOn": "",
    //         "vehicleSubLine": "motorCycle",
    //         "voluntarydeductible":  inputDiscount == ("" || null || undefined)
    //         ? "0"
    //         : inputs.policyType == "thirdParty"
    //         ? "0"
    //         : inputDiscount == "None"
    //         ? "0"
    //         : inputDiscount, // discount value
    //         "modelName": "",
    //         "modified_idv_2year_value": "0",
    //         "modified_idv_3year_value": "0",
    //         "original_idv_2year": "0",
    //         "original_idv_3year": "0",
    //         "pucnumber": "",
    //         "pucvalidUpto": "",
    //         "technicalDiscount": "",
    //         "validPUCAvailable": "",
    //         "VIRNumber": "",
    //         "registrationNumber": vehicleNumber.toUpperCase().split('-').join(''),// registration number same as Reliance  
    //         "previousInsurerName": "",
    //         "previousinsurersCorrectAddress": "",
    //         "previousPolicyType": "Comprehensive",
    //         "previuosPolicyNumber": "",
    //         "planOpted": "",
    //         "noClaimBonusPercent":'', // same as reliance but add +1
    //         "ncbcurrent": "",
    //         "ncbprevious": ((registeredModelValues&&registeredModelValues.prevNCB==(null || undefined))||prevClaimValue=='Yes')?0:`${registeredModelValues&&+registeredModelValues.prevNCB}`,
    //         "legalliabilitytopaiddriver": "No", 
    //         "legalliabilitytoemployees": "No",
    //         "fibreglass": "no",
    //         "towingChargesCover": "no",
    //         "hdnTyreCover":  finalAddons == null || undefined
    //             ? false
    //             : finalAddons.tyreProtection
    //             ? true
    //             : false , // tyre protoctor true :false
    //             "tyreCover":  finalAddons == null || undefined
    //             ?  "Off"
    //             : finalAddons.tyreProtection
    //             ? "On"
    //             :  "Off" , // tyre protoctor On:Off
    //         "cpaPolicyTerm": 1,
    //         "cpaSumInsured": 1500000,
    //         "cpaCoverisRequired": finalAddons == null || undefined
    //             ?  "No"
    //             : finalAddons.ownerDriverPAcover
    //             ? "Yes"
    //             :  "No", // pa owner driver cover Yes:No
    //         "accidentcoverforpaiddriver":   finalAddons == ("" || null || undefined)
    //         ? '0'
    //         : finalAddons.paidDrivercover
    //         ? "200000"
    //         : '0',  
    //         "isValidDrivingLicenseAvailable": "Yes",
    //         "claimsMadeInPreviousPolicy": "No",
    //         "cover_dri_othr_car_ass": "No",
    //         "towingChargesCover_SI": "1000",
    //         "hdtDiscount": 10,
    //         "personalaccidentcoverforunnamedpassengers":  finalAddons == ("" || null || undefined)
    //         ? '0'
    //         : finalAddons.unnamedpassengerPAcover
    //         ? "200000"
    //         : '0',
    //         "addOnsOptedInPreviousPolicy": "",
    //         "campaignDiscount": "",
    //         "claimAmountReceived": "0",
    //         "claimsReported": "0",
    //         "isBreakinInsurance": "No",
    //         "isBundleDiscountApply": "No",
    //         "isUsedCar": "No",
    //         "hdnRoadSideAssistanceCover": finalAddons == null || undefined
    //             ? false
    //             : finalAddons.roadSideAssistance
    //             ? true
    //             : false, // road side assistant True :false
    //             "roadSideAssistancePlan1": finalAddons == null || undefined
    //             ?  "No"
    //             : finalAddons.roadSideAssistance
    //             ? "Yes"
    //             :  "No", // road side Yes:No
    //         "roadSideAssistancePlan2": "No",
    //         "isBiFuelKit":  defaultRadioValue.fuelType ==('petrol+cng'||'petrol+lpg') ? true :(!["" || null || undefined].includes(finalAddons)&&  finalAddons&&finalAddons.externalBiFuelkit)
    //         ? 'Yes'
    //         :'No',
    //         "isBiFuelKitYes":defaultRadioValue.fuelType ==('petrol+cng'||'petrol+lpg') ? true :(!["" || null || undefined].includes(finalAddons)&& finalAddons&&finalAddons.externalBiFuelkit)
    //         ? 'Add On'
    //         :'Inbuilt',
    //         "addonValue": (finalAddonsInput==null||undefined)?0: finalAddonsInput.checkInput3,
    //         "hdnKeyReplacement": finalAddons == null || undefined
    //         ? false
    //         : finalAddons.keyLoss
    //         ? true
    //         :  false, //keyLoss True : false
    //         "keyreplacement": finalAddons == null || undefined
    //         ?  "Off"
    //         : finalAddons.keyLoss
    //         ? "On"
    //         :  "Off", // keyloss On:Off
    //         "hdnDepreciation": finalAddons == null || undefined
    //             ? false
    //             : finalAddons.zeroDepreciation
    //             ? true
    //             :  false, // zero Depricia True : false
    //             "depreciationWaiver": finalAddons == null || undefined
    //             ?  "Off"
    //             : finalAddons.zeroDepreciation
    //             ? "On"
    //             :  "Off",// zer depr is true ? On : Off
    //         "hdnWindShield": false,
    //         "windShieldGlass": "Off",
    //         "hdnProtector": finalAddons == null || undefined
    //         ?  false
    //         : finalAddons.engineProtect
    //         ? true
    //         :  false, /// engine Protoctor true:false
    //         "engineprotector": finalAddons == null || undefined
    //         ?  "Off"
    //         : finalAddons.engineProtect
    //         ? "On"
    //         :  "Off",// engine protoctor On:Off
    //         "hdnNCBProtector": finalAddons == null || undefined
    //             ?  false
    //             : finalAddons.ncbProtection
    //             ? true
    //             :  false, // ncb protoctors true:false
    //             "ncbprotector": finalAddons == null || undefined
    //             ?  "Off"
    //             : finalAddons.ncbProtection
    //             ? "On"
    //             :  "Off",// ncb protoctors On:Off
    //         "hdnRoadTax": false,
    //         "registrationchargesRoadtax": "Off",
    //         "hdnSpareCar": false,
    //         "spareCar": "off",
    //         "spareCarLimit": "",
    //         "hdnInvoicePrice": finalAddons == null || undefined
    //             ?  false
    //             : finalAddons.returntoInvoice
    //             ? true
    //             :  false, // returned invoice true:false
    //             "invoicePrice": finalAddons == null || undefined
    //             ?  "Off"
    //             : finalAddons.returntoInvoice
    //             ? "On"
    //             :  "Off",// returned invoice On:Off
    //             "hdnLossOfBaggage": finalAddons == null || undefined
    //             ?  false
    //             : finalAddons.lossOfPersonalBelongings
    //             ? true
    //             :  false, // loss of baggage true : false
    //             "lossOfBaggage": finalAddons == null || undefined
    //             ?  "Off"
    //             : finalAddons.lossOfPersonalBelongings
    //             ? "On"
    //             :  "Off",// loss of baggage On:Off
    //         "valueOfLossOfBaggage": "15000",
    //         "isVehicleInspected": "No",
    //         "hdnVehicleReplacementCover": false,
    //         "cpaCoverDetails": {
    //             "companyName": "test",
    //             "cpaCoverWithInternalAgent": false,
    //             "expiryDate": "",
    //             "noEffectiveDrivingLicense": false,
    //             "policyNumber": "",
    //             "standalonePAPolicy": false
    //         },
    //         "cover_elec_acc": finalAddons == null || undefined
    //             ?  "No"
    //             : finalAddons.electricalaccessories
    //             ? "Yes"
    //             :  "No", // elect acc Yes:No
    //         "electricalAccessories": {
    //             "electronicAccessoriesDetails": [
    //                 {
    //                     "makeModel": "KwWTIHcety",
    //                     "nameOfElectronicAccessories": "ScfgsBKTmd",
    //                     "value":  finalAddonsInput == null || undefined
    //                     ? 0
    //                     : finalAddonsInput&&finalAddonsInput.checkInput1  // elec acc Input1
    //                 }
    //             ]
    //         },
    //         "valueofelectricalaccessories":  finalAddonsInput == null || undefined
    //             ? 0
    //             : finalAddonsInput&&finalAddonsInput.checkInput1, // elec acc input1
    //             "cover_non_elec_acc": finalAddons == null || undefined
    //             ?  "No"
    //             : finalAddons.nonElectricalaccessories

    //             ? "Yes"
    //             :  "No", // non elect acc Yes:No
    //         "nonElectricalAccesories": {
    //             "nonelectronicAccessoriesDetails": [
    //                 {
    //                     "makeModel": "KwWTIHcety",
    //                     "nameOfElectronicAccessories": "ScfgsBKTmd",
    //                     "value": finalAddonsInput == null || undefined
    //                     ? 0
    //                     : finalAddonsInput&&finalAddonsInput.checkInput1  // non elec Input2
    //                 }
    //             ]
    //         },
    //         "valueofnonelectricalaccessories":  finalAddonsInput == null || undefined
    //             ? 0
    //             : finalAddonsInput&&finalAddonsInput.checkInput1 ,// non elec acc Input2
    //             "voluntaryDeductible": 500,
    //             "hdnEngineProtector": false,
    //             "coveredParkingOrGroundParking": "No",
    //             "coveredParkingNotBasement": "No",
    //             "unknownParking": "No",
    //             "hdnFullInvoice": false,
    //             "fullInvoicePlan1": "No",
    //             "fullInvoicePlan2": "No",
    //             "idv": 0,
    //             "idvFor2Year": 0,
    //             "idvFor3Year": 0,
    //             "idvFor4Year": 0,
    //             "idvFor5Year": 0,
    //             "discountIdvPercent": 0,
    //             "modifiedIdv": 0,
    //             "discountIDVPercent2Year": 0,
    //             "modifiedIDVfor2Year": 0,
    //             "discountIDVPercent3Year": 0,
    //             "modifiedIDVfor3Year": 0,
    //             "discountIDVPercent4Year": 0,
    //             "modifiedIDVfor4Year": 0,
    //             "discountIDVPercent5Year": 0,
    //             "modifiedIDVfor5Year": 0,
    //             "vehicleRegDate": "10/04/2023",
    //             "productName": "RolloverTwoWheeler",
    //             "isProductCheck": "true",
    //             "personalAccidentCoverForUnnamedPassengers": "100000",
    //             "accidentCoverForPaidDriver": "100000",
    //             "legalliabilityToPaidDriver": "No",
    //             "legalliabilityToEmployees": "No",
             
    //             "addlDiscount": 0,
    //             "previousPolicyNo": "2312100594971500000",
    //     },
    //     "source": "",
    //     "isPosOpted": "",
    //     "posCode": "",
    //     "posDetails": {
    //         "name": "",
    //         "pan": "",
    //         "aadhaar": "",
    //         "mobile": "",
    //         "licenceExpiryDate": ""
    //     },
    //     "existingTPPolicyDetails": {
    //         "tpInsurer": "",
    //         "tpPolicyNumber": "",
    //         "tpInceptionDate": "",
    //         "tpExpiryDate": "",
    //         "tpPolicyTerm": ""
    //     },
    //     "isFinalCalculateCall": "No",
    //     "utmSource": "",
    //     "utmMedium": "",
    //     "utmCampign": "",
    //     "utmTerm": "",
    //     "utmContent": "",
    //     "employeeCode": "",
    //     "branchCode": "",
    //     "empBranchCode": "",
    //     "regionCode": "",
    //     "posOpted": false,
    // },




        /////////////////////////////////////////////

        "businessType":bikeIsRegistered ?"5":'1',
        "vehicleMakeId":masterID.reliance[0].makeId,
        "vehicleModelId": masterID.reliance[0].modelId,
        // "vehicleVariant": "LXI CNG",
        // "cubicCapacity": 998,
        "rtoLocationId":Math.floor(modelRegionId.reliance.modelRegionId),
        "stateOfRegistrationId": Math.floor(modelRegionId.reliance.stateId),
        "rtoRegionCode":modelRegionId.reliance.regionCode.replace('-',''),
        "zone": modelRegionId.reliance.modelZoneName,
        "seatingCapacity": 2,
        "licencedCarryingCapacity": 0,
        "noOfWheels": 2,
        "exShowroomPrice": 0,
        "idv": inputs.coverAmount==(''||null||undefined)?0:finalPolicyType=="thirdParty"?0: inputs.coverAmount ,
        "registrationNumber": bikeIsRegistered ? vehicleNumber.toUpperCase(): `${modelRegionId.reliance.regionCode}-NEW`,
        
        // "registrationDate": "2021-07-01", // not req
        "manufacturerMonth": 1,
        "manufacturerYear":  manufactureYear,
        "dateOfPurchase":defaultRadioValue.registrationDate,
        "typeOfFuel": (initialPolicyType.fuelType == 'petrol'&&1) ||(initialPolicyType.fuelType == 'diesel'&&2)||(initialPolicyType.fuelType == 'petrol+cng'&&5)||(initialPolicyType.fuelType == 'electric'&&6),
        "branchCode": 9202,
 
        "productCode":  finalPolicyType==undefined||null? bikeIsRegistered?  (oldPolicyType=="comprehensive" && 2312) || (oldPolicyType=="thirdParty"&&2348) || (oldPolicyType=="ownDamage"&&2308)    : oldPolicyType=='bundled'?2375:2370 : bikeIsRegistered?  (finalPolicyType=="comprehensive" && 2312) || (finalPolicyType=="thirdParty"&&2348) || (finalPolicyType=="ownDamage"&&2308)    :finalPolicyType=='bundled'?2375:2370,  //based on vehicle type
        "coverFrom":    formattedStartDate.split("-").reverse().join('-'), // user inpuut 
     
        "coverTo":  formattedEndDate.split("-").reverse().join('-') ,
         "otherSystemName": 1,
        "clientType": 0,
        "agentName": "Direct",
        "ncbReservingLetter": "string",
        "miscTypeOfVehicleId": 0,
        "userId": "100002",
        "isPAToDriverCovered":(finalAddons==(''||null||undefined)|| ( ((finalPolicyType==undefined||null))?oldPolicyType=="ownDamage":finalPolicyType=="ownDamage"))?false:finalAddons.paidDrivercover?true:false,  //paid driver cover chek true / true
        "paToDriverCoveredNoOfItems":1,
             "paToDriverCoveredSumInsured":200000,
       
             "isPAToUnNamedPassenger":(finalAddons==(''||null||undefined)|| ( ((finalPolicyType==undefined||null))?oldPolicyType=="ownDamage":finalPolicyType=="ownDamage"))?false:finalAddons.unnamedpassengerPAcover?true:false, // unnamed passenger check true
             "paToUnNamedPassengerCoveredNoOfItems":2,
          "paToUnNamedPassengerCoveredSumInsured":100000,
       
          "isPAToNamedPassengerCovered":false,
             "paToNamedPassengerCoveredNoOfItems":3,
             "paToNamedPassengerCoveredSumInsured":100000,
       
              "isLiabilityToPaidDriverCovered":false,
           "liabilityToPaidDriverCoveredNoOfItems":1,
       
             "isTPPDCover":( ((finalPolicyType==undefined||null))?oldPolicyType=="ownDamage":finalPolicyType=="ownDamage")?false:true,
            "tppdSumInsured":6000,
             "tppdIsChecked":( ((finalPolicyType==undefined||null))?oldPolicyType=="ownDamage":finalPolicyType=="ownDamage")?false:true,
             "isVoluntaryDeductableOpted": inputDiscount==null||undefined?false: inputDiscount=='None'?false:finalPolicyType=="thirdParty"?false: inputDiscount=='None'?false:true, // if discount is not zero ? true : false
             "voluntaryDeductibleSumInsured": inputDiscount==null||undefined?'0': inputDiscount=='None'?'0':finalPolicyType=="thirdParty"?'0': inputDiscount, /// discount value 
           
       
             "isNilDepreciation":false  ,
             "nilDeprectiationApplicableRate":1.3,
       
             "isSecurePlus":false ,
             "securePlusApplicableRate":1.5,
       
             "isSecurePremium":false,
             "securePremiumApplicableRate":0.95,
       
          "isAntiTheftDeviceFitted":false,
             "antiTheftIsChecked":3,
           "antiTheftNoOfItems":0,
          
       
             "isMotorQuote": false,
         "isHavingValidDrivingLicense":true,
          "isMotorQuoteFlow":false,
          "isElectricalItemFitted":false, // once ticked electri accesor
          "electricalItemsTotalSi":0 , // above box value
          "isNonElectricalItemFitted":false, // non electric
           "nonElectricalItemsTotalSi":0, // value above
       
             "ncbEligibilityCriteria":prevClaimValue=='Yes'?'1':'2',
            "previousNcb":registeredModelValues&&registeredModelValues.prevNCB==(null || undefined)?0:registeredModelValues&&registeredModelValues.prevNCB,
           "isNcbApplicable":prevClaimValue=='Yes'?false : true, // yes ? true : false
       
            "isPAToOwnerDriverCovered":finalAddons==(''||null||undefined)||((finalPolicyType==undefined||null)?oldPolicyType=="ownDamage":finalPolicyType=="ownDamage")?((finalPolicyType==undefined||null)?oldPolicyType=="ownDamage":finalPolicyType=="ownDamage")? false:true:finalAddons.ownerDriverPAcover?true:false, //owner driver checked ? true 
            "nomineeName":"preetham",
         "nomineeAddress":"rmm",
         "nomineeRelationship":"cousin",
         "nomineeDob":"17/09/1990",
         "cpaCovertenure":1,
       
        //   "mandatory": true, // not req
        //  "checked": true, // not requ
        //   "islpgCng": true,  // not req

         "sourceSystemId": "100002",
         "authToken": "Pass@123",
       
         "basicLiability": ( ((finalPolicyType==undefined||null))?oldPolicyType=="ownDamage":finalPolicyType=="ownDamage")?false:true,
         "newVehicle": bikeIsRegistered?false:true,
        "basicODCoverage": ( ((finalPolicyType==undefined||null))?oldPolicyType=="thirdParty":finalPolicyType=="thirdParty")?false:true,
       
        "prevYearInsurer": 4,
         "prevYearPolicyEndDate":prevYear  + "-" + (prevMonth<10 ? `0${prevMonth}` : prevMonth) + "-" +   (prevActDay<10 ? `0${prevActDay}` :prevActDay) ,

         "prevYearPolicyNo": "8686948888564865", 
         "prevYearPolicyStartDate": tpPrevYear + "-" + (prevMonth<10 ? `0${prevMonth}` : prevMonth) + "-" +   (prevvDay<10 ? `0${prevvDay}`: (fromPrevDay.getDate()<10? `0${fromPrevDay.getDate()}` :fromPrevDay.getDate() )),
         "isPreviousPolicyDetailsAvailable":"true",
        "prevYearPolicyType":"1",
      
        "biFuelIsMandatory": false,
        "biFuelSumInsured": 0, // from addons bifuel input

         "biFuelFuelType": initialPolicyType.fuelType ==("petrol+cng"||'cng')?"CNG":"LPG",
         "biFuelKit": initialPolicyType.fuelType == ('petrol+cng'||'petrol+lpg')?true:false,
         "biFuelIsChecked": initialPolicyType.fuelType == ('petrol+cng'||'petrol+lpg')?true:false,
         "biFuelIsLpgCng": initialPolicyType.fuelType == ('petrol+cng'||'petrol+lpg')?true:false,
    

/* ------------------------- Quote Request for Godigit ------------------------------ */

enquiryId: "GODIGIT_CQ_TWO_WHEELER_PACKAGE_01",
'insuranceProductCode':finalPolicyType==undefined||null? bikeIsRegistered?  (oldPolicyType=="comprehensive" && 20201) || (oldPolicyType=="thirdParty"&&20202) || (oldPolicyType=="ownDamage"&&20203)    : oldPolicyType=='bundled'?20201:20202 : bikeIsRegistered?  (finalPolicyType=="comprehensive" && 20201) || (finalPolicyType=="thirdParty"&&20202) || (finalPolicyType=="ownDamage"&&20203)    :finalPolicyType=='bundled'?20201:20202  , //based on vehicle type
        
        
        
subInsuranceProductCode: bikeIsRegistered
? "PB"
: (finalPolicyType==undefined||null?oldPolicyType=="bundled":  finalPolicyType == "bundled")
? 51
: 50,
     "policyHolderType": "INDIVIDUAL",
     "voluntaryDeductible":inputDiscount==null||undefined?'ZERO': inputDiscount=='None'?'ZERO':finalPolicyType=="thirdParty"?'ZERO': ((inputDiscount=='1000'&&'THOUSAND')   ||  (inputDiscount=='2000'&&'TWO_THOUSAND')|| (inputDiscount=="2500" && "TWENTYFIVE_HUNDRED") || (inputDiscount=='3000'&&'THREE_THOUSAND')), // discount two thousand and five hundred from Discount filter
     "licensePlateNumber": bikeIsRegistered?  vehicleNumber.replaceAll('-','').toUpperCase() : `${modelRegionId.goDigit.regionCode.replace('-','')}NEW`, // registration number if new vehicle : RTOCODE EX MH12NEW
      "vehicleMaincode": masterID.goDigitVehicleCode[0], // MASTER ID goDigitVehicleCode
     "pinCode": null, 
     "digitRegistrationDate": initialPolicyType.registrationDate, // manufacture year
     "digitManufactureDate": initialPolicyType.registrationDate, // manufacture year
   "digitIsNewVehicle":  bikeIsRegistered? false: true,// false old vehicle  // not working if i add true
     "vehicleIDV": inputs.coverAmount==(''||null||undefined)?0:finalPolicyType=="thirdParty"?0: inputs.coverAmount ,// idv filter type
     "motorType": null, 
     "vehicleIdentificationNumber": null,
     "engineNumber": null,
     "isPreviousInsurerKnown":  bikeIsRegistered? true: false,
     "previousInsurerCode": null,
     "previousPolicyNumber": null,
     "previousPolicyExpiryDate": prevYear  + "-" + (prevMonth<10 ? `0${prevMonth}` : prevMonth) + "-" +  (prevDay<10? `0${prevDay}` :prevDay ), // from pop up expiry
     "isClaimInLastYear":  bikeIsRegistered? prevClaimValue=='Yes'?true:false :false, // yes or no from pop up
    
   
     "originalPreviousPolicyType": "1OD_5TP",
     "previousPolicyType": finalPolicyType==undefined||null? bikeIsRegistered?  (oldPolicyType=="comprehensive" && 20201) || (oldPolicyType=="thirdParty"&&20202) || (oldPolicyType=="ownDamage"&&20203)    : oldPolicyType=='bundled'?20201:20202 : bikeIsRegistered?  (finalPolicyType=="comprehensive" && 20201) || (finalPolicyType=="thirdParty"&&20202) || (finalPolicyType=="ownDamage"&&20203)    :finalPolicyType=='bundled'?20201:20202,
     "previousNoClaimBonus": bikeIsRegistered?  ((registeredModelValues&&registeredModelValues.prevNCB==(null || undefined))||prevClaimValue=='Yes')?'ZERO':`${registeredModelValues&&+registeredModelValues.prevNCB}`:'ZERO', // previous ncb  need api
     "currentThirdPartyPolicy": null,
     startDate:  formattedStartDate.split("-").reverse().join('-'), // cover from
     endDate: formattedEndDate.split("-").reverse().join('-'), 
     
     
     "isNCBTransfer": null,
     "thirdPartyLiabilityIsTppd":  (finalPolicyType==undefined||null? (['thirdParty',"ownDamage"]).includes(oldPolicyType) ?false: true : (['thirdParty',"ownDamage"]).includes(finalPolicyType) ?false: true  ),
     "discountUserSpecialDiscountPercent": 0,
     "discountDiscounts": [],
     "surchargeLoadings": [],


   
     "personalAccidentSelection":  finalAddons==null||undefined?false:finalAddons.ownerDriverPAcover?true:false, // owner pa driver ? true:false
     "personalAccidentInsuredAmount": null,
     "personalAccidentCoverTerm": 1,
     "cngSelection":false, //bifuel is true 
     "cngInsuredAmount": null,

     "electricalSelection":false, //electric true 
     "electricalInsuredAmount": null,
     "nonElectricalSelection": false, // non electric true :false
     "nonElectricalInsuredAmount": null,
     "partsDepreciationClaimsCovered": null, // zero depriciation
     "partsDepreciationSelection": false,
     "roadSideAssistanceSelection": false , // road side true
     "engineProtectionSelection": false, // engine protect true
     "tyreProtectionSelection":false, // tyre protec
     "rimProtectionSelection": false,
     "returnToInvoiceSelection": false, //return to invoice
     "consumablesSelection": false, // consumble 
     "paidDriverLlSelection": false, // paid driver cover
     "paidDriverLlInsuredCount": null, // if above true ? 1 : null
     "unNamedPaidDriverSelection": finalAddons==null||undefined?false:finalAddons.paidDrivercover?true:false, // un named driver
     "unNamedPaidDriverInsuredAmount": null,
     "unNamedPaidDriverInsuredCount": null,
     "unnamedPaxSelection": finalAddons == null || undefined
     ? false
     : finalAddons.unnamedpassengerPAcover
     ? true
     : false,
     "unnamedPaxInsuredAmount": 100000,
     "unnamedPaxInsuredCount": 2,
 
      },
      {
        headers: {
          'authorization':jwtToken,
            "Content-Type": "application/json",
            accept: "application/json",
          "x-rsa-type":1

        }
      }
      )
      .then((res) => {
        // setquoteLoading(true);
      setApiData(res);
      setLoader(false)
      setLoadApiData(res)
      // setIsApiLoaded(true)
      // localStorage.setItem('apiLoaded',isApiLoaded)
      localStorage.setItem('apiLoaded',true)


    localStorage.setItem('apiData',JSON.stringify(res))

      // console.log(res.data.length > 0);
    })
    
    .catch((error) => console.log(error));

  }
  useEffect(()=>{
    // localStorage.setItem('apiData',JSON.stringify(apiData))
  },[])


  function GFG_FUN() {
    var input = document.getElementsByClassName(coverData);
      
    input.addEventListener("mousewheel", 
        function(event){ 
            this.blur() 
        });
    }
  return (
    <>
      {filterCardData.map((items, key) => (
        <>
          <div
            className={` ${parentClass} bg-white z-0  p-5 shadow-[2px_2px_50px_rgba(0,167,142,0.15)] rounded-lg`}
          >
            <div className="flex items-center pb-5">
              <h1 className="font-semibold text-lg ">{items.title}</h1>
              <div className="flex flex-1"></div>
              <AiOutlineClose
                className="bg-[rgba(0,167,142,0.1)] text-[#989898] w-8 h-8 p-2 rounded-full"
                onClick={() => {
                  setClosePopUp(false);
                }}
              />
            </div>
            <div className="">
              {items.type === "radio2" && (
                <form onChange={handleChange}>
                  <div className="space-y-4 ">
                    {items.radioData.map((item, key) => (
                      <div className=" ">
                      <div className="pb-2 flex items-center "   onChange={(e)=>{
                      }} >
                        <input
                          type="radio"
                          id={item.id}
                          value={item.value}
                          name={item.name}
                          onClick={(e)=>{
                           if(e.target.id=='6'){
                            setShowCustomBox(true)
                          }else{
                             setShowCustomBox(false)
                            
                           }
                          }}
                          defaultChecked={
                            item.id ==JSON.parse(radioValue).policy
                            // Object.values(firstCheckedData).length > 0==undefined ?JSON.parse(radioValue).policy:checkedData.policyData
                            //   ? firstCheckedData.policyData
                            //   : ""
                          }
                        />
                         {" "}
                          
                        <label for={item.id} className="text-sm lg:text-base lg:pr-3">
                          {item.title}
                        </label>
                       
                        </div>
                        
                      </div>
                    ))}
                    <div
                      className={`${items.radioInputClass} ${
                       showCustomBox ? "" : "hidden"
                      } `}
                    >
                      <label className="text-[11px]  text-[#989898]" htmlFor="">
                        {items.inputLabel}
                      </label>
                      <input
                      onScroll={ GFG_FUN}
                        onChange={(e) => {
 setCustBoxError(false)
                          setCstCvdata(e.target.value);
                          setCoverData(e.target.value);
                        }}
                        type="number"

                        id={coverData}
                        name='coverAmount'
                        value={cstCvdata}
                        className="w-full border px-4 my-1 number border-[#989898] focus:outline-none rounded-lg py-1"
                      />
                      {(items.errorMessage || custBoxError) && (
                        <h2 className="text-[10px] text-[#c95050]">
                          Please enter a value within the range mentioned above
                        </h2>
                      )}
                    </div>
                    <button
                      className={` ${noChange==='true'?'':'opacity-[0.7]'} bg-hero text-white px-4 py-2 ml-auto  flex rounded-xl`}
                      disabled={noChange==='true'?false:true} 
                      onClick={handleFilterData}
                    >
                      {items.buttonName}
                    </button>
                  </div>
                </form>
              )}
            
            </div>

          
          </div>
        </>
      ))}
    </>
  );
};
