import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  BiChevronDown,
  BiChevronUp,
  BiMenu,
  BiX,
  BiPhone
} from "react-icons/bi";
import { AiOutlineWhatsApp } from "react-icons/ai";
import HeroLogo from "../assets/newlogo.jpg";
import Shield from "../assets/FooterSvg/shield.png";

import HealthVec from "../assets/Vector/DropDown/Health.svg";
import BikeVec from "../assets/Vector/DropDown/Bike.svg";
import TeamVec from "../assets/Vector/DropDown/Team.svg";
import CarVec from "../assets/Vector/DropDown/Car.svg";
// import RepVid from '../assets/videos/republicDay.mp4'

const NavHeader = ({dropClose=''}) => {
  const [dropOpen, setDropOpen] = dropClose
  const [isDropClose, setIsDropClose] =dropClose
  const [hamOpen, setHamOpen] = useState(false);
  // console.log('drop',isDropClose)

  const handleMouseEnter = () => {
    setDropOpen(true);
  };

  const handleMouseLeave = () => {
    setDropOpen(false);
  };
  return (
    <main className="">
   {/* <div className="relative">
      <video
        autoPlay
        muted
        loop
        className="w-screen h-32 object-fill  object-r"
      >
        <source src={RepVid} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div> */}
    <nav
      className={`${
        hamOpen
          ? "bg-[rgba(51,56,70,0.7)] sticky top-0 z-20 h-screen lg:h-full  lg:bg-white  "
          : ""
      } lg:z-20 lg:py -4 lg:px-28 lg:shadow-xl  lg:sticky lg:top-0 lg:bg-white   `}
      onClick={() => {
        setHamOpen(!hamOpen);
      }}
    >
     
      {/* __________________________ For Desktop View _________________________________ */}
      <div className="hidden lg:block z-10">
        <div className="flex  ">
          {/* Logo */}
          <div className="flex items-center">
            <Link to="/" className="font-semibold text-2xl">
              <img src={HeroLogo} alt="HeroLogo" className="w-[13rem] lg:ml-4 " />
            </Link>
          </div>
          <div className="flex flex-1  justify-around" />
          {/* Menu */}
          <div className=" flex  items-center justify-center gap-5 text-solid font-medium ">
            <div
              className="flex items-center cursor-pointer relative "
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <h1 className="flex text-[#1f243c] lg:text-lg font-semibold ">Insurance </h1>
              <span className="text-hero text-xl">
                {dropOpen ? <BiChevronUp /> : <BiChevronDown />}
              </span>
              {dropOpen && (
                <div className="absolute top-7 -right-16 "   onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}>
                  <DropDownCard
                    className="w-52 py-2 font-inter font-semibold  bg-white drop-shadow-xl rounded-lg"
                    nameClassName="px-4"
                    dropDownData={[
                      {
                        id: "1",
                        img: CarVec,
                        name: "Car Insurance",
                        url: "/carInsurance"
                      },
                      {
                        id: "2",
                        img: BikeVec,
                        name: "Bike Insurance",
                        url: "/bikeInsurance"
                      },
                      {
                        id: "3",
                        img: HealthVec,
                        name: "Health Insurance",
                        url: "/healthInsurance"
                      },
                      {
                        id: "4",
                        img: TeamVec,
                        name: "Term Insurance",
                        url: "/termInsurance"
                      }
                    ]}
                  />
                </div>
              )}
            </div>
            <Link to="/aboutus">
              <h1 className="md:w-max lg:text-lg text-[#1f243c] font-semibold lg:py-7 lg:px-3 hover:bg-[#7f7f7c34]">About Us</h1>{" "}
            </Link>
            <Link to="/article">
              <h1 className="md:w-max lg:text-lg text-[#1f243c] font-semibold lg:py-7 lg:px-3 hover:bg-[#7f7f7c34]">Articles</h1>
            </Link>
            <Link to="/contactus">
              <h1 className="md:w-max lg:text-lg text-[#1f243c] font-semibold lg:py-7 lg:px-3 hover:bg-[#7f7f7c34]">Contact Us</h1>
            </Link>
            <Link to="/join-as-posp">
              <button className="hover:bg-[#00a78e49] font-semibold border border-hero text-[#1f243c] px-4 py-1 md:w-max rounded-[90px]  ">
                Join as POSP
              </button>
            </Link>
          </div>
        </div>
      </div>

      {/* For Mobile View */}
      <div
        className={`lg:hidden ${
          hamOpen ? "ml-14 bg-white slider-in2   mobNav " : ""
        }   `}
      >
        <div
          className={`${
            hamOpen ? "bg-[rgba(0,167,142,0.1)]" : ""
          } flex items-center px-5 py-2`}
        >
          <Link to="/" className="font-semibold text-lg">
            <img src={HeroLogo} alt="HeroLogo" className="w-36" />
          </Link>
          <div className="flex flex-1"></div>
          <div
            className=""
            onClick={() => {
              setHamOpen(!hamOpen);
            }}
          >
            {hamOpen ? (
              <BiX className="text-xl" />
            ) : (
              <BiMenu className="text-xl" />
            )}{" "}
          </div>
        </div>
        {hamOpen && (
          <div className="bg-white h-screen    z-10  w-full">
            <div className="bg-[rgba(0,167,142,0.1)] px-4 ">
              <button className="bg-hero my-6 px-4 py-1 rounded-[90px] text-white  ">
                Join as POSP
              </button>
            </div>
            <div className="px-4">
              <h1 className="flex font-bold text-solid py-3 ">Insurance </h1>
              <DropDownCard
                nameClassName="text-base"
                dropDownData={[
                  {
                    id: "2",
                    name: "Car Insurance",
                    img: Shield,
                    url: "/carInsurance"
                  },
                  {
                    id: "3",
                    name: "Bike Insurance",
                    img: Shield,
                    url: "/bikeInsurance"
                  },
                  {
                    id: "4",
                    name: "Health Insurance",
                    img: Shield,
                    url: "/healthInsurance"
                  },

                  {
                    id: "5",
                    name: "Term Insurance",
                    img: Shield,
                    url: "/termInsurance"
                  }
                ]}
              />
              <h1 className="flex font-bold text-solid py-3 ">Company </h1>
              <DropDownCard
                nameClassName="text-base"
                dropDownData={[
                  {
                    id: "1",
                    name: "About us",
                    img: Shield,
                    url: "/aboutus"
                  },
                  {
                    id: "2",
                    name: "Articles",
                    img: Shield,
                    url: "/article"
                  },
                  {
                    id: "3",
                    name: "Claims",
                    img: Shield,
                    url: "/claims"
                  }
                ]}
              />
              <div className=" space-y-4 pt-4 justify-center bg-[rgba(245,245,246,0.5)] absolute bottom-5  ">
                <div className="bg-[rgba(0,167,142,0.1)] w-max px-4   py-2 rounded-xl flex space-x-3 items-center">
                  <BiPhone className="text-3xl text-hero " />
                  <a href="tel:7014987001" title="">
                    Call Us
                  </a>{" "}
                </div>

                <div className="bg-[rgba(0,167,142,0.1)] w-max px-4   py-2 rounded-xl flex space-x-3 items-center">
                  <AiOutlineWhatsApp className="text-3xl text-hero " />
                  {/* <p className=""></p> */}
                  <a href=" https://wa.me/917014987001" target="_blank">
                    Whatsapp
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
    </main>

  );
};

export default NavHeader;

const DropDownCard = ({ dropDownData, className = "", nameClassName = "" }) => {
  return (
    <div className={`${className} `}>
      {dropDownData.map((item, key) => (
        <Link to={item.url}>
          {" "}
          <div
            className={`${nameClassName} flex w-max items-center space-x-4  pb-3 `}
          >
            <img src={item.img} alt={item.img} />
            <h1 className={`${key+1&&item.id==3?'lg:pl-[6px]':''} ${key+1&&item.id==4?'lg:pl-[7px]':''}`}>{item.name}</h1>
          </div>
        </Link>
      ))}

      <hr className=" text-[#0000000e]" />
    </div>
  );
};
