import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function LocalStorageCheck() {
  const navigate = useNavigate();

  useEffect(() => {
    const isLocalStorageEmpty = () => {
      for (let key in localStorage) {
        if (localStorage.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    };

    const storageEventListener = () => {
      if (isLocalStorageEmpty()) {
        navigate('/');
      }
    };

    if (isLocalStorageEmpty()) {
      // Redirect to the homepage if local storage is empty
      navigate('/');
    }

    // Add the event listener
    window.addEventListener('storage', storageEventListener);

    return () => {
      // Remove the event listener using the same reference
      window.removeEventListener('storage', storageEventListener);
    };
  }, [navigate]);

  return null;
}

export default LocalStorageCheck;
